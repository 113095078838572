import React, { useCallback, useEffect, useRef, useState } from 'react';
import ImageComponent from '../ImageComponent/ImageComponent';
import Product from '../../../Models/Product';
import { useDispatch, useSelector } from 'react-redux';
import {
  convertNumberDateDDDMMMYYYY,
  getStemLabel,
  onlyNumbers,
  toast
} from '../../../Utils/Utils';
import { Input } from 'reactstrap';
import Environment from '../../../Environment';
import { getCountryName } from './../../../Utils/Utils';
import useIsMount from '../../../Hooks/useIsMount';
import { actionsProduct } from '../../../Store/Product/Slice';
import { actionsLoading } from '../../../Store/Loading/Slice';
import { actionsShoppingList } from '../../../Store/ShoppingList/Slice';
import { customerKey } from '../../../Store/Selectors';
import Skeleton from 'react-loading-skeleton';

type Props = {
  product: Product;
  quantity: number;
  length: number;
};

const PurchaseItems: React.FC<Props> = ({ product, quantity, length }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const ref = useRef<Input>(null);
  const productRef = useRef(product);
  const dispatch = useDispatch();
  const productPhotos = product?.photos?.map((i) => Environment.publicPath + i);
  const [productQuantity, setProductQuantity] = useState(
    getStemLabel(
      parseInt(onlyNumbers(quantity.toString())),
      !!product?.box_only
    )
  );
  const [prevQuantity, setPrevQuantity] = useState(quantity.toString());
  const [stemsLoading, setStemsLoading] = useState(false);
  const handleStemChange = (evt: React.FormEvent<HTMLInputElement>) => {
    const value = evt.currentTarget.value;
    setProductQuantity(onlyNumbers(value).toString());
  };

  const customer = useSelector(customerKey);

  const isMounted = useIsMount();

  useEffect(() => {
    productRef.current = product;

    return () => {
      productRef.current = new Product();
    };
  }, [product]);

  useEffect(() => {
    if (
      ref?.current &&
      quantity !== parseInt(ref.current?.props?.value?.toString() || '')
    ) {
      setProductQuantity(
        getStemLabel(quantity, !!productRef.current?.box_only)
      );
    }
  }, [quantity, ref]);

  const handleBlur = async () => {
    if (productQuantity.length === 0) {
      setProductQuantity(
        getStemLabel(
          parseInt(onlyNumbers(prevQuantity)),
          !!productRef.current?.box_only
        )
      );
    }

    if (productQuantity.length > 0) {
      setStemsLoading(true);
      let stems = parseInt(productQuantity);

      if (stems <= 0 && !Number.isInteger(stems)) {
        setStemsLoading(false);
        return toast('Select a valid stem number', 'warning');
      } else if (
        stems < 1 &&
        !(productRef.current?.box_only || !productRef.current?.customizable)
      ) {
        setStemsLoading(false);
        return toast('Please select a minimum of 1 stem', 'warning');
      } else {
        if (parseInt(prevQuantity) !== parseInt(productQuantity)) {
          dispatch(
            actionsProduct.updateProductQuantity({
              productKey: productRef.current.key,
              quantity: parseInt(productQuantity),
              purchase: true,
              prevQuantity: parseInt(productQuantity),
              boxOnly: productRef.current.box_only
            })
          );
          setPrevQuantity(productQuantity);
          setProductQuantity(
            getStemLabel(
              parseInt(onlyNumbers(productQuantity)),
              !!productRef.current?.box_only
            )
          );
        }
        setStemsLoading(false);
      }
      setProductQuantity(
        getStemLabel(
          parseInt(onlyNumbers(productQuantity)),
          !!productRef.current?.box_only
        )
      );
    } else {
      setProductQuantity(
        getStemLabel(
          parseInt(onlyNumbers(prevQuantity)),
          !!productRef.current?.box_only
        )
      );
      setStemsLoading(false);
    }
  };

  const deleteSLItems = async () => {
    setDeleteLoading(true);
    const itemKey = productRef.current.key;
    dispatch(actionsLoading.loadingSL());
    dispatch(actionsShoppingList.removeItemFromSl({ key: itemKey, customer }));
    isMounted && setDeleteLoading(false);
  };

  const getGrower = useCallback((growerKey: string) => {
    const grower = productRef.current?.growers?.find(
      (grower: any) => grower.key === growerKey
    );
    return (
      <span>
        {`From ${grower?.name} (${getCountryName(grower?.country)})`} <br />
      </span>
    );
  }, []);

  return (
    <div
      key={productRef.current.key}
      className={`mb-2 d-flex justify-content-between ${
        deleteLoading ? 'width-box-deleiting' : ''
      }`}>
      <>
        <div className="d-flex align-items-center">
          <div className={'mr-2'}>
            {deleteLoading ? (
              <div className="">
                <Skeleton width={'65px'} height={'65px'} />
              </div>
            ) : (
              <ImageComponent
                imageUrl={productPhotos?.length ? productPhotos[0] : ''}
                imgSize={'65px'}
                className="ibf-border-gray"
              />
            )}
          </div>

          <div className="">
            <span className={'ibf-text-card text-black mb-0'}>
              {deleteLoading ? (
                <>
                  <Skeleton width={'90px'} height={'25px'} />
                </>
              ) : (
                <>
                  {productRef.current.grower &&
                    getGrower(productRef.current.grower)}
                </>
              )}
              {deleteLoading ? (
                <>
                  <Skeleton width={'90px'} height={'25px'} />
                </>
              ) : (
                <>{(productRef.current && productRef.current.name) || ''}</>
              )}

              <br />
              {deleteLoading ? (
                <>
                  <Skeleton width={'90px'} height={'25px'} />
                </>
              ) : (
                <>
                  {length + ' cm '}
                  <span
                    className="pointer color-blue underline"
                    onClick={deleteSLItems}>
                    Delete
                  </span>
                </>
              )}
            </span>
            {productRef.current && productRef.current.delivery_date && (
              <>
                <br />
                {deleteLoading ? (
                  <>
                    <Skeleton width={'220px'} height={'25px'} />
                  </>
                ) : (
                  <span>
                    Delivery date:{' '}
                    {convertNumberDateDDDMMMYYYY(
                      productRef.current && productRef.current.delivery_date
                    )}
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        <div className="ibf-max-width d-flex justify-content-center w-40 pl-1">
          {stemsLoading ? (
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              {deleteLoading ? (
                <div className=" w-90">
                  <Skeleton height={'25px'} />
                </div>
              ) : (
                <Input
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleStemChange(e)
                  }
                  ref={ref}
                  onFocus={handleStemChange}
                  onBlur={handleBlur}
                  invalid={stemsLoading}
                  maxLength={5}
                  inputMode="numeric"
                  value={productQuantity}
                  className={
                    'w-100 form-control form-control-sm ibf-stem-mobile ibf-stem-desktop justify-content-center d-flex text-center'
                  }
                />
              )}
            </>
          )}
        </div>
      </>
    </div>
  );
};

export default PurchaseItems;
