import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../Models/State';

const initialState = {
  state: State.IDLE
};

const slice = createSlice({
  name: 'homeLoading',
  initialState,
  reducers: {
    initHomeLoading(state, { payload }: PayloadAction<any>) {
      state.state = State.PENDING;
    },
    setRejected(state) {
      state.state = State.REJECTED;
    },
    setResolved(state) {
      state.state = State.RESOLVED;
    }
  }
});

export const actionsHomeLoading = slice.actions;
export default slice.reducer;
