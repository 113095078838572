import React, { useEffect, useRef, useState } from 'react';
import ContactComponent from '../Shared/ContactComponent/ContactComponent';
import { useSelector, useDispatch } from 'react-redux';
import { getShoppingListsByConsecutive } from '../../Services/ShoppingListService';
import Environment from '../../Environment';
import Product from '../../Models/Product';
import ImageComponent from '../Shared/ImageComponent/ImageComponent';
import CartProduct from '../../Models/CartProduct';
import { Progress } from 'reactstrap';
import { RootState } from '../../Store/Reducers';
import { actionsCart } from '../../Store/Cart/Slice';
import { actionsLoading } from '../../Store/Loading/Slice';

let total = 30;
let startDate = new Date().getTime();

const CalculatingComponent = () => {
  const [imagesProducts, setImagesProducts] = useState<Array<string>>([]);
  const [productsNames, setProductNames] = useState<Array<string>>([]);
  const [loadingText, setLoadingText] = useState('Searching products...');
  const dispatch = useDispatch();

  const { shoppingLists } = useSelector(
    (state: RootState) => state.ShoppingListReducer
  );

  const { cartProducts } = useSelector((state: RootState) => state.CartReducer);

  const [time, setTime] = useState(30);
  const [currentName, setCurrentName] = useState(0);
  const [results, setResults] = useState([{}]);
  const [progress, setProgress] = useState(0);
  const [cartProductsCopy] = useState(cartProducts);

  const progressBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    startDate = new Date().getTime();
  }, []);

  useEffect(() => {
    let products: any = Object.values(cartProductsCopy).map(
      (product: any) => product
    );

    const photos = products.reduce((arr: any, product: Array<Product>) => {
      product.forEach((product: Product) => {
        if (product?.photos) {
          arr.push(Environment.publicPath + product.photos[0]);
        }
      });
      return arr;
    }, []);

    const names = products.reduce((arr: any, product: Array<CartProduct>) => {
      product.forEach((product: CartProduct) => {
        arr.push(
          `${product.name} ${product?.length} cm at grower ${!!product?.growers
            ?.length && product.growers[0].name} (${!!product?.growers
            ?.length && product.growers[0].country})`
        );
      });
      return arr;
    }, []);

    setImagesProducts(photos);
    setProductNames(names);
    dispatch(actionsCart.setProductsToListCart({}));
    dispatch(actionsLoading.setAddingProduct(false));
  }, [cartProductsCopy, dispatch]);

  useEffect(() => {
    let nameTimer = setInterval(() => {
      try {
        currentName < productsNames?.length - 1 &&
          setCurrentName(currentName + 1);
      } catch (e) {
        clearInterval(nameTimer);
      }
    }, 500);
    return () => clearInterval(nameTimer);
  }, [currentName, productsNames]);

  useEffect(() => {
    if (shoppingLists && shoppingLists?.length) {
      if (
        results &&
        results?.length &&
        results.filter((sl: any) => sl.status !== 'PROCESSED')?.length === 0
      ) {
        setLoadingText('Completed!');
        setProgress(100);
        window.location.href = Environment.urlCheckout;
      } else {
        getShoppingListsByConsecutive(shoppingLists.map((i) => i._KEY)).then(
          (res) => {
            setTimeout(() => {
              setResults(res);
            }, 1000);
          }
        );
      }
    } else {
      window.location.href = Environment.urlCheckout;
    }
  }, [results, shoppingLists]);

  useEffect(() => {
    if (progress < 100) {
      let timer = setInterval(() => {
        try {
          const p = (((new Date().getTime() - startDate) / 1000) * 100) / total;
          setProgress(p);
        } catch (e) {
          clearInterval(timer);
        }
      }, 100);

      return () => clearInterval(timer);
    }

    return () => {};
  }, [progress, shoppingLists]);

  useEffect(() => {
    let clockInterval = setInterval(() => {
      try {
        if (time > 0) {
          setTime(time - 1);
        }
      } catch (e) {
        clearInterval(clockInterval);
      }
    }, 1000);
    return () => clearInterval(clockInterval);
  }, [time]);

  return (
    <div className="bg-ibf-calculating" style={{ height: '90vh' }}>
      <div className="py-5 d-flex justify-content-center ">
        <div className="justify-content-center">
          <div className="d-flex justify-content-between">
            <div className="mt-5 mx-4 mx-md-5 p-0">
              <span className="font-weight-bold main-title">
                We're calculating the best deal available from 80+ growers
              </span>
            </div>
            <div className="mt-5 mx-auto mx-md-5 ">
              <ContactComponent />
            </div>
          </div>

          <div className="p-4 p-md-5" ref={progressBarRef}>
            <Progress
              value={progress}
              barClassName="ibf-progress-bar"
              className="ibf-progress"
            />
          </div>
          <div className="ibf-progress-bar-message d-flex justify-content-between px-md-5">
            <span className="ml-5 ml-md-2 font-italic">
              Processing{' '}
              <span className="d-none d-lg-inline">
                {productsNames[currentName]}
              </span>
            </span>
            <span className="mr-lg-2">
              {progress < 100 ? `Within ${time} sec` : loadingText}
            </span>
          </div>
          <div className="container-fluid">
            <div className="d-flex justify-content-around flex-wrap mb-4">
              <div className="d-flex flex-wrap ibf-product-list-adjustment">
                {imagesProducts.map((image: string, index) => (
                  <div
                    className="ibf-card-sm ibf-card-width mb-2 bg-white mr-lg-2"
                    key={index}>
                    <div className="ibf-card-shadow h-100">
                      <div className="justify-content-center d-flex my-1">
                        <div
                          className="overflow-hidden d-flex justify-content-center align-items-center"
                          style={{ width: '220px', height: '150px' }}>
                          <ImageComponent
                            imageUrl={image}
                            imgSize="220px"
                            style={{ objectFit: 'contain' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatingComponent;
