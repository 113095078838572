import { actionsCheckout } from './Slice';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { RootState } from '../Reducers';
import {
  getOrderRequestsByCustomer,
  setFinishShoppingList
} from '../../Services/ShoppingListService';
import {
  CHECKOUT_PRODUCTS,
  GET_INFO_BY_CUSTOMER,
  REMOVE_FROM_BOX,
  REMOVE_FROM_CUSTOM_BOX
} from './Types';
import { actionsShoppingList } from '../ShoppingList/Slice';
import {
  cancelBox,
  cancelVoucher,
  removeFromCustomBox
} from '../../Services/CheckoutService';
import { getProductsByDateAndEventName, toast } from '../../Utils/Utils';
import { OrderRequest } from '../../Models/OrderRequest';
import { actionsCustomBox } from '../CustomBox/Slice';
import { Cartbox } from '../../Models/Cartbox';
import moment from 'moment';
import { actionsLoading } from '../Loading/Slice';
// import moment from "moment";

export function* checkoutProductsSaga({
  payload
}: ReturnType<typeof actionsCheckout.checkoutProducts>): any {
  try {
    let aux: { [key: string]: Cartbox } = {};
    let count: number = 0;
    const itemsInCart = yield select(
      (state: RootState) => state.CheckoutReducer.itemsInCart
    );

    payload.products
      .filter((product: Cartbox) => {
        return product.komet_delete_date
          ? moment().diff(moment((product as Cartbox).komet_delete_date), 'm') <
              5
          : true;
      })
      .forEach((prod: any) => {
        if (prod.custom) {
          aux[prod._KEY] = prod;
          count += prod.items.length;
        } else {
          let event = prod?.event?._KEY ?? '';
          let productKey =
            prod?.items[0]?.masterlist?._KEY +
            prod?.eta_date +
            event +
            prod.items[0].price;

          if (aux[productKey]) {
            aux[productKey].quantity += 1;
            if (
              aux[productKey].cartBoxes &&
              typeof aux[productKey].cartBoxes !== 'undefined'
            ) {
              (aux[productKey].cartBoxes as string[]).push(prod._KEY);
            }
          } else {
            count += 1;
            aux[productKey] = {
              ...prod,
              quantity: 1,
              cartBoxes: [prod._KEY]
            };
          }
        }
      });

    //const shoppingListKeys: Array<string> = [];

    const products = Object.values(aux).map((item) => {
      if (item.items[0].shopping_list_item?.shopping_list) {
        item = {
          ...item,
          shopping_list: item.items[0].shopping_list_item.shopping_list
        };
        //shoppingListKeys.push(item.items[0].shopping_list_item.shopping_list);
      }
      return item;
    });

    const items =
      itemsInCart > 0 && !payload.newCount && payload.products.length > 0
        ? itemsInCart - 1
        : count;

    let productsDatesCheckout: {
      eta_date: number;
      charge_date: number;
      closing_time: string;
      komet_time: string;
    }[] = [
      ...new Set(
        products
          .filter((product) => !product.komet_delete_date)
          .map((product) => ({
            charge_date: product.charge_date,
            eta_date: product.eta_date,
            closing_time: product.grower?.closing_time ?? '',
            komet_time: product.grower.komet_intg
              ? moment((product as Cartbox)._META.created_time)
                  .add('30', 'm')
                  .format('HH:mm:ss')
              : ''
          }))
          .sort((a, b) => a.charge_date - b.charge_date)
      )
    ];
    let deliveryDatesCheckout: {
      eta_date: number;
      closing_time: string;
    }[] = [];
    let chargeDatesCheckout: {
      charge_date: number;
      closing_time: string;
      komet_time: string;
    }[] = [];
    if (productsDatesCheckout.length > 0) {
      chargeDatesCheckout = productsDatesCheckout.map((product) => ({
        charge_date: product.charge_date,
        closing_time: product.closing_time,
        komet_time: product.komet_time
      }));
      deliveryDatesCheckout = productsDatesCheckout.map((product) => ({
        eta_date: product.eta_date,
        closing_time: product.closing_time
      }));
    }

    const { productsByEventName } = getProductsByDateAndEventName(products);

    yield put(
      actionsCheckout.setCheckoutProducts({
        products,
        productsByEventName,
        itemsInCart: items,
        deliveryDatesCheckout,
        chargeDatesCheckout
      })
    );
    yield put(actionsLoading.setAddingProduct(false));
  } catch (e) {
    console.error('Error on checkout products saga');
    yield put(actionsCheckout.setRejected());
  }
}

export function* getCheckoutInfoByCustomerSaga({
  payload: { customer }
}: ReturnType<typeof actionsCheckout.getCheckoutInfoByCustomer>): any {
  try {
    const OR = yield call(getOrderRequestsByCustomer, customer);
    if (OR && OR.success) {
      yield put(actionsShoppingList.setShoppingLists(OR.results.shoppingList));
      yield put(actionsCheckout.setOrderRequests(OR.results.orderRequests));
      yield put(
        actionsCheckout.setIncompleteProducts(OR.results.incomplete_products)
      );
    }
  } catch (e) {
    console.error('Error on get checkout info saga');
    yield put(actionsCheckout.setRejected());
  }
}

export function* removeFromCustomBoxSaga({
  payload: {
    customer,
    productKey,
    quantity,
    item,
    cartBox,
    slItemKey,
    isBoxOpen
  }
}: ReturnType<typeof actionsCheckout.removeFromCustomBox>): any {
  try {
    const removeItemRes = yield call(
      removeFromCustomBox,
      customer,
      productKey,
      quantity,
      item,
      cartBox,
      isBoxOpen
    );
    if (removeItemRes.success) {
      const currentBox = yield select(
        (state: RootState) => state.CustomBoxReducer.currentBox
      );
      const grower = yield select(
        (state: RootState) =>
          state.ModalReducer?.CUSTOM_BOX_MODAL?.data?.grower?.key
      );
      const isEmpty = removeItemRes.isEmpty;
      const list =
        removeItemRes && removeItemRes.results && removeItemRes.results.carts;
      yield put(actionsCheckout.setCheckoutInfo(removeItemRes));
      if (!isEmpty) {
        yield put(actionsCheckout.checkoutProducts({ products: list }));
      } else {
        yield put(actionsCheckout.checkoutProducts({ products: [] }));
      }

      if (removeItemRes.isEmpty && removeItemRes?.results?.voucher) {
        yield call(cancelVoucher, customer, removeItemRes.results.voucher._KEY);
      }

      if (currentBox?.items) {
        const items = currentBox.items.reduce(
          (total: Array<any>, item: any) => {
            if (item._KEY === productKey) {
              const newItem = {
                ...item,
                quantity: item.quantity - quantity
              };
              if (newItem.quantity > 0) {
                total.push(newItem);
              }
            } else {
              total.push(item);
            }
            return total;
          },
          []
        );
        yield put(
          actionsCustomBox.setCurrentPercentage({
            box: { ...currentBox, items },
            percentage:
              cartBox.current_percentage -
              item.masterlist.custom_percentage * quantity
          })
        );
      }
      toast('Item deleted', 'success');

      yield put(actionsShoppingList.checkLastSLItem({ productKey, slItemKey }));
      if (isBoxOpen) {
        yield put(
          actionsCustomBox.searchInventory({
            params: {
              conditions: {
                custom: true,
                currentPercentage:
                  cartBox.current_percentage -
                  item.masterlist.custom_percentage * quantity,
                filters: { grower: [grower] }
              },
              customer,
              eta: currentBox.eta_date,
              page: 1,
              pageSize: 120
            }
          })
        );
      }
    }
  } catch (e) {
    console.error(e);
    console.error('Error on remove from custom box saga');
    yield put(actionsCheckout.setRejected());
  }
}

export function* removeFromBoxSaga({
  payload: { customer, cartboxes, slItemKey }
}: ReturnType<typeof actionsCheckout.removeFromBox>): any {
  try {
    const res = yield call(cancelBox, customer, cartboxes);
    if (res.success) {
      yield put(actionsCheckout.setCheckoutInfo(res));
      const isEmpty = res.isEmpty;

      const list = res && res.results && res.results.carts;
      // get custom boxes and products
      if (!isEmpty) {
        yield put(actionsCheckout.checkoutProducts({ products: list }));
      } else {
        yield put(actionsCheckout.checkoutProducts({ products: [] }));
      }

      toast('Item deleted', 'success');

      if (res.isEmpty && res?.results?.voucher) {
        yield call(cancelVoucher, customer, res.results.voucher._KEY);
      }

      if (slItemKey) {
        for (const index in cartboxes) {
          const key = cartboxes[parseInt(index)];
          yield put(
            actionsShoppingList.checkLastSLItemByBox({
              productKey: key,
              slItemKey
            })
          );
        }
      }
    } else {
      toast('An error occurred', 'error');
    }
  } catch (e) {
    console.error('Error on remove from box saga');
    yield put(actionsCheckout.setRejected());
  }
}

export function* checkShoppingListSaga(): any {
  try {
    const { products, incompleteProducts, orderRequests } = yield select(
      (state: RootState) => state.CheckoutReducer
    );
    const { shoppingListsProcessed } = yield select(
      (state: RootState) => state.ShoppingListReducer
    );

    if (shoppingListsProcessed?.results.length > 0 && products?.length > 0) {
      for (const index in shoppingListsProcessed.results) {
        const sl = shoppingListsProcessed.results[parseInt(index)];

        const productSL = products?.some((product: any) =>
          product.items.some(
            (item: any) => item?.shopping_list?._KEY === sl._KEY
          )
        );

        if (!products || !productSL) {
          const someOrderRequest = orderRequests.some(
            (OR: OrderRequest) => OR._KEY === sl.order_request
          );

          if (!someOrderRequest) {
            const incompleteSL = incompleteProducts?.some((product: any) =>
              product?.items?.some(
                (item: any) => item.shopping_list === sl._KEY
              )
            );
            if (!incompleteSL) {
              const res = yield call(setFinishShoppingList, sl._KEY);
              if (res.success) {
                yield put(actionsCheckout.setResolved());
              } else {
                yield put(actionsCheckout.setRejected());
                console.error(
                  'Something was wrong finishing shopping list',
                  'error'
                );
              }
            }
          }
        }
      }
    }
  } catch (e) {
    console.error('Error on check shopping list saga');
    yield put(actionsCheckout.setRejected());
  }
}

export default function* AllSagas() {
  yield all([
    //Auth sagas
    takeEvery(GET_INFO_BY_CUSTOMER, getCheckoutInfoByCustomerSaga),
    takeEvery(REMOVE_FROM_CUSTOM_BOX, removeFromCustomBoxSaga),
    takeEvery(REMOVE_FROM_BOX, removeFromBoxSaga),
    takeEvery(CHECKOUT_PRODUCTS, checkoutProductsSaga)
  ]);
}
