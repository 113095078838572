import React, { useEffect } from 'react';
import { Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCustomer } from '../../../../Store/Customer/Slice';
import {
  authReducer,
  checkoutReducer,
  customerReducer
} from '../../../../Store/Selectors';
import { actionsModal } from '../../../../Store/Modal/Slice';

import {
  ADDRESSES_MODAL,
  COMPANY_INFORMATION_MODAL,
  MESSAGE_MODAL
} from '../../../../Store/Modal/Types';
import { CustomerAddress, Actions } from '../../../../Models/CustomerModel';
import { State } from '../../../../Models/State';
import LoadingSpinner from '../../../Shared/LoadingSpinner';
import { ContentMenu } from './AddressListItems';
import { filterAddress, toast } from '../../../../Utils/Utils';

type Props = {
  states: string;
  show?: (show: boolean) => void;
};

export default function AddressListContent({ states, show }: Props) {
  const {
    user: {
      metadata: {
        customer,
        customer_zipcode,
        guest,
        customer_main_street,
        customer_main_city,
        customer_main_state,
        customer_main_zipcode
        // customer_closed_days
      }
    }
  } = useSelector(authReducer);

  const { state, selectedAddress, addressList } = useSelector(customerReducer);
  const { products } = useSelector(checkoutReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionsCustomer.getCustomerAddress(customer));

    const getAddress = () => {
      if (Object.keys(selectedAddress).length === 0) return;
      return selectedAddress;
    };
    getAddress();
  }, [customer, dispatch, selectedAddress]);

  const deleteCustomerAddress = (_KEY: string, action: string) => {
    dispatch(actionsCustomer.deleteCustomerAddress({ _KEY, action }));
  };

  const handleOnchange = (item: CustomerAddress) => {
    const address = {
      _KEY: item._KEY,
      address: item.address,
      city: item.city,
      zipcode: item.zipcode,
      state: { abbr: item.state }
    };
    // const closeDays =
    //   addressList.find((res) => res._KEY === item._KEY).closedDays ?? [];
    // const addressDefault = [
    //   customer_main_street,
    //   customer_main_city,
    //   customer_main_zipcode,
    //   customer_main_state
    // ];
    // const addressLocal = [item.address, item.city, item.zipcode, item.state];
    dispatch(
      actionsCustomer.addCustomerAddress({
        customer: customer,
        slAddress: address,
        // closedDays:
        //   addressDefault.toString() === addressLocal.toString()
        //     ? customer_closed_days
        //     : closeDays,
        save: false
      })
    );
  };

  const showModalDelete = (_KEY: string) => {
    const address = {
      address: customer_main_street,
      city: customer_main_city,
      zipcode: customer_main_zipcode,
      state: customer_main_state
    };
    const data = {
      body: `Are you sure you want to delete the (extra) address? The default address will be selected for you.`,
      size: 'md',
      iconCloseEnabled: true,
      buttonEnabled: true,
      buttonClose: true,
      textButton: 'Yes, delete',
      textClose: 'No, go back',
      bodyClassName: 'px-4 pb-4',
      classNameParagraph: 'ibf-font-20 d-block ibf-text-line-height-1 mb-4',
      buttonClassName: 'mb-1 w-100 btn btn-outline-primary',
      buttonClassClose: 'mb-1 w-100 btn bg-primary text-white',
      // contentClassButton: 'mb-1 w-100 btn bg-primary text-white  ',
      onClick: () => {
        deleteCustomerAddress(_KEY, Actions.DELETE);
        handleOnchange(address);
      },
      cancelAction: () => {}
    };
    dispatch(
      actionsModal.setOpenModal({
        modal: MESSAGE_MODAL,
        data: data
      })
    );
    dispatch(actionsCustomer.setVisibleDropdown());
  };

  return (
    <>
      {guest && (
        <ContentMenu
          handleOnClick={() => {
            dispatch(actionsCustomer.setVisibleDropdown());
            show && show(false);
          }}>
          <Menu.Item className="pb-0 mb-0">
            <div className="form-check mr-3 d-flex align-items-center">
              <input
                type="radio"
                className="form-check-input"
                id="address"
                name="address"
                defaultChecked={true}
              />
              <label
                className="normal-register-label ibf-font-16 text-black mb-0 text-break"
                htmlFor="address">
                {`
                 ${states} 
                 ${customer_zipcode}`}
              </label>
            </div>
          </Menu.Item>
          <Menu.Item className="h-auto">
            <span className="d-block text-black ibf-text-line-height-1">
              Prices are based on the zipcode you filled in.
              <br />
              Your exact address will be asked for during checkout.
            </span>
          </Menu.Item>
        </ContentMenu>
      )}

      {!guest && (
        <ContentMenu
          handleOnClick={() => {
            show && show(false);
            dispatch(actionsCustomer.setVisibleDropdown());
          }}>
          <Menu.Item className="h-auto bg-white text-break">
            <div className="d-flex align-items mt-3 ibf-text-line-height-1 ">
              <span className="text-black">Default address </span>
              <span
                className="text-primary underline pointer ml-1"
                onClick={() => {
                  show && show(false);
                  if (products.some((product) => product.komet_order_date)) {
                    toast(
                      'You have komet products in your cart. If you wish change the address buy or delete them.',
                      'error'
                    );
                  } else {
                    dispatch(actionsCustomer.setVisibleDropdown());
                    dispatch(
                      actionsModal.setOpenModal({
                        modal: COMPANY_INFORMATION_MODAL
                      })
                    );
                  }
                }}>
                (change)
              </span>
            </div>
          </Menu.Item>
          <div className="form-check mr-3 d-flex align-items-center ibf-text-line-height-1 text-break ml-3">
            <>
              {' '}
              <input
                type="radio"
                className="form-check-input mt-1"
                id={'address_default'}
                name={'address_default'}
                checked={
                  `${selectedAddress.address}, ${selectedAddress.city}, ${selectedAddress.state.abbr} ${selectedAddress.zipcode}` ===
                  `${customer_main_street}, ${customer_main_city}, ${customer_main_state} ${customer_main_zipcode}`
                }
                disabled={state === State.PENDING}
                onChange={() => {
                  const address = {
                    address: customer_main_street,
                    city: customer_main_city,
                    zipcode: customer_main_zipcode,
                    state: customer_main_state
                  };

                  handleOnchange(address);
                }}
              />
              <label
                className="normal-register-label ibf-font-16 text-black mb-0 text-break"
                htmlFor={'address_default'}>
                {`${customer_main_street}, ${customer_main_city}, ${customer_main_state} ${customer_main_zipcode}`}
              </label>
            </>
          </div>

          {addressList.filter((item) =>
            filterAddress(
              item,
              customer_main_street,
              customer_main_zipcode,
              customer_main_state,
              customer_main_city
            )
          ).length > 0 && (
            <span className="d-flex ibf-font-14 pl-3 mt-4 mb-3">
              {' '}
              Other (one time) addresses
            </span>
          )}
          {addressList.length > 0 &&
            addressList
              .filter((item) =>
                filterAddress(
                  item,
                  customer_main_street,
                  customer_main_zipcode,
                  customer_main_state,
                  customer_main_city
                )
              )
              .sort((a, b) => {
                return a.city.localeCompare(b.city, 'fr', {
                  ignorePunctuation: true
                });
              })
              .map((item: CustomerAddress) => {
                const address = `${item.address}, ${item.city}, ${item.state} ${item.zipcode}`;

                const selected = `${selectedAddress.address}, ${selectedAddress.city}, ${selectedAddress.state.abbr} ${selectedAddress.zipcode}`;

                return (
                  <div
                    className="form-check mr-3 d-flex align-items-center ibf-text-line-height-1 ml-3 pointer mb-1"
                    key={`address_${item._KEY}`}>
                    <input
                      type="radio"
                      className="form-check-input"
                      id={`address_${item._KEY}`}
                      name={`address_${item._KEY}`}
                      checked={address === selected}
                      onChange={() => {
                        handleOnchange(item);
                      }}
                      disabled={state === State.PENDING}
                    />
                    <label
                      className="normal-register-label ibf-font-16 text-black mb-0"
                      htmlFor={`address_${item._KEY}`}>
                      {address}
                    </label>
                    <span
                      className="pointer underline text-black ml-2 text-nowrap"
                      onClick={() => {
                        show && show(false);
                        showModalDelete(item._KEY!);
                      }}>
                      delete
                    </span>
                  </div>
                );
              })}
          <Menu.Item>
            <span
              className=" text-primary pointer underline"
              onClick={() => {
                show && show(false);
                if (products.some((product) => product.komet_order_date)) {
                  toast(
                    'You have komet products in your cart. If you wish change the address buy or delete them.',
                    'error'
                  );
                } else {
                  dispatch(
                    actionsModal.setOpenModal({ modal: ADDRESSES_MODAL })
                  );
                  dispatch(actionsCustomer.setVisibleDropdown());
                }
              }}>
              + Add a (one time) delivery address
            </span>
          </Menu.Item>
          {state === State.PENDING && (
            <LoadingSpinner message={'LOADING'} classSpinner="size-sppiner" />
          )}
        </ContentMenu>
      )}
    </>
  );
}
