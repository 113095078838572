import React from 'react';
import { RootState } from '../../../Store/Reducers';
import { useSelector } from 'react-redux';
import Environment from '../../../Environment';
import MixpanelService from '../../../Services/MixpanelService';
import { addObjectParamsToPath, uuidV4 } from '../../../Utils/Utils';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
interface TypeColors {
  name: string;
  searchCategory: string;
  colorFrom: string;
  colorTo: string;
  colorGradientOne?: string;
  colorGradientTwo?: string;
  colorGradientThree?: string;
}

const colors: Array<TypeColors> = [
  {
    name: 'White to Cream',
    searchCategory: 'All whites',
    colorFrom: '#FFFFFF',
    colorTo: '#FFE4C4'
  },
  {
    name: 'Blush to Pink',
    searchCategory: 'All pinks',
    colorFrom: '#FFF3F3',
    colorTo: '#FFBBFF'
  },
  {
    name: 'Violet to Purple',
    searchCategory: 'All purples',
    colorFrom: 'rgba(253, 174, 235, 0.75)',
    colorTo: 'rgba(182, 64, 206, .8)'
  },
  {
    name: 'Cerise to Red',
    searchCategory: 'All reds & cerise',
    colorFrom: 'rgba(232, 123, 123, 0.55)',
    colorTo: 'rgba(250, 6, 4, .6)'
  },
  {
    name: 'Orange to Peach',
    searchCategory: 'All orange & peach',
    colorFrom: '#FF4C00',
    colorTo: '#F2AD86'
  },
  {
    name: 'Brown to Bronze',
    searchCategory: 'All browns & bronz',
    colorFrom: '#D2B48C',
    colorTo: '#CD7F31'
  },
  {
    name: 'Gold to Yellow',
    searchCategory: 'All yellows',
    colorFrom: '#FFA500',
    colorTo: '#FFFF00'
  },
  {
    name: 'Lime to Olive',
    searchCategory: 'All greens',
    colorFrom: '#CEF8AF',
    colorTo: '#4A9808'
  },
  {
    name: 'Turqoise to Blue',
    searchCategory: 'All blues',
    colorFrom: '#40E0D0',
    colorTo: '#1469C6'
  },
  {
    name: 'Gray to Black',
    searchCategory: 'All blacks',
    colorFrom: '#D3D3D3',
    colorTo: '#808080'
  },
  {
    name: 'Multiple colors',
    searchCategory: 'All multiple colors',
    colorFrom: '#FFFFFF',
    colorTo: '#FFC4FE',
    colorGradientOne: '#49D5D3',
    colorGradientTwo: '#FFF700',
    colorGradientThree: '#D9001B'
  }
];

const ColorRangeComponent = () => {
  const { authLoading } = useSelector(
    (store: RootState) => store.LoadingReducer
  );
  const { date } = useSelector((state: RootState) => state.ProductReducer);
  const history = useHistory();

  const searchCategory = async (key: string) => {
    const filters = { color: Environment.colorList[key] };
    const params = { date, search_val: '', ...filters };
    MixpanelService.track('colors_menu_item', { name: key });
    history.push(addObjectParamsToPath('/search', params));
  };

  return (
    <div className="d-flex flex-column ">
      {!authLoading && (
        <h5 className="font-weight-bold">Shop on color range</h5>
      )}

      <div className="ibf-category-card-color-container bg-white">
        {!authLoading ? (
          colors.map((color) => {
            return (
              <div
                key={'color_' + uuidV4()}
                className="ibf-category-card-color"
                onClick={() => searchCategory(color.searchCategory)}
                style={{
                  background: `linear-gradient(to right, ${color.colorFrom}, ${
                    color.colorTo
                  }${
                    color.colorGradientOne
                      ? `,${color.colorGradientOne},${color.colorGradientTwo},${color.colorGradientThree}`
                      : ''
                  })`,
                  opacity: `${color.colorGradientOne ? 0.9 : ''}`
                }}>
                <span>
                  {color.name.substring(0, color.name.indexOf(' '))}
                  <br />
                  {color.name.substring(
                    color.name.indexOf(' '),
                    color.name.length
                  )}
                </span>
              </div>
            );
          })
        ) : (
          <div className="h-100 w-100">
            <Skeleton height="100%" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorRangeComponent;
