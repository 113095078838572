import Config from './Config/Config';
import User from './User/User';
import { Type } from 'class-transformer';
import Manager from './Manager';
import { Holiday } from './Holiday';

export default class AuthResponse {
  token!: string;
  @Type(() => Config)
  config!: Config;
  @Type(() => User)
  user!: User;
  offset!: string;
  isNewDay!: boolean;
  error!: string;
  manager!: Manager;
  holidays!: Array<Holiday>;
  dependencies: any;
  time!: number;
  verificationCode!: number;
  smsPreferencesKey!: string;
}
