import React, { ChangeEvent } from 'react';
import { GrowerPricing } from '../../../../Models/GrowerPricing';
//import { useSelector } from 'react-redux';
import {
  //getAvailableBoxes,
  //getBunchesAvailable,
  getStemLabel,
  roundUp
} from '../../../../Utils/Utils';
//import Environment from '../../../../Environment';
import Product from '../../../../Models/Product';

type Props = {
  lengthSelected: GrowerPricing;
  getBoxesValue: (e: ChangeEvent<HTMLSelectElement>) => void;
  handleStemChange: (evt: ChangeEvent<HTMLSelectElement>) => void;
  isProductDetail?: boolean;
  boxOnly?: boolean;
  category?: string;
  product: Product;
  index?: number;
  activeTab?: string;
  boxesSelected: string;
  boxesAvailable: number[];
  bunchesAvailable: number;
};

const CustomInputsComponent: React.FC<Props> = ({
  lengthSelected,
  getBoxesValue,
  handleStemChange,
  isProductDetail,
  boxOnly,
  category,
  product,
  index,
  activeTab,
  boxesSelected,
  boxesAvailable,
  bunchesAvailable
}) => {
  /*  console.log(':product ', product); */

  /* const { isAList } = useSelector(loadingReducer); */

  //console.log(':bunchesAvailable ', bunchesAvailable);

  let {
    price_own_box,
    total_box,
    stem_price,
    price_full_box,
    stems_per_bunch,
    percentage
  } = lengthSelected;

  // console.log(':product ', product);

  /*  const bunchesAvailable = getBunchesAvailable(
     available,
    percentage,
    currentPercentage
  ); */

  //const boxesAvailable = getAvailableBoxes(available, total_box);

  return (
    <>
      <div className="ibf-product-form-wrapper-box-select">
        <div className="d-flex flex-column ibf-font-12 ">
          {/*  <span className="font-weight-bold">
            Per box ({total_box} {total_box > 1 ? 'bunches' : 'bunch'} ={' '}
            {stems_per_bunch * total_box}
            {category === Environment.categoriesKey.supplies
              ? ' items'
              : ' stems'}
            )
          </span> */}
          {/*  <span>
            {category === Environment.categoriesKey.bouquets
              ? `$${roundUp(stem_price * stems_per_bunch)} / bunch`
              : price_own_box > 0 &&
                `$${roundUp(stem_price)} / 
              ${
                category === Environment.categoriesKey.supplies
                  ? ' item'
                  : ' stem'
              }`}
            $ {roundUp(price_full_box)}/box
          </span> */}
        </div>

        {/*  <div className="select">
          <select
            id={'box_list_' + product.key + '_' + index}
            disabled={!boxesAvailable.length}
            onChange={getBoxesValue}
            style={{ width: '90px', height: '31.05px' }}>
            <option key={0} value={0}>
              {boxesAvailable.length ? '# Full boxes' : 'Not available'}
            </option>
            {boxesAvailable.map((i, index) => (
              <option key={index + 1} value={index + 1}>
                {getStemLabel(index + 1, true)} = {(index + 1) * total_box}{' '}
                bunches = {(index + 1) * stems_per_bunch * total_box}
                {category === Environment.categoriesKey.supplies
                  ? ' items'
                  : ' stems'}
              </option>
            ))}
          </select>
        </div> */}
      </div>
      {/*   {!boxOnly && (
        <>
          {!isAList && <hr className="w-100 my-2" />}
          <div className="ibf-product-form-wrapper-bunch-select">
            <div className="d-flex flex-column ibf-font-12 p-0">
              <span className="font-weight-bold">
                Per bunch ({percentage}% of box)
              </span>
              <span>
                {!!stems_per_bunch &&
                  `$${roundUp(price_own_box / stems_per_bunch)}/st $
                ${roundUp(price_own_box)} (${stems_per_bunch} st)`}
              </span>
            </div>

            <div className="select p-0">
              <select
                id={'bunch_list_' + product.key + '_' + index}
                onChange={handleStemChange}
                disabled={bunchesAvailable === 0}
                style={{ width: '90px', height: '31.05px' }}>
                <option key={0} value="0">
                  {bunchesAvailable > 0
                    ? '# Bunches in 1 custom box'
                    : 'Not available'}
                </option>
                {Array(bunchesAvailable)
                  .fill(1)
                  .map((i, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1 + `${index + 1 > 1 ? ' Bunches' : ' Bunch'}`} (
                      {(index + 1) * stems_per_bunch +
                        `${
                          (index + 1) * stems_per_bunch > 1 ? ' stems' : ' stem'
                        }`}
                      )
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </>
      )} */}
      {!boxOnly && activeTab === '2' && (
        <>
          <div className="d-flex flex-column ibf-font-12 mx-1 py-2">
            <span className="">
              Per bunch:{' '}
              {!!stems_per_bunch &&
                `$${roundUp(price_own_box)} ($${roundUp(
                  price_own_box / stems_per_bunch
                )}/st)`}
            </span>
          </div>
          <div
            className={`select p-0 border-radius-1 mx-1 ${
              isProductDetail ? 'px-2 mr-2' : ''
            }`}>
            <select
              id={'bunch_list_' + product.key + '_' + index}
              onChange={handleStemChange}
              disabled={bunchesAvailable === 0}
              style={{ width: '90px', height: '31.05px' }}
              className="selector-no-border-radius">
              {bunchesAvailable > 0 ? (
                Array(bunchesAvailable)
                  .fill(1)
                  .map((i, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1 + `${index + 1 > 1 ? ' bu' : ' bu'}`}
                      {` $${roundUp(
                        (index + 1) * price_own_box
                      )} (${percentage * (index + 1)}% of box = ${(index + 1) *
                        stems_per_bunch}st)`}
                      {/* (
                    {`${(index + 1) * stems_per_bunch} st =${percentage *
                      (index + 1)}% of box) $${roundUp(
                      price_own_box / stems_per_bunch
                    )}/st`}
                    ) */}
                    </option>
                  ))
              ) : (
                <option key={0} value={0}>
                  {'Not available'}
                </option>
              )}
            </select>
          </div>
        </>
      )}
      {activeTab === '1' && (
        <>
          <div
            className={`d-flex flex-column ibf-font-12 mx-1 py-2 ${
              isProductDetail ? 'px-2 mr-2' : ''
            }`}>
            <span>
              Full box:{' '}
              {`$${roundUp(price_full_box)} ($${roundUp(
                price_full_box / total_box
              )}/bu = $${roundUp(stem_price)}/st)`}
            </span>
          </div>
          <div className={`select mx-1 ${isProductDetail ? 'px-2 mr-2' : ''}`}>
            <select
              id={'box_list_' + product.key + '_' + index}
              disabled={!boxesAvailable.length}
              onChange={getBoxesValue}
              style={{ width: '90px', height: '31.05px' }}
              defaultValue={boxesSelected}
              className="selector-no-border-radius">
              {boxesAvailable.length ? (
                boxesAvailable.map((i, index) => {
                  return (
                    <option key={index + 1} value={index + 1}>
                      {/* {`${getStemLabel(index + 1, true)} (${(index + 1) *
                        stems_per_bunch *
                        total_box} st in ${(index + 1) *
                        total_box} bu) $${roundUp(stem_price)}/st `} */}
                      {`${getStemLabel(index + 1, true)} $${roundUp(
                        (index + 1) * price_full_box
                      )} (${(index + 1) * total_box} bu
                      
                      ${
                        stems_per_bunch === 0
                          ? ')'
                          : ` = ${(index + 1) *
                              stems_per_bunch *
                              total_box} st)`
                      }
                      `}

                      {/*  */}
                    </option>
                  );
                })
              ) : (
                <option key={0} value={0}>
                  {'Not available'}
                </option>
              )}
            </select>
          </div>
        </>
      )}
    </>
  );
};

export default CustomInputsComponent;
