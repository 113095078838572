import React from 'react';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputColorLabelComponentD } from './InputColorLabelComponentD';

const subColors = [
  {
    color: 'blue',
    title: 'Blue',
    short: 'blue'
  },
  {
    color: 'blue',
    title: 'Blue - dark',
    short: 'blueDark'
  },
  {
    color: 'blue',
    title: 'Blue - light',
    short: 'blueLight'
  }
];

const subColors2 = [
  {
    color: 'brown',
    title: 'Brown',
    short: 'brown'
  },
  {
    color: 'brown',
    title: 'Brown - dark',
    short: 'brownDark'
  }
];

export const ColorFilterComponentD = () => {
  return (
    <div className="contFiltersD">
      <div className="inputFiltersD">
        <FontAwesomeIcon icon={faSearch} color="#00000040" />
        <input type="text" placeholder={'Color'} />
      </div>

      <div className="ListFiltersD" style={{ maxHeight: 250 }}>
        <ul className="ListFiltersD__ul">
          <InputColorLabelComponentD
            color={'blue'}
            title={'Blue to dark blue'}
            short="blueToDark"
            style={{ marginBottom: '5px' }}
          />

          <ul className="v2">
            {subColors.map((item) => (
              <InputColorLabelComponentD key={item.short} {...item} />
            ))}
          </ul>

          <InputColorLabelComponentD
            color={'brown'}
            title={'Brown to bronze'}
            short="brownBronze"
            style={{ marginBottom: '5px' }}
          />

          <ul className="v2">
            {subColors2.map((item) => (
              <InputColorLabelComponentD key={item.short} {...item} />
            ))}
          </ul>
        </ul>
      </div>
    </div>
  );
};
