import React from 'react';

type Props = {
  checkboxName: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  itemKey: string;
};

const FilterListItemComponent: React.FC<Props> = ({
  checkboxName,
  onChange,
  checked,
  itemKey
}) => {
  return (
    <div className="mb-2 d-inline-flex bg-list">
      <label className="checkbox mr-3">
        <input
          type="checkbox"
          name={checkboxName}
          value={checkboxName}
          id={itemKey}
          checked={checked}
          onChange={onChange}
        />
        <span className="primary" />
      </label>
      <span
        style={{
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '17px',
          color: '#4A4A4A'
        }}>
        {checkboxName}
      </span>
    </div>
  );
};

export default FilterListItemComponent;
