import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../Models/State';
import { groupColorsByReference } from '../../Utils/Utils';
import { Occasion } from '../../Models/Config/Occasion';

const { IDLE, REJECTED, RESOLVED } = State;

export type LandingProductItem = {
  type: 'product_group' | 'category';
  parent_name: string;
  name: string;
  _KEY: string;
};

type DashboardState = {
  // TODO: add the missing types, no more any!
  trackPackageInfo: any;
  growerList: any;
  colorList: any;
  categoryList: any;
  state: State;
  occasions: any;
  trends: {
    items: Array<Occasion>;
    rows: number;
    columns: number;
  };
  landingProductItems: Array<LandingProductItem[]>;
};

const initialState: DashboardState = {
  trackPackageInfo: null,
  growerList: {},
  colorList: [],
  categoryList: [],
  state: IDLE,
  occasions: [],
  trends: {
    items: [],
    rows: 1,
    columns: 4
  },
  landingProductItems: []
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setOrderToShip(state, { payload }) {
      state.trackPackageInfo = payload;
      state.state = RESOLVED;
    },
    setHomeCategoryList(state, { payload }) {
      state.categoryList = payload;
      state.state = RESOLVED;
    },
    setGrowerList(state, { payload }) {
      state.growerList = payload;
      state.state = RESOLVED;
    },
    setColorList(state, { payload }) {
      state.colorList = groupColorsByReference(payload);
      state.state = RESOLVED;
    },
    setConfig(state, { payload }) {
      state.occasions = payload;
    },
    setTrendingList(
      state,
      {
        payload
      }: PayloadAction<{ items: Occasion[]; rows?: number; columns?: number }>
    ) {
      state.trends = {
        items: payload.items,
        rows: payload.rows ? payload.rows : 1,
        columns: payload.columns ? payload.columns : 4
      };
    },
    setProductGroupWithMasterlist(state, { payload }) {
      state.landingProductItems = payload;
    },
    setRejected(state) {
      state.state = REJECTED;
    },
    setResolved(state) {
      state.state = RESOLVED;
    }
  }
});

export const actionsDashboard = slice.actions;
export default slice.reducer;
