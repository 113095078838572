import React, { useEffect, useState } from 'react';
type Props = {
  getNavHeight: () => void;
};

const HeaderComponent: React.FC<Props> = ({ getNavHeight }) => {
  const [message, setMessage] = useState('');
  const [expiration, setExpiration] = useState(new Date());
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setMessage(localStorage.getItem('deletedMessage') || '');
    setExpiration(new Date(localStorage.getItem('expDeletedMessage') || ''));
  }, []);

  useEffect(() => {
    let nameTimer = setInterval(() => {
      if (message && expiration.getTime() - new Date().getTime() < 0) {
        setIsVisible(false);
        clearInterval(nameTimer);
      } else {
        if (message && !isVisible) {
          setIsVisible(true);
        } else if (!message) {
          clearInterval(nameTimer);
        }
      }
    }, 1000);
    return () => clearInterval(nameTimer);
  }, [expiration, message, isVisible]);

  useEffect(() => {
    function displayWindowSize() {
      getNavHeight();
    }

    window.addEventListener('resize', displayWindowSize);
    getNavHeight();

    return () => {
      window.removeEventListener('resize', displayWindowSize);
    };
  }, [getNavHeight]);

  return isVisible && message ? (
    <div
      id="deleted_message"
      className="px-3 py-2 d-flex justify-content-center background-orange ibf-header-arrow-orange">
      <div className="ibf-header-text d-flex">
        <span className="ml-1">{message}</span>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default HeaderComponent;
