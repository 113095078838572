import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Statistic } from 'antd';
import { actionsCustomer } from '../../../Store/Customer/Slice';
import {
  convertNumberDateDDDMMM,
  convertNumberDateYYYYMMDD,
  findDateAfter24HIndex,
  findDateBefore24HIndex,
  getTodayESTNumber,
  redirectToCheckout,
  toast
} from '../../../Utils/Utils';
import { actionsShoppingList } from '../../../Store/ShoppingList/Slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Store/Reducers';

const { Countdown } = Statistic;

type Props = {
  count: number;
};

const getMomentDateFormatByHour = (numberDate: number, hour?: string) => {
  return moment
    .tz(
      `${convertNumberDateYYYYMMDD(numberDate)} ${hour ?? '08:00:00'}`,
      'America/New_York'
    )
    .format('YYYY-MM-DD HH:mm:ss');
};

const CheckoutCountDownComponent = ({ count }: Props) => {
  const { user } = useSelector((state: RootState) => state.AuthReducer);
  const [isCalculable, setIsCalculable] = useState(false);
  const [message, setMessage] = useState(false);
  const address = useSelector(
    (state: RootState) => state.CustomerReducer.selectedAddress
  );
  const { products, chargeDatesCheckout, deliveryDatesCheckout } = useSelector(
    (state: RootState) => state.CheckoutReducer
  );
  const { cartProducts, charge_dates, deliveryDateCart } = useSelector(
    (state: RootState) => state.CartReducer
  );
  const [greenClosingTime, setGreenClosingTime] = useState('');
  const [orangeClosingTime, setOrangeClosingTime] = useState('');
  const [greenDeliveryDate, setGreenDeliveryDate] = useState('');
  const [orangeDeliveryDate, setOrangeDeliveryDate] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const messageStorage = localStorage.getItem('deletedMessage') || '';
    const expiration = new Date(
      localStorage.getItem('expDeletedMessage') || ''
    );
    setMessage(
      messageStorage.length > 0 &&
        !(expiration.getTime() - new Date().getTime() < 0)
    );
  }, []);

  useEffect(() => {
    const guest = user.metadata.guest;
    if (
      !guest &&
      address.state &&
      address.address &&
      address.city &&
      address.zipcode
    ) {
      setIsCalculable(true);
    } else {
      setIsCalculable(false);
    }
  }, [user.metadata.guest, address]);

  const sendSL = () => {
    if (isCalculable) {
      dispatch(
        actionsShoppingList.createShoppingList({
          customer: user.metadata.customer,
          address
        })
      );
    } else {
      toast('Please select an address.', 'error');
    }
  };

  const handleCheckout = async () => {
    if (count === 0) {
      const preferences = {
        ...user.metadata.preferences,
        ...{ hideGreetingModal: true }
      };
      dispatch(
        actionsCustomer.updateUserPreferences({
          customer: user.metadata.customer,
          preferences
        })
      );
      redirectToCheckout(isCalculable);
    } else {
      sendSL();
    }
  };

  useEffect(() => {
    const setupGreenCounter = (
      index: number,
      arrayChargeDates: { charge_date: number; closing_time?: string }[],
      arrayDeliveryDates: { eta_date: number; closing_time?: string }[]
    ) => {
      if (index !== -1) {
        let arrayDeliveryDatesSorted = arrayDeliveryDates
          .map((element) => {
            return {
              ...element,
              momentDate: moment.tz(
                `${convertNumberDateYYYYMMDD(element.eta_date)} ${
                  element.closing_time
                    ? element.closing_time + ':00'
                    : '08:00:00'
                }`,
                'America/New_York'
              )
            };
          })
          .sort((a, b) => a.momentDate.diff(b.momentDate));

        const numberDate = arrayChargeDates[index]?.charge_date;
        const deliveryDate = arrayDeliveryDatesSorted[index]?.eta_date;

        // setGreenCounter(convertNumberDateYYYYMMDD(numberDate));
        setGreenDeliveryDate(convertNumberDateDDDMMM(deliveryDate));
        if (arrayChargeDates[index].closing_time) {
          setGreenClosingTime(
            getMomentDateFormatByHour(
              numberDate,
              arrayChargeDates[index].closing_time?.split(' ')[0] + ':00'
            )
          );
        } else {
          setGreenClosingTime(getMomentDateFormatByHour(numberDate));
        }
      } else {
        setGreenClosingTime('');
      }
    };

    const setupOrangeCounter = (
      index: number,
      arrayChargeDates: {
        charge_date: number;
        closing_time?: string;
        komet_time?: string;
      }[],
      arrayDeliveryDates: { eta_date: number; closing_time?: string }[]
    ) => {
      if (index !== -1) {
        const numberDate = arrayChargeDates[index]?.charge_date;
        const deliveryDate = arrayDeliveryDates[index]?.eta_date;
        // setOrangeCounter(convertNumberDateYYYYMMDD(numberDate));
        if (deliveryDate) {
          setOrangeDeliveryDate(convertNumberDateDDDMMM(deliveryDate));
        }

        if (arrayChargeDates[index].komet_time) {
          setOrangeClosingTime(
            getMomentDateFormatByHour(
              getTodayESTNumber(),
              arrayChargeDates[index].komet_time
            )
          );
        } else {
          if (arrayChargeDates[index].closing_time) {
            setOrangeClosingTime(
              getMomentDateFormatByHour(
                numberDate,
                arrayChargeDates[index].closing_time?.split(' ')[0] + ':00'
              )
            );
          } else {
            if (deliveryDate) {
              setOrangeClosingTime(getMomentDateFormatByHour(numberDate));
            }
          }
        }
      } else {
        setOrangeClosingTime('');
      }
    };

    if (Object.keys(cartProducts).length > 0 || products.length > 0) {
      let chargeDates: {
        charge_date: number;
        closing_time?: string;
        komet_time?: string;
      }[] = [];
      let deliveryDates: {
        eta_date: number;
        closing_time?: string;
        komet_time?: string;
      }[] = [];
      let indexAfter = 0;
      let indexBefore = 0;

      if (chargeDatesCheckout.length > 0 && charge_dates.length > 0) {
        chargeDates = [...chargeDatesCheckout, ...charge_dates];
        deliveryDates = [...deliveryDatesCheckout, ...deliveryDateCart];
      } else {
        chargeDates =
          chargeDatesCheckout.length > 0 ? chargeDatesCheckout : charge_dates;
        deliveryDates =
          deliveryDatesCheckout.length > 0
            ? deliveryDatesCheckout
            : deliveryDateCart;
      }

      const today = parseInt(
        moment(new Date())
          .tz('America/New_York')
          // .add(1, 'days')
          .format('YYYYMMDD')
      );

      deliveryDates = deliveryDates
        .map((element) => {
          return {
            ...element,
            momentDate: moment.tz(
              `${convertNumberDateYYYYMMDD(element.eta_date)} ${
                element.closing_time ? element.closing_time + ':00' : '08:00:00'
              }`,
              'America/New_York'
            )
          };
        })
        .sort((a, b) => a.momentDate.diff(b.momentDate));

      chargeDates = chargeDates
        .map((element) => {
          return {
            ...element,
            momentDate: moment.tz(
              `${convertNumberDateYYYYMMDD(
                element.komet_time ? getTodayESTNumber() : element.charge_date
              )} ${
                element.komet_time
                  ? element.komet_time
                  : element.closing_time
                  ? element.closing_time + ':00'
                  : '08:00:00'
              }`,
              'America/New_York'
            )
          };
        })
        .sort((a, b) => a.momentDate.diff(b.momentDate));

      deliveryDates = deliveryDates.filter((date) => date.eta_date !== today);
      chargeDates = chargeDates.filter(
        (date) => date.charge_date !== today || date.komet_time
      );

      // console.log("chargeDates", chargeDates)

      indexBefore = findDateBefore24HIndex(chargeDates);
      indexAfter = findDateAfter24HIndex(chargeDates);

      setupGreenCounter(indexAfter, chargeDates, deliveryDates);
      setupOrangeCounter(indexBefore, chargeDates, deliveryDates);
    }
  }, [
    cartProducts,
    products,
    charge_dates,
    chargeDatesCheckout,
    deliveryDatesCheckout,
    deliveryDateCart
  ]);

  return (
    <>
      {orangeClosingTime && (
        <div
          className={`w-100 background-orange ibf-header-text d-sm-flex justify-content-center align-items-center px-3 py-2 ${
            !greenClosingTime ? 'ibf-header-arrow-orange' : ''
          } ${message ? 'border font-weight-bold' : ''}`}>
          <Countdown
            valueStyle={{
              color: 'white',
              fontSize: '1rem',
              // marginLeft: '1em',
              float: 'left'
            }}
            value={orangeClosingTime}
            format={`H[h] m[m] s[s]`}
          />
          &nbsp; left to &nbsp;
          <u className="pointer" onClick={handleCheckout}>
            check out
          </u>
          &nbsp; for delivery {orangeDeliveryDate}
        </div>
      )}

      {greenClosingTime && (
        <div
          className={`w-100 background-green ibf-header-text d-sm-flex justify-content-center align-items-center px-3 py-2
            ibf-header-arrow-green ${
              message ? 'border font-weight-bold' : ''
            }`}>
          <Countdown
            valueStyle={{ color: 'white', fontSize: '1rem', float: 'left' }}
            value={greenClosingTime}
            format={'D[d] H[h] m[m] s[s]'}
          />
          &nbsp; left to &nbsp;
          <u className="pointer" onClick={handleCheckout}>
            check out
          </u>
          &nbsp; for delivery {greenDeliveryDate}
        </div>
      )}
    </>
  );
};

export default CheckoutCountDownComponent;
