import React from 'react';
import { AutoSuggestItem } from './AutoSuggestItem';
import SeeWhatProductsComponent from '../Shared/SeeWhatProductsComponent/SeeWhatProductsComponent';
import { actionsModal } from '../../Store/Modal/Slice';
import { GROWER_INFO_MODAL } from '../../Store/Modal/Types';
import { useDispatch } from 'react-redux';
import { FilterSearch } from '../NavBarComponent/SearchInputComponent/SuggestionListComponent';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLevelDown } from '@fortawesome/pro-light-svg-icons';

type Props = {
  suggestionItems: FilterSearch[];
  submitSuggestion: (item: FilterSearch) => void;
  onSubmit: () => void;
  width: number | string;
  isLoading: boolean;
  search: string;
};

export const AutoSuggestCategory = ({
  suggestionItems,
  submitSuggestion,
  width,
  search,
  isLoading,
  onSubmit
}: Props) => {
  const dispatch = useDispatch();
  return (
    <>
      <ul className="list-unstyled">
        <ul
          className="list-unstyled my-2 overflow-auto"
          style={{ maxHeight: '50vh' }}>
          {suggestionItems.length > 0
            ? suggestionItems.map((suggestion, index) => {
                return (
                  <AutoSuggestItem
                    key={'category.label' + index}
                    width={width}
                    suggestion={suggestion}
                    submitSuggestion={() => submitSuggestion(suggestion)}
                  />
                );
              })
            : search &&
              !isLoading && (
                <li className="pl-4">No suggestions found for {search}</li>
              )}
        </ul>

        {/*{search && (*/}
        {/*  <div className="border-top w-100">*/}
        {/*    <AutoSuggestItem*/}
        {/*      key={'category.label' + 9999}*/}
        {/*      width={width}*/}
        {/*      suggestion={*/}
        {/*        <span>*/}
        {/*          Find anything with{' '}*/}
        {/*          <b>*/}
        {/*            {search*/}
        {/*              .split(' ')*/}
        {/*              .filter((s) => s)*/}
        {/*              .map((word) => `'${word}'`)*/}
        {/*              .join(' and/or ')}{' '}*/}
        {/*            <FontAwesomeIcon*/}
        {/*              className="ml-1"*/}
        {/*              icon={faLevelDown}*/}
        {/*              rotation={90}*/}
        {/*            />*/}
        {/*          </b>*/}
        {/*        </span>*/}
        {/*      }*/}
        {/*      submitSuggestion={onSubmit}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}

        <li
          className={'suggested-item pl-4 py-2 mr-1 border-top'}
          key={'external'}
          onMouseDown={() => {
            dispatch(actionsModal.setOpenModal({ modal: GROWER_INFO_MODAL }));
          }}>
          <SeeWhatProductsComponent />
        </li>
      </ul>
    </>
  );
};
