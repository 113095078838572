import { State } from '../../Models/State';
export const GET_CARDS = 'card/getCards';
export const DELETE_CARD = 'card/deleteCard';
export const CHANGE_DEFAULT_CARD = 'card/changeDefaultCard';
export const ADD_CARD = 'card/setNewCard';
export interface cardState {
  cardDefault: any;
  selectedCard: any;
  cards: any;
  loadingCards: boolean;
  cardAddedSuccessfully: boolean;
  state: State;
}
