import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../Models/State';
import { TotalProductByDate } from '../../Models/Product';
import Conditions from '../../Models/SearchInventory/Conditions';
import { CalendarState } from './Types';
const { IDLE, RESOLVED, PENDING, REJECTED } = State;

const initialState: CalendarState = {
  disabledDays: [{ daysOfWeek: [0, 1, 2, 3, 4, 5, 6] }],
  modifiers: {},
  time: 0,
  nextDay: 0,
  modifiersStyles: {
    limitedOffer: {
      background: 'rgba(238, 205, 129, 0.38)'
    },
    orangeDaysOfWeek: {
      background: 'rgba(238, 205, 129, 0.38)'
    },
    disabledDays: {
      background: '#f2f2f2'
    }
  },
  aheadMonths: '',
  state: IDLE,
  totalProductByDate: [],
  showOverlay: false,
  daysOfWeek: {},
  loadingDaysOfWeek: false,
  showAirlineCalendar: true
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    initCalendar(
      state,
      {
        payload
      }: PayloadAction<{
        holidays: any;
        blockDays: any;
        time: number;
        disabled_calendar_days?: {
          [key: string]: Array<string>;
        };
      }>
    ) {
      state.state = PENDING;
    },
    setDisableDays(state, { payload }: PayloadAction<any>) {
      state.state = RESOLVED;
      state.disabledDays = payload;
    },
    setModifiers(state, { payload }: PayloadAction<any>) {
      state.state = RESOLVED;
      state.modifiers = payload;
    },
    setAheadMonths(state, { payload }: PayloadAction<any>) {
      state.state = RESOLVED;
      state.aheadMonths = payload;
    },
    setTime(state, { payload }: PayloadAction<number>) {
      state.state = RESOLVED;
      state.time = payload;
    },
    setNextDay(state, { payload }: PayloadAction<number>) {
      state.state = PENDING;
    },
    setResolved(state) {
      state.state = RESOLVED;
    },
    setRejected(state) {
      state.state = REJECTED;
    },
    getTotalProductByDate(
      state,
      {
        payload
      }: PayloadAction<{
        params: {
          conditions: Conditions;
          pageSize: number;
          customer: string;
          dates: number[];
        };
      }>
    ) {
      state.state = PENDING;
    },
    setTotalProductByDate(
      state,
      {
        payload
      }: PayloadAction<{
        daysOfWeek: { [key: string]: number };
        totalProductByDate: TotalProductByDate;
      }>
    ) {
      state.totalProductByDate = payload.totalProductByDate;
      state.daysOfWeek = payload.daysOfWeek;
      state.state = RESOLVED;
      const showAirCalendar = Object.values(payload?.totalProductByDate).some(
        (value) => value > 0
      );
      state.showAirlineCalendar = showAirCalendar;
    },
    setShowAirlineCalendar(state, { payload }: PayloadAction<boolean>) {
      state.showAirlineCalendar = payload;
    },
    setShowOverlay(state, { payload }: PayloadAction<boolean>) {
      state.showOverlay = payload;
    },

    setLoadingDaysOfWeek(state, { payload }: PayloadAction<boolean>) {
      state.loadingDaysOfWeek = payload;
    }
  }
});

export const actionsCalendar = slice.actions;
export default slice.reducer;
