import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  productReducer,
  authReducer,
  filterReducer,
  calendarReducer,
  loadingReducer
} from '../../Store/Selectors';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faSpinner
} from '@fortawesome/pro-solid-svg-icons';
import AvailabilityContent from './AvailabilityContent';
import { actionsProduct } from '../../Store/Product/Slice';
import { State } from '../../Models/State';
import {
  convertDateToNumberDate,
  PAGE_SIZE_PRODUCT,
  selectDate,
  addObjectParamsToPath,
  convertNumberDateToDate,
  hrefToObject,
  getNextBusinessDay
} from '../../Utils/Utils';
import { useLocation, useHistory } from 'react-router-dom';
import useDeviceDetect from '../../Hooks/useDeviceDetect';
import { actionsCalendar } from '../../Store/Calendar/Slice';
import Conditions from '../../Models/SearchInventory/Conditions';
import { DateUtils } from 'react-day-picker';
//import { TotalProductByDate } from '../../Models/Product';

type Props = {
  index: number;
  date: string;
  dateNumber: number | any;
};

interface Iprops {
  enableDateGrower?: boolean;
}
// 20220101: 1234 items
let cacheDates: { [date: string]: number } = {};
export default function AvailabilityAirlineCalendar({
  enableDateGrower
}: Iprops) {
  const {
    user: {
      metadata: { customer }
    }
  } = useSelector(authReducer);
  const [arrayDate, setArrayDate] = useState<Array<Props>>([]);
  const { filterLoading } = useSelector(loadingReducer);
  const [fromIndex, setFromIndex] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { date, favorites } = useSelector(productReducer);
  const { filters_conditions, search } = useSelector(filterReducer);
  const {
    disabledDays,
    totalProductByDate,
    state,
    loadingDaysOfWeek,
    showAirlineCalendar
  } = useSelector(calendarReducer);
  const { isMobile } = useDeviceDetect();
  const favoriteArr = useRef(favorites);

  React.useEffect(() => {
    const getNextIndex = (dateNumber: number) => {
      if (totalProductByDate.hasOwnProperty(dateNumber)) {
        if (totalProductByDate[dateNumber] !== 0) {
          setFromIndex(
            arrayDate.findIndex((item) => item.dateNumber === dateNumber)
          );
        } else {
          let date1 = convertNumberDateToDate(dateNumber);

          const nextAvailable = convertDateToNumberDate(
            getNextBusinessDay(date1)
          );

          const disableNumberDates = disabledDays
            .filter((days) => DateUtils.isDate(days))
            .map((days) => convertDateToNumberDate(days));

          if (disableNumberDates.some((days) => days === nextAvailable)) {
            date1.setDate(date1.getDate() + 1);
            getNextIndex(convertDateToNumberDate(date1));
          } else {
            if (totalProductByDate[dateNumber] !== 0) {
              setFromIndex(
                arrayDate.findIndex((item) => item.dateNumber === dateNumber)
              );
            } else {
              getNextIndex(convertDateToNumberDate(date1));
            }
          }
        }
      } else {
        setFromIndex(
          arrayDate.findIndex((item) => item.dateNumber === dateNumber)
        );
      }
    };

    if (arrayDate.length > 0) {
      getNextIndex(parseInt(date.toString()));
    }
  }, [arrayDate, date, totalProductByDate, disabledDays]);

  const createArrayDate = (
    date: number,
    disabledDays: any,
    isMobile: boolean,
    iteration?: number
  ) => {
    if (!iteration) {
      iteration = isMobile ? 3 : 5;
    }

    let data = [];
    let disables = [];
    let disabledDaysW: Array<number> = [];

    for (let i = 0; i < disabledDays.length; i++) {
      if (disabledDays[i] instanceof Date) {
        disables.push(moment(disabledDays[i]).format('YYYYMMDD'));
      } else if (disabledDays[i].hasOwnProperty('before')) {
        disables.push(moment(disabledDays[i].before).format('YYYYMMDD'));
      } else if (disabledDays[i].hasOwnProperty('daysOfWeek')) {
        let arr: Array<number> = disabledDays[i]['daysOfWeek'];
        for (let j = 0; j < arr.length; j++) {
          if (!disabledDaysW.includes(arr[j])) {
            disabledDaysW.push(arr[j]);
          }
        }
      }
    }

    // console.log("date", date)
    let iterator = moment(date, 'YYYYMMDD').subtract(8, 'days');

    let visibilityItems = iteration;
    let index = 0;

    while (index < visibilityItems) {
      let current = iterator.add(1, 'days');
      if (
        !(
          disables.includes(current.format('YYYYMMDD')) ||
          disabledDaysW.includes(current.day())
        )
      ) {
        // console.log("current", current)
        let dates = {
          index,
          date: current.format('ddd, MM/DD'),
          dateNumber: convertDateToNumberDate(iterator)
        };

        data.push(dates);
        index++;
      }
    }

    return data;
  };

  useEffect(() => {
    const {
      date: d,
      search_val: search = '',
      ...filters
    }: { [key: string]: any } = hrefToObject();

    const dataArrayDate = createArrayDate(
      d ?? parseInt(date.toString()),
      disabledDays,
      isMobile,
      10
    );
    setArrayDate(dataArrayDate);

    if (dataArrayDate.length > 0) {
      const { combo } = filters;

      delete filters.orderBy;
      delete filters.sortOrder;
      delete filters.combo;
      delete filters.substitute;
      if (filters.favorites) {
        filters.variety = Object.keys(favoriteArr.current);
      }

      Object.keys(filters).forEach((key) => {
        if (!Array.isArray(filters[key])) {
          filters[key] = !!filters[key] ? [filters[key]] : [];
        }
      });

      const params: {
        conditions: Conditions;
        pageSize: number;
        customer: string;
        dates: number[];
      } = {
        conditions: { filters, search, combo },
        pageSize: PAGE_SIZE_PRODUCT,
        customer,
        dates: []
      };

      for (let index = 0; index < dataArrayDate.length; index++) {
        if (!cacheDates[dataArrayDate[index].dateNumber]) {
          cacheDates[dataArrayDate[index].dateNumber] = 0;
          params.dates.push(dataArrayDate[index].dateNumber);
        }
      }

      if (params.dates.length > 0) {
        dispatch(actionsCalendar.getTotalProductByDate({ params }));
      }
    }
  }, [dispatch, customer, location, disabledDays, isMobile, date]);

  const handleSelectDate = (action: string) => {
    let selectedDate = parseInt(date.toString());

    let index = arrayDate.findIndex((item) => item.dateNumber === selectedDate);

    if (action === 'next') {
      index =
        arrayDate
          .slice(index + 1, arrayDate.length)
          .findIndex((item) => totalProductByDate[item.dateNumber] > 0) +
        index +
        1;

      if (index >= arrayDate.length) {
        index = arrayDate.length - 1;
      }
    } else {
      const datesReverse = new Array(...arrayDate).reverse();
      const tempIndex = datesReverse.length - 1 - index;
      const newIndex =
        datesReverse
          .slice(tempIndex + 1, datesReverse.length)
          .findIndex((item) => totalProductByDate[item.dateNumber] > 0) +
        tempIndex +
        1;
      index = arrayDate.length - 1 - newIndex;

      if (index < 0) {
        index = 0;
      }
    }

    selectedDate = arrayDate[index].dateNumber;

    let elementDate = convertNumberDateToDate(selectedDate);

    let pathname = location.pathname;
    const params = selectDate(
      elementDate,
      search,
      filters_conditions,
      date,
      pathname
    );

    dispatch(
      actionsProduct.setProductDate({
        date: convertDateToNumberDate(elementDate)
      })
    );
    history.push(addObjectParamsToPath(params.pathname, params.search));
  };

  return (
    <>
      {(state !== State.REJECTED || loadingDaysOfWeek) && showAirlineCalendar && (
        <div className="d-flex align-items-center bg-white border mb-3 mt-2 container-available justify-content-between w-100 px-2 ">
          <>
            <button
              className={`angle-available-right ${
                !(
                  Object.keys(totalProductByDate).length === 0 ||
                  state === State.PENDING ||
                  filterLoading
                )
                  ? 'pointer'
                  : 'cursor-auto'
              }`}
              onClick={() => handleSelectDate('prev')}
              disabled={
                Object.keys(totalProductByDate).length === 0 ||
                state === State.PENDING ||
                filterLoading
              }>
              <FontAwesomeIcon
                icon={faAngleLeft}
                color="black"
                className="mr-2"
              />
            </button>

            {arrayDate.length === 0 && state === State.PENDING ? (
              <div className="d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faSpinner} pulse />
              </div>
            ) : (
              arrayDate.length > 0 &&
              arrayDate
                .slice(fromIndex - 1, fromIndex + 4)
                .map((item: Props, index: number) => {
                  return (
                    <AvailabilityContent
                      enableDateGrower={enableDateGrower}
                      item={item}
                      key={index}
                      totalItems={totalProductByDate}
                      dateSelected={date}
                      filterLoading={
                        (!totalProductByDate[item.dateNumber] &&
                          totalProductByDate[item.dateNumber] !== 0) ||
                        filterLoading ||
                        state === State.PENDING ||
                        loadingDaysOfWeek
                      }
                    />
                  );
                })
            )}

            <button
              className={`angle-available-left ${
                !(
                  Object.keys(totalProductByDate).length === 0 ||
                  state === State.PENDING ||
                  filterLoading
                )
                  ? 'pointer'
                  : 'cursor-auto'
              }`}
              onClick={() => handleSelectDate('next')}
              disabled={
                Object.keys(totalProductByDate).length === 0 ||
                state === State.PENDING ||
                filterLoading
              }>
              <FontAwesomeIcon
                icon={faAngleRight}
                color="black"
                className="ml-2"
              />
            </button>
          </>
        </div>
      )}
    </>
  );
}
