import React, { ReactNode } from 'react';
import { Menu } from 'antd';

type Props = {
  children: ReactNode;
  handleOnClick: () => void;
};

type HeaderProps = {
  handleOnClick: () => void;
};

const Header = ({ handleOnClick }: HeaderProps) => {
  return (
    <div className="d-flex justify-content-between align-items-center ibf-font-16">
      <span className="font-weight-bold text-black">Deliver to:</span>
      <span className="text-primary underline pointer" onClick={handleOnClick}>
        Close
      </span>
    </div>
  );
};

export const ContentMenu = ({ children, handleOnClick }: Props) => {
  return (
    <Menu className="ibf-menu-list-position overflow-auto shadow pb-3 max-height-500">
      <Menu.Item>
        <Header handleOnClick={handleOnClick} />
      </Menu.Item>
      {children}
    </Menu>
  );
};
