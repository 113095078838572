import GrowerOfTheMonth from './GrowerOfTheMonth';
import { Occasion } from './Occasion';

export default class Config {
  android_version_code!: number;
  isDown!: boolean;
  holyDay!: string;
  topBarMessage!: string;
  banner!: string;
  url_filter_keys!: string[];
  carrier_available!: { _KEY: string }[];
  growerOfTheMonth!: GrowerOfTheMonth;
  country_highlight!: Array<string>;
  sl_users!: string | null;
  claim_days!: number;
  blockDays!: Array<number>;
  manager_admin!: { view: string; allow: string[] }[];
  occasions!: Array<Occasion>;
  trends!: {
    items: Array<Occasion>;
    rows?: number;
    columns?: number;
  };
  excludeCategoriesKey!: string[];
  yellowAlert!: {
    visible: boolean;
    message: string;
  };
  calendarAlert!: {
    visible: boolean;
    message: string;
    boldText: string;
  };
  emergencyMessage!: {
    title: string;
    visible: boolean;
    link: string;
    linkLabel: string;
    paragraphs: Array<string>;
  };
  especial_days!: {
    label: string;
    dates: number[];
  }[];
  tintedAlert!: {
    message: string;
    message_after_click: string;
    tinted_terms: Array<string>;
  };
  disabled_calendar_days!: {
    [key: string]: Array<string>;
  };
  vDayAlert?: {
    title: string;
    messages: { bold: string; message: string }[];
  };
}
