import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  addToBoxService,
  closeCustomBox,
  openCustomBox,
  searchInventory as inventoryService
} from '../../Services/CustomBoxService';
import * as ModalTypes from '../../Store/Modal/Types';
import { addObjectParamsToPath, hrefToObject, toast } from '../../Utils/Utils';
import { actionsCustomBox } from './Slice';
import Environment from '../../Environment';
import { actionsModal } from '../Modal/Slice';
import { actionsCheckout } from '../Checkout/Slice';
import {
  ADD_TO_BOX,
  ADD_TO_CART,
  CLOSE_BOX,
  OPEN_BOX,
  SEARCH_INVENTORY
} from './Types';
import { addToCart } from '../../Services/ProductService';
import { RootState } from '../Reducers';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import { actionsProduct } from '../Product/Slice';
import { Cartbox } from '../../Models/Cartbox';
import { TrackEventMonitor } from '../../Classes/TrackEvent';
import { actionsLoading } from '../Loading/Slice';
export function* searchInventorySaga({
  payload: { params, moreProducts }
}: ReturnType<typeof actionsCustomBox.searchInventory>): any {
  try {
    // Validation to check if there aren't inventory available
    // const params2 = {
    //   ...params,
    //   conditions: {
    //     ...params.conditions,
    //     currentPercentage: 0
    //   }
    // };
    // const res2 = yield call(inventoryService, params2);
    // if (res2?.response) {
    //   if (res2.response.products.length === 0 && !params.conditions.search) {
    //     yield put(actionsCustomBox.setInventorySold(true));
    //   }

    const res = yield call(inventoryService, params);
    yield put(
      actionsCustomBox.setProductsStillFitInBox(
        res.response.productsStillFitInBox
      )
    );
    const { currentPercentage } = yield select(
      (state: RootState) => state.CustomBoxReducer
    );
    if (res.response.productsStillFitInBox && currentPercentage < 100) {
      if (moreProducts) {
        yield put(actionsCustomBox.setMoreProducts(res.response));
        yield put(actionsLoading.setAddingProduct(false));
      } else {
        yield put(actionsCustomBox.setSearchResults({ results: res.response }));
        yield put(actionsLoading.setAddingProduct(false));
      }

      if (
        res.response.products.length === 0 &&
        !params.conditions.search &&
        res.response.productsStillFitInBox &&
        Object.keys(params.conditions).length === 1
      ) {
        yield put(actionsCustomBox.setInventorySold(true));
      }
    } else {
      // if (params.customer && !fromEdit) {
      //   yield put(
      //     actionsModal.setCloseModal({ modal: ModalTypes.CUSTOM_BOX_MODAL })
      //   );
      //   yield put(
      //     actionsCustomBox.closeBox({
      //       customer: params.customer,
      //       fromBox: true
      //     })
      //   );
      // }

      toast('Your custom box is full.');
    }
    yield put(actionsCustomBox.setResolved());
    // } else {
    //   toast('An error occurred', 'error');
    //   yield put(actionsCustomBox.setRejected());
    // }
  } catch (error) {
    toast('An error occurred', 'error');
    console.error('Error on search inventory saga');
    yield put(actionsCustomBox.setRejected());
  }
}

export function* addToBoxSaga({
  payload
}: ReturnType<typeof actionsCustomBox.addToBox>): any {
  try {
    const grower = payload.grower
      ? payload.grower
      : yield select(
          (state: RootState) =>
            state.ModalReducer?.CUSTOM_BOX_MODAL?.data?.grower?.key
        );

    const { filter_conditions } = yield select(
      (state: RootState) => state.CustomBoxReducer
    );

    const is_closing_time_product = payload.boxParams.is_closing_time_product;

    delete payload.boxParams.is_closing_time_product;
    const params = {
      payload: {
        params: {
          conditions: {
            custom: true,
            currentPercentage: payload.percentage,
            filters: { ...filter_conditions, grower: [grower] }
          },
          customer: payload.boxParams.customer,
          eta: payload.boxParams.arrival_date,
          page: 1,
          pageSize: 120
        },
        fromAddToBox: true
      },
      type: SEARCH_INVENTORY
    };
    const res = yield call(addToBoxService, payload.boxParams);
    if (res && res.success) {
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'Fill box success', value: '' });
      }

      const box: Cartbox = res.cartbox;

      TrackEventMonitor.newEvent({
        props: {
          grower_key: grower ? grower.key : '',
          box_percentage: box.current_percentage,
          inventory_key: payload.boxParams.invKey,
          arrival_date: payload.boxParams.arrival_date,
          charge_date: payload.boxParams.charge_date,
          packing_date: payload.boxParams.packing_date,
          quantity: payload.boxParams.quantity,
          custom_box_modal: false,
          is_komet_product: box.grower.komet_intg,
          is_closing_time_product,
          consecutive: box.consecutive,
          cart_box_key: box._KEY,
          customer_key: box.customer._KEY
        },
        metadata: {
          name: 'ibf_add_to_box'
        }
      });

      // const box: Cartbox | undefined = boxResponse.results.carts.find(
      //   (box: Cartbox) =>
      //     box.items[0].variety._KEY === key &&
      //     box.eta_date === payload.arrival_date &&
      //     box.items[0].inventory._KEY === payload.invKey  &&
      //     box.items[0].quantity === payload.quantity
      // );
      //

      toast('Product was added.');
      yield put(
        actionsCustomBox.setCurrentPercentage({
          percentage: res.cartbox.current_percentage,
          box: res
        })
      );

      if (res.cartbox.current_percentage < 100) {
        yield searchInventorySaga(params);
      } else {
        // const { fromEdit } = yield select(
        //   (state: RootState) => state.CustomBoxReducer
        // );
        // if (!fromEdit) {
        //   yield put(
        //     actionsModal.setCloseModal({ modal: ModalTypes.CUSTOM_BOX_MODAL })
        //   );
        //   yield put(
        //     actionsModal.setCloseModal({ modal: ModalTypes.CART_MODAL })
        //   );
        //   yield put(
        //     actionsCustomBox.closeBox({
        //       customer: payload.boxParams.customer,
        //       fromBox: true
        //     })
        //   );
        // }
        toast('Your custom box is full.');
        yield put(actionsCustomBox.setProductsStillFitInBox(false));
        yield put(actionsCustomBox.setResolved());
      }
    } else {
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'Fill box failed', value: '' });
      }

      yield put(
        actionsModal.setCloseModal({ modal: ModalTypes.CUSTOM_BOX_MODAL })
      );
      yield put(
        actionsCustomBox.closeBox({
          customer: payload.boxParams.customer,
          fromBox: true
        })
      );

      toast(
        res.error ||
          'There are no more products that can fit in your custom box, please close it!',
        'error'
      );
      yield put(actionsCustomBox.setRejected());

      if (res?.error) {
        Sentry.withScope(function(scope) {
          scope.setLevel(Severity.Error);
          Sentry.setExtra('Payload', JSON.stringify(payload));
          Sentry.setExtra('Response', JSON.stringify(res));
          // The exception has the event level set by the scope (info).
          Sentry.captureException(new Error('Error on Add to box'));
        });
      }
    }
  } catch (e) {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'Fill box failed', value: '' });
    }

    yield put(
      actionsModal.setCloseModal({ modal: ModalTypes.CUSTOM_BOX_MODAL })
    );
    yield put(
      actionsCustomBox.closeBox({
        customer: payload.boxParams.customer,
        fromBox: true
      })
    );

    Sentry.withScope(function(scope) {
      scope.setLevel(Severity.Error);
      Sentry.setExtra('Payload', JSON.stringify(payload));
      Sentry.setExtra('Error', e);
      // The exception has the event level set by the scope (info).
      Sentry.captureException(new Error('Error on Add to box'));
    });

    console.error(e);
    yield put(actionsCustomBox.setRejected());
    toast(e, 'error');
  }
}

export function* closeBoxSaga({
  payload
}: ReturnType<typeof actionsCustomBox.closeBox>): any {
  try {
    yield put(actionsCustomBox.setClearAll());

    const res = yield call(closeCustomBox, payload.customer);
    if (res && res.success) {
      const { id, date }: { [key: string]: any } = hrefToObject();
      if (id) {
        const { filters_conditions } = yield select(
          (state: RootState) => state.ProductReducer
        );
        let filters = Object.assign({}, filters_conditions);
        filters.date = date;
        window.location.href = addObjectParamsToPath('#/search', filters);
      }

      if (res.success && !res.isEmpty) {
        yield put(
          actionsCheckout.checkoutProducts({
            products: res.results.carts,
            newCount: true
          })
        );
        yield put(actionsCheckout.setCheckoutInfo(res));
      }

      //yield put(actionsCustomBox.setResolved());
    }
  } catch (e) {
    console.error('Error close box');
    yield put(actionsCustomBox.setRejected());
    toast(e, 'error');
  }
}

export function* openBoxSaga({
  payload
}: ReturnType<typeof actionsCustomBox.openBox>): any {
  try {
    const res = yield call(
      openCustomBox,
      payload.cartbox._KEY,
      payload.customer
    );
    if (res) {
      yield put(
        actionsModal.setOpenModal({
          modal: ModalTypes.CUSTOM_BOX_MODAL,
          data: {
            grower: {
              key: payload?.cartbox?.grower?._KEY,
              name: payload?.cartbox?.grower?.company_name,
              country:
                Environment.country_flag_map[payload?.cartbox?.grower?.country]
            },
            eta_date: payload.cartbox.eta_date
          }
        })
      );
      const currentPercentage = payload?.cartbox?.current_percentage
        ? payload?.cartbox?.current_percentage
        : 0;
      const grower = payload?.cartbox?.grower?._KEY
        ? payload?.cartbox?.grower?._KEY
        : '';

      const params = {
        payload: {
          params: {
            conditions: {
              custom: true,
              currentPercentage,
              filters: { grower: [grower] }
            },
            customer: payload.customer ? payload.customer : '',
            eta: payload.cartbox.eta_date ? payload.cartbox.eta_date : '',
            page: 1,
            pageSize: 120
          }
        },
        type: SEARCH_INVENTORY
      };
      yield put(
        actionsCustomBox.setCurrentPercentage({
          percentage: currentPercentage,
          box: payload.cartbox
        })
      );
      yield searchInventorySaga(params);

      yield put(actionsCustomBox.setResolved());
    } else {
      toast('An error ocurred', 'error');
      yield put(actionsCustomBox.setRejected());
    }
  } catch (e) {
    console.error('Error open box');
    yield put(actionsCustomBox.setRejected());
    toast(e, 'error');
  }
}

export function* addToCartSaga({
  payload
}: ReturnType<typeof actionsCustomBox.addToCart>): any {
  try {
    const key = payload?.key;
    const is_closing_time_product = payload?.is_closing_time_product;
    delete payload.key;
    delete payload.is_closing_time_product;

    const boxResponse = yield call(addToCart, payload);

    if (boxResponse && boxResponse.success) {
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'Fill box success', value: '' });
      }
      const box: Cartbox | undefined = boxResponse.results.carts.find(
        (box: Cartbox) =>
          box.items[0].variety._KEY === key &&
          box.eta_date === payload.arrival_date &&
          box.items[0].inventory._KEY === payload.invKey &&
          box.items[0].quantity === payload.quantity
      );

      if (box) {
        TrackEventMonitor.newEvent({
          props: {
            grower_key: box.grower._KEY,
            quantity: payload.quantity,
            arrival_date: payload.arrival_date,
            packing_date: payload.packing_date,
            inventory_key: payload.invKey,
            charge_date: box.charge_date,
            is_komet_product: box.grower.komet_intg,
            is_closing_time_product,
            consecutive: box.consecutive,
            cart_box_key: box._KEY,
            customer_key: box.customer._KEY
          },
          metadata: {
            name: 'ibf_add_to_cart'
          }
        });
      }

      yield put(
        actionsCheckout.checkoutProducts({
          products: boxResponse.results.carts,
          newCount: true
        })
      );
      yield put(actionsCheckout.setCheckoutInfo(boxResponse));
      //yield put(actionsCustomBox.setResolved());
    } else {
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'Fill box failed', value: '' });
      }

      Sentry.withScope(function(scope) {
        scope.setLevel(Severity.Error);
        Sentry.setExtra('Error on Add to cart', {
          payload,
          response: boxResponse
        });
        // The exception has the event level set by the scope (info).
        Sentry.captureException(new Error('Error on Add to cart'));
      });
      toast(boxResponse.err, 'error');
      if (boxResponse.err.includes('this inventory is no more avaliable')) {
        yield put(actionsProduct.filterProductNoAvailable({ key }));
      }

      yield put(actionsCustomBox.setRejected());
    }
  } catch (e) {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'Fill box failed', value: '' });
    }
    Sentry.withScope(function(scope) {
      scope.setLevel(Severity.Error);
      Sentry.setExtra('Error on Add to cart', {
        payload,
        error: e
      });
      // The exception has the event level set by the scope (info).
      Sentry.captureException(new Error('Error on Add to cart'));
    });
    yield put(actionsCustomBox.setRejected());
    toast('An error ocurred', 'error');
  }
}

export default function* AllSagas() {
  yield all([
    //Auth sagas
    takeEvery(SEARCH_INVENTORY, searchInventorySaga),
    takeEvery(ADD_TO_BOX, addToBoxSaga),
    takeEvery(ADD_TO_CART, addToCartSaga),
    takeEvery(OPEN_BOX, openBoxSaga),
    takeEvery(CLOSE_BOX, closeBoxSaga)
  ]);
}
