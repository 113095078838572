import { State } from '../../Models/State';
import { EventCartbox } from '../../Models/EventCartbox';

export const ADD_CARTBOX_EVENT = 'event/addCartboxEvent';
export const GET_EVENT_LIST = 'event/getEventList';
export const UPDATE_CARTBOX_EVENT = 'event/updateCartboxEvent';
export const SET_ACTIVE_EVENT_STATUS = 'event/setActiveEventStatus';
export const UPDATE_EVENT = 'event/updateEvent';
export const DELETE_EVENT = 'event/deleteEvent';

export interface EventState {
  state: State;
  donePopover: boolean;
  filterPopover: boolean;
  eventList: EventCartbox[];
  eventName: string;
  filteredSearchEvent?: string;
  tableHeaderColor?: string;
}
