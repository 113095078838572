export interface CustomerModel {
  _KEY?: string;
  office_phone?: string;
  state?: string;
  business?: string;
  street?: string;
  city?: string;
  personal_phone?: string;
  zipcode?: string;
  tax_id?: string;
  company_name?: string;
  actual_address?: string;
  stores_quantity?: number;
  employees_quantity?: number;
  spend_per_week?: string;
  place_order_plan?: string;
  signup_reason?: string;
  events_per_year?: string;
  hear_about_us_first_time?: string;
  website?: string;
  confirm_profile?: any;
  facebook_page?: string;
  mobile_phone?: string;
  instagram_page?: string;
  storefront_type?: string;
  checkboxConfirm?: string;
  last_login?: Date;
}

export enum Tier {
  TIER_1 = 'tier_1',
  TIER_2 = 'tier_2',
  TIER_3 = 'tier_3'
}

export interface CustomerAddress {
  _KEY?: string;
  address: string;
  state: string;
  city: string;
  zipcode: string;
  closedDays?: Array<string>;
}

export enum Actions {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE'
}

export type PropertiesStateZipcode = {
  name: string;
  codes: Array<string>;
};
export interface StateZipcode {
  [key: string]: PropertiesStateZipcode;
}
