import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { convertDate } from '../../../../Utils/Utils';
import { RootState } from '../../../../Store/Reducers';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { REFERRAL_MODAL } from '../../../../Store/Modal/Types';

type Props = {
  className?: string;
};

const ReferralsModal: React.FC<Props> = ({ className }) => {
  const { referralModal } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const { referrals } = useSelector(
    (state: RootState) => state.ReferralReducer
  );
  const dispatch = useDispatch();
  const toggle = () =>
    dispatch(actionsModal.setCloseModal({ modal: REFERRAL_MODAL }));

  const getRewardText = (item: any) => {
    switch (item.status) {
      case 'COMPLETED':
        return '$15';
      case 'INVALID':
        return '$0';
      default:
        return '';
    }
  };

  const getActionText = (item: any) => {
    switch (item.status) {
      case 'COMPLETED':
        return 'Ordered';
      case 'SIGNED':
        return 'Signed Up';
      case 'ACTIVE':
        return 'Emailed';
      case 'INVALID':
        return 'Sorry, already a customer';
      default:
        return '';
    }
  };

  const table = (
    <table className="table table-bordered table-striped">
      <thead>
        <tr>
          <th>Date</th>
          <th>Company name</th>
          <th>Action</th>
          <th>Reward</th>
        </tr>
      </thead>
      <tbody>
        {referrals.map((i: any) => (
          <tr key={i._KEY}>
            <td>{convertDate(i.date)}</td>
            <td>{i.company_name}</td>
            <td>{getActionText(i)}</td>
            <td>{getRewardText(i)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div>
      <Modal
        size="lg"
        isOpen={referralModal.open}
        toggle={toggle}
        className={className}>
        <ModalHeader toggle={toggle}>Referrals</ModalHeader>
        <ModalBody>
          {referrals.length > 0 ? table : <h3>You don't have referrals</h3>}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ReferralsModal;
