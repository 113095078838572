import React from 'react';
import ImageComponent from '../../ImageComponent/ImageComponent';
import Skeleton from 'react-loading-skeleton';
import { State } from '../../../../Models/State';
type Props = {
  image: string;
  image2: string;
  centimeters: string | number;
  bunches: string | number;
  name: string;
  state: string;
};
export default function BoxGrower({
  image,
  image2,
  centimeters,
  bunches,
  name,
  state
}: Props) {
  return (
    <div className="d-flex align-items-center bg-ibf-light-gray w-lg-50 border-top-bottom">
      {state === State.PENDING ? (
        <Skeleton className="w-lg-50" width={60} height={'50px'} />
      ) : (
        <ImageComponent
          width="60px"
          imageUrl={image}
          className="p-1"
          height={'60px'}
          onErrorUrl={image2}
        />
      )}

      <span className="mr-1 ml-2 mr-lg-5 ml-lg-5 ">
        {' '}
        {name} {centimeters}cm
      </span>
      <span className="pr-1 pr-lg-2 text-nowrap">{bunches} bunches</span>
    </div>
  );
}
