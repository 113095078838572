import Product from '../../Models/Product';
import Filter from '../../Models/Filter';
import { State } from '../../Models/State';
import ProductGroup, { ProductGroupByGrower } from '../../Models/ProductGroup';
import { TotalProductByDate } from '../../Models/Product';
export const GET_PRODUCT = 'product/getProducts';
export const GET_PRODUCT_DETAIL = 'product/getProductDetail';
export const UPDATE_PRODUCT_QUANTITY = 'product/updateProductQuantity';
export const ADD_PRODUCT = 'product/addProduct';
export const GET_PRODUCT_REPLACEMENTS = 'product/getProductReplacements';
export const SET_PRODUCT_REPLACEMENTS = 'product/setProductReplacements';
export const CLEAR_UNAVAILABLE_PRODUCTS = 'product/clearUnavailableProducts';
export const GET_TOTAL_PRODUCTS_BY_DATE = 'product/getTotalProductByDate';

export interface ProductState {
  products: Array<Product>;
  unavailableProducts: Array<Product>;
  favorites: { [key: string]: any };
  filters: Array<Filter>;
  currentPage: number;
  totalItems: number;
  totalPages: number;
  categoryList: any;
  numberWeek: number;
  productGroup: string;
  moreOptions: Product[];
  imageDetail: string;
  product: Product;
  productReplaces: Product[];
  date: number;
  productGroupSuggestions: ProductGroup[];
  productGroupSuggestionsBySearch: ProductGroup[];
  state: State;
  totalProductByDate: Array<TotalProductByDate>;
  productByProductGroup: Array<Product>;
  loadingProductSuggestion: boolean;
  productGroupByGrower: ProductGroupByGrower[];
  varietySuggestionsBySearch: Array<string>;
  loadingVarietySuggestion: boolean;
  listProductSuggestions: Array<Product>;
}
