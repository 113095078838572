import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { eventActions } from '../../../../Store/Event/Slice';
import { actionsModal } from '../../../../Store/Modal/Slice';
import * as ModalTypes from '../../../../Store/Modal/Types';
import { EVENT_NAME_MODAL } from '../../../../Store/Modal/Types';
import ButtonPickerColor from '../../ButtonPickerColor/ButtonPickerColor';
import { convertNumberDateEEELLLDD } from '../../../../Utils/Utils';
import './addAndEditEvent.scss';

type Props = {
  event: {
    boxCount: number;
    lastDate: number;
    name: string;
    color: string;
    eventKey: string;
  };
  index: number;
};

const EventEditRowComponent = ({ event, index }: Props) => {
  const [selectedColor, setSelectedColor] = useState(event.color ?? '#f99b9c');
  const dispatch = useDispatch();

  React.useEffect(() => {
    setSelectedColor(event.color);
  }, [event]);

  const updateColor = (color: string) => {
    setSelectedColor(color);
    dispatch(
      eventActions.updateEvent({ events: [{ _KEY: event.eventKey, color }] })
    );
  };

  const updateEventName = (
    tag_name: string,
    color: string,
    event_key: string
  ) => {
    dispatch(
      actionsModal.setOpenModal({
        modal: EVENT_NAME_MODAL,
        data: { isUpdate: true, event: { tag_name, color, event_key } }
      })
    );
  };

  const deleteModal = () => {
    const modalBody = (
      <span className="font-weight-bold">
        Are you sure you want to delete this event name(related boxes will
        change to "Not related to an event")? This can't be undone.
      </span>
    );

    const cancelClick = () => {
      dispatch(
        actionsModal.setCloseModal({
          modal: ModalTypes.CONFIRMATION_CHECKOUT_MODAL
        })
      );
    };

    const confirmAction = () => {
      dispatch(eventActions.deleteEvent({ event_key: event.eventKey }));
    };

    dispatch(
      actionsModal.setOpenModal({
        modal: ModalTypes.CONFIRMATION_CHECKOUT_MODAL,
        data: {
          body: modalBody,
          cancelButtonText: 'No, go back',
          confirmButtonText: 'Yes, delete',
          showCancelButton: true,
          confirmAction,
          cancelAction: cancelClick
        }
      })
    );
  };

  return (
    <div className="event-edit-row" key={event.eventKey}>
      <div className=" d-flex  align-items-center">
        <ButtonPickerColor
          setSelectedColor={(color: string) => {
            updateColor(color);
          }}
          index={index}
          selectedColor={selectedColor}
        />
        <span className="text-capitalize text-ellipsis">{event.name}</span>
      </div>
      <div className="d-flex flex-row flex-lg-column justify-content-end align-items-start">
        <button
          className="btn underline p-0 mr-1 mr-lg-0"
          onClick={() =>
            updateEventName(event.name, event.color, event.eventKey)
          }>
          Edit name
        </button>
        <button className="btn underline p-0 " onClick={deleteModal}>
          {' '}
          Delete
        </button>
      </div>

      <span className="event-edit-row-description">
        {event.boxCount} boxes related - This event automatically archives after
        the last delivery
        {event.lastDate
          ? `, now on ${convertNumberDateEEELLLDD(event.lastDate)}`
          : '.'}
      </span>
    </div>
  );
};

export default EventEditRowComponent;
