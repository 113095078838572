import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GrowInWeekComponent from './GrownInWeekComponent/GrowInWeekComponent';
import {
  countryFlag,
  getNumberofCard,
  hrefToObject,
  openStandingOrderQuotes,
  queryParamsToObject
} from '../../../../Utils/Utils';
import Product from '../../../../Models/Product';
import './productDetail.scss';
import ImageGallery from 'react-image-gallery';
// @ts-ignore
import ItemsCarousel from 'react-items-carousel';
import ProductComponent from '../ProductComponent';
import Environment from '../../../../Environment';
import ImageComponent from '../../../Shared/ImageComponent/ImageComponent';
import { useLocation, useHistory } from 'react-router-dom';
import ContactComponent from '../../../Shared/ContactComponent/ContactComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleRight,
  faArrowCircleLeft
} from '@fortawesome/pro-solid-svg-icons';
import { RootState } from '../../../../Store/Reducers';
import { actionsProduct } from '../../../../Store/Product/Slice';
import { State } from '../../../../Models/State';
import { actionsModal } from '../../../../Store/Modal/Slice';
import LoadingSpinner from '../../../Shared/LoadingSpinner';
import Growers from '../../../../Models/Growers';
import { CONFIRM_DELIVERY_DATE_MODAL } from '../../../../Store/Modal/Types';

function getProductId(): string {
  const parsed: any = queryParamsToObject(window.location.hash, ['id']);
  return parsed.id ? parsed.id : '';
}

const ProductDetailComponent = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [numberOfCard, setNumberOfCard] = useState(2);
  const chevronWidth = 40;

  const {
    product: ProductFromRedux,
    date,
    state,
    productReplaces
  } = useSelector((state: RootState) => state.ProductReducer);
  const { user, token } = useSelector((state: RootState) => state.AuthReducer);
  const product = useRef(ProductFromRedux);
  const [, setForceUpdate] = useState(Date.now());
  const images =
    product.current &&
    product.current?.photos &&
    product.current?.photos?.map((i) => {
      return {
        original: Environment.publicPath + i,
        thumbnail: Environment.publicPath + i
      };
    });

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'Viewed product page', value: '' });
    }

    product.current = ProductFromRedux;

    setForceUpdate(Date.now());
    return () => {
      product.current = new Product();
    };
  }, [ProductFromRedux]);

  useEffect(() => {
    const productId = getProductId();
    if (productId) {
      dispatch(
        actionsProduct.getProductDetail({
          variety: productId,
          date,
          customer: user.metadata.customer
        })
      );
    }
  }, [location, date, dispatch, user.metadata.customer]);

  useEffect(() => {
    function updateSize() {
      const elementsCard = getNumberofCard(window.innerWidth);
      setNumberOfCard(elementsCard);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    const { date: d }: { [key: string]: any } = hrefToObject();
    if (!date && !d) {
      dispatch(
        actionsModal.setOpenModal({ modal: CONFIRM_DELIVERY_DATE_MODAL })
      );
    }
  }, [dispatch, date, location]);

  useEffect(() => {}, [date, dispatch, token, user.metadata.customer]);

  useEffect(() => {
    return () => {
      dispatch(
        actionsProduct.setProductDetail({
          product: new Product(),
          imageDetail: '',
          productGroup: '',
          productReplaces: []
        })
      );
    };
  }, [dispatch]);

  return (
    <>
      {state !== State.PENDING ? (
        <div className="container-fluid">
          <div className="d-flex mt-3 mt-md-0 align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column flex-lg-row">
                <span className="product-detail-title mr-1">
                  <span
                    className="pointer ibf-text-pink"
                    onClick={() => {
                      history.goBack();
                    }}>
                    <FontAwesomeIcon icon={faArrowCircleLeft} />{' '}
                    <span className="underline">Back</span>
                  </span>{' '}
                  / Home / Flowers / {product.current?.product_group?.name} /{' '}
                </span>{' '}
                <b className="product-detail-name">
                  {' ' + product.current?.name}
                </b>
              </div>
            </div>
            <ContactComponent className="ml-auto" />
          </div>
          <div className="row justify-content-around mt-3">
            <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
              {images?.length > 1 ? (
                <ImageGallery
                  items={images}
                  additionalClass={'w-100 product-detail-img'}
                  thumbnailPosition="left"
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showNav={false}
                />
              ) : (
                <div className="image-gallery-slide-wrapper left">
                  <div className="image-gallery-slide center">
                    <ImageComponent
                      imageUrl={`${Environment.publicPath}${product.current
                        ?.photos && product.current?.photos[0]}`}
                      className="img-fluid image-gallery-image"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-lg-6">
              <div className="d-flex flex-column border rounded  my-3">
                <div className="mb-3">
                  <ProductComponent
                    product={product.current}
                    replaces={productReplaces}
                    isAList={false}
                    isDetailForm
                    setCustomizableProduct={(custom) => {}}
                  />
                </div>
              </div>
              <div className="d-flex flex-column border rounded p-3">
                <div className="mb-3">
                  <GrowInWeekComponent product={product.current} />
                </div>
              </div>
              <span
                className="underline pointer mt-2"
                onClick={() => openStandingOrderQuotes(true)}>
                Request standing order price{' '}
                <FontAwesomeIcon icon={faAngleDoubleRight} />
              </span>
            </div>
          </div>

          <div
            id="substitutes"
            className={`${
              productReplaces.length === 0
                ? 'd-none'
                : 'd-flex flex-column mt-5 pb-3'
            }`}>
            <h4 className="product-detail-subtext">
              Substitutes for {product.current?.name?.split(' ')[0]}:
            </h4>

            <div style={{ padding: `0 ${chevronWidth}px` }}>
              <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={numberOfCard}
                gutter={30}
                rightChevron={
                  <button className="btn carousel-button">
                    <span className="d-flex justify-content-center carousel-arrow">
                      {'>'}
                    </span>
                  </button>
                }
                leftChevron={
                  <button className="btn carousel-button">
                    <span className="d-flex justify-content-center carousel-arrow">
                      {'<'}
                    </span>
                  </button>
                }
                outsideChevron={false}
                chevronWidth={chevronWidth}>
                {productReplaces.map((product: Product, index: number) => (
                  <div
                    key={product.key}
                    className="d-flex mx-auto ibf-card-sm ibf-card-width-carousel">
                    <ProductComponent
                      product={product}
                      isAList={false}
                      isDetailView
                      index={index}
                      setCustomizableProduct={(customizable: boolean) => {
                        if (product.customizable !== customizable) {
                          dispatch(
                            actionsProduct.setCustomizableProduct({
                              product,
                              customizable
                            })
                          );
                        }
                      }}
                    />
                  </div>
                ))}
              </ItemsCarousel>
            </div>
          </div>

          <div className="d-flex flex-column mt-5 pb-3">
            <h4 className="product-detail-subtext">
              {product &&
                product.current?.name &&
                product.current?.name.split(' ')[0]}{' '}
              is grown at these growers:
            </h4>
            <div className="d-flex flex-wrap align-items-start h-100">
              {product &&
                product.current?.growers &&
                product.current?.growers.map(
                  (grower: Growers, index: number) => (
                    <div className="growers-flag" key={'grower_' + index}>
                      <img
                        src={countryFlag(grower.country)}
                        className="img-fluid"
                        alt=""
                      />{' '}
                      {grower.name}
                    </div>
                  )
                )}
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner message={'LOADING'} height={'60vh'} />
      )}
    </>
  );
};

export default ProductDetailComponent;
