import React, { useCallback, useReducer, useRef } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { authReducer, customerReducer } from '../../../../Store/Selectors';
import Select from 'react-select';
import { companyInformationOptions } from '../../../../Utils/Utils';
import { SubmitHandler, useForm } from 'react-hook-form';
import { State } from '../../../../Models/State';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { TrackEventMonitor } from '../../../../Classes/TrackEvent';
import { actionsCustomer } from '../../../../Store/Customer/Slice';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { CONFIRM_PROFILE_MODAL } from '../../../../Store/Modal/Types';
// import ClosedDays from '../../ClosedDays';

const validationSelectMessage = (
  <span className="ibf-font-12 color-red">Please choose an option.</span>
);

const validationCheckboxMessage = (
  <span className="ibf-font-12 color-red">
    Please select this checkbox if the info is correct.
  </span>
);

type ConfirmProfileForm = {
  business: string;
  events_per_year: string;
  employees_quantity: number;
  storefront_type: string;
  facebook_page?: string;
  website?: string;
  instagram_page?: string;
  checkbox?: boolean;
  availableDay?: boolean;
  stores_quantity: number;
};

const initialState: ConfirmProfileForm = {
  business: '',
  events_per_year: '',
  employees_quantity: 0,
  storefront_type: '',
  stores_quantity: 0
};

enum Types {
  ADD_FORM
}

function reducer(
  state: ConfirmProfileForm,
  { type, payload }: { type: Types; payload: any }
) {
  switch (type) {
    case Types.ADD_FORM:
      return {
        ...state,
        [payload.name]: payload.value
      };
    default:
      throw new Error();
  }
}

const selectValidationStyle = () => {
  return {
    container: (base: any) => ({
      ...base,
      backgroundColor: 'red',
      padding: 1,
      borderRadius: '4px'
    })
  };
};

const ConfirmProfileModal = () => {
  const {
    token,
    user: {
      first_name,
      metadata: {
        main_user_first_name,
        customer_business,
        customer,
        guest,
        customer_name,
        customer_events_per_year,
        customer_store_type,
        customer_stores_quantity,
        customer_employees_quantity,
        from_manager
      }
    }
  } = useSelector(authReducer);
  const checkboxConfirm = useRef<HTMLInputElement>(null);
  const { state: customerState } = useSelector(customerReducer);
  // const [closedDays, setClosedDays] = useState(['']);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    clearErrors
  } = useForm<ConfirmProfileForm>();

  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const dispatch = useDispatch();
  const dispatchForm = useCallback(
    (name: keyof ConfirmProfileForm, value: string | number) => {
      clearErrors();
      dispatchLocal({ type: Types.ADD_FORM, payload: { name, value } });
    },
    [clearErrors]
  );

  React.useEffect(() => {
    if (getSelectValue(companyInformationOptions.business, customer_business)) {
      dispatchForm('business', customer_business);
    }

    if (
      getSelectValue(companyInformationOptions.storefront, customer_store_type)
    ) {
      dispatchForm('storefront_type', customer_store_type);
    }

    if (
      getSelectValue(companyInformationOptions.events, customer_events_per_year)
    ) {
      dispatchForm('events_per_year', customer_events_per_year);
    }

    if (customer_employees_quantity) {
      dispatchForm('employees_quantity', customer_employees_quantity);
    }
  }, [
    customer_business,
    setValue,
    dispatchForm,
    customer_store_type,
    customer_events_per_year,
    customer_employees_quantity
  ]);

  const getSelectValue = (
    options: { label: string; value: string | number }[],
    value: string
  ) => {
    return options.find((option) => option.value === value);
  };

  const onSubmit: SubmitHandler<ConfirmProfileForm> = async (data) => {
    let isError = false;

    Object.keys(state).forEach((stateKey) => {
      if (
        (stateKey as keyof ConfirmProfileForm) !== 'stores_quantity' &&
        !state[stateKey as keyof ConfirmProfileForm]
      ) {
        isError = true;
        setError(stateKey as keyof ConfirmProfileForm, {
          type: 'manual',
          message: `Please select the ${stateKey}`
        });
      }
    });

    if (
      !state.stores_quantity &&
      state.storefront_type !== 'Home-based studio'
    ) {
      setError('stores_quantity', {
        type: 'manual',
        message: 'Please select stores quantity'
      });
      return;
    }

    if (!checkboxConfirm?.current?.checked) {
      isError = true;
      setError('checkbox', {
        type: 'manual',
        message: `Please select the checkbox`
      });
    }

    if (!isError) {
      const customerObj = {
        ...state,
        ...data
        // closed_days: closedDays.toString()
      };

      TrackEventMonitor.newEvent({
        props: {
          ...customerObj,
          guest,
          update_place: 'confirm profile modal',
          prev_customer_info: {
            business: customer_business,
            events_per_year: customer_events_per_year,
            employees_quantity: customer_employees_quantity,
            storefront_type: customer_store_type,
            stores_quantity: customer_stores_quantity
          }
        },
        metadata: { name: 'ibf_company_update' }
      });

      dispatch(
        actionsCustomer.updateCustomer({
          customer: { ...customerObj, _KEY: customer },
          token,
          reload: true
        })
      );
    }
  };

  return (
    <Modal isOpen size="lg">
      <ModalBody>
        <div className="d-flex justify-content-between align-items-center">
          <h2>Dear {main_user_first_name ?? first_name ?? customer_name},</h2>
          {from_manager && (
            <div
              className="pointer ibf-font-30 text-black"
              onClick={() =>
                dispatch(
                  actionsModal.setCloseModal({ modal: CONFIRM_PROFILE_MODAL })
                )
              }>
              x
            </div>
          )}
        </div>
        <h4>
          Please confirm your info, as the info we're sending depends on this:
        </h4>

        <div className="d-flex flex-column">
          <span>Your business type and number of events a year are: </span>

          <div
            className="d-flex flex-column flex-lg-row align-items-lg-center mb-2"
            style={{ columnGap: '5px' }}>
            <div className="flex-grow-1">
              <Select
                id="business"
                name="business"
                placeholder={'--- Select the business type'}
                className="flex-grow-1 mb-2 mb-lg-0"
                onChange={(e) => dispatchForm('business', e?.value ?? '')}
                defaultValue={
                  getSelectValue(
                    companyInformationOptions.business,
                    customer_business
                  ) ?? null
                }
                styles={
                  !state.business && errors.business
                    ? selectValidationStyle()
                    : {}
                }
                options={companyInformationOptions.business
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((business) => ({
                    value: business.value,
                    label: business.label
                  }))}
              />
              {errors.business && validationSelectMessage}
              {errors?.events_per_year && !errors.business && (
                <span className="text-white">Text</span>
              )}
            </div>

            <div className="flex-grow-1">
              <Select
                id="events_per_year"
                name="events_per_year"
                defaultValue={
                  getSelectValue(
                    companyInformationOptions.events,
                    customer_events_per_year
                  ) ?? null
                }
                placeholder={'--- Select the number of events a year'}
                className="flex-grow-1"
                onChange={(e) =>
                  dispatchForm('events_per_year', e?.value ?? '')
                }
                styles={
                  !state.events_per_year && errors.events_per_year
                    ? selectValidationStyle()
                    : {}
                }
                options={companyInformationOptions.events
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((event) => ({
                    value: event.value,
                    label: event.label
                  }))}
              />
              {errors?.events_per_year && validationSelectMessage}
            </div>
          </div>

          <div
            className="d-flex flex-column flex-lg-row"
            style={{ columnGap: '5px' }}>
            <div className="d-flex flex-column mb-2 w-lg-50">
              <Select
                id="storefront_type"
                name="storefront_type"
                defaultValue={
                  getSelectValue(
                    companyInformationOptions.storefront,
                    customer_store_type
                  ) ?? null
                }
                placeholder="--- My business is a..."
                onChange={(e) => {
                  if (e?.value === 'Home-based studio') {
                    clearErrors('stores_quantity');
                  }

                  dispatchForm('storefront_type', e?.value ?? '');
                }}
                styles={
                  !state.storefront_type && errors.storefront_type
                    ? selectValidationStyle()
                    : {}
                }
                options={companyInformationOptions.storefront
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((store) => ({
                    value: store.value,
                    label: store.label
                  }))}
              />
              {errors?.storefront_type && validationSelectMessage}
            </div>

            {state.storefront_type !== 'Home-based studio' && (
              <div className="flex-grow-1">
                <Select
                  id="stores_quantity"
                  name="stores_quantity"
                  className="mt-2 mt-lg-0"
                  placeholder={'-- Select the number of stores'}
                  onChange={(e) =>
                    dispatchForm('stores_quantity', e?.value ?? '')
                  }
                  styles={
                    !state.stores_quantity && errors.stores_quantity
                      ? selectValidationStyle()
                      : {}
                  }
                  options={companyInformationOptions.stores_quantity}
                />
                {errors?.stores_quantity && validationSelectMessage}
              </div>
            )}
          </div>
          <div className={`d-flex flex-column mb-3`}>
            <span>
              How many people are working in your business (owners and employees
              in total)?*
            </span>
            <div className="d-flex flex-wrap">
              {companyInformationOptions.employees.map((option, index) => (
                <div className="form-check mr-3" key={index}>
                  <input
                    className="form-check-input input-radio-transform"
                    type="radio"
                    name={option.value.toString()}
                    id={option.value.toString()}
                    onChange={(event) =>
                      dispatchForm(
                        'employees_quantity',
                        parseInt(event.target.name)
                      )
                    }
                    checked={state.employees_quantity === option.value}
                  />
                  <label
                    className="normal-register-label"
                    htmlFor={option.value.toString()}>
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
            {errors?.employees_quantity && validationCheckboxMessage}
          </div>
          {/*<ClosedDays returnClosedDays={(data) => setClosedDays(data)} />*/}
          <div
            className="d-flex flex-column flex-lg-row"
            style={{ columnGap: '5px' }}>
            <div className="form-group input-register flex-grow-1 mt-1">
              <Input
                id={'website'}
                defaultValue={''}
                type="text"
                placeholder=" "
                {...register('website')}
              />
              <label htmlFor="website" className="register-label">
                Your website address (optional)
              </label>
            </div>

            <div className="form-group input-register flex-grow-1 mt-1">
              <Input
                type="text"
                id={'facebook_page'}
                defaultValue={''}
                placeholder=" "
                {...register('facebook_page')}
              />
              <label htmlFor="facebook_page" className="register-label">
                Your facebook address (optional)
              </label>
            </div>
          </div>

          <div className="form-group input-register mt-1 w-lg-50">
            <Input
              id={'instagram_page'}
              type="text"
              placeholder=" "
              {...register('instagram_page')}
              defaultValue={''}
            />
            <label htmlFor="instagram_page" className="register-label">
              Your Instagram address (optional)
            </label>
          </div>

          <FormGroup check className={'mb-2 d-flex flex-column'}>
            <Label check>
              <Input
                type="checkbox"
                onChange={(evt) => evt.currentTarget.checked && clearErrors()}
                innerRef={checkboxConfirm}
              />{' '}
              Yes, this info is correct, and I agree to all{' '}
              <a
                href="https://help.ibuyflowers.com/knowledge/term-and-conditions"
                target="_blank"
                rel="noopener noreferrer">
                <u className="text-dark">general terms</u>
              </a>{' '}
              (including communication, claims, security, and delivery terms).
            </Label>
            {errors.checkbox && validationCheckboxMessage}
          </FormGroup>

          <Button
            disabled={customerState === State.PENDING}
            color={'primary'}
            type={'button'}
            onClick={() => handleSubmit(onSubmit)()}
            className="w-lg-50">
            {customerState === State.PENDING && (
              <FontAwesomeIcon icon={faSpinner} pulse className="mr-2" />
            )}
            Save and close
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmProfileModal;
