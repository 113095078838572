import { State } from '../../Models/State';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckoutState, ProductsByEventName } from './Types';
import { OrderRequest } from '../../Models/OrderRequest';
import { RemoveCustomBoxParams } from '../../Models/RemoveCustomBoxParams';
import { Cartbox } from '../../Models/Cartbox';

const { IDLE, REJECTED, RESOLVED, PENDING } = State;

const initialState: CheckoutState = {
  checkoutInfo: {},
  orderRequests: [],
  products: [],
  incompleteProducts: [],
  itemsInCart: 0,
  chargeDatesCheckout: [],
  deliveryDatesCheckout: [],
  state: IDLE,
  productsByEventName: {}
};

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    getCheckoutInfoByCustomer(
      state,
      { payload }: PayloadAction<{ customer: string }>
    ) {
      state.state = PENDING;
    },
    removeFromCustomBox(
      state,
      { payload }: PayloadAction<RemoveCustomBoxParams>
    ) {
      state.state = PENDING;
    },
    removeFromBox(
      state,
      {
        payload
      }: PayloadAction<{
        customer: string;
        cartboxes: string[];
        slItemKey?: string;
        invKey?: string;
      }>
    ) {
      state.state = PENDING;
    },
    setCheckoutInfo(state, { payload }: PayloadAction<any>) {
      state.state = RESOLVED;
      state.checkoutInfo = payload;
    },
    checkoutProducts(state, { payload }: PayloadAction<any>) {
      state.state = PENDING;
    },
    checkShoppingList(state) {
      state.state = PENDING;
    },
    setCheckoutProducts(
      state,
      {
        payload
      }: PayloadAction<{
        products: Cartbox[];
        itemsInCart: number;
        productsByEventName: ProductsByEventName;
        chargeDatesCheckout: { charge_date: number; closing_time: string }[];
        deliveryDatesCheckout: { eta_date: number; closing_time: string }[];
      }>
    ) {
      state.state = RESOLVED;
      state.products = payload.products;
      state.productsByEventName = payload.productsByEventName;
      state.itemsInCart = payload.itemsInCart > 0 ? payload.itemsInCart : 0;
      state.chargeDatesCheckout = payload.chargeDatesCheckout;
      state.deliveryDatesCheckout = payload.deliveryDatesCheckout;
    },
    setProducts(
      state,
      {
        payload
      }: PayloadAction<{
        products: Cartbox[];
      }>
    ) {
      state.state = RESOLVED;
      state.products = payload.products;
    },

    setProductsByEventName(
      state,
      { payload }: PayloadAction<{ productsByEventName: ProductsByEventName }>
    ) {
      state.state = RESOLVED;
      state.productsByEventName = payload.productsByEventName;
      state.products = state.products.map((product) => {
        Object.keys(payload.productsByEventName).forEach((key) => {
          Object.keys(payload.productsByEventName[key].dates).forEach(
            (date) => {
              const eventProduct = payload.productsByEventName[key].dates[
                date
              ].find((prod) => prod._KEY === product._KEY);
              if (eventProduct) {
                product.event = eventProduct.event;
              }
            }
          );
        });

        return product;
      });
    },
    setOrderRequests(state, { payload }: PayloadAction<OrderRequest[]>) {
      state.state = RESOLVED;
      state.orderRequests = payload;
    },
    setIncompleteProducts(state, { payload }: PayloadAction<any[]>) {
      state.state = RESOLVED;
      state.incompleteProducts = payload;
    },
    setItemsInCart(state, { payload }: PayloadAction<number>) {
      state.state = RESOLVED;
      state.itemsInCart = payload > 0 ? payload : 0;
    },
    setRejected(state) {
      state.state = REJECTED;
    },
    setResolved(state) {
      state.state = RESOLVED;
    }
  }
});

export const actionsCheckout = slice.actions;
export default slice.reducer;
