import React, { useEffect } from 'react';
import MixpanelService from '../../../../Services/MixpanelService';

type Props = {
  title: string;
  width: string;
  height: string;
  src: string;
  className: string;
  id?: string;
};

const VideoComponent: React.FC<Props> = ({
  title,
  width,
  height,
  src,
  id,
  className
}) => {
  useEffect(() => {
    window.addEventListener('blur', () => {
      MixpanelService.track('video_start_on_startpage');
    });

    return () => {
      window.removeEventListener('blur', () => {});
    };
  }, []);

  return (
    <div className={className}>
      <iframe
        title={title}
        width={width}
        frameBorder="0"
        allowFullScreen
        height={height}
        src={src}>
        {' '}
      </iframe>
    </div>
  );
};

export default VideoComponent;
