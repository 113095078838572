import { StateZipcode } from '../Models/CustomerModel';
export default class ZipcodeService {
  static _STATES_WITH_ZIPCODES: StateZipcode = {
    MA: {
      name: 'Massachusetts',
      codes: [
        '01001',
        '01002',
        '01003',
        '01005',
        '01007',
        '01008',
        '01009',
        '01010',
        '01011',
        '01012',
        '01013',
        '01020',
        '01022',
        '01026',
        '01027',
        '01028',
        '01029',
        '01030',
        '01031',
        '01032',
        '01033',
        '01034',
        '01035',
        '01036',
        '01037',
        '01038',
        '01039',
        '01040',
        '01050',
        '01053',
        '01054',
        '01056',
        '01057',
        '01060',
        '01062',
        '01063',
        '01066',
        '01068',
        '01069',
        '01070',
        '01071',
        '01072',
        '01073',
        '01074',
        '01075',
        '01077',
        '01079',
        '01080',
        '01081',
        '01082',
        '01083',
        '01084',
        '01085',
        '01086',
        '01088',
        '01089',
        '01092',
        '01093',
        '01094',
        '01095',
        '01096',
        '01097',
        '01098',
        '01103',
        '01104',
        '01105',
        '01106',
        '01107',
        '01108',
        '01109',
        '01118',
        '01119',
        '01128',
        '01129',
        '01151',
        '01199',
        '01201',
        '01220',
        '01222',
        '01223',
        '01224',
        '01225',
        '01226',
        '01229',
        '01230',
        '01235',
        '01236',
        '01237',
        '01238',
        '01240',
        '01242',
        '01243',
        '01244',
        '01245',
        '01247',
        '01253',
        '01254',
        '01255',
        '01256',
        '01257',
        '01258',
        '01259',
        '01260',
        '01262',
        '01264',
        '01266',
        '01267',
        '01270',
        '01301',
        '01330',
        '01331',
        '01337',
        '01338',
        '01339',
        '01340',
        '01341',
        '01342',
        '01343',
        '01344',
        '01346',
        '01347',
        '01349',
        '01350',
        '01351',
        '01354',
        '01355',
        '01360',
        '01364',
        '01366',
        '01367',
        '01368',
        '01370',
        '01373',
        '01375',
        '01376',
        '01378',
        '01379',
        '01420',
        '01430',
        '01431',
        '01432',
        '01434',
        '01436',
        '01438',
        '01440',
        '01450',
        '01451',
        '01452',
        '01453',
        '01460',
        '01462',
        '01463',
        '01464',
        '01467',
        '01468',
        '01469',
        '01473',
        '01474',
        '01475',
        '01501',
        '01503',
        '01504',
        '01505',
        '01506',
        '01507',
        '01510',
        '01515',
        '01516',
        '01518',
        '01519',
        '01520',
        '01521',
        '01522',
        '01523',
        '01524',
        '01525',
        '01527',
        '01529',
        '01531',
        '01532',
        '01534',
        '01535',
        '01536',
        '01537',
        '01540',
        '01541',
        '01542',
        '01543',
        '01545',
        '01550',
        '01560',
        '01561',
        '01562',
        '01564',
        '01566',
        '01568',
        '01569',
        '01570',
        '01571',
        '01581',
        '01583',
        '01585',
        '01588',
        '01590',
        '01602',
        '01603',
        '01604',
        '01605',
        '01606',
        '01607',
        '01608',
        '01609',
        '01610',
        '01611',
        '01612',
        '01701',
        '01702',
        '01718',
        '01719',
        '01720',
        '01721',
        '01730',
        '01731',
        '01740',
        '01741',
        '01742',
        '01745',
        '01746',
        '01747',
        '01748',
        '01749',
        '01752',
        '01754',
        '01756',
        '01757',
        '01760',
        '01770',
        '01772',
        '01773',
        '01775',
        '01776',
        '01778',
        '01801',
        '01803',
        '01810',
        '01821',
        '01824',
        '01826',
        '01827',
        '01830',
        '01832',
        '01833',
        '01834',
        '01835',
        '01840',
        '01841',
        '01843',
        '01844',
        '01845',
        '01850',
        '01851',
        '01852',
        '01854',
        '01860',
        '01862',
        '01863',
        '01864',
        '01867',
        '01876',
        '01879',
        '01880',
        '01886',
        '01887',
        '01890',
        '01901',
        '01902',
        '01904',
        '01905',
        '01906',
        '01907',
        '01908',
        '01913',
        '01915',
        '01921',
        '01922',
        '01923',
        '01929',
        '01930',
        '01937',
        '01938',
        '01940',
        '01944',
        '01945',
        '01949',
        '01950',
        '01951',
        '01952',
        '01960',
        '01966',
        '01969',
        '01970',
        '01982',
        '01983',
        '01984',
        '01985',
        '02019',
        '02021',
        '02025',
        '02026',
        '02030',
        '02032',
        '02035',
        '02038',
        '02043',
        '02045',
        '02047',
        '02048',
        '02050',
        '02052',
        '02053',
        '02054',
        '02056',
        '02061',
        '02062',
        '02066',
        '02067',
        '02071',
        '02072',
        '02081',
        '02090',
        '02093',
        '02108',
        '02109',
        '02110',
        '02111',
        '02113',
        '02114',
        '02115',
        '02116',
        '02118',
        '02119',
        '02120',
        '02121',
        '02122',
        '02124',
        '02125',
        '02126',
        '02127',
        '02128',
        '02129',
        '02130',
        '02131',
        '02132',
        '02134',
        '02135',
        '02136',
        '02138',
        '02139',
        '02140',
        '02141',
        '02142',
        '02143',
        '02144',
        '02145',
        '02148',
        '02149',
        '02150',
        '02151',
        '02152',
        '02155',
        '02163',
        '02169',
        '02170',
        '02171',
        '02176',
        '02180',
        '02184',
        '02186',
        '02188',
        '02189',
        '02190',
        '02191',
        '02199',
        '02203',
        '02210',
        '02215',
        '02301',
        '02302',
        '02322',
        '02324',
        '02330',
        '02332',
        '02333',
        '02338',
        '02339',
        '02341',
        '02343',
        '02346',
        '02347',
        '02351',
        '02356',
        '02357',
        '02359',
        '02360',
        '02364',
        '02366',
        '02367',
        '02368',
        '02370',
        '02375',
        '02379',
        '02382',
        '02420',
        '02421',
        '02445',
        '02446',
        '02451',
        '02452',
        '02453',
        '02457',
        '02458',
        '02459',
        '02460',
        '02461',
        '02462',
        '02464',
        '02465',
        '02466',
        '02467',
        '02468',
        '02472',
        '02474',
        '02476',
        '02478',
        '02481',
        '02482',
        '02492',
        '02493',
        '02494',
        '02532',
        '02534',
        '02535',
        '02536',
        '02537',
        '02538',
        '02539',
        '02540',
        '02542',
        '02543',
        '02553',
        '02554',
        '02556',
        '02557',
        '02558',
        '02559',
        '02561',
        '02562',
        '02563',
        '02564',
        '02568',
        '02571',
        '02575',
        '02576',
        '02584',
        '02601',
        '02630',
        '02631',
        '02632',
        '02633',
        '02635',
        '02637',
        '02638',
        '02639',
        '02641',
        '02642',
        '02643',
        '02644',
        '02645',
        '02646',
        '02647',
        '02648',
        '02649',
        '02650',
        '02651',
        '02652',
        '02653',
        '02655',
        '02657',
        '02659',
        '02660',
        '02663',
        '02664',
        '02666',
        '02667',
        '02668',
        '02669',
        '02670',
        '02671',
        '02672',
        '02673',
        '02675',
        '02702',
        '02703',
        '02713',
        '02715',
        '02717',
        '02718',
        '02719',
        '02720',
        '02721',
        '02723',
        '02724',
        '02725',
        '02726',
        '02738',
        '02739',
        '02740',
        '02743',
        '02744',
        '02745',
        '02746',
        '02747',
        '02748',
        '02760',
        '02762',
        '02763',
        '02764',
        '02766',
        '02767',
        '02769',
        '02770',
        '02771',
        '02777',
        '02779',
        '02780',
        '02790',
        '02791'
      ]
    },
    RI: {
      name: 'Rhode Island',
      codes: [
        '02802',
        '02804',
        '02806',
        '02807',
        '02808',
        '02809',
        '02812',
        '02813',
        '02814',
        '02815',
        '02816',
        '02817',
        '02818',
        '02822',
        '02825',
        '02826',
        '02827',
        '02828',
        '02830',
        '02831',
        '02832',
        '02833',
        '02835',
        '02836',
        '02837',
        '02838',
        '02839',
        '02840',
        '02841',
        '02842',
        '02852',
        '02857',
        '02858',
        '02859',
        '02860',
        '02861',
        '02863',
        '02864',
        '02865',
        '02871',
        '02872',
        '02873',
        '02874',
        '02875',
        '02876',
        '02878',
        '02879',
        '02881',
        '02882',
        '02885',
        '02886',
        '02888',
        '02889',
        '02891',
        '02892',
        '02893',
        '02894',
        '02895',
        '02896',
        '02898',
        '02903',
        '02904',
        '02905',
        '02906',
        '02907',
        '02908',
        '02909',
        '02910',
        '02911',
        '02912',
        '02914',
        '02915',
        '02916',
        '02917',
        '02919',
        '02920',
        '02921'
      ]
    },
    NH: {
      name: 'New Hampshire',
      codes: [
        '03031',
        '03032',
        '03033',
        '03034',
        '03036',
        '03037',
        '03038',
        '03042',
        '03043',
        '03044',
        '03045',
        '03046',
        '03047',
        '03048',
        '03049',
        '03051',
        '03052',
        '03053',
        '03054',
        '03055',
        '03057',
        '03060',
        '03062',
        '03063',
        '03064',
        '03070',
        '03071',
        '03076',
        '03077',
        '03079',
        '03082',
        '03084',
        '03086',
        '03087',
        '03101',
        '03102',
        '03103',
        '03104',
        '03106',
        '03109',
        '03110',
        '03215',
        '03216',
        '03217',
        '03218',
        '03220',
        '03221',
        '03222',
        '03223',
        '03224',
        '03225',
        '03226',
        '03227',
        '03229',
        '03230',
        '03231',
        '03233',
        '03234',
        '03235',
        '03237',
        '03238',
        '03240',
        '03241',
        '03242',
        '03243',
        '03244',
        '03245',
        '03246',
        '03249',
        '03251',
        '03253',
        '03254',
        '03255',
        '03256',
        '03257',
        '03258',
        '03259',
        '03260',
        '03261',
        '03262',
        '03263',
        '03264',
        '03266',
        '03268',
        '03269',
        '03273',
        '03275',
        '03276',
        '03278',
        '03279',
        '03280',
        '03281',
        '03282',
        '03284',
        '03285',
        '03287',
        '03290',
        '03291',
        '03293',
        '03301',
        '03303',
        '03304',
        '03307',
        '03431',
        '03440',
        '03441',
        '03442',
        '03443',
        '03444',
        '03445',
        '03446',
        '03447',
        '03448',
        '03449',
        '03450',
        '03451',
        '03452',
        '03455',
        '03456',
        '03457',
        '03458',
        '03461',
        '03462',
        '03464',
        '03465',
        '03466',
        '03467',
        '03470',
        '03561',
        '03570',
        '03574',
        '03575',
        '03576',
        '03579',
        '03580',
        '03581',
        '03582',
        '03583',
        '03584',
        '03585',
        '03586',
        '03588',
        '03590',
        '03592',
        '03593',
        '03595',
        '03597',
        '03598',
        '03601',
        '03602',
        '03603',
        '03604',
        '03605',
        '03607',
        '03608',
        '03609',
        '03740',
        '03741',
        '03743',
        '03745',
        '03746',
        '03748',
        '03750',
        '03751',
        '03752',
        '03753',
        '03754',
        '03755',
        '03765',
        '03766',
        '03768',
        '03770',
        '03771',
        '03773',
        '03774',
        '03777',
        '03779',
        '03780',
        '03781',
        '03782',
        '03784',
        '03785',
        '03801',
        '03809',
        '03810',
        '03811',
        '03812',
        '03813',
        '03814',
        '03816',
        '03817',
        '03818',
        '03819',
        '03820',
        '03823',
        '03824',
        '03825',
        '03826',
        '03827',
        '03830',
        '03832',
        '03833',
        '03835',
        '03836',
        '03837',
        '03838',
        '03839',
        '03840',
        '03841',
        '03842',
        '03844',
        '03845',
        '03846',
        '03847',
        '03848',
        '03849',
        '03850',
        '03851',
        '03852',
        '03853',
        '03854',
        '03855',
        '03856',
        '03857',
        '03858',
        '03860',
        '03861',
        '03862',
        '03864',
        '03865',
        '03867',
        '03868',
        '03869',
        '03870',
        '03871',
        '03872',
        '03873',
        '03874',
        '03875',
        '03878',
        '03882',
        '03883',
        '03884',
        '03885',
        '03886',
        '03887',
        '03890',
        '03894'
      ]
    },
    ME: {
      name: 'Maine',
      codes: [
        '03901',
        '03902',
        '03903',
        '03904',
        '03905',
        '03906',
        '03907',
        '03908',
        '03909',
        '03910',
        '03911',
        '04001',
        '04002',
        '04003',
        '04005',
        '04006',
        '04008',
        '04009',
        '04010',
        '04011',
        '04015',
        '04017',
        '04019',
        '04020',
        '04021',
        '04022',
        '04024',
        '04027',
        '04029',
        '04030',
        '04032',
        '04037',
        '04038',
        '04039',
        '04040',
        '04041',
        '04042',
        '04043',
        '04046',
        '04047',
        '04048',
        '04049',
        '04050',
        '04051',
        '04055',
        '04056',
        '04057',
        '04061',
        '04062',
        '04063',
        '04064',
        '04066',
        '04068',
        '04069',
        '04071',
        '04072',
        '04073',
        '04074',
        '04076',
        '04079',
        '04083',
        '04084',
        '04085',
        '04086',
        '04087',
        '04088',
        '04090',
        '04091',
        '04092',
        '04093',
        '04095',
        '04096',
        '04097',
        '04101',
        '04102',
        '04103',
        '04105',
        '04106',
        '04107',
        '04108',
        '04109',
        '04110',
        '04210',
        '04216',
        '04217',
        '04219',
        '04220',
        '04221',
        '04222',
        '04224',
        '04226',
        '04227',
        '04228',
        '04231',
        '04234',
        '04236',
        '04237',
        '04238',
        '04239',
        '04240',
        '04250',
        '04252',
        '04253',
        '04254',
        '04255',
        '04256',
        '04257',
        '04258',
        '04259',
        '04260',
        '04261',
        '04263',
        '04265',
        '04267',
        '04268',
        '04270',
        '04271',
        '04274',
        '04275',
        '04276',
        '04280',
        '04281',
        '04282',
        '04284',
        '04285',
        '04286',
        '04287',
        '04289',
        '04290',
        '04292',
        '04294',
        '04330',
        '04342',
        '04343',
        '04344',
        '04345',
        '04346',
        '04347',
        '04348',
        '04349',
        '04350',
        '04351',
        '04352',
        '04353',
        '04354',
        '04355',
        '04357',
        '04358',
        '04359',
        '04360',
        '04363',
        '04364',
        '04401',
        '04406',
        '04408',
        '04410',
        '04411',
        '04412',
        '04413',
        '04414',
        '04415',
        '04416',
        '04417',
        '04418',
        '04419',
        '04421',
        '04422',
        '04424',
        '04426',
        '04427',
        '04428',
        '04429',
        '04430',
        '04431',
        '04434',
        '04435',
        '04438',
        '04441',
        '04442',
        '04443',
        '04444',
        '04448',
        '04449',
        '04450',
        '04451',
        '04453',
        '04454',
        '04455',
        '04456',
        '04457',
        '04459',
        '04460',
        '04461',
        '04462',
        '04463',
        '04464',
        '04468',
        '04469',
        '04471',
        '04472',
        '04473',
        '04474',
        '04475',
        '04476',
        '04478',
        '04479',
        '04481',
        '04485',
        '04487',
        '04488',
        '04489',
        '04490',
        '04491',
        '04492',
        '04493',
        '04495',
        '04496',
        '04497',
        '04530',
        '04535',
        '04537',
        '04538',
        '04539',
        '04541',
        '04543',
        '04544',
        '04547',
        '04548',
        '04551',
        '04553',
        '04554',
        '04555',
        '04556',
        '04558',
        '04562',
        '04563',
        '04564',
        '04568',
        '04570',
        '04571',
        '04572',
        '04573',
        '04574',
        '04575',
        '04576',
        '04578',
        '04579',
        '04605',
        '04606',
        '04607',
        '04609',
        '04611',
        '04612',
        '04613',
        '04614',
        '04616',
        '04617',
        '04619',
        '04622',
        '04623',
        '04624',
        '04625',
        '04626',
        '04627',
        '04628',
        '04629',
        '04630',
        '04631',
        '04634',
        '04635',
        '04637',
        '04640',
        '04642',
        '04643',
        '04644',
        '04645',
        '04646',
        '04648',
        '04649',
        '04650',
        '04652',
        '04653',
        '04654',
        '04655',
        '04657',
        '04658',
        '04660',
        '04662',
        '04664',
        '04666',
        '04667',
        '04668',
        '04669',
        '04671',
        '04673',
        '04674',
        '04675',
        '04676',
        '04677',
        '04679',
        '04680',
        '04681',
        '04683',
        '04684',
        '04685',
        '04686',
        '04691',
        '04693',
        '04694',
        '04730',
        '04732',
        '04733',
        '04734',
        '04735',
        '04736',
        '04739',
        '04740',
        '04741',
        '04742',
        '04743',
        '04745',
        '04746',
        '04747',
        '04750',
        '04756',
        '04757',
        '04758',
        '04760',
        '04761',
        '04762',
        '04763',
        '04764',
        '04765',
        '04766',
        '04768',
        '04769',
        '04772',
        '04773',
        '04774',
        '04776',
        '04777',
        '04779',
        '04780',
        '04781',
        '04783',
        '04785',
        '04786',
        '04787',
        '04841',
        '04843',
        '04847',
        '04848',
        '04849',
        '04851',
        '04852',
        '04853',
        '04854',
        '04855',
        '04856',
        '04858',
        '04859',
        '04860',
        '04861',
        '04862',
        '04863',
        '04864',
        '04901',
        '04910',
        '04911',
        '04912',
        '04915',
        '04917',
        '04918',
        '04920',
        '04921',
        '04922',
        '04923',
        '04924',
        '04925',
        '04926',
        '04927',
        '04928',
        '04929',
        '04930',
        '04932',
        '04933',
        '04936',
        '04937',
        '04938',
        '04939',
        '04940',
        '04941',
        '04942',
        '04943',
        '04944',
        '04945',
        '04947',
        '04949',
        '04950',
        '04951',
        '04952',
        '04953',
        '04955',
        '04956',
        '04957',
        '04958',
        '04961',
        '04962',
        '04963',
        '04964',
        '04965',
        '04966',
        '04967',
        '04969',
        '04970',
        '04971',
        '04973',
        '04974',
        '04975',
        '04976',
        '04978',
        '04979',
        '04981',
        '04982',
        '04983',
        '04984',
        '04985',
        '04986',
        '04987',
        '04988',
        '04989',
        '04992'
      ]
    },
    VT: {
      name: 'Vermont',
      codes: [
        '05001',
        '05031',
        '05032',
        '05033',
        '05034',
        '05035',
        '05036',
        '05037',
        '05038',
        '05039',
        '05040',
        '05041',
        '05042',
        '05043',
        '05045',
        '05046',
        '05048',
        '05050',
        '05051',
        '05052',
        '05053',
        '05055',
        '05056',
        '05058',
        '05059',
        '05060',
        '05061',
        '05062',
        '05065',
        '05067',
        '05068',
        '05069',
        '05070',
        '05071',
        '05072',
        '05075',
        '05076',
        '05077',
        '05079',
        '05081',
        '05083',
        '05084',
        '05086',
        '05089',
        '05091',
        '05101',
        '05141',
        '05142',
        '05143',
        '05146',
        '05148',
        '05149',
        '05150',
        '05151',
        '05152',
        '05153',
        '05154',
        '05155',
        '05156',
        '05158',
        '05161',
        '05201',
        '05250',
        '05251',
        '05252',
        '05253',
        '05254',
        '05255',
        '05257',
        '05260',
        '05261',
        '05262',
        '05301',
        '05340',
        '05341',
        '05342',
        '05343',
        '05345',
        '05346',
        '05350',
        '05352',
        '05353',
        '05354',
        '05355',
        '05356',
        '05358',
        '05359',
        '05360',
        '05361',
        '05362',
        '05363',
        '05401',
        '05403',
        '05404',
        '05405',
        '05408',
        '05439',
        '05440',
        '05441',
        '05442',
        '05443',
        '05444',
        '05445',
        '05446',
        '05447',
        '05448',
        '05450',
        '05452',
        '05454',
        '05455',
        '05456',
        '05457',
        '05458',
        '05459',
        '05461',
        '05462',
        '05463',
        '05464',
        '05465',
        '05468',
        '05471',
        '05472',
        '05473',
        '05474',
        '05476',
        '05477',
        '05478',
        '05481',
        '05482',
        '05483',
        '05485',
        '05486',
        '05487',
        '05488',
        '05489',
        '05491',
        '05492',
        '05494',
        '05495',
        '05602',
        '05640',
        '05641',
        '05647',
        '05648',
        '05649',
        '05650',
        '05651',
        '05652',
        '05653',
        '05654',
        '05655',
        '05656',
        '05657',
        '05658',
        '05660',
        '05661',
        '05663',
        '05664',
        '05667',
        '05669',
        '05672',
        '05673',
        '05674',
        '05675',
        '05676',
        '05677',
        '05678',
        '05679',
        '05680',
        '05681',
        '05682',
        '05701',
        '05730',
        '05732',
        '05733',
        '05734',
        '05735',
        '05736',
        '05737',
        '05738',
        '05739',
        '05740',
        '05742',
        '05743',
        '05744',
        '05747',
        '05748',
        '05751',
        '05753',
        '05757',
        '05758',
        '05759',
        '05760',
        '05761',
        '05762',
        '05763',
        '05764',
        '05765',
        '05766',
        '05767',
        '05769',
        '05770',
        '05772',
        '05773',
        '05774',
        '05775',
        '05776',
        '05777',
        '05778',
        '05819',
        '05820',
        '05821',
        '05822',
        '05824',
        '05825',
        '05826',
        '05827',
        '05828',
        '05829',
        '05830',
        '05832',
        '05833',
        '05836',
        '05837',
        '05839',
        '05841',
        '05842',
        '05843',
        '05845',
        '05846',
        '05847',
        '05850',
        '05851',
        '05853',
        '05855',
        '05857',
        '05858',
        '05859',
        '05860',
        '05862',
        '05866',
        '05867',
        '05868',
        '05871',
        '05872',
        '05873',
        '05874',
        '05875',
        '05901',
        '05902',
        '05903',
        '05904',
        '05905',
        '05906',
        '05907'
      ]
    },
    CT: {
      name: 'Connecticut',
      codes: [
        '06001',
        '06002',
        '06010',
        '06013',
        '06016',
        '06018',
        '06019',
        '06020',
        '06021',
        '06022',
        '06023',
        '06024',
        '06026',
        '06027',
        '06029',
        '06031',
        '06032',
        '06033',
        '06035',
        '06037',
        '06039',
        '06040',
        '06042',
        '06043',
        '06051',
        '06052',
        '06053',
        '06057',
        '06058',
        '06059',
        '06060',
        '06061',
        '06062',
        '06063',
        '06065',
        '06066',
        '06067',
        '06068',
        '06069',
        '06070',
        '06071',
        '06073',
        '06074',
        '06076',
        '06078',
        '06081',
        '06082',
        '06084',
        '06085',
        '06088',
        '06089',
        '06090',
        '06091',
        '06092',
        '06093',
        '06095',
        '06096',
        '06098',
        '06103',
        '06105',
        '06106',
        '06107',
        '06108',
        '06109',
        '06110',
        '06111',
        '06112',
        '06114',
        '06117',
        '06118',
        '06119',
        '06120',
        '06160',
        '06226',
        '06231',
        '06232',
        '06234',
        '06235',
        '06237',
        '06238',
        '06239',
        '06241',
        '06242',
        '06243',
        '06247',
        '06248',
        '06249',
        '06250',
        '06254',
        '06255',
        '06256',
        '06259',
        '06260',
        '06262',
        '06263',
        '06264',
        '06266',
        '06268',
        '06269',
        '06277',
        '06278',
        '06279',
        '06280',
        '06281',
        '06282',
        '06320',
        '06330',
        '06331',
        '06332',
        '06333',
        '06334',
        '06335',
        '06336',
        '06339',
        '06340',
        '06350',
        '06351',
        '06353',
        '06354',
        '06355',
        '06357',
        '06359',
        '06360',
        '06365',
        '06370',
        '06371',
        '06373',
        '06374',
        '06375',
        '06376',
        '06377',
        '06378',
        '06379',
        '06380',
        '06382',
        '06384',
        '06385',
        '06387',
        '06389',
        '06401',
        '06403',
        '06405',
        '06409',
        '06410',
        '06412',
        '06413',
        '06414',
        '06415',
        '06416',
        '06417',
        '06418',
        '06419',
        '06420',
        '06422',
        '06423',
        '06424',
        '06426',
        '06437',
        '06438',
        '06441',
        '06442',
        '06443',
        '06444',
        '06447',
        '06450',
        '06451',
        '06455',
        '06456',
        '06457',
        '06460',
        '06461',
        '06467',
        '06468',
        '06469',
        '06470',
        '06471',
        '06472',
        '06473',
        '06475',
        '06477',
        '06478',
        '06479',
        '06480',
        '06481',
        '06482',
        '06483',
        '06484',
        '06488',
        '06489',
        '06492',
        '06498',
        '06510',
        '06511',
        '06512',
        '06513',
        '06514',
        '06515',
        '06516',
        '06517',
        '06518',
        '06519',
        '06524',
        '06525',
        '06604',
        '06605',
        '06606',
        '06607',
        '06608',
        '06610',
        '06611',
        '06612',
        '06614',
        '06615',
        '06702',
        '06704',
        '06705',
        '06706',
        '06708',
        '06710',
        '06712',
        '06716',
        '06750',
        '06751',
        '06752',
        '06754',
        '06755',
        '06756',
        '06757',
        '06758',
        '06759',
        '06762',
        '06763',
        '06770',
        '06776',
        '06777',
        '06778',
        '06779',
        '06782',
        '06783',
        '06784',
        '06785',
        '06786',
        '06787',
        '06790',
        '06791',
        '06793',
        '06794',
        '06795',
        '06796',
        '06798',
        '06801',
        '06804',
        '06807',
        '06810',
        '06811',
        '06812',
        '06820',
        '06824',
        '06825',
        '06830',
        '06831',
        '06840',
        '06850',
        '06851',
        '06853',
        '06854',
        '06855',
        '06856',
        '06870',
        '06877',
        '06878',
        '06880',
        '06883',
        '06890',
        '06896',
        '06897',
        '06901',
        '06902',
        '06903',
        '06905',
        '06906',
        '06907'
      ]
    },
    NY: {
      name: 'New York',
      codes: [
        '10001',
        '10002',
        '10003',
        '10004',
        '10005',
        '10006',
        '10007',
        '10009',
        '10010',
        '10011',
        '10012',
        '10013',
        '10014',
        '10016',
        '10017',
        '10018',
        '10019',
        '10020',
        '10021',
        '10022',
        '10023',
        '10024',
        '10025',
        '10026',
        '10027',
        '10028',
        '10029',
        '10030',
        '10031',
        '10032',
        '10033',
        '10034',
        '10035',
        '10036',
        '10037',
        '10038',
        '10039',
        '10040',
        '10044',
        '10065',
        '10069',
        '10075',
        '10103',
        '10110',
        '10111',
        '10112',
        '10115',
        '10119',
        '10128',
        '10152',
        '10153',
        '10154',
        '10162',
        '10165',
        '10167',
        '10168',
        '10169',
        '10170',
        '10171',
        '10172',
        '10173',
        '10174',
        '10177',
        '10199',
        '10271',
        '10278',
        '10279',
        '10280',
        '10282',
        '10301',
        '10302',
        '10303',
        '10304',
        '10305',
        '10306',
        '10307',
        '10308',
        '10309',
        '10310',
        '10311',
        '10312',
        '10314',
        '10451',
        '10452',
        '10453',
        '10454',
        '10455',
        '10456',
        '10457',
        '10458',
        '10459',
        '10460',
        '10461',
        '10462',
        '10463',
        '10464',
        '10465',
        '10466',
        '10467',
        '10468',
        '10469',
        '10470',
        '10471',
        '10472',
        '10473',
        '10474',
        '10475',
        '10501',
        '10502',
        '10503',
        '10504',
        '10505',
        '10506',
        '10507',
        '10509',
        '10510',
        '10511',
        '10512',
        '10514',
        '10516',
        '10517',
        '10518',
        '10519',
        '10520',
        '10522',
        '10523',
        '10524',
        '10526',
        '10527',
        '10528',
        '10530',
        '10532',
        '10533',
        '10535',
        '10536',
        '10537',
        '10538',
        '10541',
        '10543',
        '10545',
        '10546',
        '10547',
        '10548',
        '10549',
        '10550',
        '10552',
        '10553',
        '10560',
        '10562',
        '10566',
        '10567',
        '10570',
        '10573',
        '10576',
        '10577',
        '10578',
        '10579',
        '10580',
        '10583',
        '10588',
        '10589',
        '10590',
        '10591',
        '10594',
        '10595',
        '10596',
        '10597',
        '10598',
        '10601',
        '10603',
        '10604',
        '10605',
        '10606',
        '10607',
        '10701',
        '10703',
        '10704',
        '10705',
        '10706',
        '10707',
        '10708',
        '10709',
        '10710',
        '10801',
        '10803',
        '10804',
        '10805',
        '10901',
        '10910',
        '10911',
        '10913',
        '10914',
        '10915',
        '10916',
        '10917',
        '10918',
        '10919',
        '10920',
        '10921',
        '10922',
        '10923',
        '10924',
        '10925',
        '10926',
        '10927',
        '10928',
        '10930',
        '10931',
        '10932',
        '10933',
        '10940',
        '10941',
        '10950',
        '10952',
        '10953',
        '10954',
        '10956',
        '10958',
        '10960',
        '10962',
        '10963',
        '10964',
        '10965',
        '10968',
        '10969',
        '10970',
        '10973',
        '10974',
        '10975',
        '10976',
        '10977',
        '10979',
        '10980',
        '10983',
        '10984',
        '10985',
        '10986',
        '10987',
        '10988',
        '10989',
        '10990',
        '10992',
        '10993',
        '10994',
        '10996',
        '10998',
        '11001',
        '11003',
        '11004',
        '11005',
        '11010',
        '11020',
        '11021',
        '11023',
        '11024',
        '11030',
        '11040',
        '11042',
        '11050',
        '11096',
        '11101',
        '11102',
        '11103',
        '11104',
        '11105',
        '11106',
        '11109',
        '11201',
        '11203',
        '11204',
        '11205',
        '11206',
        '11207',
        '11208',
        '11209',
        '11210',
        '11211',
        '11212',
        '11213',
        '11214',
        '11215',
        '11216',
        '11217',
        '11218',
        '11219',
        '11220',
        '11221',
        '11222',
        '11223',
        '11224',
        '11225',
        '11226',
        '11228',
        '11229',
        '11230',
        '11231',
        '11232',
        '11233',
        '11234',
        '11235',
        '11236',
        '11237',
        '11238',
        '11239',
        '11351',
        '11354',
        '11355',
        '11356',
        '11357',
        '11358',
        '11359',
        '11360',
        '11361',
        '11362',
        '11363',
        '11364',
        '11365',
        '11366',
        '11367',
        '11368',
        '11369',
        '11370',
        '11371',
        '11372',
        '11373',
        '11374',
        '11375',
        '11377',
        '11378',
        '11379',
        '11385',
        '11411',
        '11412',
        '11413',
        '11414',
        '11415',
        '11416',
        '11417',
        '11418',
        '11419',
        '11420',
        '11421',
        '11422',
        '11423',
        '11424',
        '11425',
        '11426',
        '11427',
        '11428',
        '11429',
        '11430',
        '11432',
        '11433',
        '11434',
        '11435',
        '11436',
        '11451',
        '11501',
        '11507',
        '11509',
        '11510',
        '11514',
        '11516',
        '11518',
        '11520',
        '11530',
        '11542',
        '11545',
        '11547',
        '11548',
        '11549',
        '11550',
        '11552',
        '11553',
        '11554',
        '11556',
        '11557',
        '11558',
        '11559',
        '11560',
        '11561',
        '11563',
        '11565',
        '11566',
        '11568',
        '11569',
        '11570',
        '11572',
        '11575',
        '11576',
        '11577',
        '11579',
        '11580',
        '11581',
        '11590',
        '11596',
        '11598',
        '11691',
        '11692',
        '11693',
        '11694',
        '11697',
        '11701',
        '11702',
        '11703',
        '11704',
        '11705',
        '11706',
        '11709',
        '11710',
        '11713',
        '11714',
        '11715',
        '11716',
        '11717',
        '11718',
        '11719',
        '11720',
        '11721',
        '11722',
        '11724',
        '11725',
        '11726',
        '11727',
        '11729',
        '11730',
        '11731',
        '11732',
        '11733',
        '11735',
        '11738',
        '11739',
        '11740',
        '11741',
        '11742',
        '11743',
        '11746',
        '11747',
        '11749',
        '11751',
        '11752',
        '11753',
        '11754',
        '11755',
        '11756',
        '11757',
        '11758',
        '11762',
        '11763',
        '11764',
        '11765',
        '11766',
        '11767',
        '11768',
        '11769',
        '11770',
        '11771',
        '11772',
        '11776',
        '11777',
        '11778',
        '11779',
        '11780',
        '11782',
        '11783',
        '11784',
        '11786',
        '11787',
        '11788',
        '11789',
        '11790',
        '11791',
        '11792',
        '11793',
        '11794',
        '11795',
        '11796',
        '11797',
        '11798',
        '11801',
        '11803',
        '11804',
        '11901',
        '11930',
        '11931',
        '11932',
        '11933',
        '11934',
        '11935',
        '11937',
        '11939',
        '11940',
        '11941',
        '11942',
        '11944',
        '11946',
        '11947',
        '11948',
        '11949',
        '11950',
        '11951',
        '11952',
        '11953',
        '11954',
        '11955',
        '11956',
        '11957',
        '11958',
        '11959',
        '11960',
        '11961',
        '11962',
        '11963',
        '11964',
        '11965',
        '11967',
        '11968',
        '11970',
        '11971',
        '11972',
        '11973',
        '11975',
        '11976',
        '11977',
        '11978',
        '11980',
        '12007',
        '12008',
        '12009',
        '12010',
        '12015',
        '12017',
        '12018',
        '12019',
        '12020',
        '12022',
        '12023',
        '12024',
        '12025',
        '12027',
        '12028',
        '12029',
        '12031',
        '12032',
        '12033',
        '12035',
        '12036',
        '12037',
        '12040',
        '12041',
        '12042',
        '12043',
        '12045',
        '12046',
        '12047',
        '12051',
        '12052',
        '12053',
        '12054',
        '12056',
        '12057',
        '12058',
        '12059',
        '12060',
        '12061',
        '12062',
        '12063',
        '12064',
        '12065',
        '12066',
        '12067',
        '12068',
        '12069',
        '12070',
        '12071',
        '12072',
        '12074',
        '12075',
        '12076',
        '12077',
        '12078',
        '12083',
        '12084',
        '12085',
        '12086',
        '12087',
        '12089',
        '12090',
        '12092',
        '12093',
        '12094',
        '12095',
        '12106',
        '12108',
        '12110',
        '12115',
        '12116',
        '12117',
        '12118',
        '12120',
        '12121',
        '12122',
        '12123',
        '12124',
        '12125',
        '12130',
        '12131',
        '12132',
        '12134',
        '12136',
        '12137',
        '12138',
        '12139',
        '12140',
        '12143',
        '12144',
        '12147',
        '12148',
        '12149',
        '12150',
        '12151',
        '12153',
        '12154',
        '12155',
        '12156',
        '12157',
        '12158',
        '12159',
        '12160',
        '12161',
        '12164',
        '12165',
        '12166',
        '12167',
        '12168',
        '12169',
        '12170',
        '12172',
        '12173',
        '12174',
        '12175',
        '12176',
        '12177',
        '12180',
        '12182',
        '12183',
        '12184',
        '12185',
        '12186',
        '12187',
        '12188',
        '12189',
        '12190',
        '12192',
        '12193',
        '12194',
        '12195',
        '12196',
        '12197',
        '12198',
        '12202',
        '12203',
        '12204',
        '12205',
        '12206',
        '12207',
        '12208',
        '12209',
        '12210',
        '12211',
        '12222',
        '12302',
        '12303',
        '12304',
        '12305',
        '12306',
        '12307',
        '12308',
        '12309',
        '12401',
        '12404',
        '12405',
        '12406',
        '12407',
        '12409',
        '12410',
        '12411',
        '12412',
        '12413',
        '12414',
        '12416',
        '12417',
        '12418',
        '12419',
        '12420',
        '12421',
        '12422',
        '12423',
        '12424',
        '12427',
        '12428',
        '12429',
        '12430',
        '12431',
        '12432',
        '12433',
        '12434',
        '12435',
        '12436',
        '12438',
        '12439',
        '12440',
        '12441',
        '12442',
        '12443',
        '12444',
        '12446',
        '12448',
        '12449',
        '12450',
        '12451',
        '12452',
        '12453',
        '12454',
        '12455',
        '12456',
        '12457',
        '12458',
        '12459',
        '12460',
        '12461',
        '12463',
        '12464',
        '12465',
        '12466',
        '12468',
        '12469',
        '12470',
        '12471',
        '12472',
        '12473',
        '12474',
        '12475',
        '12477',
        '12480',
        '12481',
        '12482',
        '12483',
        '12484',
        '12485',
        '12486',
        '12487',
        '12489',
        '12490',
        '12491',
        '12492',
        '12493',
        '12494',
        '12495',
        '12496',
        '12498',
        '12501',
        '12502',
        '12503',
        '12504',
        '12507',
        '12508',
        '12512',
        '12513',
        '12514',
        '12515',
        '12516',
        '12517',
        '12518',
        '12520',
        '12521',
        '12522',
        '12523',
        '12524',
        '12525',
        '12526',
        '12527',
        '12528',
        '12529',
        '12530',
        '12531',
        '12533',
        '12534',
        '12538',
        '12540',
        '12542',
        '12543',
        '12545',
        '12546',
        '12547',
        '12548',
        '12549',
        '12550',
        '12553',
        '12561',
        '12563',
        '12564',
        '12565',
        '12566',
        '12567',
        '12569',
        '12570',
        '12571',
        '12572',
        '12574',
        '12575',
        '12577',
        '12578',
        '12580',
        '12581',
        '12582',
        '12583',
        '12585',
        '12586',
        '12589',
        '12590',
        '12592',
        '12594',
        '12601',
        '12603',
        '12604',
        '12701',
        '12719',
        '12720',
        '12721',
        '12722',
        '12723',
        '12724',
        '12725',
        '12726',
        '12729',
        '12732',
        '12733',
        '12734',
        '12736',
        '12737',
        '12738',
        '12740',
        '12741',
        '12742',
        '12743',
        '12745',
        '12746',
        '12747',
        '12748',
        '12749',
        '12750',
        '12751',
        '12752',
        '12754',
        '12758',
        '12759',
        '12760',
        '12762',
        '12763',
        '12764',
        '12765',
        '12766',
        '12767',
        '12768',
        '12769',
        '12770',
        '12771',
        '12775',
        '12776',
        '12777',
        '12778',
        '12779',
        '12780',
        '12781',
        '12783',
        '12784',
        '12785',
        '12786',
        '12787',
        '12788',
        '12789',
        '12790',
        '12791',
        '12792',
        '12801',
        '12803',
        '12804',
        '12808',
        '12809',
        '12810',
        '12811',
        '12812',
        '12814',
        '12815',
        '12816',
        '12817',
        '12819',
        '12821',
        '12822',
        '12823',
        '12824',
        '12827',
        '12828',
        '12831',
        '12832',
        '12833',
        '12834',
        '12835',
        '12836',
        '12837',
        '12838',
        '12839',
        '12841',
        '12842',
        '12843',
        '12844',
        '12845',
        '12846',
        '12847',
        '12849',
        '12850',
        '12851',
        '12852',
        '12853',
        '12855',
        '12856',
        '12857',
        '12858',
        '12859',
        '12860',
        '12861',
        '12862',
        '12863',
        '12864',
        '12865',
        '12866',
        '12870',
        '12871',
        '12872',
        '12873',
        '12874',
        '12878',
        '12883',
        '12884',
        '12885',
        '12886',
        '12887',
        '12901',
        '12903',
        '12910',
        '12911',
        '12912',
        '12913',
        '12914',
        '12916',
        '12917',
        '12918',
        '12919',
        '12920',
        '12921',
        '12922',
        '12923',
        '12924',
        '12926',
        '12927',
        '12928',
        '12929',
        '12930',
        '12932',
        '12933',
        '12934',
        '12935',
        '12936',
        '12937',
        '12939',
        '12941',
        '12942',
        '12943',
        '12944',
        '12945',
        '12946',
        '12950',
        '12952',
        '12953',
        '12955',
        '12956',
        '12957',
        '12958',
        '12959',
        '12960',
        '12961',
        '12962',
        '12964',
        '12965',
        '12966',
        '12967',
        '12969',
        '12970',
        '12972',
        '12973',
        '12974',
        '12975',
        '12976',
        '12977',
        '12978',
        '12979',
        '12980',
        '12981',
        '12983',
        '12985',
        '12986',
        '12987',
        '12989',
        '12992',
        '12993',
        '12996',
        '12997',
        '12998',
        '13020',
        '13021',
        '13024',
        '13026',
        '13027',
        '13028',
        '13029',
        '13030',
        '13031',
        '13032',
        '13033',
        '13034',
        '13035',
        '13036',
        '13037',
        '13039',
        '13040',
        '13041',
        '13042',
        '13044',
        '13045',
        '13051',
        '13052',
        '13053',
        '13054',
        '13057',
        '13060',
        '13061',
        '13062',
        '13063',
        '13064',
        '13066',
        '13068',
        '13069',
        '13071',
        '13072',
        '13073',
        '13074',
        '13076',
        '13077',
        '13078',
        '13080',
        '13081',
        '13082',
        '13083',
        '13084',
        '13087',
        '13088',
        '13090',
        '13092',
        '13101',
        '13102',
        '13103',
        '13104',
        '13108',
        '13110',
        '13111',
        '13112',
        '13113',
        '13114',
        '13115',
        '13116',
        '13117',
        '13118',
        '13120',
        '13122',
        '13123',
        '13124',
        '13126',
        '13131',
        '13132',
        '13134',
        '13135',
        '13136',
        '13138',
        '13140',
        '13141',
        '13142',
        '13143',
        '13144',
        '13145',
        '13146',
        '13147',
        '13148',
        '13152',
        '13153',
        '13155',
        '13156',
        '13157',
        '13158',
        '13159',
        '13160',
        '13162',
        '13163',
        '13164',
        '13165',
        '13166',
        '13167',
        '13202',
        '13203',
        '13204',
        '13205',
        '13206',
        '13207',
        '13208',
        '13209',
        '13210',
        '13211',
        '13212',
        '13214',
        '13215',
        '13219',
        '13224',
        '13290',
        '13301',
        '13302',
        '13303',
        '13304',
        '13305',
        '13308',
        '13309',
        '13310',
        '13312',
        '13313',
        '13314',
        '13315',
        '13316',
        '13317',
        '13318',
        '13319',
        '13320',
        '13321',
        '13322',
        '13323',
        '13324',
        '13325',
        '13326',
        '13327',
        '13328',
        '13329',
        '13331',
        '13332',
        '13333',
        '13334',
        '13335',
        '13337',
        '13338',
        '13339',
        '13340',
        '13341',
        '13342',
        '13343',
        '13345',
        '13346',
        '13348',
        '13350',
        '13352',
        '13353',
        '13354',
        '13355',
        '13357',
        '13360',
        '13361',
        '13362',
        '13363',
        '13364',
        '13365',
        '13367',
        '13368',
        '13402',
        '13403',
        '13404',
        '13406',
        '13407',
        '13408',
        '13409',
        '13410',
        '13411',
        '13413',
        '13415',
        '13416',
        '13417',
        '13418',
        '13420',
        '13421',
        '13424',
        '13425',
        '13428',
        '13431',
        '13433',
        '13435',
        '13436',
        '13437',
        '13438',
        '13439',
        '13440',
        '13441',
        '13450',
        '13452',
        '13454',
        '13456',
        '13459',
        '13460',
        '13461',
        '13464',
        '13468',
        '13469',
        '13470',
        '13471',
        '13472',
        '13473',
        '13475',
        '13476',
        '13477',
        '13478',
        '13480',
        '13483',
        '13484',
        '13485',
        '13486',
        '13488',
        '13489',
        '13490',
        '13491',
        '13492',
        '13493',
        '13494',
        '13495',
        '13501',
        '13502',
        '13601',
        '13602',
        '13603',
        '13605',
        '13606',
        '13607',
        '13608',
        '13612',
        '13613',
        '13614',
        '13615',
        '13616',
        '13617',
        '13618',
        '13619',
        '13620',
        '13621',
        '13622',
        '13623',
        '13624',
        '13625',
        '13626',
        '13628',
        '13630',
        '13633',
        '13634',
        '13635',
        '13636',
        '13637',
        '13638',
        '13639',
        '13640',
        '13641',
        '13642',
        '13643',
        '13646',
        '13647',
        '13648',
        '13650',
        '13651',
        '13652',
        '13654',
        '13655',
        '13656',
        '13658',
        '13659',
        '13660',
        '13661',
        '13662',
        '13664',
        '13665',
        '13666',
        '13667',
        '13668',
        '13669',
        '13670',
        '13672',
        '13673',
        '13674',
        '13675',
        '13676',
        '13677',
        '13678',
        '13679',
        '13680',
        '13681',
        '13682',
        '13684',
        '13685',
        '13687',
        '13690',
        '13691',
        '13692',
        '13693',
        '13694',
        '13695',
        '13696',
        '13697',
        '13730',
        '13731',
        '13732',
        '13733',
        '13734',
        '13736',
        '13739',
        '13740',
        '13743',
        '13744',
        '13746',
        '13748',
        '13750',
        '13751',
        '13752',
        '13753',
        '13754',
        '13755',
        '13756',
        '13757',
        '13760',
        '13774',
        '13775',
        '13776',
        '13777',
        '13778',
        '13780',
        '13782',
        '13783',
        '13784',
        '13786',
        '13787',
        '13788',
        '13790',
        '13794',
        '13795',
        '13796',
        '13797',
        '13801',
        '13802',
        '13803',
        '13804',
        '13806',
        '13807',
        '13808',
        '13809',
        '13810',
        '13811',
        '13812',
        '13813',
        '13815',
        '13820',
        '13825',
        '13826',
        '13827',
        '13830',
        '13832',
        '13833',
        '13834',
        '13835',
        '13838',
        '13839',
        '13841',
        '13842',
        '13843',
        '13844',
        '13845',
        '13846',
        '13847',
        '13849',
        '13850',
        '13856',
        '13859',
        '13860',
        '13861',
        '13862',
        '13863',
        '13864',
        '13865',
        '13901',
        '13902',
        '13903',
        '13904',
        '13905',
        '14001',
        '14004',
        '14005',
        '14006',
        '14008',
        '14009',
        '14011',
        '14012',
        '14013',
        '14020',
        '14024',
        '14025',
        '14026',
        '14028',
        '14030',
        '14031',
        '14032',
        '14033',
        '14034',
        '14035',
        '14036',
        '14037',
        '14039',
        '14040',
        '14041',
        '14042',
        '14043',
        '14047',
        '14048',
        '14051',
        '14052',
        '14054',
        '14055',
        '14057',
        '14058',
        '14059',
        '14060',
        '14061',
        '14062',
        '14063',
        '14065',
        '14066',
        '14067',
        '14068',
        '14069',
        '14070',
        '14072',
        '14075',
        '14080',
        '14081',
        '14082',
        '14085',
        '14086',
        '14091',
        '14092',
        '14094',
        '14098',
        '14101',
        '14102',
        '14103',
        '14105',
        '14108',
        '14109',
        '14111',
        '14112',
        '14113',
        '14120',
        '14125',
        '14126',
        '14127',
        '14129',
        '14130',
        '14131',
        '14132',
        '14134',
        '14135',
        '14136',
        '14138',
        '14139',
        '14141',
        '14143',
        '14145',
        '14150',
        '14167',
        '14168',
        '14169',
        '14170',
        '14171',
        '14172',
        '14173',
        '14174',
        '14201',
        '14202',
        '14203',
        '14204',
        '14206',
        '14207',
        '14208',
        '14209',
        '14210',
        '14211',
        '14212',
        '14213',
        '14214',
        '14215',
        '14216',
        '14217',
        '14218',
        '14219',
        '14220',
        '14221',
        '14222',
        '14223',
        '14224',
        '14225',
        '14226',
        '14227',
        '14228',
        '14261',
        '14301',
        '14302',
        '14303',
        '14304',
        '14305',
        '14411',
        '14414',
        '14415',
        '14416',
        '14418',
        '14420',
        '14422',
        '14423',
        '14424',
        '14425',
        '14427',
        '14428',
        '14432',
        '14433',
        '14435',
        '14437',
        '14441',
        '14445',
        '14450',
        '14454',
        '14456',
        '14462',
        '14464',
        '14466',
        '14467',
        '14468',
        '14469',
        '14470',
        '14471',
        '14472',
        '14475',
        '14476',
        '14477',
        '14478',
        '14479',
        '14480',
        '14481',
        '14482',
        '14485',
        '14486',
        '14487',
        '14489',
        '14502',
        '14504',
        '14505',
        '14506',
        '14507',
        '14510',
        '14511',
        '14512',
        '14513',
        '14514',
        '14516',
        '14517',
        '14519',
        '14521',
        '14522',
        '14525',
        '14526',
        '14527',
        '14529',
        '14530',
        '14532',
        '14533',
        '14534',
        '14536',
        '14537',
        '14539',
        '14541',
        '14542',
        '14543',
        '14544',
        '14545',
        '14546',
        '14548',
        '14549',
        '14550',
        '14551',
        '14555',
        '14559',
        '14560',
        '14561',
        '14564',
        '14568',
        '14569',
        '14571',
        '14572',
        '14580',
        '14585',
        '14586',
        '14588',
        '14589',
        '14590',
        '14591',
        '14592',
        '14604',
        '14605',
        '14606',
        '14607',
        '14608',
        '14609',
        '14610',
        '14611',
        '14612',
        '14613',
        '14614',
        '14615',
        '14616',
        '14617',
        '14618',
        '14619',
        '14620',
        '14621',
        '14622',
        '14623',
        '14624',
        '14625',
        '14626',
        '14627',
        '14701',
        '14706',
        '14707',
        '14708',
        '14709',
        '14710',
        '14711',
        '14712',
        '14714',
        '14715',
        '14716',
        '14717',
        '14718',
        '14719',
        '14720',
        '14721',
        '14722',
        '14723',
        '14724',
        '14726',
        '14727',
        '14728',
        '14729',
        '14731',
        '14732',
        '14733',
        '14735',
        '14736',
        '14737',
        '14738',
        '14739',
        '14740',
        '14741',
        '14742',
        '14743',
        '14744',
        '14747',
        '14748',
        '14750',
        '14752',
        '14753',
        '14754',
        '14755',
        '14756',
        '14757',
        '14760',
        '14767',
        '14769',
        '14770',
        '14772',
        '14774',
        '14775',
        '14777',
        '14778',
        '14779',
        '14781',
        '14782',
        '14783',
        '14784',
        '14787',
        '14788',
        '14801',
        '14802',
        '14803',
        '14804',
        '14805',
        '14806',
        '14807',
        '14808',
        '14809',
        '14810',
        '14812',
        '14813',
        '14814',
        '14815',
        '14816',
        '14817',
        '14818',
        '14819',
        '14820',
        '14821',
        '14822',
        '14823',
        '14824',
        '14825',
        '14826',
        '14827',
        '14830',
        '14836',
        '14837',
        '14838',
        '14839',
        '14840',
        '14841',
        '14842',
        '14843',
        '14845',
        '14846',
        '14847',
        '14850',
        '14853',
        '14854',
        '14855',
        '14856',
        '14858',
        '14859',
        '14860',
        '14861',
        '14864',
        '14865',
        '14867',
        '14869',
        '14870',
        '14871',
        '14872',
        '14873',
        '14874',
        '14877',
        '14878',
        '14879',
        '14880',
        '14881',
        '14882',
        '14883',
        '14884',
        '14885',
        '14886',
        '14889',
        '14891',
        '14892',
        '14893',
        '14894',
        '14895',
        '14897',
        '14898',
        '14901',
        '14903',
        '14904',
        '14905',
        '06390'
      ]
    },
    NJ: {
      name: 'New Jersey',
      codes: [
        '07001',
        '07002',
        '07003',
        '07004',
        '07005',
        '07006',
        '07008',
        '07009',
        '07010',
        '07011',
        '07012',
        '07013',
        '07014',
        '07016',
        '07017',
        '07018',
        '07020',
        '07021',
        '07022',
        '07023',
        '07024',
        '07026',
        '07027',
        '07028',
        '07029',
        '07030',
        '07031',
        '07032',
        '07033',
        '07034',
        '07035',
        '07036',
        '07039',
        '07040',
        '07041',
        '07042',
        '07043',
        '07044',
        '07045',
        '07046',
        '07047',
        '07050',
        '07052',
        '07054',
        '07055',
        '07057',
        '07058',
        '07059',
        '07060',
        '07062',
        '07063',
        '07064',
        '07065',
        '07066',
        '07067',
        '07068',
        '07069',
        '07070',
        '07071',
        '07072',
        '07073',
        '07074',
        '07075',
        '07076',
        '07077',
        '07078',
        '07079',
        '07080',
        '07081',
        '07082',
        '07083',
        '07086',
        '07087',
        '07088',
        '07090',
        '07092',
        '07093',
        '07094',
        '07095',
        '07102',
        '07103',
        '07104',
        '07105',
        '07106',
        '07107',
        '07108',
        '07109',
        '07110',
        '07111',
        '07112',
        '07114',
        '07201',
        '07202',
        '07203',
        '07204',
        '07205',
        '07206',
        '07208',
        '07302',
        '07304',
        '07305',
        '07306',
        '07307',
        '07310',
        '07311',
        '07401',
        '07403',
        '07405',
        '07407',
        '07410',
        '07416',
        '07417',
        '07418',
        '07419',
        '07420',
        '07421',
        '07422',
        '07423',
        '07424',
        '07430',
        '07432',
        '07435',
        '07436',
        '07438',
        '07439',
        '07440',
        '07442',
        '07444',
        '07446',
        '07450',
        '07452',
        '07456',
        '07457',
        '07458',
        '07460',
        '07461',
        '07462',
        '07463',
        '07465',
        '07470',
        '07480',
        '07481',
        '07495',
        '07501',
        '07502',
        '07503',
        '07504',
        '07505',
        '07506',
        '07508',
        '07512',
        '07513',
        '07514',
        '07522',
        '07524',
        '07601',
        '07603',
        '07604',
        '07605',
        '07606',
        '07607',
        '07608',
        '07620',
        '07621',
        '07624',
        '07626',
        '07627',
        '07628',
        '07630',
        '07631',
        '07632',
        '07640',
        '07641',
        '07642',
        '07643',
        '07644',
        '07645',
        '07646',
        '07647',
        '07648',
        '07649',
        '07650',
        '07652',
        '07656',
        '07657',
        '07660',
        '07661',
        '07662',
        '07663',
        '07666',
        '07670',
        '07675',
        '07676',
        '07677',
        '07701',
        '07702',
        '07703',
        '07704',
        '07711',
        '07712',
        '07716',
        '07717',
        '07718',
        '07719',
        '07720',
        '07721',
        '07722',
        '07723',
        '07724',
        '07726',
        '07727',
        '07728',
        '07730',
        '07731',
        '07732',
        '07733',
        '07734',
        '07735',
        '07737',
        '07738',
        '07739',
        '07740',
        '07746',
        '07747',
        '07748',
        '07750',
        '07751',
        '07753',
        '07755',
        '07756',
        '07757',
        '07758',
        '07760',
        '07762',
        '07764',
        '07801',
        '07803',
        '07820',
        '07821',
        '07822',
        '07823',
        '07825',
        '07826',
        '07827',
        '07828',
        '07830',
        '07832',
        '07833',
        '07834',
        '07836',
        '07838',
        '07840',
        '07842',
        '07843',
        '07846',
        '07847',
        '07848',
        '07849',
        '07850',
        '07851',
        '07852',
        '07853',
        '07856',
        '07857',
        '07860',
        '07863',
        '07865',
        '07866',
        '07869',
        '07870',
        '07871',
        '07874',
        '07876',
        '07878',
        '07880',
        '07881',
        '07882',
        '07885',
        '07901',
        '07920',
        '07921',
        '07922',
        '07924',
        '07926',
        '07927',
        '07928',
        '07930',
        '07931',
        '07932',
        '07933',
        '07934',
        '07935',
        '07936',
        '07939',
        '07940',
        '07945',
        '07946',
        '07950',
        '07960',
        '07961',
        '07970',
        '07974',
        '07976',
        '07977',
        '07979',
        '07980',
        '07981',
        '08001',
        '08002',
        '08003',
        '08004',
        '08005',
        '08006',
        '08007',
        '08008',
        '08009',
        '08010',
        '08011',
        '08012',
        '08014',
        '08015',
        '08016',
        '08019',
        '08020',
        '08021',
        '08022',
        '08023',
        '08026',
        '08027',
        '08028',
        '08029',
        '08030',
        '08031',
        '08033',
        '08034',
        '08035',
        '08036',
        '08037',
        '08038',
        '08039',
        '08041',
        '08042',
        '08043',
        '08045',
        '08046',
        '08048',
        '08049',
        '08050',
        '08051',
        '08052',
        '08053',
        '08054',
        '08055',
        '08056',
        '08057',
        '08059',
        '08060',
        '08061',
        '08062',
        '08063',
        '08064',
        '08065',
        '08066',
        '08067',
        '08068',
        '08069',
        '08070',
        '08071',
        '08072',
        '08073',
        '08074',
        '08075',
        '08077',
        '08078',
        '08079',
        '08080',
        '08081',
        '08083',
        '08084',
        '08085',
        '08086',
        '08087',
        '08088',
        '08089',
        '08090',
        '08091',
        '08092',
        '08093',
        '08094',
        '08095',
        '08096',
        '08097',
        '08098',
        '08102',
        '08103',
        '08104',
        '08105',
        '08106',
        '08107',
        '08108',
        '08109',
        '08110',
        '08201',
        '08202',
        '08203',
        '08204',
        '08205',
        '08210',
        '08212',
        '08215',
        '08217',
        '08221',
        '08223',
        '08224',
        '08225',
        '08226',
        '08230',
        '08232',
        '08234',
        '08240',
        '08241',
        '08242',
        '08243',
        '08244',
        '08246',
        '08247',
        '08248',
        '08251',
        '08260',
        '08270',
        '08302',
        '08310',
        '08311',
        '08312',
        '08314',
        '08316',
        '08317',
        '08318',
        '08319',
        '08320',
        '08321',
        '08322',
        '08323',
        '08324',
        '08326',
        '08327',
        '08328',
        '08329',
        '08330',
        '08332',
        '08340',
        '08341',
        '08343',
        '08344',
        '08345',
        '08346',
        '08348',
        '08349',
        '08350',
        '08352',
        '08353',
        '08360',
        '08361',
        '08401',
        '08402',
        '08403',
        '08406',
        '08501',
        '08502',
        '08505',
        '08510',
        '08511',
        '08512',
        '08514',
        '08515',
        '08518',
        '08520',
        '08525',
        '08527',
        '08528',
        '08530',
        '08533',
        '08534',
        '08535',
        '08536',
        '08540',
        '08542',
        '08550',
        '08551',
        '08553',
        '08554',
        '08555',
        '08558',
        '08559',
        '08560',
        '08561',
        '08562',
        '08608',
        '08609',
        '08610',
        '08611',
        '08618',
        '08619',
        '08620',
        '08628',
        '08629',
        '08638',
        '08640',
        '08641',
        '08648',
        '08690',
        '08691',
        '08701',
        '08720',
        '08721',
        '08722',
        '08723',
        '08724',
        '08730',
        '08731',
        '08732',
        '08733',
        '08734',
        '08735',
        '08736',
        '08738',
        '08740',
        '08741',
        '08742',
        '08750',
        '08751',
        '08752',
        '08753',
        '08755',
        '08757',
        '08758',
        '08759',
        '08801',
        '08802',
        '08804',
        '08805',
        '08807',
        '08808',
        '08809',
        '08810',
        '08812',
        '08816',
        '08817',
        '08820',
        '08821',
        '08822',
        '08823',
        '08824',
        '08825',
        '08826',
        '08827',
        '08828',
        '08829',
        '08830',
        '08831',
        '08832',
        '08833',
        '08835',
        '08836',
        '08837',
        '08840',
        '08844',
        '08846',
        '08848',
        '08850',
        '08852',
        '08853',
        '08854',
        '08857',
        '08858',
        '08859',
        '08861',
        '08863',
        '08865',
        '08867',
        '08869',
        '08872',
        '08873',
        '08876',
        '08879',
        '08880',
        '08882',
        '08884',
        '08886',
        '08887',
        '08889',
        '08890',
        '08901',
        '08902',
        '08904'
      ]
    },
    PA: {
      name: 'Pennsylvania',
      codes: [
        '15001',
        '15003',
        '15004',
        '15005',
        '15006',
        '15007',
        '15009',
        '15010',
        '15012',
        '15014',
        '15015',
        '15017',
        '15018',
        '15019',
        '15020',
        '15021',
        '15022',
        '15024',
        '15025',
        '15026',
        '15027',
        '15028',
        '15030',
        '15031',
        '15033',
        '15034',
        '15035',
        '15037',
        '15038',
        '15042',
        '15043',
        '15044',
        '15045',
        '15046',
        '15047',
        '15049',
        '15050',
        '15051',
        '15052',
        '15053',
        '15054',
        '15055',
        '15056',
        '15057',
        '15059',
        '15060',
        '15061',
        '15062',
        '15063',
        '15064',
        '15065',
        '15066',
        '15067',
        '15068',
        '15071',
        '15072',
        '15074',
        '15075',
        '15076',
        '15077',
        '15078',
        '15081',
        '15082',
        '15083',
        '15084',
        '15085',
        '15086',
        '15087',
        '15088',
        '15089',
        '15090',
        '15101',
        '15102',
        '15104',
        '15106',
        '15108',
        '15110',
        '15112',
        '15116',
        '15120',
        '15122',
        '15126',
        '15129',
        '15131',
        '15132',
        '15133',
        '15135',
        '15136',
        '15137',
        '15139',
        '15140',
        '15142',
        '15143',
        '15144',
        '15145',
        '15146',
        '15147',
        '15148',
        '15201',
        '15202',
        '15203',
        '15204',
        '15205',
        '15206',
        '15207',
        '15208',
        '15209',
        '15210',
        '15211',
        '15212',
        '15213',
        '15214',
        '15215',
        '15216',
        '15217',
        '15218',
        '15219',
        '15220',
        '15221',
        '15222',
        '15223',
        '15224',
        '15225',
        '15226',
        '15227',
        '15228',
        '15229',
        '15232',
        '15233',
        '15234',
        '15235',
        '15236',
        '15237',
        '15238',
        '15239',
        '15241',
        '15243',
        '15260',
        '15290',
        '15301',
        '15310',
        '15311',
        '15312',
        '15313',
        '15314',
        '15315',
        '15316',
        '15317',
        '15320',
        '15321',
        '15322',
        '15323',
        '15324',
        '15325',
        '15327',
        '15329',
        '15330',
        '15331',
        '15332',
        '15333',
        '15334',
        '15337',
        '15338',
        '15340',
        '15341',
        '15342',
        '15344',
        '15345',
        '15346',
        '15347',
        '15348',
        '15349',
        '15350',
        '15351',
        '15352',
        '15353',
        '15357',
        '15358',
        '15359',
        '15360',
        '15361',
        '15362',
        '15363',
        '15364',
        '15366',
        '15367',
        '15368',
        '15370',
        '15376',
        '15377',
        '15378',
        '15379',
        '15380',
        '15401',
        '15410',
        '15411',
        '15412',
        '15413',
        '15417',
        '15419',
        '15420',
        '15421',
        '15422',
        '15423',
        '15424',
        '15425',
        '15427',
        '15428',
        '15429',
        '15430',
        '15431',
        '15432',
        '15433',
        '15434',
        '15435',
        '15436',
        '15437',
        '15438',
        '15440',
        '15442',
        '15443',
        '15444',
        '15445',
        '15446',
        '15447',
        '15448',
        '15449',
        '15450',
        '15451',
        '15454',
        '15455',
        '15456',
        '15458',
        '15459',
        '15460',
        '15461',
        '15462',
        '15463',
        '15464',
        '15466',
        '15467',
        '15468',
        '15469',
        '15470',
        '15472',
        '15473',
        '15474',
        '15475',
        '15476',
        '15477',
        '15478',
        '15479',
        '15480',
        '15482',
        '15483',
        '15484',
        '15486',
        '15489',
        '15490',
        '15492',
        '15501',
        '15502',
        '15510',
        '15520',
        '15521',
        '15522',
        '15530',
        '15531',
        '15532',
        '15533',
        '15534',
        '15535',
        '15536',
        '15537',
        '15538',
        '15539',
        '15540',
        '15541',
        '15542',
        '15544',
        '15545',
        '15546',
        '15547',
        '15550',
        '15551',
        '15552',
        '15554',
        '15555',
        '15557',
        '15558',
        '15559',
        '15560',
        '15561',
        '15562',
        '15563',
        '15564',
        '15601',
        '15610',
        '15611',
        '15612',
        '15613',
        '15615',
        '15616',
        '15617',
        '15618',
        '15620',
        '15621',
        '15622',
        '15623',
        '15624',
        '15625',
        '15626',
        '15627',
        '15628',
        '15629',
        '15631',
        '15632',
        '15633',
        '15634',
        '15635',
        '15636',
        '15637',
        '15638',
        '15639',
        '15640',
        '15641',
        '15642',
        '15644',
        '15646',
        '15647',
        '15650',
        '15655',
        '15656',
        '15658',
        '15660',
        '15661',
        '15662',
        '15663',
        '15665',
        '15666',
        '15668',
        '15670',
        '15671',
        '15672',
        '15673',
        '15675',
        '15676',
        '15677',
        '15678',
        '15679',
        '15680',
        '15681',
        '15683',
        '15684',
        '15686',
        '15687',
        '15688',
        '15689',
        '15690',
        '15691',
        '15692',
        '15693',
        '15695',
        '15696',
        '15697',
        '15698',
        '15701',
        '15710',
        '15711',
        '15712',
        '15713',
        '15714',
        '15715',
        '15716',
        '15717',
        '15721',
        '15722',
        '15723',
        '15724',
        '15725',
        '15727',
        '15728',
        '15729',
        '15730',
        '15731',
        '15732',
        '15733',
        '15734',
        '15736',
        '15737',
        '15738',
        '15739',
        '15741',
        '15742',
        '15744',
        '15745',
        '15746',
        '15747',
        '15748',
        '15750',
        '15752',
        '15753',
        '15754',
        '15756',
        '15757',
        '15759',
        '15760',
        '15761',
        '15762',
        '15764',
        '15765',
        '15767',
        '15770',
        '15771',
        '15772',
        '15773',
        '15774',
        '15775',
        '15776',
        '15777',
        '15778',
        '15779',
        '15780',
        '15781',
        '15783',
        '15784',
        '15801',
        '15821',
        '15823',
        '15824',
        '15825',
        '15827',
        '15828',
        '15829',
        '15832',
        '15834',
        '15840',
        '15841',
        '15845',
        '15846',
        '15847',
        '15848',
        '15849',
        '15851',
        '15853',
        '15856',
        '15857',
        '15860',
        '15861',
        '15863',
        '15864',
        '15865',
        '15866',
        '15868',
        '15870',
        '15901',
        '15902',
        '15904',
        '15905',
        '15906',
        '15909',
        '15920',
        '15921',
        '15922',
        '15923',
        '15924',
        '15925',
        '15926',
        '15927',
        '15928',
        '15929',
        '15930',
        '15931',
        '15934',
        '15935',
        '15936',
        '15937',
        '15938',
        '15940',
        '15942',
        '15943',
        '15944',
        '15945',
        '15946',
        '15948',
        '15949',
        '15951',
        '15952',
        '15953',
        '15954',
        '15955',
        '15956',
        '15957',
        '15958',
        '15960',
        '15961',
        '15962',
        '15963',
        '16001',
        '16002',
        '16020',
        '16022',
        '16023',
        '16024',
        '16025',
        '16027',
        '16028',
        '16029',
        '16030',
        '16033',
        '16034',
        '16035',
        '16036',
        '16037',
        '16038',
        '16040',
        '16041',
        '16045',
        '16046',
        '16048',
        '16049',
        '16050',
        '16051',
        '16052',
        '16053',
        '16054',
        '16055',
        '16056',
        '16057',
        '16059',
        '16061',
        '16063',
        '16066',
        '16101',
        '16102',
        '16105',
        '16110',
        '16111',
        '16112',
        '16113',
        '16114',
        '16115',
        '16116',
        '16117',
        '16120',
        '16121',
        '16123',
        '16124',
        '16125',
        '16127',
        '16130',
        '16131',
        '16132',
        '16133',
        '16134',
        '16136',
        '16137',
        '16140',
        '16141',
        '16142',
        '16143',
        '16145',
        '16146',
        '16148',
        '16150',
        '16151',
        '16153',
        '16154',
        '16155',
        '16156',
        '16157',
        '16159',
        '16160',
        '16161',
        '16201',
        '16210',
        '16211',
        '16212',
        '16213',
        '16214',
        '16217',
        '16218',
        '16222',
        '16223',
        '16224',
        '16226',
        '16228',
        '16229',
        '16230',
        '16232',
        '16233',
        '16235',
        '16236',
        '16238',
        '16239',
        '16240',
        '16242',
        '16244',
        '16245',
        '16246',
        '16248',
        '16249',
        '16250',
        '16253',
        '16254',
        '16255',
        '16256',
        '16258',
        '16259',
        '16260',
        '16262',
        '16263',
        '16301',
        '16311',
        '16312',
        '16313',
        '16314',
        '16316',
        '16317',
        '16319',
        '16321',
        '16322',
        '16323',
        '16326',
        '16327',
        '16328',
        '16329',
        '16331',
        '16332',
        '16333',
        '16334',
        '16335',
        '16340',
        '16341',
        '16342',
        '16343',
        '16344',
        '16345',
        '16346',
        '16347',
        '16350',
        '16351',
        '16352',
        '16353',
        '16354',
        '16360',
        '16361',
        '16362',
        '16364',
        '16365',
        '16370',
        '16371',
        '16372',
        '16373',
        '16374',
        '16401',
        '16402',
        '16403',
        '16404',
        '16405',
        '16406',
        '16407',
        '16410',
        '16411',
        '16412',
        '16415',
        '16416',
        '16417',
        '16420',
        '16421',
        '16422',
        '16423',
        '16424',
        '16426',
        '16427',
        '16428',
        '16433',
        '16434',
        '16435',
        '16436',
        '16438',
        '16440',
        '16441',
        '16442',
        '16443',
        '16444',
        '16501',
        '16502',
        '16503',
        '16504',
        '16505',
        '16506',
        '16507',
        '16508',
        '16509',
        '16510',
        '16511',
        '16546',
        '16563',
        '16601',
        '16602',
        '16611',
        '16613',
        '16616',
        '16617',
        '16619',
        '16620',
        '16621',
        '16622',
        '16623',
        '16624',
        '16625',
        '16627',
        '16630',
        '16631',
        '16633',
        '16634',
        '16635',
        '16636',
        '16637',
        '16638',
        '16639',
        '16640',
        '16641',
        '16645',
        '16646',
        '16647',
        '16648',
        '16650',
        '16651',
        '16652',
        '16655',
        '16656',
        '16657',
        '16659',
        '16661',
        '16662',
        '16664',
        '16665',
        '16666',
        '16667',
        '16668',
        '16669',
        '16670',
        '16671',
        '16672',
        '16673',
        '16674',
        '16677',
        '16678',
        '16679',
        '16680',
        '16682',
        '16683',
        '16685',
        '16686',
        '16689',
        '16691',
        '16692',
        '16693',
        '16694',
        '16695',
        '16699',
        '16701',
        '16720',
        '16724',
        '16725',
        '16726',
        '16727',
        '16728',
        '16729',
        '16730',
        '16731',
        '16732',
        '16733',
        '16734',
        '16735',
        '16738',
        '16740',
        '16743',
        '16744',
        '16745',
        '16746',
        '16748',
        '16749',
        '16750',
        '16801',
        '16802',
        '16803',
        '16820',
        '16821',
        '16822',
        '16823',
        '16825',
        '16826',
        '16827',
        '16828',
        '16829',
        '16830',
        '16832',
        '16833',
        '16834',
        '16835',
        '16836',
        '16837',
        '16838',
        '16839',
        '16840',
        '16841',
        '16843',
        '16844',
        '16845',
        '16847',
        '16848',
        '16849',
        '16851',
        '16852',
        '16853',
        '16854',
        '16855',
        '16858',
        '16859',
        '16860',
        '16861',
        '16863',
        '16865',
        '16866',
        '16868',
        '16870',
        '16871',
        '16872',
        '16874',
        '16875',
        '16876',
        '16877',
        '16878',
        '16879',
        '16881',
        '16882',
        '16901',
        '16911',
        '16912',
        '16914',
        '16915',
        '16917',
        '16920',
        '16921',
        '16922',
        '16923',
        '16925',
        '16926',
        '16927',
        '16928',
        '16929',
        '16930',
        '16932',
        '16933',
        '16935',
        '16936',
        '16937',
        '16938',
        '16939',
        '16940',
        '16941',
        '16942',
        '16943',
        '16946',
        '16947',
        '16948',
        '16950',
        '17002',
        '17003',
        '17004',
        '17005',
        '17006',
        '17007',
        '17009',
        '17010',
        '17011',
        '17013',
        '17015',
        '17016',
        '17017',
        '17018',
        '17019',
        '17020',
        '17021',
        '17022',
        '17023',
        '17024',
        '17025',
        '17026',
        '17027',
        '17028',
        '17029',
        '17030',
        '17032',
        '17033',
        '17034',
        '17035',
        '17036',
        '17037',
        '17038',
        '17039',
        '17040',
        '17041',
        '17042',
        '17043',
        '17044',
        '17045',
        '17046',
        '17047',
        '17048',
        '17049',
        '17050',
        '17051',
        '17052',
        '17053',
        '17055',
        '17056',
        '17057',
        '17058',
        '17059',
        '17060',
        '17061',
        '17062',
        '17063',
        '17064',
        '17065',
        '17066',
        '17067',
        '17068',
        '17069',
        '17070',
        '17071',
        '17072',
        '17073',
        '17074',
        '17075',
        '17076',
        '17077',
        '17078',
        '17080',
        '17081',
        '17082',
        '17083',
        '17084',
        '17086',
        '17087',
        '17088',
        '17090',
        '17093',
        '17094',
        '17097',
        '17098',
        '17099',
        '17101',
        '17102',
        '17103',
        '17104',
        '17109',
        '17110',
        '17111',
        '17112',
        '17113',
        '17120',
        '17201',
        '17202',
        '17210',
        '17211',
        '17212',
        '17213',
        '17214',
        '17215',
        '17217',
        '17219',
        '17220',
        '17221',
        '17222',
        '17223',
        '17224',
        '17225',
        '17228',
        '17229',
        '17233',
        '17235',
        '17236',
        '17237',
        '17238',
        '17239',
        '17240',
        '17241',
        '17243',
        '17244',
        '17246',
        '17247',
        '17249',
        '17250',
        '17251',
        '17252',
        '17253',
        '17254',
        '17255',
        '17256',
        '17257',
        '17260',
        '17261',
        '17262',
        '17263',
        '17264',
        '17265',
        '17266',
        '17267',
        '17268',
        '17271',
        '17272',
        '17301',
        '17302',
        '17304',
        '17306',
        '17307',
        '17309',
        '17311',
        '17313',
        '17314',
        '17315',
        '17316',
        '17317',
        '17318',
        '17319',
        '17320',
        '17321',
        '17322',
        '17324',
        '17325',
        '17327',
        '17329',
        '17331',
        '17339',
        '17340',
        '17343',
        '17344',
        '17345',
        '17347',
        '17349',
        '17350',
        '17352',
        '17353',
        '17355',
        '17356',
        '17360',
        '17361',
        '17362',
        '17363',
        '17364',
        '17365',
        '17366',
        '17368',
        '17370',
        '17371',
        '17372',
        '17401',
        '17402',
        '17403',
        '17404',
        '17406',
        '17407',
        '17408',
        '17501',
        '17502',
        '17505',
        '17507',
        '17508',
        '17509',
        '17512',
        '17516',
        '17517',
        '17518',
        '17519',
        '17520',
        '17522',
        '17527',
        '17529',
        '17532',
        '17535',
        '17536',
        '17538',
        '17540',
        '17543',
        '17545',
        '17547',
        '17550',
        '17551',
        '17552',
        '17554',
        '17555',
        '17557',
        '17560',
        '17562',
        '17563',
        '17565',
        '17566',
        '17569',
        '17570',
        '17572',
        '17576',
        '17578',
        '17579',
        '17581',
        '17582',
        '17584',
        '17601',
        '17602',
        '17603',
        '17606',
        '17701',
        '17702',
        '17721',
        '17723',
        '17724',
        '17727',
        '17728',
        '17729',
        '17730',
        '17731',
        '17737',
        '17739',
        '17740',
        '17742',
        '17744',
        '17745',
        '17747',
        '17748',
        '17749',
        '17750',
        '17751',
        '17752',
        '17754',
        '17756',
        '17758',
        '17760',
        '17762',
        '17763',
        '17764',
        '17765',
        '17768',
        '17771',
        '17772',
        '17774',
        '17776',
        '17777',
        '17778',
        '17779',
        '17801',
        '17810',
        '17812',
        '17813',
        '17814',
        '17815',
        '17820',
        '17821',
        '17822',
        '17823',
        '17824',
        '17827',
        '17829',
        '17830',
        '17832',
        '17834',
        '17835',
        '17836',
        '17837',
        '17840',
        '17841',
        '17842',
        '17844',
        '17845',
        '17846',
        '17847',
        '17850',
        '17851',
        '17853',
        '17855',
        '17856',
        '17857',
        '17859',
        '17860',
        '17861',
        '17862',
        '17864',
        '17865',
        '17866',
        '17867',
        '17868',
        '17870',
        '17872',
        '17876',
        '17878',
        '17880',
        '17881',
        '17884',
        '17885',
        '17886',
        '17887',
        '17888',
        '17889',
        '17901',
        '17920',
        '17921',
        '17922',
        '17923',
        '17925',
        '17929',
        '17930',
        '17931',
        '17933',
        '17934',
        '17935',
        '17936',
        '17938',
        '17941',
        '17943',
        '17944',
        '17945',
        '17946',
        '17948',
        '17949',
        '17951',
        '17952',
        '17953',
        '17954',
        '17957',
        '17959',
        '17960',
        '17961',
        '17963',
        '17964',
        '17965',
        '17967',
        '17968',
        '17970',
        '17972',
        '17974',
        '17976',
        '17978',
        '17979',
        '17980',
        '17981',
        '17982',
        '17983',
        '17985',
        '18011',
        '18013',
        '18014',
        '18015',
        '18016',
        '18017',
        '18018',
        '18020',
        '18030',
        '18031',
        '18032',
        '18034',
        '18035',
        '18036',
        '18037',
        '18038',
        '18040',
        '18041',
        '18042',
        '18045',
        '18046',
        '18049',
        '18051',
        '18052',
        '18053',
        '18054',
        '18055',
        '18056',
        '18058',
        '18059',
        '18062',
        '18063',
        '18064',
        '18066',
        '18067',
        '18068',
        '18069',
        '18070',
        '18071',
        '18072',
        '18073',
        '18074',
        '18076',
        '18077',
        '18078',
        '18079',
        '18080',
        '18081',
        '18083',
        '18085',
        '18086',
        '18087',
        '18088',
        '18091',
        '18092',
        '18101',
        '18102',
        '18103',
        '18104',
        '18105',
        '18106',
        '18109',
        '18195',
        '18201',
        '18202',
        '18210',
        '18211',
        '18212',
        '18214',
        '18216',
        '18218',
        '18219',
        '18220',
        '18221',
        '18222',
        '18223',
        '18224',
        '18225',
        '18229',
        '18230',
        '18231',
        '18232',
        '18234',
        '18235',
        '18237',
        '18239',
        '18240',
        '18241',
        '18242',
        '18244',
        '18245',
        '18246',
        '18248',
        '18249',
        '18250',
        '18251',
        '18252',
        '18254',
        '18255',
        '18256',
        '18301',
        '18302',
        '18321',
        '18322',
        '18323',
        '18324',
        '18325',
        '18326',
        '18327',
        '18328',
        '18330',
        '18331',
        '18332',
        '18333',
        '18334',
        '18335',
        '18336',
        '18337',
        '18340',
        '18342',
        '18343',
        '18344',
        '18346',
        '18347',
        '18349',
        '18350',
        '18351',
        '18352',
        '18353',
        '18354',
        '18355',
        '18356',
        '18357',
        '18360',
        '18370',
        '18371',
        '18372',
        '18403',
        '18405',
        '18407',
        '18411',
        '18413',
        '18414',
        '18415',
        '18417',
        '18419',
        '18420',
        '18421',
        '18424',
        '18425',
        '18426',
        '18427',
        '18428',
        '18430',
        '18431',
        '18433',
        '18434',
        '18435',
        '18436',
        '18437',
        '18438',
        '18439',
        '18441',
        '18443',
        '18444',
        '18445',
        '18446',
        '18447',
        '18451',
        '18452',
        '18453',
        '18454',
        '18455',
        '18456',
        '18457',
        '18458',
        '18459',
        '18460',
        '18461',
        '18462',
        '18463',
        '18464',
        '18465',
        '18466',
        '18469',
        '18470',
        '18471',
        '18472',
        '18473',
        '18503',
        '18504',
        '18505',
        '18507',
        '18508',
        '18509',
        '18510',
        '18512',
        '18517',
        '18518',
        '18519',
        '18602',
        '18603',
        '18610',
        '18612',
        '18614',
        '18615',
        '18616',
        '18617',
        '18618',
        '18619',
        '18621',
        '18622',
        '18623',
        '18624',
        '18625',
        '18626',
        '18628',
        '18629',
        '18630',
        '18631',
        '18632',
        '18634',
        '18635',
        '18636',
        '18640',
        '18641',
        '18642',
        '18643',
        '18644',
        '18651',
        '18653',
        '18655',
        '18656',
        '18657',
        '18660',
        '18661',
        '18701',
        '18702',
        '18704',
        '18705',
        '18706',
        '18707',
        '18708',
        '18709',
        '18801',
        '18810',
        '18812',
        '18814',
        '18816',
        '18817',
        '18818',
        '18821',
        '18822',
        '18823',
        '18824',
        '18825',
        '18826',
        '18828',
        '18829',
        '18830',
        '18831',
        '18832',
        '18833',
        '18834',
        '18837',
        '18840',
        '18842',
        '18843',
        '18844',
        '18845',
        '18846',
        '18847',
        '18848',
        '18850',
        '18851',
        '18853',
        '18854',
        '18901',
        '18902',
        '18912',
        '18913',
        '18914',
        '18915',
        '18917',
        '18920',
        '18923',
        '18925',
        '18929',
        '18930',
        '18932',
        '18935',
        '18936',
        '18938',
        '18940',
        '18942',
        '18944',
        '18947',
        '18950',
        '18951',
        '18954',
        '18955',
        '18960',
        '18962',
        '18964',
        '18966',
        '18969',
        '18970',
        '18972',
        '18974',
        '18976',
        '18977',
        '18980',
        '19001',
        '19002',
        '19003',
        '19004',
        '19006',
        '19007',
        '19008',
        '19009',
        '19010',
        '19012',
        '19013',
        '19014',
        '19015',
        '19017',
        '19018',
        '19020',
        '19021',
        '19022',
        '19023',
        '19025',
        '19026',
        '19027',
        '19029',
        '19030',
        '19031',
        '19032',
        '19033',
        '19034',
        '19035',
        '19036',
        '19038',
        '19040',
        '19041',
        '19043',
        '19044',
        '19046',
        '19047',
        '19050',
        '19053',
        '19054',
        '19055',
        '19056',
        '19057',
        '19060',
        '19061',
        '19063',
        '19064',
        '19066',
        '19067',
        '19070',
        '19072',
        '19073',
        '19074',
        '19075',
        '19076',
        '19078',
        '19079',
        '19081',
        '19082',
        '19083',
        '19085',
        '19086',
        '19087',
        '19090',
        '19094',
        '19095',
        '19096',
        '19102',
        '19103',
        '19104',
        '19106',
        '19107',
        '19109',
        '19111',
        '19112',
        '19113',
        '19114',
        '19115',
        '19116',
        '19118',
        '19119',
        '19120',
        '19121',
        '19122',
        '19123',
        '19124',
        '19125',
        '19126',
        '19127',
        '19128',
        '19129',
        '19130',
        '19131',
        '19132',
        '19133',
        '19134',
        '19135',
        '19136',
        '19137',
        '19138',
        '19139',
        '19140',
        '19141',
        '19142',
        '19143',
        '19144',
        '19145',
        '19146',
        '19147',
        '19148',
        '19149',
        '19150',
        '19151',
        '19152',
        '19153',
        '19154',
        '19301',
        '19310',
        '19311',
        '19312',
        '19316',
        '19317',
        '19319',
        '19320',
        '19330',
        '19333',
        '19335',
        '19341',
        '19342',
        '19343',
        '19344',
        '19345',
        '19348',
        '19350',
        '19352',
        '19355',
        '19358',
        '19362',
        '19363',
        '19365',
        '19367',
        '19372',
        '19373',
        '19374',
        '19375',
        '19380',
        '19382',
        '19383',
        '19390',
        '19401',
        '19403',
        '19405',
        '19406',
        '19422',
        '19425',
        '19426',
        '19428',
        '19435',
        '19436',
        '19437',
        '19438',
        '19440',
        '19442',
        '19444',
        '19446',
        '19453',
        '19454',
        '19456',
        '19457',
        '19460',
        '19462',
        '19464',
        '19465',
        '19468',
        '19472',
        '19473',
        '19474',
        '19475',
        '19477',
        '19492',
        '19501',
        '19503',
        '19504',
        '19505',
        '19506',
        '19507',
        '19508',
        '19510',
        '19511',
        '19512',
        '19518',
        '19519',
        '19520',
        '19522',
        '19523',
        '19525',
        '19526',
        '19529',
        '19530',
        '19533',
        '19534',
        '19535',
        '19536',
        '19538',
        '19539',
        '19540',
        '19541',
        '19543',
        '19544',
        '19545',
        '19547',
        '19549',
        '19550',
        '19551',
        '19554',
        '19555',
        '19559',
        '19560',
        '19562',
        '19564',
        '19565',
        '19567',
        '19601',
        '19602',
        '19604',
        '19605',
        '19606',
        '19607',
        '19608',
        '19609',
        '19610',
        '19611'
      ]
    },
    DE: {
      name: 'Delaware',
      codes: [
        '19701',
        '19702',
        '19703',
        '19706',
        '19707',
        '19709',
        '19710',
        '19711',
        '19713',
        '19716',
        '19717',
        '19720',
        '19730',
        '19731',
        '19732',
        '19733',
        '19734',
        '19735',
        '19736',
        '19801',
        '19802',
        '19803',
        '19804',
        '19805',
        '19806',
        '19807',
        '19808',
        '19809',
        '19810',
        '19901',
        '19902',
        '19904',
        '19930',
        '19931',
        '19933',
        '19934',
        '19936',
        '19938',
        '19939',
        '19940',
        '19941',
        '19943',
        '19944',
        '19945',
        '19946',
        '19947',
        '19950',
        '19951',
        '19952',
        '19953',
        '19954',
        '19955',
        '19956',
        '19958',
        '19960',
        '19962',
        '19963',
        '19964',
        '19966',
        '19967',
        '19968',
        '19970',
        '19971',
        '19973',
        '19975',
        '19977',
        '19979'
      ]
    },
    DC: {
      name: 'District Of Columbia',
      codes: [
        '20001',
        '20002',
        '20003',
        '20004',
        '20005',
        '20006',
        '20007',
        '20008',
        '20009',
        '20010',
        '20011',
        '20012',
        '20015',
        '20016',
        '20017',
        '20018',
        '20019',
        '20020',
        '20024',
        '20032',
        '20036',
        '20037',
        '20045',
        '20052',
        '20053',
        '20057',
        '20064',
        '20202',
        '20204',
        '20228',
        '20230',
        '20240',
        '20245',
        '20260',
        '20317',
        '20319',
        '20373',
        '20390',
        '20405',
        '20418',
        '20427',
        '20506',
        '20510',
        '20520',
        '20535',
        '20540',
        '20551',
        '20553',
        '20560',
        '20565',
        '20566',
        '20593'
      ]
    },
    MD: {
      name: 'Maryland',
      codes: [
        '20601',
        '20602',
        '20603',
        '20606',
        '20607',
        '20608',
        '20609',
        '20611',
        '20612',
        '20613',
        '20615',
        '20616',
        '20617',
        '20618',
        '20619',
        '20620',
        '20621',
        '20622',
        '20623',
        '20624',
        '20625',
        '20626',
        '20628',
        '20629',
        '20630',
        '20632',
        '20634',
        '20636',
        '20637',
        '20639',
        '20640',
        '20645',
        '20646',
        '20650',
        '20653',
        '20657',
        '20658',
        '20659',
        '20660',
        '20662',
        '20664',
        '20667',
        '20670',
        '20674',
        '20675',
        '20676',
        '20677',
        '20678',
        '20680',
        '20684',
        '20685',
        '20686',
        '20687',
        '20688',
        '20689',
        '20690',
        '20692',
        '20693',
        '20695',
        '20701',
        '20705',
        '20706',
        '20707',
        '20708',
        '20710',
        '20711',
        '20712',
        '20714',
        '20715',
        '20716',
        '20720',
        '20721',
        '20722',
        '20723',
        '20724',
        '20732',
        '20733',
        '20735',
        '20736',
        '20737',
        '20740',
        '20742',
        '20743',
        '20744',
        '20745',
        '20746',
        '20747',
        '20748',
        '20751',
        '20754',
        '20755',
        '20758',
        '20759',
        '20762',
        '20763',
        '20764',
        '20765',
        '20769',
        '20770',
        '20772',
        '20774',
        '20776',
        '20777',
        '20778',
        '20779',
        '20781',
        '20782',
        '20783',
        '20784',
        '20785',
        '20794',
        '20812',
        '20814',
        '20815',
        '20816',
        '20817',
        '20818',
        '20832',
        '20833',
        '20837',
        '20838',
        '20839',
        '20841',
        '20842',
        '20850',
        '20851',
        '20852',
        '20853',
        '20854',
        '20855',
        '20860',
        '20861',
        '20862',
        '20866',
        '20868',
        '20871',
        '20872',
        '20874',
        '20876',
        '20877',
        '20878',
        '20879',
        '20880',
        '20882',
        '20886',
        '20895',
        '20896',
        '20899',
        '20901',
        '20902',
        '20903',
        '20904',
        '20905',
        '20906',
        '20910',
        '20912',
        '21001',
        '21005',
        '21009',
        '21010',
        '21012',
        '21013',
        '21014',
        '21015',
        '21017',
        '21028',
        '21029',
        '21030',
        '21031',
        '21032',
        '21034',
        '21035',
        '21036',
        '21037',
        '21040',
        '21042',
        '21043',
        '21044',
        '21045',
        '21046',
        '21047',
        '21048',
        '21050',
        '21051',
        '21052',
        '21053',
        '21054',
        '21056',
        '21057',
        '21060',
        '21061',
        '21071',
        '21074',
        '21075',
        '21076',
        '21077',
        '21078',
        '21082',
        '21084',
        '21085',
        '21087',
        '21090',
        '21093',
        '21102',
        '21104',
        '21105',
        '21108',
        '21111',
        '21113',
        '21114',
        '21117',
        '21120',
        '21122',
        '21128',
        '21130',
        '21131',
        '21132',
        '21133',
        '21136',
        '21140',
        '21144',
        '21146',
        '21152',
        '21153',
        '21154',
        '21155',
        '21156',
        '21157',
        '21158',
        '21160',
        '21161',
        '21162',
        '21163',
        '21201',
        '21202',
        '21204',
        '21205',
        '21206',
        '21207',
        '21208',
        '21209',
        '21210',
        '21211',
        '21212',
        '21213',
        '21214',
        '21215',
        '21216',
        '21217',
        '21218',
        '21219',
        '21220',
        '21221',
        '21222',
        '21223',
        '21224',
        '21225',
        '21226',
        '21227',
        '21228',
        '21229',
        '21230',
        '21231',
        '21234',
        '21236',
        '21237',
        '21239',
        '21240',
        '21244',
        '21250',
        '21251',
        '21252',
        '21286',
        '21401',
        '21402',
        '21403',
        '21405',
        '21409',
        '21502',
        '21520',
        '21521',
        '21522',
        '21523',
        '21524',
        '21529',
        '21530',
        '21531',
        '21532',
        '21536',
        '21538',
        '21539',
        '21540',
        '21541',
        '21542',
        '21543',
        '21545',
        '21550',
        '21555',
        '21557',
        '21561',
        '21562',
        '21601',
        '21607',
        '21610',
        '21612',
        '21613',
        '21617',
        '21619',
        '21620',
        '21622',
        '21623',
        '21624',
        '21625',
        '21626',
        '21627',
        '21628',
        '21629',
        '21631',
        '21632',
        '21634',
        '21635',
        '21636',
        '21638',
        '21639',
        '21640',
        '21641',
        '21643',
        '21644',
        '21645',
        '21647',
        '21648',
        '21649',
        '21650',
        '21651',
        '21652',
        '21653',
        '21654',
        '21655',
        '21657',
        '21658',
        '21659',
        '21660',
        '21661',
        '21662',
        '21663',
        '21664',
        '21665',
        '21666',
        '21667',
        '21668',
        '21669',
        '21671',
        '21672',
        '21673',
        '21675',
        '21676',
        '21677',
        '21678',
        '21679',
        '21701',
        '21702',
        '21703',
        '21704',
        '21705',
        '21710',
        '21711',
        '21713',
        '21714',
        '21716',
        '21717',
        '21718',
        '21719',
        '21722',
        '21723',
        '21727',
        '21733',
        '21734',
        '21737',
        '21738',
        '21740',
        '21742',
        '21746',
        '21750',
        '21754',
        '21755',
        '21756',
        '21757',
        '21758',
        '21762',
        '21766',
        '21767',
        '21769',
        '21770',
        '21771',
        '21773',
        '21774',
        '21776',
        '21777',
        '21778',
        '21779',
        '21780',
        '21781',
        '21782',
        '21783',
        '21784',
        '21787',
        '21788',
        '21790',
        '21791',
        '21793',
        '21794',
        '21795',
        '21797',
        '21798',
        '21801',
        '21802',
        '21804',
        '21810',
        '21811',
        '21813',
        '21814',
        '21817',
        '21821',
        '21822',
        '21824',
        '21826',
        '21829',
        '21830',
        '21835',
        '21837',
        '21838',
        '21840',
        '21841',
        '21842',
        '21849',
        '21850',
        '21851',
        '21853',
        '21856',
        '21861',
        '21862',
        '21863',
        '21864',
        '21865',
        '21866',
        '21867',
        '21869',
        '21871',
        '21872',
        '21874',
        '21875',
        '21890',
        '21901',
        '21902',
        '21903',
        '21904',
        '21911',
        '21912',
        '21913',
        '21914',
        '21915',
        '21916',
        '21917',
        '21918',
        '21919',
        '21920',
        '21921',
        '21930'
      ]
    },
    VA: {
      name: 'Virginia',
      codes: [
        '20105',
        '20106',
        '20109',
        '20110',
        '20111',
        '20112',
        '20115',
        '20117',
        '20118',
        '20119',
        '20120',
        '20121',
        '20124',
        '20129',
        '20130',
        '20132',
        '20135',
        '20136',
        '20137',
        '20139',
        '20141',
        '20143',
        '20144',
        '20147',
        '20148',
        '20151',
        '20152',
        '20155',
        '20158',
        '20164',
        '20165',
        '20166',
        '20169',
        '20170',
        '20171',
        '20175',
        '20176',
        '20180',
        '20181',
        '20184',
        '20186',
        '20187',
        '20190',
        '20191',
        '20194',
        '20197',
        '20198',
        '22003',
        '22015',
        '22025',
        '22026',
        '22027',
        '22030',
        '22031',
        '22032',
        '22033',
        '22035',
        '22039',
        '22041',
        '22042',
        '22043',
        '22044',
        '22046',
        '22060',
        '22066',
        '22079',
        '22101',
        '22102',
        '22124',
        '22125',
        '22134',
        '22150',
        '22151',
        '22152',
        '22153',
        '22172',
        '22180',
        '22181',
        '22182',
        '22185',
        '22191',
        '22192',
        '22193',
        '22201',
        '22202',
        '22203',
        '22204',
        '22205',
        '22206',
        '22207',
        '22209',
        '22211',
        '22213',
        '22214',
        '22301',
        '22302',
        '22303',
        '22304',
        '22305',
        '22306',
        '22307',
        '22308',
        '22309',
        '22310',
        '22311',
        '22312',
        '22314',
        '22315',
        '22401',
        '22405',
        '22406',
        '22407',
        '22408',
        '22427',
        '22432',
        '22433',
        '22435',
        '22436',
        '22437',
        '22438',
        '22443',
        '22448',
        '22454',
        '22460',
        '22469',
        '22473',
        '22476',
        '22480',
        '22482',
        '22485',
        '22488',
        '22503',
        '22504',
        '22508',
        '22509',
        '22511',
        '22514',
        '22520',
        '22529',
        '22530',
        '22534',
        '22535',
        '22538',
        '22539',
        '22542',
        '22546',
        '22548',
        '22551',
        '22553',
        '22554',
        '22556',
        '22560',
        '22567',
        '22572',
        '22576',
        '22578',
        '22579',
        '22580',
        '22601',
        '22602',
        '22603',
        '22610',
        '22611',
        '22620',
        '22623',
        '22624',
        '22625',
        '22627',
        '22630',
        '22637',
        '22639',
        '22640',
        '22641',
        '22642',
        '22643',
        '22644',
        '22645',
        '22646',
        '22650',
        '22652',
        '22654',
        '22655',
        '22656',
        '22657',
        '22660',
        '22663',
        '22664',
        '22701',
        '22709',
        '22711',
        '22712',
        '22713',
        '22714',
        '22715',
        '22716',
        '22718',
        '22719',
        '22720',
        '22722',
        '22723',
        '22724',
        '22726',
        '22727',
        '22728',
        '22729',
        '22730',
        '22731',
        '22732',
        '22733',
        '22734',
        '22735',
        '22736',
        '22737',
        '22738',
        '22740',
        '22741',
        '22742',
        '22743',
        '22747',
        '22749',
        '22801',
        '22802',
        '22807',
        '22810',
        '22811',
        '22812',
        '22815',
        '22820',
        '22821',
        '22824',
        '22827',
        '22830',
        '22831',
        '22832',
        '22834',
        '22835',
        '22840',
        '22841',
        '22842',
        '22843',
        '22844',
        '22845',
        '22846',
        '22847',
        '22849',
        '22850',
        '22851',
        '22853',
        '22901',
        '22902',
        '22903',
        '22904',
        '22911',
        '22920',
        '22922',
        '22923',
        '22931',
        '22932',
        '22935',
        '22936',
        '22937',
        '22938',
        '22939',
        '22940',
        '22942',
        '22943',
        '22946',
        '22947',
        '22948',
        '22949',
        '22952',
        '22958',
        '22959',
        '22960',
        '22963',
        '22964',
        '22967',
        '22968',
        '22969',
        '22971',
        '22972',
        '22973',
        '22974',
        '22976',
        '22980',
        '22989',
        '23002',
        '23004',
        '23005',
        '23009',
        '23011',
        '23015',
        '23021',
        '23022',
        '23023',
        '23024',
        '23025',
        '23027',
        '23030',
        '23032',
        '23035',
        '23038',
        '23039',
        '23040',
        '23043',
        '23045',
        '23047',
        '23050',
        '23055',
        '23056',
        '23059',
        '23060',
        '23061',
        '23062',
        '23063',
        '23064',
        '23065',
        '23066',
        '23068',
        '23069',
        '23070',
        '23071',
        '23072',
        '23075',
        '23076',
        '23079',
        '23083',
        '23084',
        '23085',
        '23086',
        '23089',
        '23091',
        '23092',
        '23093',
        '23102',
        '23103',
        '23106',
        '23108',
        '23109',
        '23110',
        '23111',
        '23112',
        '23113',
        '23114',
        '23115',
        '23116',
        '23117',
        '23119',
        '23120',
        '23123',
        '23124',
        '23125',
        '23126',
        '23128',
        '23129',
        '23130',
        '23138',
        '23139',
        '23140',
        '23141',
        '23146',
        '23148',
        '23149',
        '23150',
        '23153',
        '23156',
        '23160',
        '23161',
        '23163',
        '23168',
        '23169',
        '23173',
        '23175',
        '23176',
        '23177',
        '23180',
        '23181',
        '23185',
        '23187',
        '23188',
        '23192',
        '23219',
        '23220',
        '23221',
        '23222',
        '23223',
        '23224',
        '23225',
        '23226',
        '23227',
        '23228',
        '23229',
        '23230',
        '23231',
        '23233',
        '23234',
        '23235',
        '23236',
        '23237',
        '23238',
        '23250',
        '23294',
        '23301',
        '23302',
        '23303',
        '23304',
        '23306',
        '23307',
        '23308',
        '23310',
        '23313',
        '23314',
        '23315',
        '23316',
        '23320',
        '23321',
        '23322',
        '23323',
        '23324',
        '23325',
        '23336',
        '23337',
        '23347',
        '23350',
        '23354',
        '23356',
        '23357',
        '23358',
        '23359',
        '23389',
        '23395',
        '23398',
        '23401',
        '23405',
        '23407',
        '23408',
        '23409',
        '23410',
        '23413',
        '23414',
        '23415',
        '23416',
        '23417',
        '23418',
        '23420',
        '23421',
        '23422',
        '23423',
        '23426',
        '23427',
        '23430',
        '23432',
        '23433',
        '23434',
        '23435',
        '23436',
        '23437',
        '23438',
        '23440',
        '23441',
        '23442',
        '23451',
        '23452',
        '23453',
        '23454',
        '23455',
        '23456',
        '23457',
        '23459',
        '23460',
        '23461',
        '23462',
        '23464',
        '23480',
        '23486',
        '23487',
        '23488',
        '23502',
        '23503',
        '23504',
        '23505',
        '23507',
        '23508',
        '23509',
        '23510',
        '23511',
        '23513',
        '23517',
        '23518',
        '23523',
        '23551',
        '23601',
        '23602',
        '23603',
        '23604',
        '23605',
        '23606',
        '23607',
        '23608',
        '23651',
        '23661',
        '23662',
        '23663',
        '23664',
        '23665',
        '23666',
        '23669',
        '23690',
        '23691',
        '23692',
        '23693',
        '23696',
        '23701',
        '23702',
        '23703',
        '23704',
        '23707',
        '23708',
        '23709',
        '23801',
        '23803',
        '23805',
        '23806',
        '23821',
        '23824',
        '23827',
        '23828',
        '23829',
        '23830',
        '23831',
        '23832',
        '23833',
        '23834',
        '23836',
        '23837',
        '23838',
        '23839',
        '23840',
        '23841',
        '23842',
        '23843',
        '23844',
        '23845',
        '23846',
        '23847',
        '23850',
        '23851',
        '23856',
        '23857',
        '23860',
        '23866',
        '23867',
        '23868',
        '23872',
        '23874',
        '23875',
        '23876',
        '23878',
        '23879',
        '23881',
        '23882',
        '23883',
        '23884',
        '23885',
        '23887',
        '23888',
        '23889',
        '23890',
        '23891',
        '23893',
        '23894',
        '23897',
        '23898',
        '23899',
        '23901',
        '23909',
        '23915',
        '23917',
        '23919',
        '23920',
        '23921',
        '23922',
        '23923',
        '23924',
        '23927',
        '23930',
        '23934',
        '23936',
        '23937',
        '23938',
        '23942',
        '23943',
        '23944',
        '23947',
        '23950',
        '23952',
        '23954',
        '23958',
        '23959',
        '23960',
        '23962',
        '23963',
        '23964',
        '23966',
        '23967',
        '23968',
        '23970',
        '23974',
        '23976',
        '24011',
        '24012',
        '24013',
        '24014',
        '24015',
        '24016',
        '24017',
        '24018',
        '24019',
        '24020',
        '24053',
        '24054',
        '24055',
        '24058',
        '24059',
        '24060',
        '24064',
        '24065',
        '24066',
        '24067',
        '24069',
        '24070',
        '24072',
        '24073',
        '24076',
        '24077',
        '24078',
        '24079',
        '24082',
        '24083',
        '24084',
        '24085',
        '24086',
        '24087',
        '24088',
        '24089',
        '24090',
        '24091',
        '24092',
        '24093',
        '24095',
        '24101',
        '24102',
        '24104',
        '24105',
        '24112',
        '24120',
        '24121',
        '24122',
        '24124',
        '24127',
        '24128',
        '24130',
        '24131',
        '24132',
        '24133',
        '24134',
        '24136',
        '24137',
        '24138',
        '24139',
        '24141',
        '24142',
        '24147',
        '24148',
        '24149',
        '24150',
        '24151',
        '24153',
        '24161',
        '24162',
        '24165',
        '24167',
        '24168',
        '24171',
        '24174',
        '24175',
        '24176',
        '24179',
        '24184',
        '24185',
        '24201',
        '24202',
        '24210',
        '24211',
        '24216',
        '24217',
        '24219',
        '24220',
        '24221',
        '24224',
        '24225',
        '24226',
        '24228',
        '24230',
        '24236',
        '24237',
        '24239',
        '24243',
        '24244',
        '24245',
        '24246',
        '24248',
        '24250',
        '24251',
        '24256',
        '24258',
        '24260',
        '24263',
        '24265',
        '24266',
        '24269',
        '24270',
        '24271',
        '24272',
        '24273',
        '24277',
        '24279',
        '24280',
        '24281',
        '24282',
        '24283',
        '24290',
        '24292',
        '24293',
        '24301',
        '24311',
        '24312',
        '24313',
        '24314',
        '24315',
        '24316',
        '24317',
        '24318',
        '24319',
        '24322',
        '24323',
        '24324',
        '24325',
        '24326',
        '24328',
        '24330',
        '24333',
        '24340',
        '24343',
        '24347',
        '24348',
        '24350',
        '24351',
        '24352',
        '24354',
        '24360',
        '24361',
        '24363',
        '24366',
        '24368',
        '24370',
        '24374',
        '24375',
        '24377',
        '24378',
        '24380',
        '24381',
        '24382',
        '24401',
        '24411',
        '24412',
        '24413',
        '24415',
        '24416',
        '24421',
        '24422',
        '24426',
        '24430',
        '24431',
        '24432',
        '24433',
        '24435',
        '24437',
        '24439',
        '24440',
        '24441',
        '24442',
        '24445',
        '24448',
        '24450',
        '24457',
        '24458',
        '24459',
        '24460',
        '24464',
        '24465',
        '24467',
        '24471',
        '24472',
        '24473',
        '24474',
        '24476',
        '24477',
        '24479',
        '24482',
        '24483',
        '24484',
        '24485',
        '24486',
        '24487',
        '24501',
        '24502',
        '24503',
        '24504',
        '24517',
        '24520',
        '24521',
        '24522',
        '24523',
        '24526',
        '24527',
        '24528',
        '24529',
        '24530',
        '24531',
        '24534',
        '24536',
        '24538',
        '24539',
        '24540',
        '24541',
        '24549',
        '24550',
        '24551',
        '24553',
        '24554',
        '24555',
        '24556',
        '24557',
        '24558',
        '24562',
        '24563',
        '24565',
        '24566',
        '24569',
        '24570',
        '24571',
        '24572',
        '24574',
        '24577',
        '24578',
        '24579',
        '24580',
        '24581',
        '24586',
        '24588',
        '24589',
        '24590',
        '24592',
        '24593',
        '24594',
        '24595',
        '24597',
        '24598',
        '24599',
        '24601',
        '24602',
        '24603',
        '24604',
        '24605',
        '24606',
        '24607',
        '24609',
        '24612',
        '24613',
        '24614',
        '24620',
        '24622',
        '24628',
        '24630',
        '24631',
        '24634',
        '24635',
        '24637',
        '24639',
        '24641',
        '24646',
        '24649',
        '24651',
        '24656',
        '24657'
      ]
    },
    WV: {
      name: 'West Virginia',
      codes: [
        '24701',
        '24712',
        '24714',
        '24715',
        '24716',
        '24719',
        '24724',
        '24726',
        '24729',
        '24731',
        '24733',
        '24736',
        '24737',
        '24738',
        '24740',
        '24747',
        '24801',
        '24808',
        '24811',
        '24813',
        '24815',
        '24816',
        '24817',
        '24818',
        '24822',
        '24823',
        '24826',
        '24827',
        '24828',
        '24830',
        '24831',
        '24834',
        '24836',
        '24839',
        '24843',
        '24844',
        '24845',
        '24846',
        '24847',
        '24848',
        '24849',
        '24850',
        '24851',
        '24853',
        '24854',
        '24857',
        '24860',
        '24861',
        '24862',
        '24866',
        '24867',
        '24868',
        '24869',
        '24870',
        '24871',
        '24872',
        '24873',
        '24874',
        '24878',
        '24879',
        '24880',
        '24881',
        '24882',
        '24884',
        '24887',
        '24888',
        '24892',
        '24894',
        '24898',
        '24901',
        '24910',
        '24915',
        '24916',
        '24918',
        '24920',
        '24924',
        '24925',
        '24927',
        '24931',
        '24934',
        '24935',
        '24938',
        '24941',
        '24944',
        '24945',
        '24946',
        '24951',
        '24954',
        '24957',
        '24962',
        '24963',
        '24966',
        '24970',
        '24974',
        '24976',
        '24977',
        '24981',
        '24983',
        '24984',
        '24986',
        '24991',
        '25002',
        '25003',
        '25005',
        '25007',
        '25008',
        '25009',
        '25011',
        '25015',
        '25019',
        '25021',
        '25022',
        '25024',
        '25025',
        '25028',
        '25030',
        '25031',
        '25033',
        '25035',
        '25036',
        '25039',
        '25040',
        '25043',
        '25044',
        '25045',
        '25047',
        '25048',
        '25049',
        '25051',
        '25053',
        '25054',
        '25057',
        '25059',
        '25060',
        '25061',
        '25062',
        '25063',
        '25064',
        '25067',
        '25070',
        '25071',
        '25075',
        '25076',
        '25081',
        '25082',
        '25083',
        '25085',
        '25086',
        '25088',
        '25090',
        '25093',
        '25102',
        '25103',
        '25106',
        '25107',
        '25108',
        '25109',
        '25110',
        '25111',
        '25112',
        '25113',
        '25114',
        '25115',
        '25118',
        '25119',
        '25121',
        '25123',
        '25124',
        '25125',
        '25126',
        '25130',
        '25132',
        '25133',
        '25134',
        '25136',
        '25139',
        '25140',
        '25141',
        '25142',
        '25143',
        '25148',
        '25149',
        '25152',
        '25154',
        '25156',
        '25159',
        '25160',
        '25161',
        '25162',
        '25164',
        '25165',
        '25168',
        '25169',
        '25173',
        '25174',
        '25177',
        '25180',
        '25181',
        '25183',
        '25185',
        '25186',
        '25187',
        '25193',
        '25201',
        '25202',
        '25203',
        '25204',
        '25205',
        '25206',
        '25208',
        '25209',
        '25211',
        '25213',
        '25214',
        '25234',
        '25235',
        '25239',
        '25241',
        '25243',
        '25244',
        '25245',
        '25247',
        '25248',
        '25251',
        '25252',
        '25253',
        '25259',
        '25260',
        '25261',
        '25262',
        '25264',
        '25265',
        '25266',
        '25267',
        '25268',
        '25270',
        '25271',
        '25275',
        '25276',
        '25285',
        '25286',
        '25287',
        '25301',
        '25302',
        '25303',
        '25304',
        '25305',
        '25306',
        '25309',
        '25311',
        '25312',
        '25313',
        '25314',
        '25315',
        '25320',
        '25401',
        '25403',
        '25404',
        '25405',
        '25411',
        '25413',
        '25414',
        '25419',
        '25420',
        '25422',
        '25425',
        '25427',
        '25428',
        '25430',
        '25431',
        '25432',
        '25434',
        '25437',
        '25438',
        '25442',
        '25443',
        '25444',
        '25446',
        '25501',
        '25502',
        '25503',
        '25504',
        '25505',
        '25506',
        '25507',
        '25508',
        '25510',
        '25511',
        '25512',
        '25514',
        '25515',
        '25517',
        '25520',
        '25521',
        '25523',
        '25524',
        '25526',
        '25529',
        '25530',
        '25534',
        '25535',
        '25537',
        '25540',
        '25541',
        '25545',
        '25547',
        '25550',
        '25555',
        '25557',
        '25559',
        '25560',
        '25564',
        '25565',
        '25567',
        '25570',
        '25571',
        '25573',
        '25601',
        '25606',
        '25607',
        '25608',
        '25611',
        '25617',
        '25621',
        '25624',
        '25625',
        '25628',
        '25630',
        '25632',
        '25634',
        '25635',
        '25637',
        '25638',
        '25639',
        '25644',
        '25646',
        '25647',
        '25649',
        '25650',
        '25651',
        '25652',
        '25653',
        '25654',
        '25661',
        '25666',
        '25669',
        '25670',
        '25671',
        '25672',
        '25674',
        '25676',
        '25678',
        '25688',
        '25690',
        '25692',
        '25696',
        '25699',
        '25701',
        '25702',
        '25703',
        '25704',
        '25705',
        '25801',
        '25810',
        '25811',
        '25812',
        '25813',
        '25817',
        '25818',
        '25820',
        '25823',
        '25825',
        '25826',
        '25827',
        '25831',
        '25832',
        '25836',
        '25837',
        '25839',
        '25840',
        '25841',
        '25843',
        '25844',
        '25845',
        '25846',
        '25848',
        '25849',
        '25853',
        '25854',
        '25855',
        '25857',
        '25862',
        '25864',
        '25865',
        '25868',
        '25870',
        '25871',
        '25873',
        '25875',
        '25876',
        '25878',
        '25879',
        '25880',
        '25882',
        '25901',
        '25902',
        '25904',
        '25906',
        '25907',
        '25908',
        '25911',
        '25913',
        '25915',
        '25916',
        '25917',
        '25918',
        '25920',
        '25921',
        '25922',
        '25928',
        '25932',
        '25936',
        '25938',
        '25942',
        '25951',
        '25958',
        '25962',
        '25969',
        '25971',
        '25972',
        '25976',
        '25977',
        '25978',
        '25979',
        '25981',
        '25984',
        '25985',
        '25989',
        '26003',
        '26030',
        '26031',
        '26032',
        '26033',
        '26034',
        '26035',
        '26036',
        '26037',
        '26038',
        '26039',
        '26040',
        '26041',
        '26047',
        '26050',
        '26055',
        '26056',
        '26059',
        '26060',
        '26062',
        '26070',
        '26074',
        '26075',
        '26101',
        '26104',
        '26105',
        '26133',
        '26134',
        '26136',
        '26137',
        '26138',
        '26141',
        '26142',
        '26143',
        '26146',
        '26147',
        '26148',
        '26149',
        '26150',
        '26151',
        '26152',
        '26155',
        '26159',
        '26160',
        '26161',
        '26164',
        '26167',
        '26169',
        '26170',
        '26175',
        '26178',
        '26180',
        '26181',
        '26184',
        '26187',
        '26201',
        '26202',
        '26203',
        '26205',
        '26206',
        '26208',
        '26209',
        '26215',
        '26217',
        '26218',
        '26222',
        '26224',
        '26228',
        '26230',
        '26234',
        '26236',
        '26237',
        '26238',
        '26241',
        '26250',
        '26253',
        '26254',
        '26257',
        '26259',
        '26260',
        '26261',
        '26263',
        '26264',
        '26266',
        '26267',
        '26268',
        '26269',
        '26270',
        '26271',
        '26273',
        '26275',
        '26276',
        '26278',
        '26280',
        '26282',
        '26283',
        '26285',
        '26287',
        '26288',
        '26291',
        '26292',
        '26293',
        '26294',
        '26296',
        '26298',
        '26301',
        '26320',
        '26321',
        '26323',
        '26325',
        '26327',
        '26330',
        '26335',
        '26337',
        '26338',
        '26339',
        '26342',
        '26343',
        '26346',
        '26347',
        '26348',
        '26349',
        '26351',
        '26354',
        '26361',
        '26362',
        '26366',
        '26369',
        '26372',
        '26374',
        '26376',
        '26377',
        '26378',
        '26384',
        '26385',
        '26386',
        '26404',
        '26405',
        '26408',
        '26410',
        '26411',
        '26412',
        '26415',
        '26416',
        '26419',
        '26421',
        '26422',
        '26424',
        '26425',
        '26426',
        '26430',
        '26431',
        '26435',
        '26436',
        '26437',
        '26438',
        '26440',
        '26443',
        '26444',
        '26447',
        '26448',
        '26451',
        '26452',
        '26456',
        '26501',
        '26505',
        '26508',
        '26519',
        '26520',
        '26521',
        '26525',
        '26534',
        '26537',
        '26541',
        '26542',
        '26543',
        '26547',
        '26554',
        '26559',
        '26560',
        '26562',
        '26563',
        '26568',
        '26570',
        '26571',
        '26572',
        '26574',
        '26575',
        '26576',
        '26581',
        '26582',
        '26585',
        '26586',
        '26587',
        '26588',
        '26590',
        '26591',
        '26601',
        '26610',
        '26611',
        '26615',
        '26617',
        '26619',
        '26621',
        '26623',
        '26624',
        '26627',
        '26629',
        '26631',
        '26636',
        '26638',
        '26651',
        '26656',
        '26660',
        '26662',
        '26676',
        '26678',
        '26679',
        '26680',
        '26681',
        '26684',
        '26690',
        '26691',
        '26704',
        '26705',
        '26707',
        '26710',
        '26711',
        '26714',
        '26716',
        '26717',
        '26719',
        '26720',
        '26722',
        '26726',
        '26731',
        '26739',
        '26743',
        '26750',
        '26753',
        '26755',
        '26757',
        '26761',
        '26763',
        '26764',
        '26767',
        '26801',
        '26802',
        '26804',
        '26807',
        '26808',
        '26810',
        '26812',
        '26814',
        '26815',
        '26817',
        '26818',
        '26823',
        '26833',
        '26836',
        '26845',
        '26847',
        '26851',
        '26852',
        '26855',
        '26865',
        '26866',
        '26884'
      ]
    },
    NC: {
      name: 'North Carolina',
      codes: [
        '27006',
        '27007',
        '27009',
        '27011',
        '27012',
        '27013',
        '27014',
        '27016',
        '27017',
        '27018',
        '27019',
        '27020',
        '27021',
        '27022',
        '27023',
        '27024',
        '27025',
        '27027',
        '27028',
        '27030',
        '27040',
        '27041',
        '27042',
        '27043',
        '27045',
        '27046',
        '27047',
        '27048',
        '27050',
        '27051',
        '27052',
        '27053',
        '27054',
        '27055',
        '27101',
        '27103',
        '27104',
        '27105',
        '27106',
        '27107',
        '27109',
        '27110',
        '27127',
        '27201',
        '27203',
        '27205',
        '27207',
        '27208',
        '27209',
        '27212',
        '27214',
        '27215',
        '27217',
        '27229',
        '27231',
        '27233',
        '27235',
        '27239',
        '27242',
        '27243',
        '27244',
        '27248',
        '27249',
        '27252',
        '27253',
        '27258',
        '27260',
        '27262',
        '27263',
        '27265',
        '27278',
        '27281',
        '27282',
        '27283',
        '27284',
        '27288',
        '27291',
        '27292',
        '27295',
        '27298',
        '27299',
        '27301',
        '27302',
        '27305',
        '27306',
        '27310',
        '27311',
        '27312',
        '27313',
        '27314',
        '27315',
        '27316',
        '27317',
        '27320',
        '27325',
        '27326',
        '27330',
        '27332',
        '27340',
        '27341',
        '27343',
        '27344',
        '27349',
        '27350',
        '27355',
        '27356',
        '27357',
        '27358',
        '27360',
        '27370',
        '27371',
        '27376',
        '27377',
        '27379',
        '27401',
        '27403',
        '27405',
        '27406',
        '27407',
        '27408',
        '27409',
        '27410',
        '27455',
        '27501',
        '27502',
        '27503',
        '27504',
        '27505',
        '27507',
        '27508',
        '27509',
        '27510',
        '27511',
        '27513',
        '27514',
        '27516',
        '27517',
        '27518',
        '27519',
        '27520',
        '27521',
        '27522',
        '27523',
        '27524',
        '27525',
        '27526',
        '27527',
        '27529',
        '27530',
        '27531',
        '27534',
        '27536',
        '27537',
        '27539',
        '27540',
        '27541',
        '27542',
        '27544',
        '27545',
        '27546',
        '27549',
        '27551',
        '27553',
        '27555',
        '27556',
        '27557',
        '27559',
        '27560',
        '27562',
        '27563',
        '27565',
        '27568',
        '27569',
        '27571',
        '27572',
        '27573',
        '27574',
        '27576',
        '27577',
        '27581',
        '27582',
        '27583',
        '27587',
        '27589',
        '27591',
        '27592',
        '27596',
        '27597',
        '27601',
        '27603',
        '27604',
        '27605',
        '27606',
        '27607',
        '27608',
        '27609',
        '27610',
        '27612',
        '27613',
        '27614',
        '27615',
        '27616',
        '27617',
        '27701',
        '27703',
        '27704',
        '27705',
        '27707',
        '27709',
        '27712',
        '27713',
        '27801',
        '27803',
        '27804',
        '27805',
        '27806',
        '27807',
        '27808',
        '27809',
        '27810',
        '27812',
        '27813',
        '27814',
        '27816',
        '27817',
        '27818',
        '27819',
        '27820',
        '27821',
        '27822',
        '27823',
        '27824',
        '27825',
        '27826',
        '27827',
        '27828',
        '27829',
        '27830',
        '27831',
        '27832',
        '27834',
        '27837',
        '27839',
        '27840',
        '27841',
        '27842',
        '27843',
        '27844',
        '27845',
        '27846',
        '27847',
        '27849',
        '27850',
        '27851',
        '27852',
        '27853',
        '27855',
        '27856',
        '27857',
        '27858',
        '27860',
        '27861',
        '27862',
        '27863',
        '27864',
        '27865',
        '27866',
        '27869',
        '27870',
        '27871',
        '27872',
        '27873',
        '27874',
        '27875',
        '27876',
        '27878',
        '27879',
        '27880',
        '27881',
        '27882',
        '27883',
        '27884',
        '27885',
        '27886',
        '27888',
        '27889',
        '27890',
        '27891',
        '27892',
        '27893',
        '27896',
        '27897',
        '27909',
        '27910',
        '27915',
        '27916',
        '27917',
        '27919',
        '27920',
        '27921',
        '27922',
        '27923',
        '27924',
        '27925',
        '27926',
        '27927',
        '27928',
        '27929',
        '27932',
        '27935',
        '27936',
        '27937',
        '27938',
        '27939',
        '27941',
        '27942',
        '27943',
        '27944',
        '27946',
        '27947',
        '27948',
        '27949',
        '27950',
        '27953',
        '27954',
        '27956',
        '27957',
        '27958',
        '27959',
        '27960',
        '27962',
        '27964',
        '27965',
        '27966',
        '27967',
        '27968',
        '27970',
        '27972',
        '27973',
        '27974',
        '27976',
        '27978',
        '27979',
        '27980',
        '27981',
        '27982',
        '27983',
        '27985',
        '27986',
        '28001',
        '28006',
        '28007',
        '28009',
        '28012',
        '28016',
        '28017',
        '28018',
        '28019',
        '28020',
        '28021',
        '28023',
        '28025',
        '28027',
        '28031',
        '28032',
        '28033',
        '28034',
        '28036',
        '28037',
        '28039',
        '28040',
        '28043',
        '28052',
        '28054',
        '28056',
        '28071',
        '28072',
        '28073',
        '28075',
        '28076',
        '28077',
        '28078',
        '28079',
        '28080',
        '28081',
        '28083',
        '28086',
        '28088',
        '28089',
        '28090',
        '28091',
        '28092',
        '28097',
        '28098',
        '28101',
        '28102',
        '28103',
        '28104',
        '28105',
        '28107',
        '28108',
        '28109',
        '28110',
        '28112',
        '28114',
        '28115',
        '28117',
        '28119',
        '28120',
        '28124',
        '28125',
        '28127',
        '28128',
        '28129',
        '28133',
        '28134',
        '28135',
        '28137',
        '28138',
        '28139',
        '28144',
        '28146',
        '28147',
        '28150',
        '28152',
        '28159',
        '28160',
        '28163',
        '28164',
        '28166',
        '28167',
        '28168',
        '28169',
        '28170',
        '28173',
        '28174',
        '28202',
        '28203',
        '28204',
        '28205',
        '28206',
        '28207',
        '28208',
        '28209',
        '28210',
        '28211',
        '28212',
        '28213',
        '28214',
        '28215',
        '28216',
        '28217',
        '28226',
        '28227',
        '28244',
        '28262',
        '28269',
        '28270',
        '28273',
        '28277',
        '28278',
        '28280',
        '28282',
        '28301',
        '28303',
        '28304',
        '28305',
        '28306',
        '28307',
        '28308',
        '28310',
        '28311',
        '28312',
        '28314',
        '28315',
        '28318',
        '28320',
        '28323',
        '28325',
        '28326',
        '28327',
        '28328',
        '28330',
        '28332',
        '28333',
        '28334',
        '28337',
        '28338',
        '28339',
        '28340',
        '28341',
        '28342',
        '28343',
        '28344',
        '28345',
        '28347',
        '28348',
        '28349',
        '28350',
        '28351',
        '28352',
        '28356',
        '28357',
        '28358',
        '28360',
        '28363',
        '28364',
        '28365',
        '28366',
        '28367',
        '28369',
        '28371',
        '28372',
        '28373',
        '28374',
        '28375',
        '28376',
        '28377',
        '28379',
        '28382',
        '28383',
        '28384',
        '28385',
        '28386',
        '28387',
        '28390',
        '28391',
        '28392',
        '28393',
        '28394',
        '28395',
        '28396',
        '28398',
        '28399',
        '28401',
        '28403',
        '28405',
        '28409',
        '28411',
        '28412',
        '28420',
        '28421',
        '28422',
        '28423',
        '28424',
        '28425',
        '28428',
        '28429',
        '28430',
        '28431',
        '28432',
        '28433',
        '28434',
        '28435',
        '28436',
        '28438',
        '28439',
        '28441',
        '28442',
        '28443',
        '28444',
        '28445',
        '28447',
        '28448',
        '28449',
        '28450',
        '28451',
        '28452',
        '28453',
        '28454',
        '28455',
        '28456',
        '28457',
        '28458',
        '28460',
        '28461',
        '28462',
        '28463',
        '28464',
        '28465',
        '28466',
        '28467',
        '28468',
        '28469',
        '28470',
        '28472',
        '28478',
        '28479',
        '28480',
        '28501',
        '28504',
        '28508',
        '28510',
        '28511',
        '28512',
        '28513',
        '28515',
        '28516',
        '28518',
        '28519',
        '28520',
        '28521',
        '28523',
        '28524',
        '28525',
        '28526',
        '28527',
        '28528',
        '28529',
        '28530',
        '28531',
        '28532',
        '28533',
        '28537',
        '28538',
        '28539',
        '28540',
        '28543',
        '28544',
        '28546',
        '28547',
        '28551',
        '28552',
        '28553',
        '28554',
        '28555',
        '28556',
        '28557',
        '28560',
        '28562',
        '28570',
        '28571',
        '28572',
        '28573',
        '28574',
        '28575',
        '28577',
        '28578',
        '28579',
        '28580',
        '28581',
        '28582',
        '28583',
        '28584',
        '28585',
        '28586',
        '28587',
        '28589',
        '28590',
        '28594',
        '28601',
        '28602',
        '28604',
        '28605',
        '28606',
        '28607',
        '28609',
        '28610',
        '28611',
        '28612',
        '28613',
        '28615',
        '28616',
        '28617',
        '28618',
        '28619',
        '28621',
        '28622',
        '28623',
        '28624',
        '28625',
        '28626',
        '28627',
        '28628',
        '28629',
        '28630',
        '28631',
        '28634',
        '28635',
        '28636',
        '28637',
        '28638',
        '28640',
        '28642',
        '28643',
        '28644',
        '28645',
        '28646',
        '28647',
        '28649',
        '28650',
        '28651',
        '28652',
        '28654',
        '28655',
        '28657',
        '28658',
        '28659',
        '28660',
        '28662',
        '28663',
        '28665',
        '28666',
        '28667',
        '28668',
        '28669',
        '28670',
        '28671',
        '28672',
        '28673',
        '28675',
        '28676',
        '28677',
        '28678',
        '28679',
        '28681',
        '28682',
        '28683',
        '28684',
        '28685',
        '28689',
        '28690',
        '28692',
        '28693',
        '28694',
        '28697',
        '28698',
        '28701',
        '28702',
        '28704',
        '28705',
        '28707',
        '28708',
        '28709',
        '28711',
        '28712',
        '28713',
        '28714',
        '28715',
        '28716',
        '28717',
        '28718',
        '28719',
        '28720',
        '28721',
        '28722',
        '28723',
        '28725',
        '28726',
        '28729',
        '28730',
        '28731',
        '28732',
        '28733',
        '28734',
        '28735',
        '28736',
        '28739',
        '28740',
        '28741',
        '28742',
        '28743',
        '28745',
        '28746',
        '28747',
        '28748',
        '28749',
        '28751',
        '28752',
        '28753',
        '28754',
        '28756',
        '28757',
        '28759',
        '28761',
        '28762',
        '28763',
        '28766',
        '28768',
        '28771',
        '28772',
        '28773',
        '28774',
        '28775',
        '28777',
        '28778',
        '28779',
        '28781',
        '28782',
        '28783',
        '28785',
        '28786',
        '28787',
        '28789',
        '28790',
        '28791',
        '28792',
        '28801',
        '28803',
        '28804',
        '28805',
        '28806',
        '28901',
        '28902',
        '28904',
        '28905',
        '28906',
        '28909'
      ]
    },
    SC: {
      name: 'South Carolina',
      codes: [
        '29001',
        '29003',
        '29006',
        '29009',
        '29010',
        '29014',
        '29015',
        '29016',
        '29018',
        '29020',
        '29030',
        '29031',
        '29032',
        '29033',
        '29036',
        '29037',
        '29038',
        '29039',
        '29040',
        '29042',
        '29044',
        '29045',
        '29046',
        '29047',
        '29048',
        '29051',
        '29052',
        '29053',
        '29054',
        '29055',
        '29056',
        '29058',
        '29059',
        '29061',
        '29062',
        '29063',
        '29065',
        '29067',
        '29069',
        '29070',
        '29072',
        '29073',
        '29074',
        '29075',
        '29078',
        '29079',
        '29080',
        '29081',
        '29082',
        '29101',
        '29102',
        '29104',
        '29105',
        '29107',
        '29108',
        '29111',
        '29112',
        '29113',
        '29114',
        '29115',
        '29117',
        '29118',
        '29122',
        '29123',
        '29125',
        '29126',
        '29127',
        '29128',
        '29129',
        '29130',
        '29133',
        '29135',
        '29137',
        '29138',
        '29142',
        '29145',
        '29146',
        '29147',
        '29148',
        '29150',
        '29152',
        '29153',
        '29154',
        '29160',
        '29161',
        '29162',
        '29163',
        '29164',
        '29166',
        '29168',
        '29169',
        '29170',
        '29172',
        '29175',
        '29178',
        '29180',
        '29201',
        '29202',
        '29203',
        '29204',
        '29205',
        '29206',
        '29207',
        '29208',
        '29209',
        '29210',
        '29212',
        '29223',
        '29225',
        '29229',
        '29301',
        '29302',
        '29303',
        '29306',
        '29307',
        '29316',
        '29320',
        '29321',
        '29322',
        '29323',
        '29324',
        '29325',
        '29329',
        '29330',
        '29331',
        '29332',
        '29333',
        '29334',
        '29335',
        '29338',
        '29340',
        '29341',
        '29346',
        '29349',
        '29351',
        '29353',
        '29355',
        '29356',
        '29360',
        '29364',
        '29365',
        '29368',
        '29369',
        '29370',
        '29372',
        '29373',
        '29374',
        '29375',
        '29376',
        '29377',
        '29378',
        '29379',
        '29384',
        '29385',
        '29388',
        '29401',
        '29403',
        '29404',
        '29405',
        '29406',
        '29407',
        '29409',
        '29410',
        '29412',
        '29414',
        '29418',
        '29420',
        '29423',
        '29424',
        '29426',
        '29429',
        '29431',
        '29432',
        '29434',
        '29435',
        '29436',
        '29437',
        '29438',
        '29439',
        '29440',
        '29445',
        '29446',
        '29448',
        '29449',
        '29450',
        '29451',
        '29452',
        '29453',
        '29455',
        '29456',
        '29458',
        '29461',
        '29464',
        '29466',
        '29468',
        '29469',
        '29470',
        '29471',
        '29472',
        '29474',
        '29475',
        '29477',
        '29479',
        '29481',
        '29482',
        '29483',
        '29485',
        '29487',
        '29488',
        '29492',
        '29493',
        '29501',
        '29505',
        '29506',
        '29510',
        '29511',
        '29512',
        '29516',
        '29518',
        '29519',
        '29520',
        '29525',
        '29526',
        '29527',
        '29530',
        '29532',
        '29536',
        '29540',
        '29541',
        '29543',
        '29544',
        '29545',
        '29546',
        '29547',
        '29550',
        '29554',
        '29555',
        '29556',
        '29560',
        '29563',
        '29564',
        '29565',
        '29566',
        '29567',
        '29568',
        '29569',
        '29570',
        '29571',
        '29572',
        '29574',
        '29575',
        '29576',
        '29577',
        '29579',
        '29580',
        '29581',
        '29582',
        '29583',
        '29584',
        '29585',
        '29588',
        '29590',
        '29591',
        '29592',
        '29593',
        '29594',
        '29596',
        '29601',
        '29605',
        '29607',
        '29609',
        '29611',
        '29613',
        '29614',
        '29615',
        '29617',
        '29620',
        '29621',
        '29624',
        '29625',
        '29626',
        '29627',
        '29628',
        '29630',
        '29631',
        '29634',
        '29635',
        '29638',
        '29639',
        '29640',
        '29642',
        '29643',
        '29644',
        '29645',
        '29646',
        '29649',
        '29650',
        '29651',
        '29653',
        '29654',
        '29655',
        '29657',
        '29658',
        '29659',
        '29661',
        '29662',
        '29664',
        '29665',
        '29666',
        '29667',
        '29669',
        '29670',
        '29671',
        '29672',
        '29673',
        '29676',
        '29678',
        '29680',
        '29681',
        '29682',
        '29683',
        '29684',
        '29685',
        '29686',
        '29687',
        '29689',
        '29690',
        '29691',
        '29692',
        '29693',
        '29696',
        '29697',
        '29702',
        '29704',
        '29706',
        '29707',
        '29708',
        '29709',
        '29710',
        '29712',
        '29714',
        '29715',
        '29717',
        '29718',
        '29720',
        '29724',
        '29726',
        '29727',
        '29728',
        '29729',
        '29730',
        '29732',
        '29733',
        '29741',
        '29742',
        '29743',
        '29745',
        '29801',
        '29803',
        '29805',
        '29809',
        '29810',
        '29812',
        '29816',
        '29817',
        '29819',
        '29821',
        '29824',
        '29826',
        '29827',
        '29828',
        '29829',
        '29831',
        '29832',
        '29834',
        '29835',
        '29836',
        '29838',
        '29840',
        '29841',
        '29842',
        '29843',
        '29844',
        '29845',
        '29847',
        '29848',
        '29849',
        '29850',
        '29851',
        '29853',
        '29856',
        '29860',
        '29899',
        '29902',
        '29904',
        '29905',
        '29906',
        '29907',
        '29909',
        '29910',
        '29911',
        '29912',
        '29915',
        '29916',
        '29918',
        '29920',
        '29921',
        '29922',
        '29923',
        '29924',
        '29926',
        '29927',
        '29928',
        '29929',
        '29932',
        '29934',
        '29935',
        '29936',
        '29939',
        '29940',
        '29941',
        '29943',
        '29944',
        '29945'
      ]
    },
    GA: {
      name: 'Georgia',
      codes: [
        '30002',
        '30004',
        '30005',
        '30008',
        '30009',
        '30011',
        '30012',
        '30013',
        '30014',
        '30016',
        '30017',
        '30019',
        '30021',
        '30022',
        '30024',
        '30025',
        '30028',
        '30030',
        '30032',
        '30033',
        '30034',
        '30035',
        '30038',
        '30039',
        '30040',
        '30041',
        '30043',
        '30044',
        '30045',
        '30046',
        '30047',
        '30052',
        '30054',
        '30055',
        '30056',
        '30058',
        '30060',
        '30062',
        '30064',
        '30066',
        '30067',
        '30068',
        '30070',
        '30071',
        '30072',
        '30075',
        '30076',
        '30078',
        '30079',
        '30080',
        '30082',
        '30083',
        '30084',
        '30087',
        '30088',
        '30092',
        '30093',
        '30094',
        '30096',
        '30097',
        '30101',
        '30102',
        '30103',
        '30104',
        '30105',
        '30106',
        '30107',
        '30108',
        '30110',
        '30113',
        '30114',
        '30115',
        '30116',
        '30117',
        '30118',
        '30120',
        '30121',
        '30122',
        '30124',
        '30125',
        '30126',
        '30127',
        '30132',
        '30134',
        '30135',
        '30137',
        '30139',
        '30141',
        '30143',
        '30144',
        '30145',
        '30147',
        '30148',
        '30149',
        '30152',
        '30153',
        '30157',
        '30161',
        '30164',
        '30165',
        '30168',
        '30170',
        '30171',
        '30173',
        '30175',
        '30176',
        '30177',
        '30178',
        '30179',
        '30180',
        '30182',
        '30183',
        '30184',
        '30185',
        '30187',
        '30188',
        '30189',
        '30204',
        '30205',
        '30206',
        '30213',
        '30214',
        '30215',
        '30216',
        '30217',
        '30218',
        '30220',
        '30222',
        '30223',
        '30224',
        '30228',
        '30230',
        '30233',
        '30234',
        '30236',
        '30238',
        '30240',
        '30241',
        '30248',
        '30250',
        '30251',
        '30252',
        '30253',
        '30256',
        '30257',
        '30258',
        '30259',
        '30260',
        '30263',
        '30265',
        '30268',
        '30269',
        '30273',
        '30274',
        '30275',
        '30276',
        '30277',
        '30281',
        '30284',
        '30285',
        '30286',
        '30288',
        '30289',
        '30290',
        '30291',
        '30292',
        '30293',
        '30294',
        '30295',
        '30296',
        '30297',
        '30303',
        '30305',
        '30306',
        '30307',
        '30308',
        '30309',
        '30310',
        '30311',
        '30312',
        '30313',
        '30314',
        '30315',
        '30316',
        '30317',
        '30318',
        '30319',
        '30322',
        '30324',
        '30326',
        '30327',
        '30328',
        '30329',
        '30331',
        '30332',
        '30334',
        '30336',
        '30337',
        '30338',
        '30339',
        '30340',
        '30341',
        '30342',
        '30344',
        '30345',
        '30346',
        '30349',
        '30350',
        '30354',
        '30360',
        '30363',
        '30401',
        '30410',
        '30411',
        '30412',
        '30413',
        '30415',
        '30417',
        '30420',
        '30421',
        '30423',
        '30425',
        '30426',
        '30427',
        '30428',
        '30429',
        '30434',
        '30436',
        '30438',
        '30439',
        '30441',
        '30442',
        '30445',
        '30446',
        '30448',
        '30449',
        '30450',
        '30451',
        '30452',
        '30453',
        '30454',
        '30455',
        '30456',
        '30457',
        '30458',
        '30460',
        '30461',
        '30464',
        '30467',
        '30470',
        '30471',
        '30473',
        '30474',
        '30475',
        '30477',
        '30501',
        '30504',
        '30506',
        '30507',
        '30510',
        '30511',
        '30512',
        '30513',
        '30516',
        '30517',
        '30518',
        '30519',
        '30520',
        '30521',
        '30522',
        '30523',
        '30525',
        '30527',
        '30528',
        '30529',
        '30530',
        '30531',
        '30533',
        '30534',
        '30535',
        '30536',
        '30537',
        '30538',
        '30540',
        '30541',
        '30542',
        '30543',
        '30545',
        '30546',
        '30547',
        '30548',
        '30549',
        '30552',
        '30553',
        '30554',
        '30555',
        '30557',
        '30558',
        '30559',
        '30560',
        '30562',
        '30563',
        '30564',
        '30565',
        '30566',
        '30567',
        '30568',
        '30571',
        '30572',
        '30573',
        '30575',
        '30576',
        '30577',
        '30581',
        '30582',
        '30601',
        '30602',
        '30605',
        '30606',
        '30607',
        '30609',
        '30619',
        '30620',
        '30621',
        '30622',
        '30623',
        '30624',
        '30625',
        '30627',
        '30628',
        '30629',
        '30630',
        '30631',
        '30633',
        '30634',
        '30635',
        '30641',
        '30642',
        '30643',
        '30646',
        '30648',
        '30650',
        '30655',
        '30656',
        '30660',
        '30662',
        '30663',
        '30664',
        '30665',
        '30666',
        '30667',
        '30668',
        '30669',
        '30673',
        '30677',
        '30678',
        '30680',
        '30683',
        '30701',
        '30705',
        '30707',
        '30708',
        '30710',
        '30711',
        '30720',
        '30721',
        '30725',
        '30726',
        '30728',
        '30730',
        '30731',
        '30733',
        '30734',
        '30735',
        '30736',
        '30738',
        '30739',
        '30740',
        '30741',
        '30742',
        '30746',
        '30747',
        '30750',
        '30751',
        '30752',
        '30753',
        '30755',
        '30756',
        '30757',
        '30802',
        '30803',
        '30805',
        '30807',
        '30808',
        '30809',
        '30810',
        '30812',
        '30813',
        '30814',
        '30815',
        '30816',
        '30817',
        '30818',
        '30820',
        '30821',
        '30822',
        '30823',
        '30824',
        '30828',
        '30830',
        '30833',
        '30901',
        '30903',
        '30904',
        '30905',
        '30906',
        '30907',
        '30909',
        '30912',
        '31001',
        '31002',
        '31003',
        '31005',
        '31006',
        '31007',
        '31008',
        '31009',
        '31011',
        '31012',
        '31014',
        '31015',
        '31016',
        '31017',
        '31018',
        '31019',
        '31020',
        '31021',
        '31022',
        '31023',
        '31024',
        '31025',
        '31027',
        '31028',
        '31029',
        '31030',
        '31031',
        '31032',
        '31033',
        '31035',
        '31036',
        '31037',
        '31038',
        '31039',
        '31041',
        '31042',
        '31044',
        '31045',
        '31046',
        '31047',
        '31049',
        '31050',
        '31051',
        '31052',
        '31054',
        '31055',
        '31057',
        '31058',
        '31060',
        '31061',
        '31062',
        '31063',
        '31064',
        '31065',
        '31066',
        '31067',
        '31068',
        '31069',
        '31070',
        '31071',
        '31072',
        '31075',
        '31076',
        '31077',
        '31078',
        '31079',
        '31081',
        '31082',
        '31083',
        '31084',
        '31085',
        '31087',
        '31088',
        '31089',
        '31090',
        '31091',
        '31092',
        '31093',
        '31094',
        '31096',
        '31097',
        '31098',
        '31201',
        '31204',
        '31206',
        '31207',
        '31210',
        '31211',
        '31213',
        '31216',
        '31217',
        '31220',
        '31301',
        '31302',
        '31303',
        '31304',
        '31305',
        '31307',
        '31308',
        '31309',
        '31312',
        '31313',
        '31314',
        '31315',
        '31316',
        '31318',
        '31320',
        '31321',
        '31322',
        '31323',
        '31324',
        '31326',
        '31327',
        '31328',
        '31329',
        '31331',
        '31401',
        '31404',
        '31405',
        '31406',
        '31407',
        '31408',
        '31409',
        '31410',
        '31411',
        '31415',
        '31419',
        '31501',
        '31503',
        '31510',
        '31512',
        '31513',
        '31516',
        '31518',
        '31519',
        '31520',
        '31522',
        '31523',
        '31524',
        '31525',
        '31527',
        '31532',
        '31533',
        '31535',
        '31537',
        '31539',
        '31542',
        '31543',
        '31544',
        '31545',
        '31546',
        '31547',
        '31548',
        '31549',
        '31550',
        '31551',
        '31552',
        '31553',
        '31554',
        '31555',
        '31556',
        '31557',
        '31558',
        '31560',
        '31561',
        '31562',
        '31563',
        '31565',
        '31566',
        '31567',
        '31568',
        '31569',
        '31601',
        '31602',
        '31605',
        '31606',
        '31620',
        '31622',
        '31623',
        '31624',
        '31625',
        '31626',
        '31627',
        '31629',
        '31630',
        '31631',
        '31632',
        '31634',
        '31635',
        '31636',
        '31637',
        '31638',
        '31639',
        '31641',
        '31642',
        '31643',
        '31645',
        '31647',
        '31648',
        '31649',
        '31650',
        '31698',
        '31699',
        '31701',
        '31705',
        '31707',
        '31709',
        '31711',
        '31712',
        '31714',
        '31716',
        '31719',
        '31720',
        '31721',
        '31722',
        '31730',
        '31733',
        '31735',
        '31738',
        '31743',
        '31744',
        '31747',
        '31749',
        '31750',
        '31756',
        '31757',
        '31763',
        '31764',
        '31765',
        '31768',
        '31771',
        '31772',
        '31773',
        '31774',
        '31775',
        '31778',
        '31779',
        '31780',
        '31781',
        '31783',
        '31784',
        '31787',
        '31788',
        '31789',
        '31790',
        '31791',
        '31792',
        '31793',
        '31794',
        '31795',
        '31796',
        '31798',
        '31801',
        '31803',
        '31804',
        '31805',
        '31806',
        '31807',
        '31808',
        '31810',
        '31811',
        '31812',
        '31814',
        '31815',
        '31816',
        '31820',
        '31821',
        '31822',
        '31823',
        '31824',
        '31825',
        '31826',
        '31827',
        '31829',
        '31830',
        '31831',
        '31832',
        '31833',
        '31836',
        '31901',
        '31903',
        '31904',
        '31905',
        '31906',
        '31907',
        '31909',
        '39813',
        '39815',
        '39817',
        '39819',
        '39823',
        '39824',
        '39825',
        '39826',
        '39827',
        '39828',
        '39834',
        '39836',
        '39837',
        '39840',
        '39841',
        '39842',
        '39845',
        '39846',
        '39851',
        '39854',
        '39859',
        '39861',
        '39862',
        '39866',
        '39867',
        '39870',
        '39877',
        '39885',
        '39886',
        '39897'
      ]
    },
    FL: {
      name: 'Florida',
      codes: [
        '32003',
        '32008',
        '32009',
        '32011',
        '32024',
        '32025',
        '32033',
        '32034',
        '32038',
        '32040',
        '32043',
        '32044',
        '32046',
        '32052',
        '32053',
        '32054',
        '32055',
        '32058',
        '32059',
        '32060',
        '32061',
        '32062',
        '32063',
        '32064',
        '32065',
        '32066',
        '32068',
        '32071',
        '32073',
        '32079',
        '32080',
        '32081',
        '32082',
        '32083',
        '32084',
        '32086',
        '32087',
        '32091',
        '32092',
        '32094',
        '32095',
        '32096',
        '32097',
        '32102',
        '32110',
        '32112',
        '32113',
        '32114',
        '32117',
        '32118',
        '32119',
        '32124',
        '32127',
        '32128',
        '32129',
        '32130',
        '32131',
        '32132',
        '32133',
        '32134',
        '32136',
        '32137',
        '32139',
        '32140',
        '32141',
        '32145',
        '32147',
        '32148',
        '32157',
        '32159',
        '32162',
        '32164',
        '32168',
        '32169',
        '32174',
        '32176',
        '32177',
        '32179',
        '32180',
        '32181',
        '32187',
        '32189',
        '32190',
        '32193',
        '32195',
        '32202',
        '32204',
        '32205',
        '32206',
        '32207',
        '32208',
        '32209',
        '32210',
        '32211',
        '32212',
        '32216',
        '32217',
        '32218',
        '32219',
        '32220',
        '32221',
        '32222',
        '32223',
        '32224',
        '32225',
        '32226',
        '32227',
        '32228',
        '32233',
        '32234',
        '32244',
        '32246',
        '32250',
        '32254',
        '32256',
        '32257',
        '32258',
        '32259',
        '32266',
        '32277',
        '32301',
        '32303',
        '32304',
        '32305',
        '32308',
        '32309',
        '32310',
        '32311',
        '32312',
        '32317',
        '32320',
        '32321',
        '32322',
        '32323',
        '32324',
        '32327',
        '32328',
        '32330',
        '32331',
        '32332',
        '32333',
        '32334',
        '32336',
        '32340',
        '32343',
        '32344',
        '32346',
        '32347',
        '32348',
        '32350',
        '32351',
        '32352',
        '32355',
        '32356',
        '32358',
        '32359',
        '32361',
        '32399',
        '32401',
        '32403',
        '32404',
        '32405',
        '32407',
        '32408',
        '32409',
        '32410',
        '32413',
        '32420',
        '32421',
        '32423',
        '32424',
        '32425',
        '32426',
        '32427',
        '32428',
        '32430',
        '32431',
        '32432',
        '32433',
        '32435',
        '32437',
        '32438',
        '32439',
        '32440',
        '32442',
        '32443',
        '32444',
        '32445',
        '32446',
        '32447',
        '32448',
        '32449',
        '32455',
        '32456',
        '32459',
        '32460',
        '32461',
        '32462',
        '32463',
        '32464',
        '32465',
        '32466',
        '32501',
        '32502',
        '32503',
        '32504',
        '32505',
        '32506',
        '32507',
        '32508',
        '32509',
        '32511',
        '32514',
        '32526',
        '32530',
        '32531',
        '32533',
        '32534',
        '32535',
        '32536',
        '32539',
        '32541',
        '32542',
        '32544',
        '32547',
        '32548',
        '32550',
        '32561',
        '32563',
        '32564',
        '32565',
        '32566',
        '32567',
        '32568',
        '32569',
        '32570',
        '32571',
        '32577',
        '32578',
        '32579',
        '32580',
        '32583',
        '32601',
        '32603',
        '32605',
        '32606',
        '32607',
        '32608',
        '32609',
        '32612',
        '32615',
        '32616',
        '32617',
        '32618',
        '32619',
        '32621',
        '32622',
        '32625',
        '32626',
        '32628',
        '32631',
        '32639',
        '32640',
        '32641',
        '32643',
        '32648',
        '32653',
        '32656',
        '32658',
        '32664',
        '32666',
        '32667',
        '32668',
        '32669',
        '32680',
        '32681',
        '32683',
        '32686',
        '32692',
        '32693',
        '32694',
        '32696',
        '32697',
        '32701',
        '32702',
        '32703',
        '32707',
        '32708',
        '32709',
        '32712',
        '32713',
        '32714',
        '32720',
        '32724',
        '32725',
        '32726',
        '32730',
        '32732',
        '32735',
        '32736',
        '32738',
        '32744',
        '32746',
        '32750',
        '32751',
        '32754',
        '32757',
        '32759',
        '32763',
        '32764',
        '32765',
        '32766',
        '32767',
        '32771',
        '32773',
        '32776',
        '32778',
        '32779',
        '32780',
        '32784',
        '32789',
        '32792',
        '32796',
        '32798',
        '32801',
        '32803',
        '32804',
        '32805',
        '32806',
        '32807',
        '32808',
        '32809',
        '32810',
        '32811',
        '32812',
        '32814',
        '32817',
        '32818',
        '32819',
        '32820',
        '32821',
        '32822',
        '32824',
        '32825',
        '32826',
        '32827',
        '32828',
        '32829',
        '32830',
        '32831',
        '32832',
        '32833',
        '32835',
        '32836',
        '32837',
        '32839',
        '32901',
        '32903',
        '32904',
        '32905',
        '32907',
        '32908',
        '32909',
        '32920',
        '32922',
        '32925',
        '32926',
        '32927',
        '32931',
        '32934',
        '32935',
        '32937',
        '32940',
        '32948',
        '32949',
        '32950',
        '32951',
        '32952',
        '32953',
        '32955',
        '32958',
        '32960',
        '32962',
        '32963',
        '32966',
        '32967',
        '32968',
        '32970',
        '32976',
        '33001',
        '33004',
        '33009',
        '33010',
        '33012',
        '33013',
        '33014',
        '33015',
        '33016',
        '33018',
        '33019',
        '33020',
        '33021',
        '33023',
        '33024',
        '33025',
        '33026',
        '33027',
        '33028',
        '33029',
        '33030',
        '33031',
        '33032',
        '33033',
        '33034',
        '33035',
        '33036',
        '33037',
        '33039',
        '33040',
        '33042',
        '33043',
        '33050',
        '33051',
        '33054',
        '33055',
        '33056',
        '33060',
        '33062',
        '33063',
        '33064',
        '33065',
        '33066',
        '33067',
        '33068',
        '33069',
        '33070',
        '33071',
        '33073',
        '33076',
        '33101',
        '33109',
        '33122',
        '33125',
        '33126',
        '33127',
        '33128',
        '33129',
        '33130',
        '33131',
        '33132',
        '33133',
        '33134',
        '33135',
        '33136',
        '33137',
        '33138',
        '33139',
        '33140',
        '33141',
        '33142',
        '33143',
        '33144',
        '33145',
        '33146',
        '33147',
        '33149',
        '33150',
        '33154',
        '33155',
        '33156',
        '33157',
        '33158',
        '33160',
        '33161',
        '33162',
        '33165',
        '33166',
        '33167',
        '33168',
        '33169',
        '33170',
        '33172',
        '33173',
        '33174',
        '33175',
        '33176',
        '33177',
        '33178',
        '33179',
        '33180',
        '33181',
        '33182',
        '33183',
        '33184',
        '33185',
        '33186',
        '33187',
        '33189',
        '33190',
        '33193',
        '33194',
        '33196',
        '33301',
        '33304',
        '33305',
        '33306',
        '33308',
        '33309',
        '33311',
        '33312',
        '33313',
        '33314',
        '33315',
        '33316',
        '33317',
        '33319',
        '33321',
        '33322',
        '33323',
        '33324',
        '33325',
        '33326',
        '33327',
        '33328',
        '33330',
        '33331',
        '33332',
        '33334',
        '33351',
        '33401',
        '33403',
        '33404',
        '33405',
        '33406',
        '33407',
        '33408',
        '33409',
        '33410',
        '33411',
        '33412',
        '33413',
        '33414',
        '33415',
        '33417',
        '33418',
        '33426',
        '33428',
        '33430',
        '33431',
        '33432',
        '33433',
        '33434',
        '33435',
        '33436',
        '33437',
        '33438',
        '33440',
        '33441',
        '33442',
        '33444',
        '33445',
        '33446',
        '33449',
        '33455',
        '33458',
        '33460',
        '33461',
        '33462',
        '33463',
        '33467',
        '33469',
        '33470',
        '33471',
        '33472',
        '33473',
        '33476',
        '33477',
        '33478',
        '33480',
        '33483',
        '33484',
        '33486',
        '33487',
        '33493',
        '33496',
        '33498',
        '33503',
        '33510',
        '33511',
        '33513',
        '33514',
        '33521',
        '33523',
        '33525',
        '33527',
        '33534',
        '33538',
        '33540',
        '33541',
        '33542',
        '33543',
        '33544',
        '33545',
        '33547',
        '33548',
        '33549',
        '33556',
        '33558',
        '33559',
        '33563',
        '33565',
        '33566',
        '33567',
        '33569',
        '33570',
        '33572',
        '33573',
        '33576',
        '33578',
        '33579',
        '33584',
        '33585',
        '33592',
        '33594',
        '33596',
        '33597',
        '33598',
        '33602',
        '33603',
        '33604',
        '33605',
        '33606',
        '33607',
        '33609',
        '33610',
        '33611',
        '33612',
        '33613',
        '33614',
        '33615',
        '33616',
        '33617',
        '33618',
        '33619',
        '33620',
        '33621',
        '33624',
        '33625',
        '33626',
        '33629',
        '33634',
        '33635',
        '33637',
        '33647',
        '33701',
        '33702',
        '33703',
        '33704',
        '33705',
        '33706',
        '33707',
        '33708',
        '33709',
        '33710',
        '33711',
        '33712',
        '33713',
        '33714',
        '33715',
        '33716',
        '33744',
        '33755',
        '33756',
        '33759',
        '33760',
        '33761',
        '33762',
        '33763',
        '33764',
        '33765',
        '33767',
        '33770',
        '33771',
        '33772',
        '33773',
        '33774',
        '33776',
        '33777',
        '33778',
        '33781',
        '33782',
        '33785',
        '33786',
        '33801',
        '33803',
        '33805',
        '33809',
        '33810',
        '33811',
        '33812',
        '33813',
        '33815',
        '33823',
        '33825',
        '33827',
        '33830',
        '33834',
        '33837',
        '33838',
        '33839',
        '33841',
        '33843',
        '33844',
        '33847',
        '33848',
        '33849',
        '33850',
        '33851',
        '33852',
        '33853',
        '33854',
        '33855',
        '33856',
        '33857',
        '33859',
        '33860',
        '33865',
        '33867',
        '33868',
        '33870',
        '33872',
        '33873',
        '33875',
        '33876',
        '33877',
        '33880',
        '33881',
        '33884',
        '33890',
        '33896',
        '33897',
        '33898',
        '33901',
        '33903',
        '33904',
        '33905',
        '33907',
        '33908',
        '33909',
        '33912',
        '33913',
        '33914',
        '33916',
        '33917',
        '33919',
        '33920',
        '33921',
        '33922',
        '33924',
        '33928',
        '33930',
        '33931',
        '33935',
        '33936',
        '33944',
        '33945',
        '33946',
        '33947',
        '33948',
        '33950',
        '33952',
        '33953',
        '33954',
        '33955',
        '33956',
        '33957',
        '33960',
        '33965',
        '33966',
        '33967',
        '33971',
        '33972',
        '33973',
        '33974',
        '33976',
        '33980',
        '33981',
        '33982',
        '33983',
        '33990',
        '33991',
        '33993',
        '34101',
        '34102',
        '34103',
        '34104',
        '34105',
        '34108',
        '34109',
        '34110',
        '34112',
        '34113',
        '34114',
        '34116',
        '34117',
        '34119',
        '34120',
        '34134',
        '34135',
        '34137',
        '34138',
        '34139',
        '34140',
        '34141',
        '34142',
        '34145',
        '34201',
        '34202',
        '34203',
        '34205',
        '34207',
        '34208',
        '34209',
        '34210',
        '34211',
        '34212',
        '34215',
        '34216',
        '34217',
        '34219',
        '34221',
        '34222',
        '34223',
        '34224',
        '34228',
        '34229',
        '34231',
        '34232',
        '34233',
        '34234',
        '34235',
        '34236',
        '34237',
        '34238',
        '34239',
        '34240',
        '34241',
        '34242',
        '34243',
        '34251',
        '34266',
        '34268',
        '34269',
        '34275',
        '34285',
        '34286',
        '34287',
        '34288',
        '34289',
        '34291',
        '34292',
        '34293',
        '34420',
        '34428',
        '34429',
        '34431',
        '34432',
        '34433',
        '34434',
        '34436',
        '34442',
        '34445',
        '34446',
        '34448',
        '34449',
        '34450',
        '34452',
        '34453',
        '34461',
        '34465',
        '34470',
        '34471',
        '34472',
        '34473',
        '34474',
        '34475',
        '34476',
        '34479',
        '34480',
        '34481',
        '34482',
        '34484',
        '34488',
        '34491',
        '34498',
        '34601',
        '34602',
        '34604',
        '34606',
        '34607',
        '34608',
        '34609',
        '34610',
        '34613',
        '34614',
        '34637',
        '34638',
        '34639',
        '34652',
        '34653',
        '34654',
        '34655',
        '34661',
        '34667',
        '34668',
        '34669',
        '34677',
        '34679',
        '34681',
        '34683',
        '34684',
        '34685',
        '34688',
        '34689',
        '34690',
        '34691',
        '34695',
        '34698',
        '34705',
        '34711',
        '34714',
        '34715',
        '34731',
        '34734',
        '34736',
        '34737',
        '34739',
        '34741',
        '34743',
        '34744',
        '34746',
        '34747',
        '34748',
        '34753',
        '34756',
        '34758',
        '34759',
        '34760',
        '34761',
        '34762',
        '34769',
        '34771',
        '34772',
        '34773',
        '34785',
        '34786',
        '34787',
        '34788',
        '34797',
        '34945',
        '34946',
        '34947',
        '34949',
        '34950',
        '34951',
        '34952',
        '34953',
        '34956',
        '34957',
        '34972',
        '34974',
        '34981',
        '34982',
        '34983',
        '34984',
        '34986',
        '34987',
        '34990',
        '34994',
        '34996',
        '34997'
      ]
    },
    AL: {
      name: 'Alabama',
      codes: [
        '35004',
        '35005',
        '35006',
        '35007',
        '35010',
        '35013',
        '35014',
        '35016',
        '35019',
        '35020',
        '35022',
        '35023',
        '35031',
        '35032',
        '35033',
        '35034',
        '35035',
        '35036',
        '35040',
        '35042',
        '35043',
        '35044',
        '35045',
        '35046',
        '35049',
        '35051',
        '35052',
        '35053',
        '35054',
        '35055',
        '35057',
        '35058',
        '35060',
        '35061',
        '35062',
        '35063',
        '35064',
        '35068',
        '35070',
        '35071',
        '35072',
        '35073',
        '35074',
        '35077',
        '35078',
        '35079',
        '35080',
        '35082',
        '35083',
        '35085',
        '35087',
        '35089',
        '35091',
        '35094',
        '35096',
        '35097',
        '35098',
        '35111',
        '35112',
        '35114',
        '35115',
        '35116',
        '35117',
        '35118',
        '35119',
        '35120',
        '35121',
        '35124',
        '35125',
        '35126',
        '35127',
        '35128',
        '35130',
        '35131',
        '35133',
        '35135',
        '35136',
        '35139',
        '35143',
        '35146',
        '35147',
        '35148',
        '35149',
        '35150',
        '35151',
        '35160',
        '35171',
        '35172',
        '35173',
        '35175',
        '35176',
        '35178',
        '35179',
        '35180',
        '35183',
        '35184',
        '35186',
        '35187',
        '35188',
        '35203',
        '35204',
        '35205',
        '35206',
        '35207',
        '35208',
        '35209',
        '35210',
        '35211',
        '35212',
        '35213',
        '35214',
        '35215',
        '35216',
        '35217',
        '35218',
        '35221',
        '35222',
        '35223',
        '35224',
        '35226',
        '35228',
        '35229',
        '35233',
        '35234',
        '35235',
        '35242',
        '35243',
        '35244',
        '35254',
        '35401',
        '35404',
        '35405',
        '35406',
        '35441',
        '35442',
        '35443',
        '35444',
        '35446',
        '35447',
        '35452',
        '35453',
        '35456',
        '35457',
        '35458',
        '35459',
        '35460',
        '35461',
        '35462',
        '35463',
        '35464',
        '35466',
        '35469',
        '35470',
        '35473',
        '35474',
        '35475',
        '35476',
        '35477',
        '35480',
        '35481',
        '35490',
        '35501',
        '35503',
        '35504',
        '35540',
        '35541',
        '35542',
        '35543',
        '35544',
        '35545',
        '35546',
        '35548',
        '35549',
        '35550',
        '35552',
        '35553',
        '35554',
        '35555',
        '35559',
        '35563',
        '35564',
        '35565',
        '35570',
        '35571',
        '35572',
        '35574',
        '35575',
        '35576',
        '35577',
        '35578',
        '35579',
        '35580',
        '35581',
        '35582',
        '35584',
        '35585',
        '35586',
        '35587',
        '35592',
        '35593',
        '35594',
        '35601',
        '35603',
        '35610',
        '35611',
        '35613',
        '35614',
        '35615',
        '35616',
        '35618',
        '35619',
        '35620',
        '35621',
        '35622',
        '35630',
        '35633',
        '35634',
        '35640',
        '35643',
        '35645',
        '35646',
        '35647',
        '35648',
        '35649',
        '35650',
        '35651',
        '35652',
        '35653',
        '35654',
        '35660',
        '35661',
        '35670',
        '35671',
        '35672',
        '35673',
        '35674',
        '35677',
        '35739',
        '35740',
        '35741',
        '35744',
        '35745',
        '35746',
        '35747',
        '35748',
        '35749',
        '35750',
        '35751',
        '35752',
        '35754',
        '35755',
        '35756',
        '35757',
        '35758',
        '35759',
        '35760',
        '35761',
        '35763',
        '35764',
        '35765',
        '35766',
        '35768',
        '35769',
        '35771',
        '35772',
        '35773',
        '35774',
        '35775',
        '35776',
        '35801',
        '35802',
        '35803',
        '35805',
        '35806',
        '35808',
        '35810',
        '35811',
        '35816',
        '35824',
        '35896',
        '35901',
        '35903',
        '35904',
        '35905',
        '35906',
        '35907',
        '35950',
        '35951',
        '35952',
        '35953',
        '35954',
        '35956',
        '35957',
        '35958',
        '35959',
        '35960',
        '35961',
        '35962',
        '35963',
        '35966',
        '35967',
        '35968',
        '35971',
        '35972',
        '35973',
        '35974',
        '35975',
        '35976',
        '35978',
        '35979',
        '35980',
        '35981',
        '35983',
        '35984',
        '35986',
        '35987',
        '35988',
        '35989',
        '35990',
        '36003',
        '36005',
        '36006',
        '36009',
        '36010',
        '36013',
        '36016',
        '36017',
        '36020',
        '36022',
        '36024',
        '36025',
        '36026',
        '36027',
        '36028',
        '36029',
        '36030',
        '36031',
        '36032',
        '36033',
        '36034',
        '36035',
        '36036',
        '36037',
        '36038',
        '36039',
        '36040',
        '36041',
        '36042',
        '36043',
        '36046',
        '36047',
        '36048',
        '36049',
        '36051',
        '36052',
        '36053',
        '36054',
        '36064',
        '36066',
        '36067',
        '36069',
        '36071',
        '36075',
        '36078',
        '36079',
        '36080',
        '36081',
        '36082',
        '36083',
        '36088',
        '36089',
        '36091',
        '36092',
        '36093',
        '36104',
        '36105',
        '36106',
        '36107',
        '36108',
        '36109',
        '36110',
        '36111',
        '36112',
        '36113',
        '36115',
        '36116',
        '36117',
        '36201',
        '36203',
        '36205',
        '36206',
        '36207',
        '36250',
        '36251',
        '36255',
        '36256',
        '36258',
        '36260',
        '36262',
        '36263',
        '36264',
        '36265',
        '36266',
        '36267',
        '36268',
        '36269',
        '36271',
        '36272',
        '36273',
        '36274',
        '36276',
        '36277',
        '36278',
        '36279',
        '36280',
        '36301',
        '36303',
        '36305',
        '36310',
        '36311',
        '36312',
        '36313',
        '36314',
        '36316',
        '36317',
        '36318',
        '36319',
        '36320',
        '36321',
        '36322',
        '36323',
        '36330',
        '36340',
        '36343',
        '36344',
        '36345',
        '36346',
        '36350',
        '36351',
        '36352',
        '36353',
        '36360',
        '36362',
        '36370',
        '36371',
        '36373',
        '36374',
        '36375',
        '36376',
        '36401',
        '36420',
        '36421',
        '36425',
        '36426',
        '36432',
        '36435',
        '36436',
        '36439',
        '36441',
        '36442',
        '36444',
        '36445',
        '36446',
        '36451',
        '36453',
        '36454',
        '36455',
        '36456',
        '36460',
        '36467',
        '36470',
        '36471',
        '36473',
        '36474',
        '36475',
        '36476',
        '36477',
        '36480',
        '36481',
        '36482',
        '36483',
        '36502',
        '36505',
        '36507',
        '36509',
        '36511',
        '36512',
        '36513',
        '36518',
        '36521',
        '36522',
        '36523',
        '36524',
        '36525',
        '36526',
        '36527',
        '36528',
        '36529',
        '36530',
        '36532',
        '36535',
        '36538',
        '36539',
        '36540',
        '36541',
        '36542',
        '36543',
        '36544',
        '36545',
        '36548',
        '36549',
        '36550',
        '36551',
        '36553',
        '36555',
        '36556',
        '36558',
        '36559',
        '36560',
        '36561',
        '36562',
        '36564',
        '36567',
        '36568',
        '36569',
        '36571',
        '36572',
        '36574',
        '36575',
        '36576',
        '36578',
        '36579',
        '36580',
        '36581',
        '36582',
        '36583',
        '36584',
        '36585',
        '36587',
        '36590',
        '36602',
        '36603',
        '36604',
        '36605',
        '36606',
        '36607',
        '36608',
        '36609',
        '36610',
        '36611',
        '36612',
        '36613',
        '36615',
        '36616',
        '36617',
        '36618',
        '36619',
        '36688',
        '36693',
        '36695',
        '36701',
        '36703',
        '36720',
        '36722',
        '36723',
        '36726',
        '36727',
        '36728',
        '36732',
        '36736',
        '36738',
        '36740',
        '36742',
        '36744',
        '36748',
        '36749',
        '36750',
        '36751',
        '36752',
        '36753',
        '36754',
        '36756',
        '36758',
        '36759',
        '36761',
        '36763',
        '36765',
        '36766',
        '36767',
        '36768',
        '36769',
        '36773',
        '36775',
        '36776',
        '36782',
        '36783',
        '36784',
        '36785',
        '36786',
        '36790',
        '36792',
        '36793',
        '36801',
        '36804',
        '36830',
        '36832',
        '36849',
        '36850',
        '36852',
        '36853',
        '36854',
        '36855',
        '36856',
        '36858',
        '36859',
        '36860',
        '36861',
        '36862',
        '36863',
        '36865',
        '36866',
        '36867',
        '36869',
        '36870',
        '36871',
        '36874',
        '36875',
        '36877',
        '36879',
        '36901',
        '36904',
        '36907',
        '36908',
        '36910',
        '36912',
        '36913',
        '36915',
        '36916',
        '36919',
        '36921',
        '36922',
        '36925'
      ]
    },
    TN: {
      name: 'Tennessee',
      codes: [
        '37010',
        '37012',
        '37013',
        '37014',
        '37015',
        '37016',
        '37018',
        '37019',
        '37020',
        '37022',
        '37023',
        '37025',
        '37026',
        '37027',
        '37028',
        '37029',
        '37030',
        '37031',
        '37032',
        '37033',
        '37034',
        '37035',
        '37036',
        '37037',
        '37040',
        '37042',
        '37043',
        '37046',
        '37047',
        '37048',
        '37049',
        '37050',
        '37051',
        '37052',
        '37055',
        '37057',
        '37058',
        '37059',
        '37060',
        '37061',
        '37062',
        '37064',
        '37066',
        '37067',
        '37069',
        '37072',
        '37073',
        '37074',
        '37075',
        '37076',
        '37078',
        '37079',
        '37080',
        '37082',
        '37083',
        '37085',
        '37086',
        '37087',
        '37090',
        '37091',
        '37095',
        '37096',
        '37097',
        '37098',
        '37101',
        '37110',
        '37115',
        '37118',
        '37122',
        '37127',
        '37128',
        '37129',
        '37130',
        '37132',
        '37134',
        '37135',
        '37137',
        '37138',
        '37140',
        '37141',
        '37142',
        '37143',
        '37144',
        '37145',
        '37146',
        '37148',
        '37149',
        '37150',
        '37151',
        '37153',
        '37160',
        '37165',
        '37166',
        '37167',
        '37171',
        '37172',
        '37174',
        '37175',
        '37178',
        '37179',
        '37180',
        '37181',
        '37183',
        '37184',
        '37185',
        '37186',
        '37187',
        '37188',
        '37189',
        '37190',
        '37191',
        '37201',
        '37203',
        '37204',
        '37205',
        '37206',
        '37207',
        '37208',
        '37209',
        '37210',
        '37211',
        '37212',
        '37213',
        '37214',
        '37215',
        '37216',
        '37217',
        '37218',
        '37219',
        '37220',
        '37221',
        '37228',
        '37240',
        '37243',
        '37246',
        '37301',
        '37302',
        '37303',
        '37305',
        '37306',
        '37307',
        '37308',
        '37309',
        '37310',
        '37311',
        '37312',
        '37313',
        '37315',
        '37317',
        '37318',
        '37321',
        '37322',
        '37323',
        '37324',
        '37325',
        '37326',
        '37327',
        '37328',
        '37329',
        '37330',
        '37331',
        '37332',
        '37333',
        '37334',
        '37335',
        '37336',
        '37337',
        '37338',
        '37339',
        '37340',
        '37341',
        '37342',
        '37343',
        '37345',
        '37347',
        '37348',
        '37350',
        '37351',
        '37352',
        '37353',
        '37354',
        '37355',
        '37356',
        '37357',
        '37359',
        '37360',
        '37361',
        '37362',
        '37363',
        '37365',
        '37366',
        '37367',
        '37369',
        '37370',
        '37373',
        '37374',
        '37375',
        '37376',
        '37377',
        '37379',
        '37380',
        '37381',
        '37385',
        '37387',
        '37388',
        '37391',
        '37394',
        '37396',
        '37397',
        '37398',
        '37402',
        '37403',
        '37404',
        '37405',
        '37406',
        '37407',
        '37408',
        '37409',
        '37410',
        '37411',
        '37412',
        '37415',
        '37416',
        '37419',
        '37421',
        '37601',
        '37604',
        '37614',
        '37615',
        '37616',
        '37617',
        '37618',
        '37620',
        '37640',
        '37641',
        '37642',
        '37643',
        '37645',
        '37650',
        '37656',
        '37657',
        '37658',
        '37659',
        '37660',
        '37663',
        '37664',
        '37665',
        '37680',
        '37681',
        '37682',
        '37683',
        '37686',
        '37687',
        '37688',
        '37690',
        '37691',
        '37692',
        '37694',
        '37701',
        '37705',
        '37708',
        '37709',
        '37710',
        '37711',
        '37713',
        '37714',
        '37715',
        '37716',
        '37719',
        '37721',
        '37722',
        '37723',
        '37724',
        '37725',
        '37726',
        '37727',
        '37729',
        '37730',
        '37731',
        '37732',
        '37733',
        '37737',
        '37738',
        '37742',
        '37743',
        '37745',
        '37748',
        '37752',
        '37753',
        '37754',
        '37755',
        '37756',
        '37757',
        '37760',
        '37762',
        '37763',
        '37764',
        '37765',
        '37766',
        '37769',
        '37770',
        '37771',
        '37772',
        '37774',
        '37777',
        '37779',
        '37801',
        '37803',
        '37804',
        '37806',
        '37807',
        '37809',
        '37810',
        '37811',
        '37813',
        '37814',
        '37818',
        '37819',
        '37820',
        '37821',
        '37825',
        '37826',
        '37828',
        '37829',
        '37830',
        '37840',
        '37841',
        '37843',
        '37845',
        '37846',
        '37847',
        '37848',
        '37849',
        '37851',
        '37852',
        '37853',
        '37854',
        '37857',
        '37860',
        '37861',
        '37862',
        '37863',
        '37865',
        '37866',
        '37869',
        '37870',
        '37871',
        '37872',
        '37873',
        '37874',
        '37876',
        '37877',
        '37878',
        '37879',
        '37880',
        '37881',
        '37882',
        '37885',
        '37886',
        '37887',
        '37888',
        '37890',
        '37891',
        '37892',
        '37902',
        '37909',
        '37912',
        '37914',
        '37915',
        '37916',
        '37917',
        '37918',
        '37919',
        '37920',
        '37921',
        '37922',
        '37923',
        '37924',
        '37931',
        '37932',
        '37934',
        '37938',
        '38001',
        '38002',
        '38004',
        '38006',
        '38007',
        '38008',
        '38011',
        '38012',
        '38015',
        '38016',
        '38017',
        '38018',
        '38019',
        '38021',
        '38023',
        '38024',
        '38028',
        '38029',
        '38030',
        '38034',
        '38036',
        '38037',
        '38039',
        '38040',
        '38041',
        '38042',
        '38044',
        '38046',
        '38047',
        '38049',
        '38050',
        '38052',
        '38053',
        '38054',
        '38057',
        '38058',
        '38059',
        '38060',
        '38061',
        '38063',
        '38066',
        '38067',
        '38068',
        '38069',
        '38070',
        '38075',
        '38076',
        '38077',
        '38079',
        '38080',
        '38103',
        '38104',
        '38105',
        '38106',
        '38107',
        '38108',
        '38109',
        '38111',
        '38112',
        '38114',
        '38115',
        '38116',
        '38117',
        '38118',
        '38119',
        '38120',
        '38122',
        '38125',
        '38126',
        '38127',
        '38128',
        '38131',
        '38132',
        '38133',
        '38134',
        '38135',
        '38138',
        '38139',
        '38141',
        '38152',
        '38201',
        '38220',
        '38221',
        '38222',
        '38224',
        '38225',
        '38226',
        '38229',
        '38230',
        '38231',
        '38232',
        '38233',
        '38235',
        '38236',
        '38237',
        '38240',
        '38241',
        '38242',
        '38251',
        '38253',
        '38254',
        '38255',
        '38256',
        '38257',
        '38258',
        '38259',
        '38260',
        '38261',
        '38301',
        '38305',
        '38310',
        '38311',
        '38313',
        '38315',
        '38316',
        '38317',
        '38318',
        '38320',
        '38321',
        '38326',
        '38327',
        '38328',
        '38329',
        '38330',
        '38332',
        '38333',
        '38334',
        '38337',
        '38339',
        '38340',
        '38341',
        '38342',
        '38343',
        '38344',
        '38345',
        '38347',
        '38348',
        '38351',
        '38352',
        '38355',
        '38356',
        '38357',
        '38358',
        '38359',
        '38361',
        '38362',
        '38363',
        '38365',
        '38366',
        '38367',
        '38368',
        '38369',
        '38370',
        '38371',
        '38372',
        '38374',
        '38375',
        '38376',
        '38379',
        '38380',
        '38381',
        '38382',
        '38387',
        '38388',
        '38390',
        '38391',
        '38392',
        '38401',
        '38425',
        '38449',
        '38450',
        '38451',
        '38452',
        '38453',
        '38454',
        '38455',
        '38456',
        '38457',
        '38459',
        '38460',
        '38461',
        '38462',
        '38463',
        '38464',
        '38468',
        '38469',
        '38471',
        '38472',
        '38473',
        '38474',
        '38475',
        '38476',
        '38477',
        '38478',
        '38481',
        '38482',
        '38483',
        '38485',
        '38486',
        '38487',
        '38488',
        '38501',
        '38504',
        '38505',
        '38506',
        '38541',
        '38542',
        '38543',
        '38544',
        '38545',
        '38547',
        '38548',
        '38549',
        '38551',
        '38552',
        '38553',
        '38554',
        '38555',
        '38556',
        '38558',
        '38559',
        '38560',
        '38562',
        '38563',
        '38564',
        '38565',
        '38567',
        '38568',
        '38569',
        '38570',
        '38571',
        '38572',
        '38573',
        '38574',
        '38575',
        '38577',
        '38578',
        '38579',
        '38580',
        '38581',
        '38582',
        '38583',
        '38585',
        '38587',
        '38588',
        '38589'
      ]
    },
    MS: {
      name: 'Mississippi',
      codes: [
        '38601',
        '38603',
        '38606',
        '38610',
        '38611',
        '38614',
        '38617',
        '38618',
        '38619',
        '38620',
        '38621',
        '38622',
        '38623',
        '38625',
        '38626',
        '38627',
        '38629',
        '38630',
        '38631',
        '38632',
        '38633',
        '38635',
        '38637',
        '38639',
        '38641',
        '38642',
        '38643',
        '38644',
        '38645',
        '38646',
        '38647',
        '38650',
        '38651',
        '38652',
        '38654',
        '38655',
        '38658',
        '38659',
        '38661',
        '38663',
        '38664',
        '38665',
        '38666',
        '38668',
        '38670',
        '38671',
        '38672',
        '38673',
        '38674',
        '38676',
        '38677',
        '38680',
        '38683',
        '38685',
        '38701',
        '38702',
        '38703',
        '38704',
        '38720',
        '38721',
        '38722',
        '38723',
        '38725',
        '38726',
        '38730',
        '38731',
        '38732',
        '38736',
        '38737',
        '38738',
        '38740',
        '38744',
        '38745',
        '38746',
        '38748',
        '38749',
        '38751',
        '38753',
        '38754',
        '38756',
        '38759',
        '38760',
        '38761',
        '38762',
        '38764',
        '38765',
        '38767',
        '38768',
        '38769',
        '38771',
        '38772',
        '38773',
        '38774',
        '38778',
        '38781',
        '38801',
        '38804',
        '38821',
        '38824',
        '38826',
        '38827',
        '38828',
        '38829',
        '38833',
        '38834',
        '38838',
        '38841',
        '38843',
        '38844',
        '38846',
        '38847',
        '38848',
        '38849',
        '38850',
        '38851',
        '38852',
        '38855',
        '38856',
        '38857',
        '38858',
        '38859',
        '38860',
        '38862',
        '38863',
        '38864',
        '38865',
        '38866',
        '38868',
        '38869',
        '38870',
        '38871',
        '38873',
        '38876',
        '38878',
        '38879',
        '38901',
        '38913',
        '38914',
        '38915',
        '38916',
        '38917',
        '38920',
        '38921',
        '38922',
        '38923',
        '38924',
        '38925',
        '38927',
        '38928',
        '38929',
        '38930',
        '38940',
        '38941',
        '38943',
        '38944',
        '38945',
        '38946',
        '38947',
        '38948',
        '38949',
        '38950',
        '38951',
        '38952',
        '38953',
        '38954',
        '38957',
        '38958',
        '38961',
        '38962',
        '38963',
        '38964',
        '38965',
        '38966',
        '38967',
        '39038',
        '39039',
        '39040',
        '39041',
        '39042',
        '39044',
        '39045',
        '39046',
        '39047',
        '39051',
        '39054',
        '39056',
        '39057',
        '39059',
        '39061',
        '39062',
        '39063',
        '39066',
        '39067',
        '39069',
        '39071',
        '39073',
        '39074',
        '39078',
        '39079',
        '39082',
        '39083',
        '39086',
        '39088',
        '39090',
        '39092',
        '39094',
        '39095',
        '39096',
        '39097',
        '39108',
        '39110',
        '39111',
        '39113',
        '39114',
        '39115',
        '39116',
        '39117',
        '39119',
        '39120',
        '39140',
        '39144',
        '39145',
        '39146',
        '39149',
        '39150',
        '39152',
        '39153',
        '39154',
        '39156',
        '39157',
        '39159',
        '39160',
        '39162',
        '39166',
        '39167',
        '39168',
        '39169',
        '39170',
        '39174',
        '39175',
        '39176',
        '39177',
        '39179',
        '39180',
        '39183',
        '39189',
        '39191',
        '39192',
        '39193',
        '39194',
        '39201',
        '39202',
        '39203',
        '39204',
        '39206',
        '39208',
        '39209',
        '39211',
        '39212',
        '39213',
        '39216',
        '39217',
        '39218',
        '39232',
        '39269',
        '39272',
        '39301',
        '39305',
        '39307',
        '39309',
        '39320',
        '39322',
        '39323',
        '39325',
        '39326',
        '39327',
        '39328',
        '39330',
        '39332',
        '39335',
        '39336',
        '39337',
        '39338',
        '39339',
        '39341',
        '39342',
        '39345',
        '39346',
        '39347',
        '39348',
        '39350',
        '39352',
        '39354',
        '39355',
        '39356',
        '39358',
        '39359',
        '39360',
        '39361',
        '39362',
        '39363',
        '39364',
        '39365',
        '39366',
        '39367',
        '39401',
        '39402',
        '39406',
        '39421',
        '39422',
        '39423',
        '39425',
        '39426',
        '39427',
        '39428',
        '39429',
        '39437',
        '39439',
        '39440',
        '39443',
        '39451',
        '39452',
        '39455',
        '39456',
        '39459',
        '39461',
        '39462',
        '39464',
        '39465',
        '39466',
        '39470',
        '39474',
        '39475',
        '39476',
        '39477',
        '39478',
        '39479',
        '39480',
        '39481',
        '39482',
        '39483',
        '39501',
        '39503',
        '39507',
        '39520',
        '39525',
        '39530',
        '39531',
        '39532',
        '39534',
        '39540',
        '39553',
        '39556',
        '39560',
        '39561',
        '39562',
        '39563',
        '39564',
        '39565',
        '39567',
        '39571',
        '39572',
        '39573',
        '39574',
        '39576',
        '39577',
        '39581',
        '39601',
        '39629',
        '39630',
        '39631',
        '39633',
        '39635',
        '39638',
        '39641',
        '39643',
        '39645',
        '39647',
        '39648',
        '39652',
        '39653',
        '39654',
        '39656',
        '39657',
        '39661',
        '39662',
        '39663',
        '39664',
        '39665',
        '39666',
        '39667',
        '39668',
        '39669',
        '39701',
        '39702',
        '39705',
        '39730',
        '39735',
        '39736',
        '39737',
        '39739',
        '39740',
        '39741',
        '39743',
        '39744',
        '39745',
        '39746',
        '39747',
        '39750',
        '39751',
        '39752',
        '39755',
        '39756',
        '39759',
        '39760',
        '39762',
        '39766',
        '39767',
        '39769',
        '39771',
        '39772',
        '39773',
        '39776'
      ]
    },
    KY: {
      name: 'Kentucky',
      codes: [
        '40003',
        '40004',
        '40006',
        '40007',
        '40008',
        '40009',
        '40010',
        '40011',
        '40012',
        '40013',
        '40014',
        '40019',
        '40020',
        '40022',
        '40023',
        '40025',
        '40026',
        '40031',
        '40033',
        '40036',
        '40037',
        '40040',
        '40041',
        '40045',
        '40046',
        '40047',
        '40048',
        '40049',
        '40050',
        '40051',
        '40052',
        '40055',
        '40056',
        '40057',
        '40058',
        '40059',
        '40060',
        '40062',
        '40063',
        '40065',
        '40067',
        '40068',
        '40069',
        '40070',
        '40071',
        '40075',
        '40076',
        '40077',
        '40078',
        '40104',
        '40107',
        '40108',
        '40109',
        '40110',
        '40111',
        '40115',
        '40117',
        '40118',
        '40119',
        '40121',
        '40140',
        '40142',
        '40143',
        '40144',
        '40145',
        '40146',
        '40150',
        '40152',
        '40155',
        '40157',
        '40160',
        '40161',
        '40162',
        '40165',
        '40170',
        '40171',
        '40175',
        '40176',
        '40177',
        '40178',
        '40202',
        '40203',
        '40204',
        '40205',
        '40206',
        '40207',
        '40208',
        '40209',
        '40210',
        '40211',
        '40212',
        '40213',
        '40214',
        '40215',
        '40216',
        '40217',
        '40218',
        '40219',
        '40220',
        '40222',
        '40223',
        '40228',
        '40229',
        '40231',
        '40241',
        '40242',
        '40243',
        '40245',
        '40258',
        '40272',
        '40280',
        '40291',
        '40299',
        '40310',
        '40311',
        '40312',
        '40313',
        '40316',
        '40322',
        '40324',
        '40328',
        '40330',
        '40334',
        '40336',
        '40337',
        '40339',
        '40342',
        '40346',
        '40347',
        '40348',
        '40350',
        '40351',
        '40353',
        '40356',
        '40358',
        '40359',
        '40360',
        '40361',
        '40363',
        '40370',
        '40371',
        '40372',
        '40374',
        '40376',
        '40379',
        '40380',
        '40383',
        '40385',
        '40387',
        '40390',
        '40391',
        '40402',
        '40403',
        '40404',
        '40409',
        '40419',
        '40422',
        '40434',
        '40437',
        '40440',
        '40442',
        '40444',
        '40445',
        '40447',
        '40448',
        '40456',
        '40460',
        '40461',
        '40464',
        '40468',
        '40472',
        '40475',
        '40481',
        '40484',
        '40486',
        '40489',
        '40502',
        '40503',
        '40504',
        '40505',
        '40506',
        '40507',
        '40508',
        '40509',
        '40510',
        '40511',
        '40513',
        '40514',
        '40515',
        '40516',
        '40517',
        '40601',
        '40604',
        '40701',
        '40729',
        '40734',
        '40737',
        '40740',
        '40741',
        '40743',
        '40744',
        '40759',
        '40763',
        '40769',
        '40771',
        '40801',
        '40806',
        '40807',
        '40808',
        '40810',
        '40813',
        '40815',
        '40816',
        '40818',
        '40819',
        '40820',
        '40823',
        '40824',
        '40826',
        '40827',
        '40828',
        '40829',
        '40830',
        '40831',
        '40840',
        '40843',
        '40844',
        '40845',
        '40847',
        '40849',
        '40854',
        '40855',
        '40856',
        '40858',
        '40862',
        '40863',
        '40865',
        '40868',
        '40870',
        '40873',
        '40874',
        '40902',
        '40903',
        '40906',
        '40913',
        '40914',
        '40915',
        '40921',
        '40923',
        '40927',
        '40935',
        '40939',
        '40940',
        '40941',
        '40943',
        '40946',
        '40949',
        '40953',
        '40958',
        '40962',
        '40964',
        '40965',
        '40972',
        '40977',
        '40979',
        '40982',
        '40983',
        '40988',
        '40995',
        '40997',
        '41001',
        '41002',
        '41003',
        '41004',
        '41005',
        '41006',
        '41007',
        '41008',
        '41010',
        '41011',
        '41014',
        '41015',
        '41016',
        '41017',
        '41018',
        '41030',
        '41031',
        '41033',
        '41034',
        '41035',
        '41039',
        '41040',
        '41041',
        '41042',
        '41043',
        '41044',
        '41045',
        '41046',
        '41048',
        '41049',
        '41051',
        '41052',
        '41055',
        '41056',
        '41059',
        '41062',
        '41063',
        '41064',
        '41071',
        '41073',
        '41074',
        '41075',
        '41076',
        '41080',
        '41083',
        '41085',
        '41086',
        '41091',
        '41092',
        '41093',
        '41094',
        '41095',
        '41097',
        '41098',
        '41099',
        '41101',
        '41102',
        '41121',
        '41124',
        '41129',
        '41132',
        '41135',
        '41139',
        '41141',
        '41142',
        '41143',
        '41144',
        '41146',
        '41149',
        '41159',
        '41164',
        '41166',
        '41168',
        '41169',
        '41171',
        '41174',
        '41175',
        '41179',
        '41180',
        '41183',
        '41189',
        '41201',
        '41203',
        '41204',
        '41214',
        '41216',
        '41219',
        '41222',
        '41224',
        '41226',
        '41230',
        '41231',
        '41232',
        '41234',
        '41238',
        '41240',
        '41250',
        '41254',
        '41255',
        '41256',
        '41257',
        '41260',
        '41262',
        '41263',
        '41264',
        '41265',
        '41267',
        '41268',
        '41271',
        '41274',
        '41301',
        '41311',
        '41314',
        '41317',
        '41332',
        '41339',
        '41348',
        '41352',
        '41360',
        '41365',
        '41366',
        '41367',
        '41385',
        '41390',
        '41397',
        '41408',
        '41421',
        '41425',
        '41464',
        '41465',
        '41472',
        '41501',
        '41503',
        '41512',
        '41513',
        '41514',
        '41517',
        '41519',
        '41522',
        '41524',
        '41526',
        '41527',
        '41528',
        '41531',
        '41534',
        '41535',
        '41537',
        '41538',
        '41539',
        '41540',
        '41543',
        '41544',
        '41547',
        '41548',
        '41553',
        '41554',
        '41555',
        '41557',
        '41558',
        '41559',
        '41560',
        '41562',
        '41563',
        '41564',
        '41566',
        '41567',
        '41568',
        '41571',
        '41572',
        '41601',
        '41602',
        '41603',
        '41604',
        '41605',
        '41606',
        '41607',
        '41612',
        '41615',
        '41616',
        '41619',
        '41621',
        '41622',
        '41630',
        '41631',
        '41632',
        '41635',
        '41636',
        '41640',
        '41642',
        '41643',
        '41645',
        '41647',
        '41649',
        '41650',
        '41653',
        '41655',
        '41659',
        '41660',
        '41663',
        '41666',
        '41667',
        '41669',
        '41701',
        '41712',
        '41713',
        '41714',
        '41719',
        '41721',
        '41722',
        '41723',
        '41725',
        '41727',
        '41729',
        '41731',
        '41735',
        '41739',
        '41740',
        '41745',
        '41746',
        '41749',
        '41751',
        '41754',
        '41759',
        '41760',
        '41762',
        '41763',
        '41764',
        '41766',
        '41772',
        '41773',
        '41774',
        '41775',
        '41776',
        '41777',
        '41804',
        '41810',
        '41812',
        '41815',
        '41817',
        '41819',
        '41821',
        '41822',
        '41824',
        '41825',
        '41826',
        '41828',
        '41831',
        '41832',
        '41833',
        '41834',
        '41835',
        '41836',
        '41837',
        '41838',
        '41839',
        '41840',
        '41843',
        '41844',
        '41845',
        '41847',
        '41848',
        '41849',
        '41855',
        '41858',
        '41859',
        '41861',
        '41862',
        '42001',
        '42003',
        '42020',
        '42021',
        '42022',
        '42023',
        '42024',
        '42025',
        '42027',
        '42028',
        '42029',
        '42031',
        '42032',
        '42035',
        '42036',
        '42037',
        '42038',
        '42039',
        '42040',
        '42041',
        '42044',
        '42045',
        '42047',
        '42048',
        '42049',
        '42050',
        '42051',
        '42053',
        '42054',
        '42055',
        '42056',
        '42058',
        '42060',
        '42061',
        '42064',
        '42066',
        '42069',
        '42071',
        '42076',
        '42078',
        '42079',
        '42081',
        '42082',
        '42083',
        '42085',
        '42086',
        '42087',
        '42088',
        '42101',
        '42102',
        '42103',
        '42104',
        '42120',
        '42122',
        '42123',
        '42124',
        '42127',
        '42129',
        '42130',
        '42133',
        '42134',
        '42140',
        '42141',
        '42151',
        '42153',
        '42154',
        '42156',
        '42157',
        '42159',
        '42160',
        '42163',
        '42164',
        '42166',
        '42167',
        '42170',
        '42171',
        '42202',
        '42204',
        '42206',
        '42207',
        '42210',
        '42211',
        '42214',
        '42215',
        '42217',
        '42220',
        '42223',
        '42232',
        '42234',
        '42236',
        '42240',
        '42254',
        '42256',
        '42259',
        '42261',
        '42262',
        '42265',
        '42266',
        '42273',
        '42274',
        '42275',
        '42276',
        '42280',
        '42285',
        '42286',
        '42301',
        '42303',
        '42320',
        '42321',
        '42322',
        '42323',
        '42324',
        '42325',
        '42326',
        '42327',
        '42328',
        '42330',
        '42332',
        '42333',
        '42337',
        '42338',
        '42339',
        '42343',
        '42344',
        '42345',
        '42347',
        '42348',
        '42349',
        '42350',
        '42351',
        '42352',
        '42354',
        '42355',
        '42356',
        '42361',
        '42366',
        '42367',
        '42368',
        '42369',
        '42370',
        '42371',
        '42372',
        '42374',
        '42376',
        '42378',
        '42404',
        '42406',
        '42408',
        '42409',
        '42410',
        '42411',
        '42413',
        '42420',
        '42431',
        '42436',
        '42437',
        '42440',
        '42441',
        '42442',
        '42445',
        '42450',
        '42451',
        '42452',
        '42453',
        '42455',
        '42456',
        '42458',
        '42459',
        '42461',
        '42462',
        '42463',
        '42464',
        '42501',
        '42503',
        '42516',
        '42518',
        '42519',
        '42528',
        '42533',
        '42539',
        '42541',
        '42544',
        '42553',
        '42565',
        '42566',
        '42567',
        '42602',
        '42603',
        '42629',
        '42631',
        '42633',
        '42634',
        '42635',
        '42638',
        '42642',
        '42647',
        '42649',
        '42653',
        '42701',
        '42712',
        '42713',
        '42715',
        '42716',
        '42717',
        '42718',
        '42721',
        '42722',
        '42724',
        '42726',
        '42728',
        '42729',
        '42732',
        '42733',
        '42740',
        '42741',
        '42743',
        '42746',
        '42748',
        '42749',
        '42753',
        '42754',
        '42757',
        '42758',
        '42762',
        '42764',
        '42765',
        '42776',
        '42782',
        '42784',
        '42788'
      ]
    },
    OH: {
      name: 'Ohio',
      codes: [
        '43001',
        '43002',
        '43003',
        '43004',
        '43005',
        '43006',
        '43008',
        '43009',
        '43010',
        '43011',
        '43013',
        '43014',
        '43015',
        '43016',
        '43017',
        '43019',
        '43021',
        '43022',
        '43023',
        '43025',
        '43026',
        '43028',
        '43029',
        '43030',
        '43031',
        '43032',
        '43033',
        '43035',
        '43036',
        '43037',
        '43040',
        '43044',
        '43045',
        '43046',
        '43050',
        '43054',
        '43055',
        '43056',
        '43060',
        '43061',
        '43062',
        '43064',
        '43065',
        '43066',
        '43067',
        '43068',
        '43070',
        '43071',
        '43072',
        '43074',
        '43076',
        '43077',
        '43078',
        '43080',
        '43081',
        '43082',
        '43084',
        '43085',
        '43101',
        '43102',
        '43103',
        '43105',
        '43106',
        '43107',
        '43109',
        '43110',
        '43111',
        '43112',
        '43113',
        '43115',
        '43116',
        '43117',
        '43119',
        '43123',
        '43125',
        '43126',
        '43127',
        '43128',
        '43130',
        '43135',
        '43136',
        '43137',
        '43138',
        '43140',
        '43142',
        '43143',
        '43144',
        '43145',
        '43146',
        '43147',
        '43148',
        '43149',
        '43150',
        '43151',
        '43152',
        '43153',
        '43154',
        '43155',
        '43156',
        '43157',
        '43158',
        '43160',
        '43162',
        '43164',
        '43201',
        '43202',
        '43203',
        '43204',
        '43205',
        '43206',
        '43207',
        '43209',
        '43210',
        '43211',
        '43212',
        '43213',
        '43214',
        '43215',
        '43217',
        '43219',
        '43220',
        '43221',
        '43222',
        '43223',
        '43224',
        '43227',
        '43228',
        '43229',
        '43230',
        '43231',
        '43232',
        '43235',
        '43240',
        '43302',
        '43310',
        '43311',
        '43314',
        '43315',
        '43316',
        '43317',
        '43318',
        '43319',
        '43320',
        '43321',
        '43322',
        '43323',
        '43324',
        '43326',
        '43330',
        '43331',
        '43332',
        '43333',
        '43334',
        '43336',
        '43337',
        '43338',
        '43340',
        '43341',
        '43342',
        '43343',
        '43344',
        '43345',
        '43347',
        '43348',
        '43351',
        '43356',
        '43357',
        '43358',
        '43359',
        '43360',
        '43402',
        '43403',
        '43406',
        '43407',
        '43408',
        '43410',
        '43412',
        '43413',
        '43416',
        '43420',
        '43430',
        '43431',
        '43432',
        '43433',
        '43434',
        '43435',
        '43437',
        '43438',
        '43439',
        '43440',
        '43442',
        '43443',
        '43445',
        '43446',
        '43447',
        '43449',
        '43450',
        '43451',
        '43452',
        '43456',
        '43457',
        '43458',
        '43460',
        '43462',
        '43463',
        '43464',
        '43465',
        '43466',
        '43467',
        '43468',
        '43469',
        '43501',
        '43502',
        '43504',
        '43505',
        '43506',
        '43511',
        '43512',
        '43515',
        '43516',
        '43517',
        '43518',
        '43519',
        '43521',
        '43522',
        '43523',
        '43524',
        '43525',
        '43526',
        '43527',
        '43528',
        '43529',
        '43531',
        '43532',
        '43533',
        '43534',
        '43535',
        '43536',
        '43537',
        '43540',
        '43541',
        '43542',
        '43543',
        '43545',
        '43547',
        '43548',
        '43549',
        '43551',
        '43553',
        '43554',
        '43555',
        '43556',
        '43557',
        '43558',
        '43560',
        '43565',
        '43566',
        '43567',
        '43569',
        '43570',
        '43571',
        '43604',
        '43605',
        '43606',
        '43607',
        '43608',
        '43609',
        '43610',
        '43611',
        '43612',
        '43613',
        '43614',
        '43615',
        '43616',
        '43617',
        '43619',
        '43620',
        '43623',
        '43701',
        '43711',
        '43713',
        '43716',
        '43717',
        '43718',
        '43719',
        '43720',
        '43721',
        '43722',
        '43723',
        '43724',
        '43725',
        '43727',
        '43728',
        '43730',
        '43731',
        '43732',
        '43733',
        '43734',
        '43735',
        '43736',
        '43738',
        '43739',
        '43740',
        '43746',
        '43747',
        '43748',
        '43749',
        '43750',
        '43754',
        '43755',
        '43756',
        '43758',
        '43759',
        '43760',
        '43761',
        '43762',
        '43764',
        '43766',
        '43767',
        '43768',
        '43771',
        '43772',
        '43773',
        '43777',
        '43778',
        '43779',
        '43780',
        '43782',
        '43783',
        '43786',
        '43787',
        '43788',
        '43793',
        '43802',
        '43804',
        '43805',
        '43811',
        '43812',
        '43821',
        '43822',
        '43824',
        '43830',
        '43832',
        '43836',
        '43837',
        '43840',
        '43842',
        '43843',
        '43844',
        '43845',
        '43901',
        '43902',
        '43903',
        '43905',
        '43906',
        '43907',
        '43908',
        '43910',
        '43912',
        '43913',
        '43914',
        '43915',
        '43917',
        '43920',
        '43925',
        '43926',
        '43927',
        '43928',
        '43930',
        '43931',
        '43932',
        '43933',
        '43934',
        '43935',
        '43938',
        '43939',
        '43940',
        '43942',
        '43943',
        '43944',
        '43945',
        '43946',
        '43947',
        '43948',
        '43950',
        '43951',
        '43952',
        '43953',
        '43961',
        '43962',
        '43963',
        '43964',
        '43967',
        '43968',
        '43970',
        '43971',
        '43972',
        '43973',
        '43974',
        '43976',
        '43977',
        '43983',
        '43985',
        '43986',
        '43988',
        '44001',
        '44003',
        '44004',
        '44010',
        '44011',
        '44012',
        '44017',
        '44021',
        '44022',
        '44023',
        '44024',
        '44026',
        '44028',
        '44030',
        '44032',
        '44035',
        '44039',
        '44040',
        '44041',
        '44044',
        '44045',
        '44046',
        '44047',
        '44048',
        '44049',
        '44050',
        '44052',
        '44053',
        '44054',
        '44055',
        '44056',
        '44057',
        '44060',
        '44062',
        '44064',
        '44065',
        '44067',
        '44070',
        '44072',
        '44074',
        '44076',
        '44077',
        '44080',
        '44081',
        '44082',
        '44084',
        '44085',
        '44086',
        '44087',
        '44089',
        '44090',
        '44092',
        '44093',
        '44094',
        '44095',
        '44099',
        '44101',
        '44102',
        '44103',
        '44104',
        '44105',
        '44106',
        '44107',
        '44108',
        '44109',
        '44110',
        '44111',
        '44112',
        '44113',
        '44114',
        '44115',
        '44116',
        '44117',
        '44118',
        '44119',
        '44120',
        '44121',
        '44122',
        '44123',
        '44124',
        '44125',
        '44126',
        '44127',
        '44128',
        '44129',
        '44130',
        '44131',
        '44132',
        '44133',
        '44134',
        '44135',
        '44136',
        '44137',
        '44138',
        '44139',
        '44140',
        '44141',
        '44142',
        '44143',
        '44144',
        '44145',
        '44146',
        '44147',
        '44149',
        '44201',
        '44202',
        '44203',
        '44212',
        '44214',
        '44215',
        '44216',
        '44217',
        '44221',
        '44223',
        '44224',
        '44230',
        '44231',
        '44233',
        '44234',
        '44235',
        '44236',
        '44240',
        '44241',
        '44243',
        '44250',
        '44251',
        '44253',
        '44254',
        '44255',
        '44256',
        '44260',
        '44262',
        '44264',
        '44265',
        '44266',
        '44270',
        '44272',
        '44273',
        '44274',
        '44275',
        '44276',
        '44278',
        '44280',
        '44281',
        '44285',
        '44286',
        '44287',
        '44288',
        '44301',
        '44302',
        '44303',
        '44304',
        '44305',
        '44306',
        '44307',
        '44308',
        '44310',
        '44311',
        '44312',
        '44313',
        '44314',
        '44319',
        '44320',
        '44321',
        '44333',
        '44401',
        '44402',
        '44403',
        '44404',
        '44405',
        '44406',
        '44408',
        '44410',
        '44411',
        '44412',
        '44413',
        '44417',
        '44418',
        '44420',
        '44423',
        '44425',
        '44427',
        '44428',
        '44429',
        '44430',
        '44431',
        '44432',
        '44436',
        '44437',
        '44438',
        '44439',
        '44440',
        '44441',
        '44442',
        '44443',
        '44444',
        '44445',
        '44446',
        '44449',
        '44450',
        '44451',
        '44452',
        '44454',
        '44455',
        '44460',
        '44470',
        '44471',
        '44473',
        '44481',
        '44483',
        '44484',
        '44485',
        '44490',
        '44491',
        '44493',
        '44502',
        '44503',
        '44504',
        '44505',
        '44506',
        '44507',
        '44509',
        '44510',
        '44511',
        '44512',
        '44514',
        '44515',
        '44601',
        '44606',
        '44607',
        '44608',
        '44609',
        '44610',
        '44611',
        '44612',
        '44613',
        '44614',
        '44615',
        '44618',
        '44620',
        '44621',
        '44622',
        '44624',
        '44625',
        '44626',
        '44627',
        '44628',
        '44629',
        '44632',
        '44633',
        '44634',
        '44637',
        '44638',
        '44640',
        '44641',
        '44643',
        '44644',
        '44645',
        '44646',
        '44647',
        '44651',
        '44652',
        '44653',
        '44654',
        '44656',
        '44657',
        '44659',
        '44661',
        '44662',
        '44663',
        '44666',
        '44667',
        '44669',
        '44670',
        '44671',
        '44672',
        '44675',
        '44676',
        '44677',
        '44678',
        '44680',
        '44681',
        '44682',
        '44683',
        '44685',
        '44687',
        '44688',
        '44689',
        '44690',
        '44691',
        '44693',
        '44695',
        '44697',
        '44699',
        '44702',
        '44703',
        '44704',
        '44705',
        '44706',
        '44707',
        '44708',
        '44709',
        '44710',
        '44714',
        '44718',
        '44720',
        '44721',
        '44730',
        '44802',
        '44804',
        '44805',
        '44807',
        '44809',
        '44811',
        '44813',
        '44814',
        '44815',
        '44816',
        '44817',
        '44818',
        '44820',
        '44822',
        '44824',
        '44825',
        '44826',
        '44827',
        '44828',
        '44830',
        '44833',
        '44836',
        '44837',
        '44838',
        '44839',
        '44840',
        '44841',
        '44842',
        '44843',
        '44844',
        '44846',
        '44847',
        '44849',
        '44850',
        '44851',
        '44853',
        '44854',
        '44855',
        '44856',
        '44857',
        '44859',
        '44861',
        '44864',
        '44865',
        '44866',
        '44867',
        '44870',
        '44874',
        '44875',
        '44878',
        '44880',
        '44881',
        '44882',
        '44883',
        '44887',
        '44889',
        '44890',
        '44901',
        '44902',
        '44903',
        '44904',
        '44905',
        '44906',
        '44907',
        '45001',
        '45002',
        '45003',
        '45005',
        '45011',
        '45013',
        '45014',
        '45015',
        '45030',
        '45032',
        '45033',
        '45034',
        '45036',
        '45039',
        '45040',
        '45041',
        '45042',
        '45044',
        '45050',
        '45051',
        '45052',
        '45053',
        '45054',
        '45056',
        '45062',
        '45064',
        '45065',
        '45066',
        '45067',
        '45068',
        '45069',
        '45070',
        '45101',
        '45102',
        '45103',
        '45106',
        '45107',
        '45111',
        '45112',
        '45113',
        '45115',
        '45118',
        '45120',
        '45121',
        '45122',
        '45123',
        '45130',
        '45131',
        '45132',
        '45133',
        '45135',
        '45140',
        '45142',
        '45144',
        '45146',
        '45147',
        '45148',
        '45150',
        '45152',
        '45153',
        '45154',
        '45155',
        '45156',
        '45157',
        '45158',
        '45159',
        '45160',
        '45162',
        '45164',
        '45166',
        '45167',
        '45168',
        '45169',
        '45171',
        '45172',
        '45174',
        '45176',
        '45177',
        '45202',
        '45203',
        '45204',
        '45205',
        '45206',
        '45207',
        '45208',
        '45209',
        '45211',
        '45212',
        '45213',
        '45214',
        '45215',
        '45216',
        '45217',
        '45218',
        '45219',
        '45220',
        '45223',
        '45224',
        '45225',
        '45226',
        '45227',
        '45229',
        '45230',
        '45231',
        '45232',
        '45233',
        '45236',
        '45237',
        '45238',
        '45239',
        '45240',
        '45241',
        '45242',
        '45243',
        '45244',
        '45245',
        '45246',
        '45247',
        '45248',
        '45249',
        '45251',
        '45252',
        '45255',
        '45301',
        '45302',
        '45303',
        '45304',
        '45305',
        '45306',
        '45307',
        '45308',
        '45309',
        '45310',
        '45311',
        '45312',
        '45314',
        '45315',
        '45316',
        '45317',
        '45318',
        '45319',
        '45320',
        '45321',
        '45322',
        '45323',
        '45324',
        '45325',
        '45326',
        '45327',
        '45328',
        '45330',
        '45331',
        '45332',
        '45333',
        '45334',
        '45335',
        '45336',
        '45337',
        '45338',
        '45339',
        '45340',
        '45341',
        '45342',
        '45344',
        '45345',
        '45346',
        '45347',
        '45348',
        '45349',
        '45350',
        '45351',
        '45352',
        '45353',
        '45354',
        '45356',
        '45358',
        '45359',
        '45360',
        '45361',
        '45362',
        '45363',
        '45365',
        '45368',
        '45369',
        '45370',
        '45371',
        '45372',
        '45373',
        '45377',
        '45378',
        '45380',
        '45381',
        '45382',
        '45383',
        '45384',
        '45385',
        '45387',
        '45388',
        '45389',
        '45390',
        '45402',
        '45403',
        '45404',
        '45405',
        '45406',
        '45409',
        '45410',
        '45414',
        '45415',
        '45416',
        '45417',
        '45419',
        '45420',
        '45424',
        '45426',
        '45428',
        '45429',
        '45430',
        '45431',
        '45432',
        '45433',
        '45434',
        '45439',
        '45440',
        '45449',
        '45458',
        '45459',
        '45502',
        '45503',
        '45504',
        '45505',
        '45506',
        '45601',
        '45612',
        '45613',
        '45614',
        '45616',
        '45617',
        '45618',
        '45619',
        '45620',
        '45621',
        '45622',
        '45623',
        '45624',
        '45628',
        '45629',
        '45630',
        '45631',
        '45634',
        '45636',
        '45638',
        '45640',
        '45642',
        '45644',
        '45645',
        '45646',
        '45647',
        '45648',
        '45650',
        '45651',
        '45652',
        '45653',
        '45654',
        '45656',
        '45657',
        '45658',
        '45659',
        '45660',
        '45661',
        '45662',
        '45663',
        '45669',
        '45671',
        '45672',
        '45673',
        '45674',
        '45678',
        '45679',
        '45680',
        '45681',
        '45682',
        '45684',
        '45685',
        '45686',
        '45688',
        '45690',
        '45692',
        '45693',
        '45694',
        '45695',
        '45696',
        '45697',
        '45698',
        '45701',
        '45710',
        '45711',
        '45714',
        '45715',
        '45716',
        '45719',
        '45721',
        '45723',
        '45724',
        '45727',
        '45729',
        '45732',
        '45734',
        '45735',
        '45740',
        '45741',
        '45742',
        '45743',
        '45744',
        '45745',
        '45746',
        '45750',
        '45760',
        '45761',
        '45764',
        '45766',
        '45767',
        '45768',
        '45769',
        '45770',
        '45771',
        '45772',
        '45773',
        '45775',
        '45776',
        '45778',
        '45779',
        '45780',
        '45782',
        '45784',
        '45786',
        '45787',
        '45788',
        '45789',
        '45801',
        '45804',
        '45805',
        '45806',
        '45807',
        '45808',
        '45809',
        '45810',
        '45812',
        '45813',
        '45814',
        '45816',
        '45817',
        '45819',
        '45820',
        '45821',
        '45822',
        '45826',
        '45827',
        '45828',
        '45830',
        '45831',
        '45832',
        '45833',
        '45835',
        '45836',
        '45838',
        '45840',
        '45841',
        '45843',
        '45844',
        '45845',
        '45846',
        '45849',
        '45850',
        '45851',
        '45853',
        '45854',
        '45855',
        '45856',
        '45858',
        '45859',
        '45860',
        '45861',
        '45862',
        '45863',
        '45864',
        '45865',
        '45866',
        '45867',
        '45868',
        '45869',
        '45870',
        '45871',
        '45872',
        '45873',
        '45874',
        '45875',
        '45876',
        '45877',
        '45879',
        '45880',
        '45881',
        '45882',
        '45883',
        '45884',
        '45885',
        '45886',
        '45887',
        '45888',
        '45889',
        '45890',
        '45891',
        '45894',
        '45895',
        '45896',
        '45897',
        '45898',
        '45899'
      ]
    },
    IN: {
      name: 'Indiana',
      codes: [
        '46001',
        '46011',
        '46012',
        '46013',
        '46016',
        '46017',
        '46030',
        '46031',
        '46032',
        '46033',
        '46034',
        '46035',
        '46036',
        '46037',
        '46038',
        '46039',
        '46040',
        '46041',
        '46044',
        '46045',
        '46047',
        '46048',
        '46049',
        '46050',
        '46051',
        '46052',
        '46055',
        '46056',
        '46057',
        '46058',
        '46060',
        '46062',
        '46063',
        '46064',
        '46065',
        '46068',
        '46069',
        '46070',
        '46071',
        '46072',
        '46074',
        '46075',
        '46076',
        '46077',
        '46103',
        '46104',
        '46105',
        '46106',
        '46107',
        '46110',
        '46111',
        '46112',
        '46113',
        '46115',
        '46117',
        '46118',
        '46120',
        '46121',
        '46122',
        '46123',
        '46124',
        '46125',
        '46126',
        '46127',
        '46128',
        '46130',
        '46131',
        '46133',
        '46135',
        '46140',
        '46142',
        '46143',
        '46144',
        '46146',
        '46147',
        '46148',
        '46149',
        '46150',
        '46151',
        '46155',
        '46156',
        '46157',
        '46158',
        '46160',
        '46161',
        '46162',
        '46163',
        '46164',
        '46165',
        '46166',
        '46167',
        '46168',
        '46171',
        '46172',
        '46173',
        '46175',
        '46176',
        '46180',
        '46181',
        '46182',
        '46183',
        '46184',
        '46186',
        '46201',
        '46202',
        '46203',
        '46204',
        '46205',
        '46208',
        '46214',
        '46216',
        '46217',
        '46218',
        '46219',
        '46220',
        '46221',
        '46222',
        '46224',
        '46225',
        '46226',
        '46227',
        '46228',
        '46229',
        '46231',
        '46234',
        '46235',
        '46236',
        '46237',
        '46239',
        '46240',
        '46241',
        '46250',
        '46254',
        '46256',
        '46259',
        '46260',
        '46268',
        '46278',
        '46280',
        '46290',
        '46301',
        '46303',
        '46304',
        '46307',
        '46310',
        '46311',
        '46312',
        '46319',
        '46320',
        '46321',
        '46322',
        '46323',
        '46324',
        '46327',
        '46340',
        '46341',
        '46342',
        '46345',
        '46346',
        '46347',
        '46348',
        '46349',
        '46350',
        '46356',
        '46360',
        '46365',
        '46366',
        '46368',
        '46371',
        '46373',
        '46374',
        '46375',
        '46376',
        '46377',
        '46379',
        '46381',
        '46382',
        '46383',
        '46385',
        '46390',
        '46391',
        '46392',
        '46393',
        '46394',
        '46402',
        '46403',
        '46404',
        '46405',
        '46406',
        '46407',
        '46408',
        '46409',
        '46410',
        '46501',
        '46502',
        '46504',
        '46506',
        '46507',
        '46508',
        '46510',
        '46511',
        '46514',
        '46516',
        '46517',
        '46524',
        '46526',
        '46528',
        '46530',
        '46531',
        '46532',
        '46534',
        '46536',
        '46537',
        '46538',
        '46539',
        '46540',
        '46542',
        '46543',
        '46544',
        '46545',
        '46550',
        '46552',
        '46553',
        '46554',
        '46555',
        '46556',
        '46561',
        '46562',
        '46563',
        '46565',
        '46567',
        '46570',
        '46571',
        '46573',
        '46574',
        '46580',
        '46582',
        '46590',
        '46595',
        '46601',
        '46613',
        '46614',
        '46615',
        '46616',
        '46617',
        '46619',
        '46628',
        '46635',
        '46637',
        '46701',
        '46702',
        '46703',
        '46704',
        '46705',
        '46706',
        '46710',
        '46711',
        '46714',
        '46721',
        '46723',
        '46725',
        '46730',
        '46731',
        '46732',
        '46733',
        '46737',
        '46738',
        '46740',
        '46741',
        '46742',
        '46743',
        '46745',
        '46746',
        '46747',
        '46748',
        '46750',
        '46755',
        '46759',
        '46760',
        '46761',
        '46763',
        '46764',
        '46765',
        '46766',
        '46767',
        '46770',
        '46771',
        '46772',
        '46773',
        '46774',
        '46776',
        '46777',
        '46779',
        '46781',
        '46783',
        '46784',
        '46785',
        '46786',
        '46787',
        '46788',
        '46791',
        '46792',
        '46793',
        '46794',
        '46795',
        '46797',
        '46798',
        '46799',
        '46802',
        '46803',
        '46804',
        '46805',
        '46806',
        '46807',
        '46808',
        '46809',
        '46814',
        '46815',
        '46816',
        '46818',
        '46819',
        '46825',
        '46835',
        '46845',
        '46901',
        '46902',
        '46910',
        '46911',
        '46913',
        '46914',
        '46915',
        '46917',
        '46919',
        '46920',
        '46922',
        '46923',
        '46926',
        '46928',
        '46929',
        '46930',
        '46931',
        '46932',
        '46933',
        '46936',
        '46938',
        '46939',
        '46940',
        '46941',
        '46943',
        '46946',
        '46947',
        '46950',
        '46951',
        '46952',
        '46953',
        '46957',
        '46958',
        '46959',
        '46960',
        '46961',
        '46962',
        '46967',
        '46968',
        '46970',
        '46974',
        '46975',
        '46978',
        '46979',
        '46982',
        '46984',
        '46985',
        '46986',
        '46987',
        '46988',
        '46989',
        '46990',
        '46991',
        '46992',
        '46994',
        '46996',
        '46998',
        '47001',
        '47003',
        '47006',
        '47010',
        '47011',
        '47012',
        '47016',
        '47017',
        '47018',
        '47020',
        '47022',
        '47023',
        '47024',
        '47025',
        '47030',
        '47031',
        '47032',
        '47034',
        '47035',
        '47036',
        '47037',
        '47038',
        '47040',
        '47041',
        '47042',
        '47043',
        '47060',
        '47102',
        '47104',
        '47106',
        '47108',
        '47110',
        '47111',
        '47112',
        '47114',
        '47115',
        '47116',
        '47117',
        '47118',
        '47119',
        '47120',
        '47122',
        '47123',
        '47124',
        '47125',
        '47126',
        '47129',
        '47130',
        '47135',
        '47136',
        '47137',
        '47138',
        '47140',
        '47141',
        '47142',
        '47143',
        '47145',
        '47147',
        '47150',
        '47160',
        '47161',
        '47162',
        '47163',
        '47164',
        '47165',
        '47166',
        '47167',
        '47170',
        '47172',
        '47175',
        '47177',
        '47201',
        '47203',
        '47220',
        '47223',
        '47224',
        '47226',
        '47227',
        '47229',
        '47230',
        '47231',
        '47232',
        '47234',
        '47235',
        '47240',
        '47243',
        '47244',
        '47246',
        '47247',
        '47250',
        '47260',
        '47263',
        '47264',
        '47265',
        '47270',
        '47272',
        '47273',
        '47274',
        '47280',
        '47281',
        '47282',
        '47283',
        '47302',
        '47303',
        '47304',
        '47305',
        '47306',
        '47320',
        '47324',
        '47325',
        '47326',
        '47327',
        '47330',
        '47331',
        '47334',
        '47335',
        '47336',
        '47337',
        '47338',
        '47339',
        '47340',
        '47341',
        '47342',
        '47344',
        '47345',
        '47346',
        '47348',
        '47351',
        '47352',
        '47353',
        '47354',
        '47355',
        '47356',
        '47357',
        '47358',
        '47359',
        '47360',
        '47361',
        '47362',
        '47367',
        '47368',
        '47369',
        '47371',
        '47373',
        '47374',
        '47380',
        '47381',
        '47382',
        '47383',
        '47384',
        '47385',
        '47386',
        '47387',
        '47388',
        '47390',
        '47393',
        '47394',
        '47396',
        '47401',
        '47403',
        '47404',
        '47405',
        '47406',
        '47408',
        '47420',
        '47421',
        '47424',
        '47427',
        '47429',
        '47431',
        '47432',
        '47433',
        '47434',
        '47436',
        '47437',
        '47438',
        '47441',
        '47443',
        '47446',
        '47448',
        '47449',
        '47451',
        '47452',
        '47453',
        '47454',
        '47455',
        '47456',
        '47457',
        '47458',
        '47459',
        '47460',
        '47462',
        '47464',
        '47465',
        '47467',
        '47468',
        '47469',
        '47470',
        '47471',
        '47501',
        '47512',
        '47513',
        '47514',
        '47515',
        '47516',
        '47519',
        '47520',
        '47521',
        '47522',
        '47523',
        '47524',
        '47525',
        '47527',
        '47528',
        '47529',
        '47531',
        '47532',
        '47535',
        '47536',
        '47537',
        '47541',
        '47542',
        '47546',
        '47550',
        '47551',
        '47552',
        '47553',
        '47557',
        '47558',
        '47561',
        '47562',
        '47564',
        '47567',
        '47568',
        '47574',
        '47575',
        '47576',
        '47577',
        '47578',
        '47579',
        '47580',
        '47581',
        '47584',
        '47585',
        '47586',
        '47588',
        '47590',
        '47591',
        '47596',
        '47597',
        '47598',
        '47601',
        '47610',
        '47611',
        '47612',
        '47613',
        '47615',
        '47616',
        '47619',
        '47620',
        '47630',
        '47631',
        '47633',
        '47634',
        '47635',
        '47637',
        '47638',
        '47639',
        '47640',
        '47648',
        '47649',
        '47654',
        '47660',
        '47665',
        '47666',
        '47670',
        '47683',
        '47708',
        '47710',
        '47711',
        '47712',
        '47713',
        '47714',
        '47715',
        '47720',
        '47725',
        '47802',
        '47803',
        '47804',
        '47805',
        '47807',
        '47809',
        '47832',
        '47833',
        '47834',
        '47836',
        '47837',
        '47838',
        '47840',
        '47841',
        '47842',
        '47846',
        '47847',
        '47848',
        '47849',
        '47850',
        '47853',
        '47854',
        '47855',
        '47857',
        '47858',
        '47859',
        '47860',
        '47861',
        '47862',
        '47863',
        '47865',
        '47866',
        '47868',
        '47869',
        '47871',
        '47872',
        '47874',
        '47876',
        '47879',
        '47880',
        '47881',
        '47882',
        '47884',
        '47885',
        '47901',
        '47904',
        '47905',
        '47906',
        '47907',
        '47909',
        '47916',
        '47917',
        '47918',
        '47920',
        '47921',
        '47922',
        '47923',
        '47924',
        '47925',
        '47926',
        '47928',
        '47929',
        '47930',
        '47932',
        '47933',
        '47940',
        '47941',
        '47942',
        '47943',
        '47944',
        '47946',
        '47948',
        '47949',
        '47950',
        '47951',
        '47952',
        '47954',
        '47955',
        '47957',
        '47958',
        '47959',
        '47960',
        '47963',
        '47964',
        '47965',
        '47966',
        '47967',
        '47968',
        '47969',
        '47970',
        '47971',
        '47974',
        '47975',
        '47977',
        '47978',
        '47980',
        '47981',
        '47982',
        '47983',
        '47987',
        '47989',
        '47990',
        '47991',
        '47992',
        '47993',
        '47994',
        '47995',
        '47997'
      ]
    },
    MI: {
      name: 'Michigan',
      codes: [
        '48001',
        '48002',
        '48003',
        '48005',
        '48006',
        '48009',
        '48014',
        '48015',
        '48017',
        '48021',
        '48022',
        '48023',
        '48025',
        '48026',
        '48027',
        '48028',
        '48030',
        '48032',
        '48033',
        '48034',
        '48035',
        '48036',
        '48038',
        '48039',
        '48040',
        '48041',
        '48042',
        '48043',
        '48044',
        '48045',
        '48047',
        '48048',
        '48049',
        '48050',
        '48051',
        '48054',
        '48059',
        '48060',
        '48062',
        '48063',
        '48064',
        '48065',
        '48066',
        '48067',
        '48069',
        '48070',
        '48071',
        '48072',
        '48073',
        '48074',
        '48075',
        '48076',
        '48079',
        '48080',
        '48081',
        '48082',
        '48083',
        '48084',
        '48085',
        '48088',
        '48089',
        '48091',
        '48092',
        '48093',
        '48094',
        '48095',
        '48096',
        '48097',
        '48098',
        '48101',
        '48103',
        '48104',
        '48105',
        '48108',
        '48109',
        '48111',
        '48114',
        '48116',
        '48117',
        '48118',
        '48120',
        '48122',
        '48124',
        '48125',
        '48126',
        '48127',
        '48128',
        '48130',
        '48131',
        '48133',
        '48134',
        '48135',
        '48137',
        '48138',
        '48139',
        '48140',
        '48141',
        '48143',
        '48144',
        '48145',
        '48146',
        '48150',
        '48152',
        '48154',
        '48157',
        '48158',
        '48159',
        '48160',
        '48161',
        '48162',
        '48164',
        '48165',
        '48166',
        '48167',
        '48168',
        '48169',
        '48170',
        '48173',
        '48174',
        '48176',
        '48177',
        '48178',
        '48179',
        '48180',
        '48182',
        '48183',
        '48184',
        '48185',
        '48186',
        '48187',
        '48188',
        '48189',
        '48190',
        '48191',
        '48192',
        '48193',
        '48195',
        '48197',
        '48198',
        '48201',
        '48202',
        '48203',
        '48204',
        '48205',
        '48206',
        '48207',
        '48208',
        '48209',
        '48210',
        '48211',
        '48212',
        '48213',
        '48214',
        '48215',
        '48216',
        '48217',
        '48218',
        '48219',
        '48220',
        '48221',
        '48223',
        '48224',
        '48225',
        '48226',
        '48227',
        '48228',
        '48229',
        '48230',
        '48233',
        '48234',
        '48235',
        '48236',
        '48237',
        '48238',
        '48239',
        '48240',
        '48242',
        '48243',
        '48301',
        '48302',
        '48304',
        '48306',
        '48307',
        '48309',
        '48310',
        '48312',
        '48313',
        '48314',
        '48315',
        '48316',
        '48317',
        '48320',
        '48322',
        '48323',
        '48324',
        '48326',
        '48327',
        '48328',
        '48329',
        '48331',
        '48334',
        '48335',
        '48336',
        '48340',
        '48341',
        '48342',
        '48346',
        '48348',
        '48350',
        '48353',
        '48356',
        '48357',
        '48359',
        '48360',
        '48362',
        '48363',
        '48367',
        '48370',
        '48371',
        '48374',
        '48375',
        '48377',
        '48380',
        '48381',
        '48382',
        '48383',
        '48386',
        '48390',
        '48393',
        '48397',
        '48401',
        '48411',
        '48412',
        '48413',
        '48414',
        '48415',
        '48416',
        '48417',
        '48418',
        '48419',
        '48420',
        '48421',
        '48422',
        '48423',
        '48426',
        '48427',
        '48428',
        '48429',
        '48430',
        '48432',
        '48433',
        '48434',
        '48435',
        '48436',
        '48437',
        '48438',
        '48439',
        '48440',
        '48441',
        '48442',
        '48444',
        '48445',
        '48446',
        '48449',
        '48450',
        '48451',
        '48453',
        '48454',
        '48455',
        '48456',
        '48457',
        '48458',
        '48460',
        '48461',
        '48462',
        '48463',
        '48464',
        '48465',
        '48466',
        '48467',
        '48468',
        '48469',
        '48470',
        '48471',
        '48472',
        '48473',
        '48475',
        '48476',
        '48502',
        '48503',
        '48504',
        '48505',
        '48506',
        '48507',
        '48509',
        '48519',
        '48529',
        '48532',
        '48551',
        '48553',
        '48554',
        '48601',
        '48602',
        '48603',
        '48604',
        '48607',
        '48609',
        '48610',
        '48611',
        '48612',
        '48613',
        '48614',
        '48615',
        '48616',
        '48617',
        '48618',
        '48619',
        '48621',
        '48622',
        '48623',
        '48624',
        '48625',
        '48626',
        '48627',
        '48628',
        '48629',
        '48630',
        '48631',
        '48632',
        '48633',
        '48634',
        '48635',
        '48636',
        '48637',
        '48638',
        '48640',
        '48642',
        '48647',
        '48649',
        '48650',
        '48651',
        '48652',
        '48653',
        '48654',
        '48655',
        '48656',
        '48657',
        '48658',
        '48659',
        '48661',
        '48662',
        '48667',
        '48701',
        '48703',
        '48705',
        '48706',
        '48708',
        '48710',
        '48720',
        '48721',
        '48722',
        '48723',
        '48724',
        '48725',
        '48726',
        '48727',
        '48728',
        '48729',
        '48730',
        '48731',
        '48732',
        '48733',
        '48734',
        '48735',
        '48737',
        '48738',
        '48739',
        '48740',
        '48741',
        '48742',
        '48743',
        '48744',
        '48745',
        '48746',
        '48747',
        '48748',
        '48749',
        '48750',
        '48754',
        '48755',
        '48756',
        '48757',
        '48759',
        '48760',
        '48761',
        '48762',
        '48763',
        '48765',
        '48766',
        '48767',
        '48768',
        '48770',
        '48801',
        '48806',
        '48807',
        '48808',
        '48809',
        '48811',
        '48813',
        '48815',
        '48816',
        '48817',
        '48818',
        '48819',
        '48820',
        '48821',
        '48822',
        '48823',
        '48825',
        '48827',
        '48829',
        '48831',
        '48832',
        '48834',
        '48835',
        '48836',
        '48837',
        '48838',
        '48840',
        '48841',
        '48842',
        '48843',
        '48845',
        '48846',
        '48847',
        '48848',
        '48849',
        '48850',
        '48851',
        '48852',
        '48853',
        '48854',
        '48855',
        '48856',
        '48857',
        '48858',
        '48860',
        '48861',
        '48864',
        '48865',
        '48866',
        '48867',
        '48870',
        '48871',
        '48872',
        '48873',
        '48874',
        '48875',
        '48876',
        '48877',
        '48878',
        '48879',
        '48880',
        '48881',
        '48883',
        '48884',
        '48885',
        '48886',
        '48888',
        '48889',
        '48890',
        '48891',
        '48892',
        '48893',
        '48894',
        '48895',
        '48896',
        '48897',
        '48906',
        '48910',
        '48911',
        '48912',
        '48915',
        '48917',
        '48933',
        '49001',
        '49002',
        '49004',
        '49006',
        '49007',
        '49008',
        '49009',
        '49010',
        '49011',
        '49012',
        '49013',
        '49014',
        '49015',
        '49017',
        '49021',
        '49022',
        '49024',
        '49026',
        '49027',
        '49028',
        '49029',
        '49030',
        '49031',
        '49032',
        '49033',
        '49034',
        '49036',
        '49037',
        '49038',
        '49040',
        '49042',
        '49043',
        '49045',
        '49046',
        '49047',
        '49048',
        '49050',
        '49051',
        '49052',
        '49053',
        '49055',
        '49056',
        '49057',
        '49058',
        '49060',
        '49061',
        '49064',
        '49065',
        '49066',
        '49067',
        '49068',
        '49070',
        '49071',
        '49072',
        '49073',
        '49074',
        '49075',
        '49076',
        '49078',
        '49079',
        '49080',
        '49082',
        '49083',
        '49084',
        '49085',
        '49087',
        '49088',
        '49089',
        '49090',
        '49091',
        '49092',
        '49093',
        '49094',
        '49095',
        '49096',
        '49097',
        '49098',
        '49099',
        '49101',
        '49102',
        '49103',
        '49104',
        '49106',
        '49107',
        '49111',
        '49112',
        '49113',
        '49115',
        '49116',
        '49117',
        '49119',
        '49120',
        '49125',
        '49126',
        '49127',
        '49128',
        '49129',
        '49130',
        '49201',
        '49202',
        '49203',
        '49220',
        '49221',
        '49224',
        '49227',
        '49228',
        '49229',
        '49230',
        '49232',
        '49233',
        '49234',
        '49235',
        '49236',
        '49237',
        '49238',
        '49240',
        '49241',
        '49242',
        '49245',
        '49246',
        '49247',
        '49248',
        '49249',
        '49250',
        '49251',
        '49252',
        '49253',
        '49254',
        '49255',
        '49256',
        '49259',
        '49261',
        '49262',
        '49263',
        '49264',
        '49265',
        '49266',
        '49267',
        '49268',
        '49269',
        '49270',
        '49271',
        '49272',
        '49274',
        '49276',
        '49277',
        '49279',
        '49282',
        '49283',
        '49284',
        '49285',
        '49286',
        '49287',
        '49288',
        '49289',
        '49301',
        '49302',
        '49303',
        '49304',
        '49305',
        '49306',
        '49307',
        '49309',
        '49310',
        '49312',
        '49315',
        '49316',
        '49318',
        '49319',
        '49320',
        '49321',
        '49322',
        '49323',
        '49325',
        '49326',
        '49327',
        '49328',
        '49329',
        '49330',
        '49331',
        '49332',
        '49333',
        '49335',
        '49336',
        '49337',
        '49338',
        '49339',
        '49340',
        '49341',
        '49342',
        '49343',
        '49344',
        '49345',
        '49346',
        '49347',
        '49348',
        '49349',
        '49401',
        '49402',
        '49403',
        '49404',
        '49405',
        '49406',
        '49408',
        '49410',
        '49411',
        '49412',
        '49415',
        '49417',
        '49418',
        '49419',
        '49420',
        '49421',
        '49423',
        '49424',
        '49425',
        '49426',
        '49428',
        '49431',
        '49434',
        '49435',
        '49436',
        '49437',
        '49440',
        '49441',
        '49442',
        '49444',
        '49445',
        '49446',
        '49448',
        '49449',
        '49450',
        '49451',
        '49452',
        '49453',
        '49454',
        '49455',
        '49456',
        '49457',
        '49458',
        '49459',
        '49460',
        '49461',
        '49464',
        '49503',
        '49504',
        '49505',
        '49506',
        '49507',
        '49508',
        '49509',
        '49512',
        '49519',
        '49525',
        '49534',
        '49544',
        '49546',
        '49548',
        '49601',
        '49611',
        '49612',
        '49613',
        '49614',
        '49615',
        '49616',
        '49617',
        '49618',
        '49619',
        '49620',
        '49621',
        '49622',
        '49623',
        '49625',
        '49626',
        '49627',
        '49628',
        '49629',
        '49630',
        '49631',
        '49632',
        '49633',
        '49634',
        '49635',
        '49636',
        '49637',
        '49638',
        '49639',
        '49640',
        '49642',
        '49643',
        '49644',
        '49645',
        '49646',
        '49648',
        '49649',
        '49650',
        '49651',
        '49653',
        '49654',
        '49655',
        '49656',
        '49657',
        '49659',
        '49660',
        '49663',
        '49664',
        '49665',
        '49666',
        '49667',
        '49668',
        '49670',
        '49674',
        '49675',
        '49676',
        '49677',
        '49679',
        '49680',
        '49682',
        '49683',
        '49684',
        '49686',
        '49688',
        '49689',
        '49690',
        '49701',
        '49705',
        '49706',
        '49707',
        '49709',
        '49710',
        '49712',
        '49713',
        '49715',
        '49716',
        '49717',
        '49718',
        '49719',
        '49720',
        '49721',
        '49722',
        '49724',
        '49725',
        '49726',
        '49727',
        '49728',
        '49729',
        '49730',
        '49733',
        '49735',
        '49736',
        '49738',
        '49740',
        '49743',
        '49744',
        '49745',
        '49746',
        '49747',
        '49748',
        '49749',
        '49751',
        '49752',
        '49753',
        '49755',
        '49756',
        '49757',
        '49759',
        '49760',
        '49762',
        '49764',
        '49765',
        '49766',
        '49768',
        '49769',
        '49770',
        '49774',
        '49775',
        '49776',
        '49777',
        '49779',
        '49780',
        '49781',
        '49782',
        '49783',
        '49788',
        '49791',
        '49793',
        '49795',
        '49796',
        '49799',
        '49801',
        '49802',
        '49805',
        '49806',
        '49807',
        '49808',
        '49812',
        '49814',
        '49815',
        '49816',
        '49817',
        '49818',
        '49819',
        '49820',
        '49821',
        '49822',
        '49825',
        '49826',
        '49827',
        '49829',
        '49831',
        '49833',
        '49834',
        '49835',
        '49836',
        '49837',
        '49838',
        '49839',
        '49840',
        '49841',
        '49847',
        '49848',
        '49849',
        '49852',
        '49853',
        '49854',
        '49855',
        '49858',
        '49861',
        '49862',
        '49863',
        '49864',
        '49866',
        '49868',
        '49870',
        '49871',
        '49872',
        '49873',
        '49874',
        '49876',
        '49877',
        '49878',
        '49879',
        '49880',
        '49881',
        '49883',
        '49884',
        '49885',
        '49886',
        '49887',
        '49891',
        '49892',
        '49893',
        '49894',
        '49895',
        '49896',
        '49901',
        '49902',
        '49903',
        '49905',
        '49908',
        '49910',
        '49911',
        '49912',
        '49913',
        '49915',
        '49916',
        '49917',
        '49918',
        '49919',
        '49920',
        '49921',
        '49922',
        '49925',
        '49927',
        '49929',
        '49930',
        '49931',
        '49934',
        '49935',
        '49938',
        '49942',
        '49945',
        '49946',
        '49947',
        '49948',
        '49950',
        '49952',
        '49953',
        '49955',
        '49958',
        '49959',
        '49960',
        '49961',
        '49962',
        '49963',
        '49965',
        '49967',
        '49968',
        '49969',
        '49970',
        '49971'
      ]
    },
    IA: {
      name: 'Iowa',
      codes: [
        '50001',
        '50002',
        '50003',
        '50005',
        '50006',
        '50007',
        '50008',
        '50009',
        '50010',
        '50011',
        '50012',
        '50014',
        '50020',
        '50021',
        '50022',
        '50023',
        '50025',
        '50026',
        '50027',
        '50028',
        '50029',
        '50032',
        '50033',
        '50034',
        '50035',
        '50036',
        '50038',
        '50039',
        '50041',
        '50042',
        '50044',
        '50046',
        '50047',
        '50048',
        '50049',
        '50050',
        '50051',
        '50052',
        '50054',
        '50055',
        '50056',
        '50057',
        '50058',
        '50060',
        '50061',
        '50062',
        '50063',
        '50064',
        '50065',
        '50066',
        '50067',
        '50068',
        '50069',
        '50070',
        '50071',
        '50072',
        '50073',
        '50074',
        '50075',
        '50076',
        '50078',
        '50101',
        '50102',
        '50103',
        '50104',
        '50105',
        '50106',
        '50107',
        '50108',
        '50109',
        '50111',
        '50112',
        '50115',
        '50116',
        '50117',
        '50118',
        '50119',
        '50120',
        '50122',
        '50123',
        '50124',
        '50125',
        '50126',
        '50127',
        '50128',
        '50129',
        '50130',
        '50131',
        '50132',
        '50133',
        '50134',
        '50135',
        '50136',
        '50138',
        '50139',
        '50140',
        '50141',
        '50142',
        '50143',
        '50144',
        '50146',
        '50147',
        '50148',
        '50149',
        '50150',
        '50151',
        '50153',
        '50154',
        '50155',
        '50156',
        '50157',
        '50158',
        '50160',
        '50161',
        '50162',
        '50163',
        '50164',
        '50165',
        '50166',
        '50167',
        '50168',
        '50169',
        '50170',
        '50171',
        '50173',
        '50174',
        '50201',
        '50206',
        '50207',
        '50208',
        '50210',
        '50211',
        '50212',
        '50213',
        '50214',
        '50216',
        '50217',
        '50218',
        '50219',
        '50220',
        '50222',
        '50223',
        '50225',
        '50226',
        '50227',
        '50228',
        '50229',
        '50230',
        '50231',
        '50232',
        '50233',
        '50234',
        '50235',
        '50236',
        '50237',
        '50238',
        '50239',
        '50240',
        '50242',
        '50243',
        '50244',
        '50246',
        '50247',
        '50248',
        '50249',
        '50250',
        '50251',
        '50252',
        '50254',
        '50255',
        '50256',
        '50257',
        '50258',
        '50261',
        '50262',
        '50263',
        '50264',
        '50265',
        '50266',
        '50268',
        '50271',
        '50272',
        '50273',
        '50274',
        '50275',
        '50276',
        '50277',
        '50278',
        '50309',
        '50310',
        '50311',
        '50312',
        '50313',
        '50314',
        '50315',
        '50316',
        '50317',
        '50319',
        '50320',
        '50321',
        '50322',
        '50323',
        '50324',
        '50325',
        '50327',
        '50401',
        '50420',
        '50421',
        '50423',
        '50424',
        '50426',
        '50428',
        '50430',
        '50431',
        '50432',
        '50433',
        '50434',
        '50435',
        '50436',
        '50438',
        '50439',
        '50440',
        '50441',
        '50444',
        '50446',
        '50447',
        '50448',
        '50449',
        '50450',
        '50451',
        '50452',
        '50453',
        '50454',
        '50455',
        '50456',
        '50457',
        '50458',
        '50459',
        '50460',
        '50461',
        '50464',
        '50465',
        '50466',
        '50467',
        '50468',
        '50469',
        '50470',
        '50471',
        '50472',
        '50473',
        '50475',
        '50476',
        '50477',
        '50478',
        '50479',
        '50480',
        '50482',
        '50483',
        '50484',
        '50501',
        '50510',
        '50511',
        '50514',
        '50515',
        '50516',
        '50517',
        '50518',
        '50519',
        '50520',
        '50521',
        '50522',
        '50523',
        '50524',
        '50525',
        '50527',
        '50528',
        '50529',
        '50530',
        '50531',
        '50532',
        '50533',
        '50535',
        '50536',
        '50538',
        '50539',
        '50540',
        '50541',
        '50542',
        '50543',
        '50544',
        '50545',
        '50546',
        '50548',
        '50551',
        '50554',
        '50556',
        '50557',
        '50558',
        '50559',
        '50560',
        '50561',
        '50562',
        '50563',
        '50565',
        '50566',
        '50567',
        '50568',
        '50569',
        '50570',
        '50571',
        '50573',
        '50574',
        '50575',
        '50576',
        '50577',
        '50578',
        '50579',
        '50581',
        '50582',
        '50583',
        '50585',
        '50586',
        '50588',
        '50590',
        '50591',
        '50593',
        '50594',
        '50595',
        '50597',
        '50598',
        '50599',
        '50601',
        '50602',
        '50603',
        '50604',
        '50605',
        '50606',
        '50607',
        '50609',
        '50611',
        '50612',
        '50613',
        '50616',
        '50619',
        '50620',
        '50621',
        '50622',
        '50624',
        '50625',
        '50626',
        '50627',
        '50628',
        '50629',
        '50630',
        '50632',
        '50633',
        '50634',
        '50635',
        '50636',
        '50638',
        '50641',
        '50642',
        '50643',
        '50644',
        '50645',
        '50647',
        '50648',
        '50650',
        '50651',
        '50652',
        '50653',
        '50654',
        '50655',
        '50658',
        '50659',
        '50660',
        '50662',
        '50664',
        '50665',
        '50666',
        '50667',
        '50668',
        '50669',
        '50670',
        '50671',
        '50672',
        '50673',
        '50674',
        '50675',
        '50676',
        '50677',
        '50680',
        '50681',
        '50682',
        '50701',
        '50702',
        '50703',
        '50707',
        '50801',
        '50830',
        '50833',
        '50835',
        '50836',
        '50837',
        '50839',
        '50840',
        '50841',
        '50842',
        '50843',
        '50845',
        '50846',
        '50847',
        '50848',
        '50849',
        '50851',
        '50853',
        '50854',
        '50857',
        '50858',
        '50859',
        '50860',
        '50861',
        '50862',
        '50863',
        '50864',
        '51001',
        '51002',
        '51003',
        '51004',
        '51005',
        '51006',
        '51007',
        '51008',
        '51009',
        '51010',
        '51011',
        '51012',
        '51014',
        '51016',
        '51018',
        '51019',
        '51020',
        '51022',
        '51023',
        '51024',
        '51025',
        '51026',
        '51027',
        '51028',
        '51029',
        '51030',
        '51031',
        '51033',
        '51034',
        '51035',
        '51036',
        '51037',
        '51038',
        '51039',
        '51040',
        '51041',
        '51044',
        '51046',
        '51047',
        '51048',
        '51049',
        '51050',
        '51051',
        '51052',
        '51053',
        '51054',
        '51055',
        '51056',
        '51058',
        '51060',
        '51061',
        '51062',
        '51063',
        '51101',
        '51103',
        '51104',
        '51105',
        '51106',
        '51108',
        '51109',
        '51111',
        '51201',
        '51230',
        '51231',
        '51232',
        '51234',
        '51235',
        '51237',
        '51238',
        '51239',
        '51240',
        '51241',
        '51242',
        '51243',
        '51244',
        '51245',
        '51246',
        '51247',
        '51248',
        '51249',
        '51250',
        '51301',
        '51331',
        '51333',
        '51334',
        '51338',
        '51341',
        '51342',
        '51343',
        '51345',
        '51346',
        '51347',
        '51350',
        '51351',
        '51354',
        '51355',
        '51357',
        '51358',
        '51360',
        '51363',
        '51364',
        '51365',
        '51366',
        '51401',
        '51430',
        '51431',
        '51433',
        '51436',
        '51439',
        '51440',
        '51441',
        '51442',
        '51443',
        '51444',
        '51445',
        '51446',
        '51447',
        '51448',
        '51449',
        '51450',
        '51451',
        '51453',
        '51454',
        '51455',
        '51458',
        '51459',
        '51461',
        '51462',
        '51463',
        '51465',
        '51466',
        '51467',
        '51501',
        '51503',
        '51510',
        '51520',
        '51521',
        '51523',
        '51525',
        '51526',
        '51527',
        '51528',
        '51529',
        '51530',
        '51531',
        '51532',
        '51533',
        '51534',
        '51535',
        '51536',
        '51537',
        '51540',
        '51541',
        '51542',
        '51543',
        '51544',
        '51545',
        '51546',
        '51548',
        '51549',
        '51550',
        '51551',
        '51552',
        '51553',
        '51554',
        '51555',
        '51556',
        '51557',
        '51558',
        '51559',
        '51560',
        '51561',
        '51562',
        '51563',
        '51564',
        '51565',
        '51566',
        '51570',
        '51571',
        '51572',
        '51573',
        '51575',
        '51576',
        '51577',
        '51578',
        '51579',
        '51601',
        '51630',
        '51631',
        '51632',
        '51636',
        '51637',
        '51638',
        '51639',
        '51640',
        '51645',
        '51646',
        '51647',
        '51648',
        '51649',
        '51650',
        '51652',
        '51653',
        '51654',
        '51656',
        '52001',
        '52002',
        '52003',
        '52030',
        '52031',
        '52032',
        '52033',
        '52035',
        '52037',
        '52038',
        '52039',
        '52040',
        '52041',
        '52042',
        '52043',
        '52044',
        '52045',
        '52046',
        '52047',
        '52048',
        '52049',
        '52050',
        '52052',
        '52053',
        '52054',
        '52057',
        '52060',
        '52064',
        '52065',
        '52066',
        '52068',
        '52069',
        '52070',
        '52072',
        '52073',
        '52074',
        '52076',
        '52077',
        '52078',
        '52079',
        '52101',
        '52132',
        '52133',
        '52134',
        '52135',
        '52136',
        '52140',
        '52141',
        '52142',
        '52144',
        '52146',
        '52147',
        '52151',
        '52154',
        '52155',
        '52156',
        '52157',
        '52158',
        '52159',
        '52160',
        '52161',
        '52162',
        '52163',
        '52164',
        '52165',
        '52166',
        '52168',
        '52169',
        '52170',
        '52171',
        '52172',
        '52175',
        '52201',
        '52202',
        '52203',
        '52205',
        '52206',
        '52207',
        '52208',
        '52209',
        '52210',
        '52211',
        '52212',
        '52213',
        '52214',
        '52215',
        '52216',
        '52217',
        '52218',
        '52219',
        '52220',
        '52221',
        '52222',
        '52223',
        '52224',
        '52225',
        '52227',
        '52228',
        '52229',
        '52231',
        '52232',
        '52233',
        '52235',
        '52236',
        '52237',
        '52240',
        '52241',
        '52242',
        '52245',
        '52246',
        '52247',
        '52248',
        '52249',
        '52251',
        '52253',
        '52254',
        '52255',
        '52257',
        '52301',
        '52302',
        '52305',
        '52306',
        '52307',
        '52308',
        '52309',
        '52310',
        '52312',
        '52313',
        '52314',
        '52315',
        '52316',
        '52317',
        '52318',
        '52320',
        '52321',
        '52322',
        '52323',
        '52324',
        '52325',
        '52326',
        '52327',
        '52328',
        '52329',
        '52330',
        '52332',
        '52333',
        '52334',
        '52335',
        '52336',
        '52337',
        '52338',
        '52339',
        '52340',
        '52341',
        '52342',
        '52345',
        '52346',
        '52347',
        '52348',
        '52349',
        '52351',
        '52352',
        '52353',
        '52354',
        '52355',
        '52356',
        '52358',
        '52359',
        '52361',
        '52362',
        '52401',
        '52402',
        '52403',
        '52404',
        '52405',
        '52411',
        '52501',
        '52530',
        '52531',
        '52533',
        '52534',
        '52535',
        '52536',
        '52537',
        '52540',
        '52542',
        '52543',
        '52544',
        '52548',
        '52549',
        '52550',
        '52551',
        '52552',
        '52553',
        '52554',
        '52555',
        '52556',
        '52557',
        '52560',
        '52561',
        '52563',
        '52565',
        '52566',
        '52567',
        '52569',
        '52570',
        '52571',
        '52572',
        '52573',
        '52574',
        '52576',
        '52577',
        '52580',
        '52581',
        '52583',
        '52584',
        '52585',
        '52586',
        '52588',
        '52590',
        '52591',
        '52593',
        '52594',
        '52595',
        '52601',
        '52619',
        '52620',
        '52621',
        '52623',
        '52624',
        '52625',
        '52626',
        '52627',
        '52630',
        '52632',
        '52635',
        '52637',
        '52638',
        '52639',
        '52640',
        '52641',
        '52644',
        '52645',
        '52646',
        '52647',
        '52649',
        '52650',
        '52651',
        '52653',
        '52654',
        '52655',
        '52656',
        '52657',
        '52658',
        '52659',
        '52660',
        '52701',
        '52720',
        '52721',
        '52722',
        '52726',
        '52727',
        '52728',
        '52729',
        '52730',
        '52731',
        '52732',
        '52737',
        '52738',
        '52739',
        '52742',
        '52745',
        '52746',
        '52747',
        '52748',
        '52749',
        '52750',
        '52751',
        '52752',
        '52753',
        '52754',
        '52755',
        '52756',
        '52757',
        '52758',
        '52760',
        '52761',
        '52765',
        '52766',
        '52767',
        '52768',
        '52769',
        '52772',
        '52773',
        '52774',
        '52776',
        '52777',
        '52778',
        '52801',
        '52802',
        '52803',
        '52804',
        '52806',
        '52807'
      ]
    },
    WI: {
      name: 'Wisconsin',
      codes: [
        '53001',
        '53002',
        '53003',
        '53004',
        '53005',
        '53006',
        '53007',
        '53010',
        '53011',
        '53012',
        '53013',
        '53014',
        '53015',
        '53016',
        '53017',
        '53018',
        '53019',
        '53020',
        '53021',
        '53022',
        '53023',
        '53024',
        '53027',
        '53029',
        '53031',
        '53032',
        '53033',
        '53034',
        '53035',
        '53036',
        '53037',
        '53038',
        '53039',
        '53040',
        '53042',
        '53044',
        '53045',
        '53046',
        '53047',
        '53048',
        '53049',
        '53050',
        '53051',
        '53057',
        '53058',
        '53059',
        '53061',
        '53063',
        '53065',
        '53066',
        '53069',
        '53070',
        '53072',
        '53073',
        '53074',
        '53075',
        '53076',
        '53078',
        '53079',
        '53080',
        '53081',
        '53083',
        '53085',
        '53086',
        '53088',
        '53089',
        '53090',
        '53091',
        '53092',
        '53093',
        '53094',
        '53095',
        '53097',
        '53098',
        '53103',
        '53104',
        '53105',
        '53108',
        '53110',
        '53114',
        '53115',
        '53118',
        '53119',
        '53120',
        '53121',
        '53122',
        '53125',
        '53126',
        '53128',
        '53129',
        '53130',
        '53132',
        '53137',
        '53139',
        '53140',
        '53142',
        '53143',
        '53144',
        '53146',
        '53147',
        '53149',
        '53150',
        '53151',
        '53153',
        '53154',
        '53156',
        '53158',
        '53167',
        '53168',
        '53170',
        '53172',
        '53177',
        '53178',
        '53179',
        '53181',
        '53182',
        '53183',
        '53184',
        '53185',
        '53186',
        '53188',
        '53189',
        '53190',
        '53191',
        '53192',
        '53195',
        '53202',
        '53203',
        '53204',
        '53205',
        '53206',
        '53207',
        '53208',
        '53209',
        '53210',
        '53211',
        '53212',
        '53213',
        '53214',
        '53215',
        '53216',
        '53217',
        '53218',
        '53219',
        '53220',
        '53221',
        '53222',
        '53223',
        '53224',
        '53225',
        '53226',
        '53227',
        '53228',
        '53233',
        '53235',
        '53295',
        '53402',
        '53403',
        '53404',
        '53405',
        '53406',
        '53501',
        '53502',
        '53503',
        '53504',
        '53505',
        '53506',
        '53507',
        '53508',
        '53510',
        '53511',
        '53515',
        '53516',
        '53517',
        '53518',
        '53520',
        '53521',
        '53522',
        '53523',
        '53525',
        '53526',
        '53527',
        '53528',
        '53529',
        '53530',
        '53531',
        '53532',
        '53533',
        '53534',
        '53536',
        '53537',
        '53538',
        '53540',
        '53541',
        '53543',
        '53544',
        '53545',
        '53546',
        '53548',
        '53549',
        '53550',
        '53551',
        '53553',
        '53554',
        '53555',
        '53556',
        '53557',
        '53558',
        '53559',
        '53560',
        '53561',
        '53562',
        '53563',
        '53565',
        '53566',
        '53569',
        '53570',
        '53571',
        '53572',
        '53573',
        '53574',
        '53575',
        '53576',
        '53577',
        '53578',
        '53579',
        '53580',
        '53581',
        '53582',
        '53583',
        '53585',
        '53586',
        '53587',
        '53588',
        '53589',
        '53590',
        '53593',
        '53594',
        '53597',
        '53598',
        '53599',
        '53702',
        '53703',
        '53704',
        '53705',
        '53706',
        '53711',
        '53713',
        '53714',
        '53715',
        '53716',
        '53717',
        '53718',
        '53719',
        '53726',
        '53792',
        '53801',
        '53802',
        '53803',
        '53804',
        '53805',
        '53806',
        '53807',
        '53808',
        '53809',
        '53810',
        '53811',
        '53813',
        '53816',
        '53817',
        '53818',
        '53820',
        '53821',
        '53825',
        '53826',
        '53827',
        '53901',
        '53910',
        '53911',
        '53913',
        '53916',
        '53919',
        '53920',
        '53922',
        '53923',
        '53924',
        '53925',
        '53926',
        '53928',
        '53929',
        '53930',
        '53931',
        '53932',
        '53933',
        '53934',
        '53935',
        '53936',
        '53937',
        '53939',
        '53941',
        '53943',
        '53944',
        '53946',
        '53947',
        '53948',
        '53949',
        '53950',
        '53951',
        '53952',
        '53953',
        '53954',
        '53955',
        '53956',
        '53959',
        '53960',
        '53961',
        '53963',
        '53964',
        '53965',
        '53968',
        '53969',
        '54001',
        '54002',
        '54003',
        '54004',
        '54005',
        '54006',
        '54007',
        '54009',
        '54011',
        '54013',
        '54014',
        '54015',
        '54016',
        '54017',
        '54020',
        '54021',
        '54022',
        '54023',
        '54024',
        '54025',
        '54026',
        '54027',
        '54028',
        '54082',
        '54101',
        '54102',
        '54103',
        '54104',
        '54106',
        '54107',
        '54110',
        '54111',
        '54112',
        '54113',
        '54114',
        '54115',
        '54119',
        '54120',
        '54121',
        '54123',
        '54124',
        '54125',
        '54126',
        '54127',
        '54128',
        '54129',
        '54130',
        '54135',
        '54136',
        '54137',
        '54138',
        '54139',
        '54140',
        '54141',
        '54143',
        '54149',
        '54150',
        '54151',
        '54153',
        '54154',
        '54155',
        '54156',
        '54157',
        '54159',
        '54160',
        '54161',
        '54162',
        '54165',
        '54166',
        '54169',
        '54170',
        '54171',
        '54173',
        '54174',
        '54175',
        '54177',
        '54180',
        '54201',
        '54202',
        '54204',
        '54205',
        '54207',
        '54208',
        '54209',
        '54210',
        '54211',
        '54212',
        '54213',
        '54214',
        '54216',
        '54217',
        '54220',
        '54227',
        '54228',
        '54229',
        '54230',
        '54232',
        '54234',
        '54235',
        '54241',
        '54245',
        '54246',
        '54247',
        '54301',
        '54302',
        '54303',
        '54304',
        '54307',
        '54311',
        '54313',
        '54401',
        '54403',
        '54405',
        '54406',
        '54407',
        '54408',
        '54409',
        '54410',
        '54411',
        '54412',
        '54413',
        '54414',
        '54416',
        '54417',
        '54418',
        '54420',
        '54421',
        '54422',
        '54423',
        '54424',
        '54425',
        '54426',
        '54427',
        '54428',
        '54430',
        '54433',
        '54435',
        '54436',
        '54437',
        '54440',
        '54441',
        '54442',
        '54443',
        '54446',
        '54447',
        '54448',
        '54449',
        '54450',
        '54451',
        '54452',
        '54454',
        '54455',
        '54456',
        '54457',
        '54458',
        '54459',
        '54460',
        '54462',
        '54463',
        '54465',
        '54466',
        '54467',
        '54469',
        '54470',
        '54471',
        '54473',
        '54474',
        '54475',
        '54476',
        '54479',
        '54480',
        '54481',
        '54482',
        '54484',
        '54485',
        '54486',
        '54487',
        '54488',
        '54489',
        '54490',
        '54491',
        '54493',
        '54494',
        '54495',
        '54498',
        '54499',
        '54501',
        '54511',
        '54512',
        '54513',
        '54514',
        '54515',
        '54517',
        '54519',
        '54520',
        '54521',
        '54524',
        '54525',
        '54526',
        '54527',
        '54529',
        '54530',
        '54531',
        '54534',
        '54536',
        '54537',
        '54538',
        '54539',
        '54540',
        '54541',
        '54542',
        '54545',
        '54546',
        '54547',
        '54548',
        '54550',
        '54552',
        '54554',
        '54555',
        '54556',
        '54557',
        '54558',
        '54559',
        '54560',
        '54561',
        '54562',
        '54563',
        '54564',
        '54565',
        '54566',
        '54568',
        '54601',
        '54603',
        '54610',
        '54611',
        '54612',
        '54613',
        '54614',
        '54615',
        '54616',
        '54618',
        '54619',
        '54621',
        '54622',
        '54623',
        '54624',
        '54625',
        '54626',
        '54627',
        '54628',
        '54629',
        '54630',
        '54631',
        '54632',
        '54634',
        '54635',
        '54636',
        '54637',
        '54638',
        '54639',
        '54641',
        '54642',
        '54643',
        '54644',
        '54645',
        '54646',
        '54648',
        '54650',
        '54651',
        '54652',
        '54653',
        '54654',
        '54655',
        '54656',
        '54657',
        '54658',
        '54659',
        '54660',
        '54661',
        '54664',
        '54665',
        '54666',
        '54667',
        '54669',
        '54670',
        '54701',
        '54703',
        '54720',
        '54721',
        '54722',
        '54723',
        '54724',
        '54725',
        '54726',
        '54727',
        '54728',
        '54729',
        '54730',
        '54731',
        '54732',
        '54733',
        '54734',
        '54736',
        '54737',
        '54738',
        '54739',
        '54740',
        '54741',
        '54742',
        '54745',
        '54746',
        '54747',
        '54748',
        '54749',
        '54750',
        '54751',
        '54754',
        '54755',
        '54756',
        '54757',
        '54758',
        '54759',
        '54760',
        '54761',
        '54762',
        '54763',
        '54765',
        '54766',
        '54767',
        '54768',
        '54769',
        '54770',
        '54771',
        '54772',
        '54773',
        '54801',
        '54805',
        '54806',
        '54810',
        '54812',
        '54813',
        '54814',
        '54817',
        '54819',
        '54820',
        '54821',
        '54822',
        '54824',
        '54826',
        '54827',
        '54828',
        '54829',
        '54830',
        '54832',
        '54835',
        '54836',
        '54837',
        '54838',
        '54839',
        '54840',
        '54841',
        '54842',
        '54843',
        '54844',
        '54845',
        '54846',
        '54847',
        '54848',
        '54849',
        '54850',
        '54853',
        '54854',
        '54855',
        '54856',
        '54857',
        '54858',
        '54859',
        '54861',
        '54862',
        '54864',
        '54865',
        '54867',
        '54868',
        '54870',
        '54871',
        '54872',
        '54873',
        '54874',
        '54875',
        '54876',
        '54880',
        '54888',
        '54889',
        '54891',
        '54893',
        '54895',
        '54896',
        '54901',
        '54902',
        '54904',
        '54909',
        '54911',
        '54913',
        '54914',
        '54915',
        '54921',
        '54922',
        '54923',
        '54927',
        '54928',
        '54929',
        '54930',
        '54931',
        '54932',
        '54933',
        '54935',
        '54937',
        '54940',
        '54941',
        '54942',
        '54943',
        '54944',
        '54945',
        '54946',
        '54947',
        '54948',
        '54949',
        '54950',
        '54952',
        '54956',
        '54960',
        '54961',
        '54962',
        '54963',
        '54964',
        '54965',
        '54966',
        '54967',
        '54968',
        '54970',
        '54971',
        '54974',
        '54977',
        '54979',
        '54980',
        '54981',
        '54982',
        '54983',
        '54984',
        '54985',
        '54986'
      ]
    },
    MN: {
      name: 'Minnesota',
      codes: [
        '55001',
        '55003',
        '55005',
        '55006',
        '55007',
        '55008',
        '55009',
        '55011',
        '55012',
        '55013',
        '55014',
        '55016',
        '55017',
        '55018',
        '55019',
        '55020',
        '55021',
        '55024',
        '55025',
        '55026',
        '55027',
        '55029',
        '55030',
        '55031',
        '55032',
        '55033',
        '55036',
        '55037',
        '55038',
        '55040',
        '55041',
        '55042',
        '55043',
        '55044',
        '55045',
        '55046',
        '55047',
        '55049',
        '55051',
        '55052',
        '55053',
        '55054',
        '55055',
        '55056',
        '55057',
        '55060',
        '55063',
        '55065',
        '55066',
        '55068',
        '55069',
        '55070',
        '55071',
        '55072',
        '55073',
        '55074',
        '55075',
        '55076',
        '55077',
        '55079',
        '55080',
        '55082',
        '55084',
        '55085',
        '55087',
        '55088',
        '55089',
        '55090',
        '55092',
        '55101',
        '55102',
        '55103',
        '55104',
        '55105',
        '55106',
        '55107',
        '55108',
        '55109',
        '55110',
        '55111',
        '55112',
        '55113',
        '55114',
        '55115',
        '55116',
        '55117',
        '55118',
        '55119',
        '55120',
        '55121',
        '55122',
        '55123',
        '55124',
        '55125',
        '55126',
        '55127',
        '55128',
        '55129',
        '55130',
        '55150',
        '55155',
        '55301',
        '55302',
        '55303',
        '55304',
        '55305',
        '55306',
        '55307',
        '55308',
        '55309',
        '55310',
        '55311',
        '55312',
        '55313',
        '55314',
        '55315',
        '55316',
        '55317',
        '55318',
        '55319',
        '55320',
        '55321',
        '55322',
        '55324',
        '55325',
        '55327',
        '55328',
        '55329',
        '55330',
        '55331',
        '55332',
        '55333',
        '55334',
        '55335',
        '55336',
        '55337',
        '55338',
        '55339',
        '55340',
        '55341',
        '55342',
        '55343',
        '55344',
        '55345',
        '55346',
        '55347',
        '55349',
        '55350',
        '55352',
        '55353',
        '55354',
        '55355',
        '55356',
        '55357',
        '55358',
        '55359',
        '55360',
        '55362',
        '55363',
        '55364',
        '55366',
        '55367',
        '55368',
        '55369',
        '55370',
        '55371',
        '55372',
        '55373',
        '55374',
        '55375',
        '55376',
        '55378',
        '55379',
        '55381',
        '55382',
        '55384',
        '55385',
        '55386',
        '55387',
        '55388',
        '55389',
        '55390',
        '55391',
        '55395',
        '55396',
        '55397',
        '55398',
        '55401',
        '55402',
        '55403',
        '55404',
        '55405',
        '55406',
        '55407',
        '55408',
        '55409',
        '55410',
        '55411',
        '55412',
        '55413',
        '55414',
        '55415',
        '55416',
        '55417',
        '55418',
        '55419',
        '55420',
        '55421',
        '55422',
        '55423',
        '55424',
        '55425',
        '55426',
        '55427',
        '55428',
        '55429',
        '55430',
        '55431',
        '55432',
        '55433',
        '55434',
        '55435',
        '55436',
        '55437',
        '55438',
        '55439',
        '55441',
        '55442',
        '55443',
        '55444',
        '55445',
        '55446',
        '55447',
        '55448',
        '55449',
        '55450',
        '55454',
        '55455',
        '55601',
        '55602',
        '55603',
        '55604',
        '55605',
        '55606',
        '55607',
        '55609',
        '55612',
        '55613',
        '55614',
        '55615',
        '55616',
        '55702',
        '55703',
        '55704',
        '55705',
        '55706',
        '55707',
        '55708',
        '55709',
        '55710',
        '55711',
        '55712',
        '55713',
        '55716',
        '55717',
        '55718',
        '55719',
        '55720',
        '55721',
        '55722',
        '55723',
        '55724',
        '55725',
        '55726',
        '55731',
        '55732',
        '55733',
        '55734',
        '55735',
        '55736',
        '55738',
        '55741',
        '55742',
        '55744',
        '55746',
        '55748',
        '55749',
        '55750',
        '55751',
        '55752',
        '55753',
        '55756',
        '55757',
        '55758',
        '55760',
        '55763',
        '55764',
        '55765',
        '55766',
        '55767',
        '55768',
        '55769',
        '55771',
        '55772',
        '55775',
        '55779',
        '55780',
        '55781',
        '55782',
        '55783',
        '55784',
        '55785',
        '55786',
        '55787',
        '55790',
        '55792',
        '55793',
        '55795',
        '55797',
        '55798',
        '55802',
        '55803',
        '55804',
        '55805',
        '55806',
        '55807',
        '55808',
        '55810',
        '55811',
        '55812',
        '55814',
        '55901',
        '55902',
        '55904',
        '55905',
        '55906',
        '55909',
        '55910',
        '55912',
        '55917',
        '55918',
        '55919',
        '55920',
        '55921',
        '55922',
        '55923',
        '55924',
        '55925',
        '55926',
        '55927',
        '55929',
        '55931',
        '55932',
        '55933',
        '55934',
        '55935',
        '55936',
        '55939',
        '55940',
        '55941',
        '55943',
        '55944',
        '55945',
        '55946',
        '55947',
        '55949',
        '55950',
        '55951',
        '55952',
        '55953',
        '55954',
        '55955',
        '55956',
        '55957',
        '55959',
        '55960',
        '55961',
        '55962',
        '55963',
        '55964',
        '55965',
        '55967',
        '55968',
        '55969',
        '55970',
        '55971',
        '55972',
        '55973',
        '55974',
        '55975',
        '55976',
        '55977',
        '55979',
        '55981',
        '55982',
        '55983',
        '55985',
        '55987',
        '55990',
        '55991',
        '55992',
        '56001',
        '56003',
        '56007',
        '56009',
        '56010',
        '56011',
        '56013',
        '56014',
        '56016',
        '56017',
        '56019',
        '56020',
        '56021',
        '56022',
        '56023',
        '56024',
        '56025',
        '56026',
        '56027',
        '56028',
        '56029',
        '56031',
        '56032',
        '56033',
        '56034',
        '56035',
        '56036',
        '56037',
        '56039',
        '56041',
        '56042',
        '56043',
        '56044',
        '56045',
        '56046',
        '56047',
        '56048',
        '56050',
        '56051',
        '56052',
        '56054',
        '56055',
        '56056',
        '56057',
        '56058',
        '56060',
        '56062',
        '56063',
        '56065',
        '56068',
        '56069',
        '56071',
        '56072',
        '56073',
        '56074',
        '56075',
        '56078',
        '56080',
        '56081',
        '56082',
        '56083',
        '56085',
        '56087',
        '56088',
        '56089',
        '56090',
        '56091',
        '56093',
        '56096',
        '56097',
        '56098',
        '56101',
        '56110',
        '56111',
        '56113',
        '56114',
        '56115',
        '56116',
        '56117',
        '56118',
        '56119',
        '56120',
        '56121',
        '56122',
        '56123',
        '56125',
        '56127',
        '56128',
        '56129',
        '56131',
        '56132',
        '56134',
        '56136',
        '56137',
        '56138',
        '56139',
        '56140',
        '56141',
        '56142',
        '56143',
        '56144',
        '56145',
        '56146',
        '56147',
        '56149',
        '56150',
        '56151',
        '56152',
        '56153',
        '56155',
        '56156',
        '56157',
        '56158',
        '56159',
        '56160',
        '56161',
        '56162',
        '56164',
        '56165',
        '56166',
        '56167',
        '56168',
        '56169',
        '56170',
        '56171',
        '56172',
        '56173',
        '56174',
        '56175',
        '56176',
        '56178',
        '56180',
        '56181',
        '56183',
        '56185',
        '56186',
        '56187',
        '56201',
        '56207',
        '56208',
        '56209',
        '56210',
        '56211',
        '56212',
        '56214',
        '56215',
        '56216',
        '56218',
        '56219',
        '56220',
        '56221',
        '56222',
        '56223',
        '56224',
        '56225',
        '56226',
        '56227',
        '56228',
        '56229',
        '56230',
        '56231',
        '56232',
        '56235',
        '56236',
        '56237',
        '56239',
        '56240',
        '56241',
        '56243',
        '56244',
        '56245',
        '56248',
        '56249',
        '56251',
        '56252',
        '56253',
        '56255',
        '56256',
        '56257',
        '56258',
        '56260',
        '56262',
        '56263',
        '56264',
        '56265',
        '56266',
        '56267',
        '56270',
        '56271',
        '56273',
        '56274',
        '56276',
        '56277',
        '56278',
        '56279',
        '56280',
        '56281',
        '56282',
        '56283',
        '56284',
        '56285',
        '56287',
        '56288',
        '56289',
        '56291',
        '56292',
        '56293',
        '56294',
        '56295',
        '56296',
        '56297',
        '56301',
        '56303',
        '56304',
        '56307',
        '56308',
        '56309',
        '56310',
        '56311',
        '56312',
        '56313',
        '56314',
        '56315',
        '56316',
        '56318',
        '56319',
        '56320',
        '56321',
        '56323',
        '56324',
        '56325',
        '56326',
        '56327',
        '56328',
        '56329',
        '56330',
        '56331',
        '56332',
        '56334',
        '56335',
        '56336',
        '56338',
        '56339',
        '56340',
        '56342',
        '56343',
        '56345',
        '56347',
        '56349',
        '56350',
        '56352',
        '56353',
        '56354',
        '56355',
        '56356',
        '56357',
        '56358',
        '56359',
        '56360',
        '56361',
        '56362',
        '56363',
        '56364',
        '56367',
        '56368',
        '56369',
        '56371',
        '56373',
        '56374',
        '56375',
        '56376',
        '56377',
        '56378',
        '56379',
        '56381',
        '56382',
        '56384',
        '56385',
        '56386',
        '56387',
        '56389',
        '56401',
        '56425',
        '56431',
        '56433',
        '56434',
        '56435',
        '56436',
        '56437',
        '56438',
        '56440',
        '56441',
        '56442',
        '56443',
        '56444',
        '56446',
        '56447',
        '56448',
        '56449',
        '56450',
        '56452',
        '56453',
        '56455',
        '56456',
        '56458',
        '56461',
        '56464',
        '56465',
        '56466',
        '56467',
        '56468',
        '56469',
        '56470',
        '56472',
        '56473',
        '56474',
        '56475',
        '56477',
        '56479',
        '56481',
        '56482',
        '56484',
        '56501',
        '56510',
        '56511',
        '56514',
        '56515',
        '56516',
        '56517',
        '56518',
        '56519',
        '56520',
        '56521',
        '56522',
        '56523',
        '56524',
        '56525',
        '56527',
        '56528',
        '56529',
        '56531',
        '56533',
        '56534',
        '56535',
        '56536',
        '56537',
        '56540',
        '56541',
        '56542',
        '56543',
        '56544',
        '56545',
        '56546',
        '56547',
        '56548',
        '56549',
        '56550',
        '56551',
        '56552',
        '56553',
        '56554',
        '56556',
        '56557',
        '56560',
        '56565',
        '56566',
        '56567',
        '56568',
        '56569',
        '56570',
        '56571',
        '56572',
        '56573',
        '56574',
        '56575',
        '56576',
        '56577',
        '56578',
        '56579',
        '56580',
        '56581',
        '56583',
        '56584',
        '56585',
        '56586',
        '56587',
        '56588',
        '56589',
        '56590',
        '56591',
        '56592',
        '56593',
        '56594',
        '56601',
        '56621',
        '56623',
        '56626',
        '56627',
        '56628',
        '56629',
        '56630',
        '56633',
        '56634',
        '56636',
        '56637',
        '56639',
        '56641',
        '56644',
        '56646',
        '56647',
        '56649',
        '56650',
        '56651',
        '56652',
        '56653',
        '56654',
        '56655',
        '56657',
        '56658',
        '56659',
        '56660',
        '56661',
        '56662',
        '56663',
        '56666',
        '56667',
        '56668',
        '56669',
        '56670',
        '56671',
        '56672',
        '56673',
        '56676',
        '56678',
        '56680',
        '56681',
        '56683',
        '56684',
        '56685',
        '56686',
        '56687',
        '56688',
        '56701',
        '56710',
        '56711',
        '56713',
        '56714',
        '56715',
        '56716',
        '56720',
        '56721',
        '56722',
        '56723',
        '56724',
        '56725',
        '56726',
        '56727',
        '56728',
        '56729',
        '56731',
        '56732',
        '56733',
        '56734',
        '56735',
        '56736',
        '56737',
        '56738',
        '56741',
        '56742',
        '56744',
        '56748',
        '56750',
        '56751',
        '56754',
        '56755',
        '56756',
        '56757',
        '56758',
        '56759',
        '56760',
        '56761',
        '56762',
        '56763'
      ]
    },
    SD: {
      name: 'South Dakota',
      codes: [
        '57001',
        '57002',
        '57003',
        '57004',
        '57005',
        '57006',
        '57010',
        '57012',
        '57013',
        '57014',
        '57015',
        '57016',
        '57017',
        '57018',
        '57020',
        '57021',
        '57022',
        '57024',
        '57025',
        '57026',
        '57027',
        '57028',
        '57029',
        '57030',
        '57031',
        '57032',
        '57033',
        '57034',
        '57035',
        '57036',
        '57037',
        '57038',
        '57039',
        '57040',
        '57041',
        '57042',
        '57043',
        '57045',
        '57046',
        '57047',
        '57048',
        '57049',
        '57050',
        '57051',
        '57052',
        '57053',
        '57054',
        '57055',
        '57057',
        '57058',
        '57059',
        '57061',
        '57062',
        '57063',
        '57064',
        '57065',
        '57066',
        '57067',
        '57068',
        '57069',
        '57070',
        '57071',
        '57072',
        '57073',
        '57075',
        '57076',
        '57077',
        '57078',
        '57103',
        '57104',
        '57105',
        '57106',
        '57107',
        '57108',
        '57110',
        '57117',
        '57197',
        '57201',
        '57212',
        '57213',
        '57214',
        '57216',
        '57217',
        '57218',
        '57219',
        '57220',
        '57221',
        '57223',
        '57224',
        '57225',
        '57226',
        '57227',
        '57231',
        '57232',
        '57233',
        '57234',
        '57235',
        '57236',
        '57237',
        '57238',
        '57239',
        '57241',
        '57242',
        '57243',
        '57245',
        '57246',
        '57247',
        '57248',
        '57249',
        '57251',
        '57252',
        '57255',
        '57256',
        '57257',
        '57258',
        '57259',
        '57260',
        '57261',
        '57262',
        '57263',
        '57264',
        '57265',
        '57266',
        '57268',
        '57269',
        '57270',
        '57271',
        '57272',
        '57273',
        '57274',
        '57276',
        '57278',
        '57279',
        '57301',
        '57311',
        '57312',
        '57313',
        '57314',
        '57315',
        '57317',
        '57319',
        '57321',
        '57322',
        '57323',
        '57324',
        '57325',
        '57328',
        '57329',
        '57330',
        '57331',
        '57332',
        '57334',
        '57335',
        '57337',
        '57339',
        '57340',
        '57341',
        '57342',
        '57344',
        '57345',
        '57346',
        '57348',
        '57349',
        '57350',
        '57353',
        '57355',
        '57356',
        '57358',
        '57359',
        '57361',
        '57362',
        '57363',
        '57364',
        '57365',
        '57366',
        '57367',
        '57368',
        '57369',
        '57370',
        '57371',
        '57373',
        '57374',
        '57375',
        '57376',
        '57379',
        '57380',
        '57381',
        '57382',
        '57383',
        '57384',
        '57385',
        '57386',
        '57401',
        '57420',
        '57421',
        '57422',
        '57424',
        '57426',
        '57427',
        '57428',
        '57429',
        '57430',
        '57432',
        '57433',
        '57434',
        '57435',
        '57436',
        '57437',
        '57438',
        '57439',
        '57440',
        '57441',
        '57442',
        '57445',
        '57446',
        '57448',
        '57449',
        '57450',
        '57451',
        '57452',
        '57454',
        '57455',
        '57456',
        '57457',
        '57460',
        '57461',
        '57465',
        '57466',
        '57467',
        '57468',
        '57469',
        '57470',
        '57471',
        '57472',
        '57473',
        '57474',
        '57475',
        '57476',
        '57477',
        '57479',
        '57481',
        '57501',
        '57520',
        '57521',
        '57522',
        '57523',
        '57528',
        '57529',
        '57531',
        '57532',
        '57533',
        '57534',
        '57536',
        '57537',
        '57538',
        '57540',
        '57541',
        '57543',
        '57544',
        '57547',
        '57548',
        '57551',
        '57552',
        '57553',
        '57555',
        '57559',
        '57560',
        '57562',
        '57563',
        '57564',
        '57566',
        '57567',
        '57568',
        '57569',
        '57570',
        '57571',
        '57572',
        '57574',
        '57576',
        '57577',
        '57579',
        '57580',
        '57584',
        '57585',
        '57601',
        '57620',
        '57621',
        '57622',
        '57623',
        '57625',
        '57626',
        '57630',
        '57631',
        '57632',
        '57633',
        '57634',
        '57636',
        '57638',
        '57639',
        '57640',
        '57641',
        '57642',
        '57644',
        '57645',
        '57646',
        '57648',
        '57649',
        '57650',
        '57651',
        '57652',
        '57656',
        '57657',
        '57658',
        '57660',
        '57661',
        '57701',
        '57702',
        '57703',
        '57706',
        '57714',
        '57716',
        '57717',
        '57718',
        '57719',
        '57720',
        '57722',
        '57724',
        '57725',
        '57730',
        '57732',
        '57735',
        '57738',
        '57741',
        '57744',
        '57745',
        '57747',
        '57748',
        '57750',
        '57751',
        '57752',
        '57754',
        '57755',
        '57756',
        '57758',
        '57759',
        '57760',
        '57761',
        '57762',
        '57763',
        '57764',
        '57766',
        '57767',
        '57769',
        '57770',
        '57772',
        '57773',
        '57775',
        '57776',
        '57779',
        '57780',
        '57782',
        '57783',
        '57785',
        '57787',
        '57788',
        '57790',
        '57791',
        '57792',
        '57793',
        '57794',
        '57799'
      ]
    },
    ND: {
      name: 'North Dakota',
      codes: [
        '58001',
        '58002',
        '58004',
        '58005',
        '58006',
        '58007',
        '58008',
        '58009',
        '58011',
        '58012',
        '58013',
        '58015',
        '58016',
        '58017',
        '58018',
        '58021',
        '58027',
        '58029',
        '58030',
        '58031',
        '58032',
        '58033',
        '58035',
        '58036',
        '58038',
        '58040',
        '58041',
        '58042',
        '58043',
        '58045',
        '58046',
        '58047',
        '58048',
        '58049',
        '58051',
        '58052',
        '58053',
        '58054',
        '58056',
        '58057',
        '58058',
        '58059',
        '58060',
        '58061',
        '58062',
        '58063',
        '58064',
        '58065',
        '58067',
        '58068',
        '58069',
        '58071',
        '58072',
        '58075',
        '58076',
        '58077',
        '58078',
        '58079',
        '58081',
        '58102',
        '58103',
        '58104',
        '58105',
        '58201',
        '58202',
        '58203',
        '58204',
        '58205',
        '58210',
        '58212',
        '58214',
        '58216',
        '58218',
        '58219',
        '58220',
        '58222',
        '58223',
        '58224',
        '58225',
        '58227',
        '58228',
        '58229',
        '58230',
        '58231',
        '58233',
        '58235',
        '58237',
        '58238',
        '58239',
        '58240',
        '58241',
        '58243',
        '58244',
        '58249',
        '58250',
        '58251',
        '58254',
        '58256',
        '58257',
        '58258',
        '58259',
        '58260',
        '58261',
        '58262',
        '58265',
        '58266',
        '58267',
        '58269',
        '58270',
        '58271',
        '58272',
        '58273',
        '58274',
        '58275',
        '58276',
        '58277',
        '58278',
        '58281',
        '58282',
        '58301',
        '58311',
        '58316',
        '58317',
        '58318',
        '58321',
        '58323',
        '58324',
        '58325',
        '58327',
        '58329',
        '58330',
        '58331',
        '58332',
        '58335',
        '58338',
        '58339',
        '58341',
        '58343',
        '58344',
        '58345',
        '58346',
        '58348',
        '58351',
        '58352',
        '58353',
        '58355',
        '58356',
        '58357',
        '58361',
        '58362',
        '58363',
        '58365',
        '58366',
        '58367',
        '58368',
        '58369',
        '58370',
        '58372',
        '58374',
        '58377',
        '58379',
        '58380',
        '58381',
        '58382',
        '58384',
        '58385',
        '58386',
        '58401',
        '58402',
        '58405',
        '58413',
        '58415',
        '58416',
        '58418',
        '58420',
        '58421',
        '58422',
        '58423',
        '58424',
        '58425',
        '58426',
        '58428',
        '58429',
        '58430',
        '58431',
        '58433',
        '58436',
        '58438',
        '58439',
        '58440',
        '58441',
        '58442',
        '58443',
        '58444',
        '58445',
        '58448',
        '58451',
        '58452',
        '58454',
        '58455',
        '58456',
        '58458',
        '58460',
        '58461',
        '58463',
        '58464',
        '58466',
        '58467',
        '58472',
        '58474',
        '58475',
        '58476',
        '58477',
        '58478',
        '58479',
        '58480',
        '58481',
        '58482',
        '58483',
        '58484',
        '58486',
        '58487',
        '58488',
        '58490',
        '58492',
        '58494',
        '58495',
        '58496',
        '58497',
        '58501',
        '58503',
        '58504',
        '58505',
        '58520',
        '58521',
        '58523',
        '58524',
        '58528',
        '58529',
        '58530',
        '58531',
        '58532',
        '58533',
        '58535',
        '58538',
        '58540',
        '58541',
        '58542',
        '58544',
        '58545',
        '58549',
        '58552',
        '58554',
        '58558',
        '58559',
        '58560',
        '58561',
        '58562',
        '58563',
        '58564',
        '58565',
        '58566',
        '58568',
        '58569',
        '58570',
        '58571',
        '58572',
        '58573',
        '58575',
        '58576',
        '58577',
        '58579',
        '58580',
        '58581',
        '58601',
        '58620',
        '58621',
        '58622',
        '58623',
        '58625',
        '58626',
        '58627',
        '58630',
        '58631',
        '58632',
        '58634',
        '58636',
        '58638',
        '58639',
        '58640',
        '58641',
        '58642',
        '58643',
        '58645',
        '58646',
        '58647',
        '58649',
        '58650',
        '58651',
        '58652',
        '58653',
        '58654',
        '58655',
        '58656',
        '58701',
        '58702',
        '58703',
        '58704',
        '58705',
        '58707',
        '58710',
        '58711',
        '58712',
        '58713',
        '58716',
        '58718',
        '58721',
        '58722',
        '58723',
        '58725',
        '58727',
        '58730',
        '58731',
        '58733',
        '58734',
        '58735',
        '58736',
        '58737',
        '58740',
        '58741',
        '58744',
        '58746',
        '58748',
        '58750',
        '58752',
        '58755',
        '58756',
        '58757',
        '58758',
        '58759',
        '58760',
        '58761',
        '58762',
        '58763',
        '58765',
        '58768',
        '58769',
        '58770',
        '58771',
        '58772',
        '58773',
        '58775',
        '58776',
        '58778',
        '58779',
        '58781',
        '58782',
        '58783',
        '58784',
        '58785',
        '58787',
        '58788',
        '58789',
        '58790',
        '58792',
        '58793',
        '58794',
        '58795',
        '58801',
        '58830',
        '58831',
        '58833',
        '58835',
        '58838',
        '58843',
        '58844',
        '58845',
        '58847',
        '58849',
        '58852',
        '58853',
        '58854',
        '58856'
      ]
    },
    MT: {
      name: 'Montana',
      codes: [
        '59001',
        '59002',
        '59003',
        '59006',
        '59007',
        '59008',
        '59010',
        '59011',
        '59012',
        '59013',
        '59014',
        '59015',
        '59016',
        '59018',
        '59019',
        '59020',
        '59022',
        '59024',
        '59025',
        '59026',
        '59027',
        '59028',
        '59029',
        '59030',
        '59031',
        '59032',
        '59033',
        '59034',
        '59035',
        '59036',
        '59037',
        '59038',
        '59039',
        '59041',
        '59043',
        '59044',
        '59046',
        '59047',
        '59050',
        '59052',
        '59053',
        '59054',
        '59055',
        '59057',
        '59058',
        '59059',
        '59061',
        '59062',
        '59063',
        '59064',
        '59065',
        '59066',
        '59067',
        '59068',
        '59069',
        '59070',
        '59071',
        '59072',
        '59074',
        '59075',
        '59076',
        '59077',
        '59078',
        '59079',
        '59081',
        '59082',
        '59085',
        '59086',
        '59087',
        '59088',
        '59089',
        '59101',
        '59102',
        '59105',
        '59106',
        '59201',
        '59211',
        '59212',
        '59213',
        '59214',
        '59215',
        '59217',
        '59218',
        '59219',
        '59221',
        '59222',
        '59223',
        '59225',
        '59226',
        '59230',
        '59231',
        '59240',
        '59241',
        '59242',
        '59243',
        '59244',
        '59247',
        '59248',
        '59250',
        '59252',
        '59253',
        '59254',
        '59255',
        '59256',
        '59257',
        '59258',
        '59259',
        '59260',
        '59261',
        '59262',
        '59263',
        '59270',
        '59274',
        '59275',
        '59276',
        '59301',
        '59311',
        '59312',
        '59313',
        '59314',
        '59315',
        '59317',
        '59318',
        '59322',
        '59323',
        '59324',
        '59326',
        '59327',
        '59330',
        '59332',
        '59333',
        '59336',
        '59337',
        '59338',
        '59339',
        '59343',
        '59344',
        '59347',
        '59349',
        '59351',
        '59353',
        '59354',
        '59401',
        '59404',
        '59405',
        '59410',
        '59411',
        '59412',
        '59414',
        '59416',
        '59417',
        '59418',
        '59419',
        '59420',
        '59421',
        '59422',
        '59424',
        '59425',
        '59427',
        '59430',
        '59432',
        '59433',
        '59434',
        '59436',
        '59440',
        '59441',
        '59442',
        '59443',
        '59444',
        '59446',
        '59447',
        '59448',
        '59450',
        '59451',
        '59452',
        '59453',
        '59454',
        '59456',
        '59457',
        '59460',
        '59461',
        '59462',
        '59463',
        '59464',
        '59465',
        '59466',
        '59467',
        '59468',
        '59469',
        '59471',
        '59472',
        '59474',
        '59477',
        '59479',
        '59480',
        '59482',
        '59483',
        '59484',
        '59485',
        '59486',
        '59487',
        '59489',
        '59501',
        '59520',
        '59521',
        '59522',
        '59523',
        '59524',
        '59525',
        '59526',
        '59527',
        '59528',
        '59529',
        '59530',
        '59531',
        '59532',
        '59535',
        '59537',
        '59538',
        '59540',
        '59542',
        '59544',
        '59545',
        '59546',
        '59547',
        '59601',
        '59602',
        '59631',
        '59632',
        '59633',
        '59634',
        '59635',
        '59636',
        '59638',
        '59639',
        '59640',
        '59642',
        '59643',
        '59644',
        '59645',
        '59647',
        '59648',
        '59701',
        '59703',
        '59710',
        '59711',
        '59713',
        '59714',
        '59715',
        '59716',
        '59718',
        '59720',
        '59721',
        '59722',
        '59724',
        '59725',
        '59727',
        '59728',
        '59729',
        '59730',
        '59731',
        '59732',
        '59733',
        '59735',
        '59736',
        '59739',
        '59740',
        '59741',
        '59743',
        '59745',
        '59746',
        '59747',
        '59748',
        '59749',
        '59750',
        '59751',
        '59752',
        '59754',
        '59755',
        '59756',
        '59758',
        '59759',
        '59760',
        '59761',
        '59762',
        '59801',
        '59802',
        '59803',
        '59804',
        '59808',
        '59820',
        '59821',
        '59823',
        '59824',
        '59825',
        '59826',
        '59827',
        '59828',
        '59829',
        '59830',
        '59831',
        '59832',
        '59833',
        '59834',
        '59837',
        '59840',
        '59841',
        '59842',
        '59843',
        '59844',
        '59845',
        '59846',
        '59847',
        '59848',
        '59851',
        '59853',
        '59854',
        '59855',
        '59856',
        '59858',
        '59859',
        '59860',
        '59863',
        '59864',
        '59865',
        '59866',
        '59867',
        '59868',
        '59870',
        '59871',
        '59872',
        '59873',
        '59874',
        '59875',
        '59901',
        '59910',
        '59911',
        '59912',
        '59913',
        '59914',
        '59915',
        '59916',
        '59917',
        '59918',
        '59919',
        '59920',
        '59922',
        '59923',
        '59925',
        '59926',
        '59927',
        '59928',
        '59929',
        '59930',
        '59931',
        '59932',
        '59933',
        '59934',
        '59935',
        '59936',
        '59937'
      ]
    },
    IL: {
      name: 'Illinois',
      codes: [
        '60002',
        '60004',
        '60005',
        '60007',
        '60008',
        '60010',
        '60012',
        '60013',
        '60014',
        '60015',
        '60016',
        '60018',
        '60020',
        '60021',
        '60022',
        '60025',
        '60026',
        '60029',
        '60030',
        '60031',
        '60033',
        '60034',
        '60035',
        '60040',
        '60041',
        '60042',
        '60043',
        '60044',
        '60045',
        '60046',
        '60047',
        '60048',
        '60050',
        '60051',
        '60053',
        '60056',
        '60060',
        '60061',
        '60062',
        '60064',
        '60067',
        '60068',
        '60069',
        '60070',
        '60071',
        '60072',
        '60073',
        '60074',
        '60076',
        '60077',
        '60081',
        '60083',
        '60084',
        '60085',
        '60087',
        '60088',
        '60089',
        '60090',
        '60091',
        '60093',
        '60096',
        '60097',
        '60098',
        '60099',
        '60101',
        '60102',
        '60103',
        '60104',
        '60106',
        '60107',
        '60108',
        '60109',
        '60110',
        '60111',
        '60112',
        '60113',
        '60115',
        '60118',
        '60119',
        '60120',
        '60123',
        '60124',
        '60126',
        '60129',
        '60130',
        '60131',
        '60133',
        '60134',
        '60135',
        '60136',
        '60137',
        '60139',
        '60140',
        '60141',
        '60142',
        '60143',
        '60144',
        '60145',
        '60146',
        '60148',
        '60150',
        '60151',
        '60152',
        '60153',
        '60154',
        '60155',
        '60156',
        '60157',
        '60160',
        '60162',
        '60163',
        '60164',
        '60165',
        '60169',
        '60171',
        '60172',
        '60173',
        '60174',
        '60175',
        '60176',
        '60177',
        '60178',
        '60180',
        '60181',
        '60184',
        '60185',
        '60187',
        '60188',
        '60189',
        '60190',
        '60191',
        '60192',
        '60193',
        '60194',
        '60195',
        '60201',
        '60202',
        '60203',
        '60301',
        '60302',
        '60304',
        '60305',
        '60401',
        '60402',
        '60403',
        '60404',
        '60406',
        '60407',
        '60408',
        '60409',
        '60410',
        '60411',
        '60415',
        '60416',
        '60417',
        '60419',
        '60420',
        '60421',
        '60422',
        '60423',
        '60424',
        '60425',
        '60426',
        '60428',
        '60429',
        '60430',
        '60431',
        '60432',
        '60433',
        '60435',
        '60436',
        '60437',
        '60438',
        '60439',
        '60440',
        '60441',
        '60442',
        '60443',
        '60444',
        '60445',
        '60446',
        '60447',
        '60448',
        '60449',
        '60450',
        '60451',
        '60452',
        '60453',
        '60455',
        '60456',
        '60457',
        '60458',
        '60459',
        '60460',
        '60461',
        '60462',
        '60463',
        '60464',
        '60465',
        '60466',
        '60467',
        '60468',
        '60469',
        '60470',
        '60471',
        '60472',
        '60473',
        '60474',
        '60475',
        '60476',
        '60477',
        '60478',
        '60479',
        '60480',
        '60481',
        '60482',
        '60484',
        '60487',
        '60490',
        '60491',
        '60501',
        '60502',
        '60503',
        '60504',
        '60505',
        '60506',
        '60510',
        '60511',
        '60512',
        '60513',
        '60514',
        '60515',
        '60516',
        '60517',
        '60518',
        '60519',
        '60520',
        '60521',
        '60523',
        '60525',
        '60526',
        '60527',
        '60530',
        '60531',
        '60532',
        '60534',
        '60536',
        '60537',
        '60538',
        '60539',
        '60540',
        '60541',
        '60542',
        '60543',
        '60544',
        '60545',
        '60546',
        '60548',
        '60549',
        '60550',
        '60551',
        '60552',
        '60553',
        '60554',
        '60555',
        '60556',
        '60557',
        '60558',
        '60559',
        '60560',
        '60561',
        '60563',
        '60564',
        '60565',
        '60585',
        '60586',
        '60601',
        '60602',
        '60603',
        '60604',
        '60605',
        '60606',
        '60607',
        '60608',
        '60609',
        '60610',
        '60611',
        '60612',
        '60613',
        '60614',
        '60615',
        '60616',
        '60617',
        '60618',
        '60619',
        '60620',
        '60621',
        '60622',
        '60623',
        '60624',
        '60625',
        '60626',
        '60628',
        '60629',
        '60630',
        '60631',
        '60632',
        '60633',
        '60634',
        '60636',
        '60637',
        '60638',
        '60639',
        '60640',
        '60641',
        '60642',
        '60643',
        '60644',
        '60645',
        '60646',
        '60647',
        '60649',
        '60651',
        '60652',
        '60653',
        '60654',
        '60655',
        '60656',
        '60657',
        '60659',
        '60660',
        '60661',
        '60706',
        '60707',
        '60712',
        '60714',
        '60803',
        '60804',
        '60805',
        '60827',
        '60901',
        '60910',
        '60911',
        '60912',
        '60913',
        '60914',
        '60915',
        '60917',
        '60918',
        '60919',
        '60920',
        '60921',
        '60922',
        '60924',
        '60926',
        '60927',
        '60928',
        '60929',
        '60930',
        '60931',
        '60932',
        '60933',
        '60934',
        '60935',
        '60936',
        '60938',
        '60940',
        '60941',
        '60942',
        '60945',
        '60946',
        '60948',
        '60949',
        '60950',
        '60951',
        '60952',
        '60953',
        '60954',
        '60955',
        '60957',
        '60958',
        '60959',
        '60960',
        '60961',
        '60962',
        '60963',
        '60964',
        '60966',
        '60968',
        '60969',
        '60970',
        '60973',
        '60974',
        '61001',
        '61006',
        '61007',
        '61008',
        '61010',
        '61011',
        '61012',
        '61013',
        '61014',
        '61015',
        '61016',
        '61018',
        '61019',
        '61020',
        '61021',
        '61024',
        '61025',
        '61027',
        '61028',
        '61030',
        '61031',
        '61032',
        '61036',
        '61037',
        '61038',
        '61039',
        '61041',
        '61042',
        '61043',
        '61044',
        '61046',
        '61047',
        '61048',
        '61049',
        '61050',
        '61051',
        '61052',
        '61053',
        '61054',
        '61057',
        '61059',
        '61060',
        '61061',
        '61062',
        '61063',
        '61064',
        '61065',
        '61067',
        '61068',
        '61070',
        '61071',
        '61072',
        '61073',
        '61074',
        '61075',
        '61077',
        '61078',
        '61079',
        '61080',
        '61081',
        '61084',
        '61085',
        '61087',
        '61088',
        '61089',
        '61091',
        '61101',
        '61102',
        '61103',
        '61104',
        '61107',
        '61108',
        '61109',
        '61111',
        '61112',
        '61114',
        '61115',
        '61201',
        '61230',
        '61231',
        '61232',
        '61234',
        '61235',
        '61236',
        '61238',
        '61239',
        '61240',
        '61241',
        '61242',
        '61243',
        '61244',
        '61250',
        '61251',
        '61252',
        '61254',
        '61256',
        '61257',
        '61258',
        '61259',
        '61260',
        '61261',
        '61262',
        '61263',
        '61264',
        '61265',
        '61270',
        '61272',
        '61273',
        '61274',
        '61275',
        '61276',
        '61277',
        '61278',
        '61279',
        '61281',
        '61282',
        '61283',
        '61284',
        '61285',
        '61301',
        '61310',
        '61311',
        '61312',
        '61313',
        '61314',
        '61315',
        '61316',
        '61317',
        '61318',
        '61319',
        '61320',
        '61321',
        '61322',
        '61323',
        '61324',
        '61325',
        '61326',
        '61327',
        '61328',
        '61329',
        '61330',
        '61331',
        '61332',
        '61333',
        '61334',
        '61335',
        '61336',
        '61337',
        '61338',
        '61340',
        '61341',
        '61342',
        '61344',
        '61345',
        '61346',
        '61348',
        '61349',
        '61350',
        '61353',
        '61354',
        '61356',
        '61358',
        '61359',
        '61360',
        '61361',
        '61362',
        '61363',
        '61364',
        '61367',
        '61368',
        '61369',
        '61370',
        '61372',
        '61373',
        '61374',
        '61375',
        '61376',
        '61377',
        '61378',
        '61379',
        '61401',
        '61410',
        '61411',
        '61412',
        '61413',
        '61414',
        '61415',
        '61416',
        '61417',
        '61418',
        '61419',
        '61420',
        '61421',
        '61422',
        '61423',
        '61424',
        '61425',
        '61426',
        '61427',
        '61428',
        '61430',
        '61431',
        '61432',
        '61433',
        '61434',
        '61435',
        '61436',
        '61437',
        '61438',
        '61439',
        '61440',
        '61441',
        '61442',
        '61443',
        '61447',
        '61448',
        '61449',
        '61450',
        '61451',
        '61452',
        '61453',
        '61454',
        '61455',
        '61458',
        '61459',
        '61460',
        '61462',
        '61465',
        '61466',
        '61467',
        '61468',
        '61469',
        '61470',
        '61471',
        '61472',
        '61473',
        '61474',
        '61475',
        '61476',
        '61477',
        '61478',
        '61479',
        '61480',
        '61482',
        '61483',
        '61484',
        '61485',
        '61486',
        '61488',
        '61489',
        '61490',
        '61491',
        '61501',
        '61516',
        '61517',
        '61519',
        '61520',
        '61523',
        '61524',
        '61525',
        '61526',
        '61528',
        '61529',
        '61530',
        '61531',
        '61532',
        '61533',
        '61534',
        '61535',
        '61536',
        '61537',
        '61539',
        '61540',
        '61541',
        '61542',
        '61543',
        '61544',
        '61545',
        '61546',
        '61547',
        '61548',
        '61550',
        '61552',
        '61553',
        '61554',
        '61559',
        '61560',
        '61561',
        '61562',
        '61563',
        '61564',
        '61565',
        '61567',
        '61568',
        '61569',
        '61570',
        '61571',
        '61572',
        '61602',
        '61603',
        '61604',
        '61605',
        '61606',
        '61607',
        '61610',
        '61611',
        '61614',
        '61615',
        '61616',
        '61625',
        '61701',
        '61704',
        '61705',
        '61720',
        '61721',
        '61722',
        '61723',
        '61724',
        '61725',
        '61726',
        '61727',
        '61728',
        '61729',
        '61730',
        '61731',
        '61732',
        '61733',
        '61734',
        '61735',
        '61736',
        '61737',
        '61738',
        '61739',
        '61740',
        '61741',
        '61742',
        '61743',
        '61744',
        '61745',
        '61747',
        '61748',
        '61749',
        '61750',
        '61751',
        '61752',
        '61753',
        '61754',
        '61755',
        '61756',
        '61759',
        '61760',
        '61761',
        '61764',
        '61769',
        '61770',
        '61771',
        '61772',
        '61773',
        '61774',
        '61775',
        '61776',
        '61777',
        '61778',
        '61801',
        '61802',
        '61810',
        '61811',
        '61812',
        '61813',
        '61814',
        '61815',
        '61816',
        '61817',
        '61818',
        '61820',
        '61821',
        '61822',
        '61830',
        '61831',
        '61832',
        '61833',
        '61834',
        '61839',
        '61840',
        '61841',
        '61842',
        '61843',
        '61844',
        '61845',
        '61846',
        '61847',
        '61848',
        '61849',
        '61850',
        '61851',
        '61852',
        '61853',
        '61854',
        '61855',
        '61856',
        '61857',
        '61858',
        '61859',
        '61862',
        '61863',
        '61864',
        '61865',
        '61866',
        '61870',
        '61871',
        '61872',
        '61873',
        '61874',
        '61875',
        '61876',
        '61877',
        '61878',
        '61880',
        '61882',
        '61883',
        '61884',
        '61910',
        '61911',
        '61912',
        '61913',
        '61914',
        '61917',
        '61919',
        '61920',
        '61924',
        '61925',
        '61928',
        '61929',
        '61930',
        '61931',
        '61932',
        '61933',
        '61936',
        '61937',
        '61938',
        '61940',
        '61941',
        '61942',
        '61943',
        '61944',
        '61949',
        '61951',
        '61953',
        '61955',
        '61956',
        '61957',
        '62001',
        '62002',
        '62006',
        '62009',
        '62010',
        '62011',
        '62012',
        '62013',
        '62014',
        '62015',
        '62016',
        '62017',
        '62018',
        '62019',
        '62021',
        '62022',
        '62023',
        '62024',
        '62025',
        '62027',
        '62028',
        '62030',
        '62031',
        '62032',
        '62033',
        '62034',
        '62035',
        '62036',
        '62037',
        '62040',
        '62044',
        '62045',
        '62046',
        '62047',
        '62048',
        '62049',
        '62050',
        '62051',
        '62052',
        '62053',
        '62054',
        '62056',
        '62058',
        '62059',
        '62060',
        '62061',
        '62062',
        '62063',
        '62065',
        '62067',
        '62069',
        '62070',
        '62074',
        '62075',
        '62076',
        '62077',
        '62078',
        '62079',
        '62080',
        '62081',
        '62082',
        '62083',
        '62084',
        '62085',
        '62086',
        '62087',
        '62088',
        '62089',
        '62090',
        '62091',
        '62092',
        '62093',
        '62094',
        '62095',
        '62097',
        '62098',
        '62201',
        '62203',
        '62204',
        '62205',
        '62206',
        '62207',
        '62208',
        '62214',
        '62215',
        '62216',
        '62217',
        '62218',
        '62219',
        '62220',
        '62221',
        '62223',
        '62225',
        '62226',
        '62230',
        '62231',
        '62232',
        '62233',
        '62234',
        '62236',
        '62237',
        '62238',
        '62239',
        '62240',
        '62241',
        '62242',
        '62243',
        '62244',
        '62245',
        '62246',
        '62248',
        '62249',
        '62250',
        '62253',
        '62254',
        '62255',
        '62257',
        '62258',
        '62260',
        '62261',
        '62262',
        '62263',
        '62264',
        '62265',
        '62266',
        '62268',
        '62269',
        '62271',
        '62272',
        '62273',
        '62274',
        '62275',
        '62277',
        '62278',
        '62279',
        '62280',
        '62281',
        '62282',
        '62284',
        '62285',
        '62286',
        '62288',
        '62289',
        '62292',
        '62293',
        '62294',
        '62295',
        '62297',
        '62298',
        '62301',
        '62305',
        '62311',
        '62312',
        '62313',
        '62314',
        '62316',
        '62319',
        '62320',
        '62321',
        '62323',
        '62324',
        '62325',
        '62326',
        '62330',
        '62334',
        '62336',
        '62338',
        '62339',
        '62340',
        '62341',
        '62343',
        '62344',
        '62345',
        '62346',
        '62347',
        '62348',
        '62349',
        '62351',
        '62352',
        '62353',
        '62354',
        '62355',
        '62356',
        '62357',
        '62358',
        '62359',
        '62360',
        '62361',
        '62362',
        '62363',
        '62365',
        '62366',
        '62367',
        '62370',
        '62373',
        '62374',
        '62375',
        '62376',
        '62378',
        '62379',
        '62380',
        '62401',
        '62410',
        '62411',
        '62413',
        '62414',
        '62417',
        '62418',
        '62419',
        '62420',
        '62421',
        '62422',
        '62423',
        '62424',
        '62425',
        '62426',
        '62427',
        '62428',
        '62431',
        '62432',
        '62433',
        '62434',
        '62436',
        '62438',
        '62439',
        '62440',
        '62441',
        '62442',
        '62443',
        '62444',
        '62445',
        '62446',
        '62447',
        '62448',
        '62449',
        '62450',
        '62451',
        '62452',
        '62454',
        '62458',
        '62459',
        '62460',
        '62461',
        '62462',
        '62463',
        '62465',
        '62466',
        '62467',
        '62468',
        '62469',
        '62471',
        '62473',
        '62474',
        '62475',
        '62476',
        '62477',
        '62478',
        '62479',
        '62480',
        '62481',
        '62501',
        '62510',
        '62512',
        '62513',
        '62514',
        '62515',
        '62517',
        '62518',
        '62519',
        '62520',
        '62521',
        '62522',
        '62523',
        '62526',
        '62530',
        '62531',
        '62532',
        '62533',
        '62534',
        '62535',
        '62536',
        '62537',
        '62538',
        '62539',
        '62540',
        '62541',
        '62543',
        '62544',
        '62545',
        '62546',
        '62547',
        '62548',
        '62549',
        '62550',
        '62551',
        '62553',
        '62554',
        '62555',
        '62556',
        '62557',
        '62558',
        '62560',
        '62561',
        '62563',
        '62565',
        '62567',
        '62568',
        '62570',
        '62571',
        '62572',
        '62573',
        '62601',
        '62610',
        '62611',
        '62612',
        '62613',
        '62615',
        '62617',
        '62618',
        '62621',
        '62622',
        '62624',
        '62625',
        '62626',
        '62627',
        '62628',
        '62629',
        '62630',
        '62631',
        '62633',
        '62634',
        '62635',
        '62638',
        '62639',
        '62640',
        '62642',
        '62643',
        '62644',
        '62649',
        '62650',
        '62655',
        '62656',
        '62661',
        '62663',
        '62664',
        '62665',
        '62666',
        '62667',
        '62668',
        '62670',
        '62671',
        '62672',
        '62673',
        '62674',
        '62675',
        '62677',
        '62681',
        '62682',
        '62684',
        '62685',
        '62688',
        '62689',
        '62690',
        '62691',
        '62692',
        '62693',
        '62694',
        '62695',
        '62701',
        '62702',
        '62703',
        '62704',
        '62707',
        '62711',
        '62712',
        '62801',
        '62803',
        '62806',
        '62807',
        '62808',
        '62809',
        '62810',
        '62811',
        '62812',
        '62814',
        '62815',
        '62816',
        '62817',
        '62818',
        '62819',
        '62820',
        '62821',
        '62822',
        '62823',
        '62824',
        '62825',
        '62827',
        '62828',
        '62829',
        '62830',
        '62831',
        '62832',
        '62833',
        '62835',
        '62836',
        '62837',
        '62838',
        '62839',
        '62841',
        '62842',
        '62843',
        '62844',
        '62846',
        '62848',
        '62849',
        '62850',
        '62851',
        '62852',
        '62853',
        '62854',
        '62856',
        '62858',
        '62859',
        '62860',
        '62861',
        '62862',
        '62863',
        '62864',
        '62865',
        '62867',
        '62868',
        '62869',
        '62870',
        '62871',
        '62872',
        '62874',
        '62875',
        '62876',
        '62877',
        '62878',
        '62879',
        '62880',
        '62881',
        '62882',
        '62883',
        '62884',
        '62885',
        '62886',
        '62887',
        '62888',
        '62889',
        '62890',
        '62891',
        '62892',
        '62893',
        '62894',
        '62895',
        '62896',
        '62897',
        '62898',
        '62899',
        '62901',
        '62902',
        '62903',
        '62905',
        '62906',
        '62907',
        '62908',
        '62910',
        '62912',
        '62914',
        '62915',
        '62916',
        '62917',
        '62918',
        '62919',
        '62920',
        '62921',
        '62922',
        '62923',
        '62924',
        '62926',
        '62927',
        '62928',
        '62930',
        '62931',
        '62932',
        '62933',
        '62934',
        '62935',
        '62938',
        '62939',
        '62940',
        '62941',
        '62942',
        '62943',
        '62946',
        '62947',
        '62948',
        '62949',
        '62950',
        '62951',
        '62952',
        '62953',
        '62954',
        '62956',
        '62957',
        '62958',
        '62959',
        '62960',
        '62961',
        '62962',
        '62963',
        '62964',
        '62965',
        '62966',
        '62967',
        '62969',
        '62970',
        '62972',
        '62974',
        '62975',
        '62976',
        '62977',
        '62979',
        '62982',
        '62983',
        '62984',
        '62985',
        '62987',
        '62988',
        '62990',
        '62992',
        '62994',
        '62995',
        '62996',
        '62997',
        '62998',
        '62999'
      ]
    },
    MO: {
      name: 'Missouri',
      codes: [
        '63005',
        '63010',
        '63011',
        '63012',
        '63013',
        '63014',
        '63015',
        '63016',
        '63017',
        '63019',
        '63020',
        '63021',
        '63023',
        '63025',
        '63026',
        '63028',
        '63030',
        '63031',
        '63033',
        '63034',
        '63036',
        '63037',
        '63038',
        '63039',
        '63040',
        '63041',
        '63042',
        '63043',
        '63044',
        '63045',
        '63047',
        '63048',
        '63049',
        '63050',
        '63051',
        '63052',
        '63053',
        '63055',
        '63056',
        '63057',
        '63060',
        '63061',
        '63068',
        '63069',
        '63070',
        '63071',
        '63072',
        '63073',
        '63074',
        '63077',
        '63079',
        '63080',
        '63084',
        '63087',
        '63088',
        '63089',
        '63090',
        '63091',
        '63101',
        '63102',
        '63103',
        '63104',
        '63105',
        '63106',
        '63107',
        '63108',
        '63109',
        '63110',
        '63111',
        '63112',
        '63113',
        '63114',
        '63115',
        '63116',
        '63117',
        '63118',
        '63119',
        '63120',
        '63121',
        '63122',
        '63123',
        '63124',
        '63125',
        '63126',
        '63127',
        '63128',
        '63129',
        '63130',
        '63131',
        '63132',
        '63133',
        '63134',
        '63135',
        '63136',
        '63137',
        '63138',
        '63139',
        '63140',
        '63141',
        '63143',
        '63144',
        '63146',
        '63147',
        '63155',
        '63301',
        '63303',
        '63304',
        '63330',
        '63332',
        '63333',
        '63334',
        '63336',
        '63339',
        '63341',
        '63343',
        '63344',
        '63345',
        '63347',
        '63348',
        '63349',
        '63350',
        '63351',
        '63352',
        '63353',
        '63357',
        '63359',
        '63361',
        '63362',
        '63363',
        '63366',
        '63367',
        '63368',
        '63369',
        '63370',
        '63373',
        '63376',
        '63377',
        '63379',
        '63381',
        '63382',
        '63383',
        '63384',
        '63385',
        '63386',
        '63387',
        '63388',
        '63389',
        '63390',
        '63401',
        '63430',
        '63431',
        '63432',
        '63433',
        '63434',
        '63435',
        '63436',
        '63437',
        '63438',
        '63439',
        '63440',
        '63441',
        '63443',
        '63445',
        '63446',
        '63447',
        '63448',
        '63450',
        '63451',
        '63452',
        '63453',
        '63454',
        '63456',
        '63457',
        '63458',
        '63459',
        '63460',
        '63461',
        '63462',
        '63463',
        '63465',
        '63467',
        '63468',
        '63469',
        '63471',
        '63472',
        '63473',
        '63474',
        '63501',
        '63530',
        '63531',
        '63532',
        '63533',
        '63534',
        '63535',
        '63536',
        '63537',
        '63538',
        '63539',
        '63540',
        '63541',
        '63543',
        '63544',
        '63545',
        '63546',
        '63547',
        '63548',
        '63549',
        '63551',
        '63552',
        '63555',
        '63556',
        '63557',
        '63558',
        '63559',
        '63560',
        '63561',
        '63563',
        '63565',
        '63566',
        '63567',
        '63601',
        '63620',
        '63621',
        '63622',
        '63623',
        '63624',
        '63625',
        '63626',
        '63627',
        '63628',
        '63629',
        '63630',
        '63631',
        '63633',
        '63636',
        '63637',
        '63638',
        '63640',
        '63645',
        '63648',
        '63650',
        '63653',
        '63654',
        '63655',
        '63656',
        '63660',
        '63662',
        '63663',
        '63664',
        '63665',
        '63666',
        '63670',
        '63673',
        '63674',
        '63675',
        '63701',
        '63703',
        '63730',
        '63732',
        '63735',
        '63736',
        '63738',
        '63739',
        '63740',
        '63742',
        '63743',
        '63744',
        '63745',
        '63746',
        '63747',
        '63748',
        '63750',
        '63751',
        '63755',
        '63758',
        '63760',
        '63763',
        '63764',
        '63766',
        '63767',
        '63769',
        '63770',
        '63771',
        '63774',
        '63775',
        '63780',
        '63781',
        '63782',
        '63783',
        '63784',
        '63785',
        '63787',
        '63801',
        '63820',
        '63821',
        '63822',
        '63823',
        '63824',
        '63825',
        '63826',
        '63827',
        '63828',
        '63829',
        '63830',
        '63833',
        '63834',
        '63837',
        '63839',
        '63841',
        '63845',
        '63846',
        '63847',
        '63848',
        '63849',
        '63851',
        '63852',
        '63853',
        '63855',
        '63857',
        '63860',
        '63862',
        '63863',
        '63866',
        '63867',
        '63868',
        '63869',
        '63870',
        '63873',
        '63874',
        '63876',
        '63877',
        '63878',
        '63879',
        '63880',
        '63882',
        '63901',
        '63902',
        '63932',
        '63933',
        '63934',
        '63935',
        '63936',
        '63937',
        '63939',
        '63940',
        '63941',
        '63942',
        '63943',
        '63944',
        '63945',
        '63951',
        '63952',
        '63953',
        '63954',
        '63955',
        '63956',
        '63957',
        '63960',
        '63961',
        '63962',
        '63964',
        '63965',
        '63966',
        '63967',
        '64001',
        '64011',
        '64012',
        '64014',
        '64015',
        '64016',
        '64017',
        '64018',
        '64019',
        '64020',
        '64021',
        '64022',
        '64024',
        '64029',
        '64030',
        '64034',
        '64035',
        '64036',
        '64037',
        '64040',
        '64048',
        '64050',
        '64052',
        '64053',
        '64054',
        '64055',
        '64056',
        '64057',
        '64058',
        '64060',
        '64061',
        '64062',
        '64063',
        '64064',
        '64065',
        '64066',
        '64067',
        '64068',
        '64070',
        '64071',
        '64072',
        '64074',
        '64075',
        '64076',
        '64077',
        '64078',
        '64079',
        '64080',
        '64081',
        '64082',
        '64083',
        '64084',
        '64085',
        '64086',
        '64088',
        '64089',
        '64090',
        '64092',
        '64093',
        '64096',
        '64097',
        '64098',
        '64101',
        '64102',
        '64105',
        '64106',
        '64108',
        '64109',
        '64110',
        '64111',
        '64112',
        '64113',
        '64114',
        '64116',
        '64117',
        '64118',
        '64119',
        '64120',
        '64123',
        '64124',
        '64125',
        '64126',
        '64127',
        '64128',
        '64129',
        '64130',
        '64131',
        '64132',
        '64133',
        '64134',
        '64136',
        '64137',
        '64138',
        '64139',
        '64145',
        '64146',
        '64147',
        '64149',
        '64150',
        '64151',
        '64152',
        '64153',
        '64154',
        '64155',
        '64156',
        '64157',
        '64158',
        '64161',
        '64163',
        '64164',
        '64165',
        '64166',
        '64167',
        '64401',
        '64402',
        '64420',
        '64421',
        '64422',
        '64423',
        '64424',
        '64426',
        '64427',
        '64428',
        '64429',
        '64430',
        '64431',
        '64432',
        '64433',
        '64434',
        '64436',
        '64437',
        '64438',
        '64439',
        '64440',
        '64441',
        '64442',
        '64443',
        '64444',
        '64445',
        '64446',
        '64448',
        '64449',
        '64451',
        '64453',
        '64454',
        '64455',
        '64456',
        '64457',
        '64458',
        '64459',
        '64461',
        '64463',
        '64465',
        '64466',
        '64467',
        '64468',
        '64469',
        '64470',
        '64471',
        '64473',
        '64474',
        '64475',
        '64476',
        '64477',
        '64479',
        '64480',
        '64481',
        '64482',
        '64483',
        '64484',
        '64485',
        '64486',
        '64487',
        '64489',
        '64490',
        '64491',
        '64492',
        '64493',
        '64494',
        '64496',
        '64497',
        '64498',
        '64499',
        '64501',
        '64503',
        '64504',
        '64505',
        '64506',
        '64507',
        '64601',
        '64620',
        '64622',
        '64623',
        '64624',
        '64625',
        '64628',
        '64630',
        '64631',
        '64632',
        '64633',
        '64635',
        '64636',
        '64637',
        '64638',
        '64639',
        '64640',
        '64641',
        '64642',
        '64643',
        '64644',
        '64645',
        '64646',
        '64647',
        '64648',
        '64649',
        '64650',
        '64651',
        '64652',
        '64653',
        '64654',
        '64655',
        '64656',
        '64657',
        '64658',
        '64659',
        '64660',
        '64661',
        '64664',
        '64667',
        '64668',
        '64670',
        '64671',
        '64672',
        '64673',
        '64674',
        '64676',
        '64679',
        '64681',
        '64682',
        '64683',
        '64686',
        '64688',
        '64689',
        '64701',
        '64720',
        '64722',
        '64723',
        '64724',
        '64725',
        '64726',
        '64728',
        '64730',
        '64733',
        '64734',
        '64735',
        '64738',
        '64739',
        '64740',
        '64741',
        '64742',
        '64743',
        '64744',
        '64745',
        '64746',
        '64747',
        '64748',
        '64750',
        '64752',
        '64755',
        '64756',
        '64759',
        '64761',
        '64762',
        '64763',
        '64765',
        '64767',
        '64769',
        '64770',
        '64771',
        '64772',
        '64776',
        '64778',
        '64779',
        '64780',
        '64781',
        '64783',
        '64784',
        '64788',
        '64790',
        '64801',
        '64804',
        '64830',
        '64831',
        '64832',
        '64833',
        '64834',
        '64835',
        '64836',
        '64840',
        '64841',
        '64842',
        '64843',
        '64844',
        '64847',
        '64848',
        '64849',
        '64850',
        '64854',
        '64855',
        '64856',
        '64857',
        '64858',
        '64859',
        '64861',
        '64862',
        '64863',
        '64865',
        '64866',
        '64867',
        '64870',
        '64873',
        '64874',
        '65001',
        '65010',
        '65011',
        '65013',
        '65014',
        '65016',
        '65017',
        '65018',
        '65020',
        '65023',
        '65024',
        '65025',
        '65026',
        '65032',
        '65034',
        '65035',
        '65037',
        '65039',
        '65040',
        '65041',
        '65043',
        '65046',
        '65047',
        '65048',
        '65049',
        '65050',
        '65051',
        '65052',
        '65053',
        '65054',
        '65058',
        '65059',
        '65061',
        '65062',
        '65063',
        '65064',
        '65065',
        '65066',
        '65067',
        '65068',
        '65069',
        '65072',
        '65074',
        '65075',
        '65076',
        '65077',
        '65078',
        '65079',
        '65080',
        '65081',
        '65082',
        '65083',
        '65084',
        '65085',
        '65101',
        '65109',
        '65201',
        '65202',
        '65203',
        '65215',
        '65230',
        '65231',
        '65232',
        '65233',
        '65236',
        '65237',
        '65239',
        '65240',
        '65243',
        '65244',
        '65246',
        '65247',
        '65248',
        '65250',
        '65251',
        '65254',
        '65255',
        '65256',
        '65257',
        '65258',
        '65259',
        '65260',
        '65261',
        '65262',
        '65263',
        '65264',
        '65265',
        '65270',
        '65274',
        '65275',
        '65276',
        '65278',
        '65279',
        '65280',
        '65281',
        '65282',
        '65283',
        '65284',
        '65285',
        '65286',
        '65287',
        '65301',
        '65305',
        '65320',
        '65321',
        '65322',
        '65323',
        '65324',
        '65325',
        '65326',
        '65327',
        '65329',
        '65330',
        '65332',
        '65333',
        '65334',
        '65335',
        '65336',
        '65337',
        '65338',
        '65339',
        '65340',
        '65344',
        '65345',
        '65347',
        '65348',
        '65349',
        '65350',
        '65351',
        '65354',
        '65355',
        '65360',
        '65401',
        '65436',
        '65438',
        '65439',
        '65440',
        '65441',
        '65443',
        '65444',
        '65446',
        '65449',
        '65452',
        '65453',
        '65456',
        '65457',
        '65459',
        '65461',
        '65462',
        '65463',
        '65464',
        '65466',
        '65468',
        '65470',
        '65473',
        '65479',
        '65483',
        '65484',
        '65486',
        '65501',
        '65529',
        '65534',
        '65535',
        '65536',
        '65541',
        '65542',
        '65543',
        '65548',
        '65550',
        '65552',
        '65555',
        '65556',
        '65557',
        '65559',
        '65560',
        '65564',
        '65565',
        '65566',
        '65567',
        '65570',
        '65571',
        '65580',
        '65582',
        '65583',
        '65584',
        '65586',
        '65588',
        '65589',
        '65590',
        '65591',
        '65601',
        '65603',
        '65604',
        '65605',
        '65606',
        '65608',
        '65609',
        '65610',
        '65611',
        '65612',
        '65613',
        '65614',
        '65616',
        '65617',
        '65618',
        '65619',
        '65620',
        '65622',
        '65623',
        '65624',
        '65625',
        '65626',
        '65627',
        '65629',
        '65630',
        '65631',
        '65632',
        '65633',
        '65634',
        '65635',
        '65637',
        '65638',
        '65640',
        '65641',
        '65644',
        '65646',
        '65647',
        '65648',
        '65649',
        '65650',
        '65652',
        '65653',
        '65654',
        '65655',
        '65656',
        '65657',
        '65658',
        '65660',
        '65661',
        '65662',
        '65663',
        '65664',
        '65667',
        '65668',
        '65669',
        '65672',
        '65674',
        '65676',
        '65679',
        '65680',
        '65681',
        '65682',
        '65685',
        '65686',
        '65689',
        '65690',
        '65692',
        '65702',
        '65704',
        '65705',
        '65706',
        '65707',
        '65708',
        '65710',
        '65711',
        '65712',
        '65713',
        '65714',
        '65715',
        '65717',
        '65720',
        '65721',
        '65722',
        '65723',
        '65724',
        '65725',
        '65727',
        '65728',
        '65729',
        '65730',
        '65731',
        '65732',
        '65733',
        '65734',
        '65735',
        '65737',
        '65738',
        '65739',
        '65740',
        '65742',
        '65744',
        '65745',
        '65746',
        '65747',
        '65752',
        '65753',
        '65754',
        '65755',
        '65756',
        '65757',
        '65759',
        '65760',
        '65761',
        '65762',
        '65764',
        '65766',
        '65767',
        '65768',
        '65769',
        '65770',
        '65771',
        '65772',
        '65773',
        '65774',
        '65775',
        '65777',
        '65778',
        '65779',
        '65781',
        '65783',
        '65784',
        '65785',
        '65786',
        '65787',
        '65788',
        '65789',
        '65790',
        '65791',
        '65793',
        '65802',
        '65803',
        '65804',
        '65806',
        '65807',
        '65809',
        '65810'
      ]
    },
    KS: {
      name: 'Kansas',
      codes: [
        '66002',
        '66006',
        '66007',
        '66008',
        '66010',
        '66012',
        '66013',
        '66014',
        '66015',
        '66016',
        '66017',
        '66018',
        '66020',
        '66021',
        '66023',
        '66024',
        '66025',
        '66026',
        '66027',
        '66030',
        '66031',
        '66032',
        '66033',
        '66035',
        '66039',
        '66040',
        '66041',
        '66042',
        '66043',
        '66044',
        '66045',
        '66046',
        '66047',
        '66048',
        '66049',
        '66050',
        '66052',
        '66053',
        '66054',
        '66056',
        '66058',
        '66060',
        '66061',
        '66062',
        '66064',
        '66066',
        '66067',
        '66070',
        '66071',
        '66072',
        '66073',
        '66075',
        '66076',
        '66078',
        '66079',
        '66080',
        '66083',
        '66085',
        '66086',
        '66087',
        '66088',
        '66090',
        '66091',
        '66092',
        '66093',
        '66094',
        '66095',
        '66097',
        '66101',
        '66102',
        '66103',
        '66104',
        '66105',
        '66106',
        '66109',
        '66111',
        '66112',
        '66115',
        '66118',
        '66202',
        '66203',
        '66204',
        '66205',
        '66206',
        '66207',
        '66208',
        '66209',
        '66210',
        '66211',
        '66212',
        '66213',
        '66214',
        '66215',
        '66216',
        '66217',
        '66218',
        '66219',
        '66220',
        '66221',
        '66223',
        '66224',
        '66226',
        '66227',
        '66401',
        '66402',
        '66403',
        '66404',
        '66406',
        '66407',
        '66408',
        '66409',
        '66411',
        '66412',
        '66413',
        '66414',
        '66415',
        '66416',
        '66417',
        '66418',
        '66419',
        '66422',
        '66423',
        '66424',
        '66425',
        '66427',
        '66428',
        '66429',
        '66431',
        '66432',
        '66434',
        '66436',
        '66438',
        '66439',
        '66440',
        '66441',
        '66442',
        '66449',
        '66451',
        '66501',
        '66502',
        '66503',
        '66506',
        '66507',
        '66508',
        '66509',
        '66510',
        '66512',
        '66514',
        '66515',
        '66516',
        '66517',
        '66518',
        '66520',
        '66521',
        '66522',
        '66523',
        '66524',
        '66526',
        '66527',
        '66528',
        '66531',
        '66532',
        '66533',
        '66534',
        '66535',
        '66536',
        '66537',
        '66538',
        '66539',
        '66540',
        '66541',
        '66542',
        '66543',
        '66544',
        '66546',
        '66547',
        '66548',
        '66549',
        '66550',
        '66552',
        '66554',
        '66603',
        '66604',
        '66605',
        '66606',
        '66607',
        '66608',
        '66609',
        '66610',
        '66611',
        '66612',
        '66614',
        '66615',
        '66616',
        '66617',
        '66618',
        '66619',
        '66621',
        '66622',
        '66701',
        '66710',
        '66711',
        '66712',
        '66713',
        '66714',
        '66716',
        '66717',
        '66720',
        '66724',
        '66725',
        '66728',
        '66732',
        '66733',
        '66734',
        '66735',
        '66736',
        '66738',
        '66739',
        '66740',
        '66741',
        '66743',
        '66746',
        '66748',
        '66749',
        '66751',
        '66753',
        '66754',
        '66755',
        '66756',
        '66757',
        '66758',
        '66760',
        '66761',
        '66762',
        '66763',
        '66767',
        '66769',
        '66770',
        '66771',
        '66772',
        '66773',
        '66775',
        '66776',
        '66777',
        '66778',
        '66779',
        '66780',
        '66781',
        '66782',
        '66783',
        '66801',
        '66830',
        '66833',
        '66834',
        '66835',
        '66838',
        '66839',
        '66840',
        '66842',
        '66843',
        '66845',
        '66846',
        '66849',
        '66850',
        '66851',
        '66852',
        '66853',
        '66854',
        '66856',
        '66857',
        '66858',
        '66859',
        '66860',
        '66861',
        '66862',
        '66863',
        '66864',
        '66865',
        '66866',
        '66868',
        '66869',
        '66870',
        '66871',
        '66872',
        '66873',
        '66901',
        '66930',
        '66932',
        '66933',
        '66935',
        '66936',
        '66937',
        '66938',
        '66939',
        '66940',
        '66941',
        '66942',
        '66943',
        '66944',
        '66945',
        '66946',
        '66948',
        '66949',
        '66951',
        '66952',
        '66953',
        '66955',
        '66956',
        '66958',
        '66959',
        '66960',
        '66962',
        '66963',
        '66964',
        '66966',
        '66967',
        '66968',
        '66970',
        '67001',
        '67002',
        '67003',
        '67004',
        '67005',
        '67008',
        '67009',
        '67010',
        '67012',
        '67013',
        '67016',
        '67017',
        '67018',
        '67019',
        '67020',
        '67021',
        '67022',
        '67023',
        '67024',
        '67025',
        '67026',
        '67028',
        '67029',
        '67030',
        '67031',
        '67035',
        '67036',
        '67037',
        '67038',
        '67039',
        '67041',
        '67042',
        '67045',
        '67047',
        '67049',
        '67050',
        '67051',
        '67052',
        '67053',
        '67054',
        '67055',
        '67056',
        '67057',
        '67058',
        '67059',
        '67060',
        '67061',
        '67062',
        '67063',
        '67065',
        '67066',
        '67067',
        '67068',
        '67070',
        '67071',
        '67072',
        '67073',
        '67074',
        '67101',
        '67103',
        '67104',
        '67105',
        '67106',
        '67107',
        '67108',
        '67109',
        '67110',
        '67111',
        '67112',
        '67114',
        '67117',
        '67118',
        '67119',
        '67120',
        '67122',
        '67123',
        '67124',
        '67127',
        '67131',
        '67132',
        '67133',
        '67134',
        '67135',
        '67137',
        '67138',
        '67140',
        '67142',
        '67143',
        '67144',
        '67146',
        '67147',
        '67149',
        '67150',
        '67151',
        '67152',
        '67154',
        '67155',
        '67156',
        '67159',
        '67202',
        '67203',
        '67204',
        '67205',
        '67206',
        '67207',
        '67208',
        '67209',
        '67210',
        '67211',
        '67212',
        '67213',
        '67214',
        '67215',
        '67216',
        '67217',
        '67218',
        '67219',
        '67220',
        '67223',
        '67226',
        '67227',
        '67228',
        '67230',
        '67232',
        '67235',
        '67260',
        '67301',
        '67330',
        '67332',
        '67333',
        '67334',
        '67335',
        '67336',
        '67337',
        '67340',
        '67341',
        '67342',
        '67344',
        '67345',
        '67346',
        '67347',
        '67349',
        '67351',
        '67352',
        '67353',
        '67354',
        '67355',
        '67356',
        '67357',
        '67360',
        '67361',
        '67363',
        '67364',
        '67401',
        '67410',
        '67416',
        '67417',
        '67418',
        '67420',
        '67422',
        '67423',
        '67425',
        '67427',
        '67428',
        '67430',
        '67431',
        '67432',
        '67436',
        '67437',
        '67438',
        '67439',
        '67441',
        '67442',
        '67443',
        '67444',
        '67445',
        '67446',
        '67447',
        '67448',
        '67449',
        '67450',
        '67451',
        '67452',
        '67454',
        '67455',
        '67456',
        '67457',
        '67458',
        '67459',
        '67460',
        '67464',
        '67466',
        '67467',
        '67468',
        '67470',
        '67473',
        '67474',
        '67475',
        '67478',
        '67480',
        '67481',
        '67482',
        '67483',
        '67484',
        '67485',
        '67487',
        '67490',
        '67491',
        '67492',
        '67501',
        '67502',
        '67505',
        '67510',
        '67511',
        '67512',
        '67513',
        '67514',
        '67515',
        '67516',
        '67518',
        '67519',
        '67520',
        '67521',
        '67522',
        '67523',
        '67524',
        '67525',
        '67526',
        '67529',
        '67530',
        '67543',
        '67544',
        '67545',
        '67546',
        '67547',
        '67548',
        '67550',
        '67552',
        '67553',
        '67554',
        '67556',
        '67557',
        '67559',
        '67560',
        '67561',
        '67563',
        '67564',
        '67565',
        '67566',
        '67567',
        '67568',
        '67570',
        '67572',
        '67573',
        '67574',
        '67575',
        '67576',
        '67578',
        '67579',
        '67581',
        '67583',
        '67584',
        '67601',
        '67621',
        '67622',
        '67623',
        '67625',
        '67626',
        '67628',
        '67629',
        '67631',
        '67632',
        '67634',
        '67635',
        '67637',
        '67638',
        '67639',
        '67640',
        '67642',
        '67643',
        '67644',
        '67645',
        '67646',
        '67647',
        '67648',
        '67649',
        '67650',
        '67651',
        '67653',
        '67654',
        '67656',
        '67657',
        '67658',
        '67659',
        '67660',
        '67661',
        '67663',
        '67664',
        '67665',
        '67667',
        '67669',
        '67671',
        '67672',
        '67673',
        '67674',
        '67675',
        '67701',
        '67730',
        '67731',
        '67732',
        '67733',
        '67734',
        '67735',
        '67736',
        '67737',
        '67738',
        '67739',
        '67740',
        '67741',
        '67743',
        '67744',
        '67745',
        '67747',
        '67748',
        '67749',
        '67751',
        '67752',
        '67753',
        '67756',
        '67757',
        '67758',
        '67761',
        '67762',
        '67764',
        '67801',
        '67831',
        '67834',
        '67835',
        '67836',
        '67837',
        '67838',
        '67839',
        '67840',
        '67841',
        '67842',
        '67843',
        '67844',
        '67846',
        '67849',
        '67850',
        '67851',
        '67853',
        '67854',
        '67855',
        '67857',
        '67859',
        '67860',
        '67861',
        '67862',
        '67863',
        '67864',
        '67865',
        '67867',
        '67868',
        '67869',
        '67870',
        '67871',
        '67876',
        '67877',
        '67878',
        '67879',
        '67880',
        '67882',
        '67901',
        '67950',
        '67951',
        '67952',
        '67953',
        '67954'
      ]
    },
    NE: {
      name: 'Nebraska',
      codes: [
        '68001',
        '68002',
        '68003',
        '68004',
        '68005',
        '68007',
        '68008',
        '68010',
        '68014',
        '68015',
        '68016',
        '68017',
        '68018',
        '68019',
        '68020',
        '68022',
        '68023',
        '68025',
        '68028',
        '68029',
        '68030',
        '68031',
        '68033',
        '68034',
        '68036',
        '68037',
        '68038',
        '68039',
        '68040',
        '68041',
        '68042',
        '68044',
        '68045',
        '68046',
        '68047',
        '68048',
        '68050',
        '68055',
        '68057',
        '68058',
        '68059',
        '68061',
        '68062',
        '68063',
        '68064',
        '68065',
        '68066',
        '68067',
        '68068',
        '68069',
        '68070',
        '68071',
        '68072',
        '68073',
        '68102',
        '68104',
        '68105',
        '68106',
        '68107',
        '68108',
        '68110',
        '68111',
        '68112',
        '68113',
        '68114',
        '68116',
        '68117',
        '68118',
        '68122',
        '68123',
        '68124',
        '68127',
        '68128',
        '68130',
        '68131',
        '68132',
        '68133',
        '68134',
        '68135',
        '68136',
        '68137',
        '68138',
        '68142',
        '68144',
        '68147',
        '68152',
        '68154',
        '68157',
        '68164',
        '68178',
        '68301',
        '68303',
        '68304',
        '68305',
        '68307',
        '68309',
        '68310',
        '68313',
        '68314',
        '68315',
        '68316',
        '68317',
        '68318',
        '68319',
        '68320',
        '68321',
        '68322',
        '68323',
        '68324',
        '68325',
        '68326',
        '68327',
        '68328',
        '68329',
        '68331',
        '68332',
        '68333',
        '68335',
        '68336',
        '68337',
        '68338',
        '68339',
        '68340',
        '68341',
        '68342',
        '68343',
        '68344',
        '68345',
        '68346',
        '68347',
        '68348',
        '68349',
        '68350',
        '68351',
        '68352',
        '68354',
        '68355',
        '68357',
        '68358',
        '68359',
        '68360',
        '68361',
        '68362',
        '68364',
        '68365',
        '68366',
        '68367',
        '68368',
        '68370',
        '68371',
        '68372',
        '68375',
        '68376',
        '68377',
        '68378',
        '68379',
        '68380',
        '68381',
        '68382',
        '68401',
        '68402',
        '68403',
        '68404',
        '68405',
        '68406',
        '68407',
        '68409',
        '68410',
        '68413',
        '68414',
        '68415',
        '68416',
        '68417',
        '68418',
        '68419',
        '68420',
        '68421',
        '68422',
        '68423',
        '68424',
        '68428',
        '68430',
        '68431',
        '68433',
        '68434',
        '68436',
        '68437',
        '68438',
        '68439',
        '68440',
        '68441',
        '68442',
        '68443',
        '68444',
        '68445',
        '68446',
        '68447',
        '68448',
        '68450',
        '68452',
        '68453',
        '68454',
        '68455',
        '68456',
        '68457',
        '68458',
        '68460',
        '68461',
        '68462',
        '68463',
        '68464',
        '68465',
        '68466',
        '68467',
        '68502',
        '68503',
        '68504',
        '68505',
        '68506',
        '68507',
        '68508',
        '68510',
        '68512',
        '68514',
        '68516',
        '68517',
        '68520',
        '68521',
        '68522',
        '68523',
        '68524',
        '68526',
        '68527',
        '68528',
        '68531',
        '68532',
        '68601',
        '68620',
        '68621',
        '68622',
        '68623',
        '68624',
        '68626',
        '68627',
        '68628',
        '68629',
        '68631',
        '68632',
        '68633',
        '68634',
        '68635',
        '68636',
        '68637',
        '68638',
        '68640',
        '68641',
        '68642',
        '68643',
        '68644',
        '68647',
        '68648',
        '68649',
        '68651',
        '68652',
        '68653',
        '68654',
        '68655',
        '68658',
        '68659',
        '68660',
        '68661',
        '68662',
        '68663',
        '68664',
        '68665',
        '68666',
        '68667',
        '68669',
        '68701',
        '68710',
        '68711',
        '68713',
        '68714',
        '68715',
        '68716',
        '68717',
        '68718',
        '68719',
        '68720',
        '68722',
        '68723',
        '68724',
        '68725',
        '68726',
        '68727',
        '68728',
        '68729',
        '68730',
        '68731',
        '68732',
        '68733',
        '68734',
        '68735',
        '68736',
        '68739',
        '68740',
        '68741',
        '68742',
        '68743',
        '68745',
        '68746',
        '68747',
        '68748',
        '68749',
        '68751',
        '68752',
        '68753',
        '68755',
        '68756',
        '68757',
        '68758',
        '68759',
        '68760',
        '68761',
        '68763',
        '68764',
        '68765',
        '68766',
        '68767',
        '68768',
        '68769',
        '68770',
        '68771',
        '68773',
        '68774',
        '68776',
        '68777',
        '68778',
        '68779',
        '68780',
        '68781',
        '68783',
        '68784',
        '68785',
        '68786',
        '68787',
        '68788',
        '68789',
        '68790',
        '68791',
        '68792',
        '68801',
        '68803',
        '68810',
        '68812',
        '68813',
        '68814',
        '68815',
        '68816',
        '68817',
        '68818',
        '68820',
        '68821',
        '68822',
        '68823',
        '68824',
        '68825',
        '68826',
        '68827',
        '68828',
        '68831',
        '68832',
        '68833',
        '68834',
        '68835',
        '68836',
        '68837',
        '68838',
        '68840',
        '68841',
        '68842',
        '68843',
        '68844',
        '68845',
        '68846',
        '68847',
        '68849',
        '68850',
        '68852',
        '68853',
        '68854',
        '68855',
        '68856',
        '68858',
        '68859',
        '68860',
        '68861',
        '68862',
        '68863',
        '68864',
        '68865',
        '68866',
        '68869',
        '68870',
        '68871',
        '68872',
        '68873',
        '68874',
        '68875',
        '68876',
        '68878',
        '68879',
        '68881',
        '68882',
        '68883',
        '68901',
        '68920',
        '68922',
        '68923',
        '68924',
        '68925',
        '68926',
        '68927',
        '68928',
        '68929',
        '68930',
        '68932',
        '68933',
        '68934',
        '68935',
        '68936',
        '68937',
        '68938',
        '68939',
        '68940',
        '68941',
        '68942',
        '68943',
        '68944',
        '68945',
        '68946',
        '68947',
        '68948',
        '68949',
        '68950',
        '68952',
        '68954',
        '68955',
        '68956',
        '68957',
        '68958',
        '68959',
        '68960',
        '68961',
        '68964',
        '68966',
        '68967',
        '68969',
        '68970',
        '68971',
        '68972',
        '68973',
        '68974',
        '68975',
        '68976',
        '68977',
        '68978',
        '68979',
        '68980',
        '68981',
        '68982',
        '69001',
        '69020',
        '69021',
        '69022',
        '69023',
        '69024',
        '69025',
        '69026',
        '69027',
        '69028',
        '69029',
        '69030',
        '69032',
        '69033',
        '69034',
        '69036',
        '69037',
        '69038',
        '69039',
        '69040',
        '69041',
        '69042',
        '69043',
        '69044',
        '69045',
        '69046',
        '69101',
        '69120',
        '69121',
        '69122',
        '69123',
        '69125',
        '69127',
        '69128',
        '69129',
        '69130',
        '69131',
        '69132',
        '69133',
        '69134',
        '69135',
        '69138',
        '69140',
        '69141',
        '69142',
        '69143',
        '69144',
        '69145',
        '69146',
        '69147',
        '69148',
        '69149',
        '69150',
        '69151',
        '69152',
        '69153',
        '69154',
        '69155',
        '69156',
        '69157',
        '69161',
        '69162',
        '69163',
        '69165',
        '69166',
        '69167',
        '69168',
        '69169',
        '69170',
        '69171',
        '69201',
        '69210',
        '69211',
        '69212',
        '69214',
        '69216',
        '69217',
        '69218',
        '69219',
        '69220',
        '69221',
        '69301',
        '69331',
        '69333',
        '69334',
        '69335',
        '69336',
        '69337',
        '69339',
        '69340',
        '69341',
        '69343',
        '69345',
        '69346',
        '69347',
        '69348',
        '69350',
        '69351',
        '69352',
        '69353',
        '69354',
        '69355',
        '69356',
        '69357',
        '69358',
        '69360',
        '69361',
        '69365',
        '69366',
        '69367'
      ]
    },
    LA: {
      name: 'Louisiana',
      codes: [
        '70001',
        '70002',
        '70003',
        '70005',
        '70006',
        '70030',
        '70031',
        '70032',
        '70036',
        '70037',
        '70038',
        '70039',
        '70040',
        '70041',
        '70043',
        '70047',
        '70049',
        '70050',
        '70051',
        '70052',
        '70053',
        '70056',
        '70057',
        '70058',
        '70062',
        '70065',
        '70067',
        '70068',
        '70070',
        '70071',
        '70072',
        '70075',
        '70076',
        '70079',
        '70080',
        '70082',
        '70083',
        '70084',
        '70085',
        '70086',
        '70087',
        '70090',
        '70091',
        '70092',
        '70094',
        '70112',
        '70113',
        '70114',
        '70115',
        '70116',
        '70117',
        '70118',
        '70119',
        '70121',
        '70122',
        '70123',
        '70124',
        '70125',
        '70126',
        '70127',
        '70128',
        '70129',
        '70130',
        '70131',
        '70139',
        '70163',
        '70301',
        '70339',
        '70340',
        '70341',
        '70342',
        '70343',
        '70344',
        '70345',
        '70346',
        '70352',
        '70353',
        '70354',
        '70355',
        '70356',
        '70357',
        '70358',
        '70359',
        '70360',
        '70363',
        '70364',
        '70372',
        '70373',
        '70374',
        '70375',
        '70377',
        '70380',
        '70390',
        '70391',
        '70392',
        '70393',
        '70394',
        '70395',
        '70397',
        '70401',
        '70402',
        '70403',
        '70420',
        '70422',
        '70426',
        '70427',
        '70431',
        '70433',
        '70435',
        '70436',
        '70437',
        '70438',
        '70441',
        '70442',
        '70443',
        '70444',
        '70445',
        '70446',
        '70447',
        '70448',
        '70449',
        '70450',
        '70451',
        '70452',
        '70453',
        '70454',
        '70455',
        '70456',
        '70458',
        '70460',
        '70461',
        '70462',
        '70463',
        '70464',
        '70465',
        '70466',
        '70471',
        '70501',
        '70503',
        '70506',
        '70507',
        '70508',
        '70510',
        '70512',
        '70513',
        '70514',
        '70515',
        '70516',
        '70517',
        '70518',
        '70519',
        '70520',
        '70523',
        '70524',
        '70525',
        '70526',
        '70528',
        '70529',
        '70531',
        '70532',
        '70533',
        '70534',
        '70535',
        '70537',
        '70538',
        '70541',
        '70542',
        '70543',
        '70544',
        '70546',
        '70548',
        '70549',
        '70550',
        '70552',
        '70554',
        '70555',
        '70556',
        '70558',
        '70559',
        '70560',
        '70563',
        '70570',
        '70575',
        '70576',
        '70577',
        '70578',
        '70580',
        '70581',
        '70582',
        '70583',
        '70584',
        '70585',
        '70586',
        '70589',
        '70591',
        '70592',
        '70601',
        '70605',
        '70607',
        '70611',
        '70615',
        '70630',
        '70631',
        '70632',
        '70633',
        '70634',
        '70637',
        '70638',
        '70639',
        '70640',
        '70643',
        '70644',
        '70645',
        '70646',
        '70647',
        '70648',
        '70650',
        '70651',
        '70652',
        '70653',
        '70654',
        '70655',
        '70656',
        '70657',
        '70658',
        '70659',
        '70660',
        '70661',
        '70662',
        '70663',
        '70665',
        '70668',
        '70669',
        '70706',
        '70710',
        '70711',
        '70712',
        '70714',
        '70715',
        '70719',
        '70721',
        '70722',
        '70723',
        '70725',
        '70726',
        '70729',
        '70730',
        '70732',
        '70733',
        '70734',
        '70736',
        '70737',
        '70739',
        '70740',
        '70743',
        '70744',
        '70747',
        '70748',
        '70749',
        '70750',
        '70752',
        '70753',
        '70754',
        '70755',
        '70756',
        '70757',
        '70759',
        '70760',
        '70761',
        '70762',
        '70763',
        '70764',
        '70767',
        '70769',
        '70770',
        '70772',
        '70773',
        '70774',
        '70775',
        '70776',
        '70777',
        '70778',
        '70780',
        '70782',
        '70783',
        '70785',
        '70787',
        '70788',
        '70789',
        '70791',
        '70801',
        '70802',
        '70803',
        '70805',
        '70806',
        '70807',
        '70808',
        '70809',
        '70810',
        '70811',
        '70812',
        '70814',
        '70815',
        '70816',
        '70817',
        '70818',
        '70819',
        '70820',
        '70836',
        '71001',
        '71003',
        '71004',
        '71006',
        '71007',
        '71008',
        '71016',
        '71018',
        '71019',
        '71021',
        '71023',
        '71024',
        '71027',
        '71028',
        '71029',
        '71030',
        '71031',
        '71032',
        '71033',
        '71034',
        '71037',
        '71038',
        '71039',
        '71040',
        '71043',
        '71044',
        '71045',
        '71046',
        '71047',
        '71048',
        '71049',
        '71051',
        '71052',
        '71055',
        '71060',
        '71061',
        '71063',
        '71064',
        '71065',
        '71067',
        '71068',
        '71069',
        '71070',
        '71071',
        '71072',
        '71073',
        '71075',
        '71078',
        '71079',
        '71082',
        '71101',
        '71103',
        '71104',
        '71105',
        '71106',
        '71107',
        '71108',
        '71109',
        '71110',
        '71111',
        '71112',
        '71115',
        '71118',
        '71119',
        '71129',
        '71201',
        '71202',
        '71203',
        '71209',
        '71219',
        '71220',
        '71222',
        '71223',
        '71225',
        '71226',
        '71227',
        '71229',
        '71232',
        '71233',
        '71234',
        '71235',
        '71237',
        '71238',
        '71241',
        '71243',
        '71245',
        '71247',
        '71250',
        '71251',
        '71253',
        '71254',
        '71256',
        '71259',
        '71260',
        '71261',
        '71263',
        '71264',
        '71266',
        '71268',
        '71269',
        '71270',
        '71272',
        '71275',
        '71276',
        '71277',
        '71279',
        '71280',
        '71282',
        '71286',
        '71291',
        '71292',
        '71295',
        '71301',
        '71302',
        '71303',
        '71316',
        '71322',
        '71323',
        '71325',
        '71326',
        '71327',
        '71328',
        '71331',
        '71333',
        '71334',
        '71336',
        '71339',
        '71340',
        '71341',
        '71342',
        '71343',
        '71345',
        '71346',
        '71350',
        '71351',
        '71353',
        '71354',
        '71355',
        '71356',
        '71357',
        '71358',
        '71360',
        '71362',
        '71366',
        '71367',
        '71368',
        '71369',
        '71371',
        '71373',
        '71375',
        '71377',
        '71378',
        '71401',
        '71403',
        '71404',
        '71405',
        '71406',
        '71407',
        '71409',
        '71410',
        '71411',
        '71414',
        '71416',
        '71417',
        '71418',
        '71419',
        '71422',
        '71423',
        '71424',
        '71425',
        '71426',
        '71427',
        '71429',
        '71430',
        '71432',
        '71433',
        '71435',
        '71438',
        '71439',
        '71441',
        '71446',
        '71447',
        '71449',
        '71450',
        '71452',
        '71454',
        '71455',
        '71456',
        '71457',
        '71459',
        '71461',
        '71462',
        '71463',
        '71465',
        '71466',
        '71467',
        '71468',
        '71469',
        '71472',
        '71473',
        '71474',
        '71479',
        '71480',
        '71483',
        '71485',
        '71486'
      ]
    },
    AR: {
      name: 'Arkansas',
      codes: [
        '71601',
        '71602',
        '71603',
        '71630',
        '71631',
        '71635',
        '71638',
        '71639',
        '71640',
        '71642',
        '71643',
        '71644',
        '71646',
        '71647',
        '71651',
        '71652',
        '71653',
        '71654',
        '71655',
        '71658',
        '71659',
        '71660',
        '71661',
        '71662',
        '71663',
        '71665',
        '71666',
        '71667',
        '71670',
        '71671',
        '71674',
        '71675',
        '71676',
        '71677',
        '71701',
        '71711',
        '71720',
        '71722',
        '71724',
        '71725',
        '71726',
        '71730',
        '71740',
        '71742',
        '71743',
        '71744',
        '71745',
        '71747',
        '71749',
        '71751',
        '71752',
        '71753',
        '71758',
        '71759',
        '71762',
        '71763',
        '71764',
        '71765',
        '71766',
        '71770',
        '71772',
        '71801',
        '71820',
        '71822',
        '71823',
        '71825',
        '71826',
        '71827',
        '71832',
        '71833',
        '71834',
        '71835',
        '71836',
        '71837',
        '71838',
        '71839',
        '71841',
        '71842',
        '71845',
        '71846',
        '71847',
        '71851',
        '71852',
        '71853',
        '71854',
        '71855',
        '71857',
        '71858',
        '71859',
        '71860',
        '71861',
        '71862',
        '71865',
        '71866',
        '71901',
        '71909',
        '71913',
        '71921',
        '71922',
        '71923',
        '71929',
        '71933',
        '71935',
        '71937',
        '71940',
        '71941',
        '71943',
        '71944',
        '71945',
        '71949',
        '71950',
        '71952',
        '71953',
        '71956',
        '71957',
        '71958',
        '71959',
        '71960',
        '71961',
        '71962',
        '71964',
        '71965',
        '71968',
        '71969',
        '71970',
        '71971',
        '71972',
        '71973',
        '71998',
        '71999',
        '72001',
        '72002',
        '72003',
        '72004',
        '72005',
        '72006',
        '72007',
        '72010',
        '72011',
        '72012',
        '72013',
        '72014',
        '72015',
        '72016',
        '72017',
        '72019',
        '72020',
        '72021',
        '72022',
        '72023',
        '72024',
        '72025',
        '72026',
        '72027',
        '72029',
        '72030',
        '72031',
        '72032',
        '72034',
        '72035',
        '72036',
        '72037',
        '72038',
        '72039',
        '72040',
        '72041',
        '72042',
        '72044',
        '72045',
        '72046',
        '72047',
        '72048',
        '72051',
        '72055',
        '72057',
        '72058',
        '72059',
        '72060',
        '72061',
        '72063',
        '72064',
        '72065',
        '72067',
        '72068',
        '72069',
        '72070',
        '72072',
        '72073',
        '72074',
        '72076',
        '72079',
        '72080',
        '72081',
        '72082',
        '72083',
        '72084',
        '72085',
        '72086',
        '72087',
        '72088',
        '72099',
        '72101',
        '72102',
        '72103',
        '72104',
        '72106',
        '72107',
        '72108',
        '72110',
        '72111',
        '72112',
        '72113',
        '72114',
        '72116',
        '72117',
        '72118',
        '72119',
        '72120',
        '72121',
        '72122',
        '72123',
        '72125',
        '72126',
        '72127',
        '72128',
        '72129',
        '72130',
        '72131',
        '72132',
        '72134',
        '72135',
        '72136',
        '72137',
        '72139',
        '72140',
        '72141',
        '72142',
        '72143',
        '72149',
        '72150',
        '72152',
        '72153',
        '72156',
        '72157',
        '72160',
        '72165',
        '72166',
        '72167',
        '72168',
        '72169',
        '72170',
        '72173',
        '72175',
        '72176',
        '72179',
        '72181',
        '72201',
        '72202',
        '72204',
        '72205',
        '72206',
        '72207',
        '72209',
        '72210',
        '72211',
        '72212',
        '72223',
        '72227',
        '72301',
        '72311',
        '72315',
        '72320',
        '72321',
        '72322',
        '72324',
        '72325',
        '72326',
        '72327',
        '72328',
        '72329',
        '72330',
        '72331',
        '72332',
        '72333',
        '72335',
        '72338',
        '72339',
        '72340',
        '72341',
        '72342',
        '72346',
        '72347',
        '72348',
        '72350',
        '72351',
        '72353',
        '72354',
        '72355',
        '72358',
        '72359',
        '72360',
        '72364',
        '72365',
        '72366',
        '72367',
        '72368',
        '72370',
        '72372',
        '72373',
        '72374',
        '72376',
        '72377',
        '72379',
        '72383',
        '72384',
        '72386',
        '72389',
        '72390',
        '72392',
        '72394',
        '72395',
        '72396',
        '72401',
        '72404',
        '72410',
        '72411',
        '72412',
        '72413',
        '72414',
        '72415',
        '72416',
        '72417',
        '72419',
        '72421',
        '72422',
        '72424',
        '72425',
        '72426',
        '72427',
        '72428',
        '72429',
        '72430',
        '72431',
        '72432',
        '72433',
        '72434',
        '72435',
        '72436',
        '72437',
        '72438',
        '72440',
        '72441',
        '72442',
        '72443',
        '72444',
        '72445',
        '72447',
        '72449',
        '72450',
        '72453',
        '72454',
        '72455',
        '72456',
        '72457',
        '72458',
        '72459',
        '72460',
        '72461',
        '72462',
        '72464',
        '72466',
        '72467',
        '72469',
        '72470',
        '72471',
        '72472',
        '72473',
        '72476',
        '72478',
        '72479',
        '72482',
        '72501',
        '72512',
        '72513',
        '72515',
        '72517',
        '72519',
        '72520',
        '72521',
        '72522',
        '72523',
        '72524',
        '72526',
        '72527',
        '72528',
        '72529',
        '72530',
        '72531',
        '72532',
        '72533',
        '72534',
        '72536',
        '72537',
        '72538',
        '72539',
        '72540',
        '72542',
        '72543',
        '72544',
        '72546',
        '72550',
        '72553',
        '72554',
        '72555',
        '72556',
        '72560',
        '72561',
        '72562',
        '72564',
        '72565',
        '72566',
        '72567',
        '72568',
        '72569',
        '72571',
        '72572',
        '72573',
        '72576',
        '72577',
        '72578',
        '72579',
        '72581',
        '72583',
        '72584',
        '72585',
        '72587',
        '72601',
        '72611',
        '72616',
        '72617',
        '72619',
        '72623',
        '72624',
        '72626',
        '72628',
        '72629',
        '72630',
        '72631',
        '72632',
        '72633',
        '72634',
        '72635',
        '72636',
        '72638',
        '72639',
        '72640',
        '72641',
        '72642',
        '72644',
        '72645',
        '72648',
        '72650',
        '72651',
        '72653',
        '72655',
        '72658',
        '72660',
        '72661',
        '72662',
        '72663',
        '72666',
        '72668',
        '72669',
        '72670',
        '72672',
        '72675',
        '72677',
        '72679',
        '72680',
        '72682',
        '72683',
        '72685',
        '72686',
        '72687',
        '72701',
        '72703',
        '72704',
        '72712',
        '72714',
        '72715',
        '72717',
        '72718',
        '72719',
        '72721',
        '72722',
        '72727',
        '72729',
        '72730',
        '72732',
        '72734',
        '72736',
        '72738',
        '72739',
        '72740',
        '72742',
        '72744',
        '72745',
        '72747',
        '72749',
        '72751',
        '72752',
        '72753',
        '72756',
        '72758',
        '72760',
        '72761',
        '72762',
        '72764',
        '72768',
        '72769',
        '72773',
        '72774',
        '72776',
        '72801',
        '72802',
        '72821',
        '72823',
        '72824',
        '72826',
        '72827',
        '72828',
        '72830',
        '72832',
        '72833',
        '72834',
        '72835',
        '72837',
        '72838',
        '72839',
        '72840',
        '72841',
        '72842',
        '72843',
        '72845',
        '72846',
        '72847',
        '72851',
        '72852',
        '72853',
        '72854',
        '72855',
        '72856',
        '72857',
        '72858',
        '72860',
        '72863',
        '72865',
        '72901',
        '72903',
        '72904',
        '72908',
        '72916',
        '72921',
        '72923',
        '72926',
        '72927',
        '72928',
        '72930',
        '72932',
        '72933',
        '72934',
        '72935',
        '72936',
        '72937',
        '72938',
        '72940',
        '72941',
        '72943',
        '72944',
        '72945',
        '72946',
        '72947',
        '72948',
        '72949',
        '72950',
        '72951',
        '72952',
        '72955',
        '72956',
        '72958',
        '72959'
      ]
    },
    OK: {
      name: 'Oklahoma',
      codes: [
        '73002',
        '73003',
        '73004',
        '73005',
        '73006',
        '73007',
        '73008',
        '73009',
        '73010',
        '73011',
        '73012',
        '73013',
        '73014',
        '73015',
        '73016',
        '73017',
        '73018',
        '73019',
        '73020',
        '73021',
        '73024',
        '73025',
        '73026',
        '73027',
        '73028',
        '73029',
        '73030',
        '73032',
        '73033',
        '73034',
        '73036',
        '73038',
        '73040',
        '73041',
        '73042',
        '73043',
        '73044',
        '73045',
        '73047',
        '73048',
        '73049',
        '73050',
        '73051',
        '73052',
        '73053',
        '73054',
        '73055',
        '73056',
        '73057',
        '73058',
        '73059',
        '73061',
        '73062',
        '73063',
        '73064',
        '73065',
        '73066',
        '73067',
        '73068',
        '73069',
        '73071',
        '73072',
        '73073',
        '73074',
        '73075',
        '73077',
        '73078',
        '73079',
        '73080',
        '73082',
        '73084',
        '73086',
        '73089',
        '73090',
        '73092',
        '73093',
        '73095',
        '73096',
        '73097',
        '73098',
        '73099',
        '73102',
        '73103',
        '73104',
        '73105',
        '73106',
        '73107',
        '73108',
        '73109',
        '73110',
        '73111',
        '73112',
        '73114',
        '73115',
        '73116',
        '73117',
        '73118',
        '73119',
        '73120',
        '73121',
        '73122',
        '73127',
        '73128',
        '73129',
        '73130',
        '73131',
        '73132',
        '73134',
        '73135',
        '73139',
        '73141',
        '73142',
        '73145',
        '73149',
        '73150',
        '73151',
        '73159',
        '73160',
        '73162',
        '73165',
        '73169',
        '73170',
        '73173',
        '73179',
        '73401',
        '73425',
        '73430',
        '73432',
        '73433',
        '73434',
        '73437',
        '73438',
        '73439',
        '73440',
        '73441',
        '73442',
        '73443',
        '73444',
        '73446',
        '73447',
        '73448',
        '73449',
        '73450',
        '73453',
        '73455',
        '73456',
        '73458',
        '73459',
        '73460',
        '73461',
        '73463',
        '73481',
        '73487',
        '73491',
        '73501',
        '73503',
        '73505',
        '73507',
        '73520',
        '73521',
        '73526',
        '73527',
        '73528',
        '73529',
        '73530',
        '73531',
        '73532',
        '73533',
        '73537',
        '73538',
        '73539',
        '73540',
        '73541',
        '73542',
        '73543',
        '73544',
        '73546',
        '73547',
        '73548',
        '73549',
        '73550',
        '73551',
        '73552',
        '73553',
        '73554',
        '73555',
        '73556',
        '73557',
        '73559',
        '73560',
        '73562',
        '73564',
        '73565',
        '73566',
        '73567',
        '73568',
        '73569',
        '73570',
        '73571',
        '73572',
        '73573',
        '73601',
        '73620',
        '73622',
        '73624',
        '73625',
        '73626',
        '73627',
        '73628',
        '73632',
        '73638',
        '73639',
        '73641',
        '73642',
        '73644',
        '73645',
        '73646',
        '73647',
        '73650',
        '73651',
        '73654',
        '73655',
        '73658',
        '73659',
        '73660',
        '73661',
        '73662',
        '73663',
        '73664',
        '73666',
        '73667',
        '73669',
        '73673',
        '73701',
        '73703',
        '73705',
        '73716',
        '73717',
        '73718',
        '73719',
        '73720',
        '73722',
        '73724',
        '73726',
        '73727',
        '73728',
        '73729',
        '73730',
        '73731',
        '73733',
        '73734',
        '73735',
        '73736',
        '73737',
        '73738',
        '73739',
        '73741',
        '73742',
        '73743',
        '73744',
        '73746',
        '73747',
        '73749',
        '73750',
        '73753',
        '73754',
        '73755',
        '73756',
        '73757',
        '73758',
        '73759',
        '73760',
        '73761',
        '73762',
        '73763',
        '73764',
        '73766',
        '73768',
        '73771',
        '73772',
        '73773',
        '73801',
        '73832',
        '73834',
        '73835',
        '73838',
        '73840',
        '73841',
        '73842',
        '73843',
        '73844',
        '73848',
        '73851',
        '73852',
        '73853',
        '73855',
        '73857',
        '73858',
        '73859',
        '73860',
        '73901',
        '73931',
        '73932',
        '73933',
        '73937',
        '73938',
        '73939',
        '73942',
        '73944',
        '73945',
        '73946',
        '73947',
        '73949',
        '73950',
        '73951',
        '74001',
        '74002',
        '74003',
        '74006',
        '74008',
        '74010',
        '74011',
        '74012',
        '74014',
        '74015',
        '74016',
        '74017',
        '74019',
        '74020',
        '74021',
        '74022',
        '74023',
        '74026',
        '74027',
        '74028',
        '74029',
        '74030',
        '74032',
        '74033',
        '74034',
        '74035',
        '74036',
        '74037',
        '74038',
        '74039',
        '74041',
        '74042',
        '74044',
        '74045',
        '74046',
        '74047',
        '74048',
        '74050',
        '74051',
        '74052',
        '74053',
        '74054',
        '74055',
        '74056',
        '74058',
        '74059',
        '74060',
        '74061',
        '74062',
        '74063',
        '74066',
        '74068',
        '74070',
        '74071',
        '74072',
        '74073',
        '74074',
        '74075',
        '74078',
        '74079',
        '74080',
        '74081',
        '74082',
        '74083',
        '74084',
        '74085',
        '74103',
        '74104',
        '74105',
        '74106',
        '74107',
        '74108',
        '74110',
        '74112',
        '74114',
        '74115',
        '74116',
        '74117',
        '74119',
        '74120',
        '74126',
        '74127',
        '74128',
        '74129',
        '74130',
        '74131',
        '74132',
        '74133',
        '74134',
        '74135',
        '74136',
        '74137',
        '74145',
        '74146',
        '74301',
        '74330',
        '74331',
        '74332',
        '74333',
        '74337',
        '74338',
        '74339',
        '74340',
        '74342',
        '74343',
        '74344',
        '74346',
        '74347',
        '74349',
        '74350',
        '74352',
        '74354',
        '74358',
        '74359',
        '74360',
        '74361',
        '74363',
        '74364',
        '74365',
        '74366',
        '74367',
        '74368',
        '74369',
        '74370',
        '74401',
        '74403',
        '74421',
        '74422',
        '74423',
        '74425',
        '74426',
        '74427',
        '74428',
        '74429',
        '74430',
        '74431',
        '74432',
        '74434',
        '74435',
        '74436',
        '74437',
        '74438',
        '74441',
        '74442',
        '74445',
        '74446',
        '74447',
        '74450',
        '74451',
        '74452',
        '74454',
        '74455',
        '74456',
        '74457',
        '74458',
        '74459',
        '74460',
        '74462',
        '74463',
        '74464',
        '74467',
        '74468',
        '74469',
        '74470',
        '74471',
        '74472',
        '74477',
        '74501',
        '74521',
        '74522',
        '74523',
        '74525',
        '74528',
        '74530',
        '74531',
        '74533',
        '74534',
        '74535',
        '74536',
        '74538',
        '74540',
        '74543',
        '74546',
        '74547',
        '74549',
        '74552',
        '74553',
        '74554',
        '74555',
        '74556',
        '74557',
        '74558',
        '74560',
        '74561',
        '74562',
        '74563',
        '74565',
        '74567',
        '74569',
        '74570',
        '74571',
        '74572',
        '74574',
        '74576',
        '74577',
        '74578',
        '74601',
        '74604',
        '74630',
        '74631',
        '74632',
        '74633',
        '74636',
        '74637',
        '74640',
        '74641',
        '74643',
        '74644',
        '74646',
        '74647',
        '74650',
        '74651',
        '74652',
        '74653',
        '74701',
        '74720',
        '74722',
        '74723',
        '74724',
        '74726',
        '74727',
        '74728',
        '74729',
        '74730',
        '74731',
        '74733',
        '74734',
        '74735',
        '74736',
        '74738',
        '74740',
        '74741',
        '74743',
        '74745',
        '74747',
        '74748',
        '74750',
        '74753',
        '74754',
        '74755',
        '74756',
        '74759',
        '74760',
        '74761',
        '74764',
        '74766',
        '74801',
        '74804',
        '74820',
        '74824',
        '74825',
        '74826',
        '74827',
        '74829',
        '74830',
        '74831',
        '74832',
        '74833',
        '74834',
        '74836',
        '74837',
        '74839',
        '74840',
        '74842',
        '74843',
        '74844',
        '74845',
        '74848',
        '74849',
        '74850',
        '74851',
        '74852',
        '74854',
        '74855',
        '74856',
        '74857',
        '74859',
        '74860',
        '74864',
        '74865',
        '74867',
        '74868',
        '74869',
        '74871',
        '74872',
        '74873',
        '74875',
        '74878',
        '74880',
        '74881',
        '74883',
        '74884',
        '74901',
        '74902',
        '74930',
        '74931',
        '74932',
        '74935',
        '74936',
        '74937',
        '74939',
        '74940',
        '74941',
        '74942',
        '74943',
        '74944',
        '74945',
        '74946',
        '74948',
        '74949',
        '74951',
        '74953',
        '74954',
        '74955',
        '74956',
        '74957',
        '74959',
        '74960',
        '74962',
        '74963',
        '74964',
        '74965',
        '74966'
      ]
    },
    TX: {
      name: 'Texas',
      codes: [
        '75001',
        '75002',
        '75006',
        '75007',
        '75009',
        '75010',
        '75013',
        '75019',
        '75020',
        '75021',
        '75022',
        '75023',
        '75024',
        '75025',
        '75028',
        '75032',
        '75034',
        '75035',
        '75038',
        '75039',
        '75040',
        '75041',
        '75042',
        '75043',
        '75044',
        '75048',
        '75050',
        '75051',
        '75052',
        '75054',
        '75056',
        '75057',
        '75058',
        '75060',
        '75061',
        '75062',
        '75063',
        '75065',
        '75067',
        '75068',
        '75069',
        '75070',
        '75071',
        '75074',
        '75075',
        '75076',
        '75077',
        '75078',
        '75080',
        '75081',
        '75082',
        '75087',
        '75088',
        '75089',
        '75090',
        '75092',
        '75093',
        '75094',
        '75098',
        '75101',
        '75102',
        '75103',
        '75104',
        '75105',
        '75109',
        '75110',
        '75114',
        '75115',
        '75116',
        '75117',
        '75119',
        '75124',
        '75125',
        '75126',
        '75127',
        '75132',
        '75134',
        '75135',
        '75137',
        '75140',
        '75141',
        '75142',
        '75143',
        '75144',
        '75146',
        '75147',
        '75148',
        '75149',
        '75150',
        '75152',
        '75153',
        '75154',
        '75155',
        '75156',
        '75157',
        '75158',
        '75159',
        '75160',
        '75161',
        '75163',
        '75164',
        '75165',
        '75166',
        '75167',
        '75169',
        '75172',
        '75173',
        '75180',
        '75181',
        '75182',
        '75189',
        '75201',
        '75202',
        '75203',
        '75204',
        '75205',
        '75206',
        '75207',
        '75208',
        '75209',
        '75210',
        '75211',
        '75212',
        '75214',
        '75215',
        '75216',
        '75217',
        '75218',
        '75219',
        '75220',
        '75223',
        '75224',
        '75225',
        '75226',
        '75227',
        '75228',
        '75229',
        '75230',
        '75231',
        '75232',
        '75233',
        '75234',
        '75235',
        '75236',
        '75237',
        '75238',
        '75240',
        '75241',
        '75243',
        '75244',
        '75246',
        '75247',
        '75248',
        '75249',
        '75251',
        '75252',
        '75253',
        '75254',
        '75270',
        '75287',
        '75390',
        '75401',
        '75402',
        '75407',
        '75409',
        '75410',
        '75411',
        '75412',
        '75413',
        '75414',
        '75415',
        '75416',
        '75417',
        '75418',
        '75420',
        '75421',
        '75422',
        '75423',
        '75424',
        '75426',
        '75428',
        '75431',
        '75432',
        '75433',
        '75435',
        '75436',
        '75437',
        '75438',
        '75439',
        '75440',
        '75441',
        '75442',
        '75446',
        '75447',
        '75448',
        '75449',
        '75450',
        '75451',
        '75452',
        '75453',
        '75454',
        '75455',
        '75457',
        '75459',
        '75460',
        '75462',
        '75468',
        '75469',
        '75470',
        '75471',
        '75472',
        '75473',
        '75474',
        '75475',
        '75476',
        '75477',
        '75478',
        '75479',
        '75480',
        '75481',
        '75482',
        '75486',
        '75487',
        '75488',
        '75489',
        '75490',
        '75491',
        '75492',
        '75493',
        '75494',
        '75495',
        '75496',
        '75497',
        '75501',
        '75503',
        '75550',
        '75551',
        '75554',
        '75555',
        '75556',
        '75558',
        '75559',
        '75560',
        '75561',
        '75562',
        '75563',
        '75565',
        '75566',
        '75567',
        '75568',
        '75569',
        '75570',
        '75571',
        '75572',
        '75573',
        '75574',
        '75601',
        '75602',
        '75603',
        '75604',
        '75605',
        '75630',
        '75631',
        '75633',
        '75638',
        '75639',
        '75640',
        '75641',
        '75642',
        '75643',
        '75644',
        '75645',
        '75647',
        '75650',
        '75651',
        '75652',
        '75654',
        '75656',
        '75657',
        '75661',
        '75662',
        '75667',
        '75668',
        '75669',
        '75670',
        '75672',
        '75681',
        '75682',
        '75683',
        '75684',
        '75686',
        '75691',
        '75692',
        '75693',
        '75701',
        '75702',
        '75703',
        '75704',
        '75705',
        '75706',
        '75707',
        '75708',
        '75709',
        '75750',
        '75751',
        '75752',
        '75754',
        '75755',
        '75756',
        '75757',
        '75758',
        '75759',
        '75760',
        '75762',
        '75763',
        '75764',
        '75765',
        '75766',
        '75770',
        '75771',
        '75773',
        '75778',
        '75779',
        '75780',
        '75783',
        '75784',
        '75785',
        '75788',
        '75789',
        '75790',
        '75791',
        '75792',
        '75801',
        '75803',
        '75831',
        '75832',
        '75833',
        '75835',
        '75838',
        '75839',
        '75840',
        '75844',
        '75845',
        '75846',
        '75847',
        '75848',
        '75849',
        '75850',
        '75851',
        '75852',
        '75853',
        '75855',
        '75856',
        '75858',
        '75859',
        '75860',
        '75861',
        '75862',
        '75901',
        '75904',
        '75925',
        '75926',
        '75928',
        '75929',
        '75930',
        '75931',
        '75932',
        '75933',
        '75934',
        '75935',
        '75936',
        '75937',
        '75938',
        '75939',
        '75941',
        '75942',
        '75943',
        '75944',
        '75946',
        '75948',
        '75949',
        '75951',
        '75954',
        '75956',
        '75959',
        '75960',
        '75961',
        '75962',
        '75964',
        '75965',
        '75966',
        '75968',
        '75969',
        '75972',
        '75973',
        '75974',
        '75975',
        '75976',
        '75977',
        '75978',
        '75979',
        '75980',
        '76001',
        '76002',
        '76006',
        '76008',
        '76009',
        '76010',
        '76011',
        '76012',
        '76013',
        '76014',
        '76015',
        '76016',
        '76017',
        '76018',
        '76020',
        '76021',
        '76022',
        '76023',
        '76028',
        '76031',
        '76033',
        '76034',
        '76035',
        '76036',
        '76039',
        '76040',
        '76041',
        '76043',
        '76044',
        '76048',
        '76049',
        '76050',
        '76051',
        '76052',
        '76053',
        '76054',
        '76055',
        '76058',
        '76059',
        '76060',
        '76061',
        '76063',
        '76064',
        '76065',
        '76066',
        '76067',
        '76070',
        '76071',
        '76073',
        '76077',
        '76078',
        '76082',
        '76084',
        '76085',
        '76086',
        '76087',
        '76088',
        '76092',
        '76093',
        '76102',
        '76103',
        '76104',
        '76105',
        '76106',
        '76107',
        '76108',
        '76109',
        '76110',
        '76111',
        '76112',
        '76114',
        '76115',
        '76116',
        '76117',
        '76118',
        '76119',
        '76120',
        '76123',
        '76126',
        '76127',
        '76129',
        '76131',
        '76132',
        '76133',
        '76134',
        '76135',
        '76137',
        '76140',
        '76148',
        '76155',
        '76164',
        '76177',
        '76179',
        '76180',
        '76182',
        '76201',
        '76205',
        '76207',
        '76208',
        '76209',
        '76210',
        '76225',
        '76226',
        '76227',
        '76228',
        '76230',
        '76233',
        '76234',
        '76238',
        '76239',
        '76240',
        '76244',
        '76245',
        '76247',
        '76248',
        '76249',
        '76250',
        '76251',
        '76252',
        '76253',
        '76255',
        '76258',
        '76259',
        '76261',
        '76262',
        '76263',
        '76264',
        '76265',
        '76266',
        '76268',
        '76270',
        '76271',
        '76272',
        '76273',
        '76301',
        '76302',
        '76305',
        '76306',
        '76308',
        '76309',
        '76310',
        '76311',
        '76351',
        '76354',
        '76357',
        '76360',
        '76363',
        '76364',
        '76365',
        '76366',
        '76367',
        '76370',
        '76371',
        '76372',
        '76373',
        '76374',
        '76377',
        '76379',
        '76380',
        '76384',
        '76388',
        '76389',
        '76401',
        '76402',
        '76424',
        '76426',
        '76427',
        '76429',
        '76430',
        '76431',
        '76432',
        '76433',
        '76435',
        '76436',
        '76437',
        '76442',
        '76443',
        '76444',
        '76445',
        '76446',
        '76448',
        '76449',
        '76450',
        '76452',
        '76453',
        '76454',
        '76455',
        '76457',
        '76458',
        '76459',
        '76460',
        '76462',
        '76463',
        '76464',
        '76466',
        '76469',
        '76470',
        '76471',
        '76472',
        '76474',
        '76475',
        '76476',
        '76481',
        '76483',
        '76484',
        '76486',
        '76487',
        '76490',
        '76491',
        '76501',
        '76502',
        '76504',
        '76508',
        '76511',
        '76513',
        '76518',
        '76519',
        '76520',
        '76522',
        '76523',
        '76524',
        '76525',
        '76527',
        '76528',
        '76530',
        '76531',
        '76534',
        '76537',
        '76538',
        '76539',
        '76541',
        '76542',
        '76543',
        '76544',
        '76548',
        '76549',
        '76550',
        '76554',
        '76556',
        '76557',
        '76559',
        '76561',
        '76565',
        '76566',
        '76567',
        '76569',
        '76570',
        '76571',
        '76573',
        '76574',
        '76577',
        '76578',
        '76579',
        '76596',
        '76597',
        '76598',
        '76599',
        '76621',
        '76622',
        '76623',
        '76624',
        '76626',
        '76627',
        '76628',
        '76629',
        '76630',
        '76631',
        '76632',
        '76633',
        '76634',
        '76635',
        '76636',
        '76637',
        '76638',
        '76639',
        '76640',
        '76641',
        '76642',
        '76643',
        '76645',
        '76648',
        '76649',
        '76650',
        '76651',
        '76652',
        '76653',
        '76654',
        '76655',
        '76656',
        '76657',
        '76660',
        '76661',
        '76664',
        '76665',
        '76666',
        '76667',
        '76670',
        '76671',
        '76673',
        '76676',
        '76678',
        '76679',
        '76680',
        '76681',
        '76682',
        '76685',
        '76686',
        '76687',
        '76689',
        '76690',
        '76691',
        '76692',
        '76693',
        '76701',
        '76704',
        '76705',
        '76706',
        '76707',
        '76708',
        '76710',
        '76711',
        '76712',
        '76798',
        '76801',
        '76802',
        '76820',
        '76821',
        '76823',
        '76824',
        '76825',
        '76827',
        '76828',
        '76831',
        '76832',
        '76834',
        '76836',
        '76837',
        '76841',
        '76842',
        '76844',
        '76845',
        '76848',
        '76849',
        '76852',
        '76853',
        '76854',
        '76856',
        '76857',
        '76858',
        '76859',
        '76861',
        '76862',
        '76864',
        '76865',
        '76866',
        '76869',
        '76870',
        '76871',
        '76872',
        '76873',
        '76874',
        '76875',
        '76877',
        '76878',
        '76882',
        '76884',
        '76885',
        '76887',
        '76888',
        '76890',
        '76901',
        '76903',
        '76904',
        '76905',
        '76908',
        '76930',
        '76932',
        '76933',
        '76934',
        '76935',
        '76936',
        '76937',
        '76939',
        '76940',
        '76941',
        '76943',
        '76945',
        '76949',
        '76950',
        '76951',
        '76953',
        '76955',
        '76957',
        '76958',
        '77002',
        '77003',
        '77004',
        '77005',
        '77006',
        '77007',
        '77008',
        '77009',
        '77010',
        '77011',
        '77012',
        '77013',
        '77014',
        '77015',
        '77016',
        '77017',
        '77018',
        '77019',
        '77020',
        '77021',
        '77022',
        '77023',
        '77024',
        '77025',
        '77026',
        '77027',
        '77028',
        '77029',
        '77030',
        '77031',
        '77032',
        '77033',
        '77034',
        '77035',
        '77036',
        '77037',
        '77038',
        '77039',
        '77040',
        '77041',
        '77042',
        '77043',
        '77044',
        '77045',
        '77046',
        '77047',
        '77048',
        '77049',
        '77050',
        '77051',
        '77053',
        '77054',
        '77055',
        '77056',
        '77057',
        '77058',
        '77059',
        '77060',
        '77061',
        '77062',
        '77063',
        '77064',
        '77065',
        '77066',
        '77067',
        '77068',
        '77069',
        '77070',
        '77071',
        '77072',
        '77073',
        '77074',
        '77075',
        '77076',
        '77077',
        '77078',
        '77079',
        '77080',
        '77081',
        '77082',
        '77083',
        '77084',
        '77085',
        '77086',
        '77087',
        '77088',
        '77089',
        '77090',
        '77091',
        '77092',
        '77093',
        '77094',
        '77095',
        '77096',
        '77098',
        '77099',
        '77201',
        '77301',
        '77302',
        '77303',
        '77304',
        '77306',
        '77316',
        '77318',
        '77320',
        '77326',
        '77327',
        '77328',
        '77331',
        '77334',
        '77335',
        '77336',
        '77338',
        '77339',
        '77340',
        '77342',
        '77345',
        '77346',
        '77350',
        '77351',
        '77354',
        '77355',
        '77356',
        '77357',
        '77358',
        '77359',
        '77360',
        '77362',
        '77363',
        '77364',
        '77365',
        '77367',
        '77368',
        '77369',
        '77371',
        '77372',
        '77373',
        '77374',
        '77375',
        '77376',
        '77377',
        '77378',
        '77379',
        '77380',
        '77381',
        '77382',
        '77384',
        '77385',
        '77386',
        '77388',
        '77389',
        '77396',
        '77401',
        '77406',
        '77407',
        '77412',
        '77414',
        '77415',
        '77417',
        '77418',
        '77419',
        '77420',
        '77422',
        '77423',
        '77426',
        '77428',
        '77429',
        '77430',
        '77432',
        '77433',
        '77434',
        '77435',
        '77436',
        '77437',
        '77440',
        '77441',
        '77442',
        '77443',
        '77444',
        '77445',
        '77446',
        '77447',
        '77448',
        '77449',
        '77450',
        '77451',
        '77453',
        '77454',
        '77455',
        '77456',
        '77457',
        '77458',
        '77459',
        '77460',
        '77461',
        '77464',
        '77465',
        '77466',
        '77467',
        '77468',
        '77469',
        '77470',
        '77471',
        '77473',
        '77474',
        '77475',
        '77476',
        '77477',
        '77478',
        '77479',
        '77480',
        '77481',
        '77482',
        '77483',
        '77484',
        '77485',
        '77486',
        '77488',
        '77489',
        '77493',
        '77494',
        '77498',
        '77502',
        '77503',
        '77504',
        '77505',
        '77506',
        '77507',
        '77510',
        '77511',
        '77514',
        '77515',
        '77517',
        '77518',
        '77519',
        '77520',
        '77521',
        '77523',
        '77530',
        '77531',
        '77532',
        '77533',
        '77534',
        '77535',
        '77536',
        '77538',
        '77539',
        '77541',
        '77545',
        '77546',
        '77547',
        '77550',
        '77551',
        '77554',
        '77560',
        '77561',
        '77562',
        '77563',
        '77564',
        '77565',
        '77566',
        '77568',
        '77571',
        '77573',
        '77575',
        '77577',
        '77578',
        '77580',
        '77581',
        '77583',
        '77584',
        '77585',
        '77586',
        '77587',
        '77590',
        '77591',
        '77597',
        '77598',
        '77611',
        '77612',
        '77613',
        '77614',
        '77615',
        '77616',
        '77617',
        '77619',
        '77622',
        '77623',
        '77624',
        '77625',
        '77627',
        '77629',
        '77630',
        '77632',
        '77640',
        '77642',
        '77650',
        '77651',
        '77655',
        '77656',
        '77657',
        '77659',
        '77660',
        '77661',
        '77662',
        '77663',
        '77664',
        '77665',
        '77701',
        '77702',
        '77703',
        '77705',
        '77706',
        '77707',
        '77708',
        '77713',
        '77801',
        '77802',
        '77803',
        '77807',
        '77808',
        '77830',
        '77831',
        '77833',
        '77835',
        '77836',
        '77837',
        '77840',
        '77845',
        '77853',
        '77855',
        '77856',
        '77857',
        '77859',
        '77861',
        '77864',
        '77865',
        '77867',
        '77868',
        '77871',
        '77872',
        '77873',
        '77876',
        '77878',
        '77879',
        '77880',
        '77901',
        '77904',
        '77905',
        '77950',
        '77951',
        '77954',
        '77957',
        '77960',
        '77961',
        '77962',
        '77963',
        '77964',
        '77968',
        '77969',
        '77970',
        '77971',
        '77973',
        '77974',
        '77975',
        '77976',
        '77977',
        '77978',
        '77979',
        '77982',
        '77983',
        '77984',
        '77987',
        '77988',
        '77990',
        '77991',
        '77993',
        '77994',
        '77995',
        '78001',
        '78002',
        '78003',
        '78004',
        '78005',
        '78006',
        '78007',
        '78008',
        '78009',
        '78010',
        '78011',
        '78012',
        '78013',
        '78014',
        '78015',
        '78016',
        '78017',
        '78019',
        '78021',
        '78022',
        '78023',
        '78024',
        '78025',
        '78026',
        '78027',
        '78028',
        '78029',
        '78039',
        '78040',
        '78041',
        '78043',
        '78044',
        '78045',
        '78046',
        '78050',
        '78052',
        '78055',
        '78056',
        '78057',
        '78058',
        '78059',
        '78060',
        '78061',
        '78063',
        '78064',
        '78065',
        '78066',
        '78067',
        '78069',
        '78070',
        '78071',
        '78072',
        '78073',
        '78075',
        '78076',
        '78101',
        '78102',
        '78104',
        '78107',
        '78108',
        '78109',
        '78112',
        '78113',
        '78114',
        '78116',
        '78117',
        '78118',
        '78119',
        '78121',
        '78122',
        '78123',
        '78124',
        '78125',
        '78130',
        '78132',
        '78133',
        '78140',
        '78141',
        '78142',
        '78143',
        '78144',
        '78145',
        '78146',
        '78147',
        '78148',
        '78150',
        '78151',
        '78152',
        '78154',
        '78155',
        '78159',
        '78160',
        '78161',
        '78162',
        '78163',
        '78164',
        '78201',
        '78202',
        '78203',
        '78204',
        '78205',
        '78207',
        '78208',
        '78209',
        '78210',
        '78211',
        '78212',
        '78213',
        '78214',
        '78215',
        '78216',
        '78217',
        '78218',
        '78219',
        '78220',
        '78221',
        '78222',
        '78223',
        '78224',
        '78225',
        '78226',
        '78227',
        '78228',
        '78229',
        '78230',
        '78231',
        '78232',
        '78233',
        '78234',
        '78235',
        '78236',
        '78237',
        '78238',
        '78239',
        '78240',
        '78242',
        '78243',
        '78244',
        '78245',
        '78247',
        '78248',
        '78249',
        '78250',
        '78251',
        '78252',
        '78253',
        '78254',
        '78255',
        '78256',
        '78257',
        '78258',
        '78259',
        '78260',
        '78261',
        '78263',
        '78264',
        '78266',
        '78330',
        '78332',
        '78335',
        '78336',
        '78338',
        '78339',
        '78340',
        '78341',
        '78342',
        '78343',
        '78344',
        '78349',
        '78351',
        '78352',
        '78353',
        '78355',
        '78357',
        '78358',
        '78359',
        '78361',
        '78362',
        '78363',
        '78368',
        '78369',
        '78370',
        '78371',
        '78372',
        '78373',
        '78374',
        '78375',
        '78376',
        '78377',
        '78379',
        '78380',
        '78382',
        '78383',
        '78384',
        '78385',
        '78387',
        '78389',
        '78390',
        '78391',
        '78393',
        '78401',
        '78402',
        '78404',
        '78405',
        '78406',
        '78407',
        '78408',
        '78409',
        '78410',
        '78411',
        '78412',
        '78413',
        '78414',
        '78415',
        '78416',
        '78417',
        '78418',
        '78419',
        '78501',
        '78503',
        '78504',
        '78516',
        '78520',
        '78521',
        '78526',
        '78535',
        '78536',
        '78537',
        '78538',
        '78539',
        '78541',
        '78542',
        '78543',
        '78545',
        '78548',
        '78549',
        '78550',
        '78552',
        '78557',
        '78558',
        '78559',
        '78560',
        '78561',
        '78562',
        '78563',
        '78564',
        '78565',
        '78566',
        '78567',
        '78569',
        '78570',
        '78572',
        '78573',
        '78574',
        '78575',
        '78576',
        '78577',
        '78578',
        '78579',
        '78580',
        '78582',
        '78583',
        '78584',
        '78585',
        '78586',
        '78588',
        '78589',
        '78590',
        '78591',
        '78592',
        '78593',
        '78594',
        '78595',
        '78596',
        '78597',
        '78598',
        '78602',
        '78605',
        '78606',
        '78607',
        '78608',
        '78609',
        '78610',
        '78611',
        '78612',
        '78613',
        '78614',
        '78615',
        '78616',
        '78617',
        '78618',
        '78619',
        '78620',
        '78621',
        '78622',
        '78623',
        '78624',
        '78626',
        '78628',
        '78629',
        '78631',
        '78632',
        '78633',
        '78634',
        '78635',
        '78636',
        '78638',
        '78639',
        '78640',
        '78641',
        '78642',
        '78643',
        '78644',
        '78645',
        '78648',
        '78650',
        '78652',
        '78653',
        '78654',
        '78655',
        '78656',
        '78657',
        '78659',
        '78660',
        '78661',
        '78662',
        '78663',
        '78664',
        '78665',
        '78666',
        '78669',
        '78670',
        '78671',
        '78672',
        '78675',
        '78676',
        '78677',
        '78681',
        '78701',
        '78702',
        '78703',
        '78704',
        '78705',
        '78712',
        '78717',
        '78719',
        '78721',
        '78722',
        '78723',
        '78724',
        '78725',
        '78726',
        '78727',
        '78728',
        '78729',
        '78730',
        '78731',
        '78732',
        '78733',
        '78734',
        '78735',
        '78736',
        '78737',
        '78738',
        '78739',
        '78741',
        '78742',
        '78744',
        '78745',
        '78746',
        '78747',
        '78748',
        '78749',
        '78750',
        '78751',
        '78752',
        '78753',
        '78754',
        '78756',
        '78757',
        '78758',
        '78759',
        '78801',
        '78802',
        '78827',
        '78828',
        '78829',
        '78830',
        '78832',
        '78833',
        '78834',
        '78836',
        '78837',
        '78838',
        '78839',
        '78840',
        '78843',
        '78850',
        '78851',
        '78852',
        '78860',
        '78861',
        '78870',
        '78871',
        '78872',
        '78873',
        '78877',
        '78879',
        '78880',
        '78881',
        '78883',
        '78884',
        '78885',
        '78886',
        '78931',
        '78932',
        '78933',
        '78934',
        '78935',
        '78938',
        '78940',
        '78941',
        '78942',
        '78943',
        '78944',
        '78945',
        '78946',
        '78947',
        '78948',
        '78949',
        '78950',
        '78951',
        '78953',
        '78954',
        '78956',
        '78957',
        '78959',
        '78962',
        '78963',
        '79001',
        '79003',
        '79005',
        '79007',
        '79009',
        '79010',
        '79011',
        '79012',
        '79013',
        '79014',
        '79015',
        '79016',
        '79018',
        '79019',
        '79021',
        '79022',
        '79024',
        '79025',
        '79027',
        '79029',
        '79031',
        '79032',
        '79033',
        '79034',
        '79035',
        '79036',
        '79039',
        '79040',
        '79041',
        '79042',
        '79043',
        '79044',
        '79045',
        '79046',
        '79051',
        '79052',
        '79053',
        '79054',
        '79056',
        '79057',
        '79058',
        '79059',
        '79061',
        '79062',
        '79063',
        '79064',
        '79065',
        '79068',
        '79070',
        '79072',
        '79078',
        '79079',
        '79080',
        '79081',
        '79082',
        '79083',
        '79084',
        '79085',
        '79086',
        '79087',
        '79088',
        '79091',
        '79092',
        '79093',
        '79094',
        '79095',
        '79096',
        '79097',
        '79098',
        '79101',
        '79102',
        '79103',
        '79104',
        '79105',
        '79106',
        '79107',
        '79108',
        '79109',
        '79110',
        '79111',
        '79118',
        '79119',
        '79121',
        '79124',
        '79201',
        '79220',
        '79225',
        '79226',
        '79227',
        '79229',
        '79230',
        '79231',
        '79233',
        '79234',
        '79235',
        '79236',
        '79237',
        '79239',
        '79240',
        '79241',
        '79243',
        '79244',
        '79245',
        '79247',
        '79248',
        '79250',
        '79251',
        '79252',
        '79255',
        '79256',
        '79257',
        '79258',
        '79259',
        '79261',
        '79311',
        '79312',
        '79313',
        '79314',
        '79316',
        '79322',
        '79323',
        '79324',
        '79325',
        '79326',
        '79329',
        '79330',
        '79331',
        '79336',
        '79339',
        '79342',
        '79343',
        '79344',
        '79345',
        '79346',
        '79347',
        '79350',
        '79351',
        '79353',
        '79355',
        '79356',
        '79357',
        '79358',
        '79359',
        '79360',
        '79363',
        '79364',
        '79366',
        '79367',
        '79369',
        '79370',
        '79371',
        '79372',
        '79373',
        '79376',
        '79377',
        '79378',
        '79379',
        '79380',
        '79381',
        '79382',
        '79401',
        '79403',
        '79404',
        '79406',
        '79407',
        '79410',
        '79411',
        '79412',
        '79413',
        '79414',
        '79415',
        '79416',
        '79423',
        '79424',
        '79501',
        '79502',
        '79503',
        '79504',
        '79505',
        '79506',
        '79508',
        '79510',
        '79511',
        '79512',
        '79517',
        '79518',
        '79519',
        '79520',
        '79521',
        '79525',
        '79526',
        '79527',
        '79528',
        '79529',
        '79530',
        '79532',
        '79533',
        '79534',
        '79535',
        '79536',
        '79537',
        '79538',
        '79539',
        '79540',
        '79541',
        '79543',
        '79544',
        '79545',
        '79546',
        '79547',
        '79548',
        '79549',
        '79553',
        '79556',
        '79560',
        '79561',
        '79562',
        '79563',
        '79565',
        '79566',
        '79567',
        '79601',
        '79602',
        '79603',
        '79605',
        '79606',
        '79607',
        '79699',
        '79701',
        '79703',
        '79705',
        '79706',
        '79707',
        '79713',
        '79714',
        '79718',
        '79719',
        '79720',
        '79730',
        '79731',
        '79733',
        '79734',
        '79735',
        '79738',
        '79739',
        '79741',
        '79742',
        '79743',
        '79744',
        '79745',
        '79748',
        '79749',
        '79752',
        '79754',
        '79755',
        '79756',
        '79758',
        '79759',
        '79761',
        '79762',
        '79763',
        '79764',
        '79765',
        '79766',
        '79770',
        '79772',
        '79777',
        '79778',
        '79780',
        '79781',
        '79782',
        '79783',
        '79785',
        '79788',
        '79789',
        '79821',
        '79830',
        '79831',
        '79834',
        '79835',
        '79836',
        '79837',
        '79838',
        '79839',
        '79842',
        '79843',
        '79845',
        '79846',
        '79847',
        '79848',
        '79849',
        '79851',
        '79852',
        '79853',
        '79854',
        '79855',
        '79901',
        '79902',
        '79903',
        '79904',
        '79905',
        '79906',
        '79907',
        '79908',
        '79911',
        '79912',
        '79915',
        '79916',
        '79920',
        '79922',
        '79924',
        '79925',
        '79927',
        '79928',
        '79930',
        '79932',
        '79934',
        '79935',
        '79936',
        '79938',
        '79942'
      ]
    },
    CO: {
      name: 'Colorado',
      codes: [
        '80002',
        '80003',
        '80004',
        '80005',
        '80007',
        '80010',
        '80011',
        '80012',
        '80013',
        '80014',
        '80015',
        '80016',
        '80017',
        '80018',
        '80019',
        '80020',
        '80021',
        '80022',
        '80023',
        '80024',
        '80025',
        '80026',
        '80027',
        '80030',
        '80031',
        '80033',
        '80045',
        '80101',
        '80102',
        '80103',
        '80104',
        '80105',
        '80106',
        '80107',
        '80108',
        '80109',
        '80110',
        '80111',
        '80112',
        '80113',
        '80116',
        '80117',
        '80118',
        '80120',
        '80121',
        '80122',
        '80123',
        '80124',
        '80125',
        '80126',
        '80127',
        '80128',
        '80129',
        '80130',
        '80131',
        '80132',
        '80133',
        '80134',
        '80135',
        '80136',
        '80137',
        '80138',
        '80202',
        '80203',
        '80204',
        '80205',
        '80206',
        '80207',
        '80209',
        '80210',
        '80211',
        '80212',
        '80214',
        '80215',
        '80216',
        '80218',
        '80219',
        '80220',
        '80221',
        '80222',
        '80223',
        '80224',
        '80226',
        '80227',
        '80228',
        '80229',
        '80230',
        '80231',
        '80232',
        '80233',
        '80234',
        '80235',
        '80236',
        '80237',
        '80238',
        '80239',
        '80241',
        '80246',
        '80247',
        '80249',
        '80260',
        '80264',
        '80290',
        '80293',
        '80294',
        '80301',
        '80302',
        '80303',
        '80304',
        '80305',
        '80310',
        '80401',
        '80403',
        '80419',
        '80420',
        '80421',
        '80422',
        '80423',
        '80424',
        '80425',
        '80426',
        '80427',
        '80428',
        '80432',
        '80433',
        '80434',
        '80435',
        '80436',
        '80438',
        '80439',
        '80440',
        '80442',
        '80443',
        '80444',
        '80446',
        '80447',
        '80448',
        '80449',
        '80451',
        '80452',
        '80453',
        '80454',
        '80455',
        '80456',
        '80457',
        '80459',
        '80461',
        '80463',
        '80465',
        '80466',
        '80467',
        '80468',
        '80469',
        '80470',
        '80471',
        '80473',
        '80475',
        '80476',
        '80477',
        '80478',
        '80479',
        '80480',
        '80481',
        '80482',
        '80483',
        '80487',
        '80488',
        '80497',
        '80498',
        '80501',
        '80503',
        '80504',
        '80510',
        '80511',
        '80512',
        '80513',
        '80514',
        '80515',
        '80516',
        '80517',
        '80520',
        '80521',
        '80524',
        '80525',
        '80526',
        '80528',
        '80530',
        '80532',
        '80534',
        '80535',
        '80536',
        '80537',
        '80538',
        '80540',
        '80542',
        '80543',
        '80544',
        '80545',
        '80546',
        '80547',
        '80549',
        '80550',
        '80601',
        '80602',
        '80603',
        '80610',
        '80611',
        '80612',
        '80615',
        '80620',
        '80621',
        '80622',
        '80623',
        '80624',
        '80631',
        '80634',
        '80640',
        '80642',
        '80643',
        '80644',
        '80645',
        '80648',
        '80649',
        '80650',
        '80651',
        '80652',
        '80653',
        '80654',
        '80701',
        '80705',
        '80720',
        '80721',
        '80722',
        '80723',
        '80726',
        '80727',
        '80728',
        '80729',
        '80731',
        '80733',
        '80734',
        '80735',
        '80736',
        '80737',
        '80740',
        '80741',
        '80742',
        '80743',
        '80744',
        '80745',
        '80746',
        '80747',
        '80749',
        '80750',
        '80751',
        '80754',
        '80755',
        '80757',
        '80758',
        '80759',
        '80801',
        '80802',
        '80804',
        '80805',
        '80807',
        '80808',
        '80809',
        '80810',
        '80812',
        '80813',
        '80814',
        '80815',
        '80816',
        '80817',
        '80818',
        '80819',
        '80820',
        '80821',
        '80822',
        '80823',
        '80824',
        '80825',
        '80827',
        '80828',
        '80829',
        '80830',
        '80831',
        '80832',
        '80833',
        '80834',
        '80835',
        '80836',
        '80840',
        '80860',
        '80861',
        '80862',
        '80863',
        '80864',
        '80902',
        '80903',
        '80904',
        '80905',
        '80906',
        '80907',
        '80908',
        '80909',
        '80910',
        '80911',
        '80913',
        '80914',
        '80915',
        '80916',
        '80917',
        '80918',
        '80919',
        '80920',
        '80921',
        '80922',
        '80923',
        '80924',
        '80925',
        '80926',
        '80927',
        '80928',
        '80929',
        '80930',
        '80938',
        '80939',
        '80951',
        '81001',
        '81003',
        '81004',
        '81005',
        '81006',
        '81007',
        '81008',
        '81019',
        '81020',
        '81021',
        '81022',
        '81023',
        '81024',
        '81025',
        '81027',
        '81029',
        '81030',
        '81033',
        '81036',
        '81038',
        '81039',
        '81040',
        '81041',
        '81043',
        '81044',
        '81045',
        '81047',
        '81049',
        '81050',
        '81052',
        '81054',
        '81055',
        '81057',
        '81058',
        '81059',
        '81062',
        '81063',
        '81064',
        '81067',
        '81069',
        '81071',
        '81073',
        '81076',
        '81077',
        '81081',
        '81082',
        '81084',
        '81087',
        '81089',
        '81090',
        '81091',
        '81092',
        '81101',
        '81120',
        '81121',
        '81122',
        '81123',
        '81124',
        '81125',
        '81126',
        '81128',
        '81129',
        '81130',
        '81131',
        '81132',
        '81133',
        '81136',
        '81137',
        '81138',
        '81140',
        '81141',
        '81143',
        '81144',
        '81146',
        '81147',
        '81148',
        '81149',
        '81151',
        '81152',
        '81154',
        '81155',
        '81201',
        '81210',
        '81211',
        '81212',
        '81220',
        '81221',
        '81222',
        '81223',
        '81224',
        '81225',
        '81226',
        '81227',
        '81230',
        '81231',
        '81232',
        '81233',
        '81235',
        '81236',
        '81237',
        '81239',
        '81240',
        '81241',
        '81242',
        '81243',
        '81244',
        '81248',
        '81251',
        '81252',
        '81253',
        '81301',
        '81303',
        '81320',
        '81321',
        '81323',
        '81324',
        '81325',
        '81326',
        '81327',
        '81328',
        '81330',
        '81331',
        '81332',
        '81334',
        '81335',
        '81401',
        '81403',
        '81410',
        '81411',
        '81413',
        '81415',
        '81416',
        '81418',
        '81419',
        '81422',
        '81423',
        '81424',
        '81425',
        '81426',
        '81427',
        '81428',
        '81429',
        '81430',
        '81431',
        '81432',
        '81433',
        '81434',
        '81435',
        '81501',
        '81503',
        '81504',
        '81505',
        '81506',
        '81507',
        '81520',
        '81521',
        '81522',
        '81523',
        '81524',
        '81525',
        '81526',
        '81527',
        '81601',
        '81610',
        '81611',
        '81612',
        '81615',
        '81620',
        '81621',
        '81623',
        '81624',
        '81625',
        '81630',
        '81631',
        '81632',
        '81633',
        '81635',
        '81637',
        '81638',
        '81639',
        '81640',
        '81641',
        '81642',
        '81643',
        '81645',
        '81646',
        '81647',
        '81648',
        '81649',
        '81650',
        '81652',
        '81653',
        '81654',
        '81655',
        '81656',
        '81657'
      ]
    },
    WY: {
      name: 'Wyoming',
      codes: [
        '82001',
        '82005',
        '82007',
        '82009',
        '82050',
        '82051',
        '82052',
        '82053',
        '82054',
        '82055',
        '82058',
        '82059',
        '82060',
        '82061',
        '82063',
        '82070',
        '82072',
        '82073',
        '82081',
        '82082',
        '82083',
        '82084',
        '82190',
        '82201',
        '82210',
        '82212',
        '82213',
        '82214',
        '82215',
        '82217',
        '82219',
        '82221',
        '82222',
        '82223',
        '82224',
        '82225',
        '82227',
        '82229',
        '82240',
        '82242',
        '82243',
        '82244',
        '82301',
        '82321',
        '82322',
        '82323',
        '82324',
        '82325',
        '82327',
        '82329',
        '82331',
        '82332',
        '82334',
        '82335',
        '82336',
        '82401',
        '82410',
        '82411',
        '82412',
        '82414',
        '82420',
        '82421',
        '82422',
        '82423',
        '82426',
        '82428',
        '82430',
        '82431',
        '82432',
        '82433',
        '82434',
        '82435',
        '82440',
        '82441',
        '82442',
        '82443',
        '82450',
        '82501',
        '82510',
        '82512',
        '82513',
        '82514',
        '82515',
        '82516',
        '82520',
        '82523',
        '82601',
        '82604',
        '82609',
        '82620',
        '82630',
        '82633',
        '82635',
        '82636',
        '82637',
        '82638',
        '82639',
        '82640',
        '82642',
        '82643',
        '82644',
        '82646',
        '82648',
        '82649',
        '82701',
        '82710',
        '82711',
        '82712',
        '82714',
        '82715',
        '82716',
        '82718',
        '82720',
        '82721',
        '82723',
        '82725',
        '82727',
        '82729',
        '82730',
        '82731',
        '82732',
        '82801',
        '82831',
        '82832',
        '82833',
        '82834',
        '82835',
        '82836',
        '82837',
        '82838',
        '82839',
        '82842',
        '82844',
        '82845',
        '82901',
        '82922',
        '82923',
        '82925',
        '82929',
        '82930',
        '82932',
        '82933',
        '82934',
        '82935',
        '82936',
        '82937',
        '82938',
        '82939',
        '82941',
        '82942',
        '82943',
        '82944',
        '82945',
        '83001',
        '83011',
        '83012',
        '83013',
        '83014',
        '83025',
        '83101',
        '83110',
        '83111',
        '83112',
        '83113',
        '83114',
        '83115',
        '83116',
        '83118',
        '83119',
        '83120',
        '83121',
        '83122',
        '83123',
        '83124',
        '83126',
        '83127',
        '83128',
        '83414'
      ]
    },
    ID: {
      name: 'Idaho',
      codes: [
        '83201',
        '83202',
        '83203',
        '83204',
        '83209',
        '83210',
        '83211',
        '83212',
        '83213',
        '83214',
        '83215',
        '83217',
        '83218',
        '83220',
        '83221',
        '83223',
        '83226',
        '83227',
        '83228',
        '83232',
        '83233',
        '83234',
        '83235',
        '83236',
        '83237',
        '83238',
        '83239',
        '83241',
        '83243',
        '83244',
        '83245',
        '83246',
        '83250',
        '83251',
        '83252',
        '83253',
        '83254',
        '83255',
        '83261',
        '83262',
        '83263',
        '83271',
        '83272',
        '83274',
        '83276',
        '83277',
        '83278',
        '83281',
        '83283',
        '83285',
        '83286',
        '83287',
        '83301',
        '83302',
        '83311',
        '83312',
        '83313',
        '83314',
        '83316',
        '83318',
        '83320',
        '83321',
        '83322',
        '83323',
        '83324',
        '83325',
        '83327',
        '83328',
        '83330',
        '83332',
        '83333',
        '83334',
        '83335',
        '83336',
        '83337',
        '83338',
        '83340',
        '83341',
        '83342',
        '83344',
        '83346',
        '83347',
        '83348',
        '83349',
        '83350',
        '83352',
        '83353',
        '83354',
        '83355',
        '83401',
        '83402',
        '83404',
        '83406',
        '83420',
        '83421',
        '83422',
        '83423',
        '83424',
        '83425',
        '83427',
        '83428',
        '83429',
        '83431',
        '83433',
        '83434',
        '83435',
        '83436',
        '83440',
        '83442',
        '83443',
        '83444',
        '83445',
        '83446',
        '83448',
        '83449',
        '83450',
        '83451',
        '83452',
        '83454',
        '83455',
        '83460',
        '83462',
        '83463',
        '83464',
        '83465',
        '83466',
        '83467',
        '83468',
        '83469',
        '83501',
        '83520',
        '83522',
        '83523',
        '83524',
        '83525',
        '83526',
        '83530',
        '83533',
        '83535',
        '83536',
        '83537',
        '83539',
        '83540',
        '83541',
        '83542',
        '83543',
        '83544',
        '83545',
        '83546',
        '83547',
        '83548',
        '83549',
        '83552',
        '83553',
        '83554',
        '83555',
        '83602',
        '83604',
        '83605',
        '83607',
        '83610',
        '83611',
        '83612',
        '83615',
        '83616',
        '83617',
        '83619',
        '83622',
        '83623',
        '83624',
        '83626',
        '83627',
        '83628',
        '83629',
        '83631',
        '83632',
        '83633',
        '83634',
        '83636',
        '83637',
        '83638',
        '83639',
        '83641',
        '83642',
        '83643',
        '83644',
        '83645',
        '83646',
        '83647',
        '83648',
        '83650',
        '83651',
        '83654',
        '83655',
        '83656',
        '83657',
        '83660',
        '83661',
        '83666',
        '83669',
        '83670',
        '83671',
        '83672',
        '83676',
        '83677',
        '83686',
        '83687',
        '83702',
        '83703',
        '83704',
        '83705',
        '83706',
        '83709',
        '83712',
        '83713',
        '83714',
        '83716',
        '83801',
        '83802',
        '83803',
        '83804',
        '83805',
        '83806',
        '83808',
        '83809',
        '83810',
        '83811',
        '83812',
        '83813',
        '83814',
        '83815',
        '83821',
        '83822',
        '83823',
        '83824',
        '83825',
        '83826',
        '83827',
        '83830',
        '83832',
        '83833',
        '83834',
        '83835',
        '83836',
        '83837',
        '83839',
        '83840',
        '83841',
        '83842',
        '83843',
        '83844',
        '83845',
        '83846',
        '83847',
        '83848',
        '83849',
        '83850',
        '83851',
        '83852',
        '83854',
        '83855',
        '83856',
        '83857',
        '83858',
        '83860',
        '83861',
        '83864',
        '83866',
        '83867',
        '83868',
        '83869',
        '83870',
        '83871',
        '83872',
        '83873',
        '83874',
        '83876'
      ]
    },
    UT: {
      name: 'Utah',
      codes: [
        '84001',
        '84002',
        '84003',
        '84004',
        '84005',
        '84006',
        '84007',
        '84010',
        '84013',
        '84014',
        '84015',
        '84017',
        '84018',
        '84020',
        '84021',
        '84022',
        '84023',
        '84024',
        '84025',
        '84026',
        '84027',
        '84028',
        '84029',
        '84031',
        '84032',
        '84033',
        '84034',
        '84035',
        '84036',
        '84037',
        '84038',
        '84039',
        '84040',
        '84041',
        '84042',
        '84043',
        '84044',
        '84045',
        '84046',
        '84047',
        '84049',
        '84050',
        '84051',
        '84052',
        '84053',
        '84054',
        '84055',
        '84056',
        '84057',
        '84058',
        '84060',
        '84061',
        '84062',
        '84063',
        '84064',
        '84065',
        '84066',
        '84067',
        '84069',
        '84070',
        '84071',
        '84072',
        '84073',
        '84074',
        '84075',
        '84076',
        '84078',
        '84080',
        '84081',
        '84082',
        '84083',
        '84084',
        '84085',
        '84086',
        '84087',
        '84088',
        '84092',
        '84093',
        '84094',
        '84095',
        '84096',
        '84097',
        '84098',
        '84101',
        '84102',
        '84103',
        '84104',
        '84105',
        '84106',
        '84107',
        '84108',
        '84109',
        '84111',
        '84112',
        '84113',
        '84115',
        '84116',
        '84117',
        '84118',
        '84119',
        '84120',
        '84121',
        '84123',
        '84124',
        '84128',
        '84180',
        '84301',
        '84302',
        '84304',
        '84305',
        '84306',
        '84307',
        '84308',
        '84309',
        '84310',
        '84311',
        '84312',
        '84313',
        '84314',
        '84315',
        '84316',
        '84317',
        '84318',
        '84319',
        '84320',
        '84321',
        '84324',
        '84325',
        '84326',
        '84327',
        '84328',
        '84329',
        '84330',
        '84331',
        '84332',
        '84333',
        '84334',
        '84335',
        '84336',
        '84337',
        '84338',
        '84339',
        '84340',
        '84341',
        '84401',
        '84403',
        '84404',
        '84405',
        '84414',
        '84501',
        '84511',
        '84512',
        '84513',
        '84515',
        '84516',
        '84518',
        '84520',
        '84521',
        '84522',
        '84523',
        '84525',
        '84526',
        '84528',
        '84529',
        '84530',
        '84531',
        '84532',
        '84533',
        '84534',
        '84535',
        '84536',
        '84537',
        '84539',
        '84540',
        '84542',
        '84601',
        '84604',
        '84606',
        '84620',
        '84621',
        '84622',
        '84623',
        '84624',
        '84626',
        '84627',
        '84628',
        '84629',
        '84630',
        '84631',
        '84632',
        '84633',
        '84634',
        '84635',
        '84636',
        '84637',
        '84638',
        '84639',
        '84640',
        '84642',
        '84643',
        '84645',
        '84646',
        '84647',
        '84648',
        '84649',
        '84651',
        '84652',
        '84653',
        '84654',
        '84655',
        '84656',
        '84657',
        '84660',
        '84662',
        '84663',
        '84664',
        '84665',
        '84667',
        '84701',
        '84710',
        '84711',
        '84712',
        '84713',
        '84714',
        '84715',
        '84716',
        '84718',
        '84719',
        '84720',
        '84721',
        '84722',
        '84723',
        '84724',
        '84725',
        '84726',
        '84728',
        '84729',
        '84730',
        '84731',
        '84732',
        '84733',
        '84734',
        '84735',
        '84736',
        '84737',
        '84738',
        '84739',
        '84740',
        '84741',
        '84742',
        '84743',
        '84744',
        '84745',
        '84746',
        '84747',
        '84749',
        '84750',
        '84751',
        '84752',
        '84753',
        '84754',
        '84755',
        '84756',
        '84757',
        '84758',
        '84759',
        '84760',
        '84761',
        '84762',
        '84763',
        '84764',
        '84765',
        '84766',
        '84767',
        '84770',
        '84772',
        '84773',
        '84774',
        '84775',
        '84776',
        '84779',
        '84780',
        '84781',
        '84782',
        '84783',
        '84784',
        '84790'
      ]
    },
    AZ: {
      name: 'Arizona',
      codes: [
        '85003',
        '85004',
        '85006',
        '85007',
        '85008',
        '85009',
        '85012',
        '85013',
        '85014',
        '85015',
        '85016',
        '85017',
        '85018',
        '85019',
        '85020',
        '85021',
        '85022',
        '85023',
        '85024',
        '85027',
        '85028',
        '85029',
        '85031',
        '85032',
        '85033',
        '85034',
        '85035',
        '85037',
        '85040',
        '85041',
        '85042',
        '85043',
        '85044',
        '85045',
        '85048',
        '85050',
        '85051',
        '85053',
        '85054',
        '85083',
        '85085',
        '85086',
        '85087',
        '85118',
        '85119',
        '85120',
        '85121',
        '85122',
        '85123',
        '85128',
        '85131',
        '85132',
        '85135',
        '85137',
        '85138',
        '85139',
        '85140',
        '85141',
        '85142',
        '85143',
        '85145',
        '85147',
        '85172',
        '85173',
        '85192',
        '85193',
        '85194',
        '85201',
        '85202',
        '85203',
        '85204',
        '85205',
        '85206',
        '85207',
        '85208',
        '85209',
        '85210',
        '85212',
        '85213',
        '85215',
        '85224',
        '85225',
        '85226',
        '85233',
        '85234',
        '85248',
        '85249',
        '85250',
        '85251',
        '85253',
        '85254',
        '85255',
        '85256',
        '85257',
        '85258',
        '85259',
        '85260',
        '85262',
        '85263',
        '85264',
        '85266',
        '85268',
        '85281',
        '85282',
        '85283',
        '85284',
        '85286',
        '85295',
        '85296',
        '85297',
        '85298',
        '85301',
        '85302',
        '85303',
        '85304',
        '85305',
        '85306',
        '85307',
        '85308',
        '85309',
        '85310',
        '85320',
        '85321',
        '85322',
        '85323',
        '85324',
        '85325',
        '85326',
        '85328',
        '85331',
        '85332',
        '85333',
        '85334',
        '85335',
        '85336',
        '85337',
        '85338',
        '85339',
        '85340',
        '85341',
        '85342',
        '85343',
        '85344',
        '85345',
        '85346',
        '85347',
        '85348',
        '85349',
        '85350',
        '85351',
        '85352',
        '85353',
        '85354',
        '85355',
        '85356',
        '85357',
        '85360',
        '85361',
        '85362',
        '85363',
        '85364',
        '85365',
        '85367',
        '85371',
        '85373',
        '85374',
        '85375',
        '85377',
        '85379',
        '85381',
        '85382',
        '85383',
        '85387',
        '85388',
        '85390',
        '85392',
        '85395',
        '85396',
        '85501',
        '85530',
        '85531',
        '85533',
        '85534',
        '85535',
        '85536',
        '85539',
        '85540',
        '85541',
        '85542',
        '85543',
        '85544',
        '85545',
        '85546',
        '85550',
        '85551',
        '85552',
        '85553',
        '85554',
        '85601',
        '85602',
        '85603',
        '85605',
        '85606',
        '85607',
        '85608',
        '85609',
        '85610',
        '85611',
        '85613',
        '85614',
        '85615',
        '85616',
        '85617',
        '85618',
        '85619',
        '85620',
        '85621',
        '85622',
        '85623',
        '85624',
        '85625',
        '85626',
        '85627',
        '85629',
        '85630',
        '85631',
        '85632',
        '85633',
        '85634',
        '85635',
        '85637',
        '85638',
        '85640',
        '85641',
        '85643',
        '85645',
        '85646',
        '85648',
        '85650',
        '85653',
        '85654',
        '85658',
        '85701',
        '85704',
        '85705',
        '85706',
        '85707',
        '85708',
        '85710',
        '85711',
        '85712',
        '85713',
        '85714',
        '85715',
        '85716',
        '85718',
        '85719',
        '85723',
        '85724',
        '85726',
        '85730',
        '85735',
        '85736',
        '85737',
        '85739',
        '85741',
        '85742',
        '85743',
        '85745',
        '85746',
        '85747',
        '85748',
        '85749',
        '85750',
        '85755',
        '85756',
        '85757',
        '85901',
        '85911',
        '85912',
        '85920',
        '85922',
        '85923',
        '85924',
        '85925',
        '85926',
        '85927',
        '85928',
        '85929',
        '85930',
        '85931',
        '85932',
        '85933',
        '85934',
        '85935',
        '85936',
        '85937',
        '85938',
        '85939',
        '85940',
        '85941',
        '85942',
        '86001',
        '86003',
        '86004',
        '86011',
        '86015',
        '86016',
        '86017',
        '86018',
        '86020',
        '86021',
        '86022',
        '86023',
        '86024',
        '86025',
        '86028',
        '86029',
        '86030',
        '86031',
        '86032',
        '86033',
        '86034',
        '86035',
        '86036',
        '86038',
        '86039',
        '86040',
        '86042',
        '86043',
        '86044',
        '86045',
        '86046',
        '86047',
        '86052',
        '86053',
        '86054',
        '86301',
        '86303',
        '86305',
        '86313',
        '86314',
        '86315',
        '86320',
        '86321',
        '86322',
        '86323',
        '86324',
        '86325',
        '86326',
        '86327',
        '86329',
        '86331',
        '86332',
        '86333',
        '86334',
        '86335',
        '86336',
        '86337',
        '86338',
        '86343',
        '86351',
        '86401',
        '86403',
        '86404',
        '86406',
        '86409',
        '86411',
        '86413',
        '86426',
        '86429',
        '86431',
        '86432',
        '86433',
        '86434',
        '86435',
        '86436',
        '86437',
        '86438',
        '86440',
        '86441',
        '86442',
        '86443',
        '86444',
        '86445',
        '86502',
        '86503',
        '86504',
        '86505',
        '86506',
        '86507',
        '86508',
        '86510',
        '86511',
        '86512',
        '86514',
        '86515',
        '86520',
        '86535',
        '86538',
        '86540',
        '86544',
        '86545',
        '86547',
        '86556'
      ]
    },
    NM: {
      name: 'New Mexico',
      codes: [
        '87001',
        '87002',
        '87004',
        '87005',
        '87006',
        '87007',
        '87008',
        '87010',
        '87011',
        '87012',
        '87013',
        '87014',
        '87015',
        '87016',
        '87017',
        '87018',
        '87020',
        '87021',
        '87022',
        '87023',
        '87024',
        '87025',
        '87026',
        '87027',
        '87028',
        '87029',
        '87031',
        '87032',
        '87034',
        '87035',
        '87036',
        '87037',
        '87038',
        '87040',
        '87041',
        '87042',
        '87043',
        '87044',
        '87045',
        '87046',
        '87047',
        '87048',
        '87049',
        '87051',
        '87052',
        '87053',
        '87056',
        '87059',
        '87061',
        '87062',
        '87063',
        '87064',
        '87068',
        '87070',
        '87072',
        '87083',
        '87102',
        '87104',
        '87105',
        '87106',
        '87107',
        '87108',
        '87109',
        '87110',
        '87111',
        '87112',
        '87113',
        '87114',
        '87116',
        '87117',
        '87120',
        '87121',
        '87122',
        '87123',
        '87124',
        '87144',
        '87301',
        '87305',
        '87310',
        '87311',
        '87312',
        '87313',
        '87315',
        '87316',
        '87317',
        '87319',
        '87320',
        '87321',
        '87322',
        '87323',
        '87325',
        '87326',
        '87327',
        '87328',
        '87347',
        '87357',
        '87364',
        '87375',
        '87401',
        '87402',
        '87410',
        '87412',
        '87413',
        '87415',
        '87416',
        '87417',
        '87418',
        '87419',
        '87420',
        '87421',
        '87455',
        '87461',
        '87499',
        '87501',
        '87505',
        '87506',
        '87507',
        '87508',
        '87510',
        '87511',
        '87512',
        '87513',
        '87514',
        '87515',
        '87516',
        '87517',
        '87518',
        '87519',
        '87520',
        '87521',
        '87522',
        '87523',
        '87524',
        '87525',
        '87527',
        '87528',
        '87529',
        '87530',
        '87531',
        '87532',
        '87533',
        '87535',
        '87537',
        '87538',
        '87539',
        '87540',
        '87543',
        '87544',
        '87548',
        '87549',
        '87551',
        '87552',
        '87553',
        '87554',
        '87556',
        '87557',
        '87558',
        '87560',
        '87562',
        '87564',
        '87565',
        '87566',
        '87567',
        '87569',
        '87571',
        '87573',
        '87574',
        '87575',
        '87577',
        '87578',
        '87579',
        '87580',
        '87581',
        '87582',
        '87583',
        '87701',
        '87710',
        '87711',
        '87712',
        '87713',
        '87714',
        '87715',
        '87718',
        '87722',
        '87723',
        '87724',
        '87728',
        '87729',
        '87730',
        '87731',
        '87732',
        '87733',
        '87734',
        '87735',
        '87736',
        '87740',
        '87742',
        '87743',
        '87745',
        '87746',
        '87747',
        '87749',
        '87750',
        '87752',
        '87753',
        '87801',
        '87820',
        '87821',
        '87823',
        '87824',
        '87825',
        '87827',
        '87828',
        '87829',
        '87830',
        '87831',
        '87832',
        '87901',
        '87930',
        '87931',
        '87933',
        '87935',
        '87936',
        '87937',
        '87939',
        '87940',
        '87941',
        '87942',
        '87943',
        '88001',
        '88002',
        '88003',
        '88004',
        '88005',
        '88007',
        '88008',
        '88009',
        '88011',
        '88012',
        '88020',
        '88021',
        '88022',
        '88023',
        '88024',
        '88025',
        '88026',
        '88027',
        '88028',
        '88029',
        '88030',
        '88032',
        '88033',
        '88034',
        '88038',
        '88039',
        '88040',
        '88041',
        '88042',
        '88043',
        '88044',
        '88045',
        '88046',
        '88047',
        '88048',
        '88049',
        '88051',
        '88052',
        '88053',
        '88055',
        '88056',
        '88061',
        '88063',
        '88065',
        '88072',
        '88081',
        '88101',
        '88103',
        '88112',
        '88113',
        '88114',
        '88115',
        '88116',
        '88118',
        '88119',
        '88120',
        '88121',
        '88124',
        '88125',
        '88126',
        '88130',
        '88132',
        '88134',
        '88135',
        '88136',
        '88201',
        '88203',
        '88210',
        '88220',
        '88230',
        '88231',
        '88232',
        '88240',
        '88242',
        '88250',
        '88252',
        '88253',
        '88254',
        '88255',
        '88256',
        '88260',
        '88262',
        '88263',
        '88264',
        '88265',
        '88267',
        '88268',
        '88301',
        '88310',
        '88311',
        '88312',
        '88314',
        '88316',
        '88317',
        '88318',
        '88321',
        '88323',
        '88324',
        '88325',
        '88330',
        '88336',
        '88337',
        '88338',
        '88339',
        '88340',
        '88341',
        '88342',
        '88343',
        '88344',
        '88345',
        '88346',
        '88347',
        '88348',
        '88349',
        '88350',
        '88351',
        '88352',
        '88353',
        '88354',
        '88355',
        '88401',
        '88410',
        '88411',
        '88414',
        '88415',
        '88416',
        '88417',
        '88418',
        '88419',
        '88421',
        '88422',
        '88424',
        '88426',
        '88427',
        '88430',
        '88431',
        '88434',
        '88435',
        '88436'
      ]
    },
    NV: {
      name: 'Nevada',
      codes: [
        '89001',
        '89002',
        '89003',
        '89004',
        '89005',
        '89007',
        '89008',
        '89010',
        '89011',
        '89012',
        '89013',
        '89014',
        '89015',
        '89017',
        '89018',
        '89019',
        '89020',
        '89021',
        '89022',
        '89025',
        '89026',
        '89027',
        '89029',
        '89030',
        '89031',
        '89032',
        '89039',
        '89040',
        '89042',
        '89043',
        '89044',
        '89045',
        '89046',
        '89047',
        '89048',
        '89049',
        '89052',
        '89054',
        '89060',
        '89061',
        '89074',
        '89081',
        '89084',
        '89085',
        '89086',
        '89101',
        '89102',
        '89103',
        '89104',
        '89106',
        '89107',
        '89108',
        '89109',
        '89110',
        '89113',
        '89115',
        '89117',
        '89118',
        '89119',
        '89120',
        '89121',
        '89122',
        '89123',
        '89124',
        '89128',
        '89129',
        '89130',
        '89131',
        '89134',
        '89135',
        '89138',
        '89139',
        '89141',
        '89142',
        '89143',
        '89144',
        '89145',
        '89146',
        '89147',
        '89148',
        '89149',
        '89156',
        '89161',
        '89166',
        '89169',
        '89178',
        '89179',
        '89183',
        '89191',
        '89301',
        '89310',
        '89311',
        '89314',
        '89316',
        '89317',
        '89318',
        '89319',
        '89402',
        '89403',
        '89404',
        '89405',
        '89406',
        '89408',
        '89409',
        '89410',
        '89411',
        '89412',
        '89413',
        '89414',
        '89415',
        '89418',
        '89419',
        '89420',
        '89421',
        '89422',
        '89423',
        '89424',
        '89425',
        '89426',
        '89427',
        '89428',
        '89429',
        '89430',
        '89431',
        '89433',
        '89434',
        '89436',
        '89438',
        '89439',
        '89440',
        '89441',
        '89442',
        '89444',
        '89445',
        '89446',
        '89447',
        '89448',
        '89449',
        '89450',
        '89451',
        '89460',
        '89501',
        '89502',
        '89503',
        '89506',
        '89508',
        '89509',
        '89510',
        '89511',
        '89512',
        '89519',
        '89521',
        '89523',
        '89701',
        '89702',
        '89703',
        '89704',
        '89705',
        '89706',
        '89801',
        '89815',
        '89820',
        '89821',
        '89822',
        '89823',
        '89825',
        '89826',
        '89828',
        '89830',
        '89831',
        '89832',
        '89833',
        '89834',
        '89835',
        '89883'
      ]
    },
    CA: {
      name: 'California',
      codes: [
        '90001',
        '90002',
        '90003',
        '90004',
        '90005',
        '90006',
        '90007',
        '90008',
        '90010',
        '90011',
        '90012',
        '90013',
        '90014',
        '90015',
        '90016',
        '90017',
        '90018',
        '90019',
        '90020',
        '90021',
        '90022',
        '90023',
        '90024',
        '90025',
        '90026',
        '90027',
        '90028',
        '90029',
        '90031',
        '90032',
        '90033',
        '90034',
        '90035',
        '90036',
        '90037',
        '90038',
        '90039',
        '90040',
        '90041',
        '90042',
        '90043',
        '90044',
        '90045',
        '90046',
        '90047',
        '90048',
        '90049',
        '90056',
        '90057',
        '90058',
        '90059',
        '90061',
        '90062',
        '90063',
        '90064',
        '90065',
        '90066',
        '90067',
        '90068',
        '90069',
        '90071',
        '90073',
        '90077',
        '90079',
        '90089',
        '90090',
        '90094',
        '90095',
        '90201',
        '90210',
        '90211',
        '90212',
        '90220',
        '90221',
        '90222',
        '90230',
        '90232',
        '90240',
        '90241',
        '90242',
        '90245',
        '90247',
        '90248',
        '90249',
        '90250',
        '90254',
        '90255',
        '90260',
        '90262',
        '90263',
        '90265',
        '90266',
        '90270',
        '90272',
        '90274',
        '90275',
        '90277',
        '90278',
        '90280',
        '90290',
        '90291',
        '90292',
        '90293',
        '90301',
        '90302',
        '90303',
        '90304',
        '90305',
        '90401',
        '90402',
        '90403',
        '90404',
        '90405',
        '90501',
        '90502',
        '90503',
        '90504',
        '90505',
        '90506',
        '90601',
        '90602',
        '90603',
        '90604',
        '90605',
        '90606',
        '90620',
        '90621',
        '90623',
        '90630',
        '90631',
        '90638',
        '90640',
        '90650',
        '90660',
        '90670',
        '90680',
        '90701',
        '90703',
        '90704',
        '90706',
        '90710',
        '90712',
        '90713',
        '90715',
        '90716',
        '90717',
        '90720',
        '90723',
        '90731',
        '90732',
        '90740',
        '90742',
        '90743',
        '90744',
        '90745',
        '90746',
        '90747',
        '90755',
        '90802',
        '90803',
        '90804',
        '90805',
        '90806',
        '90807',
        '90808',
        '90810',
        '90813',
        '90814',
        '90815',
        '90822',
        '90831',
        '91001',
        '91006',
        '91007',
        '91008',
        '91010',
        '91011',
        '91016',
        '91020',
        '91024',
        '91030',
        '91040',
        '91042',
        '91046',
        '91101',
        '91103',
        '91104',
        '91105',
        '91106',
        '91107',
        '91108',
        '91201',
        '91202',
        '91203',
        '91204',
        '91205',
        '91206',
        '91207',
        '91208',
        '91210',
        '91214',
        '91301',
        '91302',
        '91303',
        '91304',
        '91306',
        '91307',
        '91311',
        '91316',
        '91320',
        '91321',
        '91324',
        '91325',
        '91326',
        '91330',
        '91331',
        '91335',
        '91340',
        '91342',
        '91343',
        '91344',
        '91345',
        '91350',
        '91351',
        '91352',
        '91354',
        '91355',
        '91356',
        '91360',
        '91361',
        '91362',
        '91364',
        '91367',
        '91371',
        '91377',
        '91381',
        '91384',
        '91387',
        '91390',
        '91401',
        '91402',
        '91403',
        '91405',
        '91406',
        '91411',
        '91423',
        '91436',
        '91501',
        '91502',
        '91504',
        '91505',
        '91506',
        '91601',
        '91602',
        '91604',
        '91605',
        '91606',
        '91607',
        '91608',
        '91701',
        '91702',
        '91706',
        '91708',
        '91709',
        '91710',
        '91711',
        '91722',
        '91723',
        '91724',
        '91730',
        '91731',
        '91732',
        '91733',
        '91737',
        '91739',
        '91740',
        '91741',
        '91744',
        '91745',
        '91746',
        '91748',
        '91750',
        '91752',
        '91754',
        '91755',
        '91759',
        '91761',
        '91762',
        '91763',
        '91764',
        '91765',
        '91766',
        '91767',
        '91768',
        '91770',
        '91773',
        '91775',
        '91776',
        '91780',
        '91784',
        '91786',
        '91789',
        '91790',
        '91791',
        '91792',
        '91801',
        '91803',
        '91901',
        '91902',
        '91905',
        '91906',
        '91910',
        '91911',
        '91913',
        '91914',
        '91915',
        '91916',
        '91917',
        '91931',
        '91932',
        '91934',
        '91935',
        '91941',
        '91942',
        '91945',
        '91948',
        '91950',
        '91962',
        '91963',
        '91977',
        '91978',
        '91980',
        '92003',
        '92004',
        '92007',
        '92008',
        '92009',
        '92010',
        '92011',
        '92014',
        '92019',
        '92020',
        '92021',
        '92024',
        '92025',
        '92026',
        '92027',
        '92028',
        '92029',
        '92036',
        '92037',
        '92040',
        '92054',
        '92055',
        '92056',
        '92057',
        '92058',
        '92059',
        '92060',
        '92061',
        '92064',
        '92065',
        '92066',
        '92067',
        '92069',
        '92070',
        '92071',
        '92075',
        '92078',
        '92081',
        '92082',
        '92083',
        '92084',
        '92086',
        '92091',
        '92101',
        '92102',
        '92103',
        '92104',
        '92105',
        '92106',
        '92107',
        '92108',
        '92109',
        '92110',
        '92111',
        '92113',
        '92114',
        '92115',
        '92116',
        '92117',
        '92118',
        '92119',
        '92120',
        '92121',
        '92122',
        '92123',
        '92124',
        '92126',
        '92127',
        '92128',
        '92129',
        '92130',
        '92131',
        '92132',
        '92134',
        '92135',
        '92139',
        '92140',
        '92145',
        '92147',
        '92154',
        '92155',
        '92173',
        '92201',
        '92203',
        '92210',
        '92211',
        '92220',
        '92222',
        '92223',
        '92225',
        '92227',
        '92230',
        '92231',
        '92233',
        '92234',
        '92236',
        '92239',
        '92240',
        '92241',
        '92242',
        '92243',
        '92249',
        '92250',
        '92251',
        '92252',
        '92253',
        '92254',
        '92256',
        '92257',
        '92258',
        '92259',
        '92260',
        '92262',
        '92264',
        '92266',
        '92267',
        '92268',
        '92270',
        '92273',
        '92274',
        '92275',
        '92276',
        '92277',
        '92278',
        '92280',
        '92281',
        '92282',
        '92283',
        '92284',
        '92285',
        '92301',
        '92304',
        '92305',
        '92307',
        '92308',
        '92309',
        '92310',
        '92311',
        '92313',
        '92314',
        '92315',
        '92316',
        '92320',
        '92321',
        '92322',
        '92324',
        '92325',
        '92327',
        '92328',
        '92332',
        '92333',
        '92335',
        '92336',
        '92337',
        '92338',
        '92339',
        '92341',
        '92342',
        '92344',
        '92345',
        '92346',
        '92347',
        '92352',
        '92354',
        '92356',
        '92358',
        '92359',
        '92363',
        '92364',
        '92365',
        '92368',
        '92371',
        '92372',
        '92373',
        '92374',
        '92376',
        '92377',
        '92378',
        '92382',
        '92384',
        '92385',
        '92386',
        '92389',
        '92391',
        '92392',
        '92394',
        '92395',
        '92397',
        '92398',
        '92399',
        '92401',
        '92404',
        '92405',
        '92407',
        '92408',
        '92410',
        '92411',
        '92501',
        '92503',
        '92504',
        '92505',
        '92506',
        '92507',
        '92508',
        '92509',
        '92518',
        '92530',
        '92532',
        '92536',
        '92539',
        '92543',
        '92544',
        '92545',
        '92548',
        '92549',
        '92551',
        '92553',
        '92555',
        '92557',
        '92561',
        '92562',
        '92563',
        '92567',
        '92570',
        '92571',
        '92582',
        '92583',
        '92584',
        '92585',
        '92586',
        '92587',
        '92590',
        '92591',
        '92592',
        '92595',
        '92596',
        '92602',
        '92603',
        '92604',
        '92606',
        '92610',
        '92612',
        '92614',
        '92617',
        '92618',
        '92620',
        '92624',
        '92625',
        '92626',
        '92627',
        '92629',
        '92630',
        '92637',
        '92646',
        '92647',
        '92648',
        '92649',
        '92651',
        '92653',
        '92655',
        '92656',
        '92657',
        '92660',
        '92661',
        '92662',
        '92663',
        '92672',
        '92673',
        '92675',
        '92676',
        '92677',
        '92678',
        '92679',
        '92683',
        '92688',
        '92691',
        '92692',
        '92694',
        '92701',
        '92703',
        '92704',
        '92705',
        '92706',
        '92707',
        '92708',
        '92780',
        '92782',
        '92801',
        '92802',
        '92804',
        '92805',
        '92806',
        '92807',
        '92808',
        '92821',
        '92823',
        '92831',
        '92832',
        '92833',
        '92835',
        '92840',
        '92841',
        '92843',
        '92844',
        '92845',
        '92860',
        '92861',
        '92865',
        '92866',
        '92867',
        '92868',
        '92869',
        '92870',
        '92879',
        '92880',
        '92881',
        '92882',
        '92883',
        '92886',
        '92887',
        '93001',
        '93003',
        '93004',
        '93010',
        '93012',
        '93013',
        '93015',
        '93021',
        '93022',
        '93023',
        '93030',
        '93033',
        '93035',
        '93036',
        '93040',
        '93041',
        '93042',
        '93043',
        '93060',
        '93063',
        '93064',
        '93065',
        '93066',
        '93067',
        '93101',
        '93103',
        '93105',
        '93108',
        '93109',
        '93110',
        '93111',
        '93117',
        '93201',
        '93202',
        '93203',
        '93204',
        '93205',
        '93206',
        '93207',
        '93208',
        '93210',
        '93212',
        '93215',
        '93218',
        '93219',
        '93220',
        '93221',
        '93222',
        '93223',
        '93224',
        '93225',
        '93226',
        '93230',
        '93234',
        '93235',
        '93238',
        '93239',
        '93240',
        '93241',
        '93242',
        '93243',
        '93244',
        '93245',
        '93247',
        '93249',
        '93250',
        '93251',
        '93252',
        '93254',
        '93255',
        '93256',
        '93257',
        '93258',
        '93260',
        '93261',
        '93262',
        '93263',
        '93265',
        '93266',
        '93267',
        '93268',
        '93270',
        '93271',
        '93272',
        '93274',
        '93276',
        '93277',
        '93280',
        '93283',
        '93285',
        '93286',
        '93287',
        '93291',
        '93292',
        '93301',
        '93304',
        '93305',
        '93306',
        '93307',
        '93308',
        '93309',
        '93311',
        '93312',
        '93313',
        '93314',
        '93401',
        '93402',
        '93405',
        '93410',
        '93420',
        '93422',
        '93424',
        '93426',
        '93427',
        '93428',
        '93429',
        '93430',
        '93432',
        '93433',
        '93434',
        '93436',
        '93437',
        '93440',
        '93441',
        '93442',
        '93444',
        '93445',
        '93446',
        '93449',
        '93450',
        '93451',
        '93452',
        '93453',
        '93454',
        '93455',
        '93458',
        '93460',
        '93461',
        '93463',
        '93465',
        '93501',
        '93505',
        '93510',
        '93512',
        '93513',
        '93514',
        '93516',
        '93517',
        '93518',
        '93519',
        '93522',
        '93523',
        '93524',
        '93526',
        '93527',
        '93528',
        '93529',
        '93530',
        '93531',
        '93532',
        '93534',
        '93535',
        '93536',
        '93541',
        '93543',
        '93544',
        '93545',
        '93546',
        '93549',
        '93550',
        '93551',
        '93552',
        '93553',
        '93554',
        '93555',
        '93558',
        '93560',
        '93561',
        '93562',
        '93563',
        '93591',
        '93592',
        '93601',
        '93602',
        '93603',
        '93604',
        '93605',
        '93606',
        '93608',
        '93609',
        '93610',
        '93611',
        '93612',
        '93614',
        '93615',
        '93616',
        '93618',
        '93619',
        '93620',
        '93621',
        '93622',
        '93623',
        '93624',
        '93625',
        '93626',
        '93627',
        '93628',
        '93630',
        '93631',
        '93633',
        '93634',
        '93635',
        '93636',
        '93637',
        '93638',
        '93640',
        '93641',
        '93643',
        '93644',
        '93645',
        '93646',
        '93647',
        '93648',
        '93650',
        '93651',
        '93652',
        '93653',
        '93654',
        '93656',
        '93657',
        '93660',
        '93662',
        '93664',
        '93665',
        '93666',
        '93667',
        '93668',
        '93669',
        '93673',
        '93675',
        '93701',
        '93702',
        '93703',
        '93704',
        '93705',
        '93706',
        '93710',
        '93711',
        '93720',
        '93721',
        '93722',
        '93723',
        '93725',
        '93726',
        '93727',
        '93728',
        '93730',
        '93901',
        '93905',
        '93906',
        '93907',
        '93908',
        '93920',
        '93921',
        '93923',
        '93924',
        '93925',
        '93926',
        '93927',
        '93928',
        '93930',
        '93932',
        '93933',
        '93940',
        '93943',
        '93950',
        '93953',
        '93954',
        '93955',
        '93960',
        '93962',
        '94002',
        '94005',
        '94010',
        '94014',
        '94015',
        '94019',
        '94020',
        '94021',
        '94022',
        '94024',
        '94025',
        '94027',
        '94028',
        '94030',
        '94037',
        '94038',
        '94040',
        '94041',
        '94043',
        '94044',
        '94060',
        '94061',
        '94062',
        '94063',
        '94065',
        '94066',
        '94070',
        '94074',
        '94080',
        '94085',
        '94086',
        '94087',
        '94089',
        '94102',
        '94103',
        '94104',
        '94105',
        '94107',
        '94108',
        '94109',
        '94110',
        '94111',
        '94112',
        '94114',
        '94115',
        '94116',
        '94117',
        '94118',
        '94121',
        '94122',
        '94123',
        '94124',
        '94127',
        '94128',
        '94129',
        '94130',
        '94131',
        '94132',
        '94133',
        '94134',
        '94158',
        '94301',
        '94303',
        '94304',
        '94305',
        '94306',
        '94401',
        '94402',
        '94403',
        '94404',
        '94501',
        '94502',
        '94503',
        '94505',
        '94506',
        '94507',
        '94508',
        '94509',
        '94510',
        '94511',
        '94512',
        '94513',
        '94514',
        '94515',
        '94516',
        '94517',
        '94518',
        '94519',
        '94520',
        '94521',
        '94523',
        '94525',
        '94526',
        '94528',
        '94530',
        '94531',
        '94533',
        '94534',
        '94535',
        '94536',
        '94538',
        '94539',
        '94541',
        '94542',
        '94544',
        '94545',
        '94546',
        '94547',
        '94548',
        '94549',
        '94550',
        '94551',
        '94552',
        '94553',
        '94555',
        '94556',
        '94558',
        '94559',
        '94560',
        '94561',
        '94563',
        '94564',
        '94565',
        '94566',
        '94567',
        '94568',
        '94569',
        '94571',
        '94572',
        '94573',
        '94574',
        '94575',
        '94576',
        '94577',
        '94578',
        '94579',
        '94580',
        '94582',
        '94583',
        '94585',
        '94586',
        '94587',
        '94588',
        '94589',
        '94590',
        '94591',
        '94592',
        '94595',
        '94596',
        '94597',
        '94598',
        '94599',
        '94601',
        '94602',
        '94603',
        '94605',
        '94606',
        '94607',
        '94608',
        '94609',
        '94610',
        '94611',
        '94612',
        '94613',
        '94618',
        '94619',
        '94621',
        '94702',
        '94703',
        '94704',
        '94705',
        '94706',
        '94707',
        '94708',
        '94709',
        '94710',
        '94720',
        '94801',
        '94803',
        '94804',
        '94805',
        '94806',
        '94850',
        '94901',
        '94903',
        '94904',
        '94920',
        '94922',
        '94923',
        '94924',
        '94925',
        '94928',
        '94929',
        '94930',
        '94931',
        '94933',
        '94937',
        '94938',
        '94939',
        '94940',
        '94941',
        '94945',
        '94946',
        '94947',
        '94949',
        '94950',
        '94951',
        '94952',
        '94954',
        '94956',
        '94957',
        '94960',
        '94963',
        '94964',
        '94965',
        '94970',
        '94971',
        '94972',
        '94973',
        '95002',
        '95003',
        '95004',
        '95005',
        '95006',
        '95007',
        '95008',
        '95010',
        '95012',
        '95013',
        '95014',
        '95017',
        '95018',
        '95019',
        '95020',
        '95023',
        '95030',
        '95032',
        '95033',
        '95035',
        '95037',
        '95039',
        '95041',
        '95043',
        '95045',
        '95046',
        '95050',
        '95051',
        '95053',
        '95054',
        '95060',
        '95062',
        '95064',
        '95065',
        '95066',
        '95070',
        '95073',
        '95075',
        '95076',
        '95110',
        '95111',
        '95112',
        '95113',
        '95116',
        '95117',
        '95118',
        '95119',
        '95120',
        '95121',
        '95122',
        '95123',
        '95124',
        '95125',
        '95126',
        '95127',
        '95128',
        '95129',
        '95130',
        '95131',
        '95132',
        '95133',
        '95134',
        '95135',
        '95136',
        '95138',
        '95139',
        '95140',
        '95148',
        '95202',
        '95203',
        '95204',
        '95205',
        '95206',
        '95207',
        '95209',
        '95210',
        '95211',
        '95212',
        '95215',
        '95219',
        '95220',
        '95222',
        '95223',
        '95224',
        '95225',
        '95226',
        '95227',
        '95228',
        '95230',
        '95231',
        '95232',
        '95233',
        '95234',
        '95236',
        '95237',
        '95240',
        '95242',
        '95245',
        '95246',
        '95247',
        '95248',
        '95249',
        '95251',
        '95252',
        '95254',
        '95255',
        '95257',
        '95258',
        '95301',
        '95303',
        '95304',
        '95305',
        '95306',
        '95307',
        '95310',
        '95311',
        '95312',
        '95313',
        '95315',
        '95316',
        '95317',
        '95318',
        '95319',
        '95320',
        '95321',
        '95322',
        '95323',
        '95324',
        '95325',
        '95326',
        '95327',
        '95328',
        '95329',
        '95330',
        '95333',
        '95334',
        '95335',
        '95336',
        '95337',
        '95338',
        '95340',
        '95341',
        '95345',
        '95346',
        '95348',
        '95350',
        '95351',
        '95354',
        '95355',
        '95356',
        '95357',
        '95358',
        '95360',
        '95361',
        '95363',
        '95364',
        '95365',
        '95366',
        '95367',
        '95368',
        '95369',
        '95370',
        '95372',
        '95374',
        '95375',
        '95376',
        '95377',
        '95379',
        '95380',
        '95382',
        '95383',
        '95385',
        '95386',
        '95387',
        '95388',
        '95389',
        '95391',
        '95401',
        '95403',
        '95404',
        '95405',
        '95407',
        '95409',
        '95410',
        '95412',
        '95415',
        '95417',
        '95420',
        '95421',
        '95422',
        '95423',
        '95425',
        '95426',
        '95427',
        '95428',
        '95429',
        '95430',
        '95431',
        '95432',
        '95435',
        '95436',
        '95437',
        '95439',
        '95441',
        '95442',
        '95443',
        '95444',
        '95445',
        '95446',
        '95448',
        '95449',
        '95450',
        '95451',
        '95452',
        '95453',
        '95454',
        '95456',
        '95457',
        '95458',
        '95459',
        '95460',
        '95461',
        '95462',
        '95463',
        '95464',
        '95465',
        '95466',
        '95467',
        '95468',
        '95469',
        '95470',
        '95471',
        '95472',
        '95476',
        '95482',
        '95485',
        '95486',
        '95488',
        '95490',
        '95492',
        '95493',
        '95494',
        '95497',
        '95501',
        '95503',
        '95511',
        '95514',
        '95519',
        '95521',
        '95524',
        '95525',
        '95526',
        '95527',
        '95528',
        '95531',
        '95536',
        '95537',
        '95540',
        '95542',
        '95543',
        '95545',
        '95546',
        '95547',
        '95548',
        '95549',
        '95550',
        '95551',
        '95552',
        '95553',
        '95554',
        '95555',
        '95556',
        '95558',
        '95559',
        '95560',
        '95562',
        '95563',
        '95564',
        '95565',
        '95567',
        '95568',
        '95569',
        '95570',
        '95571',
        '95573',
        '95585',
        '95587',
        '95589',
        '95595',
        '95601',
        '95602',
        '95603',
        '95604',
        '95605',
        '95606',
        '95607',
        '95608',
        '95610',
        '95612',
        '95614',
        '95615',
        '95616',
        '95618',
        '95619',
        '95620',
        '95621',
        '95623',
        '95624',
        '95625',
        '95626',
        '95627',
        '95628',
        '95629',
        '95630',
        '95631',
        '95632',
        '95633',
        '95634',
        '95635',
        '95636',
        '95637',
        '95638',
        '95639',
        '95640',
        '95641',
        '95642',
        '95645',
        '95646',
        '95648',
        '95650',
        '95651',
        '95652',
        '95653',
        '95655',
        '95658',
        '95659',
        '95660',
        '95661',
        '95662',
        '95663',
        '95664',
        '95665',
        '95666',
        '95667',
        '95668',
        '95669',
        '95670',
        '95672',
        '95673',
        '95674',
        '95675',
        '95677',
        '95678',
        '95679',
        '95680',
        '95681',
        '95682',
        '95683',
        '95684',
        '95685',
        '95686',
        '95687',
        '95688',
        '95689',
        '95690',
        '95691',
        '95692',
        '95693',
        '95694',
        '95695',
        '95697',
        '95698',
        '95699',
        '95701',
        '95703',
        '95709',
        '95713',
        '95714',
        '95715',
        '95717',
        '95720',
        '95721',
        '95722',
        '95724',
        '95726',
        '95728',
        '95735',
        '95736',
        '95742',
        '95746',
        '95747',
        '95757',
        '95758',
        '95762',
        '95765',
        '95776',
        '95811',
        '95814',
        '95815',
        '95816',
        '95817',
        '95818',
        '95819',
        '95820',
        '95821',
        '95822',
        '95823',
        '95824',
        '95825',
        '95826',
        '95827',
        '95828',
        '95829',
        '95830',
        '95831',
        '95832',
        '95833',
        '95834',
        '95835',
        '95837',
        '95838',
        '95841',
        '95842',
        '95843',
        '95864',
        '95901',
        '95903',
        '95910',
        '95912',
        '95914',
        '95915',
        '95916',
        '95917',
        '95918',
        '95919',
        '95920',
        '95922',
        '95923',
        '95925',
        '95926',
        '95928',
        '95930',
        '95932',
        '95934',
        '95935',
        '95936',
        '95937',
        '95938',
        '95939',
        '95941',
        '95942',
        '95943',
        '95944',
        '95945',
        '95946',
        '95947',
        '95948',
        '95949',
        '95950',
        '95951',
        '95953',
        '95954',
        '95955',
        '95956',
        '95957',
        '95959',
        '95960',
        '95961',
        '95962',
        '95963',
        '95965',
        '95966',
        '95968',
        '95969',
        '95970',
        '95971',
        '95973',
        '95974',
        '95975',
        '95977',
        '95978',
        '95979',
        '95981',
        '95982',
        '95983',
        '95984',
        '95986',
        '95987',
        '95988',
        '95991',
        '95993',
        '96001',
        '96002',
        '96003',
        '96006',
        '96007',
        '96008',
        '96009',
        '96010',
        '96011',
        '96013',
        '96014',
        '96015',
        '96016',
        '96017',
        '96019',
        '96020',
        '96021',
        '96022',
        '96023',
        '96024',
        '96025',
        '96027',
        '96028',
        '96029',
        '96031',
        '96032',
        '96033',
        '96034',
        '96035',
        '96037',
        '96038',
        '96039',
        '96040',
        '96041',
        '96044',
        '96046',
        '96047',
        '96048',
        '96049',
        '96050',
        '96051',
        '96052',
        '96054',
        '96055',
        '96056',
        '96057',
        '96058',
        '96059',
        '96061',
        '96062',
        '96063',
        '96064',
        '96065',
        '96067',
        '96068',
        '96069',
        '96071',
        '96073',
        '96074',
        '96075',
        '96076',
        '96080',
        '96084',
        '96085',
        '96086',
        '96087',
        '96088',
        '96090',
        '96091',
        '96092',
        '96093',
        '96094',
        '96096',
        '96097',
        '96101',
        '96103',
        '96104',
        '96105',
        '96106',
        '96107',
        '96108',
        '96109',
        '96110',
        '96111',
        '96112',
        '96113',
        '96114',
        '96115',
        '96116',
        '96117',
        '96118',
        '96119',
        '96120',
        '96121',
        '96122',
        '96123',
        '96124',
        '96125',
        '96126',
        '96128',
        '96129',
        '96130',
        '96132',
        '96133',
        '96134',
        '96135',
        '96136',
        '96137',
        '96140',
        '96141',
        '96142',
        '96143',
        '96145',
        '96146',
        '96148',
        '96150',
        '96155',
        '96161'
      ]
    },
    HI: {
      name: 'Hawaii',
      codes: [
        '96701',
        '96703',
        '96704',
        '96705',
        '96706',
        '96707',
        '96708',
        '96710',
        '96712',
        '96713',
        '96714',
        '96716',
        '96717',
        '96719',
        '96720',
        '96722',
        '96725',
        '96726',
        '96727',
        '96728',
        '96729',
        '96730',
        '96731',
        '96732',
        '96734',
        '96737',
        '96738',
        '96740',
        '96741',
        '96742',
        '96743',
        '96744',
        '96746',
        '96747',
        '96748',
        '96749',
        '96750',
        '96751',
        '96752',
        '96753',
        '96754',
        '96755',
        '96756',
        '96757',
        '96759',
        '96760',
        '96761',
        '96762',
        '96763',
        '96764',
        '96765',
        '96766',
        '96768',
        '96769',
        '96770',
        '96771',
        '96772',
        '96773',
        '96774',
        '96776',
        '96777',
        '96778',
        '96779',
        '96780',
        '96781',
        '96782',
        '96783',
        '96785',
        '96786',
        '96789',
        '96790',
        '96791',
        '96792',
        '96793',
        '96795',
        '96796',
        '96797',
        '96813',
        '96814',
        '96815',
        '96816',
        '96817',
        '96818',
        '96819',
        '96821',
        '96822',
        '96825',
        '96826',
        '96850',
        '96853',
        '96857',
        '96859',
        '96860',
        '96863'
      ]
    },
    OR: {
      name: 'Oregon',
      codes: [
        '97001',
        '97002',
        '97004',
        '97005',
        '97006',
        '97007',
        '97008',
        '97009',
        '97011',
        '97013',
        '97014',
        '97015',
        '97016',
        '97017',
        '97018',
        '97019',
        '97020',
        '97021',
        '97022',
        '97023',
        '97024',
        '97026',
        '97027',
        '97028',
        '97029',
        '97030',
        '97031',
        '97032',
        '97033',
        '97034',
        '97035',
        '97037',
        '97038',
        '97039',
        '97040',
        '97041',
        '97042',
        '97045',
        '97048',
        '97049',
        '97050',
        '97051',
        '97053',
        '97054',
        '97055',
        '97056',
        '97057',
        '97058',
        '97060',
        '97062',
        '97063',
        '97064',
        '97065',
        '97067',
        '97068',
        '97070',
        '97071',
        '97080',
        '97086',
        '97089',
        '97101',
        '97102',
        '97103',
        '97106',
        '97107',
        '97108',
        '97109',
        '97110',
        '97111',
        '97112',
        '97113',
        '97114',
        '97115',
        '97116',
        '97117',
        '97118',
        '97119',
        '97121',
        '97122',
        '97123',
        '97124',
        '97125',
        '97127',
        '97128',
        '97130',
        '97131',
        '97132',
        '97133',
        '97134',
        '97135',
        '97136',
        '97137',
        '97138',
        '97140',
        '97141',
        '97144',
        '97145',
        '97146',
        '97147',
        '97148',
        '97149',
        '97201',
        '97202',
        '97203',
        '97204',
        '97205',
        '97206',
        '97208',
        '97209',
        '97210',
        '97211',
        '97212',
        '97213',
        '97214',
        '97215',
        '97216',
        '97217',
        '97218',
        '97219',
        '97220',
        '97221',
        '97222',
        '97223',
        '97224',
        '97225',
        '97227',
        '97229',
        '97230',
        '97231',
        '97232',
        '97233',
        '97236',
        '97239',
        '97266',
        '97267',
        '97301',
        '97302',
        '97303',
        '97304',
        '97305',
        '97306',
        '97317',
        '97321',
        '97322',
        '97324',
        '97325',
        '97326',
        '97327',
        '97329',
        '97330',
        '97331',
        '97333',
        '97338',
        '97341',
        '97342',
        '97343',
        '97344',
        '97345',
        '97346',
        '97347',
        '97348',
        '97350',
        '97351',
        '97352',
        '97355',
        '97357',
        '97358',
        '97360',
        '97361',
        '97362',
        '97364',
        '97365',
        '97366',
        '97367',
        '97368',
        '97369',
        '97370',
        '97371',
        '97373',
        '97374',
        '97375',
        '97376',
        '97377',
        '97378',
        '97380',
        '97381',
        '97383',
        '97384',
        '97385',
        '97386',
        '97388',
        '97389',
        '97390',
        '97391',
        '97392',
        '97394',
        '97396',
        '97401',
        '97402',
        '97403',
        '97404',
        '97405',
        '97406',
        '97408',
        '97410',
        '97411',
        '97412',
        '97413',
        '97414',
        '97415',
        '97416',
        '97417',
        '97419',
        '97420',
        '97423',
        '97424',
        '97426',
        '97429',
        '97430',
        '97431',
        '97434',
        '97435',
        '97436',
        '97437',
        '97438',
        '97439',
        '97441',
        '97442',
        '97443',
        '97444',
        '97446',
        '97447',
        '97448',
        '97449',
        '97450',
        '97451',
        '97452',
        '97453',
        '97454',
        '97455',
        '97456',
        '97457',
        '97458',
        '97459',
        '97461',
        '97462',
        '97463',
        '97465',
        '97466',
        '97467',
        '97469',
        '97470',
        '97471',
        '97473',
        '97476',
        '97477',
        '97478',
        '97479',
        '97480',
        '97481',
        '97484',
        '97486',
        '97487',
        '97488',
        '97489',
        '97490',
        '97492',
        '97493',
        '97494',
        '97495',
        '97496',
        '97497',
        '97498',
        '97499',
        '97501',
        '97502',
        '97503',
        '97504',
        '97520',
        '97522',
        '97523',
        '97524',
        '97525',
        '97526',
        '97527',
        '97530',
        '97531',
        '97532',
        '97534',
        '97535',
        '97536',
        '97537',
        '97538',
        '97539',
        '97540',
        '97541',
        '97543',
        '97544',
        '97601',
        '97603',
        '97604',
        '97620',
        '97621',
        '97622',
        '97623',
        '97624',
        '97625',
        '97626',
        '97627',
        '97630',
        '97632',
        '97633',
        '97634',
        '97635',
        '97636',
        '97637',
        '97638',
        '97639',
        '97640',
        '97641',
        '97701',
        '97702',
        '97707',
        '97710',
        '97711',
        '97712',
        '97720',
        '97721',
        '97722',
        '97730',
        '97731',
        '97732',
        '97733',
        '97734',
        '97735',
        '97736',
        '97737',
        '97738',
        '97739',
        '97741',
        '97750',
        '97751',
        '97752',
        '97753',
        '97754',
        '97756',
        '97758',
        '97759',
        '97760',
        '97761',
        '97801',
        '97810',
        '97812',
        '97813',
        '97814',
        '97817',
        '97818',
        '97819',
        '97820',
        '97823',
        '97824',
        '97825',
        '97826',
        '97827',
        '97828',
        '97830',
        '97833',
        '97834',
        '97835',
        '97836',
        '97837',
        '97838',
        '97839',
        '97840',
        '97841',
        '97842',
        '97843',
        '97844',
        '97845',
        '97846',
        '97848',
        '97850',
        '97856',
        '97857',
        '97859',
        '97862',
        '97864',
        '97865',
        '97867',
        '97868',
        '97869',
        '97870',
        '97873',
        '97874',
        '97875',
        '97876',
        '97877',
        '97880',
        '97882',
        '97883',
        '97884',
        '97885',
        '97886',
        '97901',
        '97903',
        '97904',
        '97905',
        '97906',
        '97907',
        '97908',
        '97909',
        '97910',
        '97911',
        '97913',
        '97914',
        '97918',
        '97920'
      ]
    },
    WA: {
      name: 'Washington',
      codes: [
        '98001',
        '98002',
        '98003',
        '98004',
        '98005',
        '98006',
        '98007',
        '98008',
        '98010',
        '98011',
        '98012',
        '98014',
        '98019',
        '98020',
        '98021',
        '98022',
        '98023',
        '98024',
        '98026',
        '98027',
        '98028',
        '98029',
        '98030',
        '98031',
        '98032',
        '98033',
        '98034',
        '98036',
        '98037',
        '98038',
        '98039',
        '98040',
        '98042',
        '98043',
        '98045',
        '98047',
        '98050',
        '98051',
        '98052',
        '98053',
        '98055',
        '98056',
        '98057',
        '98058',
        '98059',
        '98065',
        '98068',
        '98070',
        '98072',
        '98074',
        '98075',
        '98077',
        '98087',
        '98092',
        '98101',
        '98102',
        '98103',
        '98104',
        '98105',
        '98106',
        '98107',
        '98108',
        '98109',
        '98110',
        '98112',
        '98115',
        '98116',
        '98117',
        '98118',
        '98119',
        '98121',
        '98122',
        '98125',
        '98126',
        '98133',
        '98134',
        '98136',
        '98144',
        '98146',
        '98148',
        '98154',
        '98155',
        '98158',
        '98164',
        '98166',
        '98168',
        '98174',
        '98177',
        '98178',
        '98188',
        '98195',
        '98198',
        '98199',
        '98201',
        '98203',
        '98204',
        '98207',
        '98208',
        '98220',
        '98221',
        '98222',
        '98223',
        '98224',
        '98225',
        '98226',
        '98229',
        '98230',
        '98232',
        '98233',
        '98235',
        '98236',
        '98237',
        '98238',
        '98239',
        '98240',
        '98241',
        '98243',
        '98244',
        '98245',
        '98247',
        '98248',
        '98249',
        '98250',
        '98251',
        '98252',
        '98253',
        '98255',
        '98256',
        '98257',
        '98258',
        '98260',
        '98261',
        '98262',
        '98263',
        '98264',
        '98266',
        '98267',
        '98270',
        '98271',
        '98272',
        '98273',
        '98274',
        '98275',
        '98276',
        '98277',
        '98278',
        '98279',
        '98280',
        '98281',
        '98282',
        '98283',
        '98284',
        '98286',
        '98288',
        '98290',
        '98292',
        '98294',
        '98295',
        '98296',
        '98297',
        '98303',
        '98304',
        '98305',
        '98310',
        '98311',
        '98312',
        '98314',
        '98315',
        '98320',
        '98321',
        '98323',
        '98325',
        '98326',
        '98327',
        '98328',
        '98329',
        '98330',
        '98331',
        '98332',
        '98333',
        '98335',
        '98336',
        '98337',
        '98338',
        '98339',
        '98340',
        '98342',
        '98345',
        '98346',
        '98349',
        '98350',
        '98351',
        '98353',
        '98354',
        '98355',
        '98356',
        '98357',
        '98358',
        '98359',
        '98360',
        '98361',
        '98362',
        '98363',
        '98364',
        '98365',
        '98366',
        '98367',
        '98368',
        '98370',
        '98371',
        '98372',
        '98373',
        '98374',
        '98375',
        '98376',
        '98377',
        '98380',
        '98381',
        '98382',
        '98383',
        '98385',
        '98387',
        '98388',
        '98390',
        '98391',
        '98392',
        '98394',
        '98396',
        '98402',
        '98403',
        '98404',
        '98405',
        '98406',
        '98407',
        '98408',
        '98409',
        '98416',
        '98418',
        '98421',
        '98422',
        '98424',
        '98430',
        '98433',
        '98438',
        '98439',
        '98443',
        '98444',
        '98445',
        '98446',
        '98447',
        '98465',
        '98466',
        '98467',
        '98498',
        '98499',
        '98501',
        '98502',
        '98503',
        '98506',
        '98512',
        '98513',
        '98516',
        '98520',
        '98524',
        '98526',
        '98527',
        '98528',
        '98530',
        '98531',
        '98532',
        '98533',
        '98535',
        '98536',
        '98537',
        '98538',
        '98541',
        '98542',
        '98544',
        '98546',
        '98547',
        '98548',
        '98550',
        '98552',
        '98555',
        '98557',
        '98558',
        '98559',
        '98560',
        '98562',
        '98563',
        '98564',
        '98565',
        '98568',
        '98569',
        '98570',
        '98571',
        '98572',
        '98575',
        '98576',
        '98577',
        '98579',
        '98580',
        '98581',
        '98582',
        '98583',
        '98584',
        '98585',
        '98586',
        '98587',
        '98588',
        '98589',
        '98590',
        '98591',
        '98592',
        '98593',
        '98595',
        '98596',
        '98597',
        '98601',
        '98602',
        '98603',
        '98604',
        '98605',
        '98606',
        '98607',
        '98610',
        '98611',
        '98612',
        '98613',
        '98614',
        '98616',
        '98617',
        '98619',
        '98620',
        '98621',
        '98624',
        '98625',
        '98626',
        '98628',
        '98629',
        '98631',
        '98632',
        '98635',
        '98638',
        '98639',
        '98640',
        '98641',
        '98642',
        '98643',
        '98644',
        '98645',
        '98647',
        '98648',
        '98649',
        '98650',
        '98651',
        '98660',
        '98661',
        '98662',
        '98663',
        '98664',
        '98665',
        '98670',
        '98671',
        '98672',
        '98673',
        '98674',
        '98675',
        '98682',
        '98683',
        '98684',
        '98685',
        '98686',
        '98801',
        '98802',
        '98811',
        '98812',
        '98813',
        '98814',
        '98815',
        '98816',
        '98817',
        '98819',
        '98821',
        '98822',
        '98823',
        '98824',
        '98826',
        '98827',
        '98828',
        '98829',
        '98830',
        '98831',
        '98832',
        '98833',
        '98834',
        '98836',
        '98837',
        '98840',
        '98841',
        '98843',
        '98844',
        '98845',
        '98846',
        '98847',
        '98848',
        '98849',
        '98850',
        '98851',
        '98852',
        '98853',
        '98855',
        '98856',
        '98857',
        '98858',
        '98859',
        '98860',
        '98862',
        '98901',
        '98902',
        '98903',
        '98908',
        '98921',
        '98922',
        '98923',
        '98925',
        '98926',
        '98930',
        '98932',
        '98933',
        '98934',
        '98935',
        '98936',
        '98937',
        '98938',
        '98939',
        '98940',
        '98941',
        '98942',
        '98943',
        '98944',
        '98946',
        '98947',
        '98948',
        '98950',
        '98951',
        '98952',
        '98953',
        '99001',
        '99003',
        '99004',
        '99005',
        '99006',
        '99008',
        '99009',
        '99011',
        '99012',
        '99013',
        '99016',
        '99017',
        '99018',
        '99019',
        '99020',
        '99021',
        '99022',
        '99023',
        '99025',
        '99026',
        '99027',
        '99029',
        '99030',
        '99031',
        '99032',
        '99033',
        '99034',
        '99036',
        '99037',
        '99039',
        '99040',
        '99101',
        '99102',
        '99103',
        '99105',
        '99109',
        '99110',
        '99111',
        '99113',
        '99114',
        '99115',
        '99116',
        '99117',
        '99118',
        '99119',
        '99121',
        '99122',
        '99123',
        '99124',
        '99125',
        '99126',
        '99128',
        '99129',
        '99130',
        '99131',
        '99133',
        '99134',
        '99135',
        '99136',
        '99137',
        '99138',
        '99139',
        '99140',
        '99141',
        '99143',
        '99144',
        '99146',
        '99147',
        '99148',
        '99149',
        '99150',
        '99151',
        '99152',
        '99153',
        '99154',
        '99155',
        '99156',
        '99157',
        '99158',
        '99159',
        '99160',
        '99161',
        '99163',
        '99164',
        '99166',
        '99167',
        '99169',
        '99170',
        '99171',
        '99173',
        '99174',
        '99176',
        '99179',
        '99180',
        '99181',
        '99185',
        '99201',
        '99202',
        '99203',
        '99204',
        '99205',
        '99206',
        '99207',
        '99208',
        '99212',
        '99216',
        '99217',
        '99218',
        '99223',
        '99224',
        '99301',
        '99320',
        '99321',
        '99322',
        '99323',
        '99324',
        '99326',
        '99328',
        '99329',
        '99330',
        '99333',
        '99335',
        '99336',
        '99337',
        '99338',
        '99341',
        '99343',
        '99344',
        '99345',
        '99346',
        '99347',
        '99348',
        '99349',
        '99350',
        '99352',
        '99353',
        '99354',
        '99356',
        '99357',
        '99359',
        '99360',
        '99361',
        '99362',
        '99363',
        '99371',
        '99401',
        '99402',
        '99403'
      ]
    },
    AK: {
      name: 'Alaska',
      codes: [
        '99501',
        '99502',
        '99503',
        '99504',
        '99505',
        '99506',
        '99507',
        '99508',
        '99510',
        '99513',
        '99515',
        '99516',
        '99517',
        '99518',
        '99519',
        '99540',
        '99546',
        '99547',
        '99548',
        '99549',
        '99550',
        '99551',
        '99552',
        '99553',
        '99554',
        '99555',
        '99556',
        '99557',
        '99558',
        '99559',
        '99561',
        '99563',
        '99564',
        '99565',
        '99566',
        '99567',
        '99568',
        '99569',
        '99571',
        '99572',
        '99573',
        '99574',
        '99575',
        '99576',
        '99577',
        '99578',
        '99579',
        '99580',
        '99581',
        '99583',
        '99585',
        '99586',
        '99587',
        '99588',
        '99589',
        '99590',
        '99591',
        '99602',
        '99603',
        '99604',
        '99605',
        '99606',
        '99607',
        '99609',
        '99610',
        '99611',
        '99612',
        '99613',
        '99614',
        '99615',
        '99620',
        '99621',
        '99622',
        '99624',
        '99625',
        '99626',
        '99627',
        '99628',
        '99630',
        '99631',
        '99632',
        '99633',
        '99634',
        '99636',
        '99637',
        '99638',
        '99639',
        '99640',
        '99641',
        '99643',
        '99644',
        '99645',
        '99647',
        '99648',
        '99649',
        '99650',
        '99651',
        '99652',
        '99653',
        '99654',
        '99655',
        '99656',
        '99657',
        '99658',
        '99659',
        '99660',
        '99661',
        '99662',
        '99663',
        '99664',
        '99665',
        '99666',
        '99667',
        '99668',
        '99669',
        '99670',
        '99671',
        '99672',
        '99674',
        '99676',
        '99677',
        '99678',
        '99679',
        '99680',
        '99681',
        '99682',
        '99683',
        '99684',
        '99685',
        '99686',
        '99688',
        '99689',
        '99690',
        '99691',
        '99692',
        '99693',
        '99694',
        '99695',
        '99701',
        '99702',
        '99703',
        '99704',
        '99705',
        '99706',
        '99709',
        '99712',
        '99714',
        '99720',
        '99721',
        '99722',
        '99723',
        '99724',
        '99726',
        '99727',
        '99729',
        '99730',
        '99732',
        '99733',
        '99734',
        '99736',
        '99737',
        '99738',
        '99739',
        '99740',
        '99741',
        '99742',
        '99743',
        '99744',
        '99745',
        '99746',
        '99747',
        '99748',
        '99749',
        '99750',
        '99751',
        '99752',
        '99753',
        '99754',
        '99755',
        '99756',
        '99757',
        '99758',
        '99759',
        '99760',
        '99761',
        '99762',
        '99763',
        '99764',
        '99765',
        '99766',
        '99767',
        '99768',
        '99769',
        '99770',
        '99771',
        '99772',
        '99773',
        '99774',
        '99775',
        '99776',
        '99777',
        '99778',
        '99780',
        '99781',
        '99782',
        '99783',
        '99784',
        '99785',
        '99786',
        '99788',
        '99789',
        '99790',
        '99791',
        '99801',
        '99820',
        '99824',
        '99825',
        '99826',
        '99827',
        '99829',
        '99830',
        '99832',
        '99833',
        '99835',
        '99836',
        '99840',
        '99841',
        '99901',
        '99903',
        '99918',
        '99919',
        '99921',
        '99922',
        '99923',
        '99925',
        '99926',
        '99927',
        '99929'
      ]
    }
  };

  static getZipcodeByState(state: string) {
    return this._STATES_WITH_ZIPCODES[state].codes;
  }

  static getStateMap() {
    return {
      AL: 'Alabama',
      AK: 'Alaska',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DE: 'Delaware',
      DC: 'District Of Columbia',
      FL: 'Florida',
      GA: 'Georgia',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PA: 'Pennsylvania',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming'
    };
  }
}
