import { State } from '../../Models/State';

export const ADD_CUSTOMER_ADDRESS = 'customer/addCustomerAddress';
export const GET_CURRENT_ADDRESS = 'customer/getCurrentAddress';
export const UPDATE_USER_PREFERENCES = 'customer/updateUserPreferences';
export const UPDATE_CUSTOMER = 'customer/updateCustomer';
export const UPDATE_CUSTOMER_AND_USER_INFO =
  'customer/updateCustomerAndUserInfo';
export const SET_CURRENT_EMAIL_WEEKLY = 'customer/setCurrentEmailWeekly';
export const GET_CUSTOMER_ADDRESS = 'customer/getCustomerAddress';
export const DELETE_CUSTOMER_ADDRESS = 'customer/deleteCustomerAddress';

export interface customerState {
  addressList: Array<any>;
  selectedAddress: any;
  state: State;
  showMessage?: boolean;
  visibleDropdown?: boolean;
}
