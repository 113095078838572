import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { addObjectParamsToPath, hrefToObject } from '../../../../Utils/Utils';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MixpanelService from './../../../../Services/MixpanelService';
import { RootState } from '../../../../Store/Reducers';

const sortOnList = [
  { label: 'Variety A-Z', checked: false },
  { label: 'Variety Z-A', checked: false },
  { label: 'Recommended', checked: true }
];
const SortOptionsDesktopComponent = () => {
  const history = useHistory();

  const filters: { [key: string]: any } = hrefToObject();
  const [sortSelected, setSortSelected] = useState(
    filters.sortOrder === 'desc'
      ? 'Variety Z-A'
      : filters.orderBy === 'variety'
      ? 'Variety A-Z'
      : 'Recommended'
  );

  const { date } = useSelector((state: RootState) => state.ProductReducer);
  const { search, filters_conditions } = useSelector(
    (state: RootState) => state.FilterReducer
  );
  const onChangeSort = async (value: any) => {
    const newFilters = { date, search_val: search, ...filters_conditions };
    switch (value.label) {
      case 'Variety A-Z':
        MixpanelService.track('sort_a_z');
        delete newFilters.orderBy;
        setSortSelected('Variety A-Z');
        newFilters.sortOrder = 'asc';
        newFilters.orderBy = 'variety';
        break;
      case 'Variety Z-A':
        MixpanelService.track('sort_z_a');
        delete newFilters.orderBy;
        setSortSelected('Variety Z-A');
        newFilters.sortOrder = 'desc';
        newFilters.orderBy = 'variety';
        break;
      default:
        setSortSelected('Recommended');
        delete newFilters.sortOrder;
        newFilters.orderBy = 'recommended';
        break;
    }
    history.push(addObjectParamsToPath('/search', newFilters));
  };

  const sortMenu = (
    <Menu>
      {sortOnList.map((value, index) => {
        return (
          <Menu.Item
            key={'input3-' + index}
            onClick={() => onChangeSort(value)}>
            <div className="form-check">
              <input
                className="form-check-input mr-2"
                type="radio"
                value={value.label}
                name="sort-price-desktop"
                id={'sort_' + index}
                defaultChecked={value.label === sortSelected}
                onChange={() => onChangeSort(value)}
              />
              <label
                className="normal-register-label"
                htmlFor={'sort_' + index}>
                {value.label}
              </label>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <>
      <Dropdown className="pointer" overlay={sortMenu} trigger={['click']}>
        <span className="ibf-text-navbar mr-2 ibf-btn-sort-desktop color-gray">
          Sort: <u> {sortSelected}</u>
        </span>
      </Dropdown>
    </>
  );
};

export default SortOptionsDesktopComponent;
