import React, { useRef, useState } from 'react';
import { sbxCoreService } from '../../../../../Network';
import { toast } from '../../../../../Utils/Utils';
import { Input, InputGroup, InputGroupAddon, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

interface Props {
  userId?: string;
  code?: string;
  close: () => void;
}

const RecoveryPasswordModal: React.FC<Props> = ({ userId, code, close }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const history = useHistory();

  const sendRequest = async () => {
    // e.preventDefault();

    switch (true) {
      case !userId || !code:
        return toast('Missing parameters', 'error');
      case password !== repeatPassword:
        return toast("Password doesn't match", 'error');
      case !password.length:
        return toast(
          'Please write a password of 8 characters minimum',
          'error'
        );
      default:
    }

    setLoading(true);
    const res: any = await sbxCoreService.requestChangePassword(
      userId,
      code,
      password
    );
    setLoading(false);
    if (res.success) {
      toast('Password changed successfully', 'success');
      history.push('/home');
      close();
    } else {
      return toast(res.error, 'error');
    }
  };

  return (
    <Modal isOpen className="ibf-login-modal-main">
      <div className="ibf-forgot-password-modal-home">
        <div className="ibf-login-modal-home-title my-4">
          <span>Choose your new password</span>
        </div>
        <div className="ibf-login-modal-home-body">
          <div>
            <div className="ibf-login-modal-password-input">
              <InputGroup>
                <Input
                  value={password}
                  placeholder="New password"
                  onKeyDown={(e: any) => {
                    if (e.key === 'Tab' && ref?.current) {
                      e.preventDefault();
                      ref.current.focus();
                    }
                    if (e.key === 'Enter') {
                      sendRequest();
                    }
                  }}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  type={showPassword ? 'text' : 'password'}
                />
                <InputGroupAddon addonType="append">
                  <Button onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? 'Hide' : 'Show'}
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>

            <div className="ibf-login-modal-password-input">
              <InputGroup>
                <Input
                  value={repeatPassword}
                  placeholder="Repeat new password"
                  innerRef={ref}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      sendRequest();
                    }
                  }}
                  onChange={(e) => setRepeatPassword(e.currentTarget.value)}
                  type={showRepeatPassword ? 'text' : 'password'}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={() => setShowRepeatPassword(!showRepeatPassword)}>
                    {showRepeatPassword ? 'Hide' : 'Show'}
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>

            <div className="ibf-login-modal-continue-btn text-center">
              <button
                type="button"
                onClick={sendRequest}
                className="btn btn-primary btn-block"
                disabled={loading}>
                Save new password and go back to login &#187;&nbsp;
                {loading && (
                  <FontAwesomeIcon icon={faCircleNotch} pulse={true} />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RecoveryPasswordModal;
