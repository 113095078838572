import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DayPicker from 'react-day-picker';
import ImageComponent from '../../ImageComponent/ImageComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  addObjectParamsToPath,
  appendTo,
  convertDateToNumberDate,
  convertNumberDateMMDDYYYY,
  getBoxQuantity,
  hrefContainsProperty,
  hrefToObject,
  removeFalsyValues
} from '../../../../Utils/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faChevronRight,
  faSquare
} from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import MixpanelService from '../../../../Services/MixpanelService';
import Environment from '../../../../Environment';
import useDeviceDetect from '../../../../Hooks/useDeviceDetect';
import LoadingSpinner from '../../LoadingSpinner';
import { RootState } from '../../../../Store/Reducers';
import { actionsModal } from '../../../../Store/Modal/Slice';
import {
  CONFIRM_DELIVERY_DATE_MODAL,
  DELIVERY_SCHEDULE_MODAL
} from '../../../../Store/Modal/Types';
import { actionsProduct } from '../../../../Store/Product/Slice';
import CartProduct from '../../../../Models/CartProduct';
import AddToCartToast from '../../AddToCartToast';
import { plainToClass } from 'class-transformer';
import flowerDeliveryDateImage from '../../../../Assets/Images/Flowers-open-up.png';
import { TrackEventMonitor } from '../../../../Classes/TrackEvent';
import { actionsOrder } from '../../../../Store/Order/Slice';
import { useLocation } from 'react-router';
import { OrderItem } from '../../../../Models/Order';
import { authReducer } from '../../../../Store/Selectors';
import AlertComponent from '../../AlertComponent';

type Props = {
  className?: string;
};

const ConfirmDeliveryDateModal: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { search } = useSelector((state: RootState) => state.FilterReducer);
  const { manager, user } = useSelector(
    (state: RootState) => state.AuthReducer
  );
  const { headerHeight } = useSelector(
    (state: RootState) => state.DisplayReducer
  );
  const { config } = useSelector(authReducer);

  const [almostOneSelected, setAlmostOneSelected] = useState(false);
  const [auxDisabledDays, setAuxdisabledDays] = useState<Array<any> | null>(
    null
  );
  const dates: number[] = [];
  const [selectedDates, setSelectedDates] = useState(dates);
  const { isMobile } = useDeviceDetect();
  const { date: reduxDate } = useSelector(
    (state: RootState) => state.ProductReducer
  );
  const { data, open } = useSelector(
    (state: RootState) => state.ModalReducer.confirmDeliveryDateModal
  );
  const { disabledDays, modifiers, modifiersStyles } = useSelector(
    (state: RootState) => state.CalendarReducer
  );
  React.useEffect(() => {
    dispatch(actionsOrder.cleanRepurchase());
  }, [dispatch]);

  useEffect(() => {
    if (data && data.boxes) {
      let freeDays: any = [{}, {}, {}, {}, {}, {}, {}];
      let growers: any = {};
      data.boxes.forEach((item: any) => {
        if (item?.grower_obj?._KEY) {
          growers[item.grower_obj._KEY] = true;
        }
      });
      [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday'
      ].forEach((day: string, numberDay: number) => {
        data.boxes.forEach((item: any) => {
          if (item.grower_obj && item.grower_obj[`shipday_${day}`]) {
            freeDays[(item.grower_obj[`shipday_${day}`] + numberDay) % 7][
              item.grower_obj._KEY
            ] = true;
          }
        });
      });
      const newBlocked = [0, 1, 2, 3, 4, 5, 6].filter(
        (_, index) =>
          Object.keys(freeDays[index]).length < Object.keys(growers).length
      );
      setAuxdisabledDays([...disabledDays, { daysOfWeek: newBlocked }]);
    }
  }, [data, disabledDays]);

  const handleDate = async (date: Date, modifiers: any) => {
    if (modifiers.disabled) {
      return;
    }
    MixpanelService.track('select_date');
    const base: { [key: string]: any } = {
      date: convertDateToNumberDate(date),
      search_val: search
    };

    if (!data?.isMultipleSelection) {
      if (!reduxDate || data.isProductDetailView) {
        dispatch(
          actionsProduct.setProductDate({ date: convertDateToNumberDate(date) })
        );
      }

      if (data?.favorites) {
        base['favorites'] = true;
        MixpanelService.track('favorite_shop_all', {
          date: convertDateToNumberDate(date)
        });
      }
      if (data?.detail) {
      } else {
        const params: any = Object.assign(
          base,
          removeFalsyValues(hrefToObject())
        );
        params.date = convertDateToNumberDate(date);
        const pathname =
          location.pathname === '/product' && hrefContainsProperty('id')
            ? location.pathname
            : '/search';
        history.push(addObjectParamsToPath(pathname, params));

        if (window.dataLayer) {
          window.dataLayer.push({ event: 'Date selected', value: '' });
        }

        TrackEventMonitor.newEvent({
          props: {
            prev_date: Number(reduxDate) ?? '',
            date_selected: params.date
          },
          metadata: {
            name: 'ibf_eta_selected'
          }
        });
      }

      dispatch(
        actionsModal.setCloseModal({ modal: CONFIRM_DELIVERY_DATE_MODAL })
      );
    } else {
      const cdate = convertDateToNumberDate(date);
      const index = selectedDates.indexOf(cdate);
      if (index > -1) {
        selectedDates.splice(index, 1);
      } else {
        selectedDates.push(cdate);
      }
      setSelectedDates([...selectedDates]);
      setAlmostOneSelected(selectedDates.length > 0);
    }
  };

  const addToCartWithDates = () => {
    let products: Array<CartProduct> = [];
    let images: Array<string> = [];
    let cart_box_key = '';
    data.boxes.forEach((item: any) => {
      if (item.product_group !== 'Custom Box') {
        images.push(
          `${Environment.publicPath}varieties/variety_${item.variety_key}.jpg`
        );
      }
    });
    selectedDates.forEach((delivery_date) => {
      if (data.boxes && data.boxes.length > 1) {
        products = [
          ...products,
          ...data?.boxes?.reduce((total: Array<any>, box: any) => {
            if (box.product_group !== 'Custom Box') {
              total.push({
                variety: box.variety_key,
                product_group: box.product_group_key,
                quantity: box.total_stems,
                length: box.length,
                grower: data.boxes[0].grower_obj._KEY,
                delivery_date: delivery_date,
                customer: user.metadata.customer,
                box_only: box.box_only,
                repurchase_cartbox_item: box.cart_box_item_key,
                customizable: box.customizable
              });
            } else {
              cart_box_key = box._KEY;
            }
            return total;
          }, [])
        ];
      } else {
        const box = data.boxes[0];
        cart_box_key = box._KEY;
        products = [
          ...products,
          plainToClass(CartProduct, {
            variety: box.variety_key,
            product_group: box.product_group_key,
            quantity: getBoxQuantity(box),
            length: box.length,
            grower: box.grower_obj._KEY,
            delivery_date: delivery_date,
            customer: user.metadata.customer,
            box_only: box.box_only,
            repurchase_cartbox_item: box.cart_box_item_key,
            customizable: box.customizable
          })
        ];
      }
    });
    appendTo(
      'root',
      data.boxes[0]._KEY,
      <AddToCartToast
        titleToast="Adding to your cart"
        show
        navHeight={headerHeight}
        body={
          data.boxes.length > 1
            ? undefined
            : data.boxes[0].product_name
            ? data.boxes[0].product_name
            : `${data.boxes[0].product_group} ${data.boxes[0].variety_grade} ${data.boxes[0].length} cm`
        }
        flowerImg={data.boxes.length > 1 ? undefined : images[0]}
        flowerImgs={data.boxes.length > 1 ? images : undefined}
        productKey={data.boxes[0]._KEY}
      />
    );

    const propsEvent = {
      cart_box_key,
      selected_dates: selectedDates,
      box_items: products.map((product) => ({
        variety_key: product.variety,
        product_group_key: product.product_group,
        quantity: product.quantity,
        length: product.length,
        grower_key: product.grower,
        delivery_date: product.delivery_date,
        customer: product.customer,
        box_only: product.box_only,
        cart_box_item_key: product.repurchase_cartbox_item,
        repurchase_cartbox_item: product.repurchase_cartbox_item,
        customizable: product.customizable
      }))
    };

    TrackEventMonitor.newEvent({
      props: propsEvent,
      metadata: { name: 'ibf_reorder_placed' }
    });

    dispatch(
      actionsProduct.addProduct({
        params: { products },
        customer: user.metadata.customer
      })
    );

    dispatch(
      actionsModal.setCloseModal({ modal: CONFIRM_DELIVERY_DATE_MODAL })
    );
  };

  const openDeliverySchedule = useCallback(() => {
    dispatch(actionsModal.setOpenModal({ modal: DELIVERY_SCHEDULE_MODAL }));
  }, [dispatch]);

  const getLabelDate = (date: number) => {
    if (config.especial_days) {
      const specialDate = config.especial_days.find((especialDays) =>
        especialDays.dates.find((especialDate) => especialDate === date)
      );
      if (specialDate) {
        return specialDate.label;
      }
    }
    return '';
  };

  function renderDay(day: Date) {
    const date = convertNumberDateMMDDYYYY(convertDateToNumberDate(day));
    const dateNumber = day.getDate();
    const today = convertNumberDateMMDDYYYY(new Date());

    const label = getLabelDate(convertDateToNumberDate(day));
    return (
      <div
        style={
          today === date
            ? { padding: '0.2em', border: '2px solid black', width: '43px' }
            : { padding: '0.2em', width: '35px' }
        }>
        {label && (
          <div
            className="text-break"
            style={{ fontSize: '0.5em', width: '100%', height: '100%' }}>
            {label}
          </div>
        )}
        <div>{dateNumber}</div>
        {today === date && (
          <div style={{ fontSize: '0.5em', width: '100%', height: '100%' }}>
            TODAY
          </div>
        )}
      </div>
    );
  }
  const toggle = () => {
    dispatch(
      actionsModal.setCloseModal({ modal: CONFIRM_DELIVERY_DATE_MODAL })
    );

    let cart_box_key = '';
    const products = data?.boxes?.reduce((total: {}[], box: OrderItem) => {
      if (box.product_group !== 'Custom Box') {
        if (data.boxes.length === 1) {
          cart_box_key = box._KEY ?? '';
        }
        total.push({
          variety_key: box.variety_key,
          product_group_key: box.product_group_key,
          quantity: box.total_stems ?? getBoxQuantity(box),
          length: box.length,
          uom: box.masterlist_uom,
          grower_key: data.boxes[0].grower_obj._KEY,
          customer: user.metadata.customer,
          box_only: box.box_only,
          cart_box_item_key: box.cart_box_item_key,
          repurchase_cartbox_item: box.cart_box_item_key,
          customizable: box.customizable
        });
      } else {
        cart_box_key = box._KEY ?? '';
      }

      return total;
    }, []);

    if (data.isMultipleSelection) {
      const propsEvent = {
        cart_box_key,
        selected_dates: selectedDates,
        box_items: products
      };

      TrackEventMonitor.newEvent({
        props: propsEvent,
        metadata: { name: 'ibf_reorder_cancel' }
      });
    }
  };

  return (
    <div>
      <Modal isOpen={open} size="lg" className={className}>
        {data?.isMultipleSelection && (
          <ModalHeader toggle={toggle}>
            {' '}
            Select one or multiple delivery dates:{' '}
          </ModalHeader>
        )}
        {!data?.isMultipleSelection && (
          <ModalHeader
            toggle={() => {
              toggle();
              history.push('/home');
            }}>
            {' '}
            Select a delivery date:{' '}
          </ModalHeader>
        )}

        <ModalBody className="text-center">
          <AlertComponent
            color={'#ffffce'}
            borderColor={'black'}
            isOpen={config?.calendarAlert?.visible}
            children={
              <>
                <FontAwesomeIcon
                  icon={faBell}
                  color={'black'}
                  size={'2x'}
                  className="mr-2"
                />
                <span
                  className={`color-black ${
                    isMobile ? 'ibf-font-14 text-center' : 'ibf-font-20'
                  }`}>
                  {config?.calendarAlert?.message}
                  {isMobile ? <br /> : null}
                  <b>{config?.calendarAlert?.boldText}</b>
                </span>
              </>
            }
          />
          {data?.isMultipleSelection && almostOneSelected && (
            <div className="ibf-calendar-multiselect-message">
              <div>
                <p>
                  {' '}
                  {selectedDates.length} date
                  {selectedDates.length > 1 ? 's' : ''} selected (you can select
                  multiple dates and delete items in your checkout){' '}
                </p>
              </div>

              <button
                className="btn btn-primary ibf-calendar-multiselect-button"
                onClick={addToCartWithDates}>
                {' '}
                I selected my dates, add to my cart{' '}
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          )}
          {Object.keys(modifiers).length === 0 ? (
            <LoadingSpinner height="50vh" />
          ) : (
            <>
              <DayPicker
                modifiers={
                  auxDisabledDays
                    ? { ...modifiers, disabledDays: auxDisabledDays }
                    : modifiers
                }
                disabledDays={auxDisabledDays || disabledDays}
                modifiersStyles={modifiersStyles}
                onDayClick={handleDate}
                numberOfMonths={2}
                renderDay={renderDay}
              />

              <div className="p-3 pt-0 text-left">
                {!data?.isMultipleSelection && (
                  <div className="ibf-calendar-availability">
                    <div className={`${isMobile ? 'mr-2 w-100' : ''}`}>
                      <FontAwesomeIcon
                        color="#8cd198"
                        className="mr-2"
                        icon={faSquare}
                      />
                      <span>Full availability/shipping</span>
                      <br />
                      <span
                        onClick={openDeliverySchedule}
                        className="pointer underline">
                        (see delivery scheme)
                      </span>
                    </div>

                    <div>
                      <FontAwesomeIcon
                        color="#EECD81"
                        className="mr-2"
                        icon={faSquare}
                      />
                      <span>
                        Limited availability due to 1 to 3 days shipping options
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter className="background-yellow">
          <div className="ibf-calendar-tip border-0">
            <div className="d-flex align-items-center">
              <ImageComponent
                className="mr-2"
                imageUrl={`${Environment.publicPath}images/${
                  manager?.email?.split('@')[0]
                }.png`}
                width="35px"
              />
              <span>
                For events we advise you to{' '}
                <b>choose a delivery date 3-5 days before</b>, because flowers
                come so fresh that they need time to fully open:
              </span>
            </div>
            <div>
              <img
                src={flowerDeliveryDateImage}
                className="img-fluid"
                alt="delivery_date_modal_image"
              />
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmDeliveryDateModal;
