import React, { useRef, useState } from 'react';
import BoxProductList from './BoxProductList';
import BoxProductFilter from './BoxProductFilter/BoxProductFilter';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../Models/State';
import BoxLoadingSpinner from './BoxLoadingSpinner';
import { RootState } from '../../../Store/Reducers';
import { actionsLoading } from '../../../Store/Loading/Slice';
import { actionsCustomBox } from '../../../Store/CustomBox/Slice';
import { customBoxReducer, modalReducer } from '../../../Store/Selectors';

const BoxProductsComponent = () => {
  const [filterMobileView, setFilterMobileView] = useState(false);
  const toggleFilterMobileView = () => setFilterMobileView(!filterMobileView);
  const [openPopover, setOpenPopover] = useState(true);
  const refModal = useRef<HTMLDivElement>(null);

  const {
    filter_conditions,
    search,
    totalPages,
    currentPage,
    state,
    currentPercentage
  } = useSelector(customBoxReducer);
  const { CUSTOM_BOX_MODAL } = useSelector(modalReducer);
  const dispatch = useDispatch();
  const [triggerScroll, setTriggerScroll] = useState(false);
  const { customer } = useSelector(
    (state: RootState) => state.AuthReducer.user.metadata
  );
  const loadFunc = async () => {
    const filters = {
      ...filter_conditions,
      grower: [CUSTOM_BOX_MODAL.data.grower.key]
    };

    dispatch(
      actionsCustomBox.searchInventory({
        params: {
          conditions: {
            search: search,
            custom: true,
            currentPercentage: currentPercentage,
            filters: filters
          },
          customer: customer,
          eta: CUSTOM_BOX_MODAL.data.eta_date,
          page: currentPage + 1,
          pageSize: 120
        },
        moreProducts: true
      })
    );

    dispatch(actionsCustomBox.setBoxFilters({ conditions: filters, search }));
  };

  const [pagePosition, setPagePosition] = useState(0);

  const handleScroll = (event: any) => {
    if (refModal && refModal.current && !inventoryLoading) {
      // console.count('handleScroll');
      const target = event.target;

      if (target.scrollTop !== 0 && !triggerScroll) {
        setPagePosition(target.scrollTop);
      }

      if (
        target.scrollHeight - target.scrollTop <= target.clientHeight + 3000 &&
        totalPages > 1 &&
        totalPages > currentPage
      ) {
        if (state !== State.PENDING) {
          dispatch(actionsLoading.setMoreProductsLoading());
          loadFunc();
        }
      }
      if (refModal.current.scrollTop >= 80) {
        setOpenPopover(false);
      }
      // return refModal.current.scrollTop >= 80 && setOpenPopover(false);
    }
  };

  const { inventoryLoading, moreProductsLoading } = useSelector(
    (state: RootState) => state.LoadingReducer
  );

  React.useEffect(() => {
    if (pagePosition > 0 && inventoryLoading) {
      setTriggerScroll(true);
    }
  }, [inventoryLoading, pagePosition]);

  React.useEffect(() => {
    if (refModal.current && triggerScroll && !inventoryLoading) {
      refModal.current.scroll({ top: pagePosition, behavior: 'auto' });
      setTriggerScroll(false);
    }
  }, [pagePosition, triggerScroll, inventoryLoading]);

  return (
    <div
      className="container-fluid pr-0"
      id="products-container"
      style={{ background: '#f2f2f2' }}>
      {/*<div id="products-container" style={{background: '#f2f2f2', width:'86vw', overflow:'auto'}}>*/}
      <>
        <div
          className="d-flex overflow-auto"
          ref={refModal}
          onScroll={handleScroll}
          style={{ height: '70vh' }}>
          {inventoryLoading && !moreProductsLoading ? (
            <BoxLoadingSpinner
              height="60vh"
              message="Updating the live inventory"
              className="d-flex justify-content-center align-items-center w-100"
            />
          ) : (
            <BoxProductList
              setOpenPopover={setOpenPopover}
              openPopover={openPopover}
              modalWidth={refModal?.current?.offsetWidth}
              toggleMobileView={toggleFilterMobileView}
            />
          )}

          <div className="ibf-filter-list ibf-filter-pr4">
            <BoxProductFilter
              showMobileView={filterMobileView}
              toggleMobileView={toggleFilterMobileView}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export default BoxProductsComponent;
