import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addObjectParamsToPath } from '../../../Utils/Utils';
import { Color } from '../../../Models/Color';
import { faAngleDoubleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Environment from '../../../Environment';
import { RootState } from '../../../Store/Reducers';
import MixpanelService from '../../../Services/MixpanelService';
import useOutsideAlerter from '../../../Hooks/useOutsideAlerter';

type Props = {
  toggleMenu?: () => void;
  mobile?: boolean;
  closeMenuMobile?: () => void;
};
const ColorList: React.FC<Props> = ({
  toggleMenu,
  mobile,
  closeMenuMobile
}) => {
  const { date } = useSelector((state: RootState) => state.ProductReducer);
  const { navHeight } = useSelector((state: RootState) => state.DisplayReducer);
  const { colorList } = useSelector(
    (state: RootState) => state.DashboardReducer
  );
  const history = useHistory();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, toggleMenu);
  const searchProduct = async (all: boolean, item?: Color) => {
    closeMenuMobile && closeMenuMobile();
    toggleMenu && toggleMenu();
    const filters = all ? {} : item && { color: [item._KEY] };
    const params = { date, search_val: '', ...filters };
    MixpanelService.track('colors_menu_item', {
      name: item ? item.color_name : 'All colors'
    });
    history.push(addObjectParamsToPath('/search', params));
  };
  const searchCategory = async (key: string) => {
    closeMenuMobile && closeMenuMobile();
    toggleMenu && toggleMenu();
    const filters = { color: Environment.colorList[key] };
    const params = { date, search_val: '', ...filters };
    MixpanelService.track('colors_menu_item', { name: key });
    history.push(addObjectParamsToPath('/search', params));
  };

  return (
    <div
      ref={wrapperRef}
      className={`d-flex flex-column ${
        mobile ? '' : 'dropdown-wrapper shadow bg-white'
      }`}
      style={{ top: `${navHeight + 61}px` }}>
      <span className="selected-item d-none d-lg-block" />
      <div className="row justify-content-between w-100 ">
        <div className="d-flex flex-column ">
          <span
            className={`underline font-weight-bold pointer list-item my-3 pt-1 list-flower-category ${
              mobile ? 'pl-3 pr-2' : 'pl-5'
            }`}
            key="All"
            onClick={() => searchProduct(true)}>
            All Colors
          </span>
        </div>
        <div className="ibf-link d-none d-lg-block pt-2" onClick={toggleMenu}>
          Close
        </div>
      </div>
      <div
        className={`d-flex flex-wrap flex-row dropdown-items-wrapper overflow-auto ${
          mobile ? 'pl-3' : 'pl-5'
        }`}>
        {Object.keys(colorList).map((key: string, index: number) => {
          return (
            key !== 'All multiple colors' && (
              <ul
                key={'colors_' + key}
                className="list-group list-unstyled dropdown-items-width">
                <span
                  className="font-weight-bold underline pointer ibf-font-14"
                  onClick={() => searchCategory(key)}>
                  {key}{' '}
                  <FontAwesomeIcon className="ml-1" icon={faAngleDoubleRight} />
                </span>
                {colorList[key].map((color: Color) => {
                  return (
                    <li
                      className="underline pointer ibf-font-14 pb-2 pt-1 d-flex"
                      key={color._KEY + '_' + index}
                      onClick={() => searchProduct(false, color)}>
                      <div
                        className="ibf-nav-color-container rounded mr-2"
                        style={{ backgroundColor: color.html_code_1 }}
                      />
                      <span className="mr-4">{color.color_name}</span>
                    </li>
                  );
                })}
              </ul>
            )
          );
        })}

        <ul
          key={'colors_All multiple colors'}
          className="list-group list-unstyled dropdown-items-width">
          <span
            className="font-weight-bold underline pointer ibf-font-14"
            onClick={() => searchCategory('All multiple colors')}>
            All multiple colors{' '}
            <FontAwesomeIcon className="ml-1" icon={faAngleDoubleRight} />
          </span>
          {colorList['All multiple colors'].map((color: Color) => {
            return (
              <li
                className="underline pointer ibf-font-14 pb-2 pt-1 d-flex"
                key={color._KEY + '_all1'}
                onClick={() => searchProduct(false, color)}>
                <div
                  className="ibf-nav-color-container rounded mr-2"
                  style={{ backgroundColor: color.html_code_1 }}
                />
                <span className="mr-4">{color.color_name}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default ColorList;
