import { runCS } from '../Network';
import Environment from '../Environment';

export const removeFromCustomBox = (
  customer: string,
  itemKey: string,
  quantity: number,
  item: any,
  cartbox: any,
  doNotCloseBox: any = false
) => {
  return runCS(Environment.cloudscripts.remFromBoxV2, {
    customer,
    itemKey,
    quantity,
    item,
    cartbox,
    doNotCloseBox
  }).then((res) => res);
};

export const cancelBox = (customer: string, cartboxes: Array<string>) => {
  return runCS(Environment.cloudscripts.cancelBox, {
    cartboxes,
    customer
  })
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
};

export function listCart(customer: string) {
  return runCS(Environment.cloudscripts.listCart, {
    customer
  })
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
}

export function deleteKometOrders() {
  return runCS(Environment.cloudscripts.delete_komet_orders, {})
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
}

export const cancelVoucher = (customer: string, voucher: string) => {
  return runCS(Environment.cloudscripts.cancelVoucher, { customer, voucher })
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
};
