import React from 'react';
import { roundUp } from '../../Utils/Utils';
import cc_amex from '../../Assets/Images/cc_amex_american_express.svg';
import cc_diners from '../../Assets/Images/cc_diners_club.svg';
import cc_discover from '../../Assets/Images/cc_discover.svg';
import cc_jcb from '../../Assets/Images/cc_jcb.svg';
import cc_mastercard from '../../Assets/Images/cc_mastercard.svg';
import cc_visa from '../../Assets/Images/cc_visa.svg';
const getCCIcon = (cc_name: string) => {
  switch (cc_name) {
    case 'American Express':
      return cc_amex;
    case 'Diners Club':
      return cc_diners;
    case 'Discover':
      return cc_discover;
    case 'JCB':
      return cc_jcb;
    case 'MasterCard':
      return cc_mastercard;
    case 'Visa':
      return cc_visa;
    default:
      return undefined;
  }
};

const getTotalBunches = (cart_boxes: any) => {
  let total_bunches = 0;
  let ave_bunch_price = 0;
  let extra_freight_cost = 0;

  cart_boxes.forEach((item: any) => {
    total_bunches += item.total_items;
    ave_bunch_price += item.items[0].price * item.items[0].quantity;
    extra_freight_cost += item.extra_freight_cost;
  });

  ave_bunch_price = ave_bunch_price / total_bunches;

  return {
    total_bunches: total_bunches,
    ave_bunch_price: ave_bunch_price,
    extra_freight_cost: extra_freight_cost
  };
};
function SideInfoOrderDetailComponent(props: any) {
  const { subtotal, total, discount } = props.cart_boxes.reduce(
    (obj: any, i: any) => {
      obj.subtotal += i.price;
      obj.total += i.price * (1 - i.off);
      obj.discount += i.price * i.off;
      return obj;
    },
    { subtotal: 0, total: 0, discount: 0 }
  );

  const {
    company_name,
    customer_date,
    street,
    city,
    state_iso,
    zipcode,
    country_iso,
    card_name,
    last_name,
    boxes
  } = props.data;

  const {
    total_bunches,
    ave_bunch_price,
    extra_freight_cost
  } = getTotalBunches(props.cart_boxes);

  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  let date: any = new Date(customer_date);
  date = date.toLocaleDateString('en-US', options);

  return (
    <div className="ibf-order-detail-sidebar">
      <div className="sidebar-header">
        <b className="ibf-text-purple">Order Detail</b>
      </div>

      <div className="sidebar-detail">
        <div className="sidebar-detail-title">
          <b>Customer Detail</b>
        </div>

        <p>
          {company_name}
          <br />
          {last_name}
          <br />
          {date}
        </p>

        <div className="sidebar-detail-title">
          <b>Shipping Address</b>
        </div>

        <p>
          {street}
          <br />
          {city}, {state_iso} {zipcode}
          <br />
          {country_iso}
        </p>

        <div className="sidebar-detail-title">
          <b>Payment Method</b>
        </div>

        {card_name && (
          <p className="card-payment-method">
            <span className="align-items-center flex">
              <img
                className="card-payment-method-icon"
                src={getCCIcon(card_name.split('-')[1])}
                alt=""
              />
              <span className="h6 m-0 mr-1">••••</span>
              {card_name.split('-')[0]}
            </span>
          </p>
        )}
      </div>

      <div className="sidebar-detail-last">
        <ul className="list-unstyled">
          <div className="d-flex">
            <small>Total boxes:</small>
            <small className="ml-auto">{boxes}</small>
          </div>
          <div className="d-flex">
            <small>Total bunches:</small>
            <small className="ml-auto">{total_bunches}</small>
          </div>
          <div className="d-flex">
            <small>Ave. bunch price:</small>
            <small className="ml-auto">${roundUp(ave_bunch_price)}</small>
          </div>
        </ul>
      </div>

      <div className="sidebar-detail-last">
        <ul className="list-unstyled">
          <div className="d-flex">
            <small>Subtotal:</small>
            <small className="ml-auto">${roundUp(subtotal)}</small>
          </div>
          <div className="d-flex">
            <small>Extra Freight Costs:</small>
            <small className="ml-auto">${roundUp(extra_freight_cost)}</small>
          </div>
          <div className="d-flex">
            <small>Discount:</small>
            <small className="ml-auto ibf-text-red">${roundUp(discount)}</small>
          </div>

          <div className="d-flex">
            <b>Total Amount:</b>
            <b className="ml-auto">${roundUp(total)}</b>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default SideInfoOrderDetailComponent;
