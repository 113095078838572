import React from 'react';
import { useSelector } from 'react-redux';
import DashboardComponent from '../../DashboardComponent/DashboardComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { displayReducer } from '../../../Store/Selectors';

type Props = {
  closeMenu: () => void;
  openMenu: () => void;
};

const MyAccountMenu: React.FC<Props> = ({ closeMenu, openMenu }) => {
  const { navHeight, navOptions } = useSelector(displayReducer);

  const toggleMenu = () => {
    if (navOptions && navOptions.myAccount) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  return (
    <>
      <span
        className="ibf-text-navbar d-none d-sm-inline pointer"
        onClick={toggleMenu}>
        <span className="mr-1">My Account</span>{' '}
        <FontAwesomeIcon color="gray" icon={faChevronDown} />{' '}
      </span>

      {navOptions.myAccount && (
        <>
          <span className="my-account-line d-none d-lg-block" />
          <div
            className={'d-flex flex-column dropdown-wrapper shadow bg-white'}
            style={{ top: `${navHeight + 61}px` }}>
            <DashboardComponent toggleDashboard={closeMenu} />
          </div>
        </>
      )}
    </>
  );
};

export default MyAccountMenu;
