import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersList, updateUser } from '../../../../Services/UserService';
import { UserResponse } from '../../../../Models/UserResponse';
import {
  cellphoneOptions,
  getTrueKeysUserConfig,
  toast
} from '../../../../Utils/Utils';
import {
  faEdit,
  faEnvelope,
  faMobile,
  faPhone,
  faUnlock,
  faUser,
  faUserTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ADD_USER_MODAL,
  CONFIRM_MODAL,
  USERS_LIST_MODAL
} from '../../../../Store/Modal/Types';
import { RootState } from '../../../../Store/Reducers';
import { actionsModal } from '../../../../Store/Modal/Slice';

type Props = {
  className?: string;
};

const UsersModal: React.FC<Props> = ({ className }) => {
  const { user } = useSelector((state: RootState) => state.AuthReducer);
  const { usersListModal } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const [userList, setUserList] = useState<Array<UserResponse>>([]);
  const customer = user.metadata.customer;

  const dispatch = useDispatch();

  const toggleEdit = (user: UserResponse) => {
    dispatch(
      actionsModal.setOpenModal({
        modal: ADD_USER_MODAL,
        data: { user: user, type: 'edit', getUsers: getUsers() }
      })
    );
  };

  const toggleConfirm = async (user: UserResponse) => {
    const data = {
      body: `Are you sure? You are about to delete ${user.first_name} ${user.last_name}`,
      okClick: () => onDelete(user),
      cancelText: 'No',
      okText: 'Yes',
      footerEnabled: true
    };
    dispatch(actionsModal.setOpenModal({ modal: CONFIRM_MODAL, data: data }));
  };

  const toggle = () => {
    dispatch(actionsModal.setCloseModal({ modal: USERS_LIST_MODAL }));
  };

  const onDelete = async (user: UserResponse) => {
    const userDeleted = await updateUser({
      row: { _KEY: user._KEY, active: false },
      customerUserConfig: null
    });
    if (userDeleted.success) {
      await getUsers();
      toast('User was deleted');
      toggle();
    } else {
      toast('User was not deleted');
      toggle();
    }
  };

  const getUsers = useCallback(async () => {
    const users = await getUsersList(customer);
    if (users.box.users) {
      setUserList(users.box.users);
    }
  }, [customer]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <div>
      <Modal
        isOpen={usersListModal.open}
        toggle={toggle}
        className={`${className} modal-lg `}>
        <ModalHeader toggle={toggle}>Users</ModalHeader>
        <div className="modal-body modal-size">
          <div className="container-fluid mt-3">
            <ul className="p-0 list-group">
              {userList.map((user: UserResponse, index) => (
                <li className="list-group-item" key={index}>
                  <div className="row">
                    <div className="col-1">
                      <FontAwesomeIcon icon={faUser} size="2x" />
                    </div>
                    <div className="col-12 col-lg-7">
                      <span className="h4">
                        {user.first_name} {user.last_name}{' '}
                        {`(${user.is_admin ? 'Administrator' : 'User'})`}
                      </span>
                      <p className="m-0">
                        <span className="main-text">
                          <FontAwesomeIcon icon={faEnvelope} /> Email:{' '}
                        </span>
                        {user.user_email}
                      </p>
                      <p className="m-0">
                        <span className="main-text">
                          <FontAwesomeIcon icon={faPhone} /> Phone:{' '}
                        </span>
                        {user.phone || 'n/a'}
                      </p>
                      {user.is_admin && (
                        <>
                          <p className="m-0">
                            <span className="main-text">
                              <FontAwesomeIcon icon={faMobile} /> Cellphone:{' '}
                            </span>
                            {user.is_admin && user.smsConfig
                              ? user.smsConfig.cellphone
                              : user.cellphone || 'n/a'}
                          </p>
                          {Object.keys(cellphoneOptions).map(
                            (key: string, index) => {
                              return (
                                <div
                                  className="form-check ibf-font-12"
                                  key={index}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name={key || ''}
                                    disabled
                                    value={key || ''}
                                    id={key}
                                    checked={
                                      user.smsConfig &&
                                      user.smsConfig[
                                        cellphoneOptions[key].value
                                      ]
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={key}>
                                    {cellphoneOptions[key].label}
                                  </label>
                                </div>
                              );
                            }
                          )}
                        </>
                      )}
                      <p className="m-0">
                        <span className="main-text">Skype: </span>
                        {user.skype || 'n/a'}
                      </p>
                      <p className="m-0">
                        <span className="main-text">
                          <FontAwesomeIcon icon={faUnlock} /> User options:{' '}
                        </span>
                        {getTrueKeysUserConfig(user.config)}
                      </p>
                    </div>
                    <div className="col-12 col-lg-4 mt-2 mt-lg-0">
                      <button
                        className="btn btn-purple btn-sm mr-2"
                        onClick={() => toggleEdit(user)}>
                        <FontAwesomeIcon icon={faEdit} />
                        Edit User
                      </button>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => toggleConfirm(user)}>
                        <FontAwesomeIcon icon={faUserTimes} />
                        Delete User
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UsersModal;
