import * as React from 'react';

const HeaderNewSiteComponent = () => {
  return (
    <div
      className={`ibf-header-background p-2 d-flex justify-content-center border-bottom`}>
      <div className="ibf-header-text d-flex flex-column ">
        <div className="d-flex mx-1 text-break">
          <span>
            This online marketplace will be replaced by February 15th.{' '}
            <u
              className="pointer"
              onClick={() => {
                window.location.href = `https://beta.ibuyflowers.com/`;
              }}>
              {' '}
              You already have access to the new version »{' '}
            </u>
          </span>
        </div>
      </div>
    </div>
  );
};

export default HeaderNewSiteComponent;
