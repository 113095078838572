export default class InventoryProduct {
  name!: string;
  key?: string;
  product_group!: string;
  colors!: Array<string>;
  grower!: string;
  delivery_date?: number;
  starting_price?: number;
  images!: Array<string>;
  favorite!: boolean;
  masterlist!: string;
  custom_percentage!: number;
  customizable!: boolean;
  country!: string;
  country_iso!: string;
  warning!: any;
  inventory!: string;
  length!: number;
  stems_per_bunch!: number;
  special!: boolean;
  holiday!: boolean;
  packing_date!: number;
  grower_name!: string;
  variety!: string;
  variety_name!: string;
  charge_date!: number;
  available!: number;
  total_box!: number;
  eta!: number;
  off!: number;
  tbs!: boolean;
  base_price!: number;
  price!: number;
  price_own_box!: number;
  grower_box_cost!: number;
  product_group_name!: string;
  // getImage(): string {
  //   return `${Environment.publicPath}${this.img}`;
  // }
}
