import React, { useState } from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import FooterComponent from '../HomeComponent/FooterComponent/FooterComponent';
import footerImage from '../../Assets/Images/modal-footer.png';
import { addObjectParamsToPath, hrefToObject, toast } from '../../Utils/Utils';
import {
  deleteAllCookies,
  loginAsCustomer,
  setCookie
} from '../../Services/AuthService';
import MessageForgotPassword from '../Shared/Modal/LoginModal/WelcomeModal/MessageForgotPassword';
import { sbxSessionService } from '../../Network';

const LoginAsCustomer = () => {
  const [urlReference] = useState(hrefToObject());
  const [email, setEmail] = useState('@ibuyflowers.com');
  const [password, setPassword] = useState('');
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [site, setSite] = useState('beta');
  const removeAll = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_key');
    localStorage.clear();
    deleteAllCookies();
    sbxSessionService.logout();
  };

  React.useEffect(() => {
    setTimeout(() => {
      removeAll();
    }, 3000);
  }, []);

  const verifyAccount = async () => {
    setLoadingVerification(true);
    removeAll();
    const res = await loginAsCustomer({
      login: email,
      password,
      customer: urlReference?.customer
    });
    setLoadingVerification(false);
    if (res.success) {
      toast('login successful', 'success');

      localStorage.setItem('token', res.response.token);
      localStorage.setItem('user_key', res.response.userKey);
      setCookie('token', res.response.token);
      setCookie('user_key', res.response.userKey);
      if (urlReference?.customer) {
        setCookie('customer', urlReference?.customer as string);
      }

      if (site && site === 'beta') {
        if (urlReference?.customer) {
          setCookie('customer', urlReference?.customer as string);
        }
        window.location.href = 'https://beta.ibuyflowers.com/';
      } else {
        window.location.href = addObjectParamsToPath('/#/home', {
          token: res.response.token,
          user_key: res.response.userKey
        });

        // window.location.href = '/#/home';
        window.location.reload();
      }
    } else {
      toast(res.error || 'an error occurred', 'error');
      if (res.error === 'Invalid Credentials') {
        setInvalidPassword(true);
      }
    }
  };
  return (
    <div className="d-flex flex-column">
      <div>
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <div className="mb-3 ">
              <b>Login as customer: </b>
            </div>
            <div className="mb-4">
              <Input
                readOnly={loadingVerification}
                value={email}
                id="email"
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    verifyAccount();
                  }
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="text-center "
                placeholder="Use your (flower company) email address"
                type="text"
              />
            </div>

            <div className="">
              <InputGroup className="mb-4">
                <Input
                  value={password}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      verifyAccount();
                    }
                  }}
                  invalid={invalidPassword}
                  placeholder="Password"
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                    if (invalidPassword) {
                      setInvalidPassword(false);
                    }
                  }}
                  type={passwordVisibility ? 'text' : 'password'}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={() => setPasswordVisibility(!passwordVisibility)}>
                    {passwordVisibility ? 'Hide' : 'Show'}
                  </Button>
                </InputGroupAddon>
              </InputGroup>

              <div className="mb-3">
                <span>Site: </span>
                <select
                  name="beta"
                  id="beta"
                  onChange={(event) => {
                    setSite(event.target.value);
                  }}
                  defaultValue="beta">
                  <option value="">App.ibuyflowers.com</option>

                  <option value="beta">Beta.ibuyflowers.com</option>
                </select>
              </div>

              <Button disabled={loadingVerification} onClick={verifyAccount}>
                {loadingVerification && (
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Start shopping &#187;
              </Button>
              {invalidPassword && <MessageForgotPassword />}
            </div>
            <div className="">
              <img src={footerImage} alt="" />
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};

export default LoginAsCustomer;
