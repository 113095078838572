import React from 'react';

// type Color = 'brown' | 'blue';

interface Props {
  color: string;
  title: string;
  short: string;
  style?: object;
}

export const InputColorLabelComponentD = ({
  color,
  title,
  short,
  style = {}
}: Props) => {
  return (
    <li style={style}>
      <input type="checkbox" id={short} />
      <div className="labelD">
        <div>
          <div style={{ backgroundColor: color }}></div>
        </div>
        <label htmlFor={short}>{title}</label>
      </div>
    </li>
  );
};
