import React from 'react';
import OccasionCardComponent from './OccasionCardComponent/OccasionCardComponent';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { dashboardReducer, loadingReducer } from '../../../Store/Selectors';
import styled from 'styled-components';
import { Occasion } from '../../../Models/Config/Occasion';

const Container = styled.div<{ columns: number; rows: number }>`
  display: grid;
  gap: 15px;
  overflow-y: scroll;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  grid-template-rows: ${(props) => `repeat(${props.rows}, 1fr)`};

  @media (max-width: 1140px) {
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
`;

const OccasionComponent = () => {
  const { authLoading } = useSelector(loadingReducer);
  const { trends } = useSelector(dashboardReducer);

  return (
    <div
      className="d-flex flex-column mx-auto px-3 px-lg-0"
      style={{ maxWidth: '1500px' }}>
      <div className="h5">
        {authLoading ? <Skeleton /> : <b>Trending now</b>}
      </div>
      {!authLoading && trends.items.length > 0 ? (
        <Container columns={trends.columns} rows={trends.rows}>
          {trends.items.length > 0
            ? [...trends.items]
                .sort(
                  (a: Occasion, b: Occasion) =>
                    (a.sort_index ? a.sort_index : 1) -
                    (b.sort_index ? b.sort_index : 1)
                )
                .map((occasion: Occasion, index: number) => (
                  <OccasionCardComponent
                    occasion={occasion}
                    id={index}
                    key={'occasion_' + index}
                  />
                ))
            : null}
        </Container>
      ) : (
        <Skeleton />
      )}
    </div>
  );
};

export default OccasionComponent;
