import React, { useState } from 'react';
import { Modal, ModalBody, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { actionsModal } from '../../../../../Store/Modal/Slice';
import { modalReducer, authReducer } from '../../../../../Store/Selectors';
import { GUEST_USER_NAME_CHANGE } from '../../../../../Store/Modal/Types';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  updateUserInfoService,
  updateCustomerInfoService
} from '../../../../../Services/UserService';
import { toast } from '../../../../../Utils/Utils';
import { State } from '../../../../../Models/State';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

type GuestUser = {
  first_name: string;
  last_name: string;
  company_name: string;
};
const validationInputMessage = (
  <span className="ibf-font-12 color-red">Please fill in this info.</span>
);

export default function UserMainDataModall() {
  const dispatch = useDispatch();
  const {
    GUEST_USER_NAME_CHANGE: { open }
  } = useSelector(modalReducer);
  const {
    user: {
      key,

      metadata: { customer, from_manager, main_user_key }
    }
  } = useSelector(authReducer);
  const { register, formState, handleSubmit } = useForm<GuestUser>();
  const [loading, setLoading] = useState<State>(State.IDLE);
  const toggle = () => {
    dispatch(actionsModal.setCloseModal({ modal: GUEST_USER_NAME_CHANGE }));
  };

  const onSubmit: SubmitHandler<GuestUser> = async (data) => {
    setLoading(State.PENDING);

    const user_customer = {
      _KEY: from_manager ? main_user_key : key,
      first_name: data.first_name,
      last_name: data.last_name
    };
    const companyInformation = {
      _KEY: customer,
      company_name: data.company_name
    };
    const res = await updateUserInfoService(user_customer);
    const resCustomer = await updateCustomerInfoService(
      companyInformation,
      true
    );

    if (res && res.success && resCustomer && resCustomer.success) {
      toast('user successfully updated', 'success');
      setLoading(State.RESOLVED);
      toggle();
      window?.location?.reload();
    } else {
      toast("user can't be updated", 'error');
      setLoading(State.REJECTED);
    }
  };

  return (
    <Modal size="md" isOpen={open}>
      <ModalBody>
        <h4 className="font-weight-bold mb-3">
          Register your first and last name
        </h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-column">
            <div className="form-group input-register flex-grow-1 mt-1">
              <Input
                id={'first_name'}
                defaultValue={''}
                type="text"
                placeholder=" "
                {...register('first_name', { required: true })}
              />
              <label htmlFor="first_name" className="register-label">
                Your first name <span className="text-danger"> * </span>
              </label>
            </div>
            {formState.errors.first_name && validationInputMessage}
            <div className="form-group input-register flex-grow-1 mt-1">
              <Input
                type="text"
                id={'last_name'}
                defaultValue={''}
                placeholder=" "
                {...register('last_name', { required: true })}
              />
              <label htmlFor="last_name" className="register-label">
                Your last name <span className="text-danger"> * </span>
              </label>
            </div>
            {formState.errors.last_name && validationInputMessage}
            <div className="form-group input-register flex-grow-1 mt-1">
              <Input
                type="text"
                id={'company_name'}
                defaultValue={''}
                placeholder=" "
                {...register('company_name', { required: true })}
              />
              <label htmlFor="company_name" className="register-label">
                Company name <span className="text-danger"> * </span>
              </label>
            </div>
            {formState.errors.company_name && validationInputMessage}
          </div>

          <div className="d-flex mt-2 text-center justify-content-center">
            <button
              className="btn btn-primary w-lg-50"
              disabled={loading === State.PENDING}>
              Save and close
              {loading === State.PENDING && (
                <FontAwesomeIcon icon={faSpinner} pulse className="ml-2" />
              )}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}
