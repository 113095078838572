import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  modalReducer,
  standingOrderReducer,
  authReducer
} from '../../../../../Store/Selectors';
import { actionsModal } from '../../../../../Store/Modal/Slice';
import { STANDING_ORDER_DONE } from '../../../../../Store/Modal/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import ContactComponent from '../../../ContactComponent/ContactComponent';
import { actionsStandingOrder } from '../../../../../Store/StandingOrder/Slice';
import { State } from '../../../../../Models/State';
import { calculateParamsStandingOrderEmail } from '../../../../../Utils/Utils';

export default function StandingOrderDoneModal() {
  const dispatch = useDispatch();
  const { state } = useSelector(standingOrderReducer);
  const {
    user: { email, first_name, last_name }
  } = useSelector(authReducer);
  const {
    STANDING_ORDER_DONE: { open, data }
  } = useSelector(modalReducer);
  const toggle = () => {
    dispatch(actionsModal.setCloseModal({ modal: STANDING_ORDER_DONE }));
  };

  const onSubmit = () => {
    const params = calculateParamsStandingOrderEmail(
      data,
      email,
      first_name,
      last_name
    );
    dispatch(actionsStandingOrder.sendEmailStandingOrder(params));
    if (state === State.REJECTED) {
      return;
    }

    toggle();
  };

  return (
    <div>
      <Modal isOpen={open} toggle={toggle} size="xl" className="px-4">
        <span
          onClick={toggle}
          className="position-absolute close--button pointer">
          X
        </span>
        <ModalBody>
          <div className="d-flex mt-1">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="ibf-color--dark-green"
              size="2x"
            />
            <h3 className="ml-2 ibf-color--dark-green">
              Done! We are working on your Standing Order request to save{' '}
              <b>{`$${data.totalPrices}`} a year</b>
            </h3>
            <ContactComponent className="ml-auto" />
          </div>
          <div className="mt-3 px-4 ml-3">
            <b>What's the next step?</b>
            <ul className="px-3">
              <li>
                We'll confirm your Standing order within the next 3-7 work days
                through email.
              </li>
              <li>
                After our confirmation, you still have 7 days review period to
                cancel.
              </li>
              <li>
                After this review period, you are committed to the Standing
                Order agreement until the end date.
              </li>
            </ul>
            <span className="mt-2 d-block">
              You should have received a copy of your Standing Order request in
              your email.
            </span>
          </div>

          <div className="d-flex justify-content-center my-3">
            <Button
              color={'primary'}
              className="px-5"
              onClick={onSubmit}
              disabled={state === State.PENDING}>
              {state === State.PENDING && (
                <FontAwesomeIcon icon={faCircleNotch} pulse className="mr-2" />
              )}
              Ok, close this screen
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
