import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addObjectParamsToPath, hrefToObject } from '../../../Utils/Utils';
import { useHistory } from 'react-router-dom';
import { actionsLoading } from '../../../Store/Loading/Slice';
import { RootState } from '../../../Store/Reducers';
import MixpanelService from '../../../Services/MixpanelService';

const SortOptionComponent = () => {
  const history = useHistory();
  const { date } = useSelector((state: RootState) => state.ProductReducer);
  const { search, filters_conditions } = useSelector(
    (state: RootState) => state.FilterReducer
  );

  const filters: { [key: string]: any } = hrefToObject();

  const [sortSelected, setSortSelected] = useState(
    filters.sortOrder === 'desc'
      ? 'Variety Z-A'
      : filters.orderBy === 'variety'
      ? 'Variety A-Z'
      : 'Recommended'
  );

  const onChangeSort = async (value: any) => {
    const newFilters = { date, search_val: search, ...filters_conditions };
    switch (value.label) {
      case 'Variety A-Z':
        MixpanelService.track('sort_a_z');
        delete newFilters.orderBy;
        setSortSelected('Variety A-Z');
        newFilters.sortOrder = 'asc';
        newFilters.orderBy = 'variety';
        break;
      case 'Variety Z-A':
        MixpanelService.track('sort_z_a');
        delete newFilters.orderBy;
        setSortSelected('Variety Z-A');
        newFilters.sortOrder = 'desc';
        newFilters.orderBy = 'variety';
        break;
      default:
        setSortSelected('Recommended');
        delete newFilters.sortOrder;
        newFilters.orderBy = 'recommended';
        break;
    }
    history.push(addObjectParamsToPath('/search', newFilters));
  };

  const sortOnList = [
    { label: 'Variety A-Z' },
    { label: 'Variety Z-A' },
    { label: 'Recommended' }
  ];

  const defaultGrower = [
    { label: 'Cheapest', checked: true }
    // {label:"Preferred grower"}
  ];

  const { totalItems } = useSelector(
    (state: RootState) => state.ProductReducer
  );
  const { sortMenuOptions } = useSelector(
    (state: RootState) => state.LoadingReducer
  );
  const dispatch = useDispatch();

  return (
    <div
      className={
        sortMenuOptions ? 'ibf-sort-show-window' : 'ibf-sort-hide-window'
      }>
      <div className="p-4">
        <div className="d-flex justify-content-between pb-5">
          <div className="ibf-sort-polygon pointer">
            <span
              onClick={() => dispatch(actionsLoading.openSortOptions())}
              className="ibf-sort-polygon-text">
              Show {' ' + totalItems + ' results'}
            </span>
          </div>
          <div>
            <span
              onClick={() => dispatch(actionsLoading.openSortOptions())}
              className="ibf-sort-close-icon">
              X
            </span>
          </div>
        </div>

        <div>
          <div className="mb-4">
            <span className="mb-2">Sort On:</span>
            {sortOnList.map((value, index) => {
              return (
                <div key={'input2-' + index} className="form-check">
                  <input
                    className="form-check-input mr-2"
                    type="radio"
                    name="sort-name"
                    id={'sort-' + index}
                    onChange={() => onChangeSort(value)}
                    defaultChecked={value.label === sortSelected}
                  />
                  <label
                    className="normal-register-label"
                    htmlFor={'sort-' + index}>
                    {value.label}
                  </label>
                </div>
              );
            })}
          </div>

          <div>
            <span className="mb-2">Default grower:</span>
            {defaultGrower.map((value, index) => {
              return (
                <div key={'input-' + index} className="form-check">
                  <input
                    className="form-check-input mr-2"
                    type="radio"
                    name="sort-grower"
                    id={'grower' + index}
                    defaultChecked={value.label === 'Cheapest'}
                  />
                  <label
                    className="normal-register-label"
                    htmlFor={'grower' + index}>
                    {value.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortOptionComponent;
