import EnvironmentModel from './Models/Environment';

const DEV: EnvironmentModel = {
  appKey: '598c9de4-6ef5-11e6-8b77-86f30ca893d3',
  baseUrl: 'https://dev.ibuyflowers.com/api',
  versionApp: '3.9.0-190620',
  version1Url: 'https://dev.ibuyflowers.com/v1/#/validate',
  searchProductUrl:
    'https://ibf-svc-product-search-node-qa.nubesocobox.com/api/v1',
  eventBaseApi: 'https://sbx-svc-event.nubesocobox.com',
  tagManagerTrackingId: 'UA-86479259-2',
  gtmId: 'GTM-NQMV2W6',
  cloudscripts: {
    guestSignIn: 'd288e46c-b170-4194-a3d4-e0111fdf28b7',
    login: 'EA0800A6-7E18-4545-8F12-97264EF81103',
    validate: 'AEDA2445-1C08-4B47-9D55-3BCE1373DB74',
    addProduct: 'd6824aa5-fc37-405e-91bb-cfdb4b623fca',
    listSLProduct: '44c22ae1-5596-466b-86b4-166ea4fe2a1b',
    removeItemFromSL: 'b831011f-5126-44d8-9c02-155e541f8a9e',
    getUsersList: 'e60ecd8b-63a1-4e14-afd3-90ba8c940a48',
    updateUser: '3e516d31-1930-428c-b08d-0873d50b0ede',
    deliverySchedule: '056B3F69-1B3F-4448-B751-698B8F375F70',
    updateQuantity: '4796a734-b823-4ee2-a554-311ca00e5f64',
    loadAddress: 'e4bb2d77-6815-4879-8882-07bdb4b94751',
    getProductDetails: '5099183c-994d-49b1-9654-972217f578d4',
    customerAddress: '997a252a-d7d7-49b2-ad14-c4b50401c725',
    newCustomerUser: '',
    deletePay: '5463621B-775A-48A8-95E7-129DED69D125',
    addCardToCustomer: '584aab10-300c-4625-843f-f8222c10f650',
    changeCustomerGrowerRelationship: 'a313fd71-d474-414c-97d4-12310c8fffe3',
    customerGrowerRelation: '165A5C6C-5056-46D8-A3A8-6A5B9BFC2F04',
    getManagerInfo: '45c9b5b7-5153-4c23-b2eb-28db7d783029',
    arrivalPerGrower: '462286F4-AC75-4CB7-81CB-773F3C2CB9A1',
    loadProductGroupsByGrower: 'f3778376-a3e3-44e6-9625-1f9fc1666c82',
    listCategories: '85ef3828-3d1a-4914-9e28-6dade353a474',
    salesforceSync: 'a88e3ff9-3607-464b-88f3-7cff56ec1872',
    sendReferral: '860bc67d-3d0b-4279-a5a2-c64b0aae5b5d',
    setShoppingList: '419abbf9-ddae-408b-92fe-06a012d4848a',
    getTimeEST: '36e2467e-e1b5-47ae-8fcb-1c57fc164ce0',
    remFromBoxV2: '2a7eb254-7d6b-426a-9be8-8274f53f30f8',
    cancelBox: 'FBA0D256-062C-4B07-8885-C90FA3AC6CD2',
    homeLoadingV2: '446e58a2-0abb-4298-9a96-ca74145fdfd9',
    userGuestUpgrade: '74c11c8f-e07d-4774-9815-eac7bd2efd96',
    getOrderRequestByCustomer: '3a856483-6939-47de-8a66-d625e9f40d4c',
    addToBox: '98DB1341-5D70-4A3D-B461-C5101195B393',
    closeBox: '7F82EB7E-CF82-4F72-BAEA-38991690387C',
    openBox: '8BF09D98-3F97-42A2-B3B6-84CAAEC457F4',
    listCart: '643107CE-ADC7-448D-A3FE-1C31950EB0A9',
    addToCart: 'EBC89B60-71DB-463E-B04E-E1E8FEB77F08',
    favoriteVarietyHandler: '13f972ca-690d-43aa-ae43-58851ba64f54',
    loginAsCustomer: '6014df37-1f67-4ea3-b937-16e055eeb502',
    createVerificationCode: '46da6efa-4839-485a-a041-cd1e4fe89b38',
    cancelBOrder: '366D3A2F-0D06-4E31-A75B-6B55B42C829D',
    fedexTracking: '4014CE19-98DF-4FB9-8812-9B94413E7875',
    ibf_po_listOrders: '1d4b42e6-816e-4146-9a74-a0820a8d4fa9',
    ibf_po_listOrdersByPurchaseDate: '8e6fee48-a37e-42f8-8596-3df0937cbc8d',
    ibf_po_orderDetail: '7c059058-fd26-41ab-8587-f2319ae39440',
    changeEmailRequest: '5864b286-11d6-47ba-b146-0b4bd3c09aa2',
    ibf_statements: '5f74ffd3-5659-4528-a680-3e8f310ba40e',
    ibf_verify_email_account: '29093283-d3bd-4522-a96d-a88ff09b7321',
    ibf_verify_email_guest: 'fa9ee015-bdeb-4657-9391-6c8baa3481e3',
    ibf_email_guest: 'df93e88f-b360-42ef-8854-d9babcc7779f',
    cancelVoucher: 'DCABEE8E-DC00-4C17-B03D-E504FD6788E0',
    ibf_deleteCustomerAddress: '2e9409ce-0e20-429b-9969-2f0147d4c0b7',
    ibf_getTotalProductByDate: '7c6d9f78-d85c-4455-9711-b1d5d7203fe0',
    ibf_listCustomerDeleteProducts: '3941dbd8-461c-4fae-8434-e761f99d432c',
    ibf_config: '8b26fc0a-efeb-4880-844f-cbf7f5ea07e9',
    ibf_updateUserAndCustomerEmail: '',
    ibf_sendNewManagerEmail: 'fc227c4e-4cf4-48a3-922d-e64ed9002813',
    kometService: '64e003ba-181a-46f5-aec4-b4cbf9ad4584',
    ibf_loadDependencies: 'cf1e4c00-3f06-48d1-9720-256f3eb5d68a',
    ibf_listStandingOrder: 'e8408c69-8bad-41d2-b922-5bea71edb68b',
    ibf_new_standing_order_from_box: 'acc4abd0-953f-4245-9791-a5dab703fce5',
    ibf_sendSORequestEmail: '71793805-52d9-445c-a7a3-2aabd444db98'
  },
  domain: 96,
  publicPath: 'https://sbxcloud.com/www/ibuyflowersdirect/',
  urlRequestClaims: 'https://dev.ibuyflowers.com/claims/#/request',
  urlPendingClaims: 'https://dev.ibuyflowers.com/claims/#/pending',
  urlAcceptedClaims: 'https://dev.ibuyflowers.com/claims/#/accepted',
  urlHelpCenter: 'https://help.ibuyflowers.com/knowledge',
  urlRejectedClaims: 'https://dev.ibuyflowers.com/claims/#/rejected',
  urlFeedback: 'https://www.ibuyflowers.com/updates-in-our-online-marketplace/',
  urlTermsReferral:
    'https://sbxcloud.com/www/ibuyflowersdirect/dev3.5/Assets/Images/Terms%20and%20Conditions%20Referral%20Program.pdf',
  stripe_key: 'pk_test_XyeFF88eX2VRUi9C3HUbn4JI',
  country_flag_map: {
    '141169ab-e293-407a-bfaf-575ecc4df476': 'CO',
    '67f917ac-e4d3-427b-ba4f-379f8097938e': 'US',
    'c5e74fe4-54de-460f-8365-d334d6b04f57': 'NL',
    'f0f9c1fa-4f80-497a-99f4-a217c3e8cd3e': 'TH',
    '7b55a566-62bd-4bf1-85b8-db4b0084f191': 'EC',
    '3d8014ad-5037-47f8-b5ca-6a151135a756': 'CR'
  },
  customBoxImage:
    'https://sbxcloud.com/www/ibuyflowersdirect/dev/assets/images/custom-box.png',
  urlCheckout: 'https://dev.ibuyflowers.com/checkout/#/',
  testMode: false,
  mixpanel: '73643dc14ca528c98a30a2b630842191',
  ibfPage: {
    aboutUs: 'https://www.ibuyflowers.com/about-ibf/',
    growers: 'https://www.ibuyflowers.com/40-premium-growers/',
    blog: 'https://www.ibuyflowers.com/blog/',
    reviews: 'https://www.ibuyflowers.com/ibuyflowers-reviews/',
    privacyPolicy: 'https://www.ibuyflowers.com/privacy-policy/',
    termsAndConditions: 'https://www.ibuyflowers.com/terms-and-conditions/'
  },
  categoriesKey: {
    bouquets: '526826f8-fa6a-46ff-9bed-732bbf69434a',
    comboBox: '75c7708a-e18d-4ce8-8efd-1a1cf8d37485',
    driedProducts: '9dad6b7a-be7f-4e27-ad2c-abfde6041506',
    supplies: '01d33fb3-df4f-4e83-8d17-611de82e736d',
    consumerBunch: ''
  },
  youtube: {
    home: 'https://www.youtube.com/embed/bD5LysBWHcc'
  },
  templates: {
    forgotPassword: '282248a5-b299-4a3f-b20f-0de60aba1b5a'
  },
  captchaKey: '6LfWi-UUAAAAAPUIPPv-ytqqh5dVv1kaM2Whp8LN',
  colorList: {
    'All whites': [
      '0e0fe421-a994-46b3-874a-226767d394d9',
      '287c98b5-b487-4d06-838b-6268bb0abe32',
      '35f597ba-31b2-46e8-9184-2e4e0eee5709'
    ],
    'All pinks': [
      '07fa0015-8827-4a9e-87b9-6c3a1581458b',
      '11f1d3be-f5e6-439d-9563-6ba5a64ee26a',
      '1ad908a0-922f-4fa9-aeef-e99338dd53dd',
      '1d1faf92-6707-4c60-aadc-6d1577889689',
      '38c06ef9-83ef-4da6-8463-5788aaf2db57',
      '47eaacf2-5b1a-4083-b706-f3480bf5d720',
      '576ae393-9efb-400a-b198-9f45ce1c36d0',
      '6e87132e-f99e-4c7a-b69b-e6e6ab9727eb',
      '73749519-e78f-453f-b19e-f86437c8e22d',
      '912c375f-69e1-4a6f-9698-e05b0f9be48d',
      '951bf966-bf02-44e3-9670-2031dd8440e3',
      '9c9ff42b-a2fb-41e5-a1ae-c5e9216c7a55',
      'b7b7cf5d-090e-4836-8b7f-02ec8b766f60',
      'cdd17774-77bf-43d1-b1f0-c1c44047689e',
      'f22a2abe-4e22-40f9-b4b6-2e2ac1aacc60'
    ],
    'All purples': [
      '04268087-0655-4283-bc9c-45098701664c',
      '30ab2dc9-0f61-4dcb-9a67-3ade6774263d'
    ],
    'All orange & peach': [
      '1ddc0ef9-1e97-446f-8b68-1b1bb20f4110',
      '25708513-fbe1-484f-b950-4d4ba41afc2b',
      '25c7804d-4396-4355-937e-8d4518e9a5c0',
      '34320245-b4d7-4ec7-8e5b-24682a2c1e41',
      '3567e0c1-99c2-4739-b5a5-1e5c0da60ed1',
      '561d16c6-af6b-45fa-9806-003fbcf5db70',
      '58c605e7-b199-493e-9df3-bec3289b8fe0',
      '654466b3-ebc5-4003-b207-595575ac44ce',
      '7dd7cdde-3c98-4fb5-af27-02de8cb050e7',
      '8f63485a-62c8-4e07-91d9-f36d8fe8b0c1',
      'b52b2a38-f34a-4ab6-b4f3-9721437a33a6'
    ],
    'All reds & cerise': [
      '053f3581-af43-4568-a70e-82aee4a70f2b',
      '19e7f8e5-005f-4e51-ad87-649fc1038516',
      '25c7804d-4396-4355-937e-8d4518e9a5c0',
      '4e0b09cb-bfb8-40d2-8ca1-8f6585ddb45f',
      '7a6d29f6-9475-4824-a95f-68919e859c82',
      'a6dba644-ad1c-4bde-a0fb-201365091c20',
      'a92ff52a-3ad1-4652-818d-913b68ea0873',
      'b8ece661-1181-45b9-a8d7-b269a6520c4c',
      'd3b3c143-7906-4cf3-8d1b-e8ef17b540b9',
      'f84aa15e-4f5a-4371-82b7-14be4d9f96f3'
    ],
    'All browns & bronz': [
      '07ae01fb-89e5-4f5e-8c55-7b17079caa9d',
      '3a83d6e7-9768-48f8-a1fb-e3b42222118b',
      '3f94d6c1-e09a-4f3c-8e43-4f6e90b321a5',
      '55bcab3b-d722-4809-8316-7f8981f6f6e8'
    ],
    'All yellows': [
      '053f3581-af43-4568-a70e-82aee4a70f2b',
      '0bb95b1a-c187-4456-9767-222b0da56c23',
      '5903f393-5e6e-433d-90de-b424950da9f7',
      'a408eea2-710c-47c3-a45f-60151bd6554c',
      'da36479b-6e70-4754-90a7-f8357994a49f'
    ],
    'All greens': [
      '5d9b7d0e-c5c0-489d-b2a9-55b1c9194f20',
      '5fedc549-2bd1-4cab-8a7e-1a478cfaae6a',
      '6bbcd9cd-20cf-4e4e-bcdc-6570b23bd5f0',
      '890f2001-cb7f-48a2-b12d-be1f70a3b36d',
      '91183292-9eb0-41fe-8625-1566fc28401a',
      'b03964ff-ec59-4a57-8778-6cea0ff4f786',
      'f68ff526-c372-4098-a8e3-7c3f08557d51'
    ],
    'All blues': [
      '07fa0015-8827-4a9e-87b9-6c3a1581458b',
      '0ea4c1f5-a8f4-41db-b2be-e625f40e33e0',
      '52457edf-d62f-40eb-b8d9-f10e844fad66',
      '5d2da8fa-3d2d-4a25-a8e8-5f9c42610eda',
      '7c619b14-5263-49e6-ad17-44aea94088cf',
      'b90682f7-e8fb-49c1-bb77-d970f8710d8c',
      'fe0b9cd8-a479-438a-a302-d57d2f726c6c'
    ],
    'All blacks': [
      '065ebde0-e21c-48e6-bab2-a4f65fb17a79',
      '35f597ba-31b2-46e8-9184-2e4e0eee5709',
      'cda328a1-8253-4ab5-b8b7-b89af6008ccd'
    ]
  }
};

const QA: EnvironmentModel = {
  appKey: '162290b2-6ef6-11e6-8b77-86f30ca893d3',
  baseUrl: 'https://app.ibuyflowers.com/api',
  versionApp: '3.10.0-190916',
  version1Url: 'https://app.ibuyflowers.com/v1/#/validate',
  searchProductUrl:
    'https://ibf-svc-product-search-node.nubesocobox.com/api/v1',
  eventBaseApi: 'https://sbx-svc-event.nubesocobox.com',
  tagManagerTrackingId: 'UA-86479259-1',
  gtmId: 'GTM-WV7H9FV',
  cloudscripts: {
    guestSignIn: '99645e5f-9cb0-4855-9b89-c0f9e1504d7c',
    login: '178CEEFA-32AB-4D01-B651-B3FA0DFAD419',
    validate: '5A02D90D-6F47-4E1B-9374-73C35E3EB042',
    addProduct: '8c8391a8-667d-4e51-8607-faf581512f46',
    listSLProduct: '325dd880-1885-4538-aebb-30db222312a3',
    removeItemFromSL: '427354bf-2a02-4ef8-aa2a-6cc17aec3b7a',
    getUsersList: '4105d6fd-b42d-4b5c-982a-b5f2e7bb2d6d',
    updateUser: '86ca55bb-7c17-492c-acae-ed41461031bf',
    deliverySchedule: 'F2B6DE9D-6BEB-487F-B421-0D349BA3A711',
    updateQuantity: '61320c16-f384-443d-8a7d-22793418db62',
    loadAddress: 'ff22617e-cf60-4cb9-91cf-69e936fda549',
    getProductDetails: '12dacc9e-7a2f-4f00-b11b-23497376004b',
    customerAddress: '3719cc9d-92f5-4b73-b19f-9b0c93ed9db8',
    newCustomerUser: 'f819780e-bc28-42a0-895e-bcaa4f54cd83',
    deletePay: 'BDC2EC25-CC0A-4BD0-A536-B05DC015F83A',
    addCardToCustomer: '33ffe1b4-0a7f-46bb-b70d-d21b3b0ecced',
    changeCustomerGrowerRelationship: '83b945ef-d7d3-46be-8992-e4453a7a3059',
    customerGrowerRelation: '97FC1B89-4E64-43DF-9AB0-37153DEC5AA1',
    getManagerInfo: '9e6c8680-54f5-45d8-92a1-724f8b641df8',
    arrivalPerGrower: '98EDF184-38AE-4B15-8501-75A0DD891AEC',
    loadProductGroupsByGrower: 'ac60e7bb-63e5-422e-8bf1-86a4487fbaee',
    listCategories: '1972ceee-8df3-482f-aa25-9e88d6f79a2e',
    salesforceSync: 'b346227f-6415-456f-a45f-0b52dbf23115',
    sendReferral: '3DEC6183-873D-43F0-8CB6-10AE116BEA9F',
    setShoppingList: '7ADBA589-C2C1-4DD3-9B61-D7A30733B6C4',
    getTimeEST: 'CAABAE3D-282D-48F6-9F8C-3D1B3DE9761B',
    remFromBoxV2: '5AC336F1-EFBA-422A-9B64-F944BAA551B4',
    cancelBox: 'DA6D03CC-99F0-4654-B659-9A18D252B427',
    homeLoadingV2: '60F5699F-9C20-4A0E-9A9A-7B12A5E86C8A',
    userGuestUpgrade: 'b9563272-af5b-44d4-a9e0-97f30203ddfb',
    getOrderRequestByCustomer: '85d114ba-947e-4927-baf2-299eb73ef841',
    addToBox: 'D315F847-AD76-41AC-8B92-AE3DD3CFDED9',
    closeBox: '985A9956-5C2F-44AA-B6B0-E27AD48B3DEE',
    openBox: '8D2CB96A-89B4-4D16-A55C-0597FBB13AFE',
    listCart: '132DBACE-3265-49B5-B56B-8893377B1E1B',
    addToCart: '2EDCCABC-74C8-459C-98CC-5889C63DE6FB',
    favoriteVarietyHandler: '620182e4-5107-43d3-8a8a-0ec801238b75',
    loginAsCustomer: '5608e396-d759-471f-bddc-d1fca96fb127',
    cancelBOrder: '5F3A9125-B7D4-451B-8574-61A7F2E6C3FD',
    fedexTracking: '9C232EAD-7C8E-4CCE-84DB-0D5DB3B6C13C',
    ibf_po_listOrders: '11b25e85-dcbf-4326-99de-0b7d4d3db6a1',
    ibf_po_listOrdersByPurchaseDate: '0ee2290c-9ce1-4a0f-b9c6-a888994c266e',
    ibf_po_orderDetail: '515eae3b-2524-4161-9f28-407fc56d21c3',
    ibf_verify_email_account: '5f744305-c392-4d7f-afa9-5eb622360031',
    ibf_verify_email_guest: 'dcea1446-a4c3-45fb-952b-61e52f8c7ca0',
    ibf_email_guest: '9a79ff65-4bc2-4349-9a6d-e5b11437bcde',
    ibf_statements: 'd680d3d3-0501-45fe-b89a-879e7ae50b82',
    cancelVoucher: '875C4D36-C654-4588-AAA8-01C46B81BF65',
    changeEmailRequest: '182daa7a-c1bd-4b05-9c17-4256c3899bf3',
    ibf_getTotalProductByDate: 'ec03a890-a8ea-4e5c-a811-47db2df948ed',
    ibf_deleteCustomerAddress: '2e780f44-e2c3-4a7a-8d2d-c11f605b1f38',
    ibf_listCustomerDeleteProducts: '8007d165-0cfe-46a6-813a-30b096c66262',
    kometService: '3b1d9cf8-64a4-47d5-bda2-3b61a284a918',
    ibf_loadDependencies: 'eebdbd5d-2a97-4139-8fc3-9c28fb9198f2',
    ibf_config: 'b50d2507-d966-4595-bcdf-527d0e06e5f4',
    ibf_updateUserAndCustomerEmail: '11f0b961-1f23-4501-a486-2a4d239f85bc',
    ibf_sendNewManagerEmail: '6250fd6f-dfd3-4e09-94e7-c7652e06c340',
    delete_komet_orders: 'b741ce65-5631-4494-8104-ae570b29c53a'
  },
  country_flag_map: {
    '20b3a7e1-b20f-491e-8240-1647c72a4f5b': 'CO',
    '23f73d62-3370-42e3-a9d2-2e453cb5cc44': 'US',
    '3d0b5c85-4e1f-43d9-9b02-808121858b51': 'NL',
    '10f64db7-5cd6-42cd-8949-596c45a2e916': 'TH',
    '2e9bd31b-a45b-41e9-a346-808a2e586a77': 'EC',
    '0bc5898c-a858-4890-8982-934e024dc69d': 'CR'
  },
  domain: 129,
  customBoxImage:
    'https://sbxcloud.com/www/ibuyflowersdirect/dev/assets/images/custom-box.png',
  publicPath: 'https://sbxcloud.com/www/ibuyflowers/',
  testMode: true,
  urlCheckout: 'https://test.ibuyflowers.com/checkout/#/',
  urlRequestClaims: 'https://test.ibuyflowers.com/claims/#/request',
  urlPendingClaims: 'https://test.ibuyflowers.com/claims/#/pending',
  urlAcceptedClaims: 'https://test.ibuyflowers.com/claims/#/accepted',
  urlRejectedClaims: 'https://test.ibuyflowers.com/claims/#/rejected',
  urlFeedback: 'https://www.ibuyflowers.com/updates-in-our-online-marketplace/',
  urlHelpCenter: 'https://help.ibuyflowers.com/knowledge',
  urlTermsReferral:
    'https://sbxcloud.com/www/ibuyflowersdirect/dev3.5/Assets/Images/Terms%20and%20Conditions%20Referral%20Program.pdf',
  stripe_key: 'pk_live_r0TsAGPh3HvaMdrxKOHfYDTR',
  mixpanel: '73643dc14ca528c98a30a2b630842191',
  ibfPage: {
    aboutUs: 'https://www.ibuyflowers.com/about-ibf/',
    growers: 'https://www.ibuyflowers.com/40-premium-growers/',
    blog: 'https://www.ibuyflowers.com/blog/',
    reviews: 'https://www.ibuyflowers.com/ibuyflowers-reviews/',
    privacyPolicy: 'https://www.ibuyflowers.com/privacy-policy/',
    termsAndConditions: 'https://www.ibuyflowers.com/terms-and-conditions/'
  },
  categoriesKey: {
    bouquets: 'e0a781f8-cae3-4f63-b7f3-462b861e8174',
    comboBox: 'cde874e2-cf22-4ea1-99d7-3975bb329b46',
    driedPreserved: '6a5f7c3f-e99f-4aff-ba61-305cc5a12210',
    supplies: '01d33fb3-df4f-4e83-8d17-611de82e736d',
    consumerBunch: '6278d067-c71d-4311-869d-5ae588723f00'
  },
  youtube: {
    home: 'https://www.youtube.com/embed/bD5LysBWHcc'
  },
  templates: {
    forgotPassword: 'b98a713a-b765-45d2-aef6-8f00707d3e99'
  },
  captchaKey: '6LfWi-UUAAAAAPUIPPv-ytqqh5dVv1kaM2Whp8LN',
  colorList: {
    'All whites': [
      '793029e1-e48e-4884-900c-ab3b2c1f19b0',
      'a9d9a032-b391-4019-a427-11161dfa263d',
      '411ce723-f444-41cf-9b0d-b4ff49dbe187',
      '2471987b-e8e1-46f5-8cc2-d38a2b3cdc22'
    ],
    'All pinks': [
      'fbb32fd0-6bdc-4617-ac44-5f61d47bfe3c',
      'a4548ddb-2770-4747-8455-57f608786e32',
      'a6b78ab8-6b52-48b9-bfba-ddac7a216650',
      '6d98d168-88da-49cf-8694-69fcd9881285',
      '376a68ae-776d-442a-bd0f-55311de4c2c4',
      'c5ae3a3c-fc4d-43bb-811a-80ea73e93e18',
      '1a65de40-7f39-428b-a3f7-8c31d8ebd8f6',
      'c3fd9d25-426a-427e-aed9-3988d64dac54'
    ],
    'All purples': [
      '98c3cbc2-14c2-4dbf-9ef9-615b27a162b1',
      'a4092f3c-bf1e-4a26-aa70-4d07247a94f1',
      'feeb171f-e83d-4b1c-8925-93063f68b109',
      '4dddd4eb-12ce-418b-8849-c82e3295bcb5',
      '62fec750-3574-40e0-8206-afdf03d5fd07'
    ],
    'All orange & peach': [
      'c61f6437-5436-42f8-ab71-950a8d4ffb91',
      'ddd6c642-ac67-43e7-bb01-ec283ddc4e80',
      'd95e4f1f-b258-4ca2-b644-2ba1265d053c',
      'bbea9632-b484-401f-ae29-d5cfbf9767ed',
      '6d7ac049-8282-4b90-9c17-095d106f43cd',
      'f0ffaeab-c93a-476e-8fab-d8347a2336f4'
    ],
    'All reds & cerise': [
      '86d330e3-b5e2-469a-b8d3-a449a7ceda6d',
      '6f60970a-207c-462c-98cc-789cb5346010',
      '9a934582-f645-423c-8a68-b937621c1eeb',
      'b2591fdd-1fef-4f38-9cd0-dc74f2fb980d',
      'c20338f3-697a-4e6f-ad15-867d702bafa9',
      '8d515530-db5f-4736-8030-12d39911c64b',
      'a0795658-8204-4c84-976a-a16b84533c3c',
      'accbafc1-1f66-4007-8372-382964b4d7aa'
    ],
    'All browns & bronz': [
      '5e87ac62-f2f4-4125-a445-97b6cd1eabea',
      '903b5eb2-c189-4922-866d-7e83e674db9d',
      '0e5ef9ea-148d-4ff7-917a-e03c286bbebf',
      '2c2d65f7-cd05-48c6-9be4-c061c2995d6f',
      '2473ddf0-a728-4154-8d62-7372bb08aa93'
    ],
    'All yellows': [
      'f711ceaf-0dcd-4e52-9343-fd1a327ebfe8',
      'fe3d651a-d630-4287-b5f9-19c4091b4b7b',
      '20820efa-2ce2-4161-a12e-aa61d4f47db8',
      '35bf4acb-36f3-47f0-b4cb-666a735df940'
    ],
    'All greens': [
      '53c6fdba-a171-4b2f-85fa-d0e6f328d349',
      '5a625664-7bb9-46d4-8a66-9db01de95846',
      '43a4d36b-2afe-4ea5-8af2-38de991a9fe5',
      'd5c5e876-ef71-4eba-aa67-340a33baad9d',
      'e475f9da-a541-473e-80d8-5462c557e9b6',
      'f1a5156a-afe8-457d-9ad9-b0a852f7a01d',
      '6f865152-75cd-459b-a05c-e1188a258f22'
    ],
    'All blues': [
      '33f3ed42-8d7b-49ad-abad-be642f35c344',
      '8cf7d046-2d52-41f8-9746-7c25d77ecd2c',
      '827e3c9c-3dff-4eb3-9b6c-90f5d30b10e2',
      '8cc64064-8c1c-4a6f-a913-36fe8784c5ba'
    ],
    'All grays & blacks': [
      'fb723c61-557c-4ce0-8ada-6aa80498e10a',
      '39ecd422-2cd4-4fbe-b851-61601f768157',
      '6f7ae408-1cbe-42d4-ad4f-8117bbc37147'
    ],
    'All multiple colors': [
      'edfff37e-6f52-41d7-b25d-2a491b194aad',
      '1a96855b-9900-4744-a5f3-55d3aaf5e3fa',
      'ba33a46c-2eee-462e-9ca4-fac0e8a0fb6c',
      '9ce1b7af-e8bb-4978-be80-4a17a426e218',
      '30d1cc66-939c-4421-a128-4b4c4d3b30a7',
      'ce53a249-d328-477e-8abf-2927ba30bcad',
      '892b706d-02a6-4e92-bffe-775fdb60f25f',
      '3d94d86b-745d-4189-8902-7e4f410ff2a4',
      '7daa6577-03d0-4de8-8e70-0c900743f687',
      '9343d3f2-f800-4ef0-add2-ba72bf2268b7'
    ]
  }
};

const PROD: EnvironmentModel = {
  appKey: '162290b2-6ef6-11e6-8b77-86f30ca893d3',
  baseUrl: 'https://app.ibuyflowers.com/api',
  versionApp: '3.10.0-190916',
  version1Url: 'https://app.ibuyflowers.com/v1/#/validate',
  searchProductUrl:
    'https://ibf-svc-product-search-node.nubesocobox.com/api/v1',
  eventBaseApi: 'https://sbx-svc-event.nubesocobox.com',
  tagManagerTrackingId: 'UA-86479259-1',
  gtmId: 'GTM-WV7H9FV',
  cloudscripts: {
    guestSignIn: '99645e5f-9cb0-4855-9b89-c0f9e1504d7c',
    login: '178CEEFA-32AB-4D01-B651-B3FA0DFAD419',
    validate: '5A02D90D-6F47-4E1B-9374-73C35E3EB042',
    addProduct: '8c8391a8-667d-4e51-8607-faf581512f46',
    listSLProduct: '325dd880-1885-4538-aebb-30db222312a3',
    removeItemFromSL: '427354bf-2a02-4ef8-aa2a-6cc17aec3b7a',
    getUsersList: '4105d6fd-b42d-4b5c-982a-b5f2e7bb2d6d',
    updateUser: '86ca55bb-7c17-492c-acae-ed41461031bf',
    deliverySchedule: 'F2B6DE9D-6BEB-487F-B421-0D349BA3A711',
    updateQuantity: '61320c16-f384-443d-8a7d-22793418db62',
    loadAddress: 'ff22617e-cf60-4cb9-91cf-69e936fda549',
    getProductDetails: '12dacc9e-7a2f-4f00-b11b-23497376004b',
    customerAddress: '3719cc9d-92f5-4b73-b19f-9b0c93ed9db8',
    newCustomerUser: 'f819780e-bc28-42a0-895e-bcaa4f54cd83',
    deletePay: 'BDC2EC25-CC0A-4BD0-A536-B05DC015F83A',
    addCardToCustomer: '33ffe1b4-0a7f-46bb-b70d-d21b3b0ecced',
    changeCustomerGrowerRelationship: '83b945ef-d7d3-46be-8992-e4453a7a3059',
    customerGrowerRelation: '97FC1B89-4E64-43DF-9AB0-37153DEC5AA1',
    getManagerInfo: '9e6c8680-54f5-45d8-92a1-724f8b641df8',
    arrivalPerGrower: '98EDF184-38AE-4B15-8501-75A0DD891AEC',
    loadProductGroupsByGrower: 'ac60e7bb-63e5-422e-8bf1-86a4487fbaee',
    listCategories: '1972ceee-8df3-482f-aa25-9e88d6f79a2e',
    salesforceSync: 'b346227f-6415-456f-a45f-0b52dbf23115',
    sendReferral: '3DEC6183-873D-43F0-8CB6-10AE116BEA9F',
    setShoppingList: '7ADBA589-C2C1-4DD3-9B61-D7A30733B6C4',
    getTimeEST: 'CAABAE3D-282D-48F6-9F8C-3D1B3DE9761B',
    remFromBoxV2: '5AC336F1-EFBA-422A-9B64-F944BAA551B4',
    cancelBox: 'DA6D03CC-99F0-4654-B659-9A18D252B427',
    homeLoadingV2: '60F5699F-9C20-4A0E-9A9A-7B12A5E86C8A',
    userGuestUpgrade: 'b9563272-af5b-44d4-a9e0-97f30203ddfb',
    getOrderRequestByCustomer: '85d114ba-947e-4927-baf2-299eb73ef841',
    addToBox: 'D315F847-AD76-41AC-8B92-AE3DD3CFDED9',
    closeBox: '985A9956-5C2F-44AA-B6B0-E27AD48B3DEE',
    openBox: '8D2CB96A-89B4-4D16-A55C-0597FBB13AFE',
    listCart: '132DBACE-3265-49B5-B56B-8893377B1E1B',
    addToCart: '2EDCCABC-74C8-459C-98CC-5889C63DE6FB',
    favoriteVarietyHandler: '620182e4-5107-43d3-8a8a-0ec801238b75',
    loginAsCustomer: '5608e396-d759-471f-bddc-d1fca96fb127',
    cancelBOrder: '5F3A9125-B7D4-451B-8574-61A7F2E6C3FD',
    fedexTracking: '9C232EAD-7C8E-4CCE-84DB-0D5DB3B6C13C',
    ibf_po_listOrders: '11b25e85-dcbf-4326-99de-0b7d4d3db6a1',
    ibf_po_listOrdersByPurchaseDate: '0ee2290c-9ce1-4a0f-b9c6-a888994c266e',
    ibf_po_orderDetail: '515eae3b-2524-4161-9f28-407fc56d21c3',
    ibf_verify_email_account: '5f744305-c392-4d7f-afa9-5eb622360031',
    ibf_verify_email_guest: 'dcea1446-a4c3-45fb-952b-61e52f8c7ca0',
    ibf_email_guest: '9a79ff65-4bc2-4349-9a6d-e5b11437bcde',
    ibf_statements: 'd680d3d3-0501-45fe-b89a-879e7ae50b82',
    cancelVoucher: '875C4D36-C654-4588-AAA8-01C46B81BF65',
    changeEmailRequest: '182daa7a-c1bd-4b05-9c17-4256c3899bf3',
    ibf_getTotalProductByDate: 'ec03a890-a8ea-4e5c-a811-47db2df948ed',
    ibf_deleteCustomerAddress: '2e780f44-e2c3-4a7a-8d2d-c11f605b1f38',
    ibf_listCustomerDeleteProducts: '8007d165-0cfe-46a6-813a-30b096c66262',
    ibf_config: 'b50d2507-d966-4595-bcdf-527d0e06e5f4',
    ibf_updateUserAndCustomerEmail: '11f0b961-1f23-4501-a486-2a4d239f85bc',
    ibf_sendNewManagerEmail: '6250fd6f-dfd3-4e09-94e7-c7652e06c340',
    kometService: '3b1d9cf8-64a4-47d5-bda2-3b61a284a918',
    ibf_loadDependencies: 'eebdbd5d-2a97-4139-8fc3-9c28fb9198f2',
    delete_komet_orders: 'b741ce65-5631-4494-8104-ae570b29c53a'
  },
  country_flag_map: {
    '20b3a7e1-b20f-491e-8240-1647c72a4f5b': 'CO',
    '23f73d62-3370-42e3-a9d2-2e453cb5cc44': 'US',
    '3d0b5c85-4e1f-43d9-9b02-808121858b51': 'NL',
    '10f64db7-5cd6-42cd-8949-596c45a2e916': 'TH',
    '2e9bd31b-a45b-41e9-a346-808a2e586a77': 'EC',
    '0bc5898c-a858-4890-8982-934e024dc69d': 'CR'
  },
  domain: 129,
  publicPath: 'https://sbxcloud.com/www/ibuyflowers/',
  customBoxImage:
    'https://sbxcloud.com/www/ibuyflowersdirect/dev/assets/images/custom-box.png',
  testMode: false,
  urlCheckout: 'https://app.ibuyflowers.com/checkout/#/',
  urlRequestClaims: 'https://app.ibuyflowers.com/claims/#/request',
  urlPendingClaims: 'https://app.ibuyflowers.com/claims/#/pending',
  urlAcceptedClaims: 'https://app.ibuyflowers.com/claims/#/accepted',
  urlRejectedClaims: 'https://app.ibuyflowers.com/claims/#/rejected',
  urlHelpCenter: 'https://help.ibuyflowers.com/knowledge',
  urlFeedback: 'https://www.ibuyflowers.com/updates-in-our-online-marketplace/',
  urlTermsReferral:
    'https://sbxcloud.com/www/ibuyflowersdirect/dev3.5/Assets/Images/Terms%20and%20Conditions%20Referral%20Program.pdf',
  stripe_key: 'pk_live_r0TsAGPh3HvaMdrxKOHfYDTR',
  mixpanel: '33bef9612fd0a274f78b0e87b5c9674a',
  ibfPage: {
    aboutUs: 'https://www.ibuyflowers.com/about-ibf/',
    growers: 'https://www.ibuyflowers.com/40-premium-growers/',
    blog: 'https://www.ibuyflowers.com/blog/',
    reviews: 'https://www.ibuyflowers.com/ibuyflowers-reviews/',
    privacyPolicy: 'https://www.ibuyflowers.com/privacy-policy/',
    termsAndConditions: 'https://www.ibuyflowers.com/terms-and-conditions/'
  },
  categoriesKey: {
    bouquets: 'e0a781f8-cae3-4f63-b7f3-462b861e8174',
    comboBox: 'cde874e2-cf22-4ea1-99d7-3975bb329b46',
    driedPreserved: '6a5f7c3f-e99f-4aff-ba61-305cc5a12210',
    supplies: '01d33fb3-df4f-4e83-8d17-611de82e736d',
    consumerBunch: '6278d067-c71d-4311-869d-5ae588723f00'
  },
  youtube: {
    home: 'https://www.youtube.com/embed/bD5LysBWHcc'
  },
  templates: {
    forgotPassword: 'b98a713a-b765-45d2-aef6-8f00707d3e99'
  },
  captchaKey: '6LfWi-UUAAAAAPUIPPv-ytqqh5dVv1kaM2Whp8LN',
  colorList: {
    'All whites': [
      '793029e1-e48e-4884-900c-ab3b2c1f19b0',
      'edfff37e-6f52-41d7-b25d-2a491b194aad',
      'a9d9a032-b391-4019-a427-11161dfa263d',
      '411ce723-f444-41cf-9b0d-b4ff49dbe187',
      '2471987b-e8e1-46f5-8cc2-d38a2b3cdc22'
    ],
    'All pinks': [
      'fbb32fd0-6bdc-4617-ac44-5f61d47bfe3c',
      'a4548ddb-2770-4747-8455-57f608786e32',
      'a6b78ab8-6b52-48b9-bfba-ddac7a216650',
      '6d98d168-88da-49cf-8694-69fcd9881285',
      '376a68ae-776d-442a-bd0f-55311de4c2c4',
      'ba33a46c-2eee-462e-9ca4-fac0e8a0fb6c',
      'c5ae3a3c-fc4d-43bb-811a-80ea73e93e18',
      '1a65de40-7f39-428b-a3f7-8c31d8ebd8f6',
      'c3fd9d25-426a-427e-aed9-3988d64dac54'
    ],
    'All purples': [
      '98c3cbc2-14c2-4dbf-9ef9-615b27a162b1',
      '9ce1b7af-e8bb-4978-be80-4a17a426e218',
      'a4092f3c-bf1e-4a26-aa70-4d07247a94f1',
      'feeb171f-e83d-4b1c-8925-93063f68b109',
      '4dddd4eb-12ce-418b-8849-c82e3295bcb5',
      '62fec750-3574-40e0-8206-afdf03d5fd07',
      '30d1cc66-939c-4421-a128-4b4c4d3b30a7'
    ],
    'All orange & peach': [
      'c61f6437-5436-42f8-ab71-950a8d4ffb91',
      'ddd6c642-ac67-43e7-bb01-ec283ddc4e80',
      'd95e4f1f-b258-4ca2-b644-2ba1265d053c',
      'bbea9632-b484-401f-ae29-d5cfbf9767ed',
      '6d7ac049-8282-4b90-9c17-095d106f43cd',
      '9d9dc9e9-1cec-4e07-930e-9e6177c1852e',
      'f0ffaeab-c93a-476e-8fab-d8347a2336f4'
    ],
    'All reds & cerise': [
      '86d330e3-b5e2-469a-b8d3-a449a7ceda6d',
      '1a96855b-9900-4744-a5f3-55d3aaf5e3fa',
      '6f60970a-207c-462c-98cc-789cb5346010',
      '9a934582-f645-423c-8a68-b937621c1eeb',
      'b2591fdd-1fef-4f38-9cd0-dc74f2fb980d',
      'c20338f3-697a-4e6f-ad15-867d702bafa9',
      '8d515530-db5f-4736-8030-12d39911c64b',
      'accbafc1-1f66-4007-8372-382964b4d7aa'
    ],
    'All browns & bronz': [
      'ce53a249-d328-477e-8abf-2927ba30bcad',
      '5e87ac62-f2f4-4125-a445-97b6cd1eabea',
      '903b5eb2-c189-4922-866d-7e83e674db9d',
      '0e5ef9ea-148d-4ff7-917a-e03c286bbebf',
      '2c2d65f7-cd05-48c6-9be4-c061c2995d6f',
      '2473ddf0-a728-4154-8d62-7372bb08aa93'
    ],
    'All yellows': [
      'f711ceaf-0dcd-4e52-9343-fd1a327ebfe8',
      'fe3d651a-d630-4287-b5f9-19c4091b4b7b',
      '20820efa-2ce2-4161-a12e-aa61d4f47db8',
      '35bf4acb-36f3-47f0-b4cb-666a735df940',
      '892b706d-02a6-4e92-bffe-775fdb60f25f'
    ],
    'All greens': [
      '53c6fdba-a171-4b2f-85fa-d0e6f328d349',
      '5a625664-7bb9-46d4-8a66-9db01de95846',
      '43a4d36b-2afe-4ea5-8af2-38de991a9fe5',
      '3d94d86b-745d-4189-8902-7e4f410ff2a4',
      'd5c5e876-ef71-4eba-aa67-340a33baad9d',
      'e475f9da-a541-473e-80d8-5462c557e9b6',
      'f1a5156a-afe8-457d-9ad9-b0a852f7a01d',
      '6f865152-75cd-459b-a05c-e1188a258f22'
    ],
    'All blues': [
      '33f3ed42-8d7b-49ad-abad-be642f35c344',
      '8cf7d046-2d52-41f8-9746-7c25d77ecd2c',
      '7daa6577-03d0-4de8-8e70-0c900743f687',
      '827e3c9c-3dff-4eb3-9b6c-90f5d30b10e2',
      '8cc64064-8c1c-4a6f-a913-36fe8784c5ba'
    ],
    'All grays & blacks': [
      'fb723c61-557c-4ce0-8ada-6aa80498e10a',
      '39ecd422-2cd4-4fbe-b851-61601f768157',
      '9343d3f2-f800-4ef0-add2-ba72bf2268b7',
      '6f7ae408-1cbe-42d4-ad4f-8117bbc37147'
    ],
    'All multiple colors': [
      'edfff37e-6f52-41d7-b25d-2a491b194aad',
      '1a96855b-9900-4744-a5f3-55d3aaf5e3fa',
      'ba33a46c-2eee-462e-9ca4-fac0e8a0fb6c',
      '9ce1b7af-e8bb-4978-be80-4a17a426e218',
      '30d1cc66-939c-4421-a128-4b4c4d3b30a7',
      'ce53a249-d328-477e-8abf-2927ba30bcad',
      '892b706d-02a6-4e92-bffe-775fdb60f25f',
      '3d94d86b-745d-4189-8902-7e4f410ff2a4',
      '7daa6577-03d0-4de8-8e70-0c900743f687',
      '9343d3f2-f800-4ef0-add2-ba72bf2268b7'
    ]
  }
};

let Environment: any;
switch (process.env.REACT_APP_ENVIRONMENT) {
  case 'DEV':
    Environment = DEV;
    break;
  case 'QA':
    Environment = QA;
    break;
  case 'PROD':
    Environment = PROD;
    break;
  default:
    Environment = DEV;
}

export default Environment;
