import React, { useState } from 'react';
import { Form, Input, Modal } from 'reactstrap';
import { Button } from 'antd';
import { onlyNumbers } from '../../../../../Utils/Utils';

type Props = {
  email: string;
  back: () => void;
};

const GuestModal: React.FC<Props> = ({ email, back }) => {
  const [zipCode, setZipCode] = useState('');
  //const [name] = useState('');
  const [invalidZipCode, setInvalidZipCode] = useState(false);
  const [loadingGuest, setLoadingGuest] = useState(false);
  const handleChangeZipCode = (e: any) => {
    setInvalidZipCode(false);
    const entry = e.target.value;
    setZipCode(onlyNumbers(entry));
  };

  const submitGuestLogin = async () => {
    if (zipCode?.length !== 5) {
      return setInvalidZipCode(true);
    } else {
      setLoadingGuest(true);
      // const res = await guestSignIn(zipCode, email, name);
      // if (res && res.success) {
      //   MixpanelService.track('login_guest');
      //   const token = res.response && res.response.token;
      //   localStorage.setItem('token', token);
      //   window.location.reload();
      // } else {
      //   toast('unable to login', 'error');
      //   setLoadingGuest(false);
      // }
    }
  };

  const handleKeyDownGuest = (e: any) => {
    if (e.key === 'Enter') {
      submitGuestLogin();
    }
  };

  return (
    <Modal className="ibf-login-modal-main" isOpen>
      <div className="ibf-guest-modal-home">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          <div className="ibf-guest-modal-home-title ">
            <b>Welcome</b> <br />
            <b>{email}</b>
          </div>
          <div className="ibf-guest-modal-home-description">
            <span>
              Enter your zip code for the calculation of the prices and shipping
              to your shop or event:
            </span>
          </div>

          <div className="ibf-guest-modal-back-button">
            <u onClick={back} className="pointer">
              (I already have an account at iBuyFlowers.com, go back)
            </u>
          </div>

          <div className="ibf-guest-modal-home-body">
            <div className="mb-3">
              <Input
                value={zipCode}
                inputMode="numeric"
                maxLength={5}
                className="text-center"
                invalid={invalidZipCode}
                onKeyDown={handleKeyDownGuest}
                onChange={(e: any) => handleChangeZipCode(e)}
                placeholder={'Zipcode of your shop or event'}
                type="text"
              />
            </div>

            {/*<div className="ibf-login-modal-password-input">*/}
            {/*  <Input*/}
            {/*    placeholder="Your first name"*/}
            {/*    value={name}*/}
            {/*    className="text-center"*/}
            {/*    onKeyDown={handleKeyDownGuest}*/}
            {/*    onChange={(e: any) => handleChangeName(e)}*/}
            {/*    type="text"*/}
            {/*  />*/}
            {/*</div>*/}

            {invalidZipCode && (
              <div className="ibf-login-modal-invalid-input">
                <b>Sorry, your zip code doesn't seem right</b>
              </div>
            )}

            <div className="ibf-login-modal-continue-btn">
              <Button disabled={loadingGuest} onClick={submitGuestLogin}>
                {loadingGuest && (
                  <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true">
                    {' '}
                  </span>
                )}
                Start &#187;
              </Button>
            </div>
          </div>

          <div className="ibf-login-modal-bottom-guest" />
        </Form>
      </div>
    </Modal>
  );
};

export default GuestModal;
