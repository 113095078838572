import { combineReducers } from '@reduxjs/toolkit';

import AuthReducer from '../Auth/Slice';
import DisplayReducer from '../Display/Slice';
import DashboardReducer from '../Dashboard/Slice';
import CardReducer from '../Card/Slice';
import CartReducer from '../Cart/Slice';
import CheckoutReducer from '../Checkout/Slice';
import CustomBoxReducer from '../CustomBox/Slice';
import CustomerReducer from '../Customer/Slice';
import FilterReducer from '../Filter/Slice';
import ModalReducer from '../Modal/Slice';
import CalendarReducer from '../Calendar/Slice';
import ShoppingListReducer from '../ShoppingList/Slice';
import ProductReducer from '../Product/Slice';
import LoadingReducer from '../Loading/Slice';
import ReferralReducer from '../Referral/Slice';
import HomeLoadingReducer from '../HomeLoading/Slice';
import OrderReducer from '../Order/Slice';
import EventReducer from '../Event/Slice';
import StatementsReducer from '../Statements/Slice';
import HelperReducer from '../Helper/Slice';
import StandingOrderReducer from '../StandingOrder/Slice';

const RootReducer = combineReducers({
  AuthReducer,
  CalendarReducer,
  CardReducer,
  CartReducer,
  CheckoutReducer,
  CustomBoxReducer,
  CustomerReducer,
  DashboardReducer,
  DisplayReducer,
  EventReducer,
  FilterReducer,
  HomeLoadingReducer,
  HelperReducer,
  LoadingReducer,
  ModalReducer,
  ProductReducer,
  ShoppingListReducer,
  ReferralReducer,
  OrderReducer,
  StatementsReducer,
  StandingOrderReducer
});

export default RootReducer;
export type RootState = ReturnType<typeof RootReducer>;
