import { faAngleDoubleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import Product from '../../../../Models/Product';
import {
  convertNumberDateMMMDD,
  convertNumberDateToDate,
  isLessThan15Days
} from '../../../../Utils/Utils';
import ImageComponent from '../../../Shared/ImageComponent/ImageComponent';
import { RootState } from '../../../../Store/Reducers';

type Props = {
  product: Product;
  detail?: () => void;
  productPhotos: string[];
};
const ListViewInfo: React.FC<Props> = ({ product, detail, productPhotos }) => {
  const { date } = useSelector((state: RootState) => state.ProductReducer);

  return (
    <>
      <div className="d-flex w-100">
        <div
          className={'d-flex d-lg-none ibf-list-product-info-image bg-white'}>
          <div
            className="overflow-hidden mr-2 justify-content-center align-items-center"
            style={{ width: '70px', height: '70px' }}>
            <ImageComponent
              imageUrl={productPhotos && productPhotos[0]}
              width={'70px'}
              onErrorUrl={''}
              setDetail={detail}
            />
          </div>
        </div>
        <div className="d-flex flex-column ibf-product-name-wrapper">
          <div className="ibf-product-name">
            <span
              className=" p-sm-0 ibf-text-info"
              id={'tooltip_' + product.key}
              onClick={detail}>
              {product.name}{' '}
              <span className="ibf-contact-text">
                more info <FontAwesomeIcon icon={faAngleDoubleRight} />
              </span>
            </span>
          </div>

          {product.pricing &&
            product.isAvailable &&
            !product.pricing &&
            !isLessThan15Days(convertNumberDateToDate(date)) && (
              <div className="ibf-product-available-mobile">
                <div className="d-flex justify-content-between align-items-center">
                  <>
                    <small>
                      <b className="color-green">
                        Product is available for {convertNumberDateMMMDD(date)},
                        but pricing and ordering only 2 weeks before.
                      </b>
                    </small>
                  </>
                </div>
              </div>
            )}
        </div>

        <UncontrolledTooltip
          placement="bottom"
          target={'tooltip_' + product.key}>
          {product.name}
        </UncontrolledTooltip>
      </div>

      <span />
    </>
  );
};
export default ListViewInfo;
