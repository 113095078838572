import { State } from '../../Models/State';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SearchInventoryParams from '../../Models/SearchInventory/SearchInventoryParams';
import { SearchInventoryResults } from '../../Models/SearchInventoryResults';
import { CustomBoxState } from './Types';
import Product from '../../Models/Product';
import { AddToBoxParams } from '../../Models/AddToBoxParams';
import { AddToCartParams } from '../../Models/AddToCartParams';
import { Cartbox } from '../../Models/Cartbox';

const { IDLE, RESOLVED, PENDING, REJECTED } = State;

const initialState: CustomBoxState = {
  products: [],
  filters: [],
  isEmpty: false,
  filter_conditions: {},
  singleFilters: {},
  currentPage: 0,
  totalItems: 0,
  totalPages: 0,
  currentBox: {},
  currentPercentage: 0,
  isAList: false,
  // isAList: !Boolean(
  //   userAgent.match(
  //     /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  //   )
  // ),
  search: '',
  productsStillFitInBox: true,
  state: IDLE,
  fromEdit: false,
  inventorySold: false
};

const slice = createSlice({
  name: 'customBox',
  initialState,
  reducers: {
    searchInventory(
      state,
      {
        payload
      }: PayloadAction<{
        params: SearchInventoryParams;
        moreProducts?: boolean;
      }>
    ) {
      state.state = PENDING;
    },
    setSearchResults(
      state: CustomBoxState,
      {
        payload: { results }
      }: PayloadAction<{ results: SearchInventoryResults }>
    ) {
      state.state = RESOLVED;
      state.products = results.products;
      state.isEmpty = results.isEmpty;
      state.currentPage = results.currentPage;
      state.totalItems = results.totalItems;
      state.totalPages = results.totalPages;
      state.filters = results.filters;
      state.singleFilters = results.singleFilters;
      state.productsStillFitInBox = results.productsStillFitInBox;
    },
    setMoreProducts(
      state: CustomBoxState,
      { payload }: PayloadAction<{ products: Product[]; currentPage?: number }>
    ) {
      state.state = RESOLVED;
      state.products = [...state.products, ...payload.products];
      if (payload.currentPage) {
        state.currentPage = payload.currentPage;
      }
    },
    setBoxFilters(
      state: CustomBoxState,
      { payload }: PayloadAction<{ conditions: object; search: string }>
    ) {
      state.state = RESOLVED;
      state.filter_conditions = payload.conditions;
      state.search = payload.search;
    },
    setSearch(state: CustomBoxState, { payload }: PayloadAction<string>) {
      state.search = payload;
    },
    setIsAList(state: CustomBoxState) {
      state.isAList = !state.isAList;
    },
    setCurrentPercentage(
      state: CustomBoxState,
      { payload }: PayloadAction<{ percentage: number; box: any }>
    ) {
      //state.state = RESOLVED;
      state.currentPercentage = payload.percentage;
      state.currentBox = payload.box;
    },
    setCurrentBox(state: CustomBoxState, { payload }: PayloadAction<any>) {
      state.state = RESOLVED;
      state.currentBox = payload;
    },
    setProductsStillFitInBox(
      state: CustomBoxState,
      { payload }: PayloadAction<boolean>
    ) {
      state.productsStillFitInBox = payload;
    },
    setInventorySold(
      state: CustomBoxState,
      { payload }: PayloadAction<boolean>
    ) {
      state.inventorySold = payload;
    },
    addToBox(
      state: CustomBoxState,
      {
        payload
      }: PayloadAction<{
        boxParams: AddToBoxParams;
        grower?: string;
        percentage: number;
      }>
    ) {
      state.state = PENDING;
    },
    addToCart(
      state: CustomBoxState,
      { payload }: PayloadAction<AddToCartParams>
    ) {
      state.state = PENDING;
    },
    closeBox(
      state: CustomBoxState,
      { payload }: PayloadAction<{ customer: string; fromBox?: boolean }>
    ) {
      state.fromEdit = false;
      state.state = PENDING;
    },
    openBox(
      state: CustomBoxState,
      {
        payload
      }: PayloadAction<{
        cartbox: Cartbox;
        customer: string;
        fromEdit?: boolean;
      }>
    ) {
      state.state = PENDING;
      state.fromEdit = payload.fromEdit ?? false;
    },
    setClearAll(state: CustomBoxState) {
      return initialState;
    },
    setRejected(state) {
      state.state = REJECTED;
    },
    setResolved(state) {
      state.state = RESOLVED;
    }
  }
});

export const actionsCustomBox = slice.actions;
export default slice.reducer;
