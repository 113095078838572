import React, { useState } from 'react';
import FavoriteComponent from '../../ProductFilterComponent/FavoriteComponent';
import ImageComponent from '../../../Shared/ImageComponent/ImageComponent';
import Product from '../../../../Models/Product';
import { getVarietyImage } from '../../../../Utils/Utils';
import Environment from '../../../../Environment';

type Props = {
  favorite: boolean;
  favoriteView?: boolean;
  productPhotos: string[];
  detail?: () => void;
  product?: Product;
};

const CardViewImage: React.FC<Props> = ({
  favorite,
  detail,
  productPhotos,
  product,
  favoriteView
}) => {
  const [defaultImage, setDefaultImage] = useState('');
  const [productPhoto, setProductPhotos] = useState(
    product?.photos?.map((i) => Environment.publicPath + i) ?? []
  );

  const [photo, setPhoto] = useState(productPhoto[0] ?? '');

  React.useEffect(() => {
    if (productPhoto[0]) {
      setPhoto(productPhoto[0]);
    }
  }, [productPhoto]);

  React.useEffect(() => {
    if (product) {
      setDefaultImage(getVarietyImage(product));
    }
  }, [product]);

  React.useEffect(() => {
    const photos =
      product?.photos?.map((i) => Environment.publicPath + i) ?? [];

    const photoGrower = product?.photos.filter(
      (item) => item.includes('growers') || !item.includes('varieties')
    );
    if (photoGrower?.length! > 0 && product?.growers?.length === 1) {
      let photo: string[] =
        photoGrower?.map((i) =>
          i.includes('grower') ? Environment.publicPath + i : i
        ) ?? [];

      setProductPhotos(photo);
    } else {
      setProductPhotos(photos);
    }
  }, [product]);

  return (
    <div className="justify-content-center d-flex mb-1 pointer">
      <div
        className="overflow-hidden d-flex justify-content-center align-items-center position-relative"
        style={{ width: '256px', height: '192px' }}>
        <FavoriteComponent favorite={favorite} product={product} />
        <ImageComponent
          imageUrl={
            product?.growers!?.length === 1 &&
            product?.photos!?.filter(
              (item) => item.includes('growers') || !item.includes('varieties')
            ).length > 0
              ? photo ?? ''
              : productPhotos
              ? productPhotos[0]
              : defaultImage
          }
          // height={'192'}
          // width={'256'}
          onErrorUrl={''}
          onError={() => {
            if (photo) {
              const currentIndex = productPhotos.findIndex((p) => p === photo);

              if (productPhotos[currentIndex + 1]) {
                setPhoto(productPhotos[currentIndex + 1]);
              }
            }
          }}
          className="img-fluid ibf-category-card-image"
          setDetail={detail}
        />
      </div>
    </div>
  );
};

export default CardViewImage;
