import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/Reducers';
import CheckoutCountDownComponent from './CheckoutCountDownComponent';
import { cartReducer } from '../../../Store/Selectors';
import HeaderProductKommetComponent from './HeaderProductKommetComponent';

type Props = {
  getNavHeight: () => void;
};

const HeaderComponent: React.FC<Props> = ({ getNavHeight }) => {
  const { user } = useSelector((state: RootState) => state.AuthReducer);
  const { products } = useSelector((state: RootState) => state.CheckoutReducer);
  const { cartProducts } = useSelector(cartReducer);
  const [count, setCount] = useState(0);
  const [limitDate, setLimitDate] = useState(false);

  useEffect(() => {
    let productCount = 0;
    Object.keys(cartProducts).forEach((key) => {
      productCount += cartProducts[key].length;
    });
    setCount(productCount);
  }, [cartProducts]);

  React.useEffect(() => {
    if (Object.values(cartProducts).length > 0 || products.length > 0) {
      setLimitDate(true);
    } else {
      setLimitDate(false);
    }
  }, [cartProducts, products]);

  useEffect(() => {
    function displayWindowSize() {
      getNavHeight();
    }

    window.addEventListener('resize', displayWindowSize);
    getNavHeight();

    return () => {
      window.removeEventListener('resize', displayWindowSize);
    };
  }, [getNavHeight]);

  return (
    <>
      {user.metadata.from_manager && (
        <div className={`background-lightgray border `}>
          <div className={`row align-items-center  pt-1 mx-3 `}>
            <div>
              <h5>
                {user.name}, you are logged in for customer{' '}
                <b>"{user.metadata.customer_name}"</b>
              </h5>
            </div>
          </div>
        </div>
      )}
      <div
        id="ibf_header"
        className={
          !limitDate
            ? 'd-none'
            : `d-flex ${
                limitDate
                  ? `flex-column`
                  : 'justify-content-between ibf-header-background px-3 py-2'
              }`
        }>
        {limitDate ? (
          <CheckoutCountDownComponent count={count} />
        ) : (
          <>
            {/*<div className="d-flex flex-column flex-lg-row">*/}
            {/*  <h6 className="ibf-header-text mr-3">*/}
            {/*    <b className="text-capitalize">*/}
            {/*      {' '}*/}
            {/*      {user && user.metadata.guest*/}
            {/*        ? user?.metadata?.main_user_email ?? user.email*/}
            {/*        : user.metadata.customer_name}*/}
            {/*    </b>*/}
            {/*    <b>, this is our optimized website.</b>*/}
            {/*  </h6>*/}
            {/*  <div className="d-flex justify-content-lg-between">*/}
            {/*    <div className="d-flex flex-column flex-md-row">*/}
            {/*      <div className="d-inline ibf-header-text mr-3 ">*/}
            {/*        <span>*/}
            {/*          <FontAwesomeIcon*/}
            {/*            className="mr-2"*/}
            {/*            color="white"*/}
            {/*            icon={faCheckCircle}*/}
            {/*          />*/}
            {/*          Better Experience*/}
            {/*        </span>*/}
            {/*      </div>*/}
            {/*      <div className="d-inline ibf-header-text mr-3 ">*/}
            {/*        <span>*/}
            {/*          <FontAwesomeIcon*/}
            {/*            className="mr-2"*/}
            {/*            color="white"*/}
            {/*            icon={faCheckCircle}*/}
            {/*          />*/}
            {/*          Mobile friendly*/}
            {/*        </span>*/}
            {/*      </div>*/}
            {/*      <div className="d-inline ibf-header-text mr-3 ">*/}
            {/*        <span>*/}
            {/*          <FontAwesomeIcon*/}
            {/*            className="mr-2"*/}
            {/*            color="white"*/}
            {/*            icon={faCheckCircle}*/}
            {/*          />*/}
            {/*          Based on your feedback*/}
            {/*        </span>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="d-flex">*/}
            {/*  <div onClick={closeMessage}>*/}
            {/*    <FontAwesomeIcon*/}
            {/*      className="pointer"*/}
            {/*      color="white"*/}
            {/*      icon={faWindowClose}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </>
        )}
        {products?.some((item) => item?.grower?.komet_intg) && (
          <HeaderProductKommetComponent />
        )}
      </div>
    </>
  );
};

export default React.memo(HeaderComponent);
