import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  debounceTime,
  removeDuplicateFromArrayObj,
  wordNonSpecialCharacters
} from '../../../Utils/Utils';
import { authReducer, filterReducer } from '../../../Store/Selectors';
import {
  CategoryResult,
  getAllProductGroups,
  getProductsSuggestion
} from '../../../Services/ProductService';
import ProductGroup from '../../../Models/ProductGroup';
import { ResponseSbx } from '../../../Models/ResponseSbx';
import { AutoSuggestCategory } from '../../AutoSuggest/AutoSuggestCategory';
import useDeviceDetect from '../../../Hooks/useDeviceDetect';
import { Variety } from '../../../Models/Variety';
import ZipcodeService from '../../../Services/ZipcodeService';
import { State } from '../../../Models/State';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { actionsFilter } from '../../../Store/Filter/Slice';

// import Axios from 'axios';
interface Props {
  width: number | string;
  submit: ({
    queryParams
  }: {
    queryParams?: { [key: string]: string | number };
  }) => void;
}

const cache: {
  [key: string]: {
    product_groups: FilterSearch[];
    varieties: FilterSearch[];
    categories: FilterSearch[];
    // searchList: string[];
  };
} = {};

const searchValidation = (search: string) => {
  if (search.toLowerCase() === 'stock') {
    return 'Stock mixed';
  }
  return search;
};

export type FilterSearch = { _KEY: string; search: string };
const cacheSuggestions: { [key: string]: ProductGroup[] } = {};

const getSuggestionList = (list: FilterSearch[], word: string) => {
  const words: string[] = word.split(' ');
  const nList = removeDuplicateFromArrayObj(list, 'search');

  const filter1 = nList
    .filter((item) =>
      words.every((word) =>
        item.search.toLowerCase().includes(word.toLowerCase())
      )
    )
    .slice(0, 33);
  const filter2 = nList
    .filter((item) =>
      words.some((word) =>
        item.search.toLowerCase().includes(word.toLowerCase())
      )
    )
    .slice(0, 33);

  return [...filter1, ...filter2];
};

const SuggestionListComponent: React.FC<Props> = ({ width, submit }) => {
  const { search } = useSelector(filterReducer);
  const { user } = useSelector(authReducer);
  const [filteredSuggestions, setFilteredSuggestions] = useState<
    FilterSearch[]
  >([]);
  const { isMobile } = useDeviceDetect();
  const [loading, setLoading] = useState(State.IDLE);
  const dispatch = useDispatch();

  const processResponse = useCallback(
    (
      list: {
        product_groups: FilterSearch[];
        varieties: FilterSearch[];
        categories: FilterSearch[];
        // searchList: string[];
      },
      word: string
    ) => {
      if (list) {
        const product_groups = getSuggestionList(list.product_groups, word);
        const varieties = getSuggestionList(list.varieties, word);
        const categories = getSuggestionList(list.categories, word);
        const words: string[] = word.split(' ');
        const newList: FilterSearch[] = [
          ...categories,
          ...product_groups,
          ...varieties
        ]
          .filter((item) =>
            words.every((word) =>
              item.search.toLowerCase().includes(word.toLowerCase())
            )
          )
          .slice(0, 33)
          .sort((a, b) => {
            const words: string[] = word.split(' ');
            const conditionA = words.every((word) =>
              a.search.toLowerCase().includes(word.toLowerCase())
            );
            const conditionB = words.every((word) =>
              b.search.toLowerCase().includes(word.toLowerCase())
            );

            if (conditionA && !conditionB) {
              return -1;
            } else if (!conditionA && conditionB) {
              return 1;
            } else {
              return 0;
            }
          });

        setFilteredSuggestions(
          removeDuplicateFromArrayObj(newList, 'search').slice(
            0,
            isMobile ? 7 : newList.length - 1
          )
        );
      }

      setLoading(State.RESOLVED);
    },
    [isMobile]
  );

  const getSuggestionsByWord = useCallback(
    async (text: string) => {
      const word = (wordNonSpecialCharacters(text.toLowerCase()) || ' ').trim();

      if (cache[word]) {
        return processResponse(cache[word], word);
      } else {
        try {
          if (word) {
            setLoading(State.PENDING);

            const responseSuggestion: {
              items: {
                variety: Variety;
                product_group: ProductGroup;
                category: CategoryResult;
              }[];
              term: string;
              success: boolean;
              cancelled: boolean;
            } = await getProductsSuggestion(searchValidation(word));

            let product_groups: FilterSearch[] = [];
            let varieties: FilterSearch[] = [];
            let categories: FilterSearch[] = [];

            if (
              responseSuggestion?.success &&
              responseSuggestion.items &&
              responseSuggestion.term === word
            ) {
              responseSuggestion.items.forEach((item) => {
                const condition = ZipcodeService._STATES_WITH_ZIPCODES.HI.codes.includes(
                  user.metadata.customer_main_zipcode
                );

                if (
                  !condition ||
                  (condition &&
                    item.product_group._KEY !==
                      '12932620-8484-4dba-bd71-205ed8e5da22')
                ) {
                  categories.push({
                    search: item.category.category + ' (Category) ',
                    _KEY: item.category._KEY
                  });
                  product_groups.push({
                    search:
                      item.category.category +
                      ' ' +
                      item.product_group.common_name +
                      ' (Product group) ',
                    _KEY: item.product_group._KEY
                  });
                  varieties.push({
                    search:
                      item.product_group.common_name +
                      ' ' +
                      item.variety.variety_name +
                      ' (Variety) ',
                    _KEY: item.variety._KEY
                  });
                }
              });
              cache[word] = {
                product_groups,
                varieties,
                categories
              };

              processResponse(cache[word], word);
            } else {
              if (!responseSuggestion || !responseSuggestion.cancelled) {
                setLoading(State.RESOLVED);
              }
            }
          } else {
            setLoading(State.RESOLVED);
          }
        } catch (error) {
          console.log(error);
          // setLoading(State.REJECTED);
        }
      }
    },
    [user.metadata.customer_main_zipcode, processResponse]
  );

  const getSuggestionsProductGroup = useCallback(async () => {
    let items: ProductGroup[] = [];

    setLoading(State.PENDING);
    if (cacheSuggestions['all']) {
      items = cacheSuggestions['all'];
    } else {
      const responseSuggestion: ResponseSbx<ProductGroup> = await getAllProductGroups();
      if (responseSuggestion?.success && responseSuggestion.results) {
        items = responseSuggestion.results;
        cacheSuggestions['all'] = items;
      }
    }

    let list = items
      .filter((product) => product.common_name)
      .sort((a, b) => a.common_name.localeCompare(b.common_name));

    if (
      ZipcodeService._STATES_WITH_ZIPCODES.HI.codes.includes(
        user.metadata.customer_main_zipcode
      )
    ) {
      list = list.filter(
        (item) => item._KEY !== '12932620-8484-4dba-bd71-205ed8e5da22'
      );
    }

    setFilteredSuggestions(
      list
        .filter((product_group) => product_group.common_name)
        .map((item) => ({
          search: item.common_name + ' (Product group)',
          _KEY: item._KEY
        }))
        .slice(0, isMobile ? 4 : 7)
    );

    setLoading(State.RESOLVED);
  }, [user.metadata.customer_main_zipcode, isMobile]);

  useEffect(() => {
    const searchV = searchValidation(search);
    if (searchV) {
      setLoading(State.PENDING);

      if (cache[searchV]) {
        return processResponse(cache[searchV], searchV);
      } else {
        debounceTime(getSuggestionsByWord, searchValidation(searchV), 500);
      }
    } else {
      getSuggestionsProductGroup();
    }

    return () => {};
  }, [
    search,
    getSuggestionsByWord,
    getSuggestionsProductGroup,
    processResponse
  ]);

  const submitSuggestion = (search_val: { _KEY: string; search: string }) => {
    setFilteredSuggestions([]);
    dispatch(actionsFilter.setSearch({ search: '' }));

    const filterType: { [key: string]: string } = {
      'Product group': 'product_group',
      Category: 'category',
      Variety: 'variety'
    };

    const queryParams: { [key: string]: string } = {
      [filterType[
        search_val.search
          .split('(')[1]
          .split(')')[0]
          .trim()
      ]]: search_val._KEY
    };

    queryParams.search_name = search_val.search;

    submit({ queryParams });
  };

  return (
    <>
      <div
        style={{
          position: 'absolute',
          left: isMobile ? 0 : '40px',
          top: '37px',
          minWidth: '175px',
          width,
          backgroundColor: 'white',
          zIndex: 2
        }}
        className="suggested">
        {loading === State.PENDING && (
          <div className="d-flex justify-content-center align-items-center p-2 border-bottom">
            <span>
              Searching {search ? `suggestion for ${search}` : ''} ...
            </span>{' '}
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        )}

        <AutoSuggestCategory
          search={search}
          isLoading={loading === State.PENDING}
          width={width}
          onSubmit={() => submit({})}
          suggestionItems={filteredSuggestions}
          submitSuggestion={submitSuggestion}
        />
      </div>
    </>
  );
};

export default SuggestionListComponent;
