import React, { ChangeEvent } from 'react';
import Product from '../../../../../Models/Product';
import { GrowerPricing } from '../../../../../Models/GrowerPricing';
import CustomInputsComponent from '../../../ProductComponent/ProductFormComponent/CustomInputsComponent';

type Props = {
  product: Product;
  lengthSelected: GrowerPricing;
  validations: {
    boxOnly?: boolean;
    isProductDetail?: boolean;
    growerSelected: boolean;
  };
  handleStemChange: (
    evt: React.FormEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => void;
  getBoxesValue: (evt: ChangeEvent<HTMLSelectElement>) => void;
  stemInput: JSX.Element;
  boxSelect: JSX.Element;
  index?: number;
  activeTab: string;
  boxesSelected: string;
  boxesAvailable: number[];
  bunchesAvailable: number;
};

const CardQuantityInputs: React.FC<Props> = ({
  lengthSelected,
  validations,
  handleStemChange,
  getBoxesValue,
  boxSelect,
  stemInput,
  product,
  index,
  activeTab,
  boxesSelected,
  boxesAvailable,
  bunchesAvailable
}) => {
  const { boxOnly, growerSelected, isProductDetail } = validations;

  return (
    <>
      {growerSelected ? (
        <CustomInputsComponent
          lengthSelected={lengthSelected}
          getBoxesValue={getBoxesValue}
          handleStemChange={handleStemChange}
          boxOnly={boxOnly}
          product={product}
          index={index}
          isProductDetail={isProductDetail}
          category={product.category.key}
          activeTab={activeTab}
          boxesSelected={boxesSelected}
          boxesAvailable={boxesAvailable}
          bunchesAvailable={bunchesAvailable}
        />
      ) : (
        <>
          {!boxOnly && (
            <div className={'p-0 col-12 order-3 my-0'}>
              {isProductDetail && !boxOnly && <label>Stems:</label>}
              {stemInput}
            </div>
          )}

          {boxOnly && (
            <div className="col-12 p-0 order-3">
              {isProductDetail && <label>Boxes:</label>}
              {boxSelect}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CardQuantityInputs;
