import TrackJSLogger from './Middleware/TrackJSLogger';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import RootReducer from './Reducers';
import cardSaga from './Card/Saga';
import customerSaga from './Customer/Saga';
import cartSaga from './Cart/Saga';
import shoppingListSaga from './ShoppingList/Saga';
import checkoutSaga from './Checkout/Saga';
import customBoxSaga from './CustomBox/Saga';
import productSaga from './Product/Saga';
import homeLoadingSaga from './HomeLoading/Saga';
import calendarSaga from './Calendar/Saga';
import orderSaga from './Order/Saga';
import authSaga from './Auth/Saga';
import eventSaga from './Event/Saga';
import statementsSaga from './Statements/Saga';
import standingOrderSaga from './StandingOrder/Saga';
import * as Sentry from '@sentry/react';

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const Store = createStore(
  RootReducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware, TrackJSLogger),
    sentryReduxEnhancer
  )
);
sagaMiddleware.run(cardSaga);
sagaMiddleware.run(authSaga);
sagaMiddleware.run(cartSaga);
sagaMiddleware.run(checkoutSaga);
sagaMiddleware.run(customBoxSaga);
sagaMiddleware.run(customerSaga);
sagaMiddleware.run(productSaga);
sagaMiddleware.run(homeLoadingSaga);
sagaMiddleware.run(shoppingListSaga);
sagaMiddleware.run(calendarSaga);
sagaMiddleware.run(orderSaga);
sagaMiddleware.run(eventSaga);
sagaMiddleware.run(statementsSaga);
sagaMiddleware.run(standingOrderSaga);
export default Store;
