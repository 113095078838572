import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cartbox } from '../../Models/Cartbox';
import { eventReducer } from '../../Store/Selectors';
import { actionsModal } from '../../Store/Modal/Slice';
import { ADD_EDIT_EVENT_NAME } from '../../Store/Modal/Types';
import { eventActions } from '../../Store/Event/Slice';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { EventCartbox } from '../../Models/EventCartbox';

type Props = {
  cartbox: Cartbox;
  disabled?: boolean;
};

const CheckoutEventSelect = ({ cartbox, disabled }: Props) => {
  const { eventList } = useSelector(eventReducer);
  const dispatch = useDispatch();

  const [dropdownOpen, setOpen] = useState(false);

  const [currentEvent, setCurrentEvent] = useState<EventCartbox | null>(
    cartbox?.event
  );

  const toggle = () => setOpen(!dropdownOpen);

  const openAddAndEditEvent = () =>
    dispatch(actionsModal.setOpenModal({ modal: ADD_EDIT_EVENT_NAME }));

  const handleSelect = (event_key: string) => {
    const eventCartbox = eventList.find((event) => event._KEY === event_key);
    setCurrentEvent(eventCartbox ?? null);

    if (cartbox?._KEY) {
      //setCartboxLoading(cartbox._KEY);
      dispatch(
        eventActions.updateCartboxEvent({
          event: eventCartbox,
          cart_box: cartbox
        })
      );
    }
  };

  return (
    <Fragment key={'fragment_' + cartbox._KEY}>
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          style={{ backgroundColor: currentEvent?.color ?? '#fff' }}
          disabled={disabled}
          className="d-flex justify-content-between align-items-center">
          <span className="text-black font-sm">
            {currentEvent?.tag_name ?? 'Not related to an event...'}
          </span>
          <FontAwesomeIcon icon={faCaretDown} color="black" />
        </DropdownToggle>
        <DropdownMenu className="overflow-auto height-300">
          <DropdownItem
            key={0}
            className="font-sm d-flex align-items-center"
            onClick={() => handleSelect('')}>
            <FontAwesomeIcon
              icon={faBan}
              className="mr-1"
              rotation={90}
              size={'2x'}
            />{' '}
            Not related to an event
          </DropdownItem>
          <DropdownItem divider />
          {eventList.map((event, index) => {
            return (
              event._KEY !== 'Not related to an event name' && (
                <Fragment key={event._KEY + '_' + index}>
                  <DropdownItem
                    key={event._KEY}
                    onClick={() => handleSelect(event._KEY)}
                    className="d-flex align-items-center font-sm">
                    <span
                      className="button-color-picker mr-1"
                      style={{
                        backgroundColor: event.color ?? '#FFF'
                      }}
                    />{' '}
                    {event.tag_name}
                  </DropdownItem>
                  <DropdownItem divider />
                </Fragment>
              )
            );
          })}
          <DropdownItem
            key={1}
            onClick={openAddAndEditEvent}
            className="pl-4 font-sm">
            Add and Edit event names
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </Fragment>
  );
};

export default CheckoutEventSelect;
