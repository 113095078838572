import { State } from '../../Models/State';
import CartProduct from '../../Models/CartProduct';

export const LIST_CART = 'cart/listCart';

export interface CartState {
  cartProducts: { [key: string]: CartProduct[] };
  charge_dates: { charge_date: number }[];
  deliveryDateCart: { eta_date: number }[];
  state: State;
}
