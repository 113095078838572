import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUser } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStateList,
  updateCustomerInfo
} from '../../../../../Services/UserService';
import { toast } from '../../../../../Utils/Utils';
import { validateToken } from '../../../../../Services/AuthService';
import { plainToClass } from 'class-transformer';
import AuthResponse from '../../../../../Models/AuthResponse';
import { RootState } from '../../../../../Store/Reducers';
import { actionsModal } from '../../../../../Store/Modal/Slice';
import { actionsAuth } from '../../../../../Store/Auth/Slice';
import {
  COMPANY_INFORMATION_MODAL,
  EDIT_COMPANY_INFORMATION_MODAL
} from '../../../../../Store/Modal/Types';

type Props = {
  className?: string;
};

const EditCompanyInformationModal: React.FC<Props> = ({ className }) => {
  const {
    user: { metadata },
    user,
    token,
    config,
    manager
  } = useSelector((state: RootState) => state.AuthReducer);
  const { editCompanyInformationModal } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const openModal = editCompanyInformationModal.open;
  const dispatch = useDispatch();
  const toggle = () =>
    dispatch(
      actionsModal.setCloseModal({ modal: EDIT_COMPANY_INFORMATION_MODAL })
    );
  const [states, setStates] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<string>('IDLE');

  const upload = (event: any) => {
    if (event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];

    let customer = { ...state.customer };

    customer.customer_logo = URL.createObjectURL(file);

    setState({
      ...state,
      customer,
      customerImage: file
    });
  };
  const [state, setState] = useState<any>({
    customer: {
      company_name: '',
      street: '',
      business: '',
      city: '',
      state: '',
      zipcode: '',
      office_phone: '',
      customer_logo: '',
      office_email: '',
      events_per_year: 0,
      customer_employees_quantity: '',
      stores_quantity: 0,
      spend_per_week: 0,
      hear_about_us: ''
    },
    customerImage: '',
    info: [
      {
        title: 'Company Name:',
        type: 'text',
        name: 'company_name',
        inputProps: { maxLength: 40 }
      },
      {
        title: 'Are you?',
        type: 'select',
        name: 'business',
        options: [
          {
            name: 'Gift or deco retail shop with flowers',
            value: 'Gift or deco shop'
          },
          {
            name: 'Retail florist with a commercial storefront',
            value: 'Florist shop'
          },
          {
            name: 'Flower delivery service without a frontstore',
            value: 'Flower delivery service only'
          },
          {
            name: 'Weddings & events floral designer',
            value: 'Wedding & event floral designer only (not a florist)'
          },
          {
            name: 'Wedding & event planner only, not the floral designer',
            value: 'Event planner (only), not a floral designer or florist'
          },
          { name: 'Venue', value: 'Venue' },
          { name: 'Hotel', value: 'Hotel' },
          { name: 'Supermarket', value: 'Supermarket' },
          { name: 'Photographer', value: 'Photographer' },
          { name: 'Grower / Flower farm', value: 'Grower / Flower farm' },
          { name: 'Garden center', value: 'Garden center' },
          { name: 'Church', value: 'Church' },
          { name: 'School', value: 'School' },
          {
            name: 'Beauty salon / Spa / Sauna',
            value: 'Beauty salon / Spa / Sauna'
          },
          { name: 'Other', value: 'Other' }
        ]
      },
      {
        title: 'Company Logo:',
        type: 'file',
        label: 'Upload Image',
        name: 'company_logo',
        onChange: upload
      },
      {
        title: 'Street:',
        type: 'text',
        name: 'street',
        inputProps: { maxLength: 40 }
      },
      {
        title: 'City:',
        type: 'text',
        name: 'city'
      },
      {
        title: 'State:',
        type: 'select',
        name: 'state',
        options: states
      },
      {
        title: 'Zip Code:',
        type: 'number',
        name: 'zipcode'
      },
      {
        title: 'Phone:',
        type: 'number',
        name: 'office_phone'
      },
      {
        title: 'Office Email:',
        type: 'text',
        name: 'office_email'
      },
      {
        title: 'How did you hear about us?',
        type: 'select',
        name: 'hear_about_us',
        options: [
          { name: 'Choose one', value: '' },
          {
            name: 'Another florist or wedding and event planner told me',
            value: 'Another florist or wedding and event planner told me'
          },
          { name: 'Facebook', value: 'Facebook' },
          { name: 'Google', value: 'Google' },
          { name: 'Instagram', value: 'Instagram' },
          { name: 'LinkedIn', value: 'LinkedIn' },
          { name: 'E-mail', value: 'E-mail' },
          {
            name: 'iBuyFlowers.com reached out to me (phone or e-mail)',
            value: 'iBuyFlowers.com reached out to me (phone or e-mail)'
          }
        ]
      }
    ],
    floristInfo: [
      {
        title: 'Stores Quantity:',
        type: 'number',
        name: 'stores_quantity'
      },
      {
        title: 'Employees Quantity:',
        type: 'number',
        name: 'employees_quantity'
      },
      {
        title: 'Average weekly spends:',
        type: 'select',
        name: 'spend_per_week',
        options: [
          { name: 'Choose one', value: '' },
          {
            name: '> $1000USD',
            value: '> $1000USD'
          },
          { name: '$500USD - $999USD', value: '$500USD - $999USD' },
          { name: '$100USD - $499USD', value: '$100USD - $499USD' },
          { name: '< $100USD', value: '< $100USD' }
        ]
      }
    ],
    eventsInfo: [
      {
        title: 'Events per year',
        type: 'select',
        name: 'events_per_year',
        options: [
          { name: 'Choose one', value: '' },
          { name: 'With 0 - 9 events a year', value: '< 10' },
          { name: 'With 10 - 24 events a year', value: '10 - 24' },
          { name: 'With 25 - 59 events a year', value: '25 - 59' },
          { name: 'With 60+ events a year', value: '> 60' }
        ]
      }
    ]
  });
  const handleChange = (event: any) => {
    let { value, name } = event.currentTarget;
    // if (event.target.type === 'radio' && event.target.checked) {
    //   value = value.toLowerCase();
    // }
    let customer = { ...state.customer };
    customer[name] = value;
    setState({
      ...state,
      customer
    });
  };

  const validateData = (customer: any) => {
    return (
      !!customer.company_name &&
      !!customer.business &&
      !!customer.street &&
      !!customer.zipcode &&
      !!customer.city &&
      !!customer.office_phone &&
      !!customer.office_email &&
      !!customer.hear_about_us
    );
  };

  const customerEdit = async (customer: any, customerImage: any) => {
    setLoading('PENDING');
    if (!validateData(customer) && (customer.logo || customerImage)) {
      toast('Please fill the requested fields', 'error');
      setLoading('REJECTED');
      return;
    }

    if (customerImage) {
      upload(customerImage);
    }
    if (customer.business === 'Florist') {
      customer.events_per_year = null;
    } else {
      customer.spend_per_week = null;
      customer.stores_quantity = null;
      customer.employees_quantity = null;
    }
    setState({ ...state, customer, customerImage: customerImage });

    let customerState = states.find((state: any) => {
      return state.state_iso === customer.state;
    });

    const company: any = {
      _KEY: customer._KEY,
      business: customer.business,
      company_name: customer.company_name,
      street: customer.street,
      city: customer.city,
      state: customerState._KEY,
      zipcode: customer.zipcode,
      office_phone: customer.office_phone,
      office_email: customer.office_email,
      hear_about_us: customer.hear_about_us,
      stores_quantity: customer.stores_quantity,
      employees_quantity: customer.employees_quantity,
      spend_per_week: customer.spend_per_week,
      events_per_year: customer.events_per_year
    };

    if (state.actual_address) {
      company.actual_address = JSON.stringify({
        state: { abbr: customerState.state_iso, name: customerState.state },
        address: customer.street,
        city: customer.city,
        zipcode: customer.zipcode
      });
    }

    const res = await updateCustomerInfo(company);
    if (res) {
      const {
        data: { user, isNewDay, offset }
      }: any = await validateToken(token);
      const authData = plainToClass(AuthResponse, {
        token,
        config,
        manager,
        user,
        isNewDay,
        offset
      });
      await dispatch(actionsAuth.setAuthData(authData));
      setLoading('RESOLVED');
      toast('Customer successfully updated', 'success');
    } else {
      toast("Customer can't be updated", 'error');
      setLoading('REJECTED');
    }
    dispatch(actionsModal.setCloseModal({ modal: COMPANY_INFORMATION_MODAL }));
    dispatch(
      actionsModal.setCloseModal({ modal: EDIT_COMPANY_INFORMATION_MODAL })
    );
  };

  useEffect(() => {
    if (state && state.customer && !state.customer._KEY) {
      getStateList().then((res) => {
        const states = res.map((i: any) => ({
          name: i.state,
          value: i.state_iso
        }));
        setStates(res);
        const newInfo = state.info.map((option: any) => {
          if (option.name === 'state') {
            option.options = states;
          }
          return option;
        });
        setState({
          ...state,
          customer: {
            _KEY: metadata.customer,
            company_name: metadata.customer_name,
            street: metadata.customer_main_street,
            business: metadata.customer_business,
            city: metadata.customer_main_city,
            state: metadata.customer_main_state,
            zipcode: metadata.customer_main_zipcode,
            office_phone: metadata.customer_office_phone,
            office_email: metadata.customer_office_email,
            events_per_year: metadata.customer_events_per_year,
            employees_quantity: metadata.customer_employees_quantity,
            stores_quantity: metadata.customer_stores_quantity,
            spend_per_week: metadata.customer_spend_per_week,
            hear_about_us_first_time: metadata.customer_hear_about_us
          },
          info: newInfo,
          actual_address:
            metadata.customer_main_state === metadata.customer_state &&
            metadata.customer_main_city === metadata.customer_city &&
            metadata.customer_main_street === metadata.customer_street &&
            metadata.customer_main_zipcode === metadata.customer_zipcode
        });
      });
    }
  }, [metadata, user, state]);

  const typeSwitch = (item: any) => {
    switch (item.type) {
      case 'select':
        return (
          <select
            name={item.name}
            className="form-control input-sm"
            value={state.customer[item.name] || ''}
            onChange={handleChange}>
            {item &&
              item.options &&
              item.options
                .sort(
                  (
                    a: { name: string; value: string },
                    b: { name: string; value: string }
                  ) => a?.name?.localeCompare(b?.name)
                )
                .map((item: any) => (
                  <option value={item.value} key={item.value}>
                    {item.name}
                  </option>
                ))}
          </select>
        );
      case 'file':
        return (
          <>
            <div>
              <img
                src={state.customer.customer_logo}
                alt=""
                className="profile-picture"
              />
            </div>
            <p className="file-select">
              {item.label}
              <input type="file" onChange={item.onChange} />
            </p>
          </>
        );
      case 'checkbox':
        return (
          <>
            {item.options.map((option: any) => (
              <div className="form-check" key={option}>
                <input
                  className="form-check-input"
                  type="radio"
                  name={item.name}
                  id={option}
                  checked={option === state.customer.business}
                  value={option}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor={option}>
                  {option}
                </label>
              </div>
            ))}
          </>
        );
      default:
        return (
          <input
            type={item.type}
            className="form-control input-sm"
            name={item.name}
            value={state.customer[item.name] || ''}
            onChange={handleChange}
            {...item.inputProps}
          />
        );
    }
  };

  return (
    <div>
      <Modal size="lg" isOpen={openModal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>
          {' '}
          <FontAwesomeIcon icon={faUser} /> Edit Company Information
        </ModalHeader>

        <ModalBody>
          <div className="row">
            {state &&
              state.info &&
              state.info.map((item: any) => (
                <div className="col-sm-4" key={item.name}>
                  <div className="form-group">
                    <b>{item.title}</b>
                    {typeSwitch(item)}
                  </div>
                </div>
              ))}
            {state &&
              state.customer &&
              state.customer.business === 'Florist' &&
              state.floristInfo.map((item: any) => (
                <div className="col-sm-4" key={item.name}>
                  <div className="form-group">
                    <b>{item.title}</b>
                    {typeSwitch(item)}
                  </div>
                </div>
              ))}
            {state.customer &&
              state.customer.business !== 'Florist' &&
              state.eventsInfo.map((item: any) => (
                <div className="col-sm-4" key={item.name}>
                  <div className="form-group">
                    <b>{item.title}</b>
                    {typeSwitch(item)}
                  </div>
                </div>
              ))}
          </div>
        </ModalBody>

        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary btn__info mr-2"
            disabled={loading === 'PENDING'}
            onClick={() => customerEdit(state.customer, state.customerImage)}>
            {loading === 'PENDING' ? (
              <FontAwesomeIcon icon={faSpinner} pulse />
            ) : (
              'SAVE CHANGES'
            )}
          </button>
          <button
            type="button"
            disabled={loading === 'PENDING'}
            className="btn btn-primary btn__info mr-2"
            onClick={toggle}>
            <i className="fa fa-arrow-alt-left" /> BACK
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditCompanyInformationModal;
