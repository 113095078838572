import React from 'react';
import { Button, Modal } from 'reactstrap';
import modalFooter from '../../../../../Assets/Images/modal-footer.png';
import { TrackEventMonitor } from '../../../../../Classes/TrackEvent';

type Props = {
  open: boolean;
  continueAsNewCustomer: () => void;
  goToLogin: () => void;
  headerModalText: JSX.Element;
};

const AuthWelcomeModal = ({
  open,
  continueAsNewCustomer,
  goToLogin,
  headerModalText
}: Props) => {
  const trackOptionEvent = (option: string) => {
    TrackEventMonitor.newEvent({
      props: {
        sign_in_option: option,
        date: new Date()
      },
      metadata: {
        name: 'ibf_user_signin_option'
      }
    });
  };

  return (
    <Modal className="ibf-login-modal-main" isOpen={open}>
      <div className="ibf-login-modal-home">
        <div className="ibf-login-modal-top-info">
          <div className="ibf-login-modal-home-title mb-3">
            <span className="font-weight-bold">Welcome to iBuyFlowers.com</span>
          </div>
          <div className="ibf-login-modal-home-description px-4">
            {headerModalText}
          </div>
        </div>

        <div className="ibf-login-modal-content">
          <div className="ibf-login-modal-home-body">
            <div className="d-flex justify-content-center flex-column">
              <Button
                color={'primary'}
                className={'rounded-pill mb-3'}
                outline
                onClick={() => {
                  continueAsNewCustomer();
                  trackOptionEvent("I'm new");
                }}>
                <span className="underline">
                  I'm new, get me started under 1 minute
                </span>
              </Button>

              <Button
                color={'primary'}
                className={'rounded-pill'}
                onClick={() => {
                  goToLogin();
                  trackOptionEvent('Login');
                }}>
                Login
              </Button>
            </div>
          </div>
        </div>

        <div className="ibf-login-modal-bottom-img">
          <img src={modalFooter} width="100%" alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default AuthWelcomeModal;
