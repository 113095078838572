import React from 'react';

type Props = {
  height?: string;
  width?: string;
  backgroundColor?: string;
  zIndex?: number;
  onCLick?: () => void;
};

const OverlayComponent: React.FC<Props> = ({
  height,
  width,
  backgroundColor,
  zIndex,
  onCLick
}) => {
  return (
    <div
      className=" fixed-top d-block"
      onClick={onCLick}
      style={{
        width: width,
        height: height,
        backgroundColor: backgroundColor,
        zIndex: zIndex
      }}
    />
  );
};

export default OverlayComponent;
