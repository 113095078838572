import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../Store/Reducers';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { CONFIRM_MODAL } from '../../../../Store/Modal/Types';

type Props = {
  title?: string;
  className?: string;
  size?: string;
  headerEnabled?: boolean;
  footerEnabled?: boolean;
  disabledOverFlow?: boolean;
  bodyClassName?: string;
  okText?: string;
  cancelText?: string;
  okClick?: () => void;
};

const ConfirmModalComponent: React.FC<Props> = ({
  title,
  className,
  size,
  headerEnabled = false,
  footerEnabled = false,
  disabledOverFlow = false,
  bodyClassName,
  okClick,
  okText,
  cancelText
}) => {
  const { confirmModal } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const dispatch = useDispatch();
  const data = confirmModal.data ? confirmModal.data : '';
  const onClose = () =>
    dispatch(actionsModal.setCloseModal({ modal: CONFIRM_MODAL, data: data }));
  const body = confirmModal.data.body;
  const handleOnClick = () => {
    okClick && okClick();
    onClose();
  };
  return (
    <div>
      <Modal
        isOpen={confirmModal.open}
        toggle={onClose}
        className={className}
        size={size}
        centered={true}>
        {headerEnabled && <ModalHeader toggle={onClose}>{title}</ModalHeader>}
        <ModalBody
          className={bodyClassName}
          style={
            disabledOverFlow ? {} : { overflow: 'auto', maxHeight: '70vh' }
          }>
          {body && body}
        </ModalBody>
        {footerEnabled && (
          <ModalFooter>
            <Button color="primary" onClick={handleOnClick}>
              {okText || 'Continue'}
            </Button>
            {cancelText && (
              <Button color="secondary" onClick={onClose}>
                {cancelText || 'Cancel'}
              </Button>
            )}
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

export default ConfirmModalComponent;
