import React from 'react';
import { redirectToCheckout } from '../../../../Utils/Utils';
import { logout } from '../../../../Services/AuthService';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCustomer } from '../../../../Store/Customer/Slice';
import { RootState } from '../../../../Store/Reducers';

type Props = {
  goToDashboard: () => void;
  closeMenu: () => void;
};

const MyAccountList: React.FC<Props> = ({ closeMenu, goToDashboard }) => {
  const { user } = useSelector((state: RootState) => state.AuthReducer);
  const dispatch = useDispatch();
  return (
    <>
      <div>
        <ul className="pt-2 pl-3 pr-5">
          <li
            onClick={goToDashboard}
            className="d-flex justify-content-between align-items-center my-3 pointer list-unstyled w-75">
            <span className="underline">Dashboard</span>
          </li>
          <li
            onClick={async () => {
              const preferences = {
                ...user.metadata.preferences,
                ...{ hideGreetingModal: true }
              };
              dispatch(
                actionsCustomer.updateUserPreferences({
                  customer: user.metadata.customer,
                  preferences
                })
              );
              redirectToCheckout();
              closeMenu();
            }}
            className="d-flex justify-content-between align-items-center my-3 pointer list-unstyled w-75">
            <span className="underline">Proceed to payment</span>
          </li>
          <li
            onClick={() => logout()}
            className="d-flex justify-content-between align-items-center my-3 pointer list-unstyled w-75">
            <span className="underline">Logout</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MyAccountList;
