import Axios, { AxiosError, AxiosResponse } from 'axios';
import Environment from '../Environment';

export const HTTP = Axios.create({
  baseURL: Environment.eventBaseApi,
  headers: {
    'app-key': Environment.appKey
  }
});

export const createSbxEvent = (params: {
  meta: any;
  props: any;
  extra: any;
}) => {
  return HTTP.post(
    `/api/event/${Environment.domain}/${params.meta.name}`,
    params
  )
    .then((res: AxiosResponse) => Promise.resolve(res.data || res))
    .catch((err: AxiosError) =>
      Promise.resolve({ success: false, error: err.message })
    );
};
