import React from 'react';
import Product from '../../../../Models/Product';
import CardViewInfo from './CardViewInfo';
import CardViewImage from './CardViewImage';
import MessageDisclaimer from './MessageDisclaimer';
import { useSelector } from 'react-redux';
import { authReducer } from '../../../../Store/Selectors';
import MessageProductKommet from './MessageProductKommet';
type Props = {
  productPhotos: string[];
  detail?: () => void;
  product: Product;
  index?: number;
  form?: JSX.Element;
  favorite: boolean;
};

const CardViewComponent: React.FC<Props> = ({
  product,
  productPhotos,
  detail,
  index,
  form,
  favorite
}) => {
  const { config } = useSelector(authReducer);

  return (
    <div className="ibf-card-shadow h-100 overflow-hidden bg-white">
      <div className="d-flex flex-column h-100">
        <CardViewImage
          favorite={favorite}
          productPhotos={productPhotos}
          detail={detail}
          product={product}
        />
        {product?.pricing &&
          Object.values(product?.pricing!).some((item) => item?.komet_intg) && (
            <MessageProductKommet className="justify-content-start font-weight-bold py-1 px-2 my-1" />
          )}

        {config?.tintedAlert?.tinted_terms.some((item) =>
          product?.name?.toLowerCase().includes(item)
        ) && <MessageDisclaimer />}
        <div className="d-flex flex-column justify-content-between">
          <div className="ibf-product-form-wrapper-card">
            <CardViewInfo index={index} product={product} detail={detail} />
          </div>
        </div>
        {form}
      </div>
    </div>
  );
};

export default React.memo(CardViewComponent);
