import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import useDeviceDetect from '../../../../Hooks/useDeviceDetect';
import farmGrower from '../../../../Assets/Images/Farm grower 1.png';
import platform from '../../../../Assets/Images/Platform.png';

const arrayItemsFormSignUp = [
  {
    id: 1,
    icon: <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />,
    text: 'Cut out the middleman and receive the freshest flowers possible!'
  },
  {
    id: 2,
    icon: <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />,
    text:
      'Up to 10 days fresher! Your inventory will last for weeks and your customers will enjoy their flowers longer!'
  },
  {
    id: 3,
    icon: <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />,
    text: 'Order by the bunch or box.'
  },
  {
    id: 4,
    icon: <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />,
    text: 'Choose from over 8000+ flowers.'
  },
  {
    id: 5,
    icon: <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />,
    text: 'FREE shipping on full boxes.'
  },
  {
    id: 6,
    icon: <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />,
    text: "Buy now, don't pay until packing"
  },
  {
    id: 7,
    icon: <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />,
    text: 'We’re always here to help.'
  }
];

export default function GuestItemsAdvantages() {
  const [showItems, setShowItems] = useState(true);
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    if (isMobile) {
      setShowItems(false);
    }
  }, [isMobile]);

  return (
    <div className="d-flex flex-column">
      <div
        className="d-block d-md-none text-center mb-3 mt-1"
        onClick={() => setShowItems(!showItems)}>
        <span className="text-white underline pointer">
          Show me the advantages...
        </span>
      </div>

      {showItems && (
        <>
          <div className="d-block px-0 px-xl-4 position-relative w-80 mb-5">
            <img
              src={platform}
              alt="image_platform"
              className="image_plataform"
            />
            <div className="d-flex justify-content-end pr-4">
              <img
                src={farmGrower}
                alt="image_farmGrower"
                className="image-farm-grower"
              />
            </div>
          </div>
          <ul className="mt-2 list-login px-0 px-xl-4 ">
            {arrayItemsFormSignUp.map((item) => {
              return (
                <li key={item.id} className="mb-1">
                  <span className="mr-1">{item.icon}</span>

                  {item.text}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
}
