import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authReducer, modalReducer } from '../../../../Store/Selectors';
import { Button, Input, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { actionsModal } from '../../../../Store/Modal/Slice';
import {
  CHANGE_PASSWORD_MODAL,
  COMPANY_INFORMATION_MODAL
} from '../../../../Store/Modal/Types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { actionsAuth } from '../../../../Store/Auth/Slice';
import { State } from '../../../../Models/State';

type ChangePasswordModel = {
  current_password: string;
  new_password: string;
  repeat_password: string;
};

const validationInputMessage = (
  <span className="ibf-font-12 color-red">Please fill in this info.</span>
);

const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === ' ') {
    e.preventDefault();
  }
};

const ChangePasswordModal = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm<ChangePasswordModel>();
  const {
    CHANGE_PASSWORD_MODAL: { open }
  } = useSelector(modalReducer);
  const { state } = useSelector(authReducer);
  const dispatch = useDispatch();
  const toggle = () =>
    dispatch(actionsModal.setCloseModal({ modal: CHANGE_PASSWORD_MODAL }));

  const onSubmit: SubmitHandler<ChangePasswordModel> = (data) => {
    if (
      data.repeat_password.toLowerCase() !== data.new_password.toLowerCase()
    ) {
      setError('repeat_password', {
        type: 'match',
        message: 'Please make shure the passwords match.'
      });
      return;
    }

    dispatch(
      actionsAuth.changePassword({
        new_password: data.new_password,
        current_password: data.current_password
      })
    );
  };

  const toggleAll = () => {
    dispatch(actionsModal.setCloseModal({ modal: CHANGE_PASSWORD_MODAL }));
    dispatch(actionsModal.setCloseModal({ modal: COMPANY_INFORMATION_MODAL }));
  };

  return (
    <Modal isOpen={open} size={'lg'}>
      <div className="d-flex align-items-center justify-content-between p-3">
        <span className="ibf-link" onClick={toggle}>
          {'<'} Go back
        </span>
        <FontAwesomeIcon
          className={'pointer'}
          onClick={toggleAll}
          icon={faTimes}
          size={'2x'}
        />
      </div>

      <div className="d-flex flex-column px-3 pb-5">
        <div className="d-flex flex-column mb-4">
          <h4 className="font-weight-bold mb-2">Change your password</h4>

          <b>Important: </b>
          <span>
            First type your current password and choose a new one. Your password
            will be changed immediately.
          </span>
          <span>
            You will be logged out and send to the login screen to login with
            your new password.
          </span>
        </div>

        <div className="d-flex flex-column align-items-lg-start">
          <div className="d-flex flex-column w-lg-50">
            <div className="form-group input-register">
              <Input
                type="password"
                placeholder=" "
                autoComplete="off"
                id={'current_password'}
                onKeyDown={handleKeyDown}
                disabled={state === State.PENDING}
                {...register('current_password', { required: true })}
              />
              {errors?.current_password && validationInputMessage}
              <label htmlFor="current_password" className="register-label">
                Current Password <span className="text-danger"> * </span>{' '}
              </label>
            </div>

            <div className="form-group input-register">
              <Input
                type="password"
                placeholder=" "
                autoComplete="off"
                id={'new_password'}
                onKeyDown={handleKeyDown}
                disabled={state === State.PENDING}
                {...register('new_password', { required: true, minLength: 8 })}
              />
              <div className="d-flex flex-column">
                {errors?.new_password && validationInputMessage}
                {errors.new_password?.type === 'minLength' && (
                  <span className="ibf-font-12 color-red">
                    Your new password needs to be at least 8 characters to
                    continue
                  </span>
                )}
              </div>
              <label htmlFor="new_password" className="register-label">
                New Password (min 8 characters){' '}
                <span className="text-danger"> * </span>{' '}
              </label>
            </div>

            <div className="form-group input-register">
              <Input
                type="password"
                placeholder=" "
                autoComplete="off"
                disabled={state === State.PENDING}
                id={'repeat_password'}
                onKeyDown={handleKeyDown}
                {...register('repeat_password', { required: true })}
              />
              <div className="d-flex flex-column">
                {errors?.repeat_password &&
                  errors.repeat_password?.type !== 'match' &&
                  validationInputMessage}
                {errors.repeat_password?.type === 'match' && (
                  <span className="ibf-font-12 color-red">
                    {errors.repeat_password.message}
                  </span>
                )}
              </div>
              <label htmlFor="repeat_password" className="register-label">
                Repeat the new Password <span className="text-danger"> * </span>{' '}
              </label>
            </div>
          </div>
          <Button
            color={'primary'}
            type={'button'}
            onClick={() => handleSubmit(onSubmit)()}
            disabled={state === State.PENDING}>
            {state === State.PENDING && (
              <FontAwesomeIcon icon={faSpinner} pulse className="mr-2" />
            )}
            Save my password, log me out and show me to the login screen
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
