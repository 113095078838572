import React, { useCallback, useEffect, useRef, useState } from 'react';
import DashboardListComponent from './DashboardListComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getList } from '../../Utils/Utils';
import { actionsCustomer } from '../../Store/Customer/Slice';
import { authReducer } from '../../Store/Selectors';
/* import useOutsideAlerter from '../../Hooks/useOutsideAlerter'; */

type Props = {
  toggleDashboard: () => void;
};

export const guestSettings = ['Logout', 'Company Information'];

const DashboardComponent: React.FC<Props> = ({ toggleDashboard }) => {
  const { user } = useSelector(authReducer);
  const orders = getList('orders') || null;
  const { title, icon } = getList('settings');

  const settings = getList('settings').options.filter((item) =>
    user.metadata.guest ? guestSettings.includes(item.name) : item
  );

  const objSettings = { title, icon, options: settings };
  const claims = getList('claims');
  const help = getList('help');
  // const standingOrders = getList('StandingOrders');

  const wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const [hideStatement, setHideStatement] = useState(
    user?.metadata?.preferences?.hideStatements ?? false
  );

  useEffect(() => {
    setHideStatement(
      user.metadata?.preferences
        ? user.metadata.preferences.hideStatements
        : false
    );
  }, [user.metadata]);

  const onChangeVisiblePopover = useCallback(() => {
    setHideStatement(true);
    const preferences = {
      ...user.metadata.preferences,
      ...{ hideStatements: true }
    };
    dispatch(
      actionsCustomer.updateUserPreferences({
        customer: user.metadata.customer,
        preferences
      })
    );
  }, [dispatch, user]);

  const handleScroll = useCallback(() => {
    if (wrapperRef?.current?.scrollTop! > 1 && !hideStatement) {
      onChangeVisiblePopover();
    }
  }, [onChangeVisiblePopover, hideStatement]);

  /*  useOutsideAlerter(wrapperRef, toggleDashboard); */
  return (
    <div
      onScroll={handleScroll}
      className="container-fluid overflow-auto px-5 mt-3 align-items-start"
      ref={wrapperRef}>
      <div className="row justify-content-between w-100">
        <div className="d-flex flex-column">
          <h3 className="d-none d-lg-block">Account Dashboard </h3>
          <h3 className="d-lg-none">My account</h3>
          <span>
            Welcome{' '}
            {!user.metadata.guest
              ? user?.metadata?.main_user_email ?? user.email
              : user.metadata.customer_name}
          </span>
        </div>

        <div className="d-none d-lg-block ibf-link" onClick={toggleDashboard}>
          Close
        </div>
      </div>
      <div className="row flex-column flex-lg-row w-100">
        <div className="d-flex flex-column col-12 col-lg-6 pl-0">
          {!user.metadata.guest && (
            <DashboardListComponent
              optionClassName="dashboard-list-option pointer underline"
              list={orders}
              toggleMenu={toggleDashboard}
              visiblePopover={!hideStatement}
              setVisibleOnChange={onChangeVisiblePopover}
            />
          )}

          {/*<DashboardListComponent*/}
          {/*  optionClassName="dashboard-list-option pointer underline"*/}
          {/*  list={standingOrders}*/}
          {/*  toggleMenu={toggleDashboard}*/}
          {/*/>*/}

          <DashboardListComponent
            optionClassName="dashboard-list-option pointer underline"
            list={objSettings}
            toggleMenu={toggleDashboard}
          />
        </div>

        <div className="d-flex flex-column col-12 col-lg-6 pl-0 pl-lg-3">
          {!user.metadata.guest && (
            <DashboardListComponent
              optionClassName="dashboard-list-option pointer underline"
              list={claims}
            />
          )}

          <DashboardListComponent
            optionClassName="dashboard-list-option pointer underline"
            list={help}
          />
        </div>

        {/*<span
          onClick={() => redirect('FEEDBACK')}
          className="pointer border rounded border-dark px-4">
          Upcoming website updates and giving your feedback »
        </span>*/}
      </div>
    </div>
  );
};

export default DashboardComponent;
