import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../../../Models/Filter';
import { RootState } from '../../../../Store/Reducers';
import { actionsCustomBox } from '../../../../Store/CustomBox/Slice';
import ProductFilterItem from '../../../ProductWrapperComponent/ProductFilterComponent/ProductFilterItem';

type Props = {
  showMobile: boolean;
  toggle: () => void;
};

const BoxProductFilterList: React.FC<Props> = ({ showMobile, toggle }) => {
  const { filters, filter_conditions, currentPercentage, search } = useSelector(
    (state: RootState) => state.CustomBoxReducer
  );
  const { customer } = useSelector(
    (state: RootState) => state.AuthReducer.user.metadata
  );
  const { CUSTOM_BOX_MODAL } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const dispatchAction = useDispatch();
  const navHeight = 0;

  //media query js
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const resetFilters = () => {
    let baseFilter = { grower: filter_conditions.grower };
    dispatchAction(
      actionsCustomBox.searchInventory({
        params: {
          conditions: {
            custom: true,
            currentPercentage,
            filters: baseFilter
          },
          customer: customer,
          eta: CUSTOM_BOX_MODAL.data.eta_date,
          page: 1,
          pageSize: 120
        }
      })
    );
    dispatchAction(
      actionsCustomBox.setBoxFilters({
        conditions: { grower: filter_conditions.grower },
        search: ''
      })
    );
  };
  return (
    <div
      className={` ${
        showMobile ? 'ibf-filter-list-mobile-view ' : 'ibf-filter-desktop-view'
      } `}
      style={
        windowDimensions.width > 980
          ? {
              top: `${navHeight}px`
            }
          : {}
      }>
      <div
        className={`row ibf-filter-list-sticky ${
          showMobile ? 'mx-2 py-3' : 'd-none'
        }`}>
        <div className="col-auto ml-auto p-0">
          <span
            className="rounded-circle ibf-filter-close-icon"
            onClick={toggle}>
            X
          </span>
        </div>
      </div>

      <div
        className={
          showMobile
            ? 'col-10 m-0 pl-3 d-flex justify-content-between'
            : 'd-none d-lg-flex  justify-content-between'
        }>
        <div>
          <span className="color-gray">{filters.length > 0 && 'Filters'} </span>
        </div>
      </div>

      <div
        className={`d-flex justify-content-between ${
          showMobile ? 'w-80' : ''
        }`}>
        {/*<span className="ibf-filter-title ml-3 ml-lg-0">*/}
        {/*  {Object.values(filters).length > 0 && 'Filter By'}*/}
        {/*</span>*/}
        <span className="color-gray pointer" onClick={resetFilters}>
          <u>
            {Object.keys(filter_conditions).length > 1 || search
              ? 'Reset all filters'
              : ''}
          </u>
        </span>
      </div>
      {Object.values(filters).length > 0 &&
        Object.values(filters).map((item: Filter, index: number) => (
          <div
            key={'product_filter_' + index}
            className={
              showMobile
                ? 'col-10 position-relative mt-2'
                : 'd-none d-lg-flex mt-2'
            }>
            <ProductFilterItem
              listItemsFilter={item}
              isMobile={showMobile}
              isBox
            />
          </div>
        ))}
    </div>
  );
};

export default BoxProductFilterList;
