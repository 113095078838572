import React from 'react';
import ImageComponent from '../ImageComponent/ImageComponent';
import { Button, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import Environment from '../../../Environment';
import MixpanelService from '../../../Services/MixpanelService';
import { RootState } from '../../../Store/Reducers';
import Skeleton from 'react-loading-skeleton';
import { authReducer } from '../../../Store/Selectors';
import { Tier } from '../../../Models/CustomerModel';
import TierContactComponent from './TierContactComponent';

type Props = {
  className?: string;
  modal?: boolean;
};
const ContactComponent: React.FC<Props> = ({ className, modal }) => {
  const {
    manager,
    user: {
      metadata: { customer_tier }
    }
  } = useSelector(authReducer);
  const { authLoading } = useSelector(
    (store: RootState) => store.LoadingReducer
  );

  const isCarol =
    manager &&
    manager.name &&
    manager.name
      .split(' ')[0]
      .toLowerCase()
      .includes('carol');

  const contactOptionsPopover = (
    <div>
      <div className="mb-2">
        <span>
          <b>Get personal help</b> (Mon - Fri 9AM - 5PM{' '}
          <b>{isCarol ? 'EST' : 'CST'}</b>)
        </span>
      </div>
      <Button className="d-none  mb-2">
        {/*ibf-contact-options*/}
        <span>Chat</span>
      </Button>
      <a
        href={`tel:+1${manager?.phone}p${manager?.Extension}`}
        className="ibf-contact-options mb-2">
        <span>
          Call {manager && manager.phone} {manager?.Extension !== '' && 'ext '}{' '}
          {manager && manager.Extension}
        </span>
      </a>
      <a href={`mailto:${manager?.email}`} className="ibf-contact-options">
        {' '}
        E-mail: {manager && (manager?.email_alias ?? manager?.email)}
      </a>
    </div>
  );

  return customer_tier === Tier.TIER_3 ? (
    <TierContactComponent />
  ) : (
    <>
      <Popover
        onVisibleChange={(isOpen) => {
          if (isOpen) {
            if (window.dataLayer) {
              window.dataLayer.push({ event: 'Contacted sales', value: '' });
            }

            MixpanelService.track('help');
          }
        }}
        trigger="click"
        placement={'bottomRight'}
        overlayClassName={`ibf-contact-popover ${modal ? 'popover-modal' : ''}`}
        content={contactOptionsPopover}>
        <div className={`media pt-1 pointer ${className ? className : ''}`}>
          <div className="media-body flex-column d-flex align-self-center mr-2 ">
            <span className="ibf-contact-text d-none d-sm-inline">
              {authLoading ? (
                <Skeleton height={25} width={150} />
              ) : (
                <>
                  <u>
                    Help From{' '}
                    {manager && manager.name && manager.name.split(' ')[0]}
                  </u>
                  <FontAwesomeIcon className="mx-1" icon={faChevronDown} />
                </>
              )}
            </span>
            <span className="ibf-link ibf-contact-text d-sm-none d-inline">
              {authLoading ? <Skeleton height={25} width={100} /> : <>Help</>}
            </span>
          </div>
          {authLoading ? (
            <div className="ibf-contact">
              {' '}
              <Skeleton circle height={50} width={50} />{' '}
            </div>
          ) : (
            <ImageComponent
              imageUrl={`${Environment.publicPath}images/${manager &&
                manager.email &&
                manager.email.split('@')[0]}.png`}
              className="ibf-contact"
            />
          )}
        </div>
      </Popover>
    </>
  );
};

export default ContactComponent;
