import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  listOrders,
  listOrdersByPurchaseDate,
  listOrdersDetails
} from '../../Services/OrderService';
import { actionsOrder } from './Slice';
import {
  FETCH_ORDER_DETAIL,
  FETCH_ORDERS,
  FETCH_ORDERS_BY_PURCHASE_DATE
} from './Types';

export function* fetchOrders({ payload }: any): any {
  try {
    const res = yield call(listOrders, payload);
    if (res.success) {
      res.box.orders.sort((a: any, b: any) => {
        if (a[0][0].eta_date < b[0][0].eta_date) return 1;
        if (a[0][0].eta_date > b[0][0].eta_date) return -1;
        return 0;
      });
      res.box.growers = [];
      res.box.orders.forEach((source: any) => {
        source.forEach((item: any) => {
          if (!res.box.growers.includes(item[0].grower_obj.company_name)) {
            res.box.growers.push(item[0].grower_obj.company_name);
          }
        });
      });
      res.box.growers.sort();
      yield put(actionsOrder.saveListOrders(res.box));
    } else {
      yield put(actionsOrder.setRejected());
      console.error(res);
      console.error('error');
    }
  } catch (e) {
    yield put(actionsOrder.setRejected());
    console.error(e);
    console.error('error');
  }
}

export function* fetchOrdersByPurchaseDate({ payload }: any): any {
  try {
    const res = yield call(listOrdersByPurchaseDate, payload);
    if (res.success) {
      res.box.orders.sort((a: any, b: any) => {
        if (a.po < b.po) return 1;
        if (a.po > b.po) return -1;
        return 0;
      });
      yield put(actionsOrder.saveListOrdersByPurchaseDate(res.box));
    } else {
      console.error(res);
      console.error('error');
    }
  } catch (err) {
    console.error(err);
  }
}

export function* fetchOrderDetail({ payload }: any): any {
  try {
    const res = yield call(listOrdersDetails, payload.key);
    if (res.success) {
      yield put(actionsOrder.saveOrderDetail(res.box));
    } else {
      console.error(res);
      console.error('error');
    }
  } catch (err) {
    console.error(err);
  }
}

export default function* AllSagas() {
  yield all([
    takeEvery(FETCH_ORDERS, fetchOrders),
    takeEvery(FETCH_ORDERS_BY_PURCHASE_DATE, fetchOrdersByPurchaseDate),
    takeEvery(FETCH_ORDER_DETAIL, fetchOrderDetail)
  ]);
}
