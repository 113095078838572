import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

type Props = {
  placeholderSearchName: string;
  handleChange: (event: any) => void;
  search: string;
  isBox?: boolean;
};

const SearchFilter: React.FC<Props> = ({
  placeholderSearchName,
  handleChange,
  search,
  isBox
}) => {
  return (
    <div
      className={`input-group p-1 ${
        isBox ? 'border-bottom bg-white' : 'mb-3 ibf-input-search'
      }`}>
      <div className="input-group-prepend border-0">
        <span className="input-group-text px-1 border-0" id="basic-addon1">
          <FontAwesomeIcon icon={faSearch} />
        </span>
      </div>
      <input
        type="text"
        className="form-control border-0"
        onChange={handleChange}
        value={search}
        placeholder={placeholderSearchName}
      />
    </div>
  );
};

export default SearchFilter;
