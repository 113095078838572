import React, { useEffect } from 'react';
import { eventReducer } from '../../Store/Selectors';
import { useSelector } from 'react-redux';
import { uuidV4 } from '../../Utils/Utils';
import ReactExport from 'react-data-export';
import {
  formatValueExcel,
  columnName,
  findEventName,
  showTotalPrice,
  totalPriceDataSet
} from '../../Utils/Utils';
import { OrderItemCsv } from '../../Models/Order';
import { roundUp } from '../../Utils/Utils';
import { authReducer } from '../../Store/Selectors';
/* import {Grower} from '../../Models/Grower' */

type Props = {
  data: Array<OrderItemCsv>;
};
let btn = (
  <button className="btn btn-purple" type="button">
    Download Excel File
  </button>
);
const columnNameAlt = { ...columnName };
columnNameAlt.charge_date = 'Charge date';
const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet = ExcelFile.ExcelSheet;
const ExcelColumn = ExcelFile.ExcelColumn;

const SubHeaderEventByName = (props: Props) => {
  let dataset: Array<OrderItemCsv> = [];

  const { user } = useSelector(authReducer);
  const events = useSelector(eventReducer);

  useEffect(() => {
    if (props.data[0] && events.filteredSearchEvent === 'eventName') {
      props.data.forEach((item: any) => {
        item.forEach((subitem: any) => {
          subitem.forEach((element: OrderItemCsv) => {
            let { objectElement } = formatValueExcel(
              element,
              element.charge_date
            );

            dataset.push(objectElement);
          });
        });
      });

      if (user.metadata.is_available_event_name_view) {
        let totals = showTotalPrice(dataset);
        let dataRow: OrderItemCsv = {};
        dataset.push(dataRow);
        let firstRow: boolean = false;

        for (let [key, value] of Object.entries(totals)) {
          let row: OrderItemCsv = {
            event_name: key,
            total_price: roundUp(value)
          };
          if (!firstRow) {
            row.grower = 'Total for:';
            firstRow = true;
          }
          dataset.push(row);
        }
      } else {
        let totalPrices = totalPriceDataSet(dataset);
        let dataRow: OrderItemCsv = {};
        dataset.push(dataRow);
        let firstRow: boolean = false;

        let row: OrderItemCsv = {
          total_price: roundUp(totalPrices)
        };
        if (!firstRow) {
          row.grower = 'Total for:';
          firstRow = true;
        }

        dataset.push(row);
      }
    }
  }, [
    props.data,
    dataset,
    user.metadata.is_available_event_name_view,
    events.filteredSearchEvent
  ]);

  return (
    <>
      {events.filteredSearchEvent === 'eventName' && events.eventName !== '' && (
        <thead className="rounded overflow-hidden">
          <tr
            className=" border-top-0 overflow-hidden"
            style={{
              background: findEventName(
                events.eventList,
                events.tableHeaderColor,
                events.filteredSearchEvent
              )?.color
            }}>
            <th colSpan={7} className="align-top">
              <span className=" ibf-font-17">
                <b>
                  Related to
                  <span className="text-capitalize">
                    <b>
                      {` "${
                        findEventName(
                          events.eventList,
                          events.tableHeaderColor,
                          events.filteredSearchEvent
                        )?.tag_name
                      }"`}
                    </b>
                  </span>
                </b>
              </span>
            </th>
            <th className="text-right py-1" colSpan={8}>
              <ExcelFile element={btn}>
                <ExcelSheet data={dataset} name="Orders" filename="orders">
                  {Object.keys(columnNameAlt)
                    .filter((item) =>
                      user.metadata.is_available_event_name_view
                        ? item
                        : item !== 'event_name'
                    )
                    .map((name: string) => {
                      return (
                        <ExcelColumn
                          label={columnNameAlt[name]}
                          value={name}
                          key={uuidV4()}
                        />
                      );
                    })}
                </ExcelSheet>
              </ExcelFile>
            </th>
          </tr>
        </thead>
      )}
    </>
  );
};

export default SubHeaderEventByName;
