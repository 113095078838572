import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../Models/State';
import { HelperState } from './Types';
import { actionsCustomBox } from '../CustomBox/Slice';
import { actionsCheckout } from '../Checkout/Slice';

const { IDLE, PENDING, RESOLVED, REJECTED } = State;

const initialState: HelperState = {
  state: IDLE,
  inventoryKey: '',
  checkInventory: false
};

const slice = createSlice({
  name: 'helper',
  initialState,
  reducers: {
    getInventoryAvailability(
      state,
      { payload }: PayloadAction<{ inventoryKey: string }>
    ) {
      state.state = PENDING;
    },
    setClearInventory(state) {
      state.inventoryKey = '';
      state.checkInventory = false;
      state.state = RESOLVED;
    },
    setResolved(state) {
      state.state = RESOLVED;
    },
    setRejected(state) {
      state.state = REJECTED;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(actionsCustomBox.addToCart, (state, { payload }) => {
      state.checkInventory = false;
      state.inventoryKey = payload.invKey;
      return state;
    });
    builder.addCase(actionsCustomBox.addToBox, (state, { payload }) => {
      state.checkInventory = false;
      state.inventoryKey = payload.boxParams.invKey;
      return state;
    });
    builder.addCase(actionsCheckout.removeFromBox, (state, { payload }) => {
      // state.inventoryKey = payload..invKey;
      // state.inventoryKey = payload.
      state.checkInventory = false;
      if (payload.invKey) {
        state.inventoryKey = payload.invKey;
      }
      return state;
    });
    builder.addCase(
      actionsCheckout.removeFromCustomBox,
      (state, { payload }) => {
        // state.inventoryKey = payload..invKey;
        if (payload.item.inventory._KEY) {
          state.checkInventory = false;
          state.inventoryKey = payload.item.inventory._KEY;
        }
        return state;
      }
    );
    builder.addCase(actionsCheckout.setCheckoutInfo, (state, { payload }) => {
      if (payload.success && state.inventoryKey) {
        state.checkInventory = true;
      }
      return state;
    });
    builder.addCase(actionsCustomBox.setSearchResults, (state, { payload }) => {
      if (payload.results.productsStillFitInBox && state.inventoryKey) {
        state.checkInventory = true;
      }
      return state;
    });
  }
});

export const helperActions = slice.actions;
export default slice.reducer;
