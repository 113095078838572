import React, { useEffect, useState } from 'react';
import Environment from '../../../Environment';
import { State } from '../../../Models/State';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import CheckoutEventSelect from '../CheckoutEventSelect';
import { Cartbox } from '../../../Models/Cartbox';
import { actionsCustomBox } from '../../../Store/CustomBox/Slice';
import MixpanelService from '../../../Services/MixpanelService';
import { useDispatch, useSelector } from 'react-redux';
import { removeBox } from '../../../Utils/Utils';
import {
  authReducer,
  customBoxReducer,
  eventReducer,
  modalReducer
} from '../../../Store/Selectors';

type Props = {
  product: Cartbox;
  setDeleteLoading?: (state: State) => void;
  fromBox: boolean;
  disabled?: boolean;
};

const CheckoutBoxHeader = ({
  product,
  setDeleteLoading,
  fromBox,
  disabled
}: Props) => {
  const { CART_MODAL } = useSelector(modalReducer);
  const {
    user: {
      metadata: { is_available_event_name_view }
    }
  } = useSelector(authReducer);
  const dispatch = useDispatch();
  const {
    user: {
      metadata: { customer }
    }
  } = useSelector(authReducer);
  const { state: customBoxState } = useSelector(customBoxReducer);
  const { eventList } = useSelector(eventReducer);
  const [editLoading, setEditLoading] = useState(State.IDLE);

  useEffect(() => {
    if (customBoxState !== State.PENDING) {
      setEditLoading(State.RESOLVED);
    }
  }, [customBoxState]);

  const editBox = (cartbox: Cartbox) => {
    if (editLoading !== State.PENDING || customBoxState !== State.PENDING) {
      setEditLoading(State.PENDING);
      dispatch(actionsCustomBox.openBox({ cartbox, customer, fromEdit: true }));
    }
  };

  const deleteBox = async (box: Cartbox) => {
    setDeleteLoading!(State.PENDING);
    MixpanelService.track('cart_delete_buying_page');
    removeBox(box, dispatch, customer, fromBox);
  };

  return (
    <div
      className={`${
        is_available_event_name_view ? 'ibf-cart-box-header' : ''
      } px-lg-2 mb-3 ${
        fromBox ? 'align-items-lg-start' : 'align-items-lg-center'
      }`}>
      <div
        className={`d-flex flex-row mb-1 mb-lg-0 pr-2 ${
          fromBox ? 'mt-lg-1' : ''
        } ${is_available_event_name_view ? 'flex-lg-column' : ''} `}>
        <span
          className={`font-weight-bold mr-1 ${
            is_available_event_name_view ? 'mr-lg-0' : ''
          }`}>
          {fromBox ? 'Custom box' : 'Box'} from {product?.grower?.company_name}(
          {Environment.country_flag_map[product.grower.country]}) filled{' '}
          {Math.ceil(product.current_percentage)}%
        </span>
        {fromBox && !CART_MODAL.open && (
          <div className="d-flex">
            <span
              className="underline pointer text-primary mr-2"
              onClick={() => deleteBox(product)}>
              Delete box
            </span>

            {(!product.tag || product.tag !== 'shopping_list_v2') && (
              <span
                className={`underline pointer mr-2 ${
                  editLoading === State.PENDING
                    ? 'ibf-text-gray'
                    : 'text-primary'
                }`}
                onClick={() => editBox(product)}>
                {editLoading === State.PENDING && (
                  <FontAwesomeIcon icon={faSpinner} pulse />
                )}
                Edit box
              </span>
            )}
          </div>
        )}
      </div>

      {eventList.length > 1 && is_available_event_name_view && (
        <CheckoutEventSelect
          // key={product._KEY}
          cartbox={product}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default CheckoutBoxHeader;
