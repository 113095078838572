import React from 'react';

type Props = {
  height?: string;
  width?: string;
  message?: string;
  classSpinner?: string;
  center?: boolean;
};

const LoadingSpinner: React.FC<Props> = ({
  height,
  width,
  message,
  classSpinner,
  center = true
}) => {
  return (
    <div
      style={{ height: height, width: width }}
      className={`d-flex ${
        center ? 'justify-content-center align-items-center' : ''
      } `}>
      <div className={`spinner-border ${classSpinner ?? ''}`} role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="ml-3 mt-1 text-uppercase font-weight-bold">
        <span> {message} ...</span>
      </div>
    </div>
  );
};

export default LoadingSpinner;
