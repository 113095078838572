import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import MixpanelService from '../../Services/MixpanelService';
import { RootState } from '../../Store/Reducers';
import { actionsCustomBox } from '../../Store/CustomBox/Slice';
import { searchInventory } from '../../Utils/Utils';

const SearchInputBox = () => {
  const dispatch = useDispatch();
  const { customer } = useSelector(
    (state: RootState) => state.AuthReducer.user.metadata
  );
  const {
    filter_conditions,
    currentPercentage,
    search: searchFromState
  } = useSelector((state: RootState) => state.CustomBoxReducer);
  const { CUSTOM_BOX_MODAL } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (searchFromState === null) {
      setSearch('');
      dispatch(
        actionsCustomBox.setBoxFilters({
          conditions: { ...filter_conditions },
          search: ''
        })
      );
    }

    if (searchFromState === '') {
      setSearch('');
    }
  }, [searchFromState, dispatch, filter_conditions]);

  const changeText = (text: string) => {
    setSearch(text);
  };

  const searchProduct = () => {
    const filters = {
      // ...filter_conditions,
      grower: [CUSTOM_BOX_MODAL.data.grower.key]
    };
    MixpanelService.track('search', { search });
    searchInventory(
      dispatch,
      customer,
      search,
      currentPercentage,
      CUSTOM_BOX_MODAL.data.eta_date,
      filters
    );

    dispatch(actionsCustomBox.setBoxFilters({ conditions: filters, search }));
  };

  return (
    <div className="d-flex w-100">
      <div className="input-group mr-lg-3">
        <div className="input-group-prepend">
          <span
            className="input-group-text pointer"
            id="basic-addon1"
            onClick={searchProduct}>
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Search available flowers"
          aria-label="Username"
          aria-describedby="basic-addon1"
          value={search}
          onChange={(e) => changeText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              searchProduct();
            }
          }}
        />
      </div>
    </div>
  );
};
export default SearchInputBox;
