import { all, call, takeEvery, put } from 'redux-saga/effects';
import { getStatements } from '../../Services/StatementsService';
import { GET_STATEMENTS } from './Types';
import { actionsStatements } from '../Statements/Slice';
import { toast } from '../../Utils/Utils';
export function* getStatementsSaga({
  payload
}: ReturnType<typeof actionsStatements.getStatements>): any {
  try {
    const res = yield call(getStatements, payload);
    if (res.success && res.data) {
      yield put(actionsStatements.setStatements(res.data.box.orders));
    } else {
      toast('No available statements were found', 'error');
      yield put(actionsStatements.setRejected());
    }
  } catch (err) {
    yield put(actionsStatements.setRejected());
  }
}

export default function* AllSagas() {
  yield all([takeEvery(GET_STATEMENTS, getStatementsSaga)]);
}
