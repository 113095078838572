import { FilterState } from './Type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../Models/State';
import { actionsProduct } from '../Product/Slice';

const { IDLE, RESOLVED } = State;
const initialState: FilterState = {
  conditions: [],
  filters_conditions: {},
  selectedGrower: { name: '', key: '' },
  resetFilter: false,
  search: '',
  substitute: '',
  checkProductDetail: false,
  availableDates: [],
  availableGrowers: [],
  availability: true,
  state: IDLE
};

const slice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilters(state: FilterState, { payload }) {
      state.state = RESOLVED;
      state.filters_conditions = payload;
    },
    setSearch(
      state: FilterState,
      { payload }: PayloadAction<{ search: string; substitute?: string }>
    ) {
      state.state = RESOLVED;
      state.search = payload.search;
      state.substitute = payload.substitute || '';
    },
    setResetFilters(state: FilterState, { payload }: PayloadAction<boolean>) {
      state.state = RESOLVED;
      state.resetFilter = payload;
      state.search = '';
      state.filters_conditions = {};
      state.selectedGrower = { name: '', key: '' };
    },
    setSelectedGrower(
      state: FilterState,
      { payload }: PayloadAction<{ name: string; key: string }>
    ) {
      state.state = RESOLVED;
      state.selectedGrower = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(actionsProduct.setProductDate, (state, { payload }) => {
      state.filters_conditions = { ...state.filters_conditions };
      return state;
    });
    builder.addCase(actionsProduct.setProducts, (state, { payload }) => {
      state.availableDates = payload.results.availableDates ?? [];
      state.availableGrowers = payload.results.availableGrowers ?? [];
      return state;
    });
  }
});
export const actionsFilter = slice.actions;
export default slice.reducer;
//Extra reducer SET_PRODUCT_DATE, SET_PRODUCT:
