import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CheckoutProducts from './CheckoutProducts';
import { OrderRequest } from '../../../Models/OrderRequest';
import { OrderRequestItem } from '../../../Models/OrderRequestItem';
import CheckoutCustomBox from './CheckoutCustomBox';
import { RootState } from '../../../Store/Reducers';
import { Cartbox } from '../../../Models/Cartbox';
import { IncompleteProduct } from '../../../Models/Product';

type Props = {
  count: number;
  toggle: () => void;
};

const CheckoutCartProducts: React.FC<Props> = ({ count, toggle }) => {
  const { products, itemsInCart } = useSelector(
    (state: RootState) => state.CheckoutReducer
  );

  const { orderRequests, incompleteProducts } = useSelector(
    (state: RootState) => state.CheckoutReducer
  );

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (itemsInCart) {
      setIsVisible(true);
    }
  }, [itemsInCart]);
  return (
    <>
      {isVisible && (
        <>
          {count === 0 && !!products.length && (
            <div className="mb-3 px-4 border-bottom">
              <span className="ibf-text-gray-cart">
                You can add new items to your cart if you want.
              </span>
            </div>
          )}
          {(products?.length > 0 ||
            orderRequests?.length > 0 ||
            incompleteProducts?.length > 0) && (
            <div className="px-3 py-2">
              <div className="px-2 pb-2">
                <span className="ibf-text-gray-cart">
                  {itemsInCart} {itemsInCart === 1 ? ' item ' : ' items '}{' '}
                  already in your checkout:
                </span>
              </div>

              {products.map((prod: Cartbox, indexProduct: number) => {
                if (prod.custom) {
                  return (
                    <CheckoutCustomBox
                      product={prod}
                      indexProduct={indexProduct}
                      key={
                        prod._KEY +
                        '_checkout_product_' +
                        prod._META.updated_time
                          ? new Date(prod._META.updated_time).toISOString()
                          : new Date().toISOString() +
                            '_' +
                            (prod.event?.color ?? '')
                      }
                    />
                  );
                } else {
                  return (
                    <CheckoutProducts
                      key={
                        prod._KEY +
                        '_checkout_product_' +
                        (prod?._META?.updated_time
                          ? new Date(prod._META.updated_time).toISOString()
                          : new Date().toISOString()) +
                        '_' +
                        indexProduct +
                        '_' +
                        (prod.event?.color ?? '')
                      }
                      eta_date={prod.eta_date}
                      custom={false}
                      toggle={toggle}
                      product={prod}
                    />
                  );
                }
              })}

              {orderRequests &&
                orderRequests.map((orderRequest: OrderRequest) => {
                  return orderRequest.items.map((ori: OrderRequestItem) => (
                    <CheckoutProducts
                      key={'checkout_OR_item_' + ori._KEY}
                      eta_date={orderRequest.delivery_date}
                      custom={false}
                      product={ori}
                      orderRequest
                    />
                  ));
                })}
              {incompleteProducts &&
                incompleteProducts.map(
                  (incompleteProduct: IncompleteProduct) => {
                    return (
                      <CheckoutProducts
                        key={
                          'checkout_incomplete_product_item_' +
                          incompleteProduct.variety
                        }
                        eta_date={incompleteProduct.deliveryDate}
                        custom={false}
                        product={incompleteProduct}
                        incomplete
                      />
                    );
                  }
                )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CheckoutCartProducts;
