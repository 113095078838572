import React from 'react';
import { Modal } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Environment from '../../../../Environment';
import VideoComponent from '../../../HomeComponent/HomeInformationComponent/VideoComponent/VideoComponent';
import { RootState } from '../../../../Store/Reducers';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { actionsDisplay } from '../../../../Store/Display/Slice';
import { HOW_TO_USE_MODAL } from '../../../../Store/Modal/Types';

const HowToUseModal = () => {
  const dispatch = useDispatch();
  const { howToUseModal } = useSelector(
    (state: RootState) => state.ModalReducer
  );

  const toggle = () => {
    dispatch(actionsModal.setCloseModal({ modal: HOW_TO_USE_MODAL }));
  };

  const continueTipFlow = () => {
    dispatch(actionsDisplay.setOpenSecondTip(true));
    toggle();
  };

  return (
    <Modal size="lg" toggle={toggle} isOpen={howToUseModal.open}>
      <div className="ibf-htu-container">
        <VideoComponent
          title="home-video"
          width="100%"
          height="100%"
          className="ibf-htu-video"
          src={Environment.youtube.home}
        />

        <div className="ibf-htu-instructions">
          <span>
            Done? Click{' '}
            <u className="pointer" onClick={continueTipFlow}>
              Continue &#187;
            </u>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default HowToUseModal;
