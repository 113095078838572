import { ShoppingList } from '../../Models/ShoppingList';
import { State } from '../../Models/State';

export const REMOVE_SHOPPING_LIST_ITEM = 'shoppingList/removeShoppingListItem';
export const REMOVE_ITEM_FROM_SL = 'shoppingList/removeItemFromSl';
export const CREATE_SHOPPING_LIST = 'shoppingList/createShoppingList';
export const CHECK_LAST_SL_ITEM = 'shoppingList/checkLastSLItem';
export const CHECK_LAST_SL_ITEM_BY_BOX = 'shoppingList/checkLastSLItemByBox';
export const REMOVE_ORI_ITEM = 'shoppingList/removeORItem';
export const REMOVE_INCOMPLETE_ITEM = 'shoppingList/removeIncompleteItem';
export const GET_SHOPPING_LIST_PROCESSED =
  'shoppingList/getShoppingListProcessed';

export interface ShoppingListState {
  shoppingLists: ShoppingList[];
  shoppingListCheckout: ShoppingList[];
  shoppingListsProcessed: ShoppingList[];
  state: State;
}
