import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OrdersTableComponent from '../OrdersTableComponent/OrdersTableComponent';
import FiltersTableComponent from '../FiltersTableComponent/FiltersTableComponent';
import { convertDateToNumberDate, uuidV4 } from '../../Utils/Utils';
import MixpanelService from '../../Services/MixpanelService';
import DisplayRouteComponent from '../Shared/DisplayRouteComponent';
import { RootState } from '../../Store/Reducers';
import { actionsOrder } from '../../Store/Order/Slice';
import LoadingSpinner from '../Shared/LoadingSpinner';
import {
  SearchParams,
  TypeTable
} from '../OrdersByEtaComponent/OrdersByEtaComponent';
import { OrderPurchase } from '../../Store/Order/Types';
import { orderReducer } from '../../Store/Selectors';

const OrdersByPurchaseComponent = (props: any) => {
  const dispatch = useDispatch();
  const [filtersEnabled, setFiltersEnabled] = useState(false);
  const [filterParams, setFilterParams] = useState<SearchParams>({});
  const orders = useSelector(orderReducer);
  const { customer } = useSelector(
    (state: RootState) => state.AuthReducer.user.metadata
  );

  useEffect(() => {
    MixpanelService.track('orderHistory', {});
    dispatch(actionsOrder.fetchOrdersByPurchaseDate(customer));
  }, [customer, dispatch]);

  let table;
  let filters;
  let spinner;

  const goToOrderDetail = (order_key: string) => {
    props.history.push('/order_detail/' + order_key);
  };

  const searchHandler = (searchParams: SearchParams) => {
    setFiltersEnabled(true);
    setFilterParams(searchParams);
  };
  const resetFiltersHandler = () => {
    setFiltersEnabled(false);
    setFilterParams({});
  };

  const filterTable = useCallback(
    (filterParams: SearchParams) => {
      return (orders.orders as OrderPurchase[]).filter((order) => {
        if (
          filterParams.poNumber &&
          order.po.toString().includes(filterParams.poNumber)
        ) {
          return true;
        }

        return !!(
          filterParams.selectedDay &&
          convertDateToNumberDate(filterParams.selectedDay) ===
            convertDateToNumberDate(order.date)
        );
      });
    },
    [orders.orders]
  );

  const getDaysAvailable = (orders: any) => {
    return orders.map((item: any) => {
      return new Date(item.date);
    });
  };

  if (orders.ordersByPurchaseLoaded) {
    let dataSource;

    if (filtersEnabled) {
      dataSource = filterTable(filterParams);
    } else {
      dataSource = orders.orders;
    }

    table = (
      <OrdersTableComponent
        key={uuidV4()}
        typeTable={TypeTable.PURCHASE_DATE}
        data={dataSource}
        headers={orders.tableHeaders}
        goToOrderDetail={goToOrderDetail}
      />
    );

    filters = (
      <FiltersTableComponent
        type={'PURCHASE_DATE'}
        searchHandler={searchHandler}
        resetFilterHandler={resetFiltersHandler}
        daysAvailable={getDaysAvailable(orders.orders)}
      />
    );
  } else {
    spinner = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '150px'
        }}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="ibf-orders-container">
      <DisplayRouteComponent currentRouteName={'My orders'} />
      {filters}

      {table}

      {spinner}
    </div>
  );
};

export default OrdersByPurchaseComponent;
