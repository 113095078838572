import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { toast } from '../../Utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadReferralServices,
  sendReferralService
} from '../../Services/UserService';
import { RootState } from '../../Store/Reducers';
import { actionsModal } from '../../Store/Modal/Slice';
import { actionsReferral } from '../../Store/Referral/Slice';
import { REFERRAL_MODAL } from '../../Store/Modal/Types';
import Environment from '../../Environment';
import { SubmitHandler, useForm } from 'react-hook-form';
import { State } from '../../Models/State';

type FormValues = {
  email: string;
  confirm_email: string;
  company_name: string;
  name: string;
};

type Props = {
  classNameInputs?: string;
  optionClassName?: string;
};
const validationMessage = (
  <span className="text-danger ibf-font-12">Please fill in this info</span>
);

const ReferralComponent: React.FC<Props> = ({ classNameInputs }) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      confirm_email: '',
      company_name: '',
      name: ''
    }
  });

  const { user } = useSelector((store: RootState) => store.AuthReducer);
  const { amount } = useSelector((store: RootState) => store.ReferralReducer);
  const dispatch = useDispatch();
  const openReferrals = () =>
    dispatch(actionsModal.setOpenModal({ modal: REFERRAL_MODAL }));
  const customer = user && user.metadata && user.metadata.customer;
  const [loading, setLoading] = useState<string>(State.IDLE);

  const getReferrals = useCallback(
    async (customer: string) => {
      const res = await loadReferralServices(customer);
      if (res && res.success) {
        dispatch(
          actionsReferral.loadReferrals({
            referrals: res.results,
            amount: res.amountDiscount
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    customer && getReferrals(customer);
  }, [dispatch, getReferrals, customer]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(State.PENDING);

    let email = data.email.toLocaleLowerCase().trim();
    let confirm_email = data.confirm_email.toLowerCase().trim();
    if (email !== confirm_email) {
      setError('confirm_email', {
        type: 'manual',
        message: "Emails doesn't match."
      });
      setLoading(State.REJECTED);
      return;
    } else {
      clearErrors('confirm_email');
      const res = await sendReferralService(
        user.key,
        data.name,
        data.email,
        data.company_name
      );
      if (res && res.success) {
        setLoading(State.RESOLVED);
        getReferrals(customer);
        toast('Referral Sent');
        reset({
          email: '',
          confirm_email: '',
          company_name: '',
          name: ''
        });
      } else {
        toast(res.error, 'error');
        setLoading(State.REJECTED);
      }
    }
  };

  return (
    <div className="ibf-referral-wrapper mt-5 mt-lg-0 pl-4 pl-sm-5">
      <ul className="list-group list-group-flush">
        <li className="list-group-item dashboard-list-title px-0">
          <FontAwesomeIcon className="mr-2" icon={faShareAlt} />
          Referral Program
        </li>

        <li className="list-group-item px-0">
          <div className="d-flex justify-content-between">
            <span className="dashboard-list-option">
              Sharing is caring! Share your love for flowers with your friends
              and invite them to try iBuyFlowers!
            </span>
          </div>
        </li>

        <li className="list-group-item px-0">
          <div className="d-flex justify-content-between">
            <span
              className="dashboard-balance-referral pointer underline"
              onClick={openReferrals}>
              Your Reward Balance: ${amount}
            </span>
          </div>
        </li>

        <li className="list-group-item px-0">
          <div className="d-flex justify-content-between">
            <span className="dashboard-list-option">
              For every new friend that uses your referral coupon, you get{' '}
              <b className="mr-1">$15 off your next order</b>
              and your friend gets a <b>
                15% discount on their first order!
              </b>{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="ibf-link ibf-text-pink"
                href={Environment.urlTermsReferral}>
                Terms and conditions apply.
              </a>
            </span>
          </div>
        </li>
      </ul>

      <div className="">
        <div className={classNameInputs}>
          <div className="input-register-dashboard">
            <input
              className="form-control "
              {...register('email', {
                required: true
              })}
              id="email"
              type="email"
            />

            <label htmlFor="email" className="register-label ibf-text-pink">
              Company email address to send your coupon to
            </label>
            <div className="d-flex flex-column">
              {errors.email?.type === 'required' && validationMessage}
            </div>
          </div>
        </div>
        <div className={classNameInputs}>
          <div className="input-register-dashboard">
            <input
              className="form-control "
              type="email"
              {...register('confirm_email', {
                required: true
              })}
              id="confirm_email"
            />

            <label
              htmlFor="confirm_email"
              className="register-label ibf-text-pink">
              Confirm email
            </label>
            <div className="d-flex flex-column">
              {errors.confirm_email?.type === 'required' && validationMessage}

              {errors.confirm_email?.type === 'manual' && (
                <span className="text-danger">
                  {errors.confirm_email.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={classNameInputs}>
          <div className="input-register-dashboard">
            <input
              type="text"
              className="form-control "
              {...register('name', { required: true })}
            />

            <label
              htmlFor="Company email address to send your coupon to"
              className="register-label ibf-text-pink">
              First Name of the receiver
            </label>
            <div className="d-flex flex-column">
              {errors.name && validationMessage}
            </div>
          </div>
        </div>
        <div className={classNameInputs}>
          <div className="input-register-dashboard">
            <input
              type="text"
              className="form-control "
              id="company_name"
              {...register('company_name', { required: true })}
            />

            <label
              htmlFor="company_name"
              className="register-label ibf-text-pink">
              Company Name
            </label>
            <div className="d-flex flex-column">
              {errors.company_name && validationMessage}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-end justify-content-start my-3">
          <button
            className="btn btn-sm dashboard-button"
            type={'button'}
            onClick={() => handleSubmit(onSubmit)()}
            disabled={loading === 'PENDING'}>
            {loading === 'PENDING' ? (
              <FontAwesomeIcon icon={faSpinner} pulse />
            ) : (
              <>
                <FontAwesomeIcon className="mr-2" icon={faShareAlt} />
                Send Referral
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReferralComponent;
