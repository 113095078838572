import React, { Fragment } from 'react';
import WeekItem from './WeekItem';
import Product from '../../../../../Models/Product';
import { useSelector } from 'react-redux';
import {
  convertNumberDateToDate,
  getWeekNumber,
  months
} from '../../../../../Utils/Utils';
import { RootState } from '../../../../../Store/Reducers';
import { isDate } from 'date-fns';

type Props = {
  list: Array<{ start: number; end: number }[]>;
  weeksNextYear: Array<{ start: number; end: number }[]>;
  product: Product;
};
const colorGreen = '#81BF81';
const colorRed = '#ffa2a2';

const WeekList: React.FC<Props> = ({ list, product, weeksNextYear }) => {
  const { date } = useSelector((state: RootState) => state.ProductReducer);

  const currentDate = (date > 0 && convertNumberDateToDate(date)) ?? null;
  const numberWeek = getWeekNumber(date);

  const getWeekColor = (cont: number) => {
    let color =
      product.weeks?.substr(cont - 1, 1) === '1'
        ? `${colorGreen}`
        : `${colorRed}`;

    if (cont === 52) {
      switch (true) {
        case product.weeks?.substr(cont - 1, 1) === `1` &&
          product.weeks?.substr(cont, 1) === `1`:
          color = `${colorGreen}`;
          break;
        case product.weeks?.substr(cont - 1, 1) === `0` &&
          product.weeks?.substr(cont, 1) === `0`:
          color = `${colorRed}`;
          break;
        case product.weeks?.substr(cont - 1, 1) === `1` &&
          product.weeks?.substr(cont, 1) === `0`:
          color = `linear-gradient(125deg, ${colorGreen} 50%, ${colorRed} 50%)`;
          break;
        case product.weeks?.substr(cont - 1, 1) === `0` &&
          product.weeks?.substr(cont, 1) === `1`:
          color = `linear-gradient(125deg, ${colorRed} 50%, ${colorGreen} 50%)`;
          break;
        default:
          // color = "${colorGreen}";
          break;
      }
    }

    return color;
  };

  const renderCurrentYearWeeks = () => {
    let cont = 0;
    let green = 0;
    return list.map((i: { start: number; end: number }[], month) => {
      return i.map((a, index) => {
        cont++;
        if (cont >= 53) {
          return null;
        }
        const label = `<span>${
          index === 0
            ? months[month].substr(0, 3)
            : cont === 52
            ? `${cont.toString()}/${(cont + 1).toString()}`
            : cont.toString()
        }</span>`;
        if (cont >= numberWeek) {
          if (
            getWeekColor(cont) === colorGreen &&
            currentDate &&
            month >= currentDate.getMonth()
          ) {
            green++;
          }
        }
        if (currentDate && month >= currentDate.getMonth()) {
          return (
            <Fragment key={'week_item' + index}>
              <WeekItem
                index={label}
                isSelected={
                  (getWeekColor(cont) === colorGreen && cont === numberWeek) ||
                  green === 1
                }
                bg={getWeekColor(cont)}
              />
            </Fragment>
          );
        }

        return null;
      });
    });
  };

  const renderNextYearWeeks = () => {
    let cont = 0;
    return weeksNextYear.map((i: { start: number; end: number }[], month) => {
      return i.map((a, index) => {
        cont++;
        if (cont >= numberWeek) {
          return null;
        }
        const label = `<span>${
          index === 0
            ? months[month].substr(0, 3)
            : cont === 52
            ? `${cont.toString()}/${(cont + 1).toString()}`
            : cont.toString()
        }</span>`;

        return (
          <Fragment key={'next_year_week_item' + index}>
            <WeekItem
              index={label}
              isSelected={cont === numberWeek}
              bg={getWeekColor(cont)}
            />
          </Fragment>
        );
      });
    });
  };

  return (
    <div className="d-flex flex-column">
      {currentDate && isDate(currentDate) && (
        <>
          <h6>{currentDate.getFullYear()}</h6>
          <div className="week-list">{renderCurrentYearWeeks()}</div>

          <h6>{currentDate.getFullYear() + 1}</h6>

          <div className="week-list">{renderNextYearWeeks()}</div>
        </>
      )}
    </div>
  );
};

export default WeekList;
