import React, { Fragment, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import FlowerListOptionsComponent from './FlowerListOptionsComponent';
import { useHistory } from 'react-router-dom';
import ImageComponent from '../../Shared/ImageComponent/ImageComponent';
import Environment from '../../../Environment';
import { addObjectParamsToPath } from '../../../Utils/Utils';
import { Categories } from '../../../Models/Categories';
import { CategoryList } from '../../../Models/CategoryList';
import MixpanelService from '../../../Services/MixpanelService';
import { RootState } from '../../../Store/Reducers';
import useOutsideAlerter from '../../../Hooks/useOutsideAlerter';

type Props = {
  toggleMenu?: () => void;
  mobile?: boolean;
  varietyDetail?: { show: boolean; category: { name: string; key: string } };
  setVarietyDetail?: ({
    show,
    category
  }: {
    show: boolean;
    category: { name: string; key: string };
  }) => void;
  closeMenuMobile?: () => void;
};

const FlowerList: React.FC<Props> = ({
  toggleMenu,
  mobile,
  setVarietyDetail,
  closeMenuMobile
}) => {
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
  const { navHeight } = useSelector((state: RootState) => state.DisplayReducer);
  const { date, categoryList } = useSelector(
    (state: RootState) => state.ProductReducer
  );
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, toggleMenu);
  const [activeCategory, setActiveCategory] = useState<string>('');
  const history = useHistory();

  const handleAllFlowers = async () => {
    toggleMenu && toggleMenu();
    closeMenuMobile && closeMenuMobile();
    MixpanelService.track('flowers_menu_item', { name: 'All flowers' });
    history.push(addObjectParamsToPath('/search', { date }));
  };

  const handleOptions = (category_info: Categories, items: Array<string>) => {
    setActiveCategory(category_info._KEY);
    setVarietyDetail &&
      setVarietyDetail({
        show: true,
        category: {
          name: category_info.category,
          key: category_info._KEY
        }
      });
    setSelectedItems(items);
  };

  return (
    <div
      className={`d-flex flex-column ${
        mobile ? '' : 'dropdown-wrapper shadow bg-white'
      }`}
      style={{ top: `${navHeight + 61}px` }}
      ref={wrapperRef}>
      <span className="selected-item d-none d-lg-block "  />

      <div className="row justify-content-between w-100 pt-3 ">
        <div className="d-flex flex-column ">
          <span
            className={`underline pointer list-item my-3 pt-1 list-flower-category ${
              mobile ? 'pl-4 pr-2' : 'pl-4'
            }`}
            key="All"
            onClick={handleAllFlowers}>
            All Flowers
          </span>
        </div>
        <div className="ibf-link d-none d-lg-block" onClick={toggleMenu}>
          Close
        </div>
      </div>

      <div className="d-flex dropdown-items-wrapper">
        <div
          className={`d-flex ${
            mobile
              ? 'w-100'
              : 'flex-column list-flower-group  px-3 border-right overflow-auto w-25'
          }`}>
          <ul className={`list-group list-unstyled ${mobile ? 'w-100' : ''}`}>
            {categoryList &&
              categoryList.map((category: CategoryList) => (
                <Fragment key={category?.info?._KEY}>
                  <li
                    className={`d-flex align-items-center justify-content-between underline pointer 
            list-item py-2 ${mobile ? 'pl-4 pr-2' : 'px-2'} list-flower-category
                  ${
                    activeCategory === category?.info?._KEY
                      ? 'list-flower-category-active'
                      : ''
                  }`}
                    onClick={() => handleOptions(category.info, category.items)}
                    key={category._KEY}>
                    <div className="d-flex align-items-center">
                      <ImageComponent
                        className="rounded-circle mr-2"
                        imgSize={'30px'}
                        imageUrl={`${Environment.publicPath}images/Categories/${category.info?.category}.jpg`}
                      />

                      <span className="mr-4">{category.info?.category}</span>
                    </div>

                    <FontAwesomeIcon icon={faChevronRight} />
                  </li>
                </Fragment>
              ))}
          </ul>
        </div>

        {!mobile && (
          <div className="d-flex w-100">
            <FlowerListOptionsComponent
              toggleMenu={toggleMenu}
              items={selectedItems}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FlowerList;
