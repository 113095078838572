import React from 'react';

const MessageForgotPassword = () => {
  return (
    <div>
      <b>Sorry, your password doesn't seem right.</b>
    </div>
  );
};

export default MessageForgotPassword;
