import React from 'react';
import Product from '../../../../Models/Product';
type Props = {
  product: Product;
  lengthSelect: JSX.Element;
  stemInput: JSX.Element;
  tabsProductPrice: JSX.Element;
  growerSelected:boolean;
  isProductDetail:boolean;
  boxSelect: JSX.Element;
  boxOnly: boolean;
  isAList:boolean;
};
export default function ShowComponentCardView({
  product,
  lengthSelect,
  stemInput,
  tabsProductPrice,
  growerSelected,
  isProductDetail,
  boxSelect,
  boxOnly,
  isAList,
}: Props) {
 
  let render;
  switch (true) {
    case (product?.growers?.length === 1 ) :
      case growerSelected:
      return tabsProductPrice;

    case product?.growers?.length! > 1&& !boxOnly:
      case !growerSelected&& !boxOnly:
      return (
        <>
          {stemInput}
          <div className={ `mx-1 ${isProductDetail?'p-2':''}`}>{lengthSelect}</div>
        </>
      );
  

  case product?.growers?.length! > 1&& boxOnly:
    case !growerSelected&& boxOnly:
    return (
      <>
        <div  className={ `mx-1 mb-2 ${isProductDetail?'p-2':''} ${isAList?'mt-2':''} `}>
        {boxSelect}
        </div>
      
       <div className={ `mx-1 ${isProductDetail?'p-2':''}`}>{lengthSelect}</div>
      </>
     
    )
}
  return <>{render}</>;
}
