import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import OptionListComponent from './MenuOptionsComponent/OptionListComponent';
import {
  addObjectParamsToPath,
  appendToFirst,
  hrefContainsProperty,
  openStandingOrderQuotes,
  redirectTo,
  removeTo,
  toggleClassWithId
} from '../../../Utils/Utils';
import MixpanelService from '../../../Services/MixpanelService';
import { RootState } from '../../../Store/Reducers';
import { faHeart, faShippingFast } from '@fortawesome/pro-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { dashboardReducer } from '../../../Store/Selectors';
type Props = {
  redirectToCategory?: (category: string) => void;
};

const MenuComponent: React.FC<Props> = ({ redirectToCategory }) => {
  const { user } = useSelector((state: RootState) => state.AuthReducer);
  const { date, numberWeek, favorites } = useSelector(
    (state: RootState) => state.ProductReducer
  );
  const { trackPackageInfo } = useSelector(dashboardReducer);

  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [secondPage, setSecondPage] = useState('');

  const openMenu = () => {
    setShowMenu(true);
    const divOverlayElement = <div />;
    const overlay = document.getElementById('overlay');
    if (overlay) {
      removeTo('overlay');
    }
    appendToFirst('navBar', 'overlay', divOverlayElement);
    toggleClassWithId('overlay', 'dropdown-overlay');
  };
  const closeMenu = () => {
    setShowMenu(false);
    visible.initialState();
    removeTo('overlay');
  };

  const closeMenuDashboard = () => {
    setShowMenu(false);
    removeTo('overlay');
    getToSecondPage('');
    setFirst(true);
    setSecond(false);
  };
  const back = () => {
    visible.previous();
    removeTo('overlay');
    getToSecondPage('');
    setFirst(true);
    setSecond(false);
  };

  const getToMainPage = () => {
    setFirst(true);
    setSecond(false);
  };
  const getToSecondPage = (page: string) => {
    setFirst(false);
    setSecond(true);
    setSecondPage(page);
  };

  const initialState = () => {
    setFirst(true);
    setSecond(false);
    setShowMenu(false);
  };

  const visible = {
    next: getToSecondPage,
    previous: getToMainPage,
    initialState: initialState
  };

  const getToMyAccount = () => {
    MixpanelService.track('myAccount_menu');
    /* getToSecondPage('myAccount'); */
    getToSecondPage('dashboard');
    setSecond(true);
  };

  const getComboBoxes = async () => {
    const params: any = {
      date,
      combo: true
      /*product_group: 'dc2d4e70-31a7-4009-972a-f5bbe103df9a'*/
    };
    if (hrefContainsProperty('week')) {
      params.week = numberWeek;
    }
    history.push(addObjectParamsToPath('/search', params));
  };

  const mainMenu = [
    {
      label: 'Home',
      function: () => {
        redirectTo('home');
        closeMenu();
      }
    },
    {
      label: 'Flowers',
      arrow: true,
      function: () => getToSecondPage('flowers')
    },
    {
      label: 'Growers',
      arrow: true,
      function: () => getToSecondPage('growers')
    },
    {
      label: 'Colors',
      arrow: true,
      function: () => getToSecondPage('colors')
    },
    {
      label: 'Bouquets/CB',
      function: () => {
        redirectToCategory && redirectToCategory('bouquets');
        closeMenu();
      }
    },
    {
      label: 'Mixed box',
      function: () => {
        getComboBoxes();
        closeMenu();
      }
    },
    {
      label: 'Dried/ Preserved',
      function: () => {
        redirectToCategory && redirectToCategory('driedPreserved');
        closeMenu();
      }
    },
    /* {
      label: 'Supplies',
      function: () => {
        redirectToCategory && redirectToCategory('supplies');
        closeMenu();
      }
    }, */
    {
      label: '',
      function: () => {},
      space: true
    },
    {
      label: `Favorites (${Object.keys(favorites).length})`,
      function: () => getToSecondPage('favorites'),
      arrow: true,
      icon: faHeart
    },
    {
      label: 'Track & Trace',
      function: () => {
        history.push(`/order_detail/${trackPackageInfo}`);
        closeMenu();
      },
      icon: faShippingFast
    },
    {
      label: 'Standing Order',
      function: () => {
        openStandingOrderQuotes(false);
      }
    },
    {
      label: 'My Account',
      arrow: true,
      function: getToMyAccount
    }
  ];

  const filterMainMenu = mainMenu.filter((item) => {
    if (!trackPackageInfo) {
      return item.label !== 'Track & Trace';
    }
    return item;
  });
  return (
    <>
      <Button
        style={{ height: '2.2rem' }}
        onClick={openMenu}
        className={'ibf-btn-cart btn ibf-nav-mobile-menu ibf-nav-menu mr-3'}>
        Menu
      </Button>

      <div
        className={
          showMenu ? 'ibf-menu-show-main-window' : 'ibf-menu-hide-main-window'
        }>
        <div className="ibf-menu-sticky d-flex justify-content-between p-4">
          <h5 className="mb-0">
            <b className="text-capitalize">
              hi, {user && user.name && user.name.split(' ', 1)}
            </b>
          </h5>
          <div>
            <span onClick={closeMenu} className="ibf-sort-close-icon">
              X
            </span>
          </div>
        </div>
        {/*content*/}

        <div className="ibf-transition-slider">
          <OptionListComponent
            mainMenu={filterMainMenu}
            closeMenu={closeMenu}
            closeMenuDashboard={closeMenuDashboard}
            setSecond={setSecond}
            visible={visible}
            secondPage={secondPage}
            getToSecondPage={getToSecondPage}
            first={first}
            second={second}
            back={back}
          />
        </div>
      </div>
    </>
  );
};

export default MenuComponent;
