import React from 'react';
import { Menu } from 'antd';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUndo, faTimes } from '@fortawesome/pro-regular-svg-icons';
import CalendarInputComponent from './CalendarInputComponent';
import { EventCartbox } from '../../Models/EventCartbox';

type Props = {
  selectedDay: string | Date;
  handleDayChange: (day: any) => void;
  parseDate: (str: any, format: any) => void;

  highlighted: (calendarDay: any) => void;
  handlePoNumber: (event: any) => void;
  poNumber: string;
  handleOrderNumber: (event: any) => void;
  orderNumber: string;
  handleGrowerPicker: (event: any) => void;
  growerPicked: string;
  growers: Array<any>;
  handleEventName: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  eventName?: string;
  eventList: Array<EventCartbox>;
  events?: { eventName: string } | any;
  handleSearch: () => void;
  clearInputs: () => void;
  onClick: () => void;
  type?: string;
};
const MobileFilteredMenu = (props: Props) => {
  const highlightedDayPiker: any = props.highlighted;
  return (
    <Menu className="shadow rounded px-2 position-sticky fixed-top scroll-behavior">
      <Menu.Item key="1">
        <div className="d-flex justify-content-end mt-2 mb-2">
          <FontAwesomeIcon
            icon={faTimes}
            size={'2x'}
            className="pointer font-weight-bold"
            onClick={props.onClick}
          />
        </div>
      </Menu.Item>

      <label className="mb-2 ml-1">Arrival date</label>
      <Menu.Item key="2" className="mb-4 h-auto p-1">
        <DayPickerInput
          value={props.selectedDay}
          onDayChange={props.handleDayChange}
          // formatDate={FiltersTableComponent.formatDate}
          parseDate={props.parseDate}
          format={'MM/DD/YYYY'}
          component={CalendarInputComponent}
          inputProps={{
            ref: null
          }}
          dayPickerProps={{
            className: 'calendar-arrival',
            modifiers: { highlightedDayPiker },
            modifiersStyles: {
              highlighted: {
                backgroundColor: '#8cd198'
              }
            },
            disabledDays: {
              daysOfWeek: [0, 6]
            }
          }}
        />
      </Menu.Item>
      <label className="mb-2 ml-1">PO #</label>
      <Menu.Item key="3" className="mb-4 h-auto p-1">
        <input
          className="form-control"
          onChange={props.handlePoNumber}
          value={props.poNumber}
        />
      </Menu.Item>

      <label
        className={`mb-2 ml-1 ${props.type !== 'ARRIVAL_DATE' && 'd-none'}`}>
        Order #
      </label>

      <Menu.Item
        key="4"
        className={`mb-4 p-1 h-auto ${props.type !== 'ARRIVAL_DATE' &&
          'd-none'}`}>
        <div className="d-flex flex-column ">
          <input
            className="form-control"
            onChange={props.handleOrderNumber}
            value={props.orderNumber}
          />
        </div>
      </Menu.Item>

      <label
        className={`mb-2 ml-1 ${props.type !== 'ARRIVAL_DATE' && 'd-none'}`}>
        Product name
      </label>
      <Menu.Item
        key="5"
        className={`mb-4 p-1 h-auto ${props.type !== 'ARRIVAL_DATE' &&
          'd-none'}`}>
        <input className="form-control mt-2 px-mb-0 px-lg-0" />
      </Menu.Item>

      <label
        className={`mb-2 ml-1 ${props.type !== 'ARRIVAL_DATE' && 'd-none'}`}>
        Grower
      </label>
      <Menu.Item
        key="6"
        className={`mb-4 p-1 h-auto ${props.type !== 'ARRIVAL_DATE' &&
          'd-none'}`}>
        <select
          className="custom-select"
          onChange={props.handleGrowerPicker}
          value={props.growerPicked}>
          <option disabled>Pick a grower</option>
          {props.growers !== undefined &&
            props.growers.map((item: any) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
        </select>
      </Menu.Item>
      <label
        className={`mb-2 ml-1 ${props.type !== 'ARRIVAL_DATE' && 'd-none'}`}>
        Event name
      </label>
      <Menu.Item
        key="7"
        className={`mb-4 p-1 h-auto ${props.type !== 'ARRIVAL_DATE' &&
          'd-none'}`}>
        {props.type === 'ARRIVAL_DATE' && (
          <select
            className="custom-select"
            onChange={props.handleEventName}
            value={props.events?.eventName}>
            <option selected value="" disabled>
              Select event name
            </option>
            {props.eventList.map((item: EventCartbox) => {
              return (
                <option key={item._KEY} value={item._KEY}>
                  {item.tag_name}
                </option>
              );
            })}
          </select>
        )}
      </Menu.Item>

      <Menu.Item key="8" className="mb-2 h-auto p-1">
        <div className="d-flex">
          {props.type === 'ARRIVAL_DATE' && (
            <button
              className="btn btn-purple mr-2 btn-font-sm"
              onClick={props.handleSearch}>
              <FontAwesomeIcon className="ml-2" icon={faSearch} /> Search
            </button>
          )}

          <button
            className="btn btn-purple btn-font-sm "
            onClick={props.clearInputs}>
            <FontAwesomeIcon className="ml-2" icon={faUndo} /> Reset Filters
          </button>
        </div>
      </Menu.Item>
    </Menu>
  );
};
export default MobileFilteredMenu;
