import { actionsCard } from './Slice';
import { mapCreditCards, toast } from '../../Utils/Utils';
import { Card } from '../../Models/Card';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { ADD_CARD, CHANGE_DEFAULT_CARD, DELETE_CARD, GET_CARDS } from './Types';
import {
  addCardService,
  deleteCustomerCard,
  loadCards,
  updateCustomerDefaultCard
} from '../../Services/UserService';
import MixpanelService from '../../Services/MixpanelService';
import { RootState } from '../Reducers';
import { PAYMENT_MODAL } from '../Modal/Types';
import { actionsModal } from '../Modal/Slice';

export function* listCardSaga({
  payload
}: ReturnType<typeof actionsCard.getCards>) {
  try {
    if (payload.creditCards && payload.creditCards.length) {
      const cards = mapCreditCards(payload.creditCards);
      const cardDefault =
        payload.creditCards.find((i: Card) => i.is_default) ||
        payload.creditCards[0];
      yield put(actionsCard.listCards({ cards, cardDefault }));
    } else {
      yield put(actionsCard.setResolved());
    }
  } catch (e) {
    console.error('Error on list card saga');
    yield put(actionsCard.setRejected());
  }
}

export function* deleteCardSaga({
  payload
}: ReturnType<typeof actionsCard.deleteCard>): any {
  try {
    const res = yield call(
      deleteCustomerCard,
      payload.customer,
      payload.cardKey,
      payload.isDefault
    );
    if (res && res.success) {
      const newCards = mapCreditCards(res.cards);
      if (payload.isDefault) {
        yield put(
          actionsCard.setDefaultCard({
            cards: newCards,
            cardDefault: res.cards[0]
          })
        );
      }
      yield put(actionsCard.setCards(newCards));
      toast('Card was deleted');
    }
  } catch (e) {
    console.error('Error on delete card saga');
    yield put(actionsCard.setRejected());
  }
}

export function* selectDefaultCard({
  payload
}: ReturnType<typeof actionsCard.changeDefaultCard>): any {
  try {
    const cards = payload.cards.map((item: Card) => {
      item = { ...item, is_default: payload.card._KEY === item._KEY };
      return item;
    });

    const res = yield call(updateCustomerDefaultCard, cards, payload.card);

    if (res) {
      const newDefaultCard = res.find((i: Card) => i.is_default);
      if (newDefaultCard) {
        yield put(
          actionsCard.setDefaultCard({
            cards: res,
            cardDefault: newDefaultCard
          })
        );
      }
      toast('New Default Payment Method Selected.', 'success');
    }
  } catch (e) {
    console.error('Error on select default card saga');
    yield put(actionsCard.setRejected());
  }
}

export function* addCardSaga({
  payload
}: ReturnType<typeof actionsCard.setNewCard>): any {
  try {
    const res = yield call(addCardService, payload.token, payload.customer);

    if (res.success) {
      const { paymentModal } = yield select(
        (state: RootState) => state.ModalReducer
      );
      yield put(actionsCard.addCard(true));
      MixpanelService.track('add_card', {
        cardBrand: res.box.card_added.brand,
        from: paymentModal.data.from
      });
      toast(res.box.card_added.brand + ' was added successfully!');
      const resCards = yield call(loadCards, payload.customer);
      const cards = mapCreditCards(resCards);
      const cardDefault =
        resCards.find((i: Card) => i.is_default) || resCards[0];
      yield put(actionsCard.listCards({ cards, cardDefault }));
      yield put(actionsModal.setCloseModal({ modal: PAYMENT_MODAL }));
    } else {
      toast(res.error, 'error');
      yield put(actionsCard.setRejected());
    }
  } catch (e) {
    console.error('Error on add card saga');
    yield put(actionsCard.addCard(false));
    yield put(actionsCard.setRejected());
  }
}

export default function* AllSagas() {
  yield all([
    //Auth sagas
    takeEvery(GET_CARDS, listCardSaga),
    takeEvery(DELETE_CARD, deleteCardSaga),
    takeEvery(CHANGE_DEFAULT_CARD, selectDefaultCard),
    takeEvery(ADD_CARD, addCardSaga)
  ]);
}
