import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCheck,
  faTimes
} from '@fortawesome/pro-solid-svg-icons';
import ImageComponent from '../../ImageComponent/ImageComponent';
import { getSchedule } from '../../../../Services/ProductService';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { countryFlag } from '../../../../Utils/Utils';
import Countries from '../../../../Models/Countries';
import { RootState } from '../../../../Store/Reducers';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { DELIVERY_SCHEDULE_MODAL } from '../../../../Store/Modal/Types';

const DeliveryScheduleModal = () => {
  const [countries, setCountries] = useState([]);

  const { deliveryScheduleModal } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const getDelivery = async () => {
      const res = await getSchedule();
      setCountries(
        res.countries.filter(
          (country: Countries) =>
            country.country_iso !== 'TH' &&
            country.country_iso !== 'NL' &&
            !(
              country.country_iso === 'US' &&
              country.days.every((day) => day.status === null)
            )
        )
      );
    };
    getDelivery();
  }, []);

  const toggle = () => {
    dispatch(actionsModal.setCloseModal({ modal: DELIVERY_SCHEDULE_MODAL }));
  };

  return (
    <div>
      <Modal
        size={'lg'}
        className="ibf-modal-delivery-schedule-size"
        isOpen={deliveryScheduleModal.open}
        toggle={toggle}>
        <ModalHeader className="pb-0 border-0" toggle={toggle}>
          {' '}
          <FontAwesomeIcon className="mr-2" icon={faCalendarAlt} />
          <span className="color-gray">
            <b>Delivery schedule</b>
          </span>
        </ModalHeader>
        <ModalBody className="ibf-modal-delivery-schedule-height">
          <span className="mb-1">
            We suggest ordering 3-5 days in advance of your events. All Flowers
            are farm fresh and need time to open
          </span>
          <div className="table-responsive">
            <table className="table table-bordered table-striped mb-0">
              <thead>
                <tr>
                  <th rowSpan={2}></th>
                  <th className="text-center" colSpan={7}>
                    Delivery days - Cut off time is daily 8 AM EST
                  </th>
                </tr>
                <tr>
                  <th className="text-center">
                    Sunday
                    <br />
                    <small>(Order before)</small>
                  </th>
                  <th className="text-center">
                    Monday
                    <br />
                    <small>(Order before)</small>
                  </th>
                  <th className="text-center">
                    Tuesday
                    <br />
                    <small>(Order before)</small>
                  </th>
                  <th className="text-center">
                    Wednesday
                    <br />
                    <small>(Order before)</small>
                  </th>
                  <th className="text-center">
                    Thursday
                    <br />
                    <small>(Order before)</small>
                  </th>
                  <th className="text-center">
                    Friday
                    <br />
                    <small>(Order before)</small>
                  </th>
                  <th className="text-center">
                    Saturday
                    <br />
                    <small>(Order before)</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                {countries &&
                  countries.map((country: any, index: number) => {
                    return (
                      <tr key={'country' + country._KEY + '-' + index}>
                        <td className="d-flex flex-column align-items-center p-2">
                          <div className={'d-flex  align-items-center '}>
                            <ImageComponent
                              className={'mr-1'}
                              imageUrl={countryFlag(country.country_iso)}
                              imgSize={'40px'}
                            />
                            <small>
                              {' '}
                              <b>
                                {' '}
                                {country.country_iso}
                                {' - '}{' '}
                              </b>{' '}
                            </small>
                            <small>{country.deliveryTime}*</small>
                          </div>

                          <small>
                            <span>{country.type}</span>
                          </small>
                        </td>

                        {country.days.map((day: any, index: number) => {
                          return (
                            <td
                              key={'day' + index}
                              className={'text-center align-middle p-2'}>
                              {day.status === 'OK' ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  color={'green'}
                                />
                              ) : day.status === 'TEMP' ? (
                                <FontAwesomeIcon icon={faClock} />
                              ) : (
                                <FontAwesomeIcon icon={faTimes} color={'red'} />
                              )}
                              <br />
                              <small>
                                <span> {day.from} </span>
                              </small>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-auto">
              <small>*Estimate Delivery Time</small>
            </div>
            <div className="col text-right">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faCheck} color={'green'} />
                  <small>Delivery</small>
                </li>
                <li className="list-inline-item">
                  <FontAwesomeIcon icon={faTimes} color={'red'} />
                  <small>No Delivery</small>
                </li>
                <li className="list-inline-item">
                  <i className="align-middle font-weight-bold fa fa-clock-o text-primary"></i>
                  <small>During holidays</small>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeliveryScheduleModal;
