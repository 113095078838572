import React from 'react';
import './productListD.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { ListItemD } from './ListItemD';

interface Props {
  arrayItems: string[];
  input: string;
}

export const ProductFilterComponentDi = ({ arrayItems, input }: Props) => {
  return (
    <div className="contFiltersD">
      <div className="inputFiltersD">
        <FontAwesomeIcon icon={faSearch} color="#00000040" />
        <input type="text" placeholder={input} />
      </div>

      <div className="ListFiltersD">
        <ul>
          {arrayItems.map((item) => (
            <ListItemD key={item} item={item} id={`sbx-${item}`} />
          ))}
        </ul>
      </div>
    </div>
  );
};
