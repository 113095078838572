import { ModalRedux } from '../../Models/ModalRedux';
import { Cartbox } from '../../Models/Cartbox';
import { Data } from '../../Components/OrdersTableComponent/OrderCustomBoxRow';

export const ACCOUNT_MANAGER_MODAL = 'accountManagerModal';
export const MANAGE_GROWERS_MODAL = 'manageGrowersModal';
export const CUSTOM_BOX_DETAIL_MODAL = 'customBoxDetailModal';
export const ADDRESS_MODAL = 'addressModal';
export const DELIVERY_SCHEDULE_MODAL = 'deliveryScheduleModal';
export const GROWER_INFO_MODAL = 'growerInfoModal';
export const OPEN_CUSTOM_BOX = 'openCustomBox';
export const PAYMENT_MODAL = 'paymentModal';
export const USERS_LIST_MODAL = 'usersListModal';
export const INFO_CARD_MODAL = 'infoCardModal';
export const ADD_USER_MODAL = 'addUserModal';
export const PAYMENT_LIST_MODAL = 'paymentListModal';
export const COMPANY_INFORMATION_MODAL_2 = 'companyInformationModal';
export const EDIT_COMPANY_INFORMATION_MODAL = 'editCompanyInformationModal';
export const CONFIRM_MODAL = 'confirmModal';
export const CONFIRM_ZIPCODE_MODAL = 'CONFIRM_ZIPCODE_MODAL';
export const CONFIRM_DELIVERY_DATE_MODAL = 'confirmDeliveryDateModal';
export const FINISH_ORDER_MODAL = 'finishOrder';
export const CLOSE_CUSTOM_BOX_MODAL = 'closeCustomBoxModal';
export const CUSTOM_BOX_OPEN = 'customBoxOpen';
export const ARRIVAL_DATE_CHANGED_MODAL = 'arrivalDateChangedModal';
export const PROMO_CODE_INVALID = 'promoCodeInvalid';
export const NEXT_AVAILABLE_DATE_MODAL = 'nextAvailableDateModal';
export const SHOPPING_LIST_SUMMARY = 'shoppingListSummary';
export const CUSTOM_BOX_INVENTORY_EMPTY = 'CUSTOM_BOX_INVENTORY_EMPTY';
export const REFERRAL_MODAL = 'referralModal';
export const SHIPPING_DETAILS = 'shippingDetails';
export const GREETING_MODAL = 'greetingModal';
export const HOW_TO_USE_MODAL = 'howToUseModal';
export const FORGOT_PASSWORD_MODAL = 'FORGOT_PASSWORD_MODAL';
export const CONFIRM_REFERRAL_PURCHASE = 'confirmReferralPurchase';
export const SL_PROCESSING = 'SLProcessing';
export const START_WORKFLOW_SEARCH = 'START_WORKFLOW_SEARCH';
export const SIGN_UP_MODAL = 'SIGN_UP_MODAL';
export const CUSTOM_BOX_MODAL = 'CUSTOM_BOX_MODAL';
export const CART_MODAL = 'CART_MODAL';
export const CELLPHONE_MODAL = 'CELLPHONE_MODAL';
export const REPLACEMENTS_MODAL = 'REPLACEMENTS_MODAL';
export const CONFIRMATION_CHECKOUT_MODAL = 'CONFIRMATION_CHECKOUT_MODAL';
export const SIGN_IN_FLOW = 'SIGN_IN_FLOW';
export const COMPANY_INFORMATION_MODAL = 'COMPANY_INFORMATION_MODAL';
export const CHANGE_EMAIL_MODAL = 'CHANGE_EMAIL_MODAL';
export const CHANGE_PASSWORD_MODAL = 'CHANGE_PASSWORD_MODAL';
export const EVENT_NAME_MODAL = 'EVENT_NAME_MODAL';
export const ADD_EDIT_EVENT_NAME = 'ADD_EDIT_EVENT_NAME';
export const EVENT_MODAL_DONE = 'EVENT_MODAL_DONE';
export const CONFIRM_PROFILE_MODAL = 'CONFIRM_PROFILE_MODAL';
export const MESSAGE_MODAL = 'MESSAGE_MODAL';
export const MESSAGE_COOKIE_MODAL = 'MESSAGE_COOKIE_MODAL';
export const ADDRESSES_MODAL = 'ADDRESSES_MODAL';
export const GUEST_USER_NAME_CHANGE = 'GUEST_USER_NAME_CHANGE';
export const STANDING_ORDER_CREATION_MODAL = 'STANDING_ORDER_CREATION_MODAL';
export const STANDING_ORDER_DONE = 'STANDING_ORDER_DONE';
export interface ModalState {
  [COMPANY_INFORMATION_MODAL]: ModalRedux;
  [PAYMENT_LIST_MODAL]: ModalRedux;
  [USERS_LIST_MODAL]: ModalRedux;
  [ADD_USER_MODAL]: ModalRedux;
  [CONFIRM_MODAL]: ModalRedux;
  [PAYMENT_MODAL]: ModalRedux;
  [MANAGE_GROWERS_MODAL]: ModalRedux;
  [DELIVERY_SCHEDULE_MODAL]: ModalRedux;
  [SHIPPING_DETAILS]: ModalRedux;
  [ACCOUNT_MANAGER_MODAL]: ModalRedux;
  [GROWER_INFO_MODAL]: ModalRedux;
  [EDIT_COMPANY_INFORMATION_MODAL]: ModalRedux;
  [REFERRAL_MODAL]: ModalRedux;
  [GREETING_MODAL]: ModalRedux;
  [HOW_TO_USE_MODAL]: ModalRedux;
  [CONFIRM_DELIVERY_DATE_MODAL]: ModalRedux;
  [CUSTOM_BOX_MODAL]: ModalRedux;
  [CART_MODAL]: ModalRedux;
  [CELLPHONE_MODAL]: ModalRedux;
  [REPLACEMENTS_MODAL]: ModalRedux;
  [CONFIRMATION_CHECKOUT_MODAL]: ModalRedux;
  [SIGN_UP_MODAL]: ModalRedux;
  [SIGN_IN_FLOW]: ModalRedux;
  [COMPANY_INFORMATION_MODAL_2]?: ModalRedux;
  [CHANGE_EMAIL_MODAL]: ModalRedux;
  [CHANGE_PASSWORD_MODAL]: ModalRedux;
  [ADD_EDIT_EVENT_NAME]: ModalRedux;
  [CONFIRM_ZIPCODE_MODAL]: ModalRedux;
  [EVENT_NAME_MODAL]: ModalRedux<{
    cartbox: Cartbox;
    isUpdate?: boolean;
    event: { tag_name: string; color: string; event_key: string };
  }>;
  [EVENT_MODAL_DONE]: ModalRedux<{ event_name: string; isCartbox: boolean }>;
  [CONFIRM_PROFILE_MODAL]: ModalRedux;
  [MESSAGE_MODAL]: ModalRedux;
  [MESSAGE_COOKIE_MODAL]: ModalRedux;
  [ADDRESSES_MODAL]: ModalRedux;
  [GUEST_USER_NAME_CHANGE]: ModalRedux;
  [STANDING_ORDER_CREATION_MODAL]: ModalRedux<Data>;
  [STANDING_ORDER_DONE]: ModalRedux;
}
