import { runCS } from '../Network';
import Environment from '../Environment';

export const listOrders = (customer: string) => {
  return runCS(Environment.cloudscripts.ibf_po_listOrders, {
    customer
  }).then((res) => res);
};

export const listOrdersByPurchaseDate = (customer: string) => {
  return runCS(Environment.cloudscripts.ibf_po_listOrdersByPurchaseDate, {
    customer
  }).then((res) => res);
};

export const listOrdersDetails = (order: string) => {
  return runCS(Environment.cloudscripts.ibf_po_orderDetail, {
    order
  }).then((res) => res);
};

export const deleteOrder = (
  cartbox: any,
  customer: any,
  requestedByCustomer: any
) => {
  return runCS(Environment.cloudscripts.cancelBOrder, {
    cartbox: cartbox,
    customer: customer,
    requestedByCustomer: requestedByCustomer
  })
    .then((res) => {
      if (res.success) {
        return { success: true, data: res.data };
      }
      return { success: false, error: res.error };
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getFedexTracking = (cartbox: any) => {
  return runCS(Environment.cloudscripts.fedexTracking, {
    cartKey: cartbox
  })
    .then((res) => {
      if (res.success) {
        return { success: true, data: res };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
