import React, { Fragment } from 'react';
import { redirectUrlIbuyflowers, roundUp, uuidV4 } from '../../Utils/Utils';
import { Popover } from 'antd';
import {
  faAngleDoubleRight,
  faSpinner
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrderEntity } from '../../Store/Order/Types';
import { useSelector } from 'react-redux';
import { standingOrderReducer } from '../../Store/Selectors';
import Environment from '../../Environment';

type Props = {
  masterlist_prices?: any;
  soExist?: any;
  box: OrderEntity;
  deleteHandler: (
    cartbox: any,
    customer: any,
    price: any,
    requestedByCustomer: any,
    box: any
  ) => void;
  openCalendar: (itemOrder: any) => void;
  itemOrder: Array<OrderEntity>;
  popoverContent: JSX.Element;
  isPopoverVisible: boolean;
  shouldDeleteCartbox: (box: OrderEntity) => boolean;
  buttonAction?: JSX.Element;
  isDeleting: boolean;
  onClick?: () => void;
};

export interface Data {
  prices: Prices;
  //masterlist_prices[itemOrder[1].masterlist_key]?.weekly_price.saving
  box: any;
  custom: boolean;
}

export interface Prices {
  weekly_saving_price?: number;
  bi_weekly_saving_price?: number;
  weekly_price?: { saving: number; price_per_bunch: number };
  bi_weekly_price?: { saving: number; price_per_bunch: number };
  masterlist_prices: any;
  normal_price: { price_per_bunch: number };
}

const OrderCustomBoxRow = ({
  masterlist_prices,
  box,
  deleteHandler,
  openCalendar,
  isPopoverVisible,
  itemOrder,
  popoverContent,
  buttonAction,
  shouldDeleteCartbox,
  isDeleting,
  onClick,
  soExist
}: Props) => {
  const { resCreationStandingOrder } = useSelector(standingOrderReducer);
  // const dispatch = useDispatch();
  // let prices = masterlist_prices[itemOrder[0]._KEY!];
  // const openStandingOrder = () => {
  //   dispatch(
  //     actionsModal.setOpenModal({
  //       modal: STANDING_ORDER_CREATION_MODAL,
  //       data: {
  //         prices: prices,
  //         //masterlist_prices[itemOrder[1].masterlist_key]?.weekly_price.saving
  //         box: itemOrder,
  //         custom: true
  //       }
  //     })
  //   );
  // };
  return (
    <Fragment key={uuidV4()}>
      <tr className="overflow-hidden">
        {/*  <td className="p-2">
          {' '}
          {box.grower_obj?.company_name ?? box.grower ?? ''}{' '}
        </td> */}
        <td
          className="text-primary underline cursor pointer"
          onClick={onClick}
          colSpan={3}>
          {box.po}-{box.order}
        </td>
        {/*    <td className=""> {box.order} </td> */}
        <td className="" colSpan={2}>
          {box.product_group}
          <>
            {box.event_color && box.event_name && (
              <div className="d-flex align-items-center">
                <span
                  className="ibf-btn-circle d-block border border-dark"
                  style={{ background: box.event_color }}
                />
                <span className="ml-1"> {box.event_name}</span>
              </div>
            )}
          </>
        </td>
        {/*   <td colSpan={3} className="p-2">
          <span>
            {box.variety_grade}
            <span className="text-primary">
              <i className="fa fa-fw fa-random" />
            </span>
          </span>
        </td> */}

        <td colSpan={2}>
          {box?.grower} {box.grower_obj?.importer ? `(Miami Inventory)` : null}
        </td>

        <td className="">
          <span className="d-flex flex-column">
            ${roundUp(box.price)}
            {box.extra_freight_cost && box.extra_freight_cost > 0 ? (
              <span className="ibf-text-red d-flex flex-column">
                <span>
                  (Subtotal: ${roundUp(box.price - box.extra_freight_cost)}
                </span>
                <span>Extra freight cost: ${box.extra_freight_cost})</span>
              </span>
            ) : null}
          </span>
        </td>

        {/*  <td className=""> {buttonAction} </td> */}
        <td className="d-none d-lg-table-cell text-nowrap">
          <Popover
            trigger="click"
            placement={'top'}
            overlayClassName="custom-step-desktop-popover"
            content={popoverContent}
            visible={isPopoverVisible}>
            <button
              className="btn bg-ibf-calculating text-primary border"
              onClick={() => openCalendar(itemOrder)}>
              Re-order this box
              {/* (select new date) */}
            </button>
          </Popover>
          {/*
          <ButtonStadingOrder/> */}
        </td>

        {box.current_percentage >= 95 ? (
          soExist ||
          (resCreationStandingOrder?.created &&
            resCreationStandingOrder._KEY === box._KEY) ? (
            <td className=" d-lg-table-cell text-nowrap p-2 pt-1">
              <i>
                This order is part of a standing order.
                <br />
                <a
                  className="text-black underline"
                  href={`${redirectUrlIbuyflowers(
                    Environment.baseUrl,
                    '#/myStandingOrders'
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  check your standing order{' '}
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </a>
              </i>
            </td>
          ) : (
            <></>
            // <td className=" d-lg-table-cell text-nowrap" colSpan={3}>
            //   <Popover
            //     trigger="click"
            //     placement={'top'}
            //     overlayClassName="custom-step-desktop-popover"
            //     content={popoverContent}
            //     visible={isPopoverVisible}>
            //     <button
            //       className="btn border bg-ibf-calculating"
            //       style={{color: 'green'}}
            //       onClick={
            //         masterlist_prices &&
            //         !isNaN(prices?.weekly_saving_price) &&
            //         openStandingOrder
            //       }>
            //       Save
            //       {masterlist_prices && !isNaN(prices?.weekly_saving_price) ? (
            //         <b> ${(prices?.weekly_saving_price).toFixed(2)}</b>
            //       ) : (
            //         <FontAwesomeIcon
            //           icon={faSpinner}
            //           spin={true}
            //           className="mx-1"
            //         />
            //       )}{' '}
            //       /year as Standing Order
            //     </button>
            //   </Popover>
            //
            //   {/*  <ButtonStadingOrder/> */}
            // </td>
          )
        ) : (
          <></>
          // <td className="d-lg-table-cell text-nowrap p-2 pt-2">
          //   <i>
          //     No Standing Order possible
          //     <Popover
          //       trigger="hover"
          //       placement={'bottom'}
          //       overlayClassName="custom-step-desktop-popover"
          //       content={
          //         <p className="text-center">
          //           Standing Orders are only possible <br/>
          //           for (custom) boxes that are 100%
          //           <br/> filled.
          //         </p>
          //       }
          //       // visible={isPopoverVisible}
          //     >
          //       <span className="pointer">(i)</span>
          //     </Popover>
          //   </i>
          // </td>
        )}
      </tr>
      <tr
        className="d-table-row d-lg-none
        border-0">
        <td colSpan={4} className="pt-0 pr-2 border-0">
          <Popover
            trigger="click"
            placement={'top'}
            overlayClassName="custom-step-desktop-popover"
            content={popoverContent}
            visible={isPopoverVisible}>
            <button
              className="btn border bg-ibf-calculating text-primary mt-1 mr-md-1 mr-lg-0"
              onClick={() => openCalendar(itemOrder)}>
              Re-order this box
              {/* (select new date) */}
            </button>
          </Popover>
          {/*  <ButtonStadingOrder/> */}
        </td>
      </tr>
      <tr className="border-0">
        <td colSpan={3} className="pt-0 text-left border-0">
          {shouldDeleteCartbox(box) && (
            <div className="btn-group dropdown" key={uuidV4()}>
              <button
                disabled={isDeleting}
                className="btn btn-sm btn-danger"
                onClick={() => {
                  deleteHandler(box._KEY, box.customer, box.price, true, box);
                }}>
                {isDeleting && (
                  <FontAwesomeIcon icon={faSpinner} pulse className={'mr-2'} />
                )}{' '}
                Delete this order
              </button>
            </div>
          )}
        </td>
        {/*  <td colSpan={10} className="pt-0 text-right">
          <Popover
            trigger="click"
            placement={'top'}
            overlayClassName="custom-step-desktop-popover"
            content={popoverContent}
            visible={isPopoverVisible}>
            <button
              className="btn ibf-btn-grey"
              onClick={() => openCalendar(itemOrder)}>
              Re-order this box (select new date)
            </button>
          </Popover>
        </td> */}
      </tr>
    </Fragment>
  );
};

export default OrderCustomBoxRow;
