import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import EventEditRowComponent from './EventEditRowComponent';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import {
  authReducer,
  checkoutReducer,
  eventReducer,
  modalReducer
} from '../../../../Store/Selectors';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { ADD_EDIT_EVENT_NAME } from '../../../../Store/Modal/Types';
import { toast } from '../../../../Utils/Utils';
import { eventActions } from '../../../../Store/Event/Slice';
import EventListLoading from '../../SkeletonLoadings/EventList/EventListLoading';
import { State } from '../../../../Models/State';
import ButtonPickerColor from '../../ButtonPickerColor/ButtonPickerColor';

export default function AddAndEditEvent() {
  const {
    ADD_EDIT_EVENT_NAME: { open }
  } = useSelector(modalReducer);
  const { eventList, state } = useSelector(eventReducer);
  const dispatch = useDispatch();
  const { productsByEventName } = useSelector(checkoutReducer);
  const { user } = useSelector(authReducer);
  const [selectedColor, setSelectedColor] = useState('#f99b9c');

  const closeModal = () => {
    dispatch(actionsModal.setCloseModal({ modal: ADD_EDIT_EVENT_NAME }));
  };
  const [events, setEvents] = useState<{
    [key: string]: {
      boxCount: number;
      lastDate: number;
      name: string;
      color: string;
    };
  }>({});
  const eventNameRef = useRef<HTMLInputElement>(null);

  const handleNewEvent = () => {
    if (eventNameRef?.current?.value === '') {
      toast('Please type a name.', 'error');
      return;
    }

    if (eventNameRef?.current?.value) {
      dispatch(
        eventActions.addCartboxEvent({
          params: {
            customer: user.metadata.customer,
            tag_name: eventNameRef.current.value,
            color: selectedColor
          }
        })
      );
      eventNameRef.current.value = '';
    }
  };

  React.useEffect(() => {
    const list = eventList.reduce(
      (
        obj: {
          [key: string]: {
            boxCount: number;
            lastDate: number;
            name: string;
            color: string;
          };
        },
        event
      ) => {
        const eventKey = event._KEY;

        if (productsByEventName[eventKey]?.event) {
          Object.keys(productsByEventName[eventKey].dates).forEach((date) => {
            productsByEventName[eventKey].dates[date].forEach((product) => {
              if (!obj[eventKey]) {
                obj[eventKey] = {
                  name: event.tag_name ?? '',
                  boxCount: 0,
                  lastDate: 0,
                  color: event.color ?? ''
                };
              }

              obj[eventKey].boxCount += product.custom
                ? 1
                : product?.quantity ?? 1;
              const dates = Object.keys(
                productsByEventName[eventKey].dates
              ).map((date) => parseInt(date));
              obj[eventKey].lastDate = Math.max(...dates);
            });
          });
        } else {
          if (eventKey !== 'Not related to an event name') {
            obj[eventKey] = {
              name: event.tag_name ?? '',
              boxCount: 0,
              lastDate: 0,
              color: event.color ?? ''
            };
          }
        }

        return obj;
      },
      {}
    );

    setEvents(list);
  }, [productsByEventName, eventList]);

  return (
    <Modal isOpen={open} size="lg" toggle={closeModal}>
      <ModalBody className="p-0">
        <div className="d-flex justify-content-between align-items-center px-4 mt-2">
          <h3 className="h4 w-100 font-weight-bold mt-2">
            Add and Edit event names
          </h3>
          <FontAwesomeIcon
            icon={faTimes}
            className="size-icon"
            onClick={closeModal}
          />
        </div>

        <hr />
        {state === State.PENDING ? (
          <EventListLoading />
        ) : (
          <div className="mb-3">
            {Object.keys(events)
              .sort((a, b) => events[a].name.localeCompare(events[b].name))
              .map((eventKey: string, index: number) => {
                return (
                  <EventEditRowComponent
                    index={index}
                    event={{ ...events[eventKey], eventKey }}
                    key={eventKey}
                  />
                );
              })}
          </div>
        )}

        <div className="border-top px-4 pb-5 pt-2 background-gray">
          <p> Add new project name:</p>
          <div className="d-lg-flex flex-sm-column flex-lg-row align-items-center ">
            <div className="d-flex align-items-center flex-grow-1">
              <ButtonPickerColor
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
              />
              <input
                className="ml-2 form-control"
                placeholder="Type a new event name here"
                maxLength={40}
                disabled={state === State.PENDING}
                ref={eventNameRef}
              />
            </div>

            <div className="flex-grow-1 text-right text-lg-left">
              <button
                className="btn btn-outline-primary ml-lg-3 px-3 mt-3 mt-lg-0"
                disabled={state === State.PENDING}
                onClick={handleNewEvent}>
                {' '}
                {state === State.PENDING && (
                  <FontAwesomeIcon icon={faSpinner} pulse className="mr-2" />
                )}
                Save
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
