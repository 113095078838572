import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../Models/State';
import { EventState } from './Types';
import {
  CreateEventParams,
  EventCartbox,
  UpdateEventParams
} from '../../Models/EventCartbox';
import { Cartbox } from '../../Models/Cartbox';

const { IDLE, PENDING, RESOLVED, REJECTED } = State;

const initialState: EventState = {
  state: IDLE,
  donePopover: false,
  filterPopover: false,
  eventList: [],
  eventName: '',
  filteredSearchEvent: '',
  tableHeaderColor: ''
};

const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    addCartboxEvent(
      state,
      {
        payload
      }: PayloadAction<{ cart_box?: Cartbox; params: CreateEventParams }>
    ) {
      state.state = PENDING;
    },
    getEventList(
      state,
      {
        payload
      }: PayloadAction<{
        customer_key: string;
        cart_box?: Cartbox;
        event_key?: string;
        event_key_list?: string[];
      }>
    ) {
      state.state = PENDING;
    },
    setEventList(
      state,
      { payload }: PayloadAction<{ eventList: EventCartbox[] }>
    ) {
      state.state = RESOLVED;
      state.eventList = [
        ...payload.eventList,
        {
          _KEY: 'Not related to an event name',
          active: true,
          color: '#FFF',
          customer: '',
          tag_name: 'Not related to an event name'
        }
      ];
    },
    setActiveEventStatus(
      state,
      {
        payload
      }: PayloadAction<{
        active: boolean;
        event_key: string;
        cartbox_keys?: string[];
      }>
    ) {
      state.state = PENDING;
    },
    setPopoverStatus(
      state: EventState,
      { payload }: PayloadAction<{ popover: keyof EventState; open: boolean }>
    ) {
      state.state = RESOLVED;
      return { ...state, [payload.popover]: payload.open };
    },
    updateEvent(
      state,
      {
        payload
      }: PayloadAction<{ events: Array<UpdateEventParams>; helperAction?: any }>
    ) {
      state.state = PENDING;
    },
    deleteEvent(state, { payload }: PayloadAction<{ event_key: string }>) {
      state.state = PENDING;
    },
    updateCartboxEvent(
      state,
      { payload }: PayloadAction<{ cart_box: Cartbox; event?: EventCartbox }>
    ) {
      state.state = PENDING;
    },
    setResolved(state) {
      state.state = RESOLVED;
    },
    setRejected(state) {
      state.state = REJECTED;
    },
    setEventName(state, { payload }: PayloadAction<string>) {
      state.eventName = payload;
      if (state.eventName === '') {
        state.filteredSearchEvent = 'clearFilter';
        state.tableHeaderColor = '';
      }
    },
    setFilteredSearchEvent(state, { payload }: PayloadAction<string>) {
      state.filteredSearchEvent = payload;
      state.tableHeaderColor = state.eventName;
      if (state.filteredSearchEvent === 'clearFilter') {
        state.tableHeaderColor = '';
        state.eventName = '';
      }
    }
  }
});

export const eventActions = slice.actions;
export default slice.reducer;
