export const fetchCsRequest = (data: Promise<any>) => {
  return data
    .then((res) => {
      if (res.success) {
        return { success: true, data: res };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
