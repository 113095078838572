import React from 'react';
import UspItem from './UspItem';
import {
  TextDelivered,
  SecurePrices,
  ShopGrowers,
  QualityRating,
  Website
} from './UspContentPopover';
const UspComponent = () => {
  return (
    <div className="d-flex justify-content-between py-2 align-items-center ibf-container-usp">
      <UspItem
        text="Delivered in 24h - 72h"
        content={<TextDelivered />}
        className="custom-step-desktop-popover w-25"
      />
      <UspItem
        text="Secure your prices months ahead"
        content={<SecurePrices />}
        className="custom-step-desktop-popover w-25"
      />
      <UspItem
        text="Shop directly from 100+ farms"
        content={<ShopGrowers />}
        className="custom-step-desktop-popover w-25"
      />
      <UspItem
        text="Quality rating 4,8 / 5"
        content={<QualityRating />}
        className="custom-step-desktop-popover w-25"
      />
      <UspItem
        text="Website updates based on feedback"
        content={<Website />}
        className="custom-step-desktop-popover w-25"
      />
    </div>
  );
};

export default UspComponent;
