import React, { CSSProperties, useEffect, useState } from 'react';
import noImg from '../../../Assets/Images/image-placeholder.png';

type Props = {
  imageUrl: string;
  onErrorUrl?: string;
  imgSize?: string;
  rounded?: boolean;
  width?: string;
  height?: string;
  title?: string;
  className?: string;
  setDetail?: () => void;
  onError?: () => void;
  style?: CSSProperties;
};

const ImageComponent: React.FC<Props> = ({
  imageUrl,
  onErrorUrl,
  imgSize,
  rounded,
  height,
  width,
  title,
  className,
  setDetail,
  style,
  onError
}) => {
  const [url, setUrl] = useState(imageUrl);
  useEffect(() => {
    setUrl(imageUrl);
  }, [imageUrl]);

  return (
    <img
      src={url}
      width={width ? width : imgSize}
      height={height ? height : imgSize}
      alt=""
      style={style}
      title={title}
      onClick={setDetail}
      className={rounded ? 'rounded-circle' : className}
      onError={() => {
        setUrl(onErrorUrl ? onErrorUrl : noImg);

        if (onError) {
          onError();
        }
      }}
    />
  );
};

export default ImageComponent;
