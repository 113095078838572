import { faArrowAltCircleLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Nav, Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { ReactComponent as Logo } from '../../Assets/Images/logo-ibuyflowers.svg';

const BackToShoppingNavBar = () => {
  return (
    <Navbar style={{ height: '46px' }} color="light" light expand="md">
      <div className="container-fluid pl-md-0 pl-lg-2 ml-md-1">
        <NavbarBrand href="/#/home" className="ml-n4 ml-sm-0">
          <Logo height="40" />
        </NavbarBrand>
        <Nav className="ml-lg-auto">
          <NavItem>
            <NavLink href="/#/home" className="ibf-text-gray">
              <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
              Back to shopping
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </Navbar>
  );
};

export default BackToShoppingNavBar;
