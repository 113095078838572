import { Alert } from 'reactstrap';
import React from 'react';

interface Iprops {
  isOpen?: any;
  children: React.ReactNode;
  color: string;
  borderColor: string;
}

export default function AlertComponent({
  isOpen,
  children,
  color,
  borderColor
}: Iprops) {
  return (
    <>
      <Alert
        style={{ backgroundColor: color, borderColor: borderColor }}
        isOpen={isOpen}
        className="my-4 d-flex align-items-center">
        {children && children}
      </Alert>
    </>
  );
}
