import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addObjectParamsToPath,
  hrefContainsProperty
} from '../../../Utils/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { actionsFilter } from '../../../Store/Filter/Slice';
import { RootState } from '../../../Store/Reducers';
import useDeviceDetect from '../../../Hooks/useDeviceDetect';

const CleanSearchButtonComponent = () => {
  const { date } = useSelector((state: RootState) => state.ProductReducer);
  const { search } = useSelector((state: RootState) => state.FilterReducer);
  const { filterLoading } = useSelector(
    (state: RootState) => state.LoadingReducer
  );
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useDeviceDetect();

  const handleClean = async () => {
    if (search.length > 0) {
      if (hrefContainsProperty('search_val')) {
        const params = { date, search_val: '' };
        history.push(addObjectParamsToPath('/search', params));
      } else {
        dispatch(actionsFilter.setSearch({ search: '' }));
      }
    }
  };

  useLayoutEffect(() => {
    setShow(Boolean(search));
  }, [search]);

  return (
    <>
      <button
        style={show ? { borderLeft: 0 } : { borderLeft: 0, zIndex: 1 }}
        className={`btn form-control ${isMobile ? 'border-0' : ''}`}
        type="button"
        onMouseDown={(e) => {
          e.preventDefault();
          if (show && !filterLoading) {
            handleClean();
          }
        }}>
        {show && <FontAwesomeIcon icon={faTimesCircle} />}
      </button>
    </>
  );
};

export default CleanSearchButtonComponent;
