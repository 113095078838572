import React, { useState } from 'react';
import { Form, Input, InputGroup, InputGroupAddon, Modal } from 'reactstrap';
import { Button, Popover } from 'antd';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import modalFooter from '../../../../../Assets/Images/modal-footer.png';
import { useSelector } from 'react-redux';
import { hrefToObject } from '../../../../../Utils/Utils';
import { modalReducer } from '../../../../../Store/Selectors';
import MessageForgotPassword from './MessageForgotPassword';
import { getCookie } from '../../../../../Services/AuthService';
import BoxLoadingSpinner from '../../../../CustomBoxModal/BoxProductsComponent/BoxLoadingSpinner';

type Props = {
  loginVisible: boolean;
  loadingVerification: boolean;
  email: string;
  handleChangeEmail: any;
  handleChangePassword: any;
  setPasswordVisibility: any;
  password: string;
  passwordVisibility: boolean;
  invalidInput: boolean;
  verifyEmail: () => void;
  loginSubmit: any;
  invalidPassword: boolean;
  loadingLogin: boolean;
  forgotPassword: () => void;
  isNewEmail: boolean;
  isNewGuest: { active: boolean; date: string };
  continueAsNewCustomer: () => void;
  headerModalText: JSX.Element;
  back: () => void;
  setStepsForgetPassword: () => void;
  stepsForgetPassword: boolean;
  validateMessageForgotPassword?: string;
  innerRef: React.Ref<HTMLInputElement>;
};

export const referralPopoverText = (
  name: string = '',
  referEmail: string = ''
) => (
  <span className="color-black">
    Yes! Your friend <b>{name}</b> arranged a 15% discount on your first order
    at iBuyFlowers. Simply use the email address {referEmail} and hit "Start
    shopping".
  </span>
);

const WelcomeModal: React.FC<Props> = ({
  loginVisible,
  loadingVerification,
  email,
  handleChangeEmail,
  password,
  handleChangePassword,
  passwordVisibility,
  setPasswordVisibility,
  invalidInput,
  loginSubmit,
  verifyEmail,
  invalidPassword,
  loadingLogin,
  forgotPassword,
  isNewEmail,
  isNewGuest,
  continueAsNewCustomer,
  headerModalText,
  back,
  setStepsForgetPassword,
  stepsForgetPassword,
  validateMessageForgotPassword,
  innerRef
}) => {
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      !loginVisible && verifyEmail();
      loginVisible && loginSubmit();
    }
  };
  const [showMessage, setShowMessage] = useState(false);

  const { SIGN_IN_FLOW } = useSelector(modalReducer);
  const {
    referral_code,
    customer,
    email: referEmail
  }: { [key: string]: any } = hrefToObject();

  return (
    <Modal className="ibf-login-modal-main" isOpen={!SIGN_IN_FLOW.open}>
      <div className="ibf-login-modal-home">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          <div className="ibf-login-modal-top-info">
            <div className="ibf-login-modal-home-title mb-3">
              <span className="font-weight-bold">
                Welcome to iBuyFlowers.com
              </span>
            </div>
            <div className="ibf-login-modal-home-description">
              {headerModalText}
            </div>
          </div>

          <div className="ibf-login-modal-content">
            <div
              className={`ibf-login-modal-home-body ${
                loginVisible ? '' : 'text-center'
              }`}>
              {!isNewGuest.active && (
                <Popover
                  visible={!SIGN_IN_FLOW.open && referral_code?.length > 0}
                  overlayClassName={'ibf-popover-referral'}
                  placement={'top'}
                  content={referralPopoverText(customer, referEmail)}>
                  <div className="mb-3 ibf-login-modal-home-description">
                    <b>Start shopping: </b>
                  </div>
                </Popover>
              )}
              {!isNewGuest.active && (
                <div className="mb-4">
                  <Input
                    defaultValue={email || getCookie('email') || ''}
                    id="email"
                    onKeyDown={handleKeyDown}
                    invalid={invalidInput}
                    className="text-center ibf-login-modal-home-input ibf-border-pink"
                    placeholder="Use your (flower company) email address"
                    onChange={(e: any) => {
                      const text = e.target.value.replace(/\s/g, '');
                      handleChangeEmail(text);
                    }}
                    type="text"
                  />
                </div>
              )}

              {invalidInput && (
                <div className="ibf-login-modal-invalid-input">
                  <b>Sorry, your email doesn't seem right</b>
                </div>
              )}

              {loginVisible && (
                <div className="ibf-login-modal-password-input">
                  <InputGroup>
                    <Input
                      value={password}
                      onKeyDown={handleKeyDown}
                      invalid={invalidPassword}
                      placeholder="Password"
                      innerRef={innerRef}
                      onChange={(e: any) => handleChangePassword(e)}
                      type={passwordVisibility ? 'text' : 'password'}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        onClick={() =>
                          setPasswordVisibility(!passwordVisibility)
                        }>
                        {passwordVisibility ? 'Hide' : 'Show'}
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>

                  {validateMessageForgotPassword === 'show error' && (
                    <div className="ibf-login-modal-invalid-input">
                      <MessageForgotPassword />
                    </div>
                  )}
                </div>
              )}

              {loginVisible && (
                <div className="d-block mb-3">
                  <span
                    className="ibf-login-modal-forgot-msg font-weight-light"
                    onClick={() => setStepsForgetPassword()}>
                    {!invalidPassword && (
                      <span className="d-block">Forgot password?</span>
                    )}
                  </span>
                  {(stepsForgetPassword || invalidPassword) && (
                    <>
                      <ul className={`p-0`}>
                        <span className="d-block font-weight-bold">
                          Please follow these steps:
                        </span>
                        <div className="d-flex">
                          <li className="list-unstyled">
                            Step 1: Click on the button “Show” behind the
                            password field to check if you mistyped your
                            password. If so, retype your correct password. If
                            not, proceed to step 2.
                          </li>
                        </div>
                        <div className="d-flex">
                          <li className="list-unstyled mt-2">
                            Step 2: Open your email program and search for
                            “iBuyFlowers login credentials”. Check if you are
                            using the exact same email address and password to
                            login today (you might have created multiple
                            iBuyFlowers accounts in the past with different
                            passwords). Still issues? Go to step 3.
                          </li>
                        </div>
                        <div className="d-flex">
                          <li className="list-unstyled mt-2">
                            Step 3: If you used the correct email address, then
                            click on the button to create a new password in
                            order to login:
                          </li>
                        </div>
                      </ul>
                    </>
                  )}
                </div>
              )}

              <div className="ibf-login-modal-continue-btn ">
                {!loginVisible &&
                  (isNewEmail ? (
                    isNewGuest.active ? (
                      <div className="text-left ">
                        <h5 className="ibf-text-pink mb-4">
                          <b>
                            Welcome back <br />
                            {email || getCookie('email')}
                          </b>
                        </h5>

                        <h5 className="ibf-text-pink mb-4">
                          To login with this email address, we need you to click
                          on the link in the email we sent you (on{' '}
                          {isNewGuest.date} with the subject line "Start
                          shopping with your free guest account"). Open the
                          email and click on "Start shopping”.
                        </h5>
                        {/*<h5 className="ibf-text-pink mb-5">*/}
                        {/*  Open the email with the subjectline "Start shopping with your free guest account" (sent {isNewGuest.date}). Then click on "start shopping".*/}
                        {/*</h5>*/}

                        <p style={{ fontSize: '0.8em', lineHeight: '1.17em' }}>
                          Can't find the email? Then we need you to{' '}
                          <u
                            className="pointer"
                            onClick={continueAsNewCustomer}>
                            create another new guest account with another email
                            address
                          </u>
                          , since we want to make sure that nobody else
                          uses&nbsp;
                          {email || getCookie('email')}
                        </p>
                      </div>
                    ) : (
                      <>
                        <h5 className="ibf-text-pink mb-3 text-left">
                          <b>This email address is new to us... Are you new?</b>
                        </h5>

                        {loadingVerification ? (
                          <BoxLoadingSpinner />
                        ) : (
                          <div
                            className=" text-left pointer"
                            onClick={() => setShowMessage(true)}>
                            <p
                              className="ibf-text-pink mb-3 text-left"
                              style={{ fontSize: '1.2em' }}>
                              {' '}
                              <u>No, I'm an existing iBuyFlowers customer</u>
                            </p>
                          </div>
                        )}

                        {showMessage && (
                          <div className="mb-3 text-left">
                            Sorry, we couldn’t find your account with that email
                            address. If you didn’t mistype your email address,
                            please contact us at{' '}
                            <a className="ibf-link" href="tel:+18444289100">
                              +1 844 428 9100
                            </a>{' '}
                            (Mon - Fri 9AM - 5PM CST) or{' '}
                            <a
                              className="ibf-link"
                              href="mailto:support@ibuyflowers.com">
                              support@ibuyflowers.com
                            </a>
                          </div>
                        )}

                        {loadingVerification ? (
                          <BoxLoadingSpinner />
                        ) : (
                          <div
                            className="text-left pointer"
                            onClick={continueAsNewCustomer}>
                            <p
                              className="ibf-text-pink mb-3 text-left"
                              style={{ fontSize: '1.2em' }}>
                              <u>Yes, I'm new! Get me started under 1 minute</u>
                            </p>
                          </div>
                        )}
                      </>
                    )
                  ) : (
                    <Button
                      disabled={loadingVerification}
                      onClick={verifyEmail}>
                      {loadingVerification && (
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Start shopping &#187;
                    </Button>
                  ))}

                {/*!loginVisible && (
                  <div className="ibf-login-modal-hr">
                    <hr/>
                  </div>
                )*/}

                {loginVisible && !invalidPassword && (
                  <Button disabled={loadingLogin} onClick={loginSubmit}>
                    {loadingLogin && (
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    <FontAwesomeIcon className="mr-2" icon={faLock} />
                    Login
                  </Button>
                )}
                {invalidPassword && (
                  <Button onClick={forgotPassword}>
                    Create a new password
                  </Button>
                )}
              </div>
              <div className="d-flex justify-content-start  pointer pt-4">
                <span className="text-primary underline" onClick={back}>
                  {'< Back'}
                </span>
              </div>
              {/*!loginVisible && (
                <div>
                  <span
                    onClick={() => {
                      setOptimisedModal(true);
                    }}
                    className="ibf-login-modal-bottom-msg">
                    Learn about our (optimised) website
                  </span>
                </div>
                  )*/}
            </div>
            <div className="ibf-login-modal-bottom-img">
              <img src={modalFooter} width="100%" alt="" />
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
