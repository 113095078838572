import React from 'react';
import ContactComponent from '../../Shared/ContactComponent/ContactComponent';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/pro-duotone-svg-icons';
import { RootState } from '../../../Store/Reducers';
import { HOW_TO_USE_MODAL } from '../../../Store/Modal/Types';
import { actionsModal } from '../../../Store/Modal/Slice';
import { actionsDisplay } from '../../../Store/Display/Slice';
import Skeleton from 'react-loading-skeleton';

const HomeInformationComponent = () => {
  const dispatch = useDispatch();
  const { name, email, metadata } = useSelector(
    (state: RootState) => state.AuthReducer.user
  );
  const { firstTip } = useSelector((state: RootState) => state.DisplayReducer);
  const { authLoading } = useSelector(
    (store: RootState) => store.LoadingReducer
  );
  const homePopover = (
    <div className="d-flex justify-content-center align-items-center">
      <Button
        onClick={() => {
          dispatch(actionsModal.setOpenModal({ modal: HOW_TO_USE_MODAL }));
          dispatch(actionsDisplay.setOpenFirstTip());
        }}>
        <span>
          <FontAwesomeIcon color="white" icon={faPlayCircle} />
        </span>
        <span>See how it works (video) &#187;</span>
        <span />
      </Button>
    </div>
  );

  return (
    <div className={`ibf-hi-container ${authLoading ? 'bg-white' : ''}`}>
      <div className="ibf-hi-contact-desktop">
        <ContactComponent />
      </div>

      <div className="ibf-hi-description">
        <div className="ibf-hi-title">
          <span className="ibf-hi-main-title">
            {authLoading ? (
              <Skeleton width={650} />
            ) : (
              <>
                Hello {metadata && !metadata.guest}{' '}
                {metadata.guest
                  ? metadata?.main_user_email ?? email
                  : name
                  ? name +
                    (email.indexOf('@ibuyflowers.com') !== -1 &&
                    metadata?.customer_name
                      ? ` (${metadata.customer_name})`
                      : '')
                  : 'You'}
              </>
            )}
          </span>
          <div className="ibf-hi-contact-mobile">
            <ContactComponent />
          </div>
        </div>
        <div className="ibf-hi-text">
          {authLoading ? (
            <Skeleton width={700} />
          ) : (
            <span>Source fresh flowers directly from growers</span>
          )}
        </div>
      </div>

      {firstTip && !authLoading && (
        <div className="ibf-hi-popover">
          <Popover
            placement={'bottom'}
            overlayClassName="ibf-home-info-popover"
            content={homePopover}
            visible={firstTip}>
            <span className="">ibf</span>
          </Popover>
        </div>
      )}

      {!firstTip && !authLoading && (
        <div className="ibf-hi-title-btn">
          <Button
            onClick={() => {
              dispatch(actionsModal.setOpenModal({ modal: HOW_TO_USE_MODAL }));
            }}>
            See how it works (video) &#187;
          </Button>
        </div>
      )}
    </div>
  );
};

export default HomeInformationComponent;
