import React from 'react';

type Props = {
  checked?: boolean;
  id?: string;
  name?: string;
  htmlFor?: string;
  text?: string;
  className?: string;
  classNameLabel?: string;
  type?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};
const InputSelectedStatements = ({
  type,
  className,
  id,
  name,
  checked,
  onChange,
  value,
  classNameLabel,
  htmlFor,
  text
}: Props) => {
  return (
    <div className="custom-control custom-radio mr-3">
      <input
        type={type}
        className={className}
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
      />
      <label className={classNameLabel} htmlFor={htmlFor}>
        {text}
      </label>
    </div>
  );
};

export default InputSelectedStatements;
