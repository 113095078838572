import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryList, shouldAddIt, toast } from '../../../../Utils/Utils';
import {
  changeCustomerGrowerRelationship,
  getCustomerGrower
} from '../../../../Services/UserService';
import { Grower } from '../../../../Models/Grower';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { RootState } from '../../../../Store/Reducers';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { MANAGE_GROWERS_MODAL } from '../../../../Store/Modal/Types';

type Props = {
  className?: string;
};

const ManageGrowersModal: React.FC<Props> = ({ className }) => {
  const { manageGrowersModal } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const dispatch = useDispatch();
  const toggle = () =>
    dispatch(actionsModal.setCloseModal({ modal: MANAGE_GROWERS_MODAL }));
  const [state, setState] = useState({
    filterGrower: '',
    filterCountry: '',
    filterStatus: ''
  });
  const { user } = useSelector((state: RootState) => state.AuthReducer);
  const [relations, setRelations] = useState<Array<any>>([]);
  const customer = user.metadata.customer;

  const filterTable = useCallback(
    (relations: Array<Grower>) => {
      let response: Array<Grower> = [];
      relations.forEach((item: any) => {
        if (shouldAddIt(item, state)) {
          response.push(item);
        }
      });
      return response;
    },
    [state]
  );

  const getGrowers = useCallback(async () => {
    await getCustomerGrower(customer, 'customer').then((res) => {
      const filterRelations = filterTable(res.relations);
      setRelations(filterRelations);
    });
  }, [customer, filterTable]);

  useEffect(() => {
    getGrowers();
  }, [getGrowers]);

  const handleGrower = (event: React.FormEvent<HTMLInputElement>) => {
    setState({ ...state, filterGrower: event.currentTarget.value });
  };

  const handleCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setState({ ...state, filterCountry: event.currentTarget.value });
  };

  const handleStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setState({ ...state, filterStatus: event.currentTarget.value });
  };

  const resetFiltersHandler = () => {
    setState({ filterGrower: '', filterCountry: '', filterStatus: '' });
  };

  const getButtonAction = (relation: Grower) => {
    if (relation.blocked) {
      return (
        <td className="text-center">
          <button
            className="btn btn-success btn-sm w-75"
            type="button"
            onClick={() => handleButtonAction(relation)}>
            <FontAwesomeIcon icon={faCheck} /> Activate
          </button>
        </td>
      );
    } else {
      return (
        <td className="text-center">
          <button
            className="btn btn-danger btn-sm w-75"
            type="button"
            onClick={() => handleButtonAction(relation)}>
            <FontAwesomeIcon icon={faTimes} /> Block
          </button>
        </td>
      );
    }
  };

  const handleButtonAction = (relation: any) => {
    let action = relation.blocked ? 'allow' : 'block';
    changeCustomerGrowerRelationship(
      action,
      customer,
      relation.grower._KEY,
      'customer'
    ).then(() => {
      // update list
      getGrowers();

      // visual feedback
      let message = relation.grower.company_name + ' has been ';
      message += relation.blocked ? 'activated' : 'blocked';
      toast(message);
    });
  };

  const getFiltersHeader = (source: any) => {
    return (
      <div className="action-panel row">
        <div className="col-3">
          <div className="form-group">
            <label>Grower</label>
            <input
              className="form-control input-sm"
              type="text"
              onChange={handleGrower}
              value={state.filterGrower}
            />
          </div>
        </div>

        <div className="col-3">
          <div className="form-group">
            <label>Country</label>
            <select
              className="form-control input-sm"
              onChange={handleCountry}
              value={state.filterCountry}>
              <option value="">All Countries</option>
              {getCountryList(source).map((item: any) => {
                return (
                  <option key={item._KEY} value={item._KEY}>
                    {item.countryLabel}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>Status</label>
            <select
              className="form-control input-sm"
              onChange={handleStatus}
              value={state.filterStatus}>
              <option value="">All</option>
              <option value="true">Blocked</option>
              <option value="false">Active</option>
            </select>
          </div>
        </div>

        <div className="col-3 align-self-center pt-0 pt-md-2">
          <button
            className="btn btn-sm btn-primary"
            onClick={resetFiltersHandler}>
            <i className="fa fa-fw fa-undo" /> Reset filter
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        size="lg"
        isOpen={manageGrowersModal.open}
        toggle={toggle}
        className={className}>
        <ModalHeader toggle={toggle}>Manage growers</ModalHeader>
        <ModalBody>
          {/*table*/}
          <div className="info-content mb-3">
            {getFiltersHeader(relations)}
            <div className="card">
              <table className="table table-bordered m-0">
                <thead>
                  <tr>
                    <th>Grower</th>
                    <th>Country</th>
                    <th>Status</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {relations.map((item, index) => {
                    const { company_name, country, city } = item.grower;
                    return (
                      <tr key={index}>
                        <td>
                          <b>{company_name}</b>
                        </td>
                        <td>
                          <img
                            className="country_flag"
                            src={`Assets/Images/${country.country_iso}_flag.svg`}
                            alt=""
                          />
                          {country.country} - {city}
                        </td>
                        <td>
                          <span>{item.blocked ? 'Blocked' : 'Active'}</span>
                        </td>

                        {getButtonAction(item)}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ManageGrowersModal;
