import ProductGroup from './ProductGroup';
import ProductColor from './ProductColor';
import Growers from './Growers';
import GrownInMonth from './GrownInMonth';
import { GrowerPricing } from './GrowerPricing';
import { Grower } from './Grower';

export default class Product {
  name!: string;
  key!: string;
  photos: Array<string> = [];
  weeks!: string;
  isAvailable!: boolean;
  product_group?: ProductGroup;
  category?: any;
  color?: ProductColor;
  grower?: string;
  growers?: Array<Growers>;
  delivery_date?: number;
  base_price?: number;
  starting_price?: number;
  grown_in_month?: GrownInMonth;
  box_only?: boolean;
  lengths?: string;
  has_substitute?: boolean;
  pricing?: { [grower: string]: GrowerPricing } | undefined;
  customizable?: boolean;
  images?: string[];
  favorite_key?: string;
  customer?: string;
  // getImage(): string {
  //   return `${Environment.publicPath}${this.img}`;
  // }
}

export interface IncompleteProduct {
  deliveryDate: number;
  date1: number;
  date2: number;
  sl_consecutive: number;
  productGroup: string;
  variety: string;
  quantity: number;
  label_quantity: string;
  shoppingList: string;
  length: number;
  shoppingListItem: string;
  grower?: Grower;
  box_only: boolean;
  orderRequest?: string;
  name: string;
}

export type TotalProductByDate = {
  [eta: number]: number;
};

export interface DeleteProduct {
  _KEY: string;
  product_name: string;
  quantity_info: string;
  picture: string;
  eta_date: number;
}

export interface ResSearchInventory {
  success?: boolean;
  response: {
    products: Array<Product>;
  };
}
