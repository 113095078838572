import * as ModalTypes from './Types';
import { ModalState } from './Types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalRedux } from '../../Models/ModalRedux';

const initialState: ModalState = {
  [ModalTypes.COMPANY_INFORMATION_MODAL]: {
    open: false
  },
  [ModalTypes.USERS_LIST_MODAL]: {
    open: false
  },
  [ModalTypes.ADD_USER_MODAL]: {
    open: false
  },
  [ModalTypes.PAYMENT_LIST_MODAL]: {
    open: false
  },
  [ModalTypes.PAYMENT_MODAL]: {
    open: false
  },
  [ModalTypes.CONFIRM_MODAL]: {
    open: false
  },
  [ModalTypes.CONFIRM_ZIPCODE_MODAL]: {
    open: false
  },
  [ModalTypes.MANAGE_GROWERS_MODAL]: {
    open: false
  },
  [ModalTypes.DELIVERY_SCHEDULE_MODAL]: {
    open: false
  },
  [ModalTypes.SHIPPING_DETAILS]: {
    open: false
  },
  [ModalTypes.ACCOUNT_MANAGER_MODAL]: {
    open: false
  },
  [ModalTypes.GROWER_INFO_MODAL]: {
    open: false
  },
  [ModalTypes.COMPANY_INFORMATION_MODAL]: {
    open: false
  },
  [ModalTypes.EDIT_COMPANY_INFORMATION_MODAL]: {
    open: false
  },
  [ModalTypes.REFERRAL_MODAL]: {
    open: false
  },
  [ModalTypes.GREETING_MODAL]: {
    open: false
  },
  [ModalTypes.HOW_TO_USE_MODAL]: {
    open: false
  },
  [ModalTypes.CONFIRM_DELIVERY_DATE_MODAL]: {
    open: false
  },
  [ModalTypes.CUSTOM_BOX_MODAL]: {
    open: false
  },
  [ModalTypes.CART_MODAL]: {
    open: false
  },
  [ModalTypes.CELLPHONE_MODAL]: {
    open: false
  },
  [ModalTypes.REPLACEMENTS_MODAL]: {
    open: false
  },
  [ModalTypes.CONFIRMATION_CHECKOUT_MODAL]: {
    open: false
  },
  [ModalTypes.SIGN_UP_MODAL]: {
    open: false
  },
  [ModalTypes.SIGN_IN_FLOW]: {
    open: false
  },
  [ModalTypes.CHANGE_EMAIL_MODAL]: {
    open: false
  },
  [ModalTypes.CHANGE_PASSWORD_MODAL]: {
    open: false
  },
  [ModalTypes.EVENT_NAME_MODAL]: {
    open: false
  },
  [ModalTypes.EVENT_MODAL_DONE]: {
    open: false
  },
  [ModalTypes.ADD_EDIT_EVENT_NAME]: {
    open: false
  },
  [ModalTypes.CONFIRM_PROFILE_MODAL]: {
    open: false
  },
  [ModalTypes.MESSAGE_MODAL]: {
    open: false
  },
  [ModalTypes.MESSAGE_COOKIE_MODAL]: {
    open: false
  },
  [ModalTypes.ADDRESSES_MODAL]: {
    open: false
  },
  [ModalTypes.GUEST_USER_NAME_CHANGE]: {
    open: false
  },
  [ModalTypes.STANDING_ORDER_CREATION_MODAL]: {
    open: false
  },
  [ModalTypes.STANDING_ORDER_DONE]: {
    open: false
  }
};

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setOpenModal(state: any, action: PayloadAction<ModalRedux>) {
      if (action.payload.modal) {
        state[action.payload.modal].open = true;
        state[action.payload.modal].data =
          action.payload.data !== undefined ? action.payload.data : null;
        state[action.payload.modal].result = null;
      }
    },
    setCloseModal(state: any, action: PayloadAction<ModalRedux>) {
      if (action.payload.modal) {
        state[action.payload.modal].open = false;
        state[action.payload.modal].data = null;
        state[action.payload.modal].result =
          action.payload.data !== undefined ? action.payload.data : null;
      }
    },
    setCleanModal(state: any, action: PayloadAction<ModalRedux>) {
      if (action.payload.modal) {
        state[action.payload.modal].open = false;
        state[action.payload.modal].data = null;
        state[action.payload.modal].result = null;
      }
    },
    setCleanAllModals() {
      return initialState;
    }
  }
});

export const actionsModal = slice.actions;
export default slice.reducer;
