import React, { ChangeEvent, useEffect, useState } from 'react';
import { getBunchesAvailable } from '../../../Utils/Utils';

type Props = {
  item: any;
  boxPercentage: number;
  handleQuantity: (quantity: number, cartboxItem?: any) => void;
};

const ProductOptionsForm: React.FC<Props> = ({
  item,
  handleQuantity,
  boxPercentage
}) => {
  const percentage = item?.percentage;
  const stems_per_bunch = item?.stems_per_bunch?.stems_per_bunch;
  const available = item?.inventory?.quantity;

  const [bunchesAvailable, setBunchesAvailable] = useState(0);

  useEffect(() => {
    setBunchesAvailable(
      getBunchesAvailable(available, percentage, boxPercentage)
    );
  }, [available, percentage, item.quantity, boxPercentage]);

  useEffect(() => {
    const bunch_list: HTMLSelectElement | any = document.getElementById(
      'bunch_list_' + item._KEY
    );

    if (bunch_list) {
      bunch_list.selectedIndex = item?.quantity > 1 ? item?.quantity : 1;
    }
  }, [item]);

  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const quantity = parseInt(event.target.value);
    handleQuantity(quantity, item);
  };

  return (
    <div className="ibf-product-ready-form-input">
      <div className="select">
        <select
          id={'bunch_list_' + item._KEY}
          onChange={handleSelect}
          style={{ width: '90px', height: '31.05px' }}>
          <option key={0} value="0">
            # Bunches
          </option>
          {Array(bunchesAvailable + item?.quantity)
            .fill(1)
            .map((i, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1 + `${index + 1 > 1 ? ' Bunches' : ' Bunch'}`} (
                {(index + 1) * stems_per_bunch +
                  `${(index + 1) * stems_per_bunch > 1 ? ' stems' : ' stem'}`}
                )
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default ProductOptionsForm;
