import React from 'react';
import {
  convertNumberDateToDate,
  getWeeksOfYear
} from '../../../../../Utils/Utils';
import WeekList from './WeekList';
import Product from '../../../../../Models/Product';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../Store/Reducers';
import { actionsModal } from '../../../../../Store/Modal/Slice';
import { CONFIRM_DELIVERY_DATE_MODAL } from '../../../../../Store/Modal/Types';

const GrowInWeekComponent: React.FC<{ product: Product }> = ({ product }) => {
  const { date } = useSelector((state: RootState) => state.ProductReducer);
  const newDate = new Date(convertNumberDateToDate(date));
  const weeksYears = getWeeksOfYear(newDate.getFullYear());
  const weeksNextYear = getWeeksOfYear(newDate.getFullYear() + 1);
  // const [openDeliveryDateModal, setOpenDeliveryDateModal] = useState(false);
  // const toggle = () => setOpenDeliveryDateModal(!openDeliveryDateModal);
  const dispatch = useDispatch();
  const toggle = () => {
    dispatch(
      actionsModal.setOpenModal({
        modal: CONFIRM_DELIVERY_DATE_MODAL,
        data: { closeButton: true, isProductDetailView: true }
      })
    );
  };

  return (
    <div
      className="d-flex flex-column align-items-center align-items-lg-start justify-content-lg-start mb-2 mt-4 mt-lg-0"
      onClick={toggle}>
      <div className="">
        <b>Availability per week</b>
        <span className="">
          (may fluctuate due to severe weather circumstances)
        </span>
      </div>
      <div className="d-flex flex-wrap flex-md-nowrap justify-content-center">
        <WeekList
          product={product}
          list={weeksYears}
          weeksNextYear={weeksNextYear}
        />
      </div>
      {/*<DeliveryDateModal*/}
      {/*  openDeliveryDateModal={openDeliveryDateModal}*/}
      {/*  toggleDeliveryDateModal={toggle}*/}
      {/*  className="ibf-delivery-date-modal"*/}
      {/*/>*/}
    </div>
  );
};

export default GrowInWeekComponent;
