import React from 'react';

interface Props {
  item: string;
  id: string;
}

export const ListItemD = ({ item, id }: Props) => {
  return (
    <li>
      <input type="checkbox" id={id} />
      <label htmlFor={id}>{item}</label>
    </li>
  );
};
