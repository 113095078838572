import React from 'react';
import { Popover } from 'antd';

type Props = {
  handleVisibleChange: () => void;
  closeModal: () => void;
  visible: boolean;
  children: React.ReactNode;
};

export default function PopoverGrowerNotification({
  handleVisibleChange,
  closeModal,
  visible,
  children
}: Props) {
  return (
    <Popover
      placement={'bottomRight'}
      trigger="click"
      visible={visible}
      onVisibleChange={() => handleVisibleChange()}
      overlayClassName={`size-popover custom-step-desktop-popover`}
      overlayStyle={{ zIndex: 1500 }}
      content={
        <div className="px-2">
          <span className="d-block text-black">Grower notification:</span>
          <span className="d-block text-black">
            Please fill this box at least 50%: too much empty space in the box
            will cause flowers shifting during transport causing damage.
          </span>
          <div className="d-flex flex-column align-items-center mt-3">
            <button
              className="btn btn-primary border-dark"
              onClick={() => handleVisibleChange()}>
              Ok, I’ll continue filling
            </button>
            <span
              className="d-block underline pointer text-black mt-1"
              onClick={closeModal}>
              No, close anyway for now
            </span>
          </div>
        </div>
      }>
      {children}
    </Popover>
  );
}
