import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { MESSAGE_MODAL } from '../../../../Store/Modal/Types';
import { modalReducer } from '../../../../Store/Selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
type Props = {
  body?: string;
  className?: string;
  size?: string;
  classNameParagraph?: string;
  bodyClassName?: string;
  buttonEnabled?: boolean;
  buttonClassName?: string;
  textButton?: string;
  text?: string;
  classNameText?: string;
  onClick: (value: string) => void;
  buttonClose?: boolean;
  textClose?: string;
  buttonClassClose?: string;
  cancelAction: (value: string) => void;
  iconCloseEnabled?: boolean;
  contentClassButton?: string;
};
const MessageModal = ({
  size,
  className,
  bodyClassName,
  body,
  classNameParagraph,
  buttonEnabled,
  buttonClassName,
  classNameText,
  textButton,
  text,
  onClick,
  buttonClose,
  textClose,
  buttonClassClose,
  cancelAction,
  iconCloseEnabled,
  contentClassButton
}: Props) => {
  const MODAL = useSelector(modalReducer);
  const data = MODAL.MESSAGE_MODAL.data ? MODAL.MESSAGE_MODAL.data : '';
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(actionsModal.setCloseModal({ modal: MESSAGE_MODAL, data: data }));
    onClick('');
  };
  const closeModal = () => {
    dispatch(actionsModal.setCloseModal({ modal: MESSAGE_MODAL, data: data }));
    cancelAction('');
  };

  return (
    <Modal
      isOpen={MODAL.MESSAGE_MODAL.open}
      toggle={onClose}
      className={className ?? ''}
      size={size}
      centered={true}>
      <ModalBody className={bodyClassName ?? ''}>
        <div className="d-flex justify-content-between">
          <p className={classNameParagraph ?? ''}>{body && body}</p>
          {iconCloseEnabled && (
            <FontAwesomeIcon
              className={'pointer mt-n1'}
              onClick={closeModal}
              icon={faTimes}
              size={'2x'}
            />
          )}
        </div>

        <span className={classNameText ?? ''}>{text}</span>
        {buttonEnabled && (
          <span className={buttonClassName ?? ''} onClick={onClose}>
            {textButton}
          </span>
        )}
        {buttonClose && (
          <span className={contentClassButton ?? ''}>
            <span className={buttonClassClose ?? ''} onClick={closeModal}>
              {textClose}
            </span>
          </span>
        )}
      </ModalBody>
    </Modal>
  );
};

export default MessageModal;
