import { OrderItem } from './../../Models/Order';
import { State } from '../../Models/State';
export const GET_STATEMENTS = 'statements/getStatements';

export interface statementState {
  statements: Array<OrderItem>;
  state: State;
  selectedValue: string;
  statementLoaded?: boolean;
  statementsSince: Date;
}
