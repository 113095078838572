import React from 'react';
import { FilterSearch } from '../NavBarComponent/SearchInputComponent/SuggestionListComponent';
import useDeviceDetect from '../../Hooks/useDeviceDetect';

type Props = {
  suggestion: FilterSearch | JSX.Element;
  submitSuggestion: () => void;
  width: number | string;
};

const getTextForSuggestion = (
  suggestionTerm: FilterSearch,
  width: number | string
) => {
  const str = suggestionTerm.search;
  const regex = new RegExp(
    '(\\(Variety\\)|\\(Category\\)|\\(Product group\\))'
  );
  let [suggestion, type] = str.split(regex);

  const typeC = type?.toLowerCase() ?? '';

  return (
    <span className="d-flex align-items-center">
      <span
        className="mr-1 "
        style={{
          display: 'inline-block',
          maxWidth: typeof width === 'string' ? width : width - 100,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
        {suggestion ?? ''}
      </span>
      <span
        className={`${
          typeC.includes('variety') ||
          typeC.includes('product') ||
          typeC.includes('category')
            ? 'ibf-text-gray-cart'
            : 'd-none'
        }`}>
        {type ?? ''}
      </span>
    </span>
  );
};

export const AutoSuggestItem = ({
  suggestion,
  submitSuggestion,
  width
}: Props) => {
  const { isMobile } = useDeviceDetect();
  return (
    <li
      className={`suggested-item px-${isMobile ? '1' : '3'} py-2 mr-1`}
      key={
        (suggestion as FilterSearch).search
          ? (suggestion as FilterSearch).search
          : 'link-external'
      }
      onMouseDown={submitSuggestion}>
      {(suggestion as FilterSearch)?.search
        ? getTextForSuggestion(suggestion as FilterSearch, width)
        : suggestion}
    </li>
  );
};
