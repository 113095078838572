import React, { useState, Fragment } from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import StoreType from '../../../../Models/Redux/Store';
import * as ModalTypes from '../../../../Store/Modal/Types';
import { onlyNumbers, toast } from '../../../../Utils/Utils';
import {
  updateSMSConfig,
  getVerificationCode
} from '../../../../Services/AuthService';
import LoadingSpinner from '../../LoadingSpinner';
import ReactInputVerificationCode from 'react-input-verification-code';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { actionsAuth } from '../../../../Store/Auth/Slice';

const CellphoneModal = () => {
  const { CELLPHONE_MODAL } = useSelector(
    (store: StoreType) => store.ModalReducer
  );

  const [phone, setPhone] = useState('');
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const {
    user: { metadata: customer },
    smsPreferencesKey,
    verificationCode
  } = useSelector((state: StoreType) => state.AuthReducer);
  const dispatch = useDispatch();

  const onClose = () =>
    dispatch(actionsModal.setCloseModal({ modal: ModalTypes.CELLPHONE_MODAL }));

  const fetchNewVerificationCode = async () => {
    if (loading) {
      return;
    }
    if (phone.length !== 10) {
      return toast('please enter a valid cellphone number', 'error');
    }
    setLoading(true);
    const res = await getVerificationCode({
      customer: customer.customer,
      cellphone: phone
    });
    setLoading(false);
    if (res.success) {
      dispatch(
        actionsAuth.newVerificationCode({ code: res.code, key: res.key })
      );
      setStep(2);
    }
  };

  const redoVerificationCode = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await getVerificationCode({
      key: smsPreferencesKey,
      cellphone: phone
    });
    setLoading(false);
    toast('A new verification code was sent');
    if (res.response.body.success) {
      dispatch(
        actionsAuth.newVerificationCode({
          code: res.response.body.code,
          key: smsPreferencesKey
        })
      );
    }
  };

  let render;
  switch (step) {
    case 1:
      render = (
        <>
          <ModalHeader toggle={onClose} className="border-0">
            <span className="font-weight-bold">
              Receive updates via Text messages on your mobile (for free)
            </span>
          </ModalHeader>
          <ModalBody className="d-flex flex-column">
            <span>
              Relevant changes by FedEx or growers, as updates from
              iBuyFlowers.com will be texted (for free and won't affect
              receiving your normal emails). You can always change your mobile
              number, or stop receiving text messages via My Account.
            </span>
            <div className="d-flex flex-column flex-md-row mt-3 justify-content-between">
              <div className="col-8 col-md-5 p-0 pr-1">
                <Input
                  placeholder="Type in your cell number"
                  type={'text'}
                  value={phone || ''}
                  inputMode="numeric"
                  onChange={(evt) => {
                    const value = evt.currentTarget.value;
                    if (value.length <= 10) {
                      setPhone(onlyNumbers(value).toString());
                    }
                  }}
                />
              </div>
              <div className="col-md-7 p-0 mt-2 mt-md-0">
                <button
                  className="btn btn-primary w-100"
                  style={{
                    backgroundColor: 'deepskyblue',
                    borderColor: 'deepskyblue'
                  }}
                  onClick={fetchNewVerificationCode}
                  disabled={loading}>
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <>Save my cell number and continue &#187;</>
                  )}
                </button>
              </div>
            </div>
          </ModalBody>
        </>
      );
      break;
    case 2:
      render = (
        <>
          <ModalHeader toggle={onClose} className="border-0">
            <span className="font-weight-bold">
              To continue, please type in the verification code we just texted
              to ******{phone.slice(5, 9)}
            </span>
          </ModalHeader>
          <ModalBody className="d-flex flex-column">
            <ReactInputVerificationCode
              length={6}
              placeholder=""
              onChange={(val: any) => {
                if (val.length === 6) {
                  if (parseInt(val) !== verificationCode) {
                    return setIsInvalidCode(true);
                  } else {
                    setIsInvalidCode(false);
                    setLoading(true);
                    updateSMSConfig({
                      _KEY: smsPreferencesKey,
                      verified: true
                    }).then((res: any) => {
                      localStorage.setItem('checkoutPopover', 'true');
                      toast('code verified successfully', 'success');
                      setStep(3);
                    });
                  }
                }
              }}
            />
            <div className="d-flex mt-3">
              <u onClick={redoVerificationCode} className="clickable">
                <i className="fa fa-redo" /> Send a new code
              </u>
              {isInvalidCode && (
                <span className="text-danger">
                  Sorry, this verification code is not correct. Please check it
                  again or send a new code.
                </span>
              )}
            </div>
          </ModalBody>
        </>
      );
      break;
    case 3:
      render = (
        <>
          <ModalHeader toggle={onClose} className="border-0">
            <span className="font-weight-bold">
              Cellphone registered successfully
            </span>
          </ModalHeader>
          <ModalBody className="d-flex flex-column">
            <div>
              Great, your cell number is now verified. You'll receive text
              message updates like date changes on ******{phone.slice(5, 9)}.
              You can always change your mobile number, or stop receiving text
              messages in the 'user section' in My Account
            </div>
            <div className="d-flex mt-3 clickable">
              <u onClick={onClose}>Ok, close</u>
            </div>
          </ModalBody>
        </>
      );
      break;
    default:
      render = <Fragment />;
      break;
  }
  return (
    <Modal
      isOpen={CELLPHONE_MODAL.open}
      toggle={onClose}
      className="ibf-cellphone-modal">
      {render}
    </Modal>
  );
};

export default CellphoneModal;
