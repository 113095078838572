import { runCS } from '../Network';
import Environment from '../Environment';
import { SendTemplateStandingOrder } from '../Models/StandingOrder';
import { fetchCsRequest } from './UtilService';
export const getStandingOrderService = (customer: string) => {
  return fetchCsRequest(
    runCS(Environment.cloudscripts.ibf_listStandingOrder, {
      customer
    })
  );
};

export const createStandingOrderService = (
  cartbox_key: string,
  frequency: string,
  start_date: number,
  months: number
) => {
  return fetchCsRequest(
    runCS(Environment.cloudscripts.ibf_new_standing_order_from_box, {
      cartbox_key,
      frequency,
      start_date,
      months
    })
  );
};

export const sendEmailStandingOrderService = (
  params: SendTemplateStandingOrder
) => {
  return fetchCsRequest(
    runCS(Environment.cloudscripts.ibf_sendSORequestEmail, params)
  );
};
