import { orderState } from './Types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../Models/State';

const { IDLE, RESOLVED, PENDING, REJECTED } = State;

const initialState: orderState = {
  orders: [],
  ordersKeys: [],
  tableHeaders: [],
  ordersByArrivalLoaded: false,
  indexCurrentPage: 0,
  growersList: [],
  isNewCheckout: false,
  ordersByPurchaseLoaded: false,
  orderDetail: {},
  orderDetailLoaded: false,
  state: IDLE,
  repurchase: false
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    fetchOrdersByPurchaseDate(
      state: orderState,
      { payload }: PayloadAction<any>
    ) {
      state.state = PENDING;
      state.ordersByPurchaseLoaded = false;
      state.ordersByArrivalLoaded = false;
      state.orderDetailLoaded = false;
      state.orderDetail = {};
      state.orders = [];
      state.repurchase = false;
    },
    fetchOrders(state: orderState, { payload }: PayloadAction<any>) {
      state.state = PENDING;
      state.ordersByPurchaseLoaded = false;
      state.ordersByArrivalLoaded = false;
      state.orderDetailLoaded = false;
      state.indexCurrentPage = 0;
      state.orderDetail = {};
      state.orders = [];
      state.repurchase = false;
    },
    fetchOrderDetail(state: orderState, { payload }: PayloadAction<any>) {
      state.state = PENDING;
      state.orderDetail = {};
      state.ordersByPurchaseLoaded = payload.ordersByPurchaseLoaded
        ? payload.ordersByPurchaseLoaded
        : false;
      state.ordersByArrivalLoaded = false;
      state.orderDetailLoaded = false;
      state.repurchase = payload.repurchase ? payload.repurchase : false;
      state.orders = payload.ordersByPurchaseLoaded ? state.orders : [];
    },
    cleanOrderDetail(state: orderState) {
      state.state = PENDING;
      state.orderDetail = {};
      state.orderDetailLoaded = false;
      state.repurchase = false;
    },
    saveListOrders(state: orderState, { payload }: PayloadAction<any>) {
      state.state = RESOLVED;
      state.ordersByArrivalLoaded = true;
      state.orders = payload.orders;
      state.growersList = payload.growers;
      state.ordersKeys = payload.keys;
      state.tableHeaders = payload.headers;
    },
    saveListOrdersByPurchaseDate(
      state: orderState,
      { payload }: PayloadAction<any>
    ) {
      state.state = RESOLVED;
      state.ordersByPurchaseLoaded = true;
      state.orders = payload.orders;
      state.tableHeaders = payload.headers;
    },
    goToPage(state, { payload }: PayloadAction<number>) {
      state.indexCurrentPage = payload;
    },
    saveOrderDetail(state: orderState, { payload }: PayloadAction<object>) {
      state.state = RESOLVED;
      state.orderDetail = payload;
      state.orderDetailLoaded = true;
    },
    newCheckout(
      state: orderState,
      {
        payload
      }: PayloadAction<{
        customer: string;
        preferences: { [key: string]: boolean };
      }>
    ) {
      state.state = PENDING;
    },
    setResolved(state) {
      state.state = RESOLVED;
    },
    setRejected(state) {
      state.state = REJECTED;
    },
    cleanRepurchase(state) {
      state.repurchase = false;
    }
  }
});

export const actionsOrder = slice.actions;
export default slice.reducer;
