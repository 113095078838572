import React from 'react';
import ReplacementProduct from './ReplacementProduct';

type Props = {
  products: Array<any>;
};
const ReplacementList: React.FC<Props> = ({ products }) => {
  return (
    <div className="w-100">
      <div
        className={
          'mr-2 d-flex flex-wrap justify-content-center justify-content-sm-between'
        }>
        {products.map((product: any, index: number) => {
          return (
            <div
              key={`replacement_${product.key}_${index}`}
              className="ibf-card-sm-modal ibf-card-width-modal mb-2">
              <ReplacementProduct product={product} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ReplacementList;
