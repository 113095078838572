import React, { Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addObjectParamsToPath,
  growerNameSortNumber
} from '../../../Utils/Utils';
import MixpanelService from '../../../Services/MixpanelService';
import { RootState } from '../../../Store/Reducers';
import { actionsFilter } from '../../../Store/Filter/Slice';
import useOutsideAlerter from '../../../Hooks/useOutsideAlerter';
import SeeWhatProductsComponent from '../../Shared/SeeWhatProductsComponent/SeeWhatProductsComponent';
type Props = {
  toggleMenu?: () => void;
  mobile?: boolean;
  closeMenuMobile?: () => void;
};

const GrowerList: React.FC<Props> = ({
  toggleMenu,
  mobile,
  closeMenuMobile
}) => {
  const { navHeight } = useSelector((state: RootState) => state.DisplayReducer);
  const { date } = useSelector((state: RootState) => state.ProductReducer);
  const { growerList } = useSelector(
    (state: RootState) => state.DashboardReducer
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, toggleMenu);

  const searchProduct = async (all: boolean, item?: any) => {
    closeMenuMobile && closeMenuMobile();
    toggleMenu && toggleMenu();
    const filters = all ? {} : { grower: [item._KEY] };
    const params = { date, search_val: '', ...filters };

    const growerParams = all
      ? { name: '', key: '' }
      : { name: item.company_name, key: item._KEY };
    dispatch(actionsFilter.setSelectedGrower(growerParams));
    MixpanelService.track('growers_menu_item', {
      name: all ? 'All' : item.company_name
    });
    history.push(addObjectParamsToPath('/search', params));
  };

  const searchCountry = (growerList: { country: string }[], name: string) => {
    closeMenuMobile && closeMenuMobile();
    toggleMenu && toggleMenu();
    const filters = {
      country: [growerList.length ? growerList[0].country : null]
    };
    const params = { date, search_val: '', ...filters };
    const growerParams = { name: '', key: '' };
    dispatch(actionsFilter.setSelectedGrower(growerParams));
    history.push(addObjectParamsToPath('/search', params));
  };

  return (
    <div
      ref={wrapperRef}
      className={`d-flex flex-column ${
        mobile ? '' : 'dropdown-wrapper shadow bg-white'
      }`}
      style={{ top: `${navHeight + 61}px` }}>
      <span className="selected-item d-none d-lg-block" />

      <div className="row justify-content-between w-100 p-3">
        <div className="d-flex flex-column ">
          <div className="mx-3 mx-lg-4">
            <SeeWhatProductsComponent />
          </div>
          <span
            className={`underline pointer list-item my-3 pt-1 list-flower-category ${
              mobile ? 'pl-3 pr-2' : 'pl-4'
            }`}
            key="All"
            onClick={() => searchProduct(true)}>
            All Growers
          </span>
        </div>
        <div className="ibf-link d-none d-lg-block" onClick={toggleMenu}>
          Close
        </div>
      </div>
      <div className="d-flex flex-column flex-wrap dropdown-items-wrapper overflow-auto">
        {Object.entries(growerList)
          .sort((a, b) => {
            let aName = a[0];
            let bName = b[0];
            let aSortNumber = growerNameSortNumber.hasOwnProperty(aName)
              ? growerNameSortNumber[a[0]]
              : -1;
            let bSortNumber = growerNameSortNumber.hasOwnProperty(bName)
              ? growerNameSortNumber[b[0]]
              : -1;
            return bSortNumber - aSortNumber;
          })
          .map(([key, value], index: number) => {
            let valueGrowerList = [...(value as any)];
            return (
              <ul
                className={`list-group list-unstyled ${
                  index > 0 ? 'mt-3' : ''
                } ${mobile ? 'pl-3' : 'pl-4'}`}
                key={'list_' + key + '_' + index}>
                <Fragment key={key + '_' + index}>
                  <li
                    key={'country_' + key}
                    onClick={() => searchCountry(valueGrowerList, key)}
                    className="font-weight-bold color-black underline pointer">
                    {key}{' '}
                    {`(${valueGrowerList.length} ${
                      valueGrowerList.length > 1 ? 'growers)' : 'grower)'
                    }`}
                  </li>
                  {valueGrowerList
                    .sort(
                      (a: any, b: any) =>
                        b.company_name &&
                        a.company_name?.localeCompare(b.company_name)
                    )
                    .map((item: any, index: number) => (
                      <Fragment key={index + item}>
                        <ul
                          style={{
                            listStyleType: 'disc',
                            marginLeft: '-20px'
                          }}>
                          <li
                            className=" underline pointer list-item pb-2 pt-1"
                            key={item + item}
                            onClick={() => searchProduct(false, item)}>
                            <span className={`mr-4 ${mobile ? 'small' : ''}`}>
                              <b>{item.company_name}</b>{' '}
                              {item.legal_name && `(${item.legal_name})`}
                            </span>
                          </li>
                        </ul>
                      </Fragment>
                    ))}
                </Fragment>
              </ul>
            );
          })}
      </div>
    </div>
  );
};

export default GrowerList;
