import React from "react";

type Props = {
  height?: string;
  width?: string;
  message?: string;
  className?:string;
};

const BoxLoadingSpinner: React.FC<Props> = ({ height, width, message, className }) => {
  return (
    <div
      style={{ height: height, width: width }}
      className={className}
    >
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="ml-3 mt-1 text-uppercase font-weight-bold">
        <span> {message}</span>
      </div>
    </div>
  );
};

export default BoxLoadingSpinner;
