import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
type Props = {
  text: string;
};
export default function StandingOrderPolicyItems({ text }: Props) {
  return (
    <div className="d-flex ">
      <FontAwesomeIcon icon={faCheck} className="color-green-light mt-1" />
      <span className="ml-2">{text}</span>
    </div>
  );
}
