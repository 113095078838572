import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel, CarouselItem, Modal } from 'reactstrap';
import { modalReducer } from '../../../../Store/Selectors';
import SignInFinalEmailStep from '../LoginModal/SigninFlow/SignInFinalEmailStep';
import { SignInGuestParams } from '../../../../Models/Auth';
import {
  hrefToObject,
  queryParamsToObject,
  signIn
} from '../../../../Utils/Utils';
import { State } from '../../../../Models/State';
import GuestSignUpForm from './GuestSignUpForm';
import { actionsModal } from '../../../../Store/Modal/Slice';
import * as ModalTypes from '../../../../Store/Modal/Types';
import { actionsAuth } from '../../../../Store/Auth/Slice';
import { getCookie } from '../../../../Services/AuthService';
const initialObjUser: SignInGuestParams = {
  zipcode: '',
  email: '',
  company_name: '',
  first_name: '',
  mobile_phone: '',
  business: '',
  events_per_year: '',
  storefront_type: ''
};
export default function GuestSignUpFormContainerModal() {
  const { referral_code }: { [key: string]: any } = hrefToObject();
  const queryParams = queryParamsToObject(window.location.hash, ['utm_pos']);
  const [loading, setLoading] = useState(State.IDLE);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [objUser, setObjectUser] = useState(initialObjUser);
  const dispatch = useDispatch();

  const {
    SIGN_IN_FLOW: { open }
  } = useSelector(modalReducer);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const signInGuest = async (params: SignInGuestParams) => {
    if (queryParams.utm_pos) {
      params.utm_pos = queryParams.utm_pos.toString();
    }
    setObjectUser(params);

    params.hutk = getCookie('hubspotutk') || '';

    if (referral_code?.length > 0) {
      const res = await signIn({
        params: { ...params, referral: referral_code }
      });
      if (!res) {
        setLoading(State.REJECTED);
      } else {
        setLoading(State.RESOLVED);
      }
    } else {
      dispatch(actionsAuth.setVerifyEmailAccount({ userInfo: params }));
      await signIn({
        params: { ...params, referral: referral_code },
        firstTime: true,
        reload: false
      });
      setLoading(State.RESOLVED);
    }
  };

  const items = [
    <GuestSignUpForm
      loading={loading === State.PENDING}
      setLoading={setLoading}
      next={next}
      signInGuest={signInGuest}
    />,
    <SignInFinalEmailStep previous={previous} objUser={objUser} />
  ];
  const slides = items.map((item, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}>
        {item}
      </CarouselItem>
    );
  });
  const toggle = () => {
    dispatch(actionsModal.setCloseModal({ modal: ModalTypes.SIGN_IN_FLOW }));
  };

  return (
    <Modal isOpen={open} className="position-relative" size="xl">
      <div
        className={`${
          activeIndex === slides.length - 1 ? 'd-none' : 'd-block'
        } pointer ibf-font-30 text-black position-absolute z-index  right-0 mr-2 mb-2`}
        onClick={toggle}>
        x
      </div>

      <Carousel
        activeIndex={activeIndex}
        next={next}
        keyboard={false}
        interval={false}
        previous={previous}>
        {slides}
      </Carousel>
    </Modal>
  );
}
