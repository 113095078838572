import React, { useEffect, useState } from 'react';
import FilterListItemComponent from './FilterListItemComponent';
import FilterItem from '../../../Models/FilterItem';
import { useSelector } from 'react-redux';
import {
  addObjectParamsToPath,
  getFiltersKeys,
  hrefToObject
} from '../../../Utils/Utils';
import { useHistory } from 'react-router-dom';
import MixpanelService from './../../../Services/MixpanelService';
import Filter from './../../../Models/Filter';
import { RootState } from '../../../Store/Reducers';

type Props = {
  listItems: Array<FilterItem>;
  isMobile: boolean;
  filterLabel: string;
};

const FilterListComponent: React.FC<Props> = ({
  listItems,
  isMobile,
  filterLabel
}) => {
  const { filters, date } = useSelector(
    (state: RootState) => state.ProductReducer
  );

  const {
    search,
    filters_conditions: { week, desc, favorites }
  } = useSelector((state: RootState) => state.FilterReducer);

  const [filterLimit, setFilterLimit] = useState<number>(5);
  const [arrayFilters, setArrayFilters] = useState<Array<any>>([]);
  const history = useHistory();

  const handleFilterListItems = () => {
    filterLimit === 5 ? setFilterLimit(listItems.length) : setFilterLimit(5);
  };

  useEffect(() => {
    filters.length > 0 && setArrayFilters(filters);
  }, [filters]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const itemId = e.target.id;
    setFilter(itemId);
  };

  const setFilter = (itemId: string) => {
    const filters = arrayFilters.map((filterRedux: Filter) => {
      let filter = { ...filterRedux };
      if (filterLabel === filter.label) {
        filter.values = filterRedux.values.map((item: FilterItem) => {
          if (item.key === itemId) {
            MixpanelService.track(
              `filter_${filter.label.toLowerCase()}_${item.value}`
            );
            item = { ...item, selected: !item.selected };
          }
          return item;
        });
      }
      return filter;
    });

    setArrayFilters(filters);

    const filtersKeys = getFiltersKeys(filters);
    const params = {
      date,
      search_val: search,
      week,
      desc,
      ...filtersKeys,
      favorites
    };

    const searchParams = hrefToObject();

    if (searchParams.country) {
      params.country = searchParams.country;
    }

    if (searchParams.combo) {
      params.combo = searchParams.combo;
    }

    history.push(addObjectParamsToPath('/search', params));
  };

  return (
    <>
      <ul
        className={`list-group list-group-flush ${
          listItems.length <= 5 ? 'h-auto' : ''
        }
      ${filterLimit === 5 ? 'filter-list-less' : ''}`}>
        {listItems &&
          listItems.slice(0, filterLimit).map((item: FilterItem) => {
            return (
              item.value !== null && (
                <li
                  key={listItems.indexOf(item) + item.value}
                  className={`list-group-item p-0 ${
                    isMobile ? 'ibf-filter-list-items ' : 'border-0'
                  }`}>
                  <FilterListItemComponent
                    checked={item.selected}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e)
                    }
                    itemKey={item.key}
                    checkboxName={item.value}
                  />
                </li>
              )
            );
          })}
      </ul>

      {listItems && listItems.length > 5 && (
        <>
          <div className="px-2 mb-2">
            <span
              className="pointer color-gray"
              onClick={handleFilterListItems}>
              <u>
                {filterLimit === 5
                  ? `See ${listItems.length} more`
                  : 'See less'}
              </u>
            </span>
          </div>
        </>
      )}
    </>
  );
};
export default FilterListComponent;
