import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  StandingOrder,
  SendTemplateStandingOrder
} from '../../Models/StandingOrder';
import { standingOrderState } from './Types';
import { State } from '../../Models/State';
import { Params } from '../../Components/Shared/Modal/StadingOrderCreationModal/StandingOrderCreationModal';
const { IDLE, RESOLVED, PENDING, REJECTED } = State;

const initialState: standingOrderState = {
  standingOrder: [],
  state: IDLE,
  indexCurrentPage: 0,
  resCreationStandingOrder: { created: false, _KEY: '' }
};

const slice = createSlice({
  name: 'standingOrder',
  initialState,
  reducers: {
    getStandingOrder: (
      state: standingOrderState,
      { payload }: PayloadAction<{ customer: string }>
    ) => {
      state.state = PENDING;
    },
    setStandingOrder: (
      state: standingOrderState,
      { payload: { items } }: PayloadAction<{ items: Array<StandingOrder> }>
    ) => {
      state.standingOrder = items;
      state.state = RESOLVED;
    },
    createStandingOrder: (
      state: standingOrderState,
      { payload }: PayloadAction<Params>
    ) => {
      state.state = PENDING;
    },
    setResponseStandingOrder(
      state: standingOrderState,
      { payload }: PayloadAction<{ created: boolean; _KEY: string }>
    ) {
      state.resCreationStandingOrder = payload;
    },
    goToPage(state, { payload }: PayloadAction<number>) {
      state.indexCurrentPage = payload;
    },
    sendEmailStandingOrder(
      state,
      { payload }: PayloadAction<SendTemplateStandingOrder>
    ) {
      state.state = PENDING;
    },

    setResolved(state) {
      state.state = RESOLVED;
    },
    setRejected(state) {
      state.state = REJECTED;
    }
  }
});

export const actionsStandingOrder = slice.actions;
export default slice.reducer;
