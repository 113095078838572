import React, { useState, useEffect, useCallback } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import {
  STANDING_ORDER_CREATION_MODAL,
  CONFIRMATION_CHECKOUT_MODAL
} from '../../../../Store/Modal/Types';

import {
  modalReducer,
  standingOrderReducer
} from '../../../../Store/Selectors';
import { useSelector, useDispatch } from 'react-redux';
import { actionsModal } from '../../../../Store/Modal/Slice';
import ContactComponent from '../../ContactComponent/ContactComponent';
import BoxGrower from './BoxGrower';
import OptionBox from './OptionBox';
import StandingOrderPolicy from './StandingOrderPolicy';
import StandingTitleBox from './StandingTitleBox';
import {
  convertDateToNumberDate,
  convertNumberDateMMDDYYYY,
  roundUp
} from '../../../../Utils/Utils';
import moment from 'moment';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CreateStandingOrder } from '../../../../Models/StandingOrder';
import { actionsStandingOrder } from '../../../../Store/StandingOrder/Slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { State } from '../../../../Models/State';
import { OrderEntity } from '../../../../Store/Order/Types';
import { Data } from '../../../OrdersTableComponent/OrderCustomBoxRow';

const modalBody = (
  <h3 className="font-weight-bold">
    Are you sure to create this standing order?
  </h3>
);

export interface Params {
  cartbox_key: string;
  frequency: string;
  start_date: number;
  months: number;
  data: Data | undefined | null;
  endDate?: number;
  priceStandingOrder?: number;
}
export default function StandingOrderCreationModal() {
  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = useState<string | Date>('');
  const [weeklySelected, setWeeklySelected] = useState('for 6 months');
  const [chooseFrecuency, setChooseFrecuency] = useState('Weekly');
  const [endDate, setEndDate] = useState('');
  const [priceStandingOrder, setPriceStandingOrder] = useState(0);
  const [daySelected, setDaySelected] = useState<Date>(new Date());

  ////
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors
  } = useForm<CreateStandingOrder>();

  const {
    STANDING_ORDER_CREATION_MODAL: { open, data }
  } = useSelector(modalReducer);

  const { state } = useSelector(standingOrderReducer);

  useEffect(() => {
    if (chooseFrecuency === 'Weekly') {
      if (data?.custom) {
        setPriceStandingOrder(roundUp(data?.prices?.weekly_saving_price));
      } else {
        setPriceStandingOrder(roundUp(data?.prices?.weekly_price?.saving));
      }
    } else {
      if (data?.custom) {
        setPriceStandingOrder(roundUp(data?.prices?.bi_weekly_saving_price));
      } else {
        setPriceStandingOrder(roundUp(data?.prices?.bi_weekly_price?.saving));
      }
    }
  }, [chooseFrecuency, data]);

  const handleDate = useCallback(
    (day: Date) => {
      let date = convertDateToNumberDate(day);
      let convertDate = convertNumberDateMMDDYYYY(date);
      let month = 12;
      if (weeklySelected === 'for 6 months') {
        month = 6;
      }

      let futureMonth = moment(day)
        .add(month, 'M')
        .format('MM/DD/YYYY');
      setSelectedDay(convertDate);
      setEndDate(moment(futureMonth, 'MM/DD/YYYY').format('YYYYMMDD'));
    },
    [weeklySelected]
  );

  useEffect(() => {
    handleDate(daySelected);
  }, [weeklySelected, daySelected, handleDate]);
  const toggle = () => {
    dispatch(
      actionsModal.setCloseModal({ modal: STANDING_ORDER_CREATION_MODAL })
    );
  };
  const onSubmit: SubmitHandler<CreateStandingOrder> = (items) => {
    if (!selectedDay) {
      setError('start_date', {
        type: 'required'
      });
      return;
    } else {
      clearErrors('start_date');
    }
    let start_date = moment(selectedDay, 'MM/DD/YYYY').format('YYYYMMDD');
    let months = 12;
    if (weeklySelected === 'for 6 months') {
      months = 6;
    }

    ////
    let cartbox_key = !data?.custom
      ? data?.box._KEY
      : data?.box?.find((item: OrderEntity) => item.custom)._KEY;

    const params: Params = {
      cartbox_key: cartbox_key,
      frequency: chooseFrecuency,
      start_date: parseInt(start_date),
      months,
      data: data,
      endDate: parseInt(endDate),
      priceStandingOrder: priceStandingOrder
    };

    const confirmAction = () => {
      dispatch(actionsStandingOrder.createStandingOrder(params));
    };

    dispatch(
      actionsModal.setOpenModal({
        modal: CONFIRMATION_CHECKOUT_MODAL,
        data: {
          body: modalBody,
          closeModal: false,
          confirmButtonText: 'Yes, create standing order.',
          cancelButtonText: 'No, go back',
          confirmAction: confirmAction,
          showCancelButton: true
        }
      })
    );
  };

  return (
    <div>
      <Modal isOpen={open} toggle={toggle} size="xl">
        <span
          onClick={toggle}
          className="position-absolute close--button pointer">
          X
        </span>
        <ModalBody>
          <>
            <div className="d-flex justify-content-between align-items-center">
              <span className="d-block">
                <span className="ibf-color--dark-green">
                  Save <b>{`$${priceStandingOrder}`}</b>{' '}
                </span>{' '}
                a year with this weekly Standing Order
              </span>
              <ContactComponent className="ml-auto" />
            </div>

            <StandingTitleBox
              custom={data?.custom}
              growers={
                !data?.custom
                  ? data?.box?.grower_obj
                  : data?.box?.find((item: OrderEntity) => item.custom)
                      .grower_obj || ''
              }
              country={
                !data?.custom
                  ? data?.box?.grower_obj?.country?.country_iso
                  : data?.box?.find((item: OrderEntity) => item.custom)
                      .grower_obj?.country?.country_iso || ''
              }
            />

            {!data?.custom ? (
              <BoxGrower
                key={data?.box._KEY}
                image={data?.box?.img[0]}
                image2={data?.box?.img[1]}
                centimeters={data?.box.length}
                bunches={data?.box.total_bunches}
                name={data?.box.variety_grade}
                state={state}
              />
            ) : (
              data?.box
                ?.filter((item: OrderEntity) => !item.custom)
                .map((data: OrderEntity) => (
                  <BoxGrower
                    key={data?.cart_box_item_key}
                    image={data?.img[0]}
                    image2={data?.img[1]}
                    centimeters={data?.length}
                    bunches={data?.total_bunches}
                    name={data?.variety_grade}
                    state={state}
                  />
                ))
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              <OptionBox
                data={data!}
                selectedDay={selectedDay}
                setSelectedDay={(date: Date) => {
                  handleDate(date);
                  setDaySelected(date);
                }}
                weeklySelected={weeklySelected}
                setWeeklySelected={(date: string) => setWeeklySelected(date)}
                errors={errors}
                ///
                chooseFrecuency={chooseFrecuency}
                setChooseFrecuency={(event: string) =>
                  setChooseFrecuency(event)
                }
                clearErrors={clearErrors}
              />
              <hr className="border-top-gray" />
              <StandingOrderPolicy
                weeklySelected={weeklySelected}
                endDate={endDate}
              />
              <div className="form-check mt-4 mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  {...register('confirm', {
                    required: true
                  })}
                  id="confirm"
                />
                <label className="form-check-label" htmlFor="confirm">
                  I confirm this Standing Order for {weeklySelected} and agree
                  upon the Standing Order policy.
                </label>
                {errors?.confirm?.type === 'required' && (
                  <span className="ibf-font-12 color-red d-block">
                    Please confirm by clicking on the checkbox to continue.
                  </span>
                )}
              </div>

              <Button color={'primary'} disabled={state === State.PENDING}>
                {state === State.PENDING && (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    pulse
                    className="mr-2"
                  />
                )}
                <span className="underline d-block font-weight-bold">
                  Request this Standing Order.
                </span>
                <span className="d-block">
                  (After the grower confirms, you have a 7 days review period to
                  cancel)
                </span>
              </Button>
            </form>
          </>
        </ModalBody>
      </Modal>
    </div>
  );
}
