import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/pro-solid-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';
import Product from '../../../Models/Product';
import CardViewImage from '../../ProductWrapperComponent/ProductViewsComponent/CardViewComponent/CardViewImage';

type Props = {
  productPhotos: string[];
  product: Product;
  detail: () => void;
  mobile?: boolean;
};

const FavoriteProduct: React.FC<Props> = ({
  productPhotos,
  product,
  detail,
  mobile
}) => {
  return (
    <div
      className={`ibf-card-shadow ${
        mobile
          ? 'ibf-card-favorite-mobile mx-2'
          : 'ibf-card-width-favorite ibf-card-sm'
      }`}>
      <CardViewImage
        favorite
        favoriteView
        productPhotos={productPhotos}
        detail={detail}
        product={product}
      />
      <div className="ibf-product-form-wrapper-card">
        <div className="d-flex w-100 flex-column">
          <div className="d-flex flex-column ibf-product-name-wrapper">
            <div className="px-2 font-weight-bold ibf-text-area">
              <span
                className=" p-sm-0 ibf-text-info"
                id={'tooltip_' + product.key}
                onClick={detail}>
                {product.name}{' '}
                <span className="ibf-contact-text">
                  more info <FontAwesomeIcon icon={faAngleDoubleRight} />
                </span>
              </span>
            </div>
          </div>

          <UncontrolledTooltip
            placement="bottom"
            target={'tooltip_' + product.key}>
            {product.name}
          </UncontrolledTooltip>
        </div>
        <span />
      </div>
    </div>
  );
};

export default FavoriteProduct;
