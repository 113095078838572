import React, { useState } from 'react';
import Product from '../../../../Models/Product';
import ImageComponent from '../../../Shared/ImageComponent/ImageComponent';
import ListViewInfo from './ListViewInfo';

type Props = {
  productPhotos: string[];
  detail: () => void;
  product: Product;
  index?: number;
  form: JSX.Element;
};

const ListViewComponent: React.FC<Props> = ({
  productPhotos,
  detail,
  index,
  product,
  form
}) => {
  const [photo, setPhoto] = useState(productPhotos[0] ?? '');

  return (
    <div className="ibf-list-product-container bg-white">
      <div className="d-flex flex-grow-1">
        <div className="d-none d-lg-flex ibf-list-product-info-image">
          <div
            className="overflow-hidden d-flex justify-content-center align-items-center"
            style={{ width: '96px', height: '72px' }}>
            <ImageComponent
              imageUrl={photo}
              className="img-fluid"
              width={'96px'}
              height={'72px'}
              onErrorUrl={''}
              onError={() => {
                if (photo) {
                  const currentIndex = productPhotos.findIndex(
                    (p) => p === photo
                  );

                  if (productPhotos[currentIndex + 1]) {
                    setPhoto(productPhotos[currentIndex + 1]);
                  }
                }
              }}
              setDetail={detail}
            />
          </div>
        </div>

        <div className="ibf-list-product-info bg-white">
          <div className="ibf-product-info-wrapper">
            <ListViewInfo
              productPhotos={productPhotos}
              product={product}
              detail={detail}
            />
          </div>
          {form}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ListViewComponent);
