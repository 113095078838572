import React, {
  ChangeEvent,
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { GrowerPricing } from '../../../../Models/GrowerPricing';
import Product from './../../../../Models/Product';
import { getStemLabel, onlyNumbers, roundUp } from '../../../../Utils/Utils';

//import { DropboxOutlined } from '@ant-design/icons';
import CardViewFormComponent from '../../ProductViewsComponent/CardViewComponent/CardViewForm/CardViewFormComponent';
import MixpanelService from '../../../../Services/MixpanelService';
import ListViewFormComponent from '../../ProductViewsComponent/ListViewComponent/ListViewForm/ListViewFormComponent';
import { Input } from 'reactstrap';
import useDeviceDetect from '../../../../Hooks/useDeviceDetect';
import StemHelperPopover from '../ProductPopover/StemHelperPopover';
import { Popover } from 'antd';
import CardButtonForm from '../../ProductViewsComponent/CardViewComponent/CardViewForm/CardButtonForm';
import Environment from '../../../../Environment';
import { useSelector } from 'react-redux';
import { productReducer } from '../../../../Store/Selectors';
import { State } from '../../../../Models/State';
import TabsProductPrice from '../../ProductViewsComponent/CardViewComponent/CardViewForm/TabsProductPrice';
import CardQuantityInputs from '../../ProductViewsComponent/CardViewComponent/CardViewForm/CardQuantityInputs';
import GrowerLengths from '../../ProductViewsComponent/CardViewComponent/CardViewForm/GrowerLengths';

/* import CardGrowerInputs from '../../ProductViewsComponent/CardViewComponent/CardViewForm/CardGrowerInputs'; */
/* import InputsBestDeal from '../../ProductViewsComponent/CardViewComponent/CardViewForm/InputsBestDeal'; */
type Props = {
  boxSelectList: Array<{ length: string }>;
  submitToShoppingCart: () => void;
  isAList: boolean;
  loadingButton: boolean;
  productList: number;
  stemValidation: boolean;
  setStemValidation: (value: boolean) => void;
  buttonName: string;
  setListSize: (length: string) => void;
  setBoxesSelected: (boxes: string) => void;
  setProductList: (value: string) => void;
  boxOnly?: boolean;
  isProductDetail?: boolean;
  growers: GrowerPricing[];
  growerSelected: string;
  growerLengths: object;
  lengthSelected: GrowerPricing;
  dispatch: Dispatch<any>;
  index?: number;
  product: Product;
  boxesSelected: string;
  MessageDisclaimer?: JSX.Element | boolean;
  boxesAvailable: number[];
  activeTab: string;
  setActiveTab: (value: string) => void;

  setSelectedBunch: (value: string) => void;
  selectedDealBunch: string;
  setselectedDealBunch: (value: string) => void;
  setTotalStemsBox: (value: string) => void;
  totalStemsBox: string;
  MessageProductKomet?: JSX.Element | boolean;
  custom: boolean;
  bunchesAvailable: number;
};

const ProductFormComponent: React.FC<Props> = ({
  growerLengths,
  submitToShoppingCart,
  isAList,
  loadingButton,
  setListSize,
  productList,
  stemValidation,
  setStemValidation,
  setProductList,
  boxOnly,
  boxSelectList,
  setBoxesSelected,
  isProductDetail,
  growers,
  growerSelected,
  dispatch,
  index,
  product,
  lengthSelected,
  boxesSelected,
  MessageDisclaimer,
  boxesAvailable,
  activeTab,
  setActiveTab,
  setSelectedBunch,
  selectedDealBunch,
  setselectedDealBunch,
  //
  setTotalStemsBox,
  totalStemsBox,
  MessageProductKomet,
  custom,
  bunchesAvailable
}) => {
  const { isMobile } = useDeviceDetect();
  const { state: productState, product: productDetail } = useSelector(
    productReducer
  );

  const [openStemHelper, setOpenStemHelper] = useState(false);

  const productRef = useRef(product);
  const [valueBox, setValueBox] = useState<string | any>('1 box');

  useEffect(() => {
    productRef.current = product;

    return () => {
      productRef.current = new Product();
    };
  }, [product]);

  useEffect(() => {
    let valueProductList = (
      lengthSelected.stems_per_bunch * lengthSelected.total_box
    ).toString();

    if (
      selectedDealBunch === '1' &&
      productRef?.current?.growers?.length! > 1
    ) {
      setProductList(valueProductList);
    }

    if (boxesSelected === '1') {
      if (isNaN(parseInt(valueProductList))) {
        setTotalStemsBox('0');
      } else {
        setTotalStemsBox(valueProductList);
      }
    }
  }, [
    lengthSelected.stems_per_bunch,
    lengthSelected.total_box,
    setProductList,
    selectedDealBunch,
    activeTab,
    boxOnly,
    //
    boxesSelected,
    setTotalStemsBox,
    product,
    growerSelected,
    growerLengths
  ]);

  const getButtonName = useMemo(() => {
    const growerName = growers

      .find((grower: GrowerPricing) => grower._KEY === growerSelected)
      ?.grower_name.split('(')[0];

    if (growerSelected && productList > 0 && activeTab === '2') {
      /*  return `Start a box with ${productList} 
        ${productList === 1 ? ' Bunch' : 'Bunches'} from ${growerName}`; */
      return (
        <span className="underline">
          &raquo; Add{' '}
          <b>{`${productList} ${productList === 1 ? ' Bunch' : 'Bunches'} `}</b>
          and see all options from {growerName} to fill the box full
        </span>
      );
    } else if (
      growerSelected &&
      boxOnly &&
      (parseInt(boxesSelected) === 0 || isNaN(parseInt(boxesSelected)))
    ) {
      //
      return (
        <div className="d-flex align-items-center justify-content-center">
          {/*  <DropboxOutlined style={{ fontSize: 28 }} /> */}
          {/*  <span>Add box(es) to Cart</span> */}

          <span className="underline">
            &raquo; <b>{`${totalStemsBox} stems`}</b> to my cart
          </span>
        </div>
      );
    } else if (
      growerSelected &&
      (parseInt(boxesSelected) === 0 || isNaN(parseInt(boxesSelected)))
    ) {
      //return `Start a box with ${growerName} items`;
      if (activeTab === '1') {
        return (
          <span className="underline">
            &raquo; <b>{`${totalStemsBox} stems`}</b> to my cart
          </span>
        );
      } else {
        return (
          <span className="underline">
            &raquo; Add <b>{`1 bunch `}</b>
            and see all options from {growerName} to fill the box full
          </span>
        );
      }
    } else if (growerSelected && parseInt(boxesSelected) > 0) {
      //
      if (activeTab === '1') {
        return (
          <div className="d-flex align-items-center justify-content-center">
            {/*  <DropboxOutlined style={{ fontSize: 28 }} /> */}
            {/*  <span>
              Add{' '} 
              <span className="font-weight-bold">
                {getStemLabel(parseInt(boxesSelected), true)}
              </span>{' '}
              to Cart
            </span> */}
            <span className="underline">
              &raquo; <b>{`${totalStemsBox} stems`}</b> to my cart
            </span>
          </div>
        );
      } else {
        return (
          <span className="underline">
            &raquo; Add <b>{`1 bunch `}</b>
            and see all options from {growerName} to fill the box full
          </span>
        );
      }
    } else if (
      (product?.growers?.length! > 1 && boxOnly) ||
      (!growerSelected && boxOnly)
    ) {
      return (
        <span className="underline">
          &raquo; <b>{`${valueBox} `}</b> to my cart
        </span>
      );
    } else {
      return (
        <span className="underline">
          &raquo;{' '}
          <b>
            {productList
              ? `${productList} stems`
              : `${lengthSelected.stems_per_bunch *
                  lengthSelected.total_box} stems`}
          </b>{' '}
          to my cart
        </span>
      );
    }
  }, [
    growerSelected,
    growers,
    boxesSelected,
    productList,
    boxOnly,
    lengthSelected.stems_per_bunch,
    lengthSelected.total_box,
    activeTab,
    totalStemsBox,
    product,
    valueBox
  ]);

  // new logic

  const getListValue = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const option = Object.values(growerLengths).find(
        (option: any) => option.length === parseInt(e.target.value)
      );

      dispatch({ type: 'SET_OPTION_SELECTED', payload: { option } });
      setListSize(e.target.value + ' cm');
    },
    [dispatch, setListSize, growerLengths]
  );

  const getGrowerValue = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const grower = growers.find(
        (grower: GrowerPricing) => grower._KEY === e.target.value
      );

      if (e.target.value === '') {
        setActiveTab('1');
      }
      if (grower) {
        dispatch({ type: 'SET_GROWER', payload: { grower } });
        MixpanelService.track('product_card_filter_grower', {
          name: !grower._KEY ? 'All' : grower.grower_name
        });
      }
    },
    [dispatch, growers, setActiveTab]
  );

  const getBoxesValue = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const list: HTMLSelectElement | any = document.getElementById(
        'bunch_list_' + productRef.current.key + '_' + index
      );

      let value = (
        lengthSelected.stems_per_bunch *
        lengthSelected.total_box *
        parseInt(e.target.value)
      ).toString();
      if (
        (product?.growers?.length! > 1 && boxOnly) ||
        (!growerSelected && boxOnly)
      ) {
        let valueData =
          e.target.value === '1'
            ? `${e.target.value} box`
            : `${e.target.value} boxes`;
        setValueBox(valueData);
      }

      if (list) {
        list.selectedIndex = 0;
        setProductList('');
      }
      setStemValidation(false);
      setBoxesSelected(e.target.value);
      setTotalStemsBox(value);
    },
    [
      setProductList,
      setStemValidation,
      setBoxesSelected,
      index,
      setTotalStemsBox,
      lengthSelected.stems_per_bunch,
      lengthSelected.total_box,
      product,
      boxOnly,
      growerSelected
    ]
  );

  const handleStemChange = useCallback(
    (
      evt: React.FormEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
    ) => {
      const value = evt.currentTarget.value;

      setselectedDealBunch('');
      setSelectedBunch(value.toString());
      MixpanelService.track('amount_stems_selected', {
        stems: onlyNumbers(value).toString()
      });
      setProductList(onlyNumbers(value).toString());
      setStemValidation(false);
      const list: HTMLSelectElement | any = document.getElementById(
        'box_list_' + productRef.current.key + '_' + index
      );

      if (list) {
        list.selectedIndex = 0;
        setBoxesSelected('');
      }
    },
    [
      setBoxesSelected,
      setProductList,
      setStemValidation,
      index,
      setSelectedBunch,
      setselectedDealBunch
    ]
  );

  const contentPopoverValidation = useCallback(() => {
    return (
      <div className="d-flex flex-column text-center">
        <span>Please type the number of bunches or boxes</span>
      </div>
    );
  }, []);

  const lengthSelect = useCallback(() => {
    const selected = growerSelected.length > 0;
    return (
      <div className={`select`}>
        <select
          id="size-list"
          onChange={getListValue}
          className={'form-control form-control-sm'}>
          {Object.values(growerLengths).map((value, index: number) => {
            return (
              <option
                key={'flower_size_' + index}
                value={value.length}
                className="text-center">
                {!isNaN(value.length) ? value.length + ' cm:' : ''}
                {value.stem_price &&
                product.category.key === Environment.categoriesKey.bouquets
                  ? ` $${roundUp(
                      value.stem_price * value.stems_per_bunch
                    )}/bunch ${
                      value.stems_per_bunch && selected ? `(Box price)` : ''
                    }`
                  : ` $${roundUp(value.stem_price)}/${
                      product.category.key ===
                      Environment.categoriesKey.supplies
                        ? 'item'
                        : 'st'
                    } ${
                      value.stems_per_bunch && selected ? `(Box price)` : ''
                    }`}
              </option>
            );
          })}
        </select>
      </div>
    );
  }, [getListValue, growerLengths, growerSelected, product.category.key]);
  ///Grower
  const growerSelect = useCallback(() => {
    const selected = growerSelected.length > 0;

    return (
      <div className={`${growers.length > 1 ? 'select' : ''}`}>
        {growers.length > 1 ? (
          <select
            id="growers"
            onChange={getGrowerValue}
            className={'form-control form-control-sm'}
            defaultValue={growerSelected}>
            {growers
              .sort(
                (a: GrowerPricing, b: GrowerPricing) =>
                  a.stem_price - b.stem_price
              )
              .map((grower: GrowerPricing, index: number) => {
                let growerName = grower.grower_name.split('(');

                return (
                  <option
                    key={'grower_' + index}
                    value={grower._KEY}
                    className="text-center ibf-font-15">
                    {`${growerName[0]} (${
                      product.pricing &&
                      product.pricing[growerName[0].trim()]?.legal_name
                        ? product.pricing[growerName[0].trim()]?.legal_name +
                          ', '
                        : ''
                    }${growerName[1]}`}

                    {grower?.grower_quantity! > 1 &&
                      (product.category.key ===
                      Environment.categoriesKey.bouquets
                        ? ` $${roundUp(
                            grower.stem_price * grower.stems_per_bunch
                          )}/bunch ${
                            grower.stems_per_bunch && selected
                              ? `(Box price)`
                              : ''
                          }`
                        : ` $${roundUp(grower.stem_price)}/${
                            product.category.key ===
                            Environment.categoriesKey.supplies
                              ? 'item'
                              : 'st'
                          } ${
                            grower.stems_per_bunch && selected
                              ? `(Box price)`
                              : ''
                          }`)}
                  </option>
                );
              })}
          </select>
        ) : (
          growers.map((grower: GrowerPricing, index: number) => {
            let growerName = grower.grower_name.split('(');

            return (
              <div
                key={'grower_' + index}
                className={`ibf-font-15 ${isProductDetail ? '' : 'px-1'} `}>
                {`${growerName[0]} (${
                  product.pricing &&
                  product.pricing[growerName[0].trim()]?.legal_name
                    ? product.pricing[growerName[0].trim()]?.legal_name + ', '
                    : ''
                }${growerName[1]}`}

                {grower?.grower_quantity! > 1 &&
                  (product.category.key === Environment.categoriesKey.bouquets
                    ? ` $${roundUp(
                        grower.stem_price * grower.stems_per_bunch
                      )}/bunch ${
                        grower.stems_per_bunch && selected ? `(Box price)` : ''
                      }`
                    : ` $${roundUp(grower.stem_price)}/${
                        product.category.key ===
                        Environment.categoriesKey.supplies
                          ? 'item'
                          : 'st'
                      } ${
                        grower.stems_per_bunch && selected ? `(Box price)` : ''
                      }`)}
              </div>
            );
          })
        )}
      </div>
    );
  }, [getGrowerValue, growers, growerSelected, product, isProductDetail]);

  const stemsInput = useCallback(() => {
    const selected = growerSelected.length > 0;

    let validateValueProductList = isNaN(productList) || productList < 1;
    return (
      <div className={`mx-1 ${isProductDetail ? 'p-2' : ''}`}>
        <span className="my-3 d-block text-ash-gray font-weight-bolder ibf-font-15">
          Type number of stems:
        </span>
        <Popover
          overlayClassName="ibf-stems-validation"
          visible={
            (isNaN(productList) || productList < 1) &&
            stemValidation &&
            !selected
          }
          content={
            <span className="color-red">Please type the number of stems</span>
          }>
          <Popover
            overlayClassName="ibf-stems-suggestion"
            visible={openStemHelper}
            trigger="click"
            placement="bottom"
            content={<StemHelperPopover stemList={setProductList} />}>
            <Input
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleStemChange(e);
                //setProductList(e.target.value)
              }}
              placeholder={
                isMobile
                  ? `${validateValueProductList ? '' : productList} stems`
                  : `${validateValueProductList ? '' : productList} stems`
              }
              inputMode="numeric"
              name="stems"
              autoComplete="off"
              onClick={() => setOpenStemHelper(true)}
              onFocus={handleStemChange}
              onBlur={(e) => setOpenStemHelper(false)}
              value={productList || ''}
              maxLength={boxOnly ? 2 : 5}
              invalid={stemValidation}
              className={'form-control form-control-sm mb-2'}
            />
          </Popover>
        </Popover>
      </div>
    );
  }, [
    boxOnly,
    growerSelected,
    productList,
    setProductList,
    stemValidation,
    handleStemChange,
    isMobile,
    openStemHelper,
    isProductDetail
  ]);

  const boxSelect = useCallback(() => {
    return (
      <div className={`select`}>
        <select
          id="size-list"
          onChange={getBoxesValue}
          className={'form-control form-control-sm'}>
          {Object.values(boxSelectList).map((value, index: number) => {
            return (
              <option
                key={'flower_size_' + index}
                value={value.length}
                className="text-center">
                {getStemLabel(parseInt(value.length), true)}
              </option>
            );
          })}
        </select>
      </div>
    );
  }, [boxSelectList, getBoxesValue]);

  ///
  const lengthSelectGrower = (value: number) => {
    const option = Object.values(growerLengths).find(
      (option: any) => option.length === value
    );

    dispatch({ type: 'SET_OPTION_SELECTED', payload: { option } });
    setListSize(value + ' cm');
  };

  ///

  const renderForm = () => {
    const boxButton = growerSelected.length > 0 && parseInt(boxesSelected) > 0;

    const events = {
      handleStemChange,
      getBoxesValue
    };
    const validations = {
      stemValidation,
      isProductDetail,
      boxButton,
      growers: growers.length > 0,
      loadingButton,
      growerSelected: growerSelected.length > 0,
      boxOnly
    };

    switch (true) {
      case isAList:
        return (
          <ListViewFormComponent
            product={productRef.current}
            events={events}
            lengthSelected={lengthSelected}
            growerSelect={growerSelect()}
            lengthSelect={lengthSelect()}
            submit={submitToShoppingCart}
            boxSelect={boxSelect()}
            stemInput={stemsInput()}
            validations={validations}
            index={index}
            isProductDetail={isProductDetail!}
            isAList={isAList}
            boxOnly={boxOnly!}
            disabledButton={
              (productState === State.REJECTED && !!productDetail) ||
              (growers && !growerSelected
                ? isNaN(productList) || productList < 1
                : !boxesAvailable.length && activeTab === '1') ||
              (bunchesAvailable === 0 && activeTab === '2')
            }
            buttonName={getButtonName}
            contentPopover={contentPopoverValidation()}
            // foote
            activeTab={activeTab}
            //

            tabsProductPrice={
              <TabsProductPrice
                grower={lengthSelected}
                activeTab={activeTab}
                setActiveTab={(value: string) => setActiveTab(value)}
                product={productRef.current}
                boxOnly={boxOnly}
                isAList={isAList}
                boxesAvailable={boxesAvailable}
                isProductDetail={isProductDetail!}
                /* growerLengths={
                  <GrowerLengths
                    product={productRef?.current}
                    selectData={(value: number) => lengthSelectGrower(value)}
                    grower={lengthSelected}
                    growerSelected={growerSelected}
                    activeTab={activeTab}
                  />
                }*/
                contentTab1={
                  <CardQuantityInputs
                    getBoxesValue={getBoxesValue}
                    handleStemChange={handleStemChange}
                    lengthSelected={lengthSelected}
                    product={product}
                    boxSelect={boxSelect()}
                    index={index}
                    stemInput={stemsInput()}
                    validations={validations}
                    activeTab={activeTab}
                    //
                    boxesSelected={boxesSelected}
                    boxesAvailable={boxesAvailable}
                    bunchesAvailable={bunchesAvailable}
                  />
                }
                contentTab2={
                  !boxOnly ? (
                    <CardQuantityInputs
                      getBoxesValue={getBoxesValue}
                      handleStemChange={handleStemChange}
                      lengthSelected={lengthSelected}
                      product={product}
                      boxSelect={boxSelect()}
                      index={index}
                      stemInput={stemsInput()}
                      validations={validations}
                      activeTab={activeTab}
                      ///
                      boxesSelected={boxesSelected}
                      boxesAvailable={boxesAvailable}
                      bunchesAvailable={bunchesAvailable}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            }
            growerLengths={
              <GrowerLengths
                product={productRef?.current}
                selectData={(value: number) => lengthSelectGrower(value)}
                grower={lengthSelected}
                growerSelected={growerSelected}
                activeTab={activeTab}
              />
            }
          />
        );
      default:
        return (
          <div className={`d-flex flex-column flex-grow-1`}>
            <CardViewFormComponent
              product={productRef.current}
              events={events}
              //1.input
              growerSelect={growerSelect()}
              lengthSelect={lengthSelect()}
              lengthSelected={lengthSelected}
              stemInput={stemsInput()}
              validations={validations}
              isProductDetail={isProductDetail!}
              boxSelect={boxSelect()}
              isAList={isAList}
              /*  index={index}
              boxSelect={boxSelect()}
              stemInput={stemsInput()} */
              //*
              growerLengths={
                <GrowerLengths
                  product={productRef.current}
                  selectData={(value: number) => lengthSelectGrower(value)}
                  grower={lengthSelected}
                  //
                  growerSelected={growerSelected}
                  activeTab={activeTab}
                />
              }
              contentPopover={contentPopoverValidation()}
              //2.tabs
              tabsProductPrice={
                <TabsProductPrice
                  grower={lengthSelected}
                  activeTab={activeTab}
                  setActiveTab={(value: string) => setActiveTab(value)}
                  product={productRef.current}
                  boxOnly={boxOnly}
                  isAList={isAList}
                  boxesAvailable={boxesAvailable}
                  isProductDetail={isProductDetail!}
                  /* growerLengths={
                    <GrowerLengths
                      product={productRef.current}
                      selectData={(value: number) => lengthSelectGrower(value)}
                      grower={lengthSelected}
                      //
                      growerSelected={growerSelected}
                      activeTab={activeTab}
                    />
                  }*/
                  contentTab1={
                    <CardQuantityInputs
                      getBoxesValue={getBoxesValue}
                      handleStemChange={handleStemChange}
                      lengthSelected={lengthSelected}
                      product={product}
                      boxSelect={boxSelect()}
                      index={index}
                      stemInput={stemsInput()}
                      validations={validations}
                      activeTab={activeTab}
                      boxesSelected={boxesSelected}
                      boxesAvailable={boxesAvailable}
                      bunchesAvailable={bunchesAvailable}
                    />
                  }
                  contentTab2={
                    !boxOnly ? (
                      <CardQuantityInputs
                        getBoxesValue={getBoxesValue}
                        handleStemChange={handleStemChange}
                        lengthSelected={lengthSelected}
                        product={product}
                        boxSelect={boxSelect()}
                        index={index}
                        stemInput={stemsInput()}
                        validations={validations}
                        activeTab={activeTab}
                        boxesSelected={boxesSelected}
                        boxesAvailable={boxesAvailable}
                        bunchesAvailable={bunchesAvailable}
                      />
                    ) : (
                      <></>
                    )
                  }
                />
              }
            />
            {MessageProductKomet}
            {MessageDisclaimer}

            <CardButtonForm
              submit={submitToShoppingCart}
              greenButton={
                (validations.boxOnly && growerSelected.length > 0) || boxButton
              }
              disabledButton={
                (productState === State.REJECTED && !!productDetail) ||
                (growers && !growerSelected
                  ? isNaN(productList) || productList < 1
                  : !boxesAvailable.length && activeTab === '1') ||
                (bunchesAvailable === 0 && activeTab === '2')
              }
              loadingButton={validations.loadingButton}
              buttonName={getButtonName}
              isProductDetail={isProductDetail!}
            />
          </div>
        );
    }
  };

  return <>{renderForm()}</>;
};

export default React.memo(ProductFormComponent);
