import React from 'react';
import { useSelector } from 'react-redux';
import InventoryProduct from './../../../../Models/InventoryProduct';
import ImageComponent from './../../../Shared/ImageComponent/ImageComponent';
import { roundUp } from './../../../../Utils/Utils';
import { RootState } from '../../../../Store/Reducers';

type Props = {
  product: InventoryProduct;
  productPhotos?: any;
};

const BoxProductInfoComponent: React.FC<Props> = ({
  product,
  productPhotos
}) => {
  const { isAList } = useSelector((state: RootState) => state.CustomBoxReducer);

  return (
    <>
      <div
        className={`d-flex ${isAList ? 'align-items-center' : 'flex-column'} `}>
        <div className={'justify-content-center d-flex my-1 pointer'}>
          <div
            className="overflow-hidden d-flex justify-content-center align-items-center mr-3"
            style={
              isAList
                ? { width: '70px', height: '70px' }
                : { width: '220px', height: '150px' }
            }>
            <ImageComponent
              imageUrl={productPhotos && productPhotos[0]}
              width={isAList ? '140px' : '235px'}
              onErrorUrl={''}
            />
          </div>
        </div>

        <div className={`${isAList ? '' : ' font-weight-bold px-3'}`}>
          <span className=" p-sm-0 ibf-text-info" id={'tooltip_' + product.key}>
            {product.name} {product.length} {'cm'}
          </span>
        </div>
      </div>

      <div className={isAList ? 'd-flex flex-column' : 'px-3 mb-1'}>
        <div
          className="mb-1"
          style={isAList ? {} : { height: '20px', maxHeight: '22px' }}
        />
        <small className={isAList ? '' : 'd-flex flex-column mb-2'}>
          <span className="font-size-sm">
            <b>Bunch (fills {product.custom_percentage + '%'} of box) </b>
          </span>
          {product.stems_per_bunch > 0 && (
            <span className="font-size-sm d-inline-block ">
              ${roundUp(product.price_own_box / product.stems_per_bunch)} / stem
            </span>
          )}
          <span className="font-size-sm d-inline-block ">
            ${roundUp(product.price_own_box)} / bunch{' '}
            {product.customizable &&
              product.stems_per_bunch > 0 &&
              `(${product.stems_per_bunch} st)`}
          </span>
        </small>
      </div>
    </>
  );
};

export default React.memo(BoxProductInfoComponent);
