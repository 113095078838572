import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import Product from '../../../Models/Product';
import {
  addObjectParamsToPath,
  appendTo,
  boxSelectList,
  toast,
  getAvailableBoxes,
  isValidClosingTime,
  USA,
  getBunchesAvailable
} from '../../../Utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { plainToClass } from 'class-transformer';
import CartProduct from '../../../Models/CartProduct';
import AddToCartToast from '../../Shared/AddToCartToast';
import DeliveryDateModal from '../../Shared/Modal/DeliveryDateModal/DeliveryDateModal';
import Environment from '../../../Environment';
import { useHistory, useLocation } from 'react-router';
import MixpanelService from '../../../Services/MixpanelService';
import { GrowerPricing } from '../../../Models/GrowerPricing';
import ProductStatusComponent from './ProductStatusComponent';
import ProductFormComponent from './ProductFormComponent/ProductFormComponent';
import ListViewComponent from '../ProductViewsComponent/ListViewComponent/ListViewComponent';
import CardViewComponent from '../ProductViewsComponent/CardViewComponent/CardViewComponent';
import ProductDetailForm from './ProductDetailComponent/ProductDetailForm';
import * as ModalTypes from '../../../Store/Modal/Types';
import {
  productReducer,
  ProductReducerState,
  Types
} from './ProductComponentReducer';
import { RootState } from '../../../Store/Reducers';
import { actionsModal } from '../../../Store/Modal/Slice';
import { actionsCustomBox } from '../../../Store/CustomBox/Slice';
import { actionsProduct } from '../../../Store/Product/Slice';
import { State } from '../../../Models/State';
import { actionsDisplay } from '../../../Store/Display/Slice';
import { TrackEventMonitor } from '../../../Classes/TrackEvent';
import { helperReducer } from '../../../Store/Selectors';
import { getInventoryAvailableQuantity } from '../../../Services/ProductService';
import { helperActions } from '../../../Store/Helper/Slice';
import MessageDisclaimer from '../ProductViewsComponent/CardViewComponent/MessageDisclaimer';
import MessageProductKommet from '../ProductViewsComponent/CardViewComponent/MessageProductKommet';
import {
  cartReducer,
  customBoxReducer,
  displayReducer,
  filterReducer
} from '../../../Store/Selectors';
import moment from 'moment';

type Props = {
  product: Product;
  isAList: boolean;
  index?: number;
  isDetailView?: boolean;
  isDetailForm?: boolean;
  replaces?: Product[];
  setCustomizableProduct?: (custom: boolean) => void;
};

const initialState: ProductReducerState = {
  grower: { _KEY: '' },
  growerLengths: {},
  growerOptions: [],
  optionSelected: {}
};

const { PENDING, IDLE, RESOLVED, REJECTED } = State;

const ProductComponent: React.FC<Props> = ({
  product,
  isAList,
  index,
  isDetailView,
  setCustomizableProduct,
  isDetailForm,
  replaces
}) => {
  const productRef = useRef(product);
  const [productPhotos, setProductPhotos] = useState(
    productRef.current?.photos?.map((i) => Environment.publicPath + i)
  );

  const history = useHistory();
  const location = useLocation();
  const dispatchRedux = useDispatch();
  const [productList, setProductList] = useState('');

  const [state, dispatch] = useReducer(productReducer, initialState);
  const [listSize, setListSize] = useState('0 cm');
  const [boxesSelected, setBoxesSelected] = useState('1');
  const [stemValidation, setStemValidation] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [loadingButton, setLoadingButton] = useState(IDLE);
  const { user, config } = useSelector((state: RootState) => state.AuthReducer);
  const { date, favorites, state: productState } = useSelector(
    (state: RootState) => state.ProductReducer
  );
  const { search, filters_conditions } = useSelector(filterReducer);
  const { state: stateBox } = useSelector(customBoxReducer);
  const { cartProducts } = useSelector(cartReducer);
  const { headerHeight } = useSelector(displayReducer);
  const { inventoryKey, checkInventory } = useSelector(helperReducer);
  //Open delivery Date modal
  const [openDeliveryDateModal, setOpenDeliveryDateModal] = useState(false);
  const [forcedUpdate, setForceUpdate] = useState(Date.now());
  const toggleDeliveryDateModal = useCallback(
    () => setOpenDeliveryDateModal(!openDeliveryDateModal),
    [openDeliveryDateModal]
  );

  const [custom, setCustom] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const [selectedBunch, setSelectedBunch] = useState('1');
  const [selectedDealBunch, setSelectedDealBunch] = useState('1');
  const [bunchesAvailable, setBunchesAvailable] = useState(0);

  ///
  let {
    //price_own_box,
    total_box,
    //stem_price,
    //price_full_box,
    stems_per_bunch,
    percentage,
    available
  } = state.optionSelected;
  const [boxesAvailable, setBoxesAvailable] = useState<number[]>([]);
  const { currentPercentage } = useSelector(customBoxReducer);
  //
  const [totalStemsBox, setTotalStemsBox] = useState('');
  React.useEffect(() => {
    setBoxesAvailable(getAvailableBoxes(available, total_box));
  }, [available, total_box]);

  useEffect(() => {
    const bunches = getBunchesAvailable(
      available,
      percentage,
      currentPercentage
    );
    if (typeof bunches !== 'undefined') {
      setBunchesAvailable(
        getBunchesAvailable(available, percentage, currentPercentage)
      );
    }
  }, [available, percentage, currentPercentage]);

  useEffect(() => {
    const photos = productRef.current?.photos?.map(
      (i) => Environment.publicPath + i
    );
    const photoGrower = productRef.current?.photos.filter(
      (item) => item.includes('growers') || !item.includes('varieties')
    );
    if (photoGrower.length > 0 && productRef.current?.growers?.length === 1) {
      let photo = photoGrower.map((i) =>
        i.includes('grower') ? Environment.publicPath + i : i
      );
      setProductPhotos(photo);
    } else {
      setProductPhotos(photos);
    }
  }, [productRef]);

  useEffect(() => {
    if (productState !== PENDING || stateBox !== PENDING) {
      setLoadingButton(RESOLVED);
    }
  }, [productState, stateBox]);

  useEffect(() => {
    productRef.current = product;

    if (isDetailForm || isDetailView) {
      setForceUpdate(Date.now());

      setCustom(product.customizable ?? true);
    }

    return () => {
      productRef.current = new Product();
    };
  }, [product, isDetailForm, isDetailView]);

  useEffect(() => {
    if (state.grower._KEY.length > 0 && boxesAvailable.length === 1) {
      setBoxesSelected('1');
    } else {
      setBoxesSelected('0');
    }
    if (
      ((state.grower._KEY.length > 0 && boxesAvailable.length > 1) ||
        (productList.length > 0 &&
          productRef?.current?.growers?.length! < 2)) &&
      activeTab === '1'
    ) {
      setBoxesSelected('1');
      setProductList('1');
    } else {
      setBoxesSelected('0');
    }
    if (activeTab === '2' && selectedBunch === '1') {
      setProductList('1');
    } else if (selectedDealBunch === '1') {
      setProductList((stems_per_bunch * total_box).toString());
      setBoxesSelected('1');
    }
  }, [
    state.grower._KEY,
    boxesAvailable,
    activeTab,
    productList,
    selectedBunch,
    total_box,
    stems_per_bunch,
    selectedDealBunch,
    productRef
  ]);

  const resetSelects = useCallback(() => {
    const box_list: HTMLSelectElement | any = document.getElementById(
      'box_list_' + productRef.current.key + '_' + index
    );
    if (box_list && state.grower._KEY.length > 0) {
      box_list.selectedIndex = 0;
      if (activeTab === '1') {
        setBoxesSelected('1');
      } else {
        setBoxesSelected('0');
      }
    }

    const bunch_list: HTMLSelectElement | any = document.getElementById(
      'bunch_list_' + productRef.current.key + '_' + index
    );
    if (bunch_list) {
      bunch_list.selectedIndex = 0;
      setProductList('');
    }
  }, [state.grower._KEY, index, activeTab]);

  useEffect(() => {
    if (
      state.optionSelected?.customizable !== undefined &&
      state.optionSelected?.customizable !==
        productRef?.current?.customizable &&
      setCustomizableProduct
    ) {
      setCustomizableProduct(state.optionSelected.customizable);
    }

    if (isDetailForm) {
      setCustom(state.optionSelected.customizable ?? true);
    }

    return () => {};
  }, [state.optionSelected, setCustomizableProduct, isDetailForm]);

  useEffect(() => {
    resetSelects();
  }, [state.optionSelected, resetSelects]);

  useEffect(() => {
    const growers = productRef.current?.pricing
      ? Object.values(productRef.current.pricing).reduce(
          (arr: any[], grower: GrowerPricing) => {
            const option = grower.options.reduce((previous, current) => {
              return current.length < previous.length ? current : previous;
            });

            arr.push({
              grower_name: grower.grower_name,
              _KEY: grower.grower,
              country: grower.country,
              komet_intg: grower.komet_intg,
              closing_time: grower.closing_time,
              stem_price: option.stem_price,
              stems_per_bunch: option.stems_per_bunch,
              grower_quantity: Object.keys(productRef.current?.pricing!).length
            });
            return arr;
          },
          []
        )
      : [];

    if (growers.length) {
      let list = [...growers];

      if (filters_conditions.grower?.length > 0) {
        const grower = growers.find(
          (grower: GrowerPricing) =>
            grower._KEY === filters_conditions.grower[0]
        );
        if (grower) {
          dispatch({ type: 'SET_GROWER', payload: { grower } });
          list.unshift(grower);
          list = [...new Set(list)];
        }
      } else {
        if (growers.length === 1) {
          dispatch({ type: 'SET_GROWER', payload: { grower: growers[0] } });
        } else {
          let temp = {
            grower_name: 'Best deal (calculated at checkout)',
            _KEY: ''
          };
          list.push(temp);
          const listGrowerSort = [...list];

          listGrowerSort.sort(
            (a: GrowerPricing, b: GrowerPricing) => a.stem_price - b.stem_price
          );
          dispatch({
            type: 'SET_GROWER',
            payload: { grower: listGrowerSort[0] }
          });
        }
      }
      dispatch({
        type: Types.SET_GROWER_OPTIONS,
        payload: {
          growers: list
        }
      });
    }
  }, [filters_conditions.grower, forcedUpdate]);

  useEffect(() => {
    let lengths = {};

    if (state.growerOptions.length) {
      dispatch({ type: Types.RESET_LENGTHS });
      lengths =
        productRef.current?.pricing &&
        Object.values(productRef.current.pricing).reduce(
          (obj: any, grower: GrowerPricing) => {
            if (
              grower.grower === state.grower._KEY ||
              state.grower._KEY.length === 0
            ) {
              grower.options.forEach((option: GrowerPricing) => {
                if (obj[option.length]) {
                  if (obj[option.length]?.stem_price > option.stem_price) {
                    obj[option.length] = option;
                  }
                } else {
                  obj[option.length] = option;
                }
              });
            }
            return obj;
          },
          {}
        );
    } else {
      lengths = productRef.current.lengths
        ?.split(',')
        .reduce((obj: any, length: string) => {
          const intLength = parseInt(length);
          obj[intLength] = { length: intLength };
          return obj;
        }, {});
    }

    if (lengths && Object.keys(lengths) && Object.keys(lengths).length > 0) {
      const sortedLengths: any[] = Object.values(lengths).sort(
        (a: any, b: any) => {
          if (a.stem_price < b.stem_price) return -1;
          if (a.stem_price > b.stem_price) return 1;
          return 0;
        }
      );
      setListSize(sortedLengths[0].length.toString() + ' cm');
      lengths = sortedLengths;
    } else {
      if (productRef.current?.lengths) {
        setListSize(productRef.current.lengths.split(',')[0] + ' cm');
        lengths = productRef.current.lengths
          ?.split(',')
          .reduce((obj: any, length: string) => {
            const intLength = parseInt(length);
            obj[intLength] = { length: intLength };
            return obj;
          }, {});
      } else {
        lengths = { 0: { length: 0 } };
      }
    }

    const list: HTMLSelectElement | any = document.getElementById('size-list');
    if (list) {
      list.selectedIndex = 0;
    }

    let optionSelected = Object.values(lengths).sort(
      (a: any, b: any) => a.length - b.length
    );

    if (optionSelected[0]) {
      dispatch({
        type: Types.SET_OPTION_SELECTED,
        payload: { option: optionSelected[0] }
      });
    }

    dispatch({ type: Types.SET_GROWER_LENGTHS, payload: lengths });
  }, [state.grower._KEY, state.growerOptions.length]);

  const goToShopping = useCallback(() => {
    let filters = Object.assign({}, filters_conditions);
    if (search) {
      filters.search_val = search;
    }
    filters.date = date;
    history.push(addObjectParamsToPath('/search', filters));
  }, [history, date, filters_conditions, search]);

  const getProductImageToast = useCallback(() => {
    if (productPhotos) {
      if (
        productPhotos.some(
          (p) => !p.includes('growers') && !p.includes('varieties')
        )
      ) {
        return productPhotos.filter(
          (p) => !p.includes('growers') && !p.includes('varieties')
        )[0];
      }

      return productPhotos[0];
    }

    return '';
  }, [productPhotos]);

  const submitToShoppingCart = useCallback(async () => {
    let stems = parseInt(productList);
    setLoadingButton(PENDING);
    if (
      state.grower._KEY &&
      productList.length === 0 &&
      (parseInt(boxesSelected) === 0 || isNaN(parseInt(boxesSelected)))
    ) {
      setLoadingButton(REJECTED);
      return setStemValidation(true);
    } else if (
      !state.grower._KEY &&
      (stems <= 0 || !Number.isInteger(stems)) &&
      productRef.current.customizable
    ) {
      setLoadingButton(REJECTED);
      setStemValidation(true);
      return toast('Please type the number of stems', 'warning');
    } else if (date === 0) {
      return toggleDeliveryDateModal();
    } else {
      let today = parseInt(
        moment(new Date())
          .tz('America/New_York')
          .format('YYYYMMDD')
      );

      const {
        inventory,
        charge_date,
        packing_date,
        total_box,
        eta_date: arrival_date,
        percentage
      } = state.optionSelected;

      let is_closing_time_product = false;

      if (state.grower._KEY && packing_date === today) {
        if (state.grower.closing_time && state.grower.country === USA) {
          if (!isValidClosingTime(state.grower.closing_time, packing_date)) {
            toast('The product is not available in this moment.', 'error');
            setLoadingButton(RESOLVED);
            return;
          }

          const currentHour = moment(new Date())
            .tz('America/New_York')
            .hours();

          if (currentHour > 8) {
            is_closing_time_product = true;
          }
        }
      }

      appendTo(
        'root',
        productRef.current.key,
        <AddToCartToast
          titleToast="Adding to your cart"
          show
          navHeight={headerHeight}
          body={`${productRef.current.name} ${listSize}`}
          flowerImg={getProductImageToast()}
          productKey={productRef.current.key}
        />
      );

      const customer: string = user.metadata.customer;
      const boxOnly =
        state.grower._KEY.length > 0 && parseInt(boxesSelected) > 0;

      if (state.grower._KEY.length > 0 && !boxOnly) {
        const grower = productRef.current.growers?.find(
          (grower) => grower.key === state.grower._KEY
        );
        dispatchRedux(
          actionsModal.setOpenModal({
            modal: ModalTypes.CUSTOM_BOX_MODAL,
            data: { grower, eta_date: arrival_date }
          })
        );
        dispatchRedux(
          actionsCustomBox.addToBox({
            boxParams: {
              customer,
              invKey: inventory,
              arrival_date,
              charge_date,
              packing_date,
              is_closing_time_product,
              quantity: parseInt(productList)
            },
            grower: grower ? grower.key : '',
            percentage: parseInt(productList) * percentage
          })
        );

        if (window.dataLayer) {
          window.dataLayer.push({ event: 'Add to cart', value: '' });
        }

        resetSelects();
        setLoadingButton(RESOLVED);
      } else if (
        state.grower._KEY.length > 0 &&
        boxOnly &&
        parseInt(boxesSelected) > 0
      ) {
        dispatchRedux(
          actionsCustomBox.addToCart({
            invKey: inventory,
            quantity: total_box,
            numberBoxes: parseInt(boxesSelected),
            arrival_date,
            packing_date,
            customer,
            accept_date: true,
            is_closing_time_product,
            key: productRef.current.key
          })
        );

        if (window.dataLayer) {
          window.dataLayer.push({ event: 'Add to cart', value: '' });
        }

        resetSelects();
        if (isDetailForm) {
          goToShopping();
        }
      } else {
        //reset stems
        setSelectedDealBunch('1');
        const params = plainToClass(CartProduct, {
          ...productRef.current,
          box_only: boxOnly ? boxOnly : productRef.current.box_only,
          length: listSize.replace('cm', ''),
          delivery_date: date,
          quantity:
            productRef.current.box_only ||
            !productRef.current.customizable ||
            boxOnly
              ? parseInt(boxesSelected)
              : productList.replace(' stems', ''),
          grower: state.grower._KEY
        });

        TrackEventMonitor.newEvent({
          props: {
            delivery_date: date,
            quantity:
              productRef.current.box_only ||
              !productRef.current.customizable ||
              boxOnly
                ? parseInt(boxesSelected)
                : productList.replace(' stems', ''),
            length: listSize.replace('cm', '').trim(),
            variety_key: productRef.current.key
          },
          metadata: {
            name: 'ibf_add_to_shopping_list'
          }
        });

        if (window.dataLayer) {
          window.dataLayer.push({ event: 'Add to cart', value: '' });
        }

        if (cartProducts && cartProducts[date]) {
          const sameProduct = cartProducts[date].find(
            (prod: any) =>
              prod.productKey === productRef.current.key &&
              prod.length === parseInt(listSize) &&
              prod.box_only === boxOnly
          );
          if (sameProduct) {
            const quantity =
              parseInt(
                productRef.current.box_only ||
                  !productRef.current.customizable ||
                  boxOnly
                  ? boxesSelected
                  : productList
              ) + sameProduct.quantity;
            dispatchRedux(
              actionsProduct.updateProductQuantity({
                productKey: sameProduct.key,
                quantity
              })
            );
          } else {
            dispatchRedux(actionsProduct.addProduct({ params, customer }));
          }
        } else {
          dispatchRedux(actionsProduct.addProduct({ params, customer }));
        }

        const mixpanelParams = {
          ...params,
          name: productRef.current.name,
          growerName: state.grower._KEY ? state.grower.name : 'All'
        };

        if (isDetailView) {
          MixpanelService.track('add_to_cart_substitute', {
            ...mixpanelParams
          });
        }

        if (location.pathname === '/product') {
          //How do I make difference between add to cart from substitutes and detail
          MixpanelService.track('add_to_cart_product_details', {
            ...mixpanelParams
          });
        } else {
          MixpanelService.track('add_to_cart_buying_page', {
            ...mixpanelParams
          });
        }

        if (isDetailForm) {
          goToShopping();
        }
      }
    }
  }, [
    date,
    dispatchRedux,
    listSize,
    productList,
    toggleDeliveryDateModal,
    user.metadata.customer,
    headerHeight,
    boxesSelected,
    location,
    isDetailView,
    cartProducts,
    state,
    goToShopping,
    isDetailForm,
    resetSelects,
    getProductImageToast
  ]);

  useEffect(() => {
    if (openDeliveryDateModal && date !== 0) {
      setOpenDeliveryDateModal(!openDeliveryDateModal);
      submitToShoppingCart();
    }
  }, [date, openDeliveryDateModal, submitToShoppingCart]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setPagePosition(window.pageYOffset);
  //   };
  //
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    if (favorites[productRef.current.key]) {
      productRef.current = {
        ...productRef.current,
        favorite_key: favorites[productRef.current.key].favorite_key
      };
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
  }, [favorites]);
  //
  // React.useEffect(() => {
  //   if (pagePosition){
  //
  //   }
  // }, [pagePosition, dispatchRedux]);

  /// On click open the detail of the product
  const detail = useCallback(() => {
    if (!productRef.current?.pricing) {
      return;
    }
    const params: any = { id: productRef.current.key, date };
    if (location.pathname !== '/product') {
      MixpanelService.track('product_details_view', {
        ...params,
        name: productRef.current.name
      });
    }
    if (isDetailView) {
      MixpanelService.track('product_details_substitute_link', {
        name: productRef.current.name
      });
    }

    dispatchRedux(actionsDisplay.setScrollPosition(window.pageYOffset));

    history.push(addObjectParamsToPath('/product', params));
  }, [history, isDetailView, location, date, dispatchRedux]);

  const getRightSideRender = () => {
    const form = (
      <ProductFormComponent
        isAList={isAList}
        loadingButton={loadingButton === PENDING}
        buttonName="Add to cart"
        bunchesAvailable={bunchesAvailable}
        // boxOnly={product.box_only || !product.customizable}
        boxOnly={
          isDetailForm
            ? !custom || product.box_only || !product.customizable
            : product.box_only ||
              !product.customizable ||
              (state.grower._KEY &&
                state.growerOptions.find(
                  (grower: GrowerPricing) => grower._KEY === state.grower._KEY
                )?.komet_intg)
        }
        boxSelectList={boxSelectList()}
        boxesSelected={boxesSelected}
        setBoxesSelected={setBoxesSelected}
        submitToShoppingCart={submitToShoppingCart}
        setListSize={setListSize}
        stemValidation={stemValidation}
        setStemValidation={setStemValidation}
        productList={parseInt(productList)}
        setProductList={setProductList}
        growers={state.growerOptions}
        growerSelected={state.grower._KEY}
        growerLengths={state.growerLengths}
        isProductDetail={isDetailForm}
        dispatch={dispatch}
        lengthSelected={state.optionSelected}
        index={index}
        product={productRef.current}
        MessageDisclaimer={
          isDetailForm &&
          config?.tintedAlert?.tinted_terms.some((item) =>
            product?.name?.toLowerCase().includes(item)
          ) ? (
            <MessageDisclaimer className="mx-1" />
          ) : (
            <></>
          )
        }
        ///
        custom={custom}
        boxesAvailable={boxesAvailable}
        activeTab={activeTab}
        setActiveTab={(value: string) => setActiveTab(value)}
        ///

        setSelectedBunch={(value: string) => setSelectedBunch(value)}
        selectedDealBunch={selectedDealBunch}
        setselectedDealBunch={(value: string) => setSelectedDealBunch(value)}
        //
        totalStemsBox={totalStemsBox}
        setTotalStemsBox={(value: any) => setTotalStemsBox(value)}
        ///
        MessageProductKomet={
          isDetailForm &&
          product?.pricing &&
          Object.values(product?.pricing!)?.some((item) => item?.komet_intg) ? (
            <MessageProductKommet className="mx-1 py-1 my-1" />
          ) : (
            <></>
          )
        }
      />
    );

    return (
      <ProductStatusComponent
        product={productRef.current}
        form={form}
        detail={detail}
      />
    );
  };

  const renderProduct = () => {
    switch (true) {
      case isDetailForm:
        return (
          <ProductDetailForm
            form={getRightSideRender()}
            favorite={isFavorite}
            product={productRef.current}
            replaces={replaces}
          />
        );
      case isAList:
        return (
          <ListViewComponent
            product={productRef.current}
            index={index}
            detail={detail}
            productPhotos={productPhotos}
            form={getRightSideRender()}
          />
        );
      default:
        return (
          <CardViewComponent
            product={productRef.current}
            index={index}
            detail={detail}
            favorite={isFavorite}
            productPhotos={productPhotos}
            form={getRightSideRender()}
          />
        );
    }
  };

  React.useEffect(() => {
    const getAvailabilityFromInv = async () => {
      let lengths = { ...state.growerLengths };
      if (
        Object.keys(lengths).some(
          (index) => lengths[index].inventory === inventoryKey
        )
      ) {
        setLoadingButton(State.PENDING);
        const response = await getInventoryAvailableQuantity(inventoryKey);

        if (response.success) {
          const inv = response.results[0];
          Object.keys(lengths).forEach((index) => {
            if (
              lengths[index].inventory === inventoryKey &&
              lengths[index].uom
            ) {
              const available =
                lengths[index].uom === 'stem'
                  ? Math.floor(inv.quantity / lengths[index].stems_per_bunch)
                  : inv.quantity;
              lengths = {
                ...lengths,
                [index]: { ...lengths[index], available }
              };
              if (state.optionSelected.available !== available) {
                dispatch({
                  type: Types.SET_OPTION_SELECTED,
                  payload: { option: { ...state.optionSelected, available } }
                });
              }
            }
          });

          dispatch({ type: Types.SET_GROWER_LENGTHS, payload: lengths });
        }
        setLoadingButton(State.RESOLVED);
        dispatchRedux(helperActions.setClearInventory());
      }
    };

    if (checkInventory && inventoryKey) {
      getAvailabilityFromInv();
    }
  }, [
    state.optionSelected,
    inventoryKey,
    checkInventory,
    dispatch,
    state.growerLengths,
    dispatchRedux
  ]);

  return (
    <>
      {renderProduct()}
      {/*</Popover>*/}
      <DeliveryDateModal
        openDeliveryDateModal={openDeliveryDateModal}
        toggleDeliveryDateModal={toggleDeliveryDateModal}
        className="ibf-delivery-date-modal"
      />
    </>
  );
};

export default React.memo(ProductComponent);
