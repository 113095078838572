import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../Models/State';
import CheckoutProducts from './CheckoutProducts';
import { Cartbox } from '../../../Models/Cartbox';
import { CartboxItem } from '../../../Models/CartboxItem';
import { checkoutReducer, loadingReducer } from '../../../Store/Selectors';
import CheckoutBoxHeader from './CheckoutBoxHeader';
import Skeleton from 'react-loading-skeleton';
type Props = {
  product: Cartbox;
  indexProduct: number;
  isBoxOpen?: boolean;
};

const CheckoutCustomBox: React.FC<Props> = ({
  product,
  indexProduct,
  isBoxOpen = false
}) => {
  const { state: checkoutState } = useSelector(checkoutReducer);
  const { productKey } = useSelector(loadingReducer);
  const [deleteLoading, setDeleteLoading] = useState(State.IDLE);

  useEffect(() => {
    if (checkoutState === State.REJECTED) {
      setDeleteLoading(State.REJECTED);
    }
  }, [checkoutState]);

  return (
    <>
      <div className="d-flex flex-column border-bottom">
        {deleteLoading === State.PENDING ? (
          <div className="w-100">
            <Skeleton height={'65px'} />
          </div>
        ) : (
          <CheckoutBoxHeader
            product={product}
            fromBox
            setDeleteLoading={setDeleteLoading}
          />
        )}

        <div className="d-flex flex-column">
          {product?.items?.map((item: CartboxItem, index) => {
            return (
              <Fragment
                key={
                  item._KEY +
                  '_checkout_product_item_' +
                  index +
                  '_' +
                  +'_' +
                  new Date(product._META.updated_time).toISOString()
                }>
                {productKey.some((element: string) =>
                  item?._KEY?.includes(element)
                ) || deleteLoading === State.PENDING ? (
                  <div className="w-100">
                    <Skeleton height={'65px'} />
                  </div>
                ) : (
                  <CheckoutProducts
                    key={
                      item._KEY +
                      '_checkout_product_item_' +
                      index +
                      '_' +
                      +'_' +
                      new Date(product._META.updated_time).toISOString()
                    }
                    fromBox
                    eta_date={product.eta_date}
                    custom={true}
                    setDeleteLoading={setDeleteLoading}
                    product={item}
                    isBoxOpen={isBoxOpen}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CheckoutCustomBox;
