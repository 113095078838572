import { faAngleDoubleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import Product from '../../../../Models/Product';
import {
  convertNumberDateMMMDD,
  convertNumberDateToDate,
  isLessThan15Days
} from '../../../../Utils/Utils';
import { RootState } from '../../../../Store/Reducers';

type Props = {
  product: Product;
  index?: number;
  detail?: () => void;
};
const CardViewInfo: React.FC<Props> = ({ product, detail }) => {
  const { date } = useSelector((state: RootState) => state.ProductReducer);
  return (
    <>
      <div className="d-flex w-100 flex-column">
        <div className="d-flex flex-column ibf-product-name-wrapper">
          <div className="px-2 font-weight-bold ibf-text-area">
            <span
              className={`p-sm-0 ibf-text-info ${
                !product.pricing ? 'text-decoration-none' : 'clickable'
              }`}
              id={'tooltip_' + product.key}
              onClick={detail}>
              {product.name}{' '}
              {!!product.pricing && (
                <span className="ibf-contact-text">
                  more info <FontAwesomeIcon icon={faAngleDoubleRight} />
                </span>
              )}
            </span>
          </div>

          {product.pricing &&
            product.isAvailable &&
            !product.pricing &&
            !isLessThan15Days(convertNumberDateToDate(date)) && (
              <div className="ibf-product-available-mobile">
                <div className="mb-3">
                  <>
                    <small>
                      <b className="color-green">
                        Product is available for {convertNumberDateMMMDD(date)},
                        but pricing and ordering only 2 weeks before.
                      </b>
                    </small>
                  </>
                </div>
              </div>
            )}
        </div>

        <UncontrolledTooltip
          placement="bottom"
          target={'tooltip_' + product.key}>
          {product.name}
        </UncontrolledTooltip>
      </div>

      <span />
    </>
  );
};
export default CardViewInfo;
