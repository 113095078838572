import { CreateEventParams, UpdateEventParams } from '../Models/EventCartbox';
import { sbxCoreService } from '../Network';

export function addNewEventService(params: CreateEventParams) {
  return sbxCoreService
    .with('cartbox_event')
    .insert({ ...params, active: true })
    .then((res: any) => {
      return res;
    });
}

export function getEventListService(customer_key: string) {
  return sbxCoreService
    .with('cartbox_event')
    .andWhereIsEqualTo('customer', customer_key)
    .find()
    .then((res) => res);
}

export function updateCartboxEventService(
  cartbox: string,
  event: string | null
) {
  const data = { _KEY: cartbox, event: event };
  return sbxCoreService
    .with('cart_box')
    .update(data, true)
    .then((res) => res);
}

export function updateEventActiveStatus(active: boolean, event: string) {
  return sbxCoreService
    .with('cartbox_event')
    .update({ _KEY: event, active })
    .then((res) => res);
}

export function updateEventService(events: Array<UpdateEventParams>) {
  return sbxCoreService
    .with('cartbox_event')
    .update(events)
    .then((res) => res);
}

export function deleteEventService(event: string) {
  return sbxCoreService
    .with('cartbox_event')
    .andWhereIsEqualTo('_KEY', event)
    .delete()
    .then((res) => res);
}
