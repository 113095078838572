import { actionsCart } from './Slice';
import { listSLProduct } from '../../Services/ProductService';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { LIST_CART } from './Types';
import { actionsLoading } from '../Loading/Slice';

export function* listCartSaga({
  payload: { customer }
}: ReturnType<typeof actionsCart.listCart>): any {
  try {
    const result = yield call(listSLProduct, customer);
    if (result && result.success) {
      yield put(actionsCart.setProductsToListCart(result.response));
    }
  } catch (e) {
    console.error('Error on add list cart saga');
    yield put(actionsCart.setRejected());
    yield put(actionsLoading.setAddingProduct(false));
  }
}

export default function* AllSagas() {
  yield all([
    //Auth sagas
    takeEvery(LIST_CART, listCartSaga)
  ]);
}
