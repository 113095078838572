import { runCS } from '../Network';
import Environment from '../Environment';

export const getStatements = (customer: string) => {
  return runCS(Environment.cloudscripts.ibf_statements, {
    customer
  })
    .then((res) => {
      if (res.success) {
        return { success: true, data: res };
      } else {
        return { success: false };
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
