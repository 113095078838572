import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import SortOptionsDesktopComponent from './SortOptionsDesktopComponent/SortOptionsDesktopComponent';
import { RootState } from '../../../Store/Reducers';
import { actionsLoading } from '../../../Store/Loading/Slice';

type Props = {
  toggleFilterMobileView: () => void;
  filtersCount: number;
};

const DisplayFilterComponent: React.FC<Props> = ({
  toggleFilterMobileView,
  filtersCount
}) => {
  const dispatch = useDispatch();

  const { isAList } = useSelector((state: RootState) => state.LoadingReducer);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(actionsLoading.setIsAList());
  };

  const showFilter = () => {
    const divFilters = document.querySelector('.contFiltersMainD');
    
    if(divFilters) {
      divFilters.className += ' showFilter';
    } 
  }

  return (
    <div className="w-100">
      <div className="d-flex  pb-2 pb-lg-0">
        <div className="ibf-display-filter">
          <span
            className="text-center pointer"
            style={{
              color: '#4A4A4A',
              border: '1px solid #E7E2DF',
              borderRadius: '22px',
              lineHeight: '30px',
              width: '100%'
            }}
            // onClick={toggleFilterMobileView}
            onClick={showFilter}
            >
            Filters{' '}
            <span className=" text-center ibf-notification-filter">
              {' '}
              {filtersCount}{' '}
            </span>
          </span>
        </div>

        <div className="ibf-display-toggle">
          <div className="d-flex ">
            <label
              htmlFor="customSwitch1"
              className="ibf-text-navbar mr-2 d-flex d-lg-none">
              Icon
            </label>
            <span
              className="d-none d-lg-flex ibf-text-navbar pr-2"
              style={{ color: '#4A4A4A' }}>
              Show: Icon view{' '}
            </span>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                checked={isAList}
                onChange={onChange}
                className="custom-control-input"
                id="customSwitch1"
              />
              <label
                style={{ cursor: 'pointer', color: '#4A4A4A' }}
                className="custom-control-label ibf-text-navbar d-flex d-lg-none"
                htmlFor="customSwitch1">
                List
              </label>
              <label
                style={{ cursor: 'pointer', color: '#4A4A4A' }}
                className="custom-control-label ibf-text-navbar d-lg-flex d-none"
                htmlFor="customSwitch1">
                List view
              </label>
            </div>
          </div>
        </div>

        <div>
          <SortOptionsDesktopComponent />
        </div>

        <div className="ibf-display-sort">
          <Button
            onClick={() => dispatch(actionsLoading.openSortOptions())}
            className="pointer ibf-btn-ant ibf-btn-sort-mobile">
            Sorting
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DisplayFilterComponent;
