import React from 'react';
import ImageComponent from '../ImageComponent/ImageComponent';
import Environment from '../../../Environment';
import flowerDeliveryDateImage from '../../../Assets/Images/Flowers-open-up.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Store/Reducers';

const CalendarFooter = () => {
  const { manager } = useSelector((state: RootState) => state.AuthReducer);
  return (
    <div className="ibf-calendar-tip">
      <div className="d-flex align-items-center">
        <ImageComponent
          className="mr-2"
          imageUrl={`${Environment.publicPath}images/${
            manager?.email?.split('@')[0]
          }.png`}
          width="35px"
        />
        <span>
          For events we advise you to{' '}
          <b>choose a delivery date 3-5 days before</b>, because flowers come so
          fresh that they need time to fully open:
        </span>
      </div>

      <div>
        <img
          src={flowerDeliveryDateImage}
          className="img-fluid"
          alt="delivery_date_modal_image"
        />
      </div>
    </div>
  );
};

export default CalendarFooter;
