import React, {
  CSSProperties,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import ImageComponent from '../ImageComponent/ImageComponent';
import {
  addObjectParamsToPath,
  convertNumberDateDDDMMMYYYY,
  getCountDownInterval,
  roundUp,
  toast
} from '../../../Utils/Utils';
import Environment from '../../../Environment';
import { useDispatch, useSelector } from 'react-redux';
import { OrderRequest } from '../../../Models/OrderRequest';
import { OrderRequestItem } from '../../../Models/OrderRequestItem';
//import { State } from '../../../Models/State';
import isEqual from 'lodash.isequal';
import MixpanelService from '../../../Services/MixpanelService';
import { RootState } from '../../../Store/Reducers';
import { actionsShoppingList } from '../../../Store/ShoppingList/Slice';
import { actionsCheckout } from '../../../Store/Checkout/Slice';
import { TrackEventMonitor } from '../../../Classes/TrackEvent';
import { Cartbox } from '../../../Models/Cartbox';
import { CartboxItem } from '../../../Models/CartboxItem';
import CheckoutBoxHeader from './CheckoutBoxHeader';
import { IncompleteProduct } from '../../../Models/Product';
import MessageProductKommet from '../../ProductWrapperComponent/ProductViewsComponent/CardViewComponent/MessageProductKommet';
// import {Button} from 'reactstrap';
import { Statistic } from 'antd';
import moment from 'moment/moment';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { loadingReducer } from '../../../Store/Selectors';
import {
  closeBoxService,
  removeFromBoxService
} from '../../../Services/ProductService';
import { State } from '../../../Models/State';
import { listCart } from '../../../Services/CheckoutService';

type Props = {
  product: Cartbox | CartboxItem | OrderRequestItem | IncompleteProduct;
  custom: boolean;
  eta_date: any;
  incomplete?: boolean;
  orderRequest?: boolean;
  isBoxOpen?: boolean;
  fromBox?: boolean;
  toggle?: () => void;
  setDeleteLoading?: (state: State) => void;
};

enum TypeActions {
  change,
  init
}

interface Action {
  type: TypeActions;
  payload: any;
}

const initialState = {
  productGroupName: '',
  varietyName: '',
  colorName: '',
  image: '',
  length: '',
  date: 0,
  country: '',
  pricePerStem: 0,
  pricePerBunch: 1,
  box: '',
  bunch: 0,
  quantity: 0
};

const isRedStyle: CSSProperties = {
  border: `3px solid #D9001B`,
  background: 'rgba(217, 0, 27, 0.2)'
};
const isOrangeStyle: CSSProperties = {
  border: `3px solid #f19923`,
  background: 'rgba(241, 153, 35, 0.2)'
};

const reducer = (state: any, { type, payload }: Action) => {
  switch (type) {
    case TypeActions.change:
      return { ...state, [payload.name]: payload.value };
    case TypeActions.init:
      return isEqual(state, initialState) ? payload : state;
    default:
      throw new Error();
  }
};

const { Countdown } = Statistic;

const CheckoutProducts: React.FC<Props> = ({
  product,
  eta_date,
  custom,
  incomplete,
  orderRequest,
  isBoxOpen,
  fromBox,
  toggle,
  setDeleteLoading
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const history = useHistory();
  const [stemInfoOptions, setStemInfoOptions] = useState('');
  const { itemKeyDeleteCartbox } = useSelector(loadingReducer);
  const [loading, setLoading] = useState(State.IDLE);
  const dispatchAction = useDispatch();
  const { customer } = useSelector(
    (state: RootState) => state.AuthReducer.user.metadata
  );
  const {
    orderRequests,
    itemsInCart,
    //state: CheckoutState,
    products
  } = useSelector((state: RootState) => state.CheckoutReducer);

  const productRef = useRef(product);

  useEffect(() => {
    productRef.current = product;

    return () => {
      //productRef.current = null;
    };
  }, [product]);

  useEffect(() => {
    if (productRef && !(incomplete || orderRequest)) {
      dispatch({
        type: TypeActions.init,
        payload: {
          productGroupName:
            (productRef.current as Cartbox).product_group?.common_name || '',
          varietyName:
            (productRef.current as Cartbox).variety?.variety_name || '',
          colorName: custom
            ? (productRef.current as CartboxItem).variety.color.color_name
            : (productRef.current as Cartbox)?.items[0]?.variety.color
                .color_name ?? '',
          image: (productRef.current as Cartbox).img
            ? Environment.publicPath +
              ((productRef.current as Cartbox)?.img &&
                (productRef.current as Cartbox).img[0])
            : Environment.publicPath +
              ((productRef.current as CartboxItem).images &&
                (productRef.current as CartboxItem)?.images[0]),
          length: (productRef.current as Cartbox).length
            ? (productRef.current as Cartbox).length
            : (productRef.current as CartboxItem).masterlist?.length,
          date: (productRef.current as Cartbox).eta_date
            ? (productRef.current as Cartbox).eta_date
            : eta_date,
          country: (productRef.current as Cartbox).grower?.country || '',
          pricePerStem:
            (productRef.current as Cartbox).items &&
            (productRef.current as Cartbox).items[0]?.stems_per_bunch
              ? (productRef.current as Cartbox).price /
                ((productRef.current as Cartbox).items[0].stems_per_bunch
                  .stems_per_bunch *
                  (productRef.current as Cartbox).current_items)
              : (productRef.current as CartboxItem).price /
                (productRef.current as CartboxItem).stems_per_bunch
                  .stems_per_bunch,
          pricePerBunch:
            (productRef.current as Cartbox).price /
            (productRef.current as Cartbox).current_items,
          box: custom
            ? `${Math.ceil(
                (productRef.current as Cartbox).percentage *
                  productRef.current.quantity
              )} % of 1 box`
            : `${productRef.current.quantity} ${
                productRef.current.quantity === 1 ? ' box' : 'boxes'
              }`,
          stem: custom
            ? (productRef.current as Cartbox).stems_per_bunch?.stems_per_bunch
            : (productRef.current as Cartbox).items
            ? (productRef.current as Cartbox).items[0]?.stems_per_bunch
                ?.stems_per_bunch
            : (productRef.current as Cartbox).stems_per_bunch?.stems_per_bunch,
          bunch: custom
            ? productRef.current.quantity
            : (productRef.current as Cartbox).current_items,
          quantity: custom ? 1 : productRef.current.quantity,
          custom: custom
        }
      });
    } else {
      if (incomplete || orderRequest) {
        dispatch({
          type: TypeActions.init,
          payload: {
            productGroupName:
              (productRef.current as Cartbox).product_group?.common_name ||
              (productRef.current as IncompleteProduct).name ||
              (productRef.current as OrderRequestItem).label_name ||
              '',
            varietyName:
              (productRef.current as Cartbox).variety?.variety_name || '',
            image: `${Environment.publicPath}varieties/variety_${productRef.current.variety}.jpg`,
            length: (productRef.current as Cartbox).length,
            colorName: '',
            date: eta_date,
            quantity: productRef.current.quantity
          }
        });
      }
    }
    return () => {};
  }, [eta_date, custom, incomplete, orderRequest]);

  useEffect(() => {
    if (orderRequest) {
      const getOR = orderRequests.find(
        (OR: OrderRequest) =>
          OR.items.some(
            (ORI: OrderRequestItem) =>
              ORI._KEY ===
              (productRef.current as CartboxItem).shopping_list_item
                ?.order_request_item
          ) ||
          OR._KEY === (productRef.current as OrderRequestItem).order_request
      );
      if (getOR) {
        getCountDownInterval(
          getOR,
          (productRef.current as OrderRequestItem)._KEY
        );
      }
    }
    return () => {};
  }, [orderRequest, orderRequests]);

  useEffect(() => {
    if (!incomplete || orderRequest) {
      setStemInfoOptions('');
      if (state.bunch) {
        if (state.stem) {
          setStemInfoOptions(
            `${state.quantity * state.stem * state.bunch} ${
              state.quantity * state.stem * state.bunch === 1 ? 'stem' : 'stems'
            }`
          );
        } else if (state.stem === 0) {
          setStemInfoOptions('');
        } else {
          setStemInfoOptions(
            `${state.quantity * state.bunch} ${
              state.quantity * state.bunch === 1 ? 'bunch' : 'bunches'
            }`
          );
        }
      }
    }

    return () => {};
  }, [
    product,
    state.stem,
    state.bunch,
    state.quantity,
    incomplete,
    orderRequest
  ]);

  useEffect(() => {
    if (!custom && productRef.current.quantity !== state.quantity) {
      dispatch({
        type: TypeActions.change,
        payload: { name: 'quantity', value: productRef.current.quantity }
      });
      dispatch({
        type: TypeActions.change,
        payload: {
          name: 'box',
          value: `${productRef.current.quantity} ${
            productRef.current.quantity === 1 ? ' box' : 'boxes'
          }`
        }
      });
    }
  }, [state.quantity, custom, product]);

  // useEffect(() => {
  //   if (CheckoutState === State.REJECTED) {
  //     setDeleteLoading(State.REJECTED);
  //   }
  // }, [CheckoutState]);

  const deleteSLItems = async () => {
    //if (CheckoutState === State.PENDING) {
    // toast('An item is being deleted.', 'info');
    // } else {

    //setDeleteLoading(State.PENDING);

    if (incomplete || orderRequest) {
      const slItem = (productRef.current as IncompleteProduct).shoppingListItem
        ? (productRef.current as IncompleteProduct).shoppingListItem
        : (productRef.current as CartboxItem)._KEY;
      if (orderRequest) {
        const productKey = (productRef.current as OrderRequestItem)._KEY;
        const orderRequest = (productRef.current as OrderRequestItem)
          .order_request;
        dispatchAction(
          actionsShoppingList.removeORItem({ orderRequest, productKey })
        );
      } else {
        dispatchAction(actionsShoppingList.removeShoppingListItem({ slItem }));
      }
      dispatchAction(actionsCheckout.setItemsInCart(itemsInCart - 1));
      dispatchAction(actionsCheckout.getCheckoutInfoByCustomer({ customer }));
      dispatchAction(
        actionsShoppingList.checkLastSLItem({
          slItemKey: slItem,
          productKey: ''
        })
      );
      toast('Item was deleted');
    } else {
      if (custom) {
        if (
          (productRef.current as CartboxItem).cart_box.tag ===
          'shopping_list_v2'
        ) {
          setLoading(State.PENDING);
          if (setDeleteLoading) {
            setDeleteLoading(State.PENDING);
          }
          const response = await removeFromBoxService({
            customer,
            cart_box: (productRef.current as CartboxItem).cart_box._KEY,
            key: (productRef.current as CartboxItem)._KEY
          });

          await closeBoxService({ customer });

          if (response?.success) {
            toast('Item was deleted');
          }
          const response2 = await listCart(customer);
          if (
            response2?.success &&
            response2.results &&
            response2.results.carts
          ) {
            dispatchAction(
              actionsCheckout.checkoutProducts({
                products: response2.results.carts
              })
            );
          }

          setLoading(State.RESOLVED);
          if (setDeleteLoading) {
            setDeleteLoading(State.RESOLVED);
          }
        } else {
          dispatchAction(
            actionsCheckout.removeFromCustomBox({
              item: {
                quantity: productRef.current.quantity,
                masterlist: {
                  _KEY: (productRef.current as CartboxItem).masterlist._KEY,
                  custom_percentage: (productRef.current as CartboxItem)
                    .masterlist.custom_percentage
                },
                inventory: {
                  _KEY: (productRef.current as CartboxItem).inventory._KEY,
                  quantity: (productRef.current as CartboxItem).inventory
                    .quantity
                },
                uom: (productRef.current as CartboxItem).uom.uom,
                stems_per_bunch: (productRef.current as CartboxItem)
                  .stems_per_bunch.stems_per_bunch
              },
              customer,
              cartBox: {
                current_percentage: (productRef.current as CartboxItem).cart_box
                  .current_percentage,
                _KEY: (productRef.current as CartboxItem).cart_box._KEY,
                current_items: (productRef.current as CartboxItem).cart_box
                  .current_items
              },
              productKey: (productRef.current as CartboxItem)._KEY,
              quantity: productRef.current.quantity,
              slItemKey: (productRef.current as Cartbox).shopping_list_item
                ? (productRef.current as Cartbox).shopping_list_item._KEY
                : '',
              isBoxOpen
              /*setCurrentPercentage({
                percentage:
                  productRef.current.cart_box.current_percentage -
                  productRef.current.percentage * productRef.current.quantity,
                box: {
                  ...currentBox,
                  items: currentBox?.items?.filter(
                    (item: any) => item._KEY !== productRef.current._KEY
                  )
                }
              })*/
            })
          );
        }
      } else {
        MixpanelService.track('cart_delete_buying_page');
        const cartboxes = (productRef.current as CartboxItem).cartBoxes
          ? (productRef.current as CartboxItem).cartBoxes
          : [(productRef.current as Cartbox)._KEY];

        dispatchAction(
          actionsCheckout.removeFromBox({
            cartboxes,
            customer,
            invKey:
              (productRef.current as Cartbox).items[0]?.inventory?._KEY ?? '',
            slItemKey:
              (productRef.current as Cartbox).items[0]?.shopping_list_item
                ?._KEY ?? ''
          })
        );
      }
    }

    fromBox
      ? TrackEventMonitor.newEvent({
          props: {
            cart_box_key: (productRef.current as CartboxItem).cart_box._KEY,
            grower_name: (productRef.current as CartboxItem).grower
              ?.company_name,
            grower_key: (productRef.current as CartboxItem).grower?._KEY,
            masterlist_key: (productRef.current as CartboxItem).masterlist._KEY,
            quantity: productRef.current.quantity,
            repurchase_cartbox_item_key:
              (productRef.current as CartboxItem).repurchase_cartbox_item ?? '',
            variety_key: productRef.current.variety._KEY,
            cart_box_item_key: (productRef.current as CartboxItem)._KEY
          },
          metadata: {
            name: 'ibf_remove_from_box'
          }
        })
      : TrackEventMonitor.newEvent({
          props: {
            consecutive: (productRef.current as Cartbox).consecutive,
            charge_date: (productRef.current as Cartbox).charge_date,
            grower_name: (productRef.current as Cartbox).grower?.company_name,
            eta_date: (productRef.current as Cartbox).eta_date,
            current_percentage: (productRef.current as Cartbox)
              .current_percentage,
            current_items: (productRef.current as Cartbox).current_items,
            custom: (productRef.current as Cartbox).custom,
            cart_box_key: (productRef.current as Cartbox)._KEY,
            extra_freight_cost: (productRef.current as Cartbox)
              .extra_freight_cost,
            grower_key: (productRef.current as Cartbox).grower?._KEY,
            items: (productRef.current as Cartbox).items?.map((item: any) => ({
              quantity: item.quantity,
              cart_box_item_key: item._KEY,
              masterlist_key: item.masterlist._KEY
            })),
            packing_date: (productRef.current as Cartbox).packing_date
          },
          metadata: {
            name: 'ibf_delete_box'
          }
        });
  };

  const hideProduct = () => {
    dispatchAction(
      actionsCheckout.setProducts({
        products: products.filter(
          (product) => product._KEY !== (productRef.current as Cartbox)._KEY
        )
      })
    );
    dispatchAction(actionsCheckout.setItemsInCart(itemsInCart - 1));
    if (toggle) {
      toggle();
    }
  };

  return (
    <div
      key={(productRef.current as Cartbox)._KEY + '-checkout-element'}
      className="mb-2 p-1">
      <div
        className={`${
          (productRef.current as CartboxItem).grower?.komet_intg ? '' : ''
        }`}
        style={
          (productRef.current as CartboxItem).grower?.komet_intg
            ? isOrangeStyle
            : (productRef.current as Cartbox).komet_delete_date
            ? isRedStyle
            : {}
        }>
        {((productRef.current as CartboxItem).grower?.komet_intg ||
          (productRef.current as Cartbox).komet_delete_date) && (
          <MessageProductKommet
            message={
              (productRef.current as Cartbox).komet_delete_date ? (
                <span className="text-white">
                  Sorry, this box has been deleted by the open market inventory.
                </span>
              ) : (
                <span className="d-flex align-items-center">
                  Checkout this box in <b className="ml-1">max</b>{' '}
                  <Countdown
                    valueStyle={{
                      fontSize: '12px',
                      marginLeft: '5px',
                      marginRight: '5px'
                    }}
                    value={moment((product as Cartbox)._META.created_time)
                      .add('30', 'm')
                      .format()}
                    format={`m[m] s[s]`}
                  />
                  or it get's deleted by the open market inventory{' '}
                </span>
              )
            }
            className={`ibf-font-10 p-1 font-weight-bold  ${
              (productRef.current as Cartbox).komet_delete_date
                ? 'bg-danger'
                : ''
            }`}
          />
        )}

        {!fromBox && (productRef.current as CartboxItem)?.grower && (
          <>
            {itemKeyDeleteCartbox.some(
              (item: string) =>
                (productRef.current as Cartbox)._KEY.includes(item) ||
                loading === State.PENDING
            ) ? (
              <Skeleton
                width={'250px'}
                height={'25px'}
                //style={{ backgroundColor: '#01a2e4' }}
              />
            ) : (
              <CheckoutBoxHeader
                product={productRef.current as Cartbox}
                disabled={!!(productRef.current as Cartbox).komet_delete_date}
                //setDeleteLoading={setDeleteLoading}
                fromBox={false}
              />
            )}
          </>
        )}
        <div
          className={`d-flex flex-column flex-md-row justify-content-between ${
            !fromBox ? 'border-bottom pb-3' : ''
          }`}>
          <div className="mr-2">
            {itemKeyDeleteCartbox.some(
              (item: string) =>
                (productRef.current as Cartbox)._KEY.includes(item) ||
                loading === State.PENDING
            ) ? (
              <>
                <Skeleton
                  width={'65px'}
                  height={'65px'}
                  //style={{ backgroundColor: '#01a2e4' }}
                />
              </>
            ) : (
              <ImageComponent
                className="ibf-border-gray"
                imageUrl={state && state.image}
                onError={() => {
                  let images: string[] = [];

                  if ((productRef.current as CartboxItem).images) {
                    images = (productRef.current as CartboxItem).images;
                  }
                  if ((productRef.current as Cartbox).img) {
                    images = (productRef.current as Cartbox).img;
                  }

                  if (images.length > 0) {
                    const index = images.findIndex(
                      (image) => Environment.publicPath + image === state.image
                    );
                    if (images[index + 1]) {
                      dispatch({
                        type: TypeActions.change,
                        payload: {
                          name: 'image',
                          value: images[index + 1]
                        }
                      });
                    }
                  }
                }}
                imgSize={'65px'}
              />
            )}
          </div>

          <div className="w-70">
            {itemKeyDeleteCartbox.some(
              (item: string) =>
                (productRef.current as Cartbox)._KEY.includes(item) ||
                loading === State.PENDING
            ) ? (
              <Skeleton
                height={'25px'}
                width={'220px'}
                //style={{ backgroundColor: '#01a2e4' }}
              />
            ) : (
              <span className={'ibf-text-card mb-0'}>
                {incomplete || orderRequest
                  ? state.productGroupName
                  : state.productGroupName +
                    ' ' +
                    state.varietyName +
                    ' ' +
                    state.length +
                    ' cm ' +
                    ' - ' +
                    state.colorName}
              </span>
            )}

            {eta_date && !orderRequest && !incomplete && (
              <>
                <br />
                <>
                  {itemKeyDeleteCartbox.some(
                    (item: string) =>
                      (productRef.current as Cartbox)._KEY.includes(item) ||
                      loading === State.PENDING
                  ) ? (
                    <div className="py-1">
                      <Skeleton
                        height={'25px'}
                        width={'220px'}
                        //style={{ backgroundColor: '#01a2e4' }}
                      />
                    </div>
                  ) : (
                    <>
                      <span className="ibf-text-gray-cart">
                        {/*Delete button*/}
                        Delivery date: {convertNumberDateDDDMMMYYYY(
                          eta_date
                        )}{' '}
                        {!(productRef.current as Cartbox).komet_delete_date && (
                          <span
                            className="pointer ibf-text-gray-cart"
                            onClick={deleteSLItems}
                            style={{ textDecoration: 'underline' }}>
                            Delete
                          </span>
                        )}
                      </span>
                    </>
                  )}
                </>
              </>
            )}
          </div>
          <div className="w-40 pl-md-1 mt-2 mt-md-0">
            {/*number of boxes and stems*/}

            <div>
              {itemKeyDeleteCartbox.some(
                (item: string) =>
                  (productRef.current as Cartbox)._KEY.includes(item) ||
                  loading === State.PENDING
              ) ? (
                <div className="w-100">
                  <Skeleton
                    height={'25px'}
                    //width={'70px'}
                    //style={{ backgroundColor: '#01a2e4' }}
                  />
                </div>
              ) : (
                <span className="font-weight-bold">
                  {!incomplete && !orderRequest && state.custom
                    ? ` ${state.bunch} ${state.bunch > 1 ? 'bunches' : 'bunch'}`
                    : ` ${state.box}`}
                  {!(incomplete || orderRequest) &&
                    productRef.current.quantity && (
                      <span className=" ml-1 font-weight-bold">
                        $
                        {product &&
                          roundUp(
                            (productRef.current as Cartbox).price *
                              (1 - (productRef.current as Cartbox).off) *
                              productRef.current.quantity
                          )}
                      </span>
                    )}
                </span>
              )}
            </div>
            {/*price of the product*/}
            <div>
              {itemKeyDeleteCartbox.some(
                (item: string) =>
                  (productRef.current as Cartbox)._KEY.includes(item) ||
                  loading === State.PENDING
              ) ? (
                <div className="w-100">
                  <Skeleton height={'25px'} />
                </div>
              ) : (
                <>
                  {' '}
                  <div className="ibf-text-gray-cart">
                    {!(incomplete || orderRequest) && state.custom
                      ? `${state.box} `
                      : `${state.bunch} ${
                          state.bunch > 1 ? 'bunches' : 'bunch'
                        } ($${roundUp(state.pricePerBunch)}/bu)`}
                    <span className="d-block">
                      {incomplete || orderRequest
                        ? `${
                            (productRef.current as OrderRequestItem)
                              .label_quantity
                          } ($${roundUp(state.pricePerStem)}/st)`
                        : `
                     ${stemInfoOptions} ${
                            state.stem === 0
                              ? ''
                              : `(${roundUp(state.pricePerStem)}/st)`
                          }
                      `}
                    </span>

                    {/* {!(incomplete || orderRequest) &&
                  productRef.current.quantity && (
                    <span className="ibf-text-gray-cart">
                      $
                      {product &&
                        roundUp(
                          (productRef.current as Cartbox).price *
                            (1 - (productRef.current as Cartbox).off) *
                            productRef.current.quantity
                        )}
                    </span>
                  )} */}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="ibf-cart-orderR mx-3">
          {orderRequest ? (
            <span>
              Growers give their best deal within{' '}
              <span
                id={`ori_${(productRef.current as OrderRequestItem)._KEY}`}
              />
            </span>
          ) : (
            incomplete && (
              <span>Ask growers to give their best deal in your check out</span>
            )
          )}
        </div>
        {(productRef.current as Cartbox).komet_delete_date && (
          <div className="d-flex justify-content-end mb-2 px-2">
            <Button
              color={'success'}
              size={'sm'}
              className={'mr-2'}
              onClick={() => {
                const params = {
                  date: (productRef.current as Cartbox).eta_date,
                  search_val: '',
                  grower: [(productRef.current as Cartbox).grower._KEY],
                  product_group: [
                    (productRef.current as Cartbox).product_group._KEY
                  ]
                };
                history.push(addObjectParamsToPath('/search', params));
                hideProduct();
              }}>
              <u className="text-white">Go back to search again</u>
            </Button>

            <Button size={'sm'} color={'danger'} onClick={hideProduct}>
              <u className="text-white d-flex align-items-center">
                Close (
                <Countdown
                  valueStyle={{
                    fontSize: '12px',
                    marginLeft: '5px',
                    marginRight: '5px',
                    color: 'white'
                  }}
                  value={moment((product as Cartbox).komet_delete_date)
                    .add('5', 'm')
                    .format()}
                  format={`m[m] s[s]`}
                />
                )
              </u>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutProducts;
