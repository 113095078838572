import { default as iziToast } from 'izitoast';
import FilterItem from '../Models/FilterItem';
import CO_flag from '../Assets/Images/CO_flag.png';
import CR_flag from '../Assets/Images/CR_flag.png';
import EC_flag from '../Assets/Images/EC_flag.png';
import NL_flag from '../Assets/Images/NL_flag.png';
import TH_flag from '../Assets/Images/TH_flag.png';
import US_flag from '../Assets/Images/US_flag.png';
import { differenceInDays, format } from 'date-fns';
import { render } from 'react-dom';
import { CustomerAddress } from '../Models/CustomerModel';
import {
  faBoxes,
  faClipboardList,
  faQuestion,
  faUser,
  faRepeat
} from '@fortawesome/pro-solid-svg-icons';
import Environment from '../Environment';
import {
  ACCOUNT_MANAGER_MODAL,
  ADD_USER_MODAL,
  COMPANY_INFORMATION_MODAL,
  DELIVERY_SCHEDULE_MODAL,
  GROWER_INFO_MODAL,
  MANAGE_GROWERS_MODAL,
  SHIPPING_DETAILS,
  SIGN_UP_MODAL,
  USERS_LIST_MODAL
} from '../Store/Modal/Types';
import { DashboardList } from '../Models/DashboardList';
import { InputsReferral } from '../Models/InputsReferral';
import { Card } from '../Models/Card';
import Filter from '../Models/Filter';
import { OrderRequest } from '../Models/OrderRequest';
import * as queryString from 'query-string';
import pick from 'lodash.pick';
import MixpanelService from './../Services/MixpanelService';
import { Color } from '../Models/Color';
import { Holiday } from '../Models/Holiday';
import moment from 'moment';
import 'moment-timezone';
import { OrderItem, OrderItemCsv } from '../Models/Order';
import { Cartbox } from '../Models/Cartbox';
import { Dispatch } from 'react';
import { actionsModal } from '../Store/Modal/Slice';
import { ProductsByEventName } from '../Store/Checkout/Types';
import { EventCartbox } from '../Models/EventCartbox';
import User from '../Models/User/User';
import { UserRole } from '../Models/User/UserRole';
import { addUtmCode, getCookie, guestSignIn } from '../Services/AuthService';
import { TrackEventMonitor } from '../Classes/TrackEvent';
import * as Sentry from '@sentry/react';
import AuthResponse from '../Models/AuthResponse';
import { SignInGuestParams } from '../Models/Auth';
import { hlp } from '../App';
import Product from '../Models/Product';
import { actionsCustomBox } from '../Store/CustomBox/Slice';
import {
  Boxes,
  sendEmailStandingOrder,
  SendTemplateStandingOrder
} from '../Models/StandingOrder';
import { OrderEntity } from '../Store/Order/Types';
import { actionsCheckout } from '../Store/Checkout/Slice';
import { actionsShoppingList } from '../Store/ShoppingList/Slice';
import { ErrorTypeRequest } from '../Models/ResponseCS/SearchCS';
import { VarietySuggestion } from '../Models/Variety';

export let globalHolidays = [];
export let globalDisabledDaysOfWeek = [0, 6, 1];

export function uuidV4() {
  return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (
      ((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >>
      (c / 4)
    ).toString(16)
  );
}

export function toast(message: string, type: string = 'success'): void {
  const toast: any = {
    message: message,
    position: 'bottomLeft',
    displayMode: 2,
    closeOnClick: true
  };
  switch (type) {
    case 'error':
      iziToast.error(toast);
      break;
    case 'info':
      iziToast.info(toast);
      break;
    case 'warning':
      iziToast.warning(toast);
      break;
    default:
      iziToast.success(toast);
      break;
  }
}

export function getFiltersKeys(arrayFilters: Array<Filter>) {
  return arrayFilters.reduce((obj: any, filter: Filter) => {
    filter.values.forEach((item: FilterItem) => {
      if (item.selected && filter.label) {
        const label = getRequestLabel(filter.label);
        if (obj[label]) {
          obj[label] = [...obj[label], item.key];
        } else {
          obj[label] = [item.key];
        }
      }
    });
    return obj;
  }, {});
}

//note: month is 0 based, just like Dates in js
function endFirstWeek(firstDate: Date, firstDay: number) {
  if (!firstDay) {
    return 7 - firstDate.getDay();
  }
  if (firstDate.getDay() < firstDay) {
    return firstDay - firstDate.getDay();
  } else {
    return 7 - firstDate.getDay() + firstDay;
  }
}

function getWeeksStartAndEndInMonth(month: number, year: number) {
  let weeks = [],
    firstDate = new Date(year, month, 1),
    lastDate = new Date(year, month + 1, 0),
    numDays = lastDate.getDate();

  let start = 1;
  let end = endFirstWeek(firstDate, 0);
  while (start <= numDays) {
    weeks.push({ start: start, end: end });
    start = end + 1;
    end = end + 7;
    end = start === 1 && end === 8 ? 1 : end;
    if (end > numDays) {
      end = numDays;
    }
  }
  if (lastDate.getDay() !== 6) {
    weeks.pop();
  }
  return weeks;
}

export function getWeeksOfYear(year: number) {
  const months = [];
  for (let month = 0; month < 12; month++) {
    months.push(getWeeksStartAndEndInMonth(month, year));
  }
  return months;
}

export function getProductStartingPrice(p: any) {
  if (p.stems_per_bunch) {
    return getDecimalsDigits(p.price / p.stems_per_bunch);
  }
  return p.price;
}

export function countSelectedItems(filters: any): number {
  return Object.keys(filters).reduce((sum: number, key: any) => {
    if (key !== 'variety') {
      let a: any = Array.isArray(filters[key]) ? filters[key].length : 0;
      sum = sum + a;
    } else {
      sum += filters[key].length > 0 ? 1 : 0;
    }
    return sum;
  }, 0);
}

export function removeFalsyValues(object: any) {
  return pick(
    object,
    Object.keys(object).filter((key) => Boolean(object[key]))
  );
}

export function addObjectParamsToPath(
  path: string,
  params: { [key: string]: any }
) {
  params = removeFalsyValues(params);
  if (Object.keys(params).length) {
    path += `?${objectToQueryParams(params)}`;
  }
  return path;
}

export function addQueryParamsToPath(path: string, queryParams?: string) {
  const params = queryParams
    ? queryParamsToObject(queryParams)
    : hrefToObject();
  return addObjectParamsToPath(path, params);
}

export function objectToQueryParams(object: { [key: string]: any }) {
  return queryString.stringify(object, { arrayFormat: 'comma' });
}

export function queryParamsToObject(
  hash: string,
  selection: Array<string> | null = null
) {
  if (!hash || !hash.includes('?')) {
    return {};
  }
  const search = `?${hash.split('?')[1]}`;
  let object = queryString.parse(search, { arrayFormat: 'comma' });
  if (selection?.length) {
    object = pick(object, selection);
  }
  return object;
}

export function hrefToObject() {
  const search = `?${window.location.href.split('?')[1]}`;
  return queryString.parse(search, { arrayFormat: 'comma' });
}

export function hrefContainsProperty(attr: string): boolean {
  const params = hrefToObject();
  return Boolean(params[attr]);
}

export function urlContainsProperty(url: string, attr: string): boolean {
  const params = queryParamsToObject(url, [attr]);
  return Boolean(Object.keys(params).length);
}

export function checkSearchEquality(search: string) {
  let { search_val = '' } = hrefToObject();
  return search_val === search;
}

export function getTrueKeysUserConfig(obj: any) {
  let keys = Object.keys(obj).filter((key) => obj[key] === true);
  return keys.length > 1 ? keys.join(' - ') : keys;
}

export function getKeysUserConfig(obj: any) {
  if (obj) {
    return Object.entries(obj).filter(
      ([key, value]) => obj[key] === true || obj[key] === false
    );
  }
  return [];
}

enum Direction {
  CO = 'CO',
  CR = 'CR',
  EC = 'EC',
  NL = 'NL',
  TH = 'TH',
  US = 'US'
}

export function countryFlag(country: string): any {
  switch (country) {
    case Direction.CO:
      return CO_flag;
    case Direction.CR:
      return CR_flag;
    case Direction.EC:
      return EC_flag;
    case Direction.NL:
      return NL_flag;
    case Direction.TH:
      return TH_flag;
    case Direction.US:
      return US_flag;
  }
}

export const openStandingOrderQuotes = (detail: boolean) => {
  if (detail) {
    MixpanelService.track('product_details_standing_order_price');
  } else {
    MixpanelService.track('standing_order');
  }

  window.open('https://www.ibuyflowers.com/standing_order');
};

export function onlyNumbers(entry: string) {
  return entry.replace(/[^0-9]/g, '');
}

export function convertDateToStringDate(date: Date) {
  return format(date, 'MM/DD/YYYY hh:mm:ss A');
}

export function convertNumberDateEEELLLDD(date: string | number) {
  try {
    if (typeof date === 'number') date = date.toString();
    return format(date, 'ddd, MMM DD');
  } catch (e) {
    return date;
  }
}

export function convertDateToStantard(date: any) {
  let stringDate = date.toString();
  return format(stringDate, 'MM-DD-YYYY');
}

export function convertDateToNumberDate(date: any) {
  return parseInt(format(date, 'YYYYMMDD'), 10);
}

export function convertDateToHourDate(date: string | number) {
  let string_date = date.toString();
  return format(string_date, 'DD/MM/YY [at] HH:mm A');
}

export function convertNumberDateToDate(number_date: number | string) {
  let string_date = number_date.toString();
  const year = string_date.slice(0, 4);
  const month = string_date.slice(4, 6);
  const day = string_date.slice(6, 8);
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}

export function convertNumberDateToDateHour(
  number_date: number | string,
  hour: number
) {
  let string_date = number_date.toString();
  const year = string_date.slice(0, 4);
  const month = string_date.slice(4, 6);
  const day = string_date.slice(6, 8);
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), hour);
}

export function convertNumberDateMMDDYYYY(date: any) {
  if (typeof date === 'number') date = date.toString();
  return format(date, 'MM-DD-YYYY');
}

export function convertNumberDateYYYYMMDD(date: any) {
  if (typeof date === 'number') date = date.toString();
  return format(date, 'YYYY-MM-DD');
}

export function convertNumberDateDDDMMMYYYY(date: any) {
  if (typeof date === 'number') date = date.toString();
  return format(date, 'ddd, MMM, DD, YYYY');
}

export function convertNumberDateDDDMMM(date: any) {
  if (typeof date === 'number') date = date.toString();
  return format(date, 'ddd, MMM DD');
}

export function convertNumberDateMMM(date: any) {
  if (typeof date === 'number') date = date.toString();
  return format(date, 'MMM');
}

export function convertNumberDateMMMDD(date: any) {
  if (typeof date === 'number') date = date.toString();
  return format(date, 'MMM D');
}

export function convertNumberDateMMMDDYYYY(date: any) {
  if (typeof date === 'number') date = date.toString();
  return format(date, 'MMM DD, YYYY');
}

export function convertDateDDMMM(date: any) {
  if (typeof date === 'number') date = date.toString();
  return format(date, 'DD MMM');
}

export function convertNumberDateToFullDate(date: string) {
  let reservation_date = convertNumberDateToDate(date);
  reservation_date.setDate(reservation_date.getDate() - 1);
  return format(reservation_date, 'dddd, MMMM DD, YYYY');
}

export function convertNumberDateToFullDate2(date: string) {
  return format(convertNumberDateToDate(date), 'dddd, MMMM DD, YYYY');
}

export function convertDateToCalendar(date: any) {
  if (date === 'no-date') {
    return 'Date to be defined';
  } else {
    const daysInWeek: Array<string> = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    const months: Array<string> = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const date1 = new Date(convertDateToStantard(date));
    return `${daysInWeek[date1.getDay()]},  ${
      months[date1.getMonth()]
    } ${date1.getDate()}`;
  }
}

export const grownInMonthDefault = {
  january: true,
  february: true,
  march: true,
  april: true,
  may: true,
  june: true,
  july: true,
  august: true,
  september: true,
  october: true,
  november: true,
  december: true
};
export const months: Array<string> = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
export const getDecimalsDigits = (
  number: number,
  decimalDigits: number = 2
): number => {
  return parseFloat(number.toFixed(decimalDigits));
};

export const getNumberofCard = (size: number) => {
  if (size <= 426) {
    return 1;
  } else if (size > 426 && size <= 680) {
    return 2;
  } else if (size > 681 && size <= 768) {
    return 3;
  } else if (size > 768 && size <= 1024) {
    return 4;
  } else if (size > 1024 && size <= 1440) {
    return 5;
  } else if (size > 1440 && size <= 1800) {
    return 6;
  } else {
    return 7;
  }
};

export const getStemLabel = (stems: number, boxOnly: boolean) => {
  if (stems === 1) {
    return stems + `${boxOnly ? ' box' : ' stem'}`;
  } else {
    return stems + `${boxOnly ? ' boxes' : ' stems'}`;
  }
};

export const appendTo = (tagId: string, elementId: string, component: any) => {
  const tag = document.getElementById(tagId);
  const div = document.createElement('div');
  div.setAttribute('id', elementId);
  tag && render(component, tag.appendChild(div));
};

export const appendToFirst = (
  tagId: string,
  elementId: string,
  component: any
) => {
  const tag = document.getElementById(tagId);
  const div = document.createElement('div');
  div.setAttribute('id', elementId);
  const pre = tag?.prepend(div);
  tag && pre && render(component, tag.appendChild(pre));
};

export const removeTo = (elementId: string) => {
  const element = document.getElementById(elementId);
  element && element.remove();
};

export function isLessThan15Days(date: Date, date2: Date = new Date()) {
  return differenceInDays(date, date2) + 1 <= 15;
}

export function getStateList() {
  return [
    { name: 'Alabama', abbr: 'AL' },
    { name: 'Alaska', abbr: 'AK' },
    { name: 'Arizona', abbr: 'AZ' },
    { name: 'Arkansas', abbr: 'AR' },
    { name: 'California', abbr: 'CA' },
    { name: 'Colorado', abbr: 'CO' },
    { name: 'Connecticut', abbr: 'CT' },
    { name: 'Delaware', abbr: 'DE' },
    { name: 'District Of Columbia', abbr: 'DC' },
    { name: 'Florida', abbr: 'FL' },
    { name: 'Georgia', abbr: 'GA' },
    { name: 'Hawaii', abbr: 'HI' },
    { name: 'Idaho', abbr: 'ID' },
    { name: 'Illinois', abbr: 'IL' },
    { name: 'Indiana', abbr: 'IN' },
    { name: 'Iowa', abbr: 'IA' },
    { name: 'Kansas', abbr: 'KS' },
    { name: 'Kentucky', abbr: 'KY' },
    { name: 'Louisiana', abbr: 'LA' },
    { name: 'Maine', abbr: 'ME' },
    { name: 'Maryland', abbr: 'MD' },
    { name: 'Massachusetts', abbr: 'MA' },
    { name: 'Michigan', abbr: 'MI' },
    { name: 'Minnesota', abbr: 'MN' },
    { name: 'Mississippi', abbr: 'MS' },
    { name: 'Missouri', abbr: 'MO' },
    { name: 'Montana', abbr: 'MT' },
    { name: 'Nebraska', abbr: 'NE' },
    { name: 'Nevada', abbr: 'NV' },
    { name: 'New Hampshire', abbr: 'NH' },
    { name: 'New Jersey', abbr: 'NJ' },
    { name: 'New Mexico', abbr: 'NM' },
    { name: 'New York', abbr: 'NY' },
    { name: 'North Carolina', abbr: 'NC' },
    { name: 'North Dakota', abbr: 'ND' },
    { name: 'Ohio', abbr: 'OH' },
    { name: 'Oklahoma', abbr: 'OK' },
    { name: 'Oregon', abbr: 'OR' },
    { name: 'Pennsylvania', abbr: 'PA' },
    { name: 'Rhode Island', abbr: 'RI' },
    { name: 'South Carolina', abbr: 'SC' },
    { name: 'South Dakota', abbr: 'SD' },
    { name: 'Tennessee', abbr: 'TN' },
    { name: 'Texas', abbr: 'TX' },
    { name: 'Utah', abbr: 'UT' },
    { name: 'Vermont', abbr: 'VT' },
    { name: 'Virginia', abbr: 'VA' },
    { name: 'Washington', abbr: 'WA' },
    { name: 'West Virginia', abbr: 'WV' },
    { name: 'Wisconsin', abbr: 'WI' },
    { name: 'Wyoming', abbr: 'WY' }
  ];
}

//

//calendar

export function getNextBusinessDay(d: any) {
  let day = d.getDay(),
    add = 1;
  if (day === 5) add = 3;
  else if (day === 6) add = 2;
  d.setDate(d.getDate() + add);
  return d;
}

export const getModifiersStyles = () => {
  return {
    limitedOffer: {
      background: 'rgba(238, 205, 129, 0.38)'
    },
    today: {
      backgroundImage:
        "url('https://sbxcloud.com/www/ibuyflowersdirect/images/icons/calendar/today.png')",
      backgroundRepeat: 'no-repeat'
    }
  };
};

export const getTodayEST = () => {
  return convertDateToNumberDate(new Date());
};

export const setDeliveryDateDefault = () => {
  const today = new Date();
  const weekDay = today.getDay();

  const addDays = (date: Date, days: number) => {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  };

  switch (weekDay) {
    case 1:
      return addDays(today, 4);
    case 2:
      return addDays(today, 6);
    case 3:
      return addDays(today, 6);
    case 4:
      return addDays(today, 6);
    case 5:
      return addDays(today, 6);
    case 6:
      return addDays(today, 5);
    case 0:
      return addDays(today, 5);
    default:
      return;
  }
};

export const splitIntoChunks = <T>(array: T[], chunkSize: number): T[][] => {
  const chunks = new Array<Array<T>>();

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
};

export const getAheadMonths = () => {
  let x = 12; //or whatever offset
  let CurrentDate = new Date();
  CurrentDate.setMonth(CurrentDate.getMonth() + x);
  return CurrentDate;
};

export const scrollToElement = (id: string = '') => {
  const element = document.getElementById(id);
  element &&
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'end'
    });
};

export const getList = (list: string): DashboardList => {
  switch (list) {
    case 'orders':
      return {
        title: 'Orders',
        icon: faBoxes,
        options: [
          { name: 'Track Package', url: '/' },
          {
            name: 'My orders',
            url: '/#/ordersbyarrival',
            closeMenu: true
          },
          {
            name: 'Statements',
            url: '/#/statements',
            closeMenu: true
          },
          {
            name: 'Request Standing Order Price',
            function: () => openStandingOrderQuotes(false)
          }
        ]
      };
    case 'claims':
      return {
        title: 'Claims',
        icon: faClipboardList,
        options: [
          {
            name: 'Claims Pending',
            url: Environment.urlPendingClaims,
            component: {
              name: 'button',
              label: 'Request Claim',
              url: Environment.urlRequestClaims
            }
          },
          { name: 'Claims Accepted', url: Environment.urlAcceptedClaims },
          { name: 'Claims Rejected', url: Environment.urlRejectedClaims }
        ]
      };
    case 'settings':
      return {
        title: 'Account / User Settings',
        icon: faUser,
        options: [
          {
            name: 'Company Information',
            component: { name: 'progress' },
            modalData: { name: COMPANY_INFORMATION_MODAL }
          },
          {
            name: 'Users',
            component: {
              name: 'button',
              label: 'Add User',
              modalData: {
                name: ADD_USER_MODAL,
                data: { type: 'add', user: {} }
              }
            },
            modalData: { name: USERS_LIST_MODAL }
          },
          {
            name: 'Payment options (my credit cards)',
            url: '/#/paymentoptions',
            closeMenu: true
          },
          { name: 'Manage Growers', modalData: { name: MANAGE_GROWERS_MODAL } },
          { name: 'Referrals', url: '/#/referral' },
          { name: 'Logout' }
        ]
      };
    case 'help':
      return {
        title: 'Help',
        icon: faQuestion,
        options: [
          {
            name: 'Help center',
            url: Environment.urlHelpCenter
          },
          {
            name: 'Delivery Schedule',
            modalData: { name: DELIVERY_SCHEDULE_MODAL }
          },
          {
            name: 'Grower Info - Arrival Dates',
            modalData: { name: GROWER_INFO_MODAL }
          },
          {
            name: 'Personal Account Manager',
            modalData: { name: ACCOUNT_MANAGER_MODAL }
          },
          { name: 'Shipping Details', modalData: { name: SHIPPING_DETAILS } }
        ]
      };
    case 'StandingOrders':
      return {
        title: 'Standing Orders',
        icon: faRepeat,
        options: [
          {
            name: 'Request Standing Order prices',
            url: '/#/requestStanding',
            closeMenu: true
          },
          {
            name: 'My Standing Orders',
            url: '/#/myStandingOrders',
            closeMenu: true
          }
        ]
      };

    default:
      return { title: '', icon: faUser, options: [] };
  }
};

export const referralInputs: Array<InputsReferral> = [
  {
    label: 'Company email address to send your coupon to',
    type: 'text',
    id: 1,
    name: 'email',
    value: ''
  },
  {
    label: 'Confirm email',
    type: 'text',
    id: 2,
    name: 'confirm_email',
    value: ''
  },
  {
    label: 'First Name of the receiver',
    type: 'text',
    id: 3,
    name: 'name',
    value: ''
  },
  {
    label: 'Company Name',
    type: 'text',
    id: 4,
    name: 'company_name',
    value: ''
  }
];

export const checkUndefinedObject = (obj: any) => {
  return Object.values(obj).every((value) => value === undefined);
};

export function mapCreditCards(list: Array<Card>) {
  return list.map((item) => {
    switch (item.card_brand) {
      case 'Visa':
        item.icon = 'visa';
        break;
      case 'MasterCard':
        item.icon = 'mastercard';
        break;
      case 'Discover':
        item.icon = 'discover';
        break;
      case 'Diners Club':
        item.icon = 'diners';
        break;
      case 'JCB':
        item.icon = 'jcb';
        break;
      case 'American Express':
        item.icon = 'amex';
        break;
      default:
        item.icon = '';
        break;
    }
    return item;
  });
}

export const shouldAddIt = (relation: any, filterParams: any) => {
  let condition1 = false;
  let condition2 = false;
  let condition3 = false;

  if (filterParams.filterGrower) {
    if (
      relation.grower_obj.company_name
        .toLowerCase()
        .includes(filterParams.filterGrower.toLowerCase())
    ) {
      condition1 = true;
    }
  } else {
    condition1 = true;
  }

  if (filterParams.filterCountry) {
    if (filterParams.filterCountry === relation.grower.country._KEY) {
      condition2 = true;
    }
  } else {
    condition2 = true;
  }

  if (filterParams.filterStatus) {
    if (filterParams.filterStatus === relation.blocked.toString()) {
      condition3 = true;
    }
  } else {
    condition3 = true;
  }

  return condition1 && condition2 && condition3;
};

export const getCountryList = (relations: any) => {
  let keys: any = [];
  let countryList: any = [];

  relations.forEach((item: any) => {
    const { country, country_iso, _KEY } = item.grower.country;

    let countryLabel = country + ' (' + country_iso + ')';

    if (!keys.includes(_KEY)) {
      countryList.push({ countryLabel: countryLabel, _KEY: _KEY });
      keys.push(_KEY);
    }
  });

  return countryList;
};

export function getPhoneLabel(phone: string) {
  let response = '';

  if (phone) {
    response += '(' + phone.substring(0, 3) + ') ';
    response += phone.substring(3, 6) + '-' + phone.substring(6);
  }

  return response;
}

export const getRequestLabel = (label: string) => {
  let lowerLabel = label.toLowerCase();
  if (label === 'Product varieties') {
    return 'variety';
  } else if (
    lowerLabel.substr(lowerLabel.length - 3, lowerLabel.length - 1) === 'ies'
  ) {
    let reg = new RegExp('ies');
    return lowerLabel.replace(reg, 'y');
  } else if (
    lowerLabel.split(' ').length === 1 &&
    lowerLabel.substr(lowerLabel.length - 1) === 's'
  ) {
    return lowerLabel.slice(0, lowerLabel.length - 1);
  } else {
    lowerLabel = lowerLabel.split(' ').join('_');
    if (lowerLabel.substr(lowerLabel.length - 1) === 's') {
      lowerLabel = lowerLabel.slice(0, lowerLabel.length - 1);
    }
    return lowerLabel;
  }
};

export function convertDate(date: string) {
  const newDay = convertDateToNumberDate(date);
  return convertNumberDateMMMDDYYYY(newDay);
}

export const validateEmail = (email: string) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

function toggleClass(element: any, className: string) {
  if (element && element.classList) {
    element.classList.toggle(className);
  } else {
    // For IE9
    const classes = element.className.split(' ');
    const i = classes.indexOf(className);

    if (i >= 0) classes.splice(i, 1);
    else classes.push(className);
    element.className = classes.join(' ');
  }
}

export function toggleClassWithId(idName: string, classNameToggle: string) {
  const element = document.getElementById(idName);
  if (element) {
    toggleClass(element, classNameToggle);
  }
}

export function toggleClassWithClass(
  className: string,
  classNameToggle: string
) {
  const element = document.getElementsByTagName(className)[0];
  toggleClass(element, classNameToggle);
}

export const redirectToCheckout = (isCalculable: boolean = true) => {
  if (isCalculable) {
    MixpanelService.track('back_to_my_checkout');
    window.location.href = Environment.urlCheckout;
  } else {
    toast('Please select an address.', 'error');
  }
};

export function roundUp(num: any = 0) {
  if (!num) {
    return 0;
  }

  num = parseFloat(num).toFixed(2);
  let delimiter = ','; // replace comma if desired
  let a = num.split('.', 2);
  let d = a[1] || '0';
  let i = parseInt(a[0]);
  let minus = '';
  if (i < 0) {
    minus = '-';
  }
  i = Math.abs(i);
  let n = String(i);
  a = [];
  while (n.length > 3) {
    let nn = n.substr(n.length - 3);
    a.unshift(nn);
    n = n.substr(0, n.length - 3);
  }
  if (n.length > 0) {
    a.unshift(n);
  }
  n = a.join(delimiter);
  if (d.length < 1) {
    num = n;
  } else {
    num = n + '.' + d;
  }
  num = minus + num;
  return num;
}

const PAGE = {
  HOME: 'home',
  BOUQUETS: 'bouquets',
  DASHBOARD: 'dashboard'
};

export const redirectTo = (url: string) => {
  switch (url) {
    case PAGE.HOME:
      return (window.location.href = '/#/home');
    case PAGE.BOUQUETS:
      return (window.location.href = '/#/search');
    case PAGE.DASHBOARD:
      return (window.location.href = '/#/dashboard');
    default:
      return;
  }
};

export const checkMail = (mail: string) => {
  return mail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
};

enum Page {
  IBF = 'IBF',
  IBF_SHOPPING = 'IBF_SHOPPING',
  ABOUT_US = 'ABOUT_US',
  GROWERS = 'GROWERS',
  BLOG = 'BLOG',
  REVIEWS = 'REVIEWS',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERMS_CONDITIONS = 'TERMS_CONDITIONS',
  FEEDBACK = 'FEEDBACK'
}

export function redirect(url: string): any {
  switch (url) {
    case Page.IBF:
      return (window.location.href = Environment.mainPageUrl);
    case Page.IBF_SHOPPING:
      return window.open(Environment.ibfShopping);
    case Page.ABOUT_US:
      return window.open(Environment.ibfPage.aboutUs);
    case Page.GROWERS:
      return window.open(Environment.ibfPage.growers);
    case Page.BLOG:
      return window.open(Environment.ibfPage.blog);
    case Page.REVIEWS:
      return window.open(Environment.ibfPage.reviews);
    case Page.PRIVACY_POLICY:
      return window.open(Environment.ibfPage.privacyPolicy);
    case Page.TERMS_CONDITIONS:
      return window.open(Environment.ibfPage.termsAndConditions);
    case Page.FEEDBACK:
      return window.open(Environment.urlFeedback);
  }
}

export const boxSelectList = () => {
  return [
    { length: '1' },
    { length: '2' },
    { length: '3' },
    { length: '4' },
    { length: '5' },
    { length: '6' },
    { length: '7' },
    { length: '8' },
    { length: '9' },
    { length: '10' },
    { length: '11' },
    { length: '12' },
    { length: '13' },
    { length: '14' },
    { length: '15' },
    { length: '16' },
    { length: '17' },
    { length: '18' },
    { length: '19' },
    { length: '20' },
    { length: '21' },
    { length: '22' },
    { length: '23' },
    { length: '24' },
    { length: '25' },
    { length: '26' },
    { length: '27' },
    { length: '28' },
    { length: '29' },
    { length: '30' }
  ];
};

export function getCountDownInterval(OR: OrderRequest, ORI: string) {
  let x = setInterval(function() {
    const countDownDate =
      new Date(OR.created_at).getTime() + OR.waiting_time * 60000;
    const now = new Date().getTime();
    const distance = countDownDate - now;
    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    // If the count down is finished, write some text
    const element = document.getElementById(`ori_${ORI}`);
    if (distance < 0 && element) {
      clearInterval(x);
      element.innerHTML = 'Expired';
    } else {
      if (!document.getElementById(`ori_${ORI}`)) {
        clearInterval(x);
      } else {
        if (
          element &&
          !isNaN(days) &&
          !isNaN(hours) &&
          !isNaN(minutes) &&
          !isNaN(seconds)
        ) {
          element.innerHTML =
            days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';
        }
      }
    }
  }, 1000);

  return x;
}

export function getCountDownHeader(dateToCount: number) {
  if (dateToCount > 0) {
    let x = setInterval(function() {
      let tz = moment()
        .tz('America/New_York')
        .format();
      let countDownDate = new Date(
        moment
          .tz(
            `${convertNumberDateYYYYMMDD(dateToCount)} 08:00:00`,
            'America/New_York'
          )
          .format()
      ).getTime();
      const now = new Date(tz).getTime();
      const distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      // If the count down is finished, write some text
      const element = document.getElementById(`customer_header_${dateToCount}`);

      if (distance < 0 && element) {
        clearInterval(x);
        element.innerHTML = 'Expired';
      } else {
        if (!element) {
          clearInterval(x);
        } else {
          if (
            element &&
            !isNaN(days) &&
            !isNaN(hours) &&
            !isNaN(minutes) &&
            !isNaN(seconds)
          ) {
            element.innerHTML =
              (days > 0 ? days + 'd ' : '') +
              (hours > 0 ? hours + 'h ' : '') +
              minutes +
              'm ' +
              seconds +
              's ';
          }
        }
      }
    }, 1000);

    return x;
  }
  return null;
}

export function getCountDownHeaderGuest(time: any) {
  let x = setInterval(function() {
    const timeProduct = new Date(time.format());
    timeProduct.setHours(timeProduct.getHours() + 24);

    const now = new Date(moment.utc().format()).getTime();
    const distance = timeProduct.getTime() - now;
    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    // If the count down is finished, write some text
    const element = document.getElementById(`customer_header_guest`);
    if (distance < 0 && element) {
      clearInterval(x);
      element.innerHTML = 'Expired';
    } else {
      if (!element) {
        clearInterval(x);
      } else {
        if (
          element &&
          !isNaN(days) &&
          !isNaN(hours) &&
          !isNaN(minutes) &&
          !isNaN(seconds)
        ) {
          element.innerHTML =
            (days > 0 ? days + 'd ' : '') +
            (hours > 0 ? hours + 'h ' : '') +
            minutes +
            'm ' +
            seconds +
            's ';
        }
      }
    }
  }, 1000);

  return x;
}

export const checkLastSlItem = (
  products: any[],
  incompleteProducts: any[],
  slItemKey: string,
  itemKey: string
) => {
  const productSL = products.some((product: any) =>
    product.items.some((item: any) => {
      if (item.shopping_list_item) {
        return (
          item.shopping_list_item._KEY === slItemKey && item._KEY !== itemKey
        );
      }
      return false;
    })
  );
  if (!productSL) {
    const incompleteSL = incompleteProducts.some(
      (product: any) => product.shoppingListItem === slItemKey
    );
    if (!incompleteSL) {
      return true;
    }
  }

  return false;
};

export const checkLastSlItemByBox = (
  products: any[],
  incompleteProducts: any[],
  itemKey: string
) => {
  const cartbox = products.find((product: any) => product._KEY === itemKey);

  if (cartbox) {
    cartbox.items.some((cartboxItem: any) => {
      const productSL = products.some((product: any) =>
        product.items.some((item: any) => {
          if (item.shopping_list_item && cartboxItem.shopping_list_item) {
            return (
              item.shopping_list_item._KEY ===
                cartboxItem.shopping_list_item._KEY && item._KEY !== itemKey
            );
          }
          return false;
        })
      );
      if (!productSL) {
        const incompleteSL = incompleteProducts.some((product: any) => {
          if (product.shoppingListItem) {
            return (
              product.shoppingListItem === cartboxItem.shopping_list_item._KEY
            );
          }
          return false;
        });
        if (!incompleteSL) {
          return true;
        }
      }
      return false;
    });
  }

  return false;
};

export const getCountryName = (country: string = '') => {
  switch (country) {
    case Direction.CO:
      return 'Colombia';
    case Direction.CR:
      return 'Costa Rica';
    case Direction.EC:
      return 'Ecuador';
    case Direction.NL:
      return 'Netherlands';
    case Direction.TH:
      return 'Thailand';
    case Direction.US:
      return 'United States';
    default:
      return '';
  }
};

export function getBunchesAvailable(
  available: number,
  custom_percentage: number,
  current_percentage: number
) {
  try {
    const total_box = Math.floor(
      (100 - current_percentage) / custom_percentage
    );
    return available >= total_box ? total_box : available <= 0 ? 0 : available;
  } catch (e) {
    return 0;
  }
}

export function getAvailableBoxes(
  available: number,
  total_box: number,
  boxQuantity: number = 0
) {
  if (available && total_box) {
    let total = Math.abs(Math.floor(available / total_box));
    if (total > 30) {
      total = 30;
    }
    return Array(total + boxQuantity).fill(1);
  } else {
    return Array(boxQuantity).fill(1);
  }
}

export const numberDateToDate = (date: number) => {
  const date_string = date.toString();
  const year = date_string.slice(0, 4);
  const month = date_string.slice(4, 6);
  const day = date_string.slice(6, 8);
  return `${month}-${day}-${year}`;
};

export function convertDateDWMMDD(date: number | string) {
  if (typeof date === 'number') date = date.toString();
  return format(date, 'ddd MMM DD');
}

export const sortObjByKey = (obj: any) => {
  return Object.keys(obj)
    .sort()
    .reduce((newObj: any, key) => {
      newObj[key] = obj[key];
      return newObj;
    }, {});
};

export function checkLastShoppingListItem(
  products: any[],
  incompleteProducts: any[],
  cartbox: Cartbox
) {
  if (cartbox && cartbox._KEY && cartbox?.shopping_list_item?._KEY) {
    const productSL = products.some((product: any) =>
      product.items.some((item: any) => {
        if (item?.shopping_list_item?._KEY) {
          return (
            item.shopping_list_item._KEY === cartbox.shopping_list_item._KEY &&
            item._KEY !== cartbox._KEY
          );
        }
        return false;
      })
    );

    if (!products || !productSL) {
      const incompleteSL = incompleteProducts.some((product: any) => {
        if (product.shoppingListItem) {
          return product.shoppingListItem === cartbox.shopping_list_item._KEY;
        }
        return false;
      });
      if (!incompleteSL) {
        return true;
      }
    }
  }

  return false;
}

export function arrayToObject(array: any[], key: string) {
  return array.reduce((acc, item) => {
    const val = item[key];
    acc[val] = item;
    return acc;
  }, {});
}

export const groupColorsByReference = (colorList: Color[]) => {
  return colorList.reduce((obj: any, color) => {
    Object.keys(Environment.colorList).forEach((key) => {
      const category = Environment.colorList[key].find(
        (colorKey: string) => color._KEY === colorKey
      );
      if (category) {
        obj[key] = obj[key] ? [...obj[key], color] : [color];
      }
    });
    return obj;
  }, {});
};

export function getNextWeekDay(number_date: number) {
  const firstDay = convertNumberDateToDate(number_date);
  return convertDateToStantard(
    new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000)
  );
}

export const checkHoliday: any = (
  defaultDate: number,
  holidays: Array<any>,
  blockDays: Array<any>
) => {
  const validate = holidays.some(
    (holiday: Holiday) => holiday && holiday.date === defaultDate
  );
  const weekDay = convertNumberDateToDate(defaultDate).getDay();

  if (validate || blockDays.indexOf(weekDay) !== -1) {
    let nextDate = convertNumberDateToDate(defaultDate);
    nextDate.setDate(nextDate.getDate() + 1);

    return checkHoliday(convertDateToNumberDate(nextDate), holidays, blockDays);
  } else {
    return defaultDate;
  }
};

export const getOrangeDays = (
  holidays: Array<any>,
  blockDays: Array<any>,
  time?: number
) => {
  let todayOrange = new Date();
  if (time && time > 800) {
    todayOrange.setDate(todayOrange.getDate() + 1);
  }
  let days: Array<any> = [];

  while (days.length < 3) {
    todayOrange.setDate(todayOrange.getDate() + 1);
    let nextDate = checkHoliday(
      convertDateToNumberDate(todayOrange),
      holidays,
      blockDays
    );
    if (!days.some((date: number) => date === nextDate)) {
      days.push(nextDate);
    }
  }
  return days;
};

export const cellphoneOptions: { [key: string]: any } = {
  option1: {
    label: 'Track&trace, delivery exceptions, date changes, payment issues',
    value: 'main_information'
  },
  option2: {
    label:
      'Reminder when your cart items are about to expire for a certain delivery date',
    value: 'cart_reminder'
  },
  option3: {
    label:
      'Punchcard, voucher reminders and other relevant iBuyFlowers.com updates',
    value: 'promo_information'
  }
};

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const getWeekNumber = (weekDate: number) => {
  const date1 = new Date(convertNumberDateToDate(weekDate));
  //find the year of the current date
  const oneJan = new Date(date1.getFullYear(), 0, 1);
  // calculating number of days in given year before a given date
  const numberOfDays = Math.floor(
    (date1.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000)
  );
  // adding 1 since to current date and returns value starting from 0
  return Math.ceil((date1.getDay() + 1 + numberOfDays) / 7);
};

export const findDateAfter24HIndex = (
  arrayDates: {
    charge_date: number;
    closing_time?: string;
    komet_time?: string;
  }[]
) => {
  let arrayDate = {
    index: -1,
    closing_time: ''
  };

  arrayDates.forEach((date, index) => {
    let today = moment.tz('America/New_York');
    let closingTime = date.closing_time ? date.closing_time.split(' ')[0] : '';
    const dateLimit = moment.tz(
      `${convertNumberDateYYYYMMDD(date.charge_date)} ${
        closingTime ? closingTime + ':00' : '08:00:00'
      }`,
      'America/New_York'
    );

    const hours = dateLimit.diff(today, 'hours', true);

    if (arrayDate.index === -1 && hours > 24 && !date.komet_time) {
      arrayDate.index = index;
      arrayDate.closing_time = closingTime ?? '08:00';
    } else {
      if (hours > 24 && !date.komet_time) {
        // arrayDate.index = index;
        if (
          date.closing_time &&
          getGrowerTime(closingTime) < getGrowerTime(arrayDate.closing_time)
        ) {
          arrayDate.closing_time = closingTime;
        } else {
          arrayDate.closing_time = closingTime ?? '08:00';
        }
      }
    }
  });

  return arrayDate.index;
};

export const findDateBefore24HIndex = (
  arrayDates: {
    charge_date: number;
    closing_time?: string;
    komet_time?: string;
  }[]
) => {
  let arrayDate = {
    index: -1,
    closing_time: '',
    komet_time: ''
  };

  arrayDates.forEach((date, index) => {
    let today = moment.tz('America/New_York');
    let closingTime = date.closing_time ? date.closing_time.split(' ')[0] : '';
    const dateLimit = moment.tz(
      `${convertNumberDateYYYYMMDD(date.charge_date)} ${
        closingTime ? closingTime + ':00' : '08:00:00'
      }`,
      'America/New_York'
    );

    const hours = dateLimit.diff(today, 'hours', true);
    if (index === -1 && (hours < 24 || date.komet_time)) {
      arrayDate.index = index;
      arrayDate.closing_time = closingTime ?? '08:00';
      if (date.komet_time) {
        arrayDate.komet_time = date.komet_time;
      }
    } else {
      if ((hours < 24 || date.komet_time) && !arrayDate.komet_time) {
        arrayDate.index = index;

        if (
          date.closing_time &&
          getGrowerTime(closingTime) < getGrowerTime(arrayDate.closing_time)
        ) {
          arrayDate.closing_time = closingTime;
        } else {
          arrayDate.closing_time = closingTime ?? '08:00';
        }

        if (date.komet_time) {
          arrayDate.komet_time = date.komet_time;
        }
      }
    }
  });

  return arrayDate.index;
};

export const getBoxQuantity = (box: OrderItem) => {
  if (box?.units_masterlist && (box.box_only || !box.customizable)) {
    if (box.masterlist_uom === 'bunch') {
      return box.total_stems / (box.units_masterlist * box.stem_bunch);
    } else {
      return box.total_stems / box.units_masterlist;
    }
  } else {
    return box.stem_bunch * box.total_bunches;
  }
};

export type SignInOptions = {
  label: string;
  value: string;
  children_questions_id?: string;
};

export enum SignInBusinessTypes {
  business = 'business',
  events_per_year = 'events_per_year',
  business_is = 'business_is'
}

type SignInQuestions = {
  id: string;
  label: string;
  parent_id: string;
  children_id: string;
  options: SignInOptions[];
};

export const signInQuestions: { [key: string]: SignInQuestions } = {
  main_menu: {
    id: 'main_menu',
    label:
      'Select your business type, so we can provide you the correct info: ',
    parent_id: '',
    children_id: '',
    options: [
      // { label: 'Retail florist', value: '', children_questions_id: 'retail' },
      {
        label: 'Florist',
        value: 'Florist shop',
        children_questions_id: 'florist'
      },
      {
        label: 'Wedding & Event only',
        value: 'Wedding & event floral designer only (not a florist)',
        children_questions_id: 'wedding'
      },
      {
        label: 'Other',
        value: 'Other',
        children_questions_id: 'other_business_type'
      }
      /*{
        label: 'Consumer, not a business, ie LLC',
        value: '',
        children_questions_id: 'consumer'
      },
      {
        label: 'Other, namely...',
        value: '',
        children_questions_id: 'other_business_type'
      }*/
    ]
  },

  retail: {
    id: 'business',
    label: 'What kind of Retail shop are you? (choose the best fitting)',
    parent_id: 'main_menu',
    children_id: 'business_is_normal',
    options: [
      {
        label: 'Florist shop',
        value: 'Florist shop'
      },
      { label: 'Gift & Home Decor', value: 'Gift & Home Decor' },
      {
        label: 'Flower delivery service only',
        value: 'Flower delivery service only'
      },
      {
        label: 'Other, namely...',
        value: '',
        children_questions_id: 'other_business_type'
      }
    ]
  },

  wedding: {
    id: 'business',
    label: "I'm a... (choose the best fitting)",
    parent_id: 'main_menu',
    children_id: 'business_is_alternative',
    options: [
      {
        label: 'Floral designer',
        value: 'Wedding & event floral designer only (not a florist)'
      },
      {
        label: 'Planner (only), not the floral designer',
        value: 'Event planner (only), not a floral designer or florist'
      },
      { label: 'Venue only', value: 'Venue' },
      {
        label: 'Other, namely...',
        value: '',
        children_questions_id: 'other_business_type'
      }
    ]
  },

  other_business_type: {
    id: 'business',
    label: 'Choose your business type to continue: (choose the best fitting)',
    parent_id: 'main_menu',
    children_id: 'final',
    options: [
      {
        label: 'Flower delivery service only',
        value: 'Flower delivery service only',
        children_questions_id: 'business_is_normal'
      },
      { label: 'Hotel', value: 'Hotel' },
      {
        label: 'Gift & Home Decor',
        value: 'Gift & Home Decor',
        children_questions_id: 'business_is_normal'
      },
      { label: 'Venue only', value: 'Venue' },
      { label: 'Supermarket', value: 'Supermarket' },
      { label: 'Photographer', value: 'Photographer' },
      { label: 'Grower / Flower farm', value: 'Grower / Flower farm' },
      { label: 'Garden center', value: 'Garden center' },
      { label: 'Church', value: 'Church' },
      { label: 'School', value: 'School' },
      {
        label: 'Beauty salon / Spa / Sauna',
        value: 'Beauty salon / Spa / Sauna'
      },
      { label: 'Other', value: 'Other' }
    ]
  },

  business_is_normal: {
    id: 'storefront_type',
    label: 'My business is a',
    parent_id: '',
    children_id: 'events_per_year',
    options: [
      { label: 'Physical shop', value: 'Physical shop' },
      { label: 'Studio (not home-based)', value: 'Studio (not home-based)' },
      { label: 'Flower truck', value: 'Flower truck' },
      { label: 'Home-based studio', value: 'Home-based studio' }
    ]
  },

  business_is_alternative: {
    id: 'storefront_type',
    label: 'My business is a',
    parent_id: 'wedding',
    children_id: 'events_per_year',
    options: [
      { label: 'Physical shop', value: 'Physical shop' },
      { label: 'Studio (not home-based)', value: 'Studio (not home-based)' },
      { label: 'Home-based studio', value: 'Home-based studio' }
    ]
  },

  events_per_year: {
    id: 'events_per_year',
    label: 'How many weddings or floral events do you have?',
    parent_id: '',
    children_id: 'final',
    options: [
      { label: 'None - 9 events a year', value: '< 10' },
      { label: '10-24 events a year', value: '10 - 24' },
      { label: '25-59 events a year', value: '25 - 59' },
      { label: '60+ events a year', value: '> 60' }
    ]
  },
  retail_shop_studio: {
    id: 'retail_shop_studio',
    label: 'Retail shop Studio (not home) Residential',
    parent_id: '',
    children_id: '',
    options: [
      { label: 'Retail shop', value: 'Physical shop' },
      { label: 'Studio (not home)', value: 'Studio (not home-based)' },
      { label: 'Home/other', value: 'Home-based studio' }
    ]
  }
};

export const updateCartboxEventObj = ({
  productsByEventName,
  eta_date,
  tag_name,
  cartboxArray
}: {
  productsByEventName: ProductsByEventName;
  eta_date: number;
  tag_name: string;
  cartboxArray: Cartbox[];
}) => {
  return {
    ...productsByEventName,
    [tag_name]: {
      ...productsByEventName[tag_name],
      dates: {
        ...productsByEventName[tag_name].dates,
        [eta_date]: [...cartboxArray]
      }
    }
  };
};

export const deleteEtaFromEventObj = (
  eta_date: number,
  tag_names: string[],
  productsByEventName: ProductsByEventName
) => {
  tag_names.forEach((tag) => {
    if (productsByEventName[tag]['dates'][eta_date].length === 0) {
      delete productsByEventName[tag]['dates'][eta_date];
    }

    if (Object.keys(productsByEventName[tag]['dates']).length === 0) {
      delete productsByEventName[tag];
    }
  });

  return productsByEventName;
};

export const updateProductsByName = ({
  products,
  cart_box,
  event
}: {
  products: ProductsByEventName;
  cart_box: Cartbox;
  event: EventCartbox | null;
}) => {
  const eventKey = event?._KEY ?? 'Not related to an event name';
  const cartboxEventKey =
    cart_box.event?._KEY ?? 'Not related to an event name';

  if (!products[eventKey]) {
    products[eventKey] = { dates: {}, event: event ?? null, index_order: 0 };
  }

  if (!products[cartboxEventKey]) {
    products[cartboxEventKey] = {
      dates: {},
      event: event ?? null,
      index_order: 0
    };
  }

  let eventDate = products[eventKey]['dates'][cart_box.eta_date] ?? [];

  // Check if the date exist in the event
  if (eventDate.length === 0) {
    const currentEvent = products[eventKey];
    // Add the new date to the event.dates object
    products = {
      ...products,
      [eventKey]: {
        dates: { ...currentEvent.dates, [cart_box.eta_date]: [] },
        event: currentEvent.event,
        index_order: currentEvent.index_order
      }
    };
  }

  // Take the last event from the box and delete is deleting from it.
  let boxEventsDate = [];
  // If exist and event name, we'll filter by cartbox event name for delete the current cartbox in the past event
  let tag_filter = products[eventKey] ? cartboxEventKey : eventKey;
  boxEventsDate = products[tag_filter]['dates'][cart_box.eta_date];

  boxEventsDate = boxEventsDate.filter(
    (product: Cartbox) => product._KEY !== cart_box._KEY
  );
  // Update the previous event that had the cartbox
  products = updateCartboxEventObj({
    productsByEventName: products,
    eta_date: cart_box.eta_date,
    tag_name: tag_filter,
    cartboxArray: boxEventsDate
  });

  // Update the cartboxes in the event.dates object
  products = updateCartboxEventObj({
    productsByEventName: products,
    eta_date: cart_box.eta_date,
    tag_name: eventKey,
    cartboxArray: [...products[eventKey]['dates'][cart_box.eta_date], cart_box]
  });

  products[eventKey]['dates'][cart_box.eta_date] = products[eventKey]['dates'][
    cart_box.eta_date
  ].map((product) => {
    if (product._KEY === cart_box._KEY) {
      product = { ...product, event: event ?? null };
    }

    return product;
  });

  //Delete etas with empty []
  products = deleteEtaFromEventObj(
    cart_box.eta_date,
    [eventKey, cartboxEventKey],
    products
  );

  return products;
};

export const getProductsByDateAndEventName = (productList: Cartbox[]) => {
  const productsByDate = productList.reduce(
    (obj: { [key: string]: Cartbox[] }, product) => {
      if (!obj[product.eta_date]) {
        obj[product.eta_date] = [];
      }

      obj[product.eta_date].push(product);
      return obj;
    },
    {}
  );

  const productsByEventName = productList.reduce(
    (obj: ProductsByEventName, product) => {
      const event = product.event;
      const eventKey = event?._KEY ?? 'Not related to an event name';

      if (!obj[eventKey]) {
        obj[eventKey] = { dates: {}, event: event ?? null, index_order: 0 };
      }

      const productList = obj[eventKey]['dates'][product.eta_date] ?? [];

      const index_order = event ? productList.length : -1;
      obj[eventKey] = {
        dates: {
          ...obj[eventKey]['dates'],
          [product.eta_date]: [...productList, product]
        },
        event: event ?? null,
        index_order
      };

      return obj;
    },
    {}
  );

  return { productsByEventName, productsByDate };
};

export const goToSignup = (dispatch: Dispatch<any>) => {
  return dispatch(actionsModal.setOpenModal({ modal: SIGN_UP_MODAL }));
};

export const wordNonSpecialCharacters = (word: string) => {
  return word.replace(/[^a-zA-Z ]/g, '');
};
type ColumnNameArrivalTable = {
  [key: string]: string;
};

export const convertStringToDate = (item: string) =>
  moment(item, 'YYYYMMDD').format('YYYY-MM-DD');

export const columnName: ColumnNameArrivalTable = {
  charge_date: 'Charge capture',
  card_name: 'Your card',
  eta_date: 'Delivery date',
  po: 'Purchase#',
  order: 'Order #',
  product_group: 'Product group',
  variety_grade: 'Variety',
  length: 'length',
  grower: 'Grower',
  event_name: 'related to an event name',
  total_stems: 'stems',
  per_stems: '$ per stems',
  stems_per_bunch: 'in buches #',
  per_bunch: '$ per bunch',
  total_price: '$ total prices'
};

export const findEventName = (
  array: Array<EventCartbox>,
  value?: string,
  condition?: string
) => {
  let element;
  if (condition === 'eventName') {
    element = array.find((item) => item._KEY === value);
  }

  return element;
};

export const companyInformationOptions = {
  stores_quantity: [
    { label: '— Select the number of stores', value: '' },
    ...Array.from({ length: 10 }, (_, i) => ({
      value: i + 1,
      label: (i + 1).toString()
    }))
  ],
  employees: [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10+', value: 10 }
  ],
  expenses: [
    '> $1000USD',
    '$500USD - $999USD',
    '$100USD - $499USD',
    '< $100USD'
  ],
  events: [
    { label: 'With 0 - 9 events a year', value: '< 10' },
    { label: 'With 10 - 24 events a year', value: '10 - 24' },
    { label: 'With 25 - 59 events a year', value: '25 - 59' },
    { label: 'With 60+ events a year', value: '> 60' }
  ],
  socialMedia: [
    'Another florist or wedding and event planner told me',
    'Facebook',
    'LinkedIn',
    'Google',
    'Instagram',
    'E-mail',
    'iBuyFlowers.com reached out to me (phone or e-mail)'
  ],
  business: [
    {
      label: 'Florist shop',
      value: 'Florist shop'
    },
    {
      label: 'Gift & Home Decor',
      value: 'Gift & Home Decor'
    },
    {
      label: 'Wedding & event floral designer only (not a florist)',
      value: 'Wedding & event floral designer only (not a florist)'
    },
    {
      label: 'Event planner (only), not a floral designer or florist',
      value: 'Event planner (only), not a floral designer or florist'
    },
    {
      label: 'Flower delivery service only',
      value: 'Flower delivery service only'
    },
    { label: 'Photographer', value: 'Photographer' },
    { label: 'Grower / Flower farm', value: 'Grower / Flower farm' },
    { label: 'Garden center', value: 'Garden center' },
    { label: 'Church', value: 'Church' },
    {
      label: 'Beauty salon / Spa / Sauna',
      value: 'Beauty salon / Spa / Sauna'
    },
    { label: 'Hotel', value: 'Hotel' },
    { label: 'Supermarket', value: 'Supermarket' },
    { label: 'School', value: 'School' },
    { label: 'Other', value: 'Other' },
    { label: 'Venue', value: 'Venue' }
  ],
  signUpToday: [
    'My wholesaler closed (I need another source)',
    'My wholesaler is still open (but I want another source)'
  ],
  placeOrder: ['Within 7 days', 'Within 14 days', 'Within a month', 'Later'],
  storefront: [
    { label: 'Physical shop', value: 'Physical shop' },
    { label: 'Studio (not home-based)', value: 'Studio (not home-based)' },
    { label: 'Flower truck', value: 'Flower truck' },
    { label: 'Home-based studio', value: 'Home-based studio' }
  ]
};

export const showConfirmProfileModal = (user: User) => {
  const today = moment().tz('America/New_York');
  const signup_date = moment(user.metadata.customer_signup_date).tz(
    'America/New_York'
  );

  const isAdmin = user.main_user_is_admin ?? user.is_admin;

  return (
    Math.abs(today.toDate().getTime() - signup_date.toDate().getTime()) >
      86400 * 1000 &&
    user.role === UserRole.FLORIST &&
    isAdmin &&
    !user.metadata.customer_confirm_profile
  );
};
export const showTotalPrice = (dataset: Array<OrderItemCsv>) => {
  let totals: { [key: string]: number } = {};
  for (const row of dataset) {
    let en: string = '';
    let enV: number = 0;
    let splitTotal = '';
    if (
      (row.event_name === undefined ||
        row.event_name === null ||
        row.event_name === 'null') &&
      row.total_price !== undefined &&
      row.total_price !== null &&
      row.total_price !== ''
    ) {
      en = 'Not related to an event name';
      splitTotal = row.total_price.substring(1);
      enV = parseFloat(splitTotal);
    } else if (
      row.event_name !== undefined &&
      row.event_name !== null &&
      row.event_name !== 'null' &&
      row.total_price !== undefined &&
      row.total_price !== null &&
      row.total_price !== ''
    ) {
      en = row.event_name;
      splitTotal = row.total_price.substring(1);
      enV = parseFloat(splitTotal);
    } else if (row.total_price === null || row.total_price === '') {
      continue;
    } else {
      continue;
    }

    if (!Object.keys(totals).includes(en)) {
      totals[en] = +enV;
    } else {
      totals[en] = +enV + +totals[en];
    }
  }
  return totals;
};
export const filterStatements = (
  statements: Array<OrderItem>,
  selectedValue: string
) => {
  let arrFiltered = [...statements];

  arrFiltered.sort((a, b) => {
    return parseInt(b?.eta_date!) - parseInt(a?.eta_date!);
  });

  return arrFiltered.reduce(
    (obj: { [key: string]: Array<OrderItem> }, item: OrderItem) => {
      let date = moment(item?.eta_date, 'YYYYMMDD');
      const year = date.year();
      const monthLetter = date.format('MMMM');

      const week = date.isoWeek().toString();
      let column = '';
      if (selectedValue === 'month') {
        column = monthLetter + ' ' + year;
        if (!(column in obj)) {
          obj[column] = [];
        }
      }
      if (selectedValue === 'week') {
        let weekStart = moment(date)
          .startOf('week')
          .format('MMM DD');
        let weekEnd = moment(date)
          .endOf('week')
          .format('MMM DD');
        let weekRange = weekStart + ' - ' + weekEnd;
        column = `${week} ${year} (${weekRange})`;
        //column = week + ' ' + year + '  ' + '  ' + ' (' + weekRange + ')';
        if (!(column in obj)) {
          obj[column] = [];
        }
      }
      if (selectedValue === 'year') {
        column = ` ${year}`;

        if (!(column in obj)) {
          obj[column] = [];
        }
      }
      if (selectedValue === 'event') {
        if (item.event_name !== null && item.event_name !== undefined) {
          column = `"${item.event_name}" `;
        } else {
          column = `"Not related to an event name" `;
        }

        if (!(column in obj)) {
          obj[column] = [];
        }
      }
      obj[column].push(item);
      return obj;
    },
    {}
  );
};
export const loopArrayStatements = (statements: Array<OrderItem>) => {
  let item: any, subItem: any, element: OrderItem;
  let newArrayStatements: Array<OrderItem> = [];
  for (item of statements) {
    for (subItem of item) {
      for (element of subItem) {
        newArrayStatements.push(element);
      }
    }
  }
  return newArrayStatements;
};

export const filteredArrayStatements = (
  statements: { [key: string]: Array<OrderItem> },
  selectedValue: string
) => {
  let newArrayStatements: Array<{
    type: string;
    key: string;
    data: Array<OrderItem>;
  }> = [];

  for (let [key, value] of Object.entries(statements)) {
    /*  let filterValue = value.filter(item =>{

      return !item.parent
    }

    ) */
    let row = { key: key, data: value, type: selectedValue };

    newArrayStatements.push(row);
  }
  return newArrayStatements;
};

export const formatValueExcel = (
  element: OrderItemCsv,
  dateElement?: string
) => {
  let dataEventName;
  let dateCharge_date = null;
  if (element?.event_name) {
    dataEventName = element.event_name;
  } else {
    dataEventName = 'Not related to an event name';
  }

  dateCharge_date = moment(dateElement, 'YYYYMMDD').isValid()
    ? convertStringToDate(dateElement || '')
    : null;

  let deliveryDate = moment(element.eta_date, 'YYYYMMDD').isValid()
    ? convertStringToDate(element.eta_date || '')
    : null;
  let totalPrice = element?.total_price
    ? formatCurrency(parseFloat(roundUp(element.total_price)))
    : null;
  let perStemsBunch = element?.per_bunch
    ? formatCurrency(element.per_bunch)
    : null;

  let perStems = element?.per_stems
    ? formatCurrency(parseFloat(roundUp(element.per_stems)))
    : null;
  let objectElement: {} = {
    ...element,
    charge_date: dateCharge_date,
    total_price: totalPrice,
    eta_date: deliveryDate,
    per_bunch: perStemsBunch,
    per_stems: perStems,
    event_name: dataEventName
  };

  return { objectElement };
};

export const totalPriceDataSet = (dataset: Array<OrderItemCsv>) => {
  let totals: number = 0;
  for (const row of dataset) {
    if (!row.total_price) {
      totals = 0;
    } else {
      totals += parseFloat(row?.total_price);
    }
  }
  return totals;
};

export const utmReferralEvent = (eventName: string) => {
  const {
    email,
    referral_code,
    customerKey
  }: { [key: string]: any } = hrefToObject();
  TrackEventMonitor.newEvent({
    props: {
      date: convertDateToNumberDate(new Date()),
      referral_key: referral_code,
      referred: email,
      referrer: customerKey
    },
    metadata: {
      name: eventName
    }
  });
};

export const utmOnErrorRedirect = (history: any) => {
  const location = history.location;

  // history.push("/search"+location.search ?? "")

  if (hrefContainsProperty('referral_code')) {
    utmReferralEvent('ibf_referral_opened');
  }
  const promoParams = window.location.href.split('?');

  if (
    location.search.includes('search_val') ||
    location.pathname === '/search'
  ) {
    window.location.href = window.location.href.replace('/#/search', '/#/home');
  } else {
    const { utm_campaign }: { [key: string]: any } = hrefToObject();
    if (utm_campaign === 'Lead invitation') {
      window.location.href = `${promoParams[0]}#/home${
        promoParams[2] ? `?${promoParams[2]}` : ''
      }`;
    }
  }
};

export const utmOnSuccessRedirect = ({
  customer,
  history
}: {
  customer: string;
  history: any;
}) => {
  //debugger;
  if (hrefContainsProperty('redirect')) {
    const { redirect }: { [key: string]: any } = hrefToObject();
    if (redirect === 'checkout') {
      window.location.href = Environment.urlCheckout;
    } else {
      if (window.location.href.includes('home?redirect=#/search')) {
        const [, , search] = window.location.href.split('#/');
        window.location.replace('/#/' + search);
      } else {
        window.location.replace(redirect);
      }
    }
  } else {
    const promoParams = window.location.href.split('?');
    if (hrefContainsProperty('utm_campaign')) {
      const {
        utm_campaign,
        utm_medium,
        utm_source
      }: { [key: string]: any } = hrefToObject();
      TrackEventMonitor.newEvent({
        props: {
          utm_medium,
          utm_campaign,
          utm_source,
          campaign_code: utm_campaign
        },
        metadata: {
          name: 'ibf_utm_click'
        }
      });
      const utm_code = `${promoParams[1]}?${promoParams[2]}`;
      addUtmCode(utm_code, customer);
    }
    const utm_code = promoParams[1];
    if (window.location?.href?.indexOf('utm_referrer') !== -1) {
      addUtmCode(utm_code, customer);
    }
    const code = localStorage.getItem('utm_code');
    if (code) {
      addUtmCode(code, customer).then(() => {
        localStorage.removeItem('utm_code');
      });
    }
    if (window.location.hash.indexOf('auth/validate') !== -1) {
      window.location.href = addQueryParamsToPath('/#/home');
    }
  }
  // console.log("window.location",window.location)
  if (
    window.location.href.includes('?') &&
    !window.location.href.includes('login_as') &&
    !window.location.href.includes('user_key')
  ) {
    const promoParams = window.location.href.split('?');

    if (promoParams.length > 0) {
      // const params = promoParams[1].split('&');

      // const searchVal = params.find((i) => i.indexOf('search_val') !== -1);
      // const date = params.find((i) => i.indexOf('date') !== -1);
      window.location.href = `${window.location.origin}/#/search?${promoParams[1]}`;

      // window.location.href = `${window.location.origin}/#/search?${
      //   date ? date + '&' : ''
      // }${searchVal ? searchVal?.replace('#/home', ''):""}`;
    }
  }
};

// export const utmOnSuccessRedirect = ({
//   customer,
//   history
// }: {
//   customer: string;
//   history: any;
// }) => {
//   //debugger;
//   if (hrefContainsProperty('redirect')) {
//     const { redirect }: { [key: string]: any } = hrefToObject();
//     if (redirect === 'checkout') {
//       window.location.href = Environment.urlCheckout;
//     } else {
//       if (window.location.href.includes('home?redirect=#/search')) {
//         const [, , search] = window.location.href.split('#/');
//         window.location.replace('/#/' + search);
//       } else {
//         window.location.replace(redirect);
//       }
//     }
//   } else {
//     const promoParams = window.location.href.split('?');
//     if (hrefContainsProperty('utm_campaign')) {
//       const {
//         utm_campaign,
//         utm_medium,
//         utm_source
//       }: { [key: string]: any } = hrefToObject();
//
//       TrackEventMonitor.newEvent({
//         props: {
//           utm_medium,
//           utm_campaign,
//           utm_source,
//           campaign_code: utm_campaign
//         },
//         metadata: {
//           name: 'ibf_utm_click'
//         }
//       });
//
//       const utm_code = `${promoParams[1]}?${promoParams[2]}`;
//
//       addUtmCode(utm_code, customer);
//     }
//     const utm_code = promoParams[1];
//     if (window.location?.href?.indexOf('utm_referrer') !== -1) {
//       addUtmCode(utm_code, customer);
//     }
//     const code = localStorage.getItem('utm_code');
//     if (code) {
//       addUtmCode(code, customer).then(() => {
//         localStorage.removeItem('utm_code');
//       });
//     }
//     if (window.location.hash.indexOf('auth/validate') !== -1) {
//       window.location.href = addQueryParamsToPath('/#/home');
//     }
//   }
//
//   if (window.location.href.includes('?')) {
//     const promoParams = window.location.href.split('?');
//     if (promoParams[1]?.includes('search_val')) {
//       const searchVal = promoParams[1]
//         .split('&')
//         .find((i) => i.indexOf('search_val') !== -1);
//
//       window.location.href = `${
//         window.location.origin
//       }/#/search?${searchVal?.replace('#/home', '')}`;
//     }
//   }
// };

export const setTrackingAuthorization = (authData: AuthResponse) => {
  TrackEventMonitor.setUser({
    user_id: authData.user.user_id
  });

  TrackEventMonitor.setMetadataExtra({
    user_key: authData.user.key,
    user_email: authData.user.email,
    customer_key: authData.user.metadata.customer
  });

  Sentry.setUser({
    id: authData.user.id.toString(),
    email: authData.user.email,
    customer: authData.user.metadata.customer,
    user_key: authData.user.key
  });

  MixpanelService.setPeople(authData.user);
  if (
    (!authData.user.metadata.from_manager ||
      !authData.user.email.includes('ibuyflowers')) &&
    (window.location.origin === 'https://test.ibuyflowers.com' ||
      window.location.origin === 'https://app.ibuyflowers.com')
  ) {
    hlp.identify(authData.user.user_id, {
      customer_key: authData.user.metadata.customer,
      user_key: authData.user.key,
      user_email: authData.user.metadata.main_user_email ?? authData.user.email,
      customer_email: authData.user.metadata.customer_office_email,
      guest: authData.user.metadata.guest,
      last_order_number: authData.user.metadata.last_order_number
    });
  }
};

export const signIn = async ({
  params,
  firstTime,
  reload = true
}: {
  params: SignInGuestParams;
  firstTime?: boolean;
  reload?: boolean;
}) => {
  const res = await guestSignIn(params);
  if (res && res.success) {
    MixpanelService.track('login_guest');
    const token = res.response && res.response.token;
    const eventResponse = res.response;
    delete eventResponse.token;
    if (firstTime) {
      TrackEventMonitor.newEvent({
        props: { params, response: eventResponse },
        metadata: { name: 'ibf_sign_in_success' }
      });
    }

    if (window && window.gtag) {
      const callback = function() {
        return;
      };
      window.gtag('event', 'conversion', {
        send_to: 'AW-869926913/W25sCN2PmMsBEIGQ6J4D',
        event_callback: callback
      });

      window.gtag('event', 'conversion', {
        send_to: 'AW-869926913/ymztCMzNiYwYEIGQ6J4D'
      });
    }

    if (reload) {
      toast('Sign in successfull!');
      localStorage.setItem('token', token);
      window.location.reload();
    }

    return;
  } else {
    if (firstTime) {
      TrackEventMonitor.newEvent({
        props: { params, response: res },
        metadata: { name: 'ibf_sign_in_rejected' }
      });
    }
    toast(res.error || 'unable to login', 'error');
    return false;
  }
};

export const removeDuplicateFromArrayObj = (arr: any[], key: string) => {
  const seen = new Set();

  return arr.filter((element) => {
    const duplicate = seen.has(element[key]);
    seen.add(element[key]);
    return !duplicate;
  });
};

export const removeDuplicateFromArray = (array: any[]) => {
  return array.filter((c, index) => {
    return array.indexOf(c) === index;
  });
};

export const changeCustomerActualAddress = (
  value?: string | number,
  customer_actual_address?: string | number,
  input?: string
) => {
  let changeValue = false;
  if (!customer_actual_address) {
    return false;
  }

  if (value !== customer_actual_address && input) {
    changeValue = true;
  }

  return changeValue;
};

export const selectDate = (
  handleDate: Date,
  search: string,
  filters_conditions: object,
  date: number | string,
  pathname: string
) => {
  MixpanelService.track('select_date', { handleDate });
  const queryParams = {
    ...hrefToObject(),
    date: convertDateToNumberDate(handleDate)
  };
  if (pathname !== '/search' && pathname !== '/product') {
    pathname = '/search';
  }
  const params = {
    search_val: search,
    ...filters_conditions,
    ...queryParams
  };

  if (window.dataLayer) {
    window.dataLayer.push({ event: 'Date selected', value: '' });
  }

  TrackEventMonitor.newEvent({
    props: {
      prev_date: Number(date) ?? 0,
      date_selected: convertDateToNumberDate(handleDate)
    },
    metadata: {
      name: 'ibf_eta_selected'
    }
  });
  return { search: params, pathname };
};

export const BaseImgUrlVarieties =
  Environment.publicPath + 'varieties/variety_';

export function getVarietyImage(variety: Product) {
  return `${BaseImgUrlVarieties}${variety.key}.jpg?t=${new Date().getTime()}`;
}

declare global {
  interface Window {
    gtag_report_conversion: () => void;
  }
}

let time: any;

export function debounceTime(
  func: (params: any) => any,
  params: any,
  timeout: number
): Promise<any> {
  if (time) clearTimeout(time);
  return new Promise((resolve) => {
    time = setTimeout(async () => {
      const res = await func(params);
      resolve(res);
    }, timeout);
  });
}

export const PAGE_SIZE_PRODUCT = 40;

export const growerNameSortNumber: { [id: string]: number } = {
  'United States': 1,
  Colombia: 2,
  Ecuador: 3
};

export const searchInventory = (
  dispatch: Dispatch<any>,
  customer: string,
  search: string,
  currentPercentage: number,
  eta: string | number,
  filters: any
) => {
  return dispatch(
    actionsCustomBox.searchInventory({
      params: {
        conditions: {
          search: search,
          custom: true,
          currentPercentage: currentPercentage,
          filters: filters
        },
        customer: customer,
        eta: eta,
        page: 1,
        pageSize: 120
      }
    })
  );
};

export const filterAddress = (
  item: CustomerAddress,
  street: string,
  zipcode: string,
  state: string,
  city: string
) => {
  let res =
    item?.address === street &&
    item?.state === state &&
    item?.zipcode === zipcode &&
    item?.city === city;
  return !res;
};
export const validationNumber = (data: string) => {
  return /^[0-9]/.test(data);
};
export const validationSpecialCharacters = (data: string) => {
  return /^[a-zA-Z\s]*$/.test(data);
};
export const validateNumberLetters = (data: string) => {
  return /^[a-zA-Z0-9 ]+$/.test(data);
};

export const redirectUrlIbuyflowers = (text: string, url: string) => {
  return `${text.substring(0, text.length - 3)}${url}`;
};

export const calculateParamsStandingOrderEmail = (
  data: sendEmailStandingOrder,
  email: string,
  first_name: string,
  last_name: string
) => {
  const validateCustom = data?.box?.custom;
  let totalPrices;
  let box = validateCustom ? data?.box?.box[0] : data?.box?.box;
  let previousPrice;
  let customer = `${first_name} ${last_name}`;
  let grower_email: string = box?.grower_obj?.email_address;
  let consecutive: number = box?.po;
  let grower: string = box?.grower_obj?.company_name;
  let totalBunches: number = box?.total_bunches;
  let price;
  let prices = data?.box?.prices;
  let subjectCustomer = `Your Standing Order request of ${data.startDate}`;
  let subjectGrower = `Confirmation: Your new Standing Order is Activated (${data.startDate})`;
  const boxes: Array<Boxes> = [];
  if (validateCustom) {
    totalPrices =
      data.chooseFrecuency === 'Weekly'
        ? Object.keys(prices?.masterlist_prices)
            .reduce((sum, i) => {
              let it = prices?.masterlist_prices[i];
              let item = data.box.box.filter((it2: OrderEntity) => {
                return it2.masterlist_key === i;
              })[0];
              sum = sum + it.weekly_price.price_per_bunch * item?.quantity;
              return sum;
            }, 0)
            .toFixed(2)
        : Object.keys(prices?.masterlist_prices)
            .reduce((sum, i) => {
              let it = prices?.masterlist_prices[i];
              let item = data.box.box.filter((it2: OrderEntity) => {
                return it2.masterlist_key === i;
              })[0];
              sum = sum + it.bi_weekly_price.price_per_bunch * item?.quantity;
              return sum;
            }, 0)
            .toFixed(2);
    data?.box?.box?.slice(1).map((res: OrderEntity) => {
      price =
        data.chooseFrecuency === 'Weekly'
          ? prices?.masterlist_prices[res.masterlist_key!]?.weekly_price
              ?.price_per_bunch
          : prices?.masterlist_prices[res.masterlist_key!]?.bi_weekly_price
              ?.price_per_bunch;
      previousPrice =
        prices?.masterlist_prices[res.masterlist_key!]?.normal_price
          ?.price_per_bunch;
      return boxes.push({
        _KEY: res?.cart_box_item_key!,
        product_name: res?.product_group,
        grade: res?.variety_grade,
        // color: res?.variety_grade,
        bunch: res?.total_bunches,
        picture: res?.img[0],
        picture2: res?.img[1],
        length: res?.length,
        growerPrice:
          data.chooseFrecuency === 'Weekly'
            ? prices?.masterlist_prices[res.masterlist_key!]?.weekly_price
                ?.grower_price
            : prices?.masterlist_prices[res.masterlist_key!]?.bi_weekly_price
                ?.grower_price
      });
    });
  } else {
    price =
      data.chooseFrecuency === 'Weekly'
        ? prices?.weekly_price?.price_per_bunch
        : prices?.bi_weekly_price?.price_per_bunch;
    totalPrices = (price * totalBunches).toFixed(2);
    previousPrice = prices?.normal_price?.price_per_bunch;
    boxes.push({
      _KEY: box?._KEY,
      product_name: box?.product_group,
      grade: box?.variety_grade,
      // color: res?.variety_grade,
      bunch: box?.total_bunches,
      picture: box?.img[0],
      picture2: box?.img[1],
      length: box?.length,
      growerPrice:
        data.chooseFrecuency === 'Weekly'
          ? prices?.weekly_price?.grower_price
          : prices?.bi_weekly_price?.grower_price
    });
  }

  const params: SendTemplateStandingOrder = {
    customer,
    grower_email: grower_email,
    custom_email: email,
    subjectCustomer: subjectCustomer,
    subjectGrower: subjectGrower,
    grower,
    totalBunches,
    consecutive,
    priceResult: totalPrices,
    price,
    previousPrice,
    validateCustom,
    chooseFrecuency: data.chooseFrecuency,
    startDate: data.startDate,
    endDate: data.endDate,
    boxes
  };
  return params;
};

export const standingOrderPageSize = 7;

export const validateNumberNan = (value: number, data?: JSX.Element) => {
  return !isNaN(value) ? data : '';
};

export const removeBox = async (
  box: Cartbox,
  dispatch: Dispatch<any>,
  customer: string,
  fromBox: boolean
) => {
  const cartboxes = box.cartBoxes ? box.cartBoxes : [box._KEY];
  dispatch(
    actionsCheckout.removeFromBox({
      customer,
      cartboxes,
      invKey: !fromBox ? box.items[0].inventory._KEY : ''
    })
  );

  TrackEventMonitor.newEvent({
    props: {
      consecutive: box.consecutive,
      charge_date: box.charge_date,
      grower_name: box.grower?.company_name,
      eta_date: box.eta_date,
      current_percentage: box.current_percentage,
      current_items: box.current_items,
      custom: box.custom,
      cart_box_key: box._KEY,
      extra_freight_cost: box.extra_freight_cost,
      grower_key: box.grower?._KEY,
      items: box.items?.map((item: any) => ({
        quantity: item.quantity,
        cart_box_item_key: item._KEY,
        masterlist_key: item.masterlist._KEY
      })),
      packing_date: box.packing_date
    },
    metadata: {
      name: 'ibf_delete_box'
    }
  });

  for (const index in box.items) {
    const cartBoxItem = box.items[parseInt(index)];
    dispatch(
      actionsShoppingList.checkLastSLItem({
        slItemKey: cartBoxItem?.shopping_list_item?._KEY ?? '',
        productKey: box._KEY
      })
    );
  }
};

export const replaceSpecialCharacters = (str: string) => {
  return str.replace(/[^\w\s]/gi, '');
};

export const getTodayESTNumber = () => {
  return parseInt(
    moment(new Date())
      .tz('America/New_York')
      .format('YYYYMMDD')
  );
};

export const getEstNowTimeMinutes = () => {
  let today = moment(new Date()).tz('America/New_York');
  return today.hours() * 60 + (today.minutes() === 0 ? 1 : today.minutes());
};

export const isValidClosingTime = (
  closingTimeGrower: string,
  packing_date: number
) => {
  // console.log(today.hours(), today.minutes());

  if (closingTimeGrower && getTodayESTNumber() === packing_date) {
    // If now time is less than closing time grower, show product
    return getGrowerTime(closingTimeGrower) > getEstNowTimeMinutes();
  } else {
    return true;
  }
};

export const calculateDisabledDays = (
  disabled_calendar_days: {
    [key: string]: Array<string>;
  },
  stateKey: string
) => {
  let days: Array<number> = [];
  if (Object.keys(disabled_calendar_days).length > 0) {
    Object.entries(disabled_calendar_days!).forEach(([keyDay, state]) => {
      let getDay = moment()
        .day(keyDay)
        .weekday();

      if (state.length > 0 && state.includes(stateKey)) {
        days.push(getDay);
      }
    });
  }
  return days;
};

export const USA =
  Environment.domain === 96
    ? '67f917ac-e4d3-427b-ba4f-379f8097938e'
    : '23f73d62-3370-42e3-a9d2-2e453cb5cc44';

export const getGrowerTime = (closingTimeGrower: string) => {
  const t = closingTimeGrower.split(' ')[0];

  const d = new Date('0001-01-01T' + t);

  return (
    d.getHours() * 60 +
    (d.getMinutes() === 0 ? 1 : d.getMinutes()) -
    (d.getMinutes() === 0 ? 1 : 0)
  );
};

export const convertMinutesToHour = (num: number) => {
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return rhours + ':' + (rminutes < 10 ? '0' + rminutes : rminutes);
};

export const getNumbersWithoutSymbols = (data: string) => {
  if (data) {
    const newString = data.replace(/\D/g, '');
    return newString;
  } else {
    return '';
  }
};

export const statesManager: {
  [key: number]: { [manager: string]: string[] };
} = {
  96: {
    'amanager1@ibuyflowers.com': [
      '24370a5e-3e15-4337-8c06-57e26b96f1b0',
      '50ab4930-a0f3-4aff-948e-cd2aa105cb69',

      'a89bd316-7f0d-40e4-a861-24416ea48fea' //Nevada
    ],
    'amanager2@ibuyflowers.com': [
      '9282021f-144f-49c2-b69a-68dc0aa53b4a',
      '75a674ef-9a36-4209-b941-7ec66a673c26',
      'f1a0ddd9-9430-4f5e-96fe-d61a36a35af6',
      '7f684019-1751-447c-810c-646191e988cc', //Kentucky
      'f90a5957-9e9b-4be3-94a1-89f963c8275d',
      '80df38df-975f-4b85-a774-bf2ed38cbaf6',
      '4601da67-6b61-4ab3-bb26-cb2fd95e65b1', //Washington
      'd4e863d2-03d6-4e29-93a0-61cc9e09ba1e',
      'e810ef2a-b9cb-409f-80fb-e9682986c885',
      'c567b291-2b3b-4c37-b6fe-907e29c6bf3c',
      'ef085375-fb7a-409d-b8db-77a2f23b974f',
      '6219fd15-44a0-4b3d-a10a-b95223954ffb',
      'd06fd026-4f05-45c0-b819-9d19feda9467',
      'a8e274a5-ae4e-41c5-947c-3a75aca9f466',
      '8e415478-faff-4651-b024-e7f2de2f0bac',
      '2a39b230-a9b6-4e58-900e-ddbc2920f42b',
      '658b74e1-23c1-456a-867b-ffcf3dd67a6b',
      '027610e6-2f68-4251-bc24-c0599f12f361',
      'f7338180-d413-4fa0-98c6-609cd5d1ce37',
      'afd46c18-cab7-4dc0-8a83-e1d1f56828e8',
      '59594383-5bdf-4c95-9ce6-ab514a49fe4b',
      '8750a55d-de78-468e-8eb9-2320d8f18cdf',
      'a33c3759-39da-4bdd-9d94-8a2af83f3680',
      '6f02e8c3-8723-457d-8afc-fdc2325f35f7' //Indiana
    ],
    'amanager3@ibuyflowers.com': [
      'cb108c12-bcb3-4fee-8e06-2411201518ca',
      '6e1e3776-3230-4b4c-aa16-fcec21d8bf66',
      'f86e4a98-832a-4508-b791-5bfad755d2bc', //Georgia
      'd297b28a-c1cc-4881-908e-fb23ebc95a82',
      '55ca3d5d-9f58-4bca-a8d4-372c4e06efa6',
      'adf5ea2f-58dc-4714-a9a0-b7618e1a71c7',
      'cb89df45-065b-468e-b65a-4e6eee24674c',
      //  "50ab4930-a0f3-4aff-948e-cd2aa105cb69",
      '3a5ca1a6-223f-4f43-b417-ee203047019f',
      'e726031d-749d-4c1d-9b41-41666869f108',
      'b7237d8f-8e21-4ade-971d-fc396225b386', //Alaska
      '3b16e99f-3d4f-49b8-8095-13430f53dcc0' //Hawaii
      //   "a89bd316-7f0d-40e4-a861-24416ea48fea", //Nevada
      //   "9f7e7c54-f310-4bbf-bf2e-c3a2858cd4ae", //Utah
    ],
    'amanager4@ibuyflowers.com': [
      '144bfa0f-90f2-4c03-85d4-404264b25f1a',
      '154c7141-9e71-45dd-8a3d-64961666016f',
      '723a45c5-b3b0-4a95-a3ed-0efb5347038e',
      '88eeb54d-874f-4569-9f64-06a880d609e2',
      'aad3367a-d23f-41fc-b7d9-8a34f6483f0c'
    ],
    'amanager5@ibuyflowers.com': [
      '0915681f-da29-40d2-841e-4dd7b2c9cbf0',
      '8bdea0ec-f631-44c4-88e6-b06fa71418c3',
      'ed1d3107-3a55-44e2-983f-0776d63ffb9a', //Utah
      'fe9987c7-c66e-456f-b703-015ec3b73e63',
      '6a267617-2e29-4049-bacb-b0bd0e4773c3',
      '983ac161-1ccf-420b-bcad-06566e40c278',
      '49ab361a-bbdc-4980-bb36-032b75773695',
      //  "24370a5e-3e15-4337-8c06-57e26b96f1b0",   IS FOR KEVIN 20210701 slack message
      '945734ec-f6b9-4bc1-bb77-0b7ea763c069'
    ]
  },
  129: {
    'sales1@ibuyflowers.com': [
      '154c7141-9e71-45dd-8a3d-64961666016f', //MT
      '945734ec-f6b9-4bc1-bb77-0b7ea763c069', //  CO COlorado
      '8bdea0ec-f631-44c4-88e6-b06fa71418c3', // IA Iowa
      '983ac161-1ccf-420b-bcad-06566e40c278', // SD  South Dakota
      '6a267617-2e29-4049-bacb-b0bd0e4773c3', // ND North Dakota
      '723a45c5-b3b0-4a95-a3ed-0efb5347038e', //WY
      '49ab361a-bbdc-4980-bb36-032b75773695' // NE Nebraska
    ],
    'sales2@ibuyflowers.com': [
      'ef085375-fb7a-409d-b8db-77a2f23b974f', // TN
      'a8e274a5-ae4e-41c5-947c-3a75aca9f466', // PA
      '658b74e1-23c1-456a-867b-ffcf3dd67a6b', // VA virginia
      '6219fd15-44a0-4b3d-a10a-b95223954ffb', //MD Maryland
      '80df38df-975f-4b85-a774-bf2ed38cbaf6', // DE Delaware
      '4601da67-6b61-4ab3-bb26-cb2fd95e65b1', //DC Distirc of columbia
      '59594383-5bdf-4c95-9ce6-ab514a49fe4b' //NJ,
    ],
    'sales3@ibuyflowers.com': [
      '3a5ca1a6-223f-4f43-b417-ee203047019f' // TX
    ],
    'sales4@ibuyflowers.com': [],
    'sales5@ibuyflowers.com': [
      'd297b28a-c1cc-4881-908e-fb23ebc95a82', // KS Kansas
      'cb89df45-065b-468e-b65a-4e6eee24674c', // OK Oklahoma
      'f1a0ddd9-9430-4f5e-96fe-d61a36a35af6', // MO Misouri
      '50ab4930-a0f3-4aff-948e-cd2aa105cb69', //AZ Arizona
      'e726031d-749d-4c1d-9b41-41666869f108', // NM Nuevo Mexico
      '9f7e7c54-f310-4bbf-bf2e-c3a2858cd4ae' //UT Utah
    ],
    'sales6@ibuyflowers.com': [
      'ef085375-fb7a-409d-b8db-77a2f23b974f', // TN
      '7f684019-1751-447c-810c-646191e988cc', // KY Kentucky,
      '6f02e8c3-8723-457d-8afc-fdc2325f35f7' // IN Indiana
    ],
    'sales7@ibuyflowers.com': [
      '50ab4930-a0f3-4aff-948e-cd2aa105cb69', //AZ Arizona
      'e726031d-749d-4c1d-9b41-41666869f108', // NM Nuevo Mexico
      '55ca3d5d-9f58-4bca-a8d4-372c4e06efa6', //LA Louisiana,
      'a89bd316-7f0d-40e4-a861-24416ea48fea' //NV Nevada
    ],
    'sales8@ibuyflowers.com': [
      '9282021f-144f-49c2-b69a-68dc0aa53b4a', //IL Illinous
      'fe9987c7-c66e-456f-b703-015ec3b73e63', //WI Wisconsin
      '0915681f-da29-40d2-841e-4dd7b2c9cbf0' //MN Minnesota
    ],
    'sales9@ibuyflowers.com': [
      'd06fd026-4f05-45c0-b819-9d19feda9467', // ME Maine
      'f90a5957-9e9b-4be3-94a1-89f963c8275d', // CT Connecticut
      '027610e6-2f68-4251-bc24-c0599f12f361', // VT Vermont,
      'c567b291-2b3b-4c37-b6fe-907e29c6bf3c', // MA Massachusetts
      'afd46c18-cab7-4dc0-8a83-e1d1f56828e8', // NH New Hampshire
      '8e415478-faff-4651-b024-e7f2de2f0bac', // RI Rhode Island
      '8750a55d-de78-468e-8eb9-2320d8f18cdf' // NY
    ],
    'salesmanager@ibuyflowers.com': [],
    'sales10@ibuyflowers.com': [],
    'sales11@ibuyflowers.com': [
      'b7237d8f-8e21-4ade-971d-fc396225b386', //AK Alaska
      '144bfa0f-90f2-4c03-85d4-404264b25f1a', //OR Oregon
      'aad3367a-d23f-41fc-b7d9-8a34f6483f0c', //WA Washinton
      '3b16e99f-3d4f-49b8-8095-13430f53dcc0', // HI Hawaii
      '88eeb54d-874f-4569-9f64-06a880d609e2', //ID

      '24370a5e-3e15-4337-8c06-57e26b96f1b0' //CA
    ],
    'sales12@ibuyflowers.com': [],
    'sales13@ibuyflowers.com': [],
    'sales14@ibuyflowers.com': [
      '2a39b230-a9b6-4e58-900e-ddbc2920f42b', // MS
      '6e1e3776-3230-4b4c-aa16-fcec21d8bf66', // AR,
      'cb108c12-bcb3-4fee-8e06-2411201518ca' //AL Alabama
    ],
    'sales15@ibuyflowers.com': [
      'a33c3759-39da-4bdd-9d94-8a2af83f3680', // OH
      'f7338180-d413-4fa0-98c6-609cd5d1ce37', //WV
      'd4e863d2-03d6-4e29-93a0-61cc9e09ba1e', // SC South Carolina
      '75a674ef-9a36-4209-b941-7ec66a673c26', // MI Michigan
      'adf5ea2f-58dc-4714-a9a0-b7618e1a71c7', // NC North Carolina
      'e810ef2a-b9cb-409f-80fb-e9682986c885' //FL Florida
    ],
    'sales16@ibuyflowers.com': []
  }
};

export const showDaysOfWeek = (days: Array<{ [key: string]: number }>) => {
  let result: { [key: string]: number } = {};
  if (days.length) {
    result = days.reduce((acc, day) => {
      Object.keys(day).forEach((key: string) => {
        if (day[key] !== 0) {
          const date = moment(key, 'YYYYMMDD');
          const formattedDate: string = date.format('dd');
          acc[formattedDate] = day[key];
        }
      });
      return acc;
    }, {});
  }
  return result;
};

export const getErrorTypeSearchProduct = (error?: string) => {
  if (error?.includes('401')) {
    return ErrorTypeRequest.AuthenticationRequired;
  } else if (error?.includes('Network Error')) {
    return ErrorTypeRequest.NetworkError;
  } else if (error?.includes('The request was cancelled')) {
    return ErrorTypeRequest.RequestCancelled;
  }

  return null;
};

export const sendSbxEvent = ({
  props,
  name
}: {
  props: { [key: string]: any };
  name: string;
}) => {
  TrackEventMonitor.newEvent({
    props,
    metadata: { name }
  });
};

export const findDuplicateVarietyKeys = (
  items: Array<VarietySuggestion>
): Array<string> => {
  if (!Array.isArray(items) || items.length === 0) {
    return [];
  }

  const duplicateKeys: Array<string> = [];
  const checkedKeys: { [key: string]: boolean } = {};

  items.forEach((item: VarietySuggestion) => {
    if (checkedKeys[item.variety._KEY]) {
      duplicateKeys.push(item.variety._KEY);
    } else {
      checkedKeys[item.variety._KEY] = true;
    }
  });

  return duplicateKeys;
};

export const formatCurrency = (
  amount: number,
  currencyCode: string = 'USD'
) => {
  if (typeof amount !== 'number') {
    return null;
  }

  const formatter = new Intl.NumberFormat('es-US', {
    style: 'currency',
    currency: currencyCode
  });
  return formatter.format(amount);
};

export const validateCookies = () => {
  const token_cookie = getCookie('token');
  const user_key_cookie = getCookie('user_key');

  const token_storage = localStorage.getItem('token');
  const user_key_storage = localStorage.getItem('user_key');
  if (token_cookie && (!token_storage || token_storage !== token_cookie)) {
    localStorage.setItem('token', token_cookie);
    if (
      user_key_cookie &&
      (!user_key_storage || user_key_storage !== user_key_cookie)
    ) {
      localStorage.setItem('user_key', user_key_cookie);
    } else {
      if (user_key_storage && !user_key_cookie) {
        localStorage.removeItem('user_key');
      }
    }
    window.location.reload();
  } else {
    // if (user_key_storage && !user_key_cookie) {
    //   localStorage.removeItem('user_key');
    //   window.location.reload();
    // }
  }
};
