import { faFlag, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ListItemD } from './ListItemD';

const arrayItems = [{
    title: 'AFR (Afrodita, CO)',
    id: 'AFR-A-CO'
  },
  {
    title: 'ALE (Alexandra, CO)',
    id: 'ALE-A-CO'
  },
  {
    title: 'ALE-DA (Alexandra, CO)',
    id: 'ALEDA-A-CO'
  },
  {
    title: 'ALE-WB (Alexandra, CO)',
    id: 'ALEWB-A-CO'
  },
];

export const FarmFilterComponentD = () => {
  return (
    <div className="contFiltersD">
      <div className="inputFiltersD">
        <FontAwesomeIcon icon={faSearch} color="#00000040" />
        <input type="text" placeholder={'Farm'} />
      </div>

      <div className="ListFiltersD" style={{ maxHeight: 250 }}>
        <ul>
          <li style={{ marginBottom: '5px' }}>
            <input type="checkbox" id="flagc" />
            <FontAwesomeIcon icon={faFlag} />
            <label htmlFor="flagc"> Colombia</label>
          </li>

          <ul>
            {arrayItems.map((item) => (
              <ListItemD key={item.id} item={item.title} id={`sbx-${item.id}`} />
            ))}
          </ul>
        </ul>
      </div>
    </div>
  );
};
