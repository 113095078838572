import React, { ReactElement, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Environment from '../../../../Environment';
import Product from '../../../../Models/Product';
import {
  convertNumberDateMMMDD,
  convertNumberDateToDate,
  isLessThan15Days,
  scrollToElement
} from '../../../../Utils/Utils';
import ImageComponent from '../../../Shared/ImageComponent/ImageComponent';
import DeliveryDateModal from '../../../Shared/Modal/DeliveryDateModal/DeliveryDateModal';
import ProductDescriptionComponent from './ProductDescriptionComponent';
import FavoriteComponent from '../../ProductFilterComponent/FavoriteComponent';
import { RootState } from '../../../../Store/Reducers';
import { State } from '../../../../Models/State';

type Props = {
  product: Product;
  form: JSX.Element;
  replaces?: Product[];
  favorite: boolean;
};

const ProductDetailForm: React.FC<Props> = ({
  product,
  form,
  replaces,
  favorite
}) => {
  const [showTip, setShowTip] = useState<boolean>(true);
  const [openDeliveryDateModal, setOpenDeliveryDateModal] = useState('');
  const { date, state } = useSelector(
    (state: RootState) => state.ProductReducer
  );
  const { manager } = useSelector((state: RootState) => state.AuthReducer);
  const getMessage = useCallback(() => {
    const isLess15Days = isLessThan15Days(convertNumberDateToDate(date));

    const alert = (label: ReactElement, showOk = true) => (
      <div
        className={showTip ? 'd-flex align-items-center p-2 mt-2' : 'd-none'}
        style={{ backgroundColor: '#FFFFCE' }}>
        <div className="mx-2">
          <ImageComponent
            imageUrl={`${Environment.publicPath}images/${
              manager?.email?.split('@')[0]
            }.png`}
            imgSize={'30px'}
            rounded={true}
            className="pointer"
          />
        </div>
        <div className="px-2">
          <span>
            {label}
            {'  '}
            {showOk && (
              <u className="pointer" onClick={() => setShowTip(false)}>
                Ok
              </u>
            )}
          </span>
        </div>
      </div>
    );

    if (product.pricing) {
      return (
        <>
          <h4 className="font-weight-bold p-2">Add to your cart:</h4>
          {form}
        </>
      );
    } else if (product.isAvailable && !product.pricing && isLess15Days) {
      return (
        <>
          {form} {alert(<i>Price at checkout (delivery -/-2 to +3 days)</i>)}
        </>
      );
    } else if (!product.isAvailable) {
      return alert(
        <>
          <b className="text-danger">
            Not available in the week of {convertNumberDateMMMDD(date)}
          </b>
          <br />
          {replaces?.length ? (
            <u
              onClick={() => scrollToElement('substitutes')}
              className="pointer">
              See the substitutes
            </u>
          ) : (
            ''
          )}
          {replaces?.length ? ' or ' : ''}
          <u
            className="pointer"
            onClick={() => setOpenDeliveryDateModal('changeDate')}>
            Select another delivery date.
          </u>
        </>,
        false
      );
    } else if (product.isAvailable && !product.pricing && !isLess15Days) {
      return alert(
        <span className="text-success">
          Product is available for ${convertNumberDateMMMDD(date)}, but pricing
          and ordering only 2 weeks before
        </span>
      );
    }

    return <></>;
  }, [date, product, replaces, showTip, manager, form]);
  return (
    <div className="ibf-product-detail-text">
      {getMessage()}
      {state === State.REJECTED && (
        <span className="color-red">
          This product is not available for this date.
        </span>
      )}
      {product?.product_group && (
        <ProductDescriptionComponent
          description={product?.product_group?.description}
          warning={product?.product_group?.warning}
        />
      )}

      <FavoriteComponent favorite={favorite} isDetailForm product={product} />
      <DeliveryDateModal
        openDeliveryDateModal={!!openDeliveryDateModal}
        toggleDeliveryDateModal={() => setOpenDeliveryDateModal('')}
        className="ibf-delivery-date-modal"
      />
    </div>
  );
};

export default ProductDetailForm;
