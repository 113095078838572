import React, { useEffect, useState } from 'react';
import { actionsStatements } from '../../Store/Statements/Slice';
import { actionsCustomer } from '../../Store/Customer/Slice';
import { useDispatch, useSelector } from 'react-redux';
import { statementsSelector, authReducer } from '../../Store/Selectors';
import DisplayRouteComponent from '../Shared/DisplayRouteComponent';
import InputSelectedStatements from './InputSelectedStatements';
import ItemStatements from './ItemStatements';
import LoadingSpinner from '../Shared/LoadingSpinner';
import moment from 'moment';

const StatementsComponent = () => {
  const dispatch = useDispatch();
  const {
    selectedValue,
    statementLoaded,
    statements,
    statementsSince
  } = useSelector(statementsSelector);
  const { user } = useSelector(authReducer);

  const [currentEmail, setCurrentEmail] = useState(
    user.metadata.customer_statement_email_weekly
  );

  useEffect(() => {
    dispatch(actionsStatements.getStatements(user.metadata.customer));
  }, [dispatch, user.metadata.customer]);

  const handleOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actionsStatements.setSelectedValue(event.target.value));
  };

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.checked;
    setCurrentEmail(value);
    dispatch(
      actionsCustomer.setCurrentEmailWeekly({
        _KEY: user.metadata.customer,
        statement_email_weekly: value
      })
    );
  };

  return (
    <div className="ibf-orders-container mt-3">
      <DisplayRouteComponent currentRouteName={'Statements'} />

      <div className="d-flex flex-column mb-3 mt-4 mt-md-2">
        <span className="mb-2">Show by:</span>
        <div className="d-flex mb-2">
          <InputSelectedStatements
            text={'Week'}
            id="week"
            htmlFor="week"
            className="custom-control-input"
            classNameLabel="custom-control-label"
            type="radio"
            onChange={handleOnchange}
            checked={selectedValue === 'week'}
            value="week"
            name="statements"
          />

          <InputSelectedStatements
            text={'Month'}
            className="custom-control-input"
            classNameLabel="custom-control-label"
            id="month"
            htmlFor="month"
            type="radio"
            onChange={handleOnchange}
            checked={selectedValue === 'month'}
            value="month"
          />
          <InputSelectedStatements
            text={'Year'}
            className="custom-control-input"
            classNameLabel="custom-control-label"
            id="year"
            htmlFor="year"
            type="radio"
            onChange={handleOnchange}
            checked={selectedValue === 'year'}
            value="year"
          />

          <InputSelectedStatements
            text={'Event name'}
            className="custom-control-input"
            classNameLabel="custom-control-label text-nowrap"
            type="radio"
            id="event"
            htmlFor="event"
            onChange={handleOnchange}
            checked={selectedValue === 'event'}
            value="event"
          />
        </div>
      </div>
      {selectedValue === 'week' && (
        <div className="bg-ibf-cream p-0 p-lg-1 d-inline-flex">
          <>
            <div className="custom-control custom-radio mr-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="currentEmail"
                defaultChecked={currentEmail}
                onChange={handleChecked}
              />
              <label className="form-check-label" htmlFor="currentEmail">
                Receive every Sunday the statement of last week (you won't
                receive an email when there are no credit card captures that
                week)
              </label>
            </div>
          </>
        </div>
      )}

      <div className="d-flex flex-column mt-3 pb-5">
        {statements.length !== 0 && (
          <span className="font-weight-bold">
            Statements since
            {` ${moment(statementsSince, 'YYYYMMDD').format('YYYY-MM-DD')}`}:
          </span>
        )}

        {statementLoaded && (
          <div className="d-flex justify-content-start my-2">
            <LoadingSpinner message={'LOADING'} />
          </div>
        )}

        <ul className="ibf-order-detail-items p-0">
          {statements.length !== 0 && <ItemStatements />}
        </ul>
      </div>
    </div>
  );
};

export default StatementsComponent;
