import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import {
  cellphoneOptions,
  checkUndefinedObject,
  getKeysUserConfig,
  toast
} from '../../../../Utils/Utils';
import { saveAddUser, updateUser } from '../../../../Services/UserService';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_USER_MODAL, CELLPHONE_MODAL } from '../../../../Store/Modal/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { RootState } from '../../../../Store/Reducers';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { State } from '../../../../Models/State';

type Props = {
  className?: string;
};

const EditUserModal: React.FC<Props> = ({ className }) => {
  const { addUserModal } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const { user: reduxUser, token } = useSelector(
    (state: RootState) => state.AuthReducer
  );
  const user = addUserModal.data.user;
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [cellphone, setCellphone] = useState<string>('');
  const [skype, setSkype] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailConfirm, setEmailConfirm] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [checkbox, setCheckbox] = useState<Array<any>>([]);
  const [updateSelected, setUpdateSelected] = useState<Array<any>>([]);
  const [state, setState] = useState<string>('IDLE');
  const [smsConfig, setSmsConfig] = useState({
    ...user.smsConfig,
    _META: undefined
  });

  const dispatch = useDispatch();

  const customer = { _KEY: reduxUser.metadata.customer };
  const getUsers = () => addUserModal.data.getUsers;
  const type = addUserModal.data.type;
  let titleModal;
  if (type === 'add') {
    titleModal = 'Add User\n';
  } else {
    titleModal = 'Edit User\n';
  }
  useEffect(() => {
    setFirstName(user.first_name || '');
    setLastName(user.last_name || '');
    setPhone(user.phone || '');
    setCellphone(user.cellphone || '');
    setSkype(user.skype || '');
    setEmail(user.user_email || '');
    setEmailConfirm('');
    setCompanyName('');
    setPassword('');
    setPasswordConfirm('');

    const userCheckBox = checkUndefinedObject(user)
      ? [
          ['accounting', false],
          ['auto_buy', false],
          ['claims', false],
          ['ibuyflowers', false],
          ['purchase_orders', false],
          ['standing_orders', false]
        ]
      : getKeysUserConfig(user.config);
    setCheckbox(userCheckBox);
  }, [user]);

  useEffect(() => {
    const arr = checkbox.reduce((obj: any, checkbox: string) => {
      obj.push({ [checkbox[0]]: checkbox[1] });
      return obj;
    }, []);

    setUpdateSelected(arr);
  }, [checkbox]);

  const toggle = () => {
    dispatch(actionsModal.setCloseModal({ modal: ADD_USER_MODAL }));
  };

  const addUser = async () => {
    if (
      email.length === 0 ||
      emailConfirm.length === 0 ||
      passwordConfirm.length === 0 ||
      password.length === 0 ||
      phone.length === 0 ||
      lastName.length === 0
    ) {
      toast('Check empty fields to create a user.', 'error');
    } else {
      const validatePermission = updateSelected.some((item) =>
        Object.values(item).some((value) => value === true)
      );
      if (!validatePermission) {
        toast('Check at least one permission.', 'error');
      } else {
        if (email.toLowerCase() !== emailConfirm.toLowerCase()) {
          toast('Email does not match.', 'error');
        } else if (password.toLowerCase() !== passwordConfirm.toLowerCase()) {
          toast('Password does not match.', 'error');
        } else {
          const regexp = /[a-zA-Z]*[-_.][a-zA-Z0-9]*[a-zA-Z0-9]$/;
          if (regexp.test(email)) {
            setState(State.PENDING);
            const newUser = {
              first_name: firstName,
              last_name: lastName,
              user_email: email,
              cellphone: phone,
              password: password
            };

            const user_preferences = {
              accounting: updateSelected.some(
                (item: any) => item['accounting']
              ),
              auto_buy: updateSelected.some((item: any) => item['auto_buy']),
              claims: updateSelected.some((item: any) => item['claims']),
              ibuyflowers: updateSelected.some(
                (item: any) => item['ibuyflowers']
              ),
              purchase_orders: updateSelected.some(
                (item: any) => item['purchase_orders']
              ),
              standing_orders: updateSelected.some(
                (item: any) => item['standing_orders']
              )
            };
            const res = await saveAddUser(
              customer,
              false,
              'FLORIST',
              newUser,
              user_preferences
            );
            if (res && res.success) {
              if (cellphone || phone || skype) {
                const userUpdated = {
                  row: {
                    _KEY: res.response.user._KEY,
                    cellphone: cellphone,
                    phone: phone,
                    skype: skype
                  }
                };

                const resUpdated = await updateUser(userUpdated);

                if (resUpdated.success) {
                  toast('User created');
                  getUsers();
                  setState(State.RESOLVED);
                  if (toggle) {
                    toggle();
                  }
                } else {
                  toast('An error occurred, please contact support.', 'error');
                  setState(State.REJECTED);
                }
              } else {
                setState(State.RESOLVED);

                getUsers();
                if (toggle) {
                  toggle();
                }
                toast('User created');
              }
            } else {
              setState(State.REJECTED);

              toast(res.error, 'error');
            }
          } else {
            setState(State.REJECTED);
            toast(
              'Invalid user, please use letters, numbers, hyphen (-), period (.) And underscore (_).',
              'error'
            );
          }
        }
      }
    }
  };

  const updateUserFunction = async () => {
    if ((password && !passwordConfirm) || (!password && passwordConfirm)) {
      toast(
        'Please entry the password and confirm password to update.',
        'error'
      );
      return;
    }

    if (password.toLowerCase() !== passwordConfirm.toLowerCase()) {
      toast('The password and confirm password should be the same.', 'error');
      return;
    }

    if (password && passwordConfirm && !currentPassword) {
      toast(
        'Please entry the current password to update the user info.',
        'error'
      );
      return;
    }
    const userUpdated = await updateUser({
      smsConfig,
      row: {
        first_name: firstName,
        last_name: lastName,
        user_email: email,
        cellphone: cellphone,
        phone: phone,
        skype: skype,
        _KEY: user._KEY
      },
      user: {
        password,
        token,
        user_email: email,
        current_password: currentPassword
      },
      customerUserConfig: {
        accounting: updateSelected.some((item: any) => item['accounting']),
        auto_buy: updateSelected.some((item: any) => item['auto_buy']),
        claims: updateSelected.some((item: any) => item['claims']),
        ibuyflowers: updateSelected.some((item: any) => item['ibuyflowers']),
        purchase_orders: updateSelected.some(
          (item: any) => item['purchase_orders']
        ),
        standing_orders: updateSelected.some(
          (item: any) => item['standing_orders']
        ),
        user: user._KEY,
        _KEY: user.config._KEY
      }
    });

    if (userUpdated.success) {
      toast('User updated');
      getUsers();
      if (toggle) {
        toggle();
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    let update = updateSelected.map((obj: any) => {
      return Object.entries(obj).reduce((arr: any, [key, value]) => {
        if (name === key) {
          arr[key] = e.target.checked;
        } else {
          arr[key] = value;
        }
        return arr;
      }, {});
    });

    setUpdateSelected(update);
  };

  return (
    <div>
      <Modal
        isOpen={addUserModal.open}
        toggle={toggle}
        className={`${className} modal-lg `}>
        <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
        <div className="modal-body modal-size">
          <div className="card mb-3">
            <div className="card-header">User Info</div>
            <div className="card-body">
              <div className="form-row mb-2">
                <div className="col-12 col-sm-4">
                  <label htmlFor="first_name">
                    {' '}
                    First Name:<sup className="text-danger">*</sup>
                  </label>
                  <input
                    className="form-control input-sm"
                    id="first_name"
                    name="first_name"
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFirstName(e.target.value)
                    }
                    value={firstName || ''}
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <label htmlFor="last_name">
                    {' '}
                    Last Name:<sup className="text-danger">*</sup>
                  </label>
                  <input
                    className="form-control input-sm"
                    id="last_name"
                    name="last_name"
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setLastName(e.target.value)
                    }
                    value={lastName || ''}
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <label htmlFor="phone">
                    {' '}
                    Phone:<sup className="text-danger">*</sup>
                  </label>
                  <input
                    className="form-control input-sm"
                    id="phone"
                    name="phone"
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPhone(e.target.value)
                    }
                    value={phone || ''}
                  />
                </div>
              </div>
              <div className="form-row mb-2">
                <div className="col-12 col-sm-4">
                  <label htmlFor="skype">Skype:</label>
                  <input
                    className="form-control input-sm"
                    id="skype"
                    name="skype"
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSkype(e.target.value)
                    }
                    value={skype || ''}
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <label htmlFor="company">Company:</label>
                  <input
                    className="form-control input-sm"
                    id="company"
                    name="company_name"
                    readOnly={true}
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setCompanyName(e.target.value)
                    }
                    value={companyName || ''}
                  />
                </div>
              </div>

              <div className="form-row mb-2">
                <div className="col-12 col-sm-4">
                  <label htmlFor="email">
                    {' '}
                    Email:<sup className="text-danger">*</sup>
                  </label>
                  <input
                    className="form-control input-sm"
                    id="email"
                    name="contact_email"
                    type="email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmail(e.target.value)
                    }
                    value={email || ''}
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <label htmlFor="confirm_email">
                    {' '}
                    Confirm Email:<sup className="text-danger">*</sup>
                  </label>
                  <input
                    className="form-control input-sm"
                    id="confirm_email"
                    name="confirm_contact_email"
                    type="email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmailConfirm(e.target.value)
                    }
                    value={emailConfirm || ''}
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <label htmlFor="username">Username:</label>
                  <input
                    className="form-control input-sm"
                    id="username"
                    name="username"
                    readOnly={true}
                    type="text"
                    value={email || ''}
                  />
                </div>
              </div>

              <div className="form-row mb-2">
                {type === 'edit' && (
                  <div className="col-12 col-sm-4">
                    <label htmlFor="current_password">
                      {' '}
                      Current Password:<sup className="text-danger">*</sup>
                    </label>
                    <input
                      className="form-control input-sm"
                      id="current_password"
                      name="current_password"
                      type="password"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCurrentPassword(e.target.value)
                      }
                      value={currentPassword || ''}
                    />
                  </div>
                )}
                <div className="col-12 col-sm-4">
                  <label htmlFor="password">
                    {' '}
                    Password:<sup className="text-danger">*</sup>
                  </label>
                  <input
                    className="form-control input-sm"
                    id="password"
                    name="password"
                    type="password"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                    value={password || ''}
                  />
                </div>
                <div className="col-12 col-sm-4">
                  <label htmlFor="confirm_password">
                    {' '}
                    Confirm Password:<sup className="text-danger">*</sup>
                  </label>
                  <input
                    className="form-control input-sm"
                    id="confirm_password"
                    name="confirm_password"
                    type="password"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPasswordConfirm(e.target.value)
                    }
                    value={passwordConfirm || ''}
                  />
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="text-danger">
                <sup>*</sup> Field Required.
              </div>
            </div>
          </div>
          {user.is_admin && (
            <div className="card">
              <div className="card-header">Cellphone and Text messages</div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-sm-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <label htmlFor="cellphone">Cellphone:</label>
                      <span
                        className="font-weight-bold pointer underline ibf-font-14 mb-2"
                        onClick={() =>
                          dispatch(
                            actionsModal.setOpenModal({
                              modal: CELLPHONE_MODAL
                            })
                          )
                        }>
                        Edit cell number
                      </span>
                    </div>

                    <input
                      className="form-control input-sm"
                      id="cellphone"
                      name="cellphone"
                      type="tel"
                      disabled
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCellphone(e.target.value)
                      }
                      value={cellphone || ''}
                    />
                  </div>
                  <div className="col-12 col-sm-8 mt-2 mt-lg-0">
                    <span>Receive Text messages on your cellphone: </span>
                    {Object.keys(cellphoneOptions).map((key: string, index) => {
                      return (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name={key || ''}
                            value={key || ''}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setSmsConfig((old: any) => ({
                                ...old,
                                [cellphoneOptions[key].value]: !old[
                                  cellphoneOptions[key].value
                                ]
                              }));
                            }}
                            checked={smsConfig[cellphoneOptions[key].value]}
                            id={key}
                          />
                          <label className="form-check-label" htmlFor={key}>
                            {cellphoneOptions[key].label}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="card">
            <div className="card-header">
              User Permissions<sup className="text-danger">*</sup>
            </div>
            <div className="card-body">
              <div className="row">
                {updateSelected.length > 0
                  ? updateSelected.map((config: any, index) => {
                      return Object.entries(config).map(([key, value]) => (
                        <div className="form-check col-6 col-sm-4" key={index}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name={key || ''}
                            value={key || ''}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleChange(e)}
                            id={key}
                            checked={!!value}
                          />
                          <label className="form-check-label" htmlFor={key}>
                            {key}
                          </label>
                        </div>
                      ));
                    })
                  : ''}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-purple"
              onClick={type === 'edit' ? updateUserFunction : addUser}>
              {state === 'PENDING' ? (
                <FontAwesomeIcon icon={faSpinner} pulse />
              ) : (
                'Continue'
              )}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditUserModal;
