import { RootState } from './Reducers';

export const customerKey = (state: RootState) =>
  state.AuthReducer.user.metadata.customer;

export const modalReducer = (state: RootState) => state.ModalReducer;
export const authReducer = (state: RootState) => state.AuthReducer;
export const customerReducer = (state: RootState) => state.CustomerReducer;
export const eventReducer = (state: RootState) => state.EventReducer;
export const customBoxReducer = (state: RootState) => state.CustomBoxReducer;
export const cartReducer = (state: RootState) => state.CartReducer;
export const checkoutReducer = (state: RootState) => state.CheckoutReducer;
export const productReducer = (state: RootState) => state.ProductReducer;
export const loadingReducer = (state: RootState) => state.LoadingReducer;
export const orderReducer = (state: RootState) => state.OrderReducer;
export const shoppingListReducer = (state: RootState) =>
  state.ShoppingListReducer;
export const dashboardReducer = (state: RootState) => state.DashboardReducer;
export const statementsSelector = (state: RootState) => state.StatementsReducer;
export const displayReducer = (state: RootState) => state.DisplayReducer;
export const filterReducer = (state: RootState) => state.FilterReducer;
export const helperReducer = (state: RootState) => state.HelperReducer;
export const calendarReducer = (state: RootState) => state.CalendarReducer;
export const standingOrderReducer = (state: RootState) =>
  state.StandingOrderReducer;
