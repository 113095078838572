import { OrderRequest } from '../../Models/OrderRequest';
import { State } from '../../Models/State';
import { Cartbox } from '../../Models/Cartbox';
import { EventCartbox } from '../../Models/EventCartbox';
import { IncompleteProduct } from '../../Models/Product';

export const CHECKOUT_PRODUCTS = 'checkout/checkoutProducts';
export const GET_INFO_BY_CUSTOMER = 'checkout/getCheckoutInfoByCustomer';
export const REMOVE_FROM_CUSTOM_BOX = 'checkout/removeFromCustomBox';
export const REMOVE_FROM_BOX = 'checkout/removeFromBox';
export const CHECK_SHOPPING_LIST = 'checkout/checkShoppingList';

export interface CheckoutState {
  checkoutInfo: any;
  orderRequests: OrderRequest[];
  products: Cartbox[];
  incompleteProducts: IncompleteProduct[];
  itemsInCart: number;
  chargeDatesCheckout: {
    charge_date: number;
    closing_time: string;
    komet_time?: string;
  }[];
  deliveryDatesCheckout: { eta_date: number; closing_time: string }[];
  state: State;
  productsByEventName: ProductsByEventName;
}

export interface ProductsByEventName {
  [key: string]: EventInfo;
}

export interface EventInfo {
  event: EventCartbox | null;
  dates: { [date: string]: Cartbox[] };
  index_order: number;
}
