import { SbxCoreService, SbxSessionService } from 'sbx-axios';
import Environment from '../Environment';
import { initialize } from 'sbx-react-auth-hoc';
import * as Sentry from '@sentry/react';
const sbxCoreService = new SbxCoreService();
const sbxSessionService = new SbxSessionService(sbxCoreService);

sbxSessionService.initialize(
  Environment.domain,
  Environment.appKey,
  Environment.baseUrl
);

sbxCoreService.httpClient.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
  }
);
sbxCoreService.httpClient.interceptors.response.use(
  function(response: any) {
    // Do something with response data
    // if (response?.cloud_script_run?.error_log.length > 0) {
    //   Sentry.captureMessage('CS Execution Error', {
    //     extra: {
    //       error_log: response.cloud_script_run.error_log[0],
    //       params: response?.cloud_script_run?.params,
    //       test: response?.cloud_script_run?.test
    //     }
    //   });
    // }
    return response;
  },
  function(error) {
    // Do something with response error
    Sentry.captureException(error);
  }
);

const runCS = function(key: string, params: any) {
  return sbxCoreService.run(key, params, Environment.testMode);
};

initialize(
  sbxCoreService,
  sbxSessionService,
  Environment.cloudscripts.validate,
  true
);

export { sbxSessionService, sbxCoreService, runCS };
