import React from 'react';
import { redirect } from '../../../Utils/Utils';

const FooterComponent = () => {
  return (
    <>
      <hr className="mb-0" />
      <div className="ibf-footer-container">
        <div className="ibf-footer-redirection-place">
          <div className="ibf-footer-element-container">
            <span
              onClick={() => redirect('ABOUT_US')}
              className="ibf-footer-element">
              About us
            </span>
            <span
              onClick={() => redirect('GROWERS')}
              className="ibf-footer-element">
              Growers
            </span>
            <span
              onClick={() => redirect('BLOG')}
              className="ibf-footer-element">
              Blog
            </span>
          </div>

          <div className="ibf-footer-element-container">
            <span
              onClick={() => redirect('REVIEWS')}
              className="ibf-footer-element">
              Reviews
            </span>
            <span
              onClick={() => redirect('PRIVACY_POLICY')}
              className="ibf-footer-element">
              Privacy Policy
            </span>
            <span
              onClick={() => redirect('TERMS_CONDITIONS')}
              className="ibf-footer-element">
              Terms and conditions
            </span>
          </div>
        </div>
        <span>
          {' '}
          ©iBuyFlowers {new Date().getFullYear()}, All rights reserved{' '}
        </span>
      </div>
    </>
  );
};

export default FooterComponent;
