import React, { useState, useEffect } from 'react';
import { statementsSelector } from '../../Store/Selectors';
import { useSelector, useDispatch } from 'react-redux';
import { filterStatements } from '../../Utils/Utils';
import { OrderItem } from '../../Models/Order';
import {
  toast,
  loopArrayStatements,
  uuidV4,
  filteredArrayStatements
} from '../../Utils/Utils';
import moment from 'moment';
import { actionsStatements } from '../../Store/Statements/Slice';
import ExcelDataStatements from './ExcelDataStatements';
const ItemStatements = () => {
  const { statements, selectedValue } = useSelector(statementsSelector);

  const [filterStatement, setFilterStatement] = useState<
    Array<{ type: string; key: string; data: Array<OrderItem> }>
  >([]);
  const dispatch = useDispatch();
  useEffect(() => {
    let result: { [key: string]: Array<OrderItem> } = filterStatements(
      loopArrayStatements(statements),
      selectedValue
    );

    let mostOldestDate = moment();

    for (const key in result) {
      const element = result[key];
      for (let item of element) {
        let date = moment(item?.eta_date, 'YYYYMMDD');
        if (date < mostOldestDate) {
          mostOldestDate = date;
        }
      }
    }

    dispatch(actionsStatements.showStatementsSince(mostOldestDate));
    if (Object.keys(result).length === 0 && selectedValue === 'event') {
      toast('There is no event name in the statements', 'info');
    }
    const filteredData = filteredArrayStatements(result, selectedValue);
    setFilterStatement(filteredData);
  }, [selectedValue, statements, dispatch]);

  return (
    <>
      {filterStatement.length > 0 &&
        filterStatement.map((item) => {
          let type = ' of';
          if (item.type === 'week') {
            type = ' of week';
          }
          let li = (
            <li className="underline pointer" key={uuidV4()}>
              Statement
              {type} {item.key}: {item.data.filter((f) => !f?.parent).length}{' '}
              {` ${
                item.data.filter((f) => !f?.parent).length > 1
                  ? 'captures'
                  : 'capture'
              }`}
            </li>
          );
          let fileName = `Statement ${type} ${item.key}`;

          return (
            <ExcelDataStatements
              element={li}
              data={item.data}
              key={uuidV4()}
              fileName={fileName}
            />
          );
        })}
    </>
  );
};

export default ItemStatements;
