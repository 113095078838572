import React, { useState } from 'react';
import ImageComponent from '../../ImageComponent/ImageComponent';
import { Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Environment from '../../../../Environment';
import { actionsCustomer } from '../../../../Store/Customer/Slice';
import { RootState } from '../../../../Store/Reducers';
import { actionsDisplay } from '../../../../Store/Display/Slice';

const SearchTipPopover = () => {
  const dispatch = useDispatch();
  const {
    manager,
    user: {
      metadata: { preferences: userPreferences, customer }
    }
  } = useSelector((state: RootState) => state.AuthReducer);

  const [checked, setChecked] = useState(false);

  const closeTip = async () => {
    dispatch(actionsDisplay.setOpenSecondTip(false));
    if (checked) {
      const preferences = { ...userPreferences, ...{ hideSearchTip: true } };
      dispatch(
        actionsCustomer.updateUserPreferences({ customer, preferences })
      );
    }
  };

  const handleChangeChecked = (e: any) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
  };

  return (
    <div className="ibf-search-popover-container">
      <div className="ibf-search-popover-image">
        <ImageComponent
          imageUrl={`${Environment.publicPath}images/${
            manager?.email?.split('@')[0]
          }.png`}
          imgSize="25px"
        />
      </div>

      <div className="ibf-search-popover-content">
        <div className="ibf-search-popover-title">
          <span>Next step: type a flower you need.</span>
        </div>

        <div className="ibf-search-popover-input">
          <div className="ibf-search-popover-text">
            <u className="pointer font-weight-bolder" onClick={closeTip}>
              Close
            </u>
          </div>

          <div className="ibf-search-popover-dont-show">
            <Input
              onChange={handleChangeChecked}
              className="mr-2"
              id="search_dont_show"
              type="checkbox"
            />
            <label htmlFor="search_dont_show">
              {' '}
              Don't show{' '}
              <span className="ibf-search-popover-hide-desktop">
                this message
              </span>{' '}
              again
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchTipPopover;
