import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  error: null,
  referrals: [],
  amount: 0
};

const slice = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    loadReferrals(state, { payload }) {
      state.amount = payload.amount;
      state.referrals = payload.referrals;
    }
  }
});

export const actionsReferral = slice.actions;
export default slice.reducer;
