import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addObjectParamsToPath } from '../../../Utils/Utils';
import { RootState } from '../../../Store/Reducers';
import MixpanelService from '../../../Services/MixpanelService';
type Props = {
  items: Array<string>;
  toggleMenu?: () => void;
  mobile?: boolean;
  closeMenuMobile?: () => void;
};

const FlowerListOptionsComponent: React.FC<Props> = ({
  items,
  toggleMenu,
  mobile,
  closeMenuMobile
}) => {
  const { date } = useSelector((state: RootState) => state.ProductReducer);
  const history = useHistory();

  const searchProduct = async (item: any) => {
    closeMenuMobile && closeMenuMobile();
    toggleMenu && toggleMenu();

    const filter = item.name.includes('All') ? 'category' : 'product_group';
    const filters = { [filter]: [item.key] };
    const params = { date, search_val: '', ...filters };
    MixpanelService.track('flowers_menu_item', { name: item.name });
    history.push(addObjectParamsToPath('/search', params));
  };

  return (
    <ul
      className={`d-flex flex-wrap list-group list-unstyled ${
        mobile ? 'pl-3' : 'pl-5'
      }`}>
      {items &&
        items.length > 0 &&
        items.map((item: any, index: number) => (
          <Fragment key={index + item}>
            <li
              className="d-flex align-items-center justify-content-between underline pointer list-item pl-2 pb-2 pt-1"
              key={item + item}
              onClick={() => searchProduct(item)}>
              <span className="mr-4">{item.name}</span>
            </li>
          </Fragment>
        ))}
    </ul>
  );
};

export default FlowerListOptionsComponent;
