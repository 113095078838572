import { runCS, sbxCoreService, sbxSessionService } from '../Network';
import Environment from '../Environment';
import ResponseCS from '../Models/ResponseCS/ResponseCS';
import { plainToClass } from 'class-transformer';
import AddProductCS from '../Models/ResponseCS/AddProductCS';
import CartProduct from '../Models/CartProduct';
import Axios, { CancelTokenSource } from 'axios';
import { AddToCartParams } from '../Models/AddToCartParams';
import SearchInventoryParams from '../Models/SearchInventory/SearchInventoryParams';
import Conditions from '../Models/SearchInventory/Conditions';
import { Cartbox } from '../Models/Cartbox';

// import cloneDeep from "lodash.clonedeep";

// const cache: any = {};

// export async function getProductsFromCache(
//   params: any,
//   where: string = ''
// ): Promise<ResponseCS<SearchCS>> {
//   params.filters = sortFilters(params.filters);
//   const key = JSON.stringify(params);
//   if (cache[key]) {
//     const res = cache[key];
//     res.response = plainToClass(SearchCS, res.response);
//     return Promise.resolve({ success: false, response: {} as SearchCS });
//   }
//   return Promise.resolve({ success: false, response: {} as SearchCS });
// }

// function sortFilters(filters: any) {
//   return Object.keys(filters).reduce((acc, key) => {
//     let val = acc[key];
//     if (val === 'true') {
//       val = true;
//     }
//     if (
//       !Array.isArray(val) &&
//       key !== 'week' &&
//       key !== 'desc' &&
//       key !== 'favorites' &&
//       key !== 'no_price' &&
//       key !== 'combo'
//     ) {
//       val = [val];
//     }
//     acc[key] = val.sort ? val.sort() : val;
//     return acc;
//   }, filters);
// }

const http = Axios.create({
  // baseURL: 'http://localhost:3000/api/v1',
  baseURL: Environment.searchProductUrl,
  headers: {
    authorization:
      'Bearer ' +
      (sbxSessionService.getCurrentUser().token ||
        localStorage.getItem('token'))
  }
});

// let currentRequest: any = null;
// let cancelPreviousRequest: (() => void) | null = null;

export async function searchInventory(
  params: SearchInventoryParams
): Promise<any> {
  let data = { ...params };

  // if (currentRequest !== null) {
  //   cancelPreviousRequest && cancelPreviousRequest();
  // }
  //
  // const cancelToken = Axios.CancelToken.source();
  // currentRequest = { cancel: cancelToken.cancel };
  // cancelPreviousRequest = cancelToken.cancel;

  try {
    const res = await http.post('/search', data, {
      // cancelToken: cancelToken.token
    });

    //console.log('res_search', res)

    return res.data;
  } catch (error) {
    // if (Axios.isCancel(error)) {
    //   throw new Error('The request was cancelled');
    // } else {
    //   throw new Error(error as string);
    // }
  }
}

export function getAllProductGroups() {
  return http.get('/get_all_product_groups').then((res) => {
    if (res.data?.success) {
      return res.data;
    }
  });
}

export function addProduct(
  params?: CartProduct | { products: Array<CartProduct> },
  customer?: string
): Promise<ResponseCS<AddProductCS>> {
  return runCS(Environment.cloudscripts.addProduct, {
    ...params,
    customer
  }).then((res: ResponseCS<AddProductCS>) => {
    if (res.success) {
      res.response = plainToClass(AddProductCS, res.response);
    }
    return res;
  });
}

export function listSLProduct(
  customer: string
): Promise<ResponseCS<Array<CartProduct>>> {
  return runCS(Environment.cloudscripts.listSLProduct, { customer }).then(
    (res: ResponseCS<Array<CartProduct>>) => {
      return res;
    }
  );
}

export function removeItemFromSL(key: string): any {
  return runCS(Environment.cloudscripts.removeItemFromSL, { key }).then(
    (res: any) => {
      if (res.success) {
        res.response = null;
      }
      return res;
    }
  );
}

export function getSchedule() {
  return runCS(Environment.cloudscripts.deliverySchedule, {}).then(
    (res: any) => {
      if (res.success) {
        res.response = null;
      }
      return res;
    }
  );
}

export function getPriceStandingOrder(params: any) {
  return http.post('/get_standing_order_saving_price', params).then((res) => {
    return res.data;
  });
}

export function getCategoryMap() {
  return runCS(Environment.cloudscripts.listCategories, {}).then((res: any) => {
    return res;
  });
}

export function getGrownInMonth(productGroup: string) {
  return sbxCoreService
    .with('grown_in_month')
    .andWhereIsEqualTo('product_group', productGroup)
    .find()
    .then((res) => {
      return res && res.success && res.results;
    });
}

export function getProductGroupInfo(productGroup: string) {
  return sbxCoreService
    .with('product_group')
    .whereWithKeys([productGroup])
    .find()
    .then((res) => {
      return res && res.success && res.results;
    });
}

export function updateQuantity(key: string, quantity: number) {
  return runCS(Environment.cloudscripts.updateQuantity, { key, quantity }).then(
    (res: any) => {
      return res;
    }
  );
}

export function getProductDetails(params: any): any {
  return runCS(Environment.cloudscripts.getProductDetails, params).then(
    (res: any) => {
      return res;
    }
  );
}

export function loadProductGroupsByGrower() {
  return runCS(Environment.cloudscripts.loadProductGroupsByGrower, {})
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
}

export function addToCart(params: AddToCartParams) {
  return runCS(Environment.cloudscripts.addToCart, params).then((res: any) => {
    return res;
  });
}

export function handleFavoriteProducts(params: {
  customer: string;
  variety?: string;
  favorite_key?: string;
}) {
  return runCS(Environment.cloudscripts.favoriteVarietyHandler, params).then(
    (res) => res
  );
}

export function getSubstitutesInfo(variety: string[]) {
  return sbxCoreService
    .with('replacement')
    .andWhereIsIn('variety', variety)
    .orWhereIsIn('replace', variety)
    .find()
    .then((res) => {
      return res && res.success && res.results;
    });
}

export const getProductGroupSuggestion = (product_group: string) => {
  return http
    .post('/get_product_group_suggestions', { term: product_group })
    .then((res) => res);
};

export const getVarietySuggestion = (variety: string) => {
  return http
    .post('/get_variety_suggestions', { term: variety })
    .then((res) => res);
};

// let currentRequest1: any = null;
// let cancelPreviousRequest1: (() => void) | null = null;

let lastRequestFlag: CancelTokenSource | null = null;

export const getProductsSuggestion = (term: string) => {
  const cancelToken = Axios.CancelToken.source();

  if (lastRequestFlag !== null) {
    lastRequestFlag.cancel('Cancelled due to new request');
  }

  lastRequestFlag = cancelToken;

  // if (currentRequest1 !== null) {
  //   cancelPreviousRequest1 && cancelPreviousRequest1();
  // }
  //
  // currentRequest1 = { cancel: cancelToken.cancel };
  // cancelPreviousRequest1 = cancelToken.cancel;

  return http
    .post(
      '/get_products_suggestions',
      { term },
      { cancelToken: cancelToken.token }
    )
    .then((res: any) => {
      if (res.data?.success) {
        if (cancelToken === lastRequestFlag) {
          lastRequestFlag = null; // Reset the last request flag
        }
        return { success: true, items: res.data.items, term };
      } else {
        return res;
      }
    })
    .catch((err: any) => {
      if (Axios.isCancel(err)) {
        console.log('Request cancelled:', err.message);
        return { success: false, cancelled: true, term };
      } else {
        console.error('Request error:', err);
        return;
      }
    });
};

export const getInventoryAvailableQuantity = (inventory: string) => {
  return sbxCoreService
    .with('inventory')
    .whereWithKeys([inventory])
    .find()
    .then((res) => res);
};

let currentRequestGetTotalProductDate: any = null;
let cancelPreviousRequestFunction: (() => void) | null = null;
export const getTotalProductByDateService = (params: {
  conditions: Conditions;
  pageSize: number;
  customer: string;
  dates: number[];
}) => {
  if (currentRequestGetTotalProductDate !== null) {
    cancelPreviousRequestFunction && cancelPreviousRequestFunction();
  }

  const cancelToken = Axios.CancelToken.source();
  currentRequestGetTotalProductDate = { cancel: cancelToken.cancel };
  cancelPreviousRequestFunction = cancelToken.cancel;
  return http
    .post('/get_total_products_by_dates', params, {
      cancelToken: cancelToken.token
    })
    .then((res) => res.data)
    .catch((err) => {
      if (Axios.isCancel(err)) {
        throw new Error('The request was cancelled');
      }
    });
};

export const getListDeleteProducts = (customer: string) => {
  return runCS(Environment.cloudscripts.ibf_listCustomerDeleteProducts, {
    customer
  })
    .then((res) => res)
    .catch((error) => {
      console.error(error);
    });
};

export const getGrowersByKey = (growerKeys: string[]) => {
  return sbxCoreService
    .with('grower')
    .whereWithKeys(growerKeys)
    .find()
    .then((res: any) => res);
};

export const updateCheckDeleteProducts = (
  products: { _KEY: string; user_check: boolean }[]
) => {
  return sbxCoreService
    .with('customer_delete_product')
    .update(products)
    .then((res) => res);
};

export const updateCartboxService = (cartboxes: Cartbox[]) => {
  return sbxCoreService.update('cart_box', cartboxes);
};

export type CategoryResult = {
  _KEY: string;
  category: string;
};

type CategoriesWithProductGroups = {
  category: CategoryResult[];
  product_group: {
    _KEY: string;
    common_name: string;
    category: string;
  }[];
};
type LoadDependenciesResult =
  | {
      success: true;
      results: CategoriesWithProductGroups;
    }
  | {
      success: false;
      error: string;
    };

export const getCategoriesWithProductGroups = (): Promise<LoadDependenciesResult> => {
  return runCS(Environment.cloudscripts.ibf_loadDependencies, {
    includes: ['product_group', 'category']
  }).then((res: any) => res as LoadDependenciesResult);
  // return sbxCoreService
  //   .with('add_masterlist')
  //   .andWhereIsEqualTo('active', true)
  //   .fetchModels(['product_group.category'])
  //   .loadAll(['product_group.category'])
  //   .then((res: any) => res);
};

export function removeFromBoxService(params: {
  customer: string;
  key: string;
  cart_box: string;
}) {
  return http
    .post(
      `https://shopping.ibuyflowers.com/api/cart_box/remove_from_box`,
      params
    )
    .then((res: any) => {
      return res.data;
    });
}

export function closeBoxService(params: { customer: string }) {
  return http
    .post(`https://shopping.ibuyflowers.com/api/cart_box/close_box`, params)
    .then((res: any) => {
      return res.data;
    })
    .catch((error) => error);
}
