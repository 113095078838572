import * as React from 'react';
import { useDispatch } from 'react-redux';
import { actionsModal } from '../../../Store/Modal/Slice';
import { GROWER_INFO_MODAL } from '../../../Store/Modal/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-light-svg-icons';

const SeeWhatProductsComponent = () => {
  const dispatch = useDispatch();

  return (
    <div
      className="text-primary px-3 px-lg-0"
      onClick={() => {
        dispatch(actionsModal.setOpenModal({ modal: GROWER_INFO_MODAL }));
      }}>
      <u className="pointer">
        See which products a grower has{' '}
        <FontAwesomeIcon className="ml-1" icon={faExternalLinkAlt} />
      </u>
    </div>
  );
};

export default SeeWhatProductsComponent;
