import { all, call, takeEvery, put } from 'redux-saga/effects';
import {
  getStandingOrderService,
  createStandingOrderService,
  sendEmailStandingOrderService
} from '../../Services/StandingOrderService';
import {
  GET_STANDING_ORDER,
  CREATE_STANDING_ORDER,
  SEND_EMAIL_STANDING_ORDER
} from './Types';
import { actionsStandingOrder } from './Slice';
import { toast } from '../../Utils/Utils';
import { actionsModal } from '../Modal/Slice';
import * as ModalTypes from '../../Store/Modal/Types';
import { TrackEventMonitor } from '../../Classes/TrackEvent';

export function* getStandingOrderSaga({
  payload
}: ReturnType<typeof actionsStandingOrder.getStandingOrder>): any {
  try {
    const res = yield call(getStandingOrderService, payload.customer);

    if (res.success) {
      yield put(actionsStandingOrder.setStandingOrder(res.data));
      yield put(actionsStandingOrder.setResolved());
    } else {
      yield put(actionsStandingOrder.setRejected());
    }
  } catch (err) {
    yield put(actionsStandingOrder.setRejected());
  }
}

export function* createStandingOrderSaga({
  payload
}: ReturnType<typeof actionsStandingOrder.createStandingOrder>): any {
  const freq = payload.frequency.includes('-')
    ? payload.frequency.replace('-', '_')
    : payload.frequency;

  const eventData = {
    start_date: payload.start_date,
    end_date: payload.endDate,
    frequency: payload.frequency,
    normal_price: payload?.data?.prices.normal_price,
    price: (payload?.data?.prices as { [key: string]: any })[
      freq.toLowerCase() + '_price'
    ],
    months: payload.months
  };

  try {
    const res = yield call(
      createStandingOrderService,
      payload.cartbox_key,
      payload.frequency,
      payload.start_date,
      payload.months
    );
    const selectedDay = payload.start_date;
    const data = payload.data;
    const endDate = payload.endDate;
    const priceStandingOrder = payload.priceStandingOrder;
    const chooseFrecuency = payload.frequency;

    if (res?.success) {
      TrackEventMonitor.newEvent({
        props: eventData,
        metadata: { name: 'ibf_so_order_creation' }
      });

      toast('The standing order has been created.');
      yield put(actionsStandingOrder.setResolved());
      yield put(
        actionsModal.setCloseModal({
          modal: ModalTypes.CONFIRMATION_CHECKOUT_MODAL
        })
      );

      yield put(
        actionsModal.setCloseModal({
          modal: ModalTypes.STANDING_ORDER_CREATION_MODAL
        })
      );
      yield put(
        actionsModal.setOpenModal({
          modal: ModalTypes.STANDING_ORDER_DONE,
          data: {
            box: data,
            startDate: selectedDay,
            endDate,
            totalPrices: priceStandingOrder,
            chooseFrecuency
          }
        })
      );
      yield put(
        actionsStandingOrder.setResponseStandingOrder({
          created: true,
          _KEY: payload.cartbox_key
        })
      );
      yield put(
        actionsModal.setCloseModal({
          modal: ModalTypes.CONFIRMATION_CHECKOUT_MODAL
        })
      );
    } else {
      TrackEventMonitor.newEvent({
        props: { ...eventData, response: res },
        metadata: { name: 'ibf_so_order_error' }
      });
      toast('Failed creation standing order. Please, try again.', 'error');
      yield put(actionsStandingOrder.setRejected());
      // yield put(actionsStandingOrder.setResponseStandingOrder(false));
    }
  } catch (error) {
    TrackEventMonitor.newEvent({
      props: { ...eventData, error },
      metadata: { name: 'ibf_so_order_error' }
    });

    yield put(actionsStandingOrder.setRejected());
  }
}

export function* sendEmailStandingOrderSaga({
  payload
}: ReturnType<typeof actionsStandingOrder.sendEmailStandingOrder>): any {
  try {
    const res = yield call(sendEmailStandingOrderService, payload);

    if (res.success) {
      toast('The email standing order has been sent.');
      yield put(actionsStandingOrder.setResolved());
    } else {
      yield put(actionsStandingOrder.setRejected());
      toast('The standing order email could not be sent.', 'error');
    }
  } catch (err) {
    yield put(actionsStandingOrder.setRejected());
  }
}

export default function* AllSagas() {
  yield all([
    takeEvery(GET_STANDING_ORDER, getStandingOrderSaga),
    takeEvery(CREATE_STANDING_ORDER, createStandingOrderSaga),
    takeEvery(SEND_EMAIL_STANDING_ORDER, sendEmailStandingOrderSaga)
  ]);
}
