import React, { useCallback } from 'react';
import DateOptionList from './DateOptionsComponent/DateOptionList';
import GrowerOptionList from './GrowerOptionsComponent/GrowerOptionList';
import { useDispatch, useSelector } from 'react-redux';
import {
  addObjectParamsToPath,
  convertDateToStantard
} from '../../../../Utils/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { RootState } from '../../../../Store/Reducers';
import { actionsFilter } from '../../../../Store/Filter/Slice';
import { useHistory } from 'react-router-dom';
const MoreOptionsComponent = () => {
  const { date } = useSelector((state: RootState) => state.ProductReducer);
  const {
    selectedGrower,
    availableDates,
    availableGrowers,
    filters_conditions
  } = useSelector((state: RootState) => state.FilterReducer);

  const dispatch = useDispatch();
  const history = useHistory();
  const setAllProducts = () => {
    delete filters_conditions.favorites;
    delete filters_conditions.variety;
    const params = { ...filters_conditions, date };
    dispatch(actionsFilter.setFilters(params));
    history.push(addObjectParamsToPath('/search', params));
  };

  const setAllGrowers = () => {
    delete filters_conditions.grower;
    delete filters_conditions.variety;
    let params = { ...filters_conditions, date };
    dispatch(actionsFilter.setFilters(params));
    dispatch(actionsFilter.setSelectedGrower({ name: '', key: '' }));
    history.push(addObjectParamsToPath('/search', params));
  };

  const getMessage = useCallback(() => {
    if (selectedGrower.key) {
      if (filters_conditions.favorites) {
        return (
          <span>
            No{' '}
            <span className="font-weight-bold mr-1">available favorites</span>
            found at grower {selectedGrower.name} for delivery date{' '}
            {convertDateToStantard(date)}.
          </span>
        );
      } else {
        return;
      }
    } else {
      if (filters_conditions.favorites) {
        return (
          <span>
            No{' '}
            {<span className="font-weight-bold mr-1">available favorites</span>}
            found for delivery date {convertDateToStantard(date)}.
          </span>
        );
      } else {
        return <></>;
      }
    }
  }, [selectedGrower, date, filters_conditions.favorites]);

  return (
    <div className="d-flex flex-column mx-2">
      {getMessage()}
      {filters_conditions.favorites ? (
        <div className="d-flex flex-column mt-2">
          <span>Tip: broaden your search by:</span>
          <button
            className="btn btn-primary"
            style={{ width: '435px' }}
            onClick={setAllProducts}>
            Select filter 'All products' (instead of 'Available favorites')
          </button>
          {selectedGrower.key && (
            <button
              className="btn btn-primary mt-2"
              style={{ width: '350px' }}
              onClick={setAllGrowers}>
              Select filter 'All growers' (instead of {selectedGrower.name})
            </button>
          )}
          <span className="mt-3 font-weight-bold">How to make a favorite:</span>
          <span>
            When you see a product, simply click on the{' '}
            <FontAwesomeIcon icon={faHeart} className="mr-1" />
            heart symbol, to save or undo it as a favorite. Previously bought
            products are saved as favorite automatically.
          </span>
        </div>
      ) : (
        <>
          {availableDates.length >= 0 && (
            <div className="content-available w-100">
              <DateOptionList
                availableDates={availableDates.length === 0}
                date={convertDateToStantard(date)}
              />
            </div>
          )}
          {availableGrowers.length !== 0 && <GrowerOptionList />}
        </>
      )}
    </div>
  );
};

export default MoreOptionsComponent;
