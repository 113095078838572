import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { modalReducer } from '../../../../Store/Selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { MESSAGE_COOKIE_MODAL } from '../../../../Store/Modal/Types';
export default function MessageCookiesModal() {
  const dispatch = useDispatch();
  const {
    MESSAGE_COOKIE_MODAL: { open }
  } = useSelector(modalReducer);
  const toggle = () => {
    dispatch(actionsModal.setCloseModal({ modal: MESSAGE_COOKIE_MODAL }));
  };

  return (
    <Modal isOpen={open} size="sm">
      <span className="d-flex justify-content-end">
        <FontAwesomeIcon
          className={'pointer pr-2 mt-1'}
          onClick={toggle}
          icon={faTimes}
          size={'2x'}
        />
      </span>
      <ModalBody>
        <p className="px-2">
          Sorry, the website doesn't work because the cookies are turned off in
          your browser. Please turn your cookies on. You can find your cookies
          settings in the settings of your browser.
        </p>
      </ModalBody>
    </Modal>
  );
}
