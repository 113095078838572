import React, { useState } from 'react';
import './buttonPicker.scss';
import { Button, Popover, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

const arrayColors: Array<string> = [
  '#f99b9c',
  '#D1E9F5',
  '#F8D0BE',
  '#B8C38B',
  '#FEF1CE'
];

type Props = {
  selectedColor: string;
  setSelectedColor: (color: string) => void;
  className?: string;
  index?: number;
};

export default function ButtonPickerColor({
  selectedColor,
  setSelectedColor,
  className,
  index
}: Props) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);

  const handleClickPikerColor = (item: string) => {
    setSelectedColor(item);
  };

  const renderContentColorPiker = (
    <div className="d-flex justify-content-between ">
      {arrayColors.map((item, index) => (
        <span
          key={index}
          className={`button-color-picker ml-1 mr-1 ${item === selectedColor &&
            'button-color-picker-selected'} pointer`}
          style={{ backgroundColor: item }}
          onClick={() => handleClickPikerColor(item)}
        />
      ))}
    </div>
  );

  return (
    <div className={className ?? ''}>
      <Button
        id={`Popover-${index}`}
        className="d-flex align-items-center"
        style={{ border: 'none', backgroundColor: 'transparent' }}
        type="button">
        <span
          style={{ backgroundColor: selectedColor }}
          className="button-color-picker button-color-picker-selected align-items-center mr-2"
        />
        <FontAwesomeIcon
          icon={faChevronDown}
          className="ml-1"
          color={'black'}
        />
      </Button>
      <Popover
        placement="bottom"
        trigger={'legacy'}
        isOpen={popoverOpen}
        target={`Popover-${index}`}
        toggle={toggle}>
        <PopoverBody>{renderContentColorPiker}</PopoverBody>
      </Popover>
    </div>
  );
}
