import * as React from 'react';
import { useState } from 'react';
import {
  CategoryResult,
  getCategoriesWithProductGroups
} from '../../Services/ProductService';
import { State } from '../../Models/State';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardReducer } from '../../Store/Selectors';
import {
  actionsDashboard,
  LandingProductItem
} from '../../Store/Dashboard/Slice';
import { addObjectParamsToPath, splitIntoChunks } from '../../Utils/Utils';
import { actionsProduct } from '../../Store/Product/Slice';
import styled from 'styled-components';

const Container = styled.div`
  //display: grid;
  //grid-auto-flow: row;
  //grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  //row-gap: 20px;
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;

  @media (max-width: 990px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
    padding: 15px;
  }
`;

const RowList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 220px;
`;

const RowItem = styled.div`
  justify-content: space-around;
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
  height: 30px;
`;

const CategoriesWithProductGroups = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { landingProductItems } = useSelector(dashboardReducer);

  const [loading, setLoading] = useState(State.IDLE);

  React.useEffect(() => {
    const getProductGroups = async () => {
      setLoading(State.PENDING);
      const response = await getCategoriesWithProductGroups();
      if (response.success) {
        const categoriesWithProductGroups = new Map<
          string,
          LandingProductItem[]
        >();

        const {
          categoriesMap,
          categoriesRecord
        } = response.results.category.reduce(
          (acc, category) => {
            acc.categoriesMap.set(category.category, category._KEY);
            acc.categoriesRecord[category._KEY] = category;
            return acc;
          },
          {
            categoriesMap: new Map<string, string>(),
            categoriesRecord: {} as Record<string, CategoryResult>
          }
        );

        response.results.product_group.forEach((product_group) => {
          // if we dont have the product group, add it
          if (!categoriesWithProductGroups.has(product_group.category)) {
            categoriesWithProductGroups.set(
              product_group.category,
              new Array<LandingProductItem>()
            );
          }

          // add the variety
          categoriesWithProductGroups.get(product_group.category)?.push({
            type: 'product_group',
            parent_name: categoriesRecord[product_group.category].category,
            name: product_group.common_name,
            _KEY: product_group._KEY
          });
        });

        const categoryNames = Array.from(categoriesMap.keys()).sort((a, b) => {
          return a.localeCompare(b);
        });

        const viewObjectsobjects = Array.from(categoryNames).reduce(
          (acc, cat) => {
            const key = categoriesMap.get(cat) ?? '';
            const productGroups = categoriesWithProductGroups.get(key) || [];
            const group = categoriesRecord[key];

            acc.push({
              type: 'category',
              parent_name: group.category,
              name: group.category,
              _KEY: group._KEY
            });

            const value = productGroups.sort((a, b) => {
              return (a.name ?? '').localeCompare(b.name ?? '');
            });
            acc.push(...value);
            return acc;
          },
          new Array<LandingProductItem>()
        );

        const chunks = splitIntoChunks(viewObjectsobjects, 12);

        dispatch(actionsDashboard.setProductGroupWithMasterlist(chunks));
        setLoading(State.RESOLVED);
      } else {
        setLoading(State.REJECTED);
      }
    };

    if (Object.keys(landingProductItems).length === 0) {
      getProductGroups();
    }
  }, [dispatch, landingProductItems]);

  const search = ({
    type,
    key
  }: {
    type: 'product_group' | 'category';
    key: string;
  }) => {
    dispatch(actionsProduct.setProductDate({ date: 0 }));

    history.push(addObjectParamsToPath('/search', { [type]: key }));
  };

  return (
    <>
      {loading === State.PENDING ? (
        <div>
          <Skeleton />
        </div>
      ) : (
        <Container>
          {landingProductItems.map((items, idx) => {
            return (
              <RowList key={`${idx}`}>
                {items.map((item) => {
                  return (
                    <RowItem key={item._KEY}>
                      <span
                        className={
                          item.type === 'category'
                            ? `font-weight-bold underline pointer ibf-font-14`
                            : `underline pointer ibf-font-14 d-lg-flex`
                        }
                        onClick={() => {
                          search({ key: item._KEY, type: item.type });
                        }}>
                        {item.name}
                      </span>
                    </RowItem>
                  );
                })}
              </RowList>
            );
          })}

          {/*// </div>*/}
        </Container>
      )}
    </>
  );
};

export default CategoriesWithProductGroups;
