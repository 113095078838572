import React from 'react';
import { RootState } from '../../../../Store/Reducers';
import { useDispatch, useSelector } from 'react-redux';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { CONFIRMATION_CHECKOUT_MODAL } from '../../../../Store/Modal/Types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'antd';

const ConfirmationCheckoutModal = () => {
  const {
    CONFIRMATION_CHECKOUT_MODAL: { open, data }
  } = useSelector((state: RootState) => state.ModalReducer);
  const dispatch = useDispatch();
  const closeModal = () =>
    dispatch(
      actionsModal.setCloseModal({ modal: CONFIRMATION_CHECKOUT_MODAL })
    );
  const confirmationClick = () => {
    data.confirmAction();
    closeModal();
  };
  const cancelClick = () => {
    data.cancelAction && data.cancelAction();
    closeModal();
  };

  return (
    <Modal isOpen={open} size="lg" className="ibf-modal-confirmation-size">
      <span className="ibf-modal-close-button pr-2" onClick={cancelClick}>
        X
      </span>
      <ModalBody className="pt-0 ibf-modal-body-confirmation">
        <div className="ibf-modal-confirmation-title mb-2">{data.title}</div>
        {data.body && (
          <span className="ibf-modal-confirmation-body">{data.body}</span>
        )}
      </ModalBody>
      <ModalFooter className="border-0 ibf-modal-confirmation-footer">
        {data.showCancelButton && (
          <div className="ibf-modal-confirmation-button">
            <Button onClick={cancelClick}>{data.cancelButtonText}</Button>
          </div>
        )}
        <div className="ibf-modal-confirmation-button-2">
          <Button onClick={confirmationClick}>{data.confirmButtonText}</Button>{' '}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationCheckoutModal;
