import Environment from '../Environment';
import { loadStripe } from '@stripe/stripe-js';

let StripeService = loadStripe(Environment.stripe_key);
//
// if ((window as any).Stripe) {
//   StripeService = (window as any).Stripe(Environment.stripe_key);
// } else {
//   const querySelector = document.querySelector("#stripe-js");
//   querySelector &&
//     querySelector.addEventListener("load", () => {
//       // Create Stripe instance once Stripe.js l
//       StripeService = (window as any).Stripe(Environment.stripe_key);
//     });
// }

export default StripeService;
