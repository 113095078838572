import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { authReducer, modalReducer } from '../../../../Store/Selectors';
import { actionsModal } from '../../../../Store/Modal/Slice';
import {
  CHANGE_EMAIL_MODAL,
  CONFIRMATION_CHECKOUT_MODAL
} from '../../../../Store/Modal/Types';
import { Button, Input, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { isMailAvailable } from '../../../../Services/AuthService';
import { toast } from '../../../../Utils/Utils';
import { State } from '../../../../Models/State';
import { actionsAuth } from '../../../../Store/Auth/Slice';
import { ChangeEmailParams } from '../../../../Models/Auth';

type ChangeEmailModal = {
  current_email: string;
  new_email: string;
  repeat_email: string;
};

const validationInputMessage = (
  <span className="ibf-font-12 color-red">Please fill in this info.</span>
);

const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === ' ') {
    e.preventDefault();
  }
};

const ChangeEmailModal = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm<ChangeEmailModal>();

  const {
    CHANGE_EMAIL_MODAL: { open }
  } = useSelector(modalReducer);

  const {
    user: {
      email,
      key,
      user_id,
      metadata: {
        from_manager,
        main_user_email,
        main_user_key,
        customer,
        main_user_id
      }
    }
  } = useSelector(authReducer);

  const dispatch = useDispatch();
  const [availableError, setAvailableError] = useState(false);
  const [loading, setLoading] = useState(State.IDLE);

  const toggle = () =>
    dispatch(actionsModal.setCloseModal({ modal: CHANGE_EMAIL_MODAL }));

  const onSubmit: SubmitHandler<ChangeEmailModal> = async (data) => {
    if (data.repeat_email.toLowerCase() !== data.new_email.toLowerCase()) {
      toast("The email doesn't match", 'error');
      return;
    }
    setLoading(State.PENDING);
    const isAvailable = await isMailAvailable(data.new_email, true);

    if (isAvailable === null || !isAvailable) {
      setAvailableError(true);
      setLoading(State.REJECTED);
      return;
    } else {
      setAvailableError(false);
    }
    // let dataUser = {
    //   new_email: data.new_email,
    //   current_email: data.current_email,
    //   user: from_manager ? main_user_key : key,
    //   user_name: from_manager
    //     ? `${main_user_first_name}
    //     ${main_user_last_name}`
    //     : `${first_name} ${last_name}`
    // };

    const dataUser: ChangeEmailParams = {
      email: data.new_email,
      user_key: main_user_key ?? key,
      user_id: main_user_id ?? user_id,
      customer
    };

    dispatch(
      actionsModal.setOpenModal({
        modal: CONFIRMATION_CHECKOUT_MODAL,
        data: {
          body: (
            <span>
              Are you sure to change email from <b>{data.current_email}</b> to{' '}
              <b>{data.new_email}</b> ?
            </span>
          ),
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          showCancelButton: true,
          confirmAction: () => {
            dispatch(actionsAuth.changeEmail(dataUser));
          },
          cancelAction: () => {
            setLoading(State.RESOLVED);
          }
        }
      })
    );
  };

  React.useEffect(() => {
    setValue('current_email', from_manager ? main_user_email : email);
  }, [email, from_manager, main_user_email, setValue]);

  const toggleAll = () => {
    dispatch(actionsModal.setCloseModal({ modal: CHANGE_EMAIL_MODAL }));
  };

  return (
    <Modal isOpen={open} size={'lg'}>
      <div className="d-flex align-items-center justify-content-between p-3">
        <span className="ibf-link" onClick={toggle}>
          {'<'} Go back
        </span>
        <FontAwesomeIcon
          className={'pointer'}
          onClick={toggleAll}
          icon={faTimes}
          size={'2x'}
        />
      </div>

      <div className="d-flex flex-column px-3 pb-5">
        <div className="d-flex flex-column mb-4">
          <h4 className="font-weight-bold mb-2">Change your email</h4>
        </div>

        <div className="d-flex flex-column align-items-lg-start">
          <div className="d-flex flex-column w-lg-50">
            <div
              style={{ display: 'grid', gridTemplateColumns: '3em 1fr' }}
              className={'align-items-center'}>
              <span className="mr-2">From: </span>
              <div className="form-group input-register flex-grow-1">
                <Input
                  type="email"
                  defaultValue={from_manager ? main_user_email : email || ''}
                  placeholder=" "
                  autoComplete="off"
                  id={'current_email'}
                  onKeyDown={handleKeyDown}
                  disabled
                />
                {errors?.current_email && validationInputMessage}
                <label htmlFor="current_email" className="register-label">
                  Current Email address <span className="text-danger"> * </span>{' '}
                </label>
              </div>
            </div>

            <div
              style={{ display: 'grid', gridTemplateColumns: '3em 1fr' }}
              className={'align-items-center'}>
              <span className="mr-2">To:</span>
              <div className="form-group input-register flex-grow-1">
                <Input
                  type="email"
                  placeholder=" "
                  autoComplete="off"
                  id={'new_email'}
                  onKeyDown={handleKeyDown}
                  {...register('new_email', { required: true, minLength: 8 })}
                />
                <div className="d-flex flex-column">
                  {errors?.new_email && validationInputMessage}
                </div>
                <label htmlFor="new_email" className="register-label">
                  New email address <span className="text-danger"> * </span>{' '}
                </label>
              </div>
            </div>

            <div className="form-group input-register ml-5">
              <Input
                type="email"
                placeholder=" "
                autoComplete="off"
                id={'repeat_email'}
                onKeyDown={handleKeyDown}
                {...register('repeat_email', { required: true })}
              />
              <div className="d-flex flex-column">
                {errors?.repeat_email && validationInputMessage}
              </div>
              <label htmlFor="repeat_email" className="register-label">
                Repeat the new email address{' '}
                <span className="text-danger"> * </span>{' '}
              </label>
            </div>
          </div>

          {availableError && (
            <div className="pl-5 mb-3 w-lg-75">
              <span className="color-red">
                This new email address is already related to another account, so
                you can not change it to this one. Please try another email
                address or contact support at +1 844 428 9100 for assistance.
              </span>
            </div>
          )}

          <Button
            color={'primary'}
            type={'button'}
            onClick={() => handleSubmit(onSubmit)()}
            disabled={loading === State.PENDING}
            className="ml-5">
            <div className="d-flex align-items-center">
              {loading === State.PENDING && (
                <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" pulse />
              )}
              <div className="d-flex flex-column">
                <span>Change the email address now</span>
                <span>(it can take up to a few minutes)</span>
              </div>
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeEmailModal;
