import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { ReactComponent as Logo } from '../../Assets/Images/logo-ibuyflowers.svg';

const NavBarCalculatingComponent = () => {
  return (
    <div className="ibf-nav-bar">
      <Navbar
        className="align-items-baseline px-3 position-sticky ibf-nav-shadow justify-content-center"
        light
        expand="md">
        <NavbarBrand>
          <Logo />
        </NavbarBrand>
      </Navbar>
    </div>
  );
};

export default NavBarCalculatingComponent;
