import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../Models/State';
import { ShoppingList } from '../../Models/ShoppingList';
import { ShoppingListState } from './Types';

const { IDLE, RESOLVED, PENDING, REJECTED } = State;

const initialState: ShoppingListState = {
  shoppingLists: [],
  shoppingListCheckout: [],
  shoppingListsProcessed: [],
  state: IDLE
};

const slice = createSlice({
  name: 'shoppingList',
  initialState,
  reducers: {
    createShoppingList(
      state,
      { payload }: PayloadAction<{ customer: string; address: any }>
    ) {
      state.state = PENDING;
    },
    setShoppingLists(state, { payload }: PayloadAction<ShoppingList[]>) {
      state.state = RESOLVED;
      state.shoppingLists = payload;
    },
    getShoppingListProcessed(state, { payload }: PayloadAction<string>) {
      state.state = PENDING;
    },
    setShoppingListsCheckout(
      state,
      { payload }: PayloadAction<ShoppingList[]>
    ) {
      state.state = RESOLVED;
      state.shoppingListCheckout = payload;
    },
    setShoppingListsProcessed(
      state,
      { payload }: PayloadAction<ShoppingList[]>
    ) {
      state.state = RESOLVED;
      state.shoppingListsProcessed = payload;
    },
    removeShoppingListItem(
      state,
      { payload }: PayloadAction<{ slItem: string }>
    ) {
      state.state = PENDING;
    },
    removeORItem(
      state,
      { payload }: PayloadAction<{ orderRequest: string; productKey: string }>
    ) {
      state.state = PENDING;
    },
    removeIncompleteItem(
      state,
      { payload }: PayloadAction<{ slItem: string; productKey: string }>
    ) {
      state.state = PENDING;
    },
    setRemoveShoppingListItem(state, { payload }: PayloadAction<string>) {
      state.state = RESOLVED;
      state.shoppingListCheckout.forEach((sl: any) => {
        sl.items = sl.items.filter((sli: any) => sli._KEY !== payload);
      });
    },
    checkLastSLItem(
      state,
      { payload }: PayloadAction<{ slItemKey: string; productKey: string }>
    ) {
      state.state = PENDING;
    },
    checkLastSLItemByBox(
      state,
      { payload }: PayloadAction<{ slItemKey: string; productKey: string }>
    ) {
      state.state = PENDING;
    },
    removeItemFromSl(
      state,
      { payload }: PayloadAction<{ key: string; customer: string }>
    ) {
      state.state = PENDING;
    },
    setPending(state) {
      state.state = PENDING;
    },
    setResolved(state) {
      state.state = RESOLVED;
    },
    setRejected(state) {
      state.state = REJECTED;
    }
  }
});

export const actionsShoppingList = slice.actions;
export default slice.reducer;
