import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Environment from '../../../Environment';
import { State } from '../../../Models/State';
import { actionsCheckout } from '../../../Store/Checkout/Slice';
import { actionsCustomBox } from '../../../Store/CustomBox/Slice';
import { RootState } from '../../../Store/Reducers';
import {
  convertNumberDateDDDMMMYYYY,
  roundUp,
  toast
} from '../../../Utils/Utils';
import ImageComponent from '../ImageComponent/ImageComponent';
import ProductOptionsForm from './ProductOptionsForm';
import { TrackEventMonitor } from '../../../Classes/TrackEvent';

type Props = {
  boxItem: any;
  etaDate: number;
  percentage: number;
};

const CartCustomBoxItem: React.FC<Props> = ({
  boxItem,
  etaDate,
  percentage
}) => {
  const dispatchAction = useDispatch();
  const { state: CheckoutState } = useSelector(
    (state: RootState) => state.CheckoutReducer
  );
  const { state: CustomBoxState } = useSelector(
    (state: RootState) => state.CustomBoxReducer
  );
  const { customer } = useSelector(
    (state: RootState) => state.AuthReducer.user.metadata
  );

  const [loading, setLoading] = useState(State.IDLE);

  React.useEffect(() => {
    if (CheckoutState === State.REJECTED || CustomBoxState === State.REJECTED) {
      setLoading(State.REJECTED);
    } else if (
      (CustomBoxState === State.RESOLVED && CheckoutState !== State.PENDING) ||
      (CustomBoxState !== State.PENDING && CheckoutState === State.RESOLVED)
    ) {
      setLoading(State.RESOLVED);
    }
  }, [CheckoutState, CustomBoxState]);

  const deleteItems = async (quantity?: number) => {
    if (CheckoutState === State.PENDING) {
      toast('Waiting for action to end.', 'info');
    } else {
      setLoading(State.PENDING);
      await dispatchAction(
        actionsCheckout.removeFromCustomBox({
          item: {
            quantity: boxItem.quantity,
            masterlist: {
              _KEY: boxItem.masterlist._KEY,
              custom_percentage: boxItem.masterlist.custom_percentage
            },
            inventory: {
              _KEY: boxItem.inventory._KEY,
              quantity: boxItem.inventory.quantity
            },
            uom: boxItem.uom.uom,
            stems_per_bunch: boxItem.stems_per_bunch.stems_per_bunch
          },
          customer,
          cartBox: {
            current_percentage: percentage,
            _KEY: boxItem.cart_box._KEY,
            current_items: boxItem.cart_box.current_items
          },
          productKey: boxItem._KEY,
          quantity: quantity || boxItem.quantity,
          slItemKey: boxItem.shopping_list_item
            ? boxItem.shopping_list_item._KEY
            : '',
          isBoxOpen: true
        })
      );

      TrackEventMonitor.newEvent({
        props: {
          cart_box_key: boxItem.cart_box._KEY,
          grower_name: boxItem.grower?.company_name,
          grower_key: boxItem.grower?._KEY,
          masterlist_key: boxItem.masterlist._KEY,
          quantity: boxItem.quantity,
          repurchase_cartbox_item_key: boxItem.repurchase_cartbox_item ?? '',
          variety_key: boxItem.variety._KEY,
          cart_box_item_key: boxItem._KEY,
          custom_box_modal: true
        },
        metadata: {
          name: 'ibf_remove_from_box'
        }
      });
    }
  };

  const handleQuantity = (quantity: number, cartboxItem?: any) => {
    setLoading(State.PENDING);
    if (CustomBoxState !== State.PENDING) {
      if (cartboxItem) {
        if (quantity > cartboxItem?.quantity) {
          if (cartboxItem.percentage + percentage <= 100) {
            dispatchAction(
              actionsCustomBox.addToBox({
                boxParams: {
                  customer,
                  invKey: cartboxItem.inventory._KEY,
                  arrival_date: cartboxItem.cart_box.eta_date,
                  charge_date: cartboxItem.cart_box.charge_date,
                  packing_date: cartboxItem.cart_box.packing_date,
                  quantity: quantity - cartboxItem?.quantity
                },
                percentage:
                  percentage +
                  cartboxItem.percentage * (quantity - cartboxItem?.quantity)
              })
            );
            // dispatchAction(actionsCustomBox.increaseCustomBox({params, cartbox: cartboxItem.cart_box}));
          }
        } else {
          if (cartboxItem?.quantity - quantity > 0) {
            deleteItems(cartboxItem.quantity - quantity);
          }
        }
      }
    }
  };

  return (
    <div key={boxItem._KEY + '-checkout-element'} className="mb-2 p-1">
      {loading === State.PENDING ? (
        <div className="w-100 text-center" style={{ height: '9vh' }}>
          <div className="spinner-grow text-primary " role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex">
            <div className="mr-2">
              <ImageComponent
                imageUrl={
                  boxItem.img
                    ? Environment.publicPath + (boxItem.img && boxItem.img[0])
                    : Environment.publicPath +
                      (boxItem.images && boxItem.images[0])
                }
                rounded={true}
                imgSize={'65px'}
              />
            </div>
            <div className="d-flex flex-column mr-2 w-50">
              <span className={'ibf-text-card mb-0'}>
                {boxItem.name}
                <span
                  className="pointer color-blue ml-1"
                  onClick={() => deleteItems()}
                  style={{ textDecoration: 'underline' }}>
                  Delete
                </span>
              </span>

              {etaDate && (
                <span>
                  Delivery date: {convertNumberDateDDDMMMYYYY(etaDate)}
                </span>
              )}
            </div>
            <div className="d-flex flex-column flex-grow-1">
              <>
                <ProductOptionsForm
                  item={boxItem}
                  handleQuantity={handleQuantity}
                  boxPercentage={percentage}
                />
                <div>
                  <span>
                    {`${boxItem.percentage * boxItem.quantity} % of the box`}
                  </span>
                </div>
                <div className="ibf-product-ready-form-price">
                  ${' '}
                  {roundUp(
                    boxItem?.price * (1 - boxItem?.off) * boxItem?.quantity
                  )}
                </div>
              </>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CartCustomBoxItem;
