import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Emma from '../../../Assets/Images/emma.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDolly } from '@fortawesome/pro-solid-svg-icons';
import { actionsModal } from '../../../Store/Modal/Slice';
import { RootState } from '../../../Store/Reducers';
import { SHIPPING_DETAILS } from '../../../Store/Modal/Types';

const ShippingDetails = () => {
  const dispatch = useDispatch();
  const toggle = () => {
    dispatch(actionsModal.setCloseModal({ modal: SHIPPING_DETAILS }));
  };
  const { shippingDetails } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  return (
    <>
      <Modal size={'lg'} centered isOpen={shippingDetails.open}>
        <ModalBody>
          <div className="media">
            <img src={Emma} className="mr-3" alt="" />
            <div className="media-body">
              <div className="d-flex mt-4">
                <FontAwesomeIcon icon={faDolly} size="2x" />
                <h5 className="mt-1 ml-2">Shipping Details</h5>
              </div>
              <p className="mt-3">
                iBuyFlowers ships all their flowers directly from the farm to
                the customer with FedEx. Each purchase order confirmation is
                providing with a FedEx tracking link. iBuyFlowers is not
                responsible for FedEx shipping delays of 3 days or less or other
                shipping delivery problems including the customer not being on
                site to accept the delivery. iBuyFlowers can’t credit if a
                package has been delayed beyond our control. iBuyFlowers stands
                behind the quality of the flowers. Should something not be
                according to standard. Please file a claim following our claim
                policy.
              </p>

              <div className="mt-5 d-flex justify-content-end">
                <button className="btn btn-primary mt-3" onClick={toggle}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ShippingDetails;
