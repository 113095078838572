import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ModalComponent from '../ModalComponent';
import { authReducer } from '../../../../Store/Selectors';

const EmergencyMessage = () => {
  const {
    config: { emergencyMessage },
    user: {
      metadata: { customer_confirm_profile }
    }
  } = useSelector(authReducer);

  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    if (emergencyMessage?.visible && customer_confirm_profile) {
      setOpen(true);
    }
  }, [emergencyMessage, customer_confirm_profile]);

  //function onEmergency

  return (
    <ModalComponent
      size={'lg'}
      isOpen={open}
      onClose={() => setOpen(false)}
      okClick={() => setOpen(false)}
      okText={'Ok'}
      centerFooter
      largeOkButton
      title={emergencyMessage?.title}>
      {emergencyMessage?.paragraphs.map((text, index) => (
        <p key={'emergency_message_text_' + index} className="mb-0">
          {text}
        </p>
      ))}

      <hr
        style={{ visibility: emergencyMessage?.link ? 'visible' : 'hidden' }}
      />
      <a
        style={{ visibility: emergencyMessage?.link ? 'visible' : 'hidden' }}
        target="_blank"
        rel="noopener noreferrer"
        className="alert-link"
        href={emergencyMessage?.link}>
        {emergencyMessage?.linkLabel}
      </a>
    </ModalComponent>
  );
};

export default EmergencyMessage;
