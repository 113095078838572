import React from 'react';
type Props = {
  custom?: boolean;
  growers: { [key: string]: string };
  country: string;
};
export default function StandingTitleBox({ custom, growers, country }: Props) {
  return (
    <div className="mt-3">
      <b>
        Your {custom && 'custom'} box from grower {growers.name} ({country}):
      </b>
    </div>
  );
}
