import SearchInventoryParams from './../Models/SearchInventory/SearchInventoryParams';
import Axios from 'axios';
import Environment from './../Environment';
import { runCS, sbxCoreService, sbxSessionService } from '../Network';
import { toast } from '../Utils/Utils';
import { AddToBoxParams } from '../Models/AddToBoxParams';

const http = Axios.create({
  baseURL: Environment.searchProductUrl,
  headers: {
    authorization:
      'Bearer ' +
      (sbxSessionService.getCurrentUser().token ||
        localStorage.getItem('token'))
  }
});

let currentRequest: any = null;
let cancelPreviousRequest: (() => void) | null = null;

export function searchInventory(params: SearchInventoryParams): any {
  let data = { ...params };

  if (currentRequest !== null) {
    cancelPreviousRequest && cancelPreviousRequest();
  }

  const cancelToken = Axios.CancelToken.source();
  currentRequest = { cancel: cancelToken.cancel };
  cancelPreviousRequest = cancelToken.cancel;

  try {
    return http
      .post('/search_custom', data, { cancelToken: cancelToken.token })
      .then((res: any) => {
        if (res.data?.success) {
          cancelPreviousRequest = null;
          return res.data;
        }
      })
      .catch((error: any) => {
        if (Axios.isCancel(error)) {
          cancelPreviousRequest = null;
        } else {
          cancelPreviousRequest = null;
        }
      });
  } catch (error) {
    cancelPreviousRequest = null;
  }
}

export function getAllStandingOrderService() {
  return sbxCoreService
    .with('standing_order')
    .loadAll()
    .then((res: any) => res)
    .catch((error) => toast(error, 'error'));
}

export function addToBoxService(params: AddToBoxParams) {
  return runCS(Environment.cloudscripts.addToBox, params)
    .then((res) => res)
    .catch((error) => toast(error, 'error'));
}

export const closeCustomBox = (customer: any) => {
  return runCS(Environment.cloudscripts.closeBox, {
    customer
  }).then((res) => res);
};

export const openCustomBox = (cart_box_key: string, customer: string) => {
  return runCS(Environment.cloudscripts.openBox, {
    cart_box_key,
    customer
  }).then((res) => res);
};
