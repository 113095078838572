import React, { useState } from 'react';
import { Input, Modal } from 'reactstrap';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCustomer } from '../../../../Store/Customer/Slice';
import { RootState } from '../../../../Store/Reducers';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { GREETING_MODAL } from '../../../../Store/Modal/Types';

const GreetingModal = () => {
  const dispatch = useDispatch();
  const { name, metadata } = useSelector(
    (state: RootState) => state.AuthReducer.user
  );
  const [checked, setChecked] = useState(true);

  const handleChangeChecked = (e: any) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
  };

  const onSubmit = async () => {
    dispatch(actionsModal.setCloseModal({ modal: GREETING_MODAL }));
    if (checked) {
      const preferences = {
        ...metadata.preferences,
        ...{ hideGreetingModal: true }
      };
      dispatch(
        actionsCustomer.updateUserPreferences({
          customer: metadata.customer,
          preferences
        })
      );
    }
  };

  return (
    <div>
      <Modal size="lg" isOpen className="ibf-modal-border" centered={true}>
        <div className="ibf-greeting-modal-container">
          <div className="ibf-greeting-modal-image" />

          <div className="ibf-greeting-modal-title">
            <b>{name ? name : 'You'}, we've optimised iBuyFlowers.com</b>
          </div>

          <div className="ibf-greeting-modal-description">
            <span>
              A better experience, fully mobile friendly and based on feedback
              from florist and Wedding & Event planners.
            </span>
          </div>

          <div className="ibf-greeting-modal-btn">
            <Button onClick={onSubmit}>Start &#187;</Button>
          </div>

          <div className="ibf-greeting-modal-dont-show">
            <div className="ibf-greeting-modal-input">
              <Input
                onChange={handleChangeChecked}
                className="mr-2"
                id="greeting_dont_show"
                type="checkbox"
              />
              <label className="" htmlFor="greeting_dont_show">
                Don't show this message anymore
              </label>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GreetingModal;
