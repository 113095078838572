import React, { Fragment, useEffect, useState } from 'react';
import { getCountryFlag } from '../../../../Services/UserService';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addObjectParamsToPath,
  countryFlag,
  removeDuplicateFromArrayObj
} from '../../../../Utils/Utils';
import { RootState } from '../../../../Store/Reducers';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { GROWER_INFO_MODAL } from '../../../../Store/Modal/Types';
import { useHistory } from 'react-router-dom';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { productReducer } from '../../../../Store/Selectors';
import { ProductGroupByGrower } from '../../../../Models/ProductGroup';
import Select from 'react-select';

const GrowerInfoComponent = () => {
  // const [loaded, ] = useState(false);
  const { growerInfoModal } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const [list, setList] = useState<
    (ProductGroupByGrower & { countryISO?: string; countryFlag?: string })[]
  >([]);
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { date, productGroupByGrower } = useSelector(productReducer);
  const toggle = () =>
    dispatch(actionsModal.setCloseModal({ modal: GROWER_INFO_MODAL }));

  const submitSuggestion = (grower: string) => {
    toggle();
    history.push(addObjectParamsToPath('/search', { date, grower: grower })); // submit(
    //   e,
    //   e.currentTarget.innerText.replace(' - ', ' ').replace(/\W/g, ' ')
    // );
  };
  useEffect(() => {
    const newVal = searchValue.toLowerCase().trim();
    const list = productGroupByGrower.filter((item) =>
      JSON.stringify(item)
        .trim()
        .includes(newVal)
    );
    setList(list);
  }, [searchValue, productGroupByGrower]);

  useEffect(() => {
    setList(productGroupByGrower);
  }, [productGroupByGrower]);

  const handleSearchProduct = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setSearchValue(value);
  };

  const GrowerInfoModalBody = () => {
    return (
      <React.Fragment>
        <div className="modal-body background-gray p-0">
          <div className="border border-grey-soft modal-body--max">
            <ul className="list-group list-group-flush">
              {list.map((grower: any) => (
                <li
                  className="list-group-item pointer"
                  key={grower.name}
                  onClick={() => submitSuggestion(grower.key)}>
                  <p className="m-0">
                    <span className="small font-weight-bold">
                      {' '}
                      {grower.name}{' '}
                    </span>
                    <span>
                      <img
                        className="country_flag"
                        src={countryFlag(getCountryFlag(grower.country))}
                        title={getCountryFlag(grower.country)}
                        alt={getCountryFlag(grower.country)}
                        width="36"
                      />
                      {getCountryFlag(grower.country)}
                    </span>
                    <span className="small font-weight-bold">
                      {' '}
                      - Arrival Date:{' '}
                    </span>
                    <span className="small font-weight-bold">
                      {grower.days}
                    </span>
                    <br />
                    <span className="small"> {grower.desc} </span>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const GrowerInfoModalTitle = () => {
    return (
      <Fragment>
        <div className="media">
          <div className="media-body">
            <span>
              <span className="font-weight-bold">Products By Grower</span>
              <br />
              <small>
                Personalized boxes are filled with one Grower’s products.
              </small>
            </span>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <div>
      <Modal size="lg" isOpen={growerInfoModal.open} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <GrowerInfoModalTitle />
        </ModalHeader>
        <ModalBody
          className={`${
            productGroupByGrower.length === 0 ? 'text-center' : ''
          } background-gray`}>
          {productGroupByGrower.length === 0 ? (
            <div className="spinner-border mt-5" style={{ color: '#e83293' }} />
          ) : (
            <>
              <div
                className="d-flex flex-lg-row flex-column"
                style={{ gap: ' 10px ' }}>
                <div className="d-flex align-content-center border bg-white align-items-center mb-2 px-2 flex-grow-1">
                  <FontAwesomeIcon
                    color="#495057"
                    size={'1x'}
                    className="mt-1"
                    icon={faSearch}
                  />
                  <input
                    placeholder={'Type here to search on product group name...'}
                    type="text"
                    className="form-control  border-0"
                    value={searchValue || ''}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      handleSearchProduct(e)
                    }
                  />
                </div>

                <div className="flex-grow-1">
                  <Select
                    options={removeDuplicateFromArrayObj(
                      productGroupByGrower
                        .map((productGroup) =>
                          productGroup.list.map((pg) => ({
                            label: pg.common_name,
                            value: pg._KEY
                          }))
                        )
                        .flat(),
                      'label'
                    ).sort((a, b) => a.label.localeCompare(b.label))}
                    isMulti
                    placeholder={'Select multiple product groups...'}
                    onChange={(value) => {
                      if (value) {
                        if (value.length === 0) {
                          setList(productGroupByGrower);
                        } else {
                          const newList = productGroupByGrower.filter(
                            (item) => {
                              return value.every((evt) =>
                                JSON.stringify(item)
                                  .trim()
                                  .includes(evt.label)
                              );
                            }
                          );
                          setList(newList);
                        }
                      } else {
                        setList(productGroupByGrower);
                      }
                    }}
                  />
                </div>
              </div>

              <GrowerInfoModalBody />
            </>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default GrowerInfoComponent;
