import React from 'react';
import ImageComponent from '../../../Shared/ImageComponent/ImageComponent';
import Environment from '../../../../Environment';
import { useHistory } from 'react-router';
import {
  addObjectParamsToPath,
  queryParamsToObject
} from '../../../../Utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { actionsProduct } from '../../../../Store/Product/Slice';
import Skeleton from 'react-loading-skeleton';
import { authReducer } from '../../../../Store/Selectors';
import { Occasion } from '../../../../Models/Config/Occasion';

type Props = {
  id: number;
  occasion: Occasion | null;
  textClassName?: string;
};
const OccasionCardComponent: React.FC<Props> = ({
  id,
  occasion,
  textClassName
}) => {
  const history = useHistory();
  const { config } = useSelector(authReducer);
  const dispatch = useDispatch();

  const openOccasion = async () => {
    if (occasion?.external_path) {
      window.open(occasion?.external_path, '_blank');
    } else {
      if (occasion?.path && config?.url_filter_keys) {
        const params: any = queryParamsToObject(
          occasion.path,
          config?.url_filter_keys
        );
        // delete params.date;
        dispatch(actionsProduct.setProductDate({ date: params.date ?? 0 }));
        history.push(addObjectParamsToPath('/search', params));
      }
    }
  };

  const getPhoto = () => {
    if (occasion?.fullPhoto) {
      return occasion.fullPhoto;
    }

    return Environment.publicPath + 'images/Occasions/' + occasion?.photo;
  };

  return (
    <div
      onClick={openOccasion}
      key={`occasion_${id}_component`}
      className="card pointer ">
      <div style={{ maxHeight: '180px' }}>
        {occasion ? (
          <ImageComponent
            imageUrl={getPhoto()}
            height={'180px'}
            style={{ objectFit: 'cover' }}
            className="w-100"
          />
        ) : (
          <div className="h-100 w-100">
            <Skeleton height="100%" />
          </div>
        )}
      </div>

      <div className="d-flex flex-column px-3 py-1">
        {occasion ? (
          <span className={textClassName ?? 'font-weight-bold h6'}>
            {occasion?.name}
          </span>
        ) : (
          <Skeleton />
        )}
        {occasion?.description && (
          <span className="ibf-text-gray-cart ibf-font-14">
            {occasion.description}
          </span>
        )}
      </div>
    </div>
  );
};

export default OccasionCardComponent;
