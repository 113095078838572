import { all, call, put, takeEvery } from 'redux-saga/effects';
import { actionsHomeLoading } from './Slice';
import {
  getCategoryMap,
  loadProductGroupsByGrower
} from '../../Services/ProductService';
import { homeLoading, getConfig } from '../../Services/AuthService';
import { getAheadMonths } from '../../Utils/Utils';
import { actionsDashboard } from '../Dashboard/Slice';
import { actionsCheckout } from '../Checkout/Slice';
import { actionsProduct } from '../Product/Slice';
import { actionsShoppingList } from '../ShoppingList/Slice';
import { actionsAuth } from '../Auth/Slice';
import { actionsCalendar } from '../Calendar/Slice';
import { actionsCustomer } from '../Customer/Slice';
import { actionsCart } from '../Cart/Slice';
import { actionsCard } from '../Card/Slice';
import { INIT_HOME_LOADING } from './Types';
import {
  checkSqlCustomerService,
  getArrivalPerGrower,
  updateCustomerInfo,
  updateSqlCustomerService
} from '../../Services/UserService';
import { ProductGroupByGrower } from '../../Models/ProductGroup';
import { ArrivalPerGrower } from '../../Models/Grower';
import { actionsLoading } from '../Loading/Slice';

export function* homeLoadingSaga({
  payload
}: ReturnType<typeof actionsHomeLoading.initHomeLoading>): any {
  try {
    if (!payload) {
      const response = yield call(getCategoryMap);
      const config = yield call(getConfig);
      yield put(actionsDashboard.setHomeCategoryList(response.categories));

      if (config.success && config.data.item.trends) {
        yield put(actionsDashboard.setTrendingList(config.data.item.trends));
      } else {
        // yield put(actionsDashboard.setConfig([]));
      }
    } else {
      const { success, response } = yield call(
        homeLoading,
        payload.customer,
        payload.account_manager
      );

      if (Object.keys(response?.shoppingCartItems?.response).length > 0) {
        yield put(
          actionsCart.setProductsToListCart(response.shoppingCartItems.response)
        );
        yield put(actionsLoading.setAddingProduct(false));
      }

      if (success) {
        if (
          response.cartInfo &&
          response.cartInfo.results &&
          response.cartInfo.results.deletedMessage
        ) {
          localStorage.setItem(
            'deletedMessage',
            response.cartInfo.results.deletedMessage
          );
          localStorage.setItem(
            'expDeletedMessage',
            new Date(new Date().getTime() + 60 * 1000) + ''
          );
        }

        yield put(actionsCheckout.setCheckoutInfo(response.cartInfo));
        if (!response.cartInfo.isEmpty) {
          yield put(
            actionsCheckout.checkoutProducts({
              products: response.cartInfo.results.carts
            })
          );
        }

        yield put(actionsAuth.setManagerInfo(response.accountManager));
        yield response.categoryMap.forEach((category: any) => {
          category.items.unshift({
            name: `All ${category.info.category}`,
            key: category.info._KEY
          });
        });

        const aheadMonths = getAheadMonths();
        yield put(actionsCalendar.setAheadMonths(aheadMonths));
        yield put(actionsCalendar.setTime(response.time));
        yield put(actionsProduct.setCategoryList(response.categoryMap));

        yield put(actionsCustomer.getCurrentAddress(payload));
        yield put(actionsCard.getCards({ creditCards: response.creditCards }));

        //Secondary info
        yield put(actionsCustomer.setCustomerAddresses(response.addresses));
        yield put(
          actionsShoppingList.getShoppingListProcessed(payload.customer)
        );
        //Secondary info

        //Checkout reducer info
        if (response.order) {
          yield put(actionsDashboard.setOrderToShip(response.order));
        }
        if (response.orInfo.orderRequests) {
          yield put(
            actionsCheckout.setOrderRequests(response.orInfo.orderRequests)
          );
        }

        yield put(
          actionsShoppingList.setShoppingListsCheckout(
            response.orInfo.shoppingList
          )
        );

        yield put(actionsCheckout.checkShoppingList());
        yield put(
          actionsCheckout.setIncompleteProducts(
            response.orInfo.incomplete_products
          )
        );
        //Checkout reducer info
        yield put(actionsAuth.setIsFirstRepurchase(response.firstRepurchase));

        yield put(actionsHomeLoading.setResolved());

        const responseValidateSql = yield call(
          checkSqlCustomerService,
          payload.customer_office_email
        );
        if (
          responseValidateSql?.success &&
          responseValidateSql.results.length > 0
        ) {
          const sql = responseValidateSql.results[0];
          yield call(updateCustomerInfo, {
            _KEY: payload.customer,
            sql_invitation: true
          });
          yield call(updateSqlCustomerService, sql._KEY, payload.customer);
        }

        const responseGrower = yield all([
          call(getArrivalPerGrower),
          call(loadProductGroupsByGrower)
        ]);

        if (responseGrower[0]?.success) {
          const listMasterArr: (ProductGroupByGrower & { days: string })[] = [
            ...responseGrower[1].results
          ];

          listMasterArr.forEach((item) => {
            const days: string[] = [];
            Object.keys(responseGrower[0].info).forEach((key) => {
              const grower = (responseGrower[0].info[
                key
              ] as ArrivalPerGrower).countryArrival.find(
                (country) => country._KEY === item.country
              );
              if (grower) {
                days.push(
                  (responseGrower[0].info[key] as ArrivalPerGrower).label
                );
              }
            });

            item.days = days.join(', ');
          });

          yield put(actionsProduct.setProductGroupByGrower(listMasterArr));
        }
      }
    }
  } catch (e) {
    console.error('Error on init home loading');
    yield put(actionsHomeLoading.setRejected());
  }
}

export default function* AllSagas() {
  yield all([takeEvery(INIT_HOME_LOADING, homeLoadingSaga)]);
}
