export enum Types {
  SET_GROWER = 'SET_GROWER',
  SET_GROWER_LENGTHS = 'SET_GROWER_LENGTHS',
  SET_GROWER_OPTIONS = 'SET_GROWER_OPTIONS',
  SET_OPTION_SELECTED = 'SET_OPTION_SELECTED',
  RESET_LENGTHS = 'RESET_LENGTHS'
}

interface ItemAvailabilityOption {
  stem_price?: number;
  price_full_box?: number;
  total_box?: number;
  price_own_box?: number;
  favorite?: boolean;
  available?: number;
  stems_per_bunch?: number;
  length?: number;
  percentage?: 6;
  inventory?: string;
  charge_date?: number;
  packing_date?: number;
  eta_date?: number;
  customizable?: boolean;
}

export interface ProductReducerState {
  grower: { _KEY: string; grower_name?: string; country?: string };
  growerLengths: {
    [index: number]: ItemAvailabilityOption;
  };
  growerOptions: { _KEY: string; grower_name?: string; country?: string }[];
  optionSelected: ItemAvailabilityOption;
}

export function productReducer(
  state: ProductReducerState,
  { type, payload }: any
) {
  switch (type) {
    case Types.SET_GROWER:
      return {
        ...state,

        grower: payload.grower
      };
    case Types.SET_GROWER_LENGTHS:
      return {
        ...state,
        growerLengths: { ...state.growerLengths, ...payload }
      };
    case Types.SET_GROWER_OPTIONS: {
      return {
        ...state,
        growerOptions: payload.growers
      };
    }
    case Types.SET_OPTION_SELECTED: {
      return {
        ...state,
        optionSelected: payload.option
      };
    }
    case Types.RESET_LENGTHS: {
      return {
        ...state,
        growerLengths: {}
      };
    }
    default:
      throw new Error();
  }
}
