import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faHeart } from '@fortawesome/pro-solid-svg-icons';
import FavoriteList from './FavoriteList';
import { RootState } from '../../../Store/Reducers';

type Props = {
  closeMenu: () => void;
  openMenu: () => void;
};

const FavoriteListComponent: React.FC<Props> = ({ closeMenu, openMenu }) => {
  const { navOptions } = useSelector(
    (state: RootState) => state.DisplayReducer
  );

  const { favorites } = useSelector((state: RootState) => state.ProductReducer);

  const toggleMenu = () => {
    if (navOptions && navOptions.favorites) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  return (
    <>
      <span className="ibf-text-navbar d-none d-sm-inline">
        <span onClick={toggleMenu} className="pointer">
          <FontAwesomeIcon icon={faHeart} className="mr-1" />
          Favorites ({Object.keys(favorites).length})
          <FontAwesomeIcon
            className="ml-1"
            color="gray"
            icon={faChevronDown}
          />{' '}
        </span>{' '}
      </span>
      {navOptions.favorites && <FavoriteList toggleMenu={toggleMenu} />}
    </>
  );
};

export default FavoriteListComponent;
