import { faCalendar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { InputGroup, InputGroupAddon } from 'reactstrap';

const CalendarInputComponent = (props: any) => {
  const calendarInput = useRef<HTMLInputElement>(null);
  const buttonGroupHandler = () => {
    if (calendarInput?.current) {
      calendarInput.current.focus();
    }
  };

  return (
    <InputGroup className="group-input-append mt-2">
      <input
        ref={calendarInput}
        {...props}
        type="text"
        className="form-control"
      />
      <InputGroupAddon
        onClick={buttonGroupHandler}
        addonType="append"
        className="clickable border-left-0 p-2">
        <FontAwesomeIcon icon={faCalendar} />
      </InputGroupAddon>
    </InputGroup>
  );
};

export default CalendarInputComponent;
