import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import ImageComponent from './ImageComponent/ImageComponent';
import { removeTo } from '../../Utils/Utils';

type Props = {
  show: boolean;
  body?: string;
  flowerImg?: string;
  flowerImgs?: Array<string>;
  titleToast: string;
  productKey: string;
  navHeight?: number;
};

const AddToCartToast: React.FC<Props> = ({
  show,
  body,
  flowerImg,
  titleToast,
  productKey,
  navHeight,
  flowerImgs
}: Props) => {
  const [progressPercentage, setProgressPercentage] = useState<number>(100);
  const key = useRef(productKey);

  useEffect(() => {
    setProgressPercentage(100);
    return () => {
      key.current = '';
    };
  }, []);

  useEffect(() => {
    if (progressPercentage === 0) {
      removeTo(key.current);
    } else {
      if (progressPercentage > 0 && show) {
        let timer = setInterval(() => {
          try {
            setProgressPercentage((percentage) => percentage - 1);
          } catch (e) {
            clearInterval(timer);
          }
        }, 25);
        return () => clearInterval(timer);
      }
    }

    return () => {};
  }, [show, progressPercentage]);

  const listItems =
    flowerImgs && flowerImgs.length > 0
      ? flowerImgs.map(
          (img) =>
            img && (
              <ImageComponent
                style={{ margin: '2px' }}
                imageUrl={img}
                imgSize={'60px'}
                rounded={true}
              />
            )
        )
      : [];
  return (
    <div
      style={{ top: `${navHeight ? navHeight + 60 : 60}px` }}
      className={`container toast-size shadow ibf-border ibf-toast-add-to-cart
       ${show ? 'ibf-toast-visible' : 'ibf-toast-not-visible'}`}>
      {show && (
        <>
          <div className="progress ibf-toast-cart">
            <div
              className="progress-bar"
              style={{
                width: `${progressPercentage}%`,
                backgroundColor: 'green',
                transition: 'width 0.3s'
              }}
            />
          </div>
          <div className="d-flex justify-content-between pt-2 mb-1">
            <span className="ibf-text-toast-success">
              <FontAwesomeIcon className="color-green" icon={faCheck} />
              {titleToast}
            </span>

            <div>
              <span
                onClick={() => removeTo(key.current)}
                className="ibf-circle-icon rounded-circle">
                X
              </span>
            </div>
          </div>
          {(!flowerImgs || !flowerImgs.length) && flowerImg && (
            <div>
              <ImageComponent
                imageUrl={flowerImg}
                imgSize={'60px'}
                rounded={true}
              />
              <span className="ibf-text-card text-black ml-2"> {body} </span>
            </div>
          )}
          {flowerImgs && flowerImgs.length > 0 && listItems}
        </>
      )}
    </div>
  );
};

export default AddToCartToast;
