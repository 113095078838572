import React from 'react';
import { useSelector } from 'react-redux';
import StoreType from '../../../Models/Redux/Store';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router';

const ReferralMessageComponent = () => {
  const { authLoading } = useSelector(
    (store: StoreType) => store.LoadingReducer
  );

  const history = useHistory();

  return (
    <div
      className="card d-flex flex-column flex-grow-1 p-3 pointer"
      onClick={() => history.push('/referral')}>
      <h5 className="font-weight-bold">
        {authLoading ? <Skeleton /> : '$15 Referral program'}
      </h5>

      {authLoading ? (
        <Skeleton />
      ) : (
        <span>
          Get $ 15 off your next order for every new company that uses your
          coupon!
        </span>
      )}
    </div>
  );
};

export default ReferralMessageComponent;
