import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Store/Reducers';
import { actionsModal } from '../../../Store/Modal/Slice';
import * as ModalTypes from '../../../Store/Modal/Types';
import CartCustomBoxItem from './CartCustomBoxItem';

type Props = {
  className?: string;
};

const CartModal: React.FC<Props> = ({ className }) => {
  const { CART_MODAL } = useSelector((state: RootState) => state.ModalReducer);
  const { currentBox, currentPercentage } = useSelector(
    (state: RootState) => state.CustomBoxReducer
  );
  const dispatch = useDispatch();

  const toggle = () =>
    dispatch(actionsModal.setCloseModal({ modal: ModalTypes.CART_MODAL }));
  const externalCloseButton = (
    <span onClick={toggle} className="ibf-circle-icon rounded-circle">
      {' '}
      X{' '}
    </span>
  );
  return (
    <div>
      <Modal isOpen={CART_MODAL.open} className={className}>
        <ModalHeader close={externalCloseButton}>
          Details of this box ({currentBox?.items?.length}{' '}
          {currentBox?.items?.length > 1 ? 'items' : 'item'})
        </ModalHeader>
        <ModalBody>
          {currentBox?.items?.map((item: any, index: number) => (
            <CartCustomBoxItem
              boxItem={item}
              etaDate={currentBox.eta_date}
              percentage={currentPercentage}
              key={`custom_box_cart_item_${index}`}
            />
          ))}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CartModal;
