import React from 'react';
import FilterItem from './../../../../../Models/FilterItem';

type Props = {
  grower: FilterItem;
  selectGrower: () => void;
};

const GrowerOption: React.FC<Props> = ({ grower, selectGrower }) => {
  return (
    <div>
      <button className="btn btn-primary mr-2 mt-2" onClick={selectGrower}>
        {grower.value}
      </button>
    </div>
  );
};

export default GrowerOption;
