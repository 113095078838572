import React, { useEffect, useState } from 'react';
import {
  Dropdown
  /*  Menu  */
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { actionsCustomer } from '../../../../Store/Customer/Slice';
import { authReducer, customerReducer } from '../../../../Store/Selectors';
/* import { ADDRESSES_MODAL } from '../../../../Store/Modal/Types';
import { actionsModal } from '../../../../Store/Modal/Slice'; */
import AddressListContent from './AddressListContent';
import ZipcodeService from '../../../../Services/ZipcodeService';
import { PropertiesStateZipcode } from '../../../../Models/CustomerModel';

interface Iprops {
  setShow?: (setShow: boolean) => void;
  show?: boolean;
}

const AddressListComponent = ({ setShow, show }: Iprops) => {
  const {
    /* addressList,  */

    selectedAddress,
    visibleDropdown
  } = useSelector(customerReducer);
  const {
    user: {
      metadata: { customer_zipcode }
    }
  } = useSelector(authReducer);
  const [states, setStates] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {
    Object.values(ZipcodeService._STATES_WITH_ZIPCODES).forEach(
      (item: PropertiesStateZipcode) => {
        for (const iterator of item.codes) {
          if (iterator === customer_zipcode) {
            let dataState = ZipcodeService.getStateMap();
            Object.entries(dataState).forEach(([key, value]) => {
              if (item.name === value) {
                setStates(key);
              }
            });
            break;
          }
        }
      }
    );
  }, [customer_zipcode]);

  return (
    <>
      <div className="d-flex bg-white">
        <span className="ibf-address-icon pl-3 pl-lg-0">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </span>
        <Dropdown
          trigger={['click']}
          overlay={<AddressListContent show={setShow} states={states} />}
          visible={visibleDropdown}
          overlayClassName="ibf-dropdown-size text-break"
          placement={`bottomRight`}
          onVisibleChange={() => {
            dispatch(actionsCustomer.setVisibleDropdown());
            setShow && setShow(!show);
          }}
          className="pointer">
          <input
            autoComplete={'off'}
            className="w-100 px-1  ibf-font-15 "
            readOnly
            value={`Deliver to: ${selectedAddress?.state?.abbr ??
              states} ${selectedAddress.zipcode ?? ''}`}
            data-testid="addressInput"
            type="text"
            aria-describedby="basic-addon2"
            style={{
              border: 0,
              outline: 'none',
              color: '#6c757d',
              height: '2.2em',
              padding: '0px 5px'
            }}
          />
        </Dropdown>
      </div>
    </>
  );
};

export default AddressListComponent;
