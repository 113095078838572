import { State } from '../../Models/State';

export const SET_NEXT_DAY = 'calendar/setNextDay';
export const INIT_CALENDAR = 'calendar/initCalendar';
export const GET_TOTAL_PRODUCTS_BY_DATE = 'calendar/getTotalProductByDate';

export interface CalendarState {
  disabledDays: any[];
  modifiers: { [key: string]: any };
  time: number;
  nextDay: number;
  modifiersStyles: { [key: string]: any };
  aheadMonths: any;
  state: State;
  totalProductByDate: { [eta: number]: number };
  showOverlay: boolean;
  daysOfWeek: { [key: string]: number };
  loadingDaysOfWeek: boolean;
  showAirlineCalendar: boolean;
}
