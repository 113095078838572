import React, { useEffect, useState } from 'react';
import Product from '../../../../../Models/Product';
import { GrowerPricing } from '../../../../../Models/GrowerPricing';
import { roundUp } from '../../../../../Utils/Utils';

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

type Props = {
  grower: GrowerPricing;
  // growerLengths: JSX.Element;
  contentTab1?: JSX.Element;
  contentTab2?: JSX.Element;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  product: Product;
  boxOnly?: boolean;
  isAList?: boolean;
  boxesAvailable: Array<number>;
  isProductDetail: boolean;
};

export default function TabsProductPrice({
  grower,
  contentTab1,
  //growerLengths,
  contentTab2,
  activeTab,
  setActiveTab,
  product,
  boxOnly,
  isAList,
  boxesAvailable,
  isProductDetail
}: Props) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (boxesAvailable.length && !active) {
      setActiveTab('1');
    } else {
      setActiveTab('2');
    }
  }, [boxesAvailable, setActiveTab, active]);

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="mt-2">
      {
        <>
          <Nav
            // tabs
            className={`d-flex flex-nowrap ibf-font-12 border-bottom-0 w-100`}>
            <NavItem className={`w-50`}>
              <NavLink
                disabled={boxesAvailable.length === 0}
                className={`text-nowrap  text-ash-gray  pl-0 
                ${
                  boxesAvailable.length === 0
                    ? 'border-top-0 font-weight-light text-gray-lighter cursor-auto '
                    : 'border-top font-weight-bold text-black underline'
                }
                  ${activeTab === '1' ? 'border-radius-t-right' : ''}
                  ${isAList ? 'border-left' : 'border-left-0'}  ${activeTab ===
                  '1' &&
                  ' text-decoration-none active  border-right ibf-text-line-height-2'} ${activeTab ===
                  '2' && 'mt-2 border-bottom'} ${
                  isAList ? 'border-left' : 'border-left-0'
                } `}
                onClick={() => {
                  setActive(false);
                  toggle('1');
                }}>
                <div className="ml-2">
                  {boxesAvailable.length === 0
                    ? '(Box not available)'
                    : `Box ($${roundUp(
                        //grower.stem_price
                        grower.price_full_box / grower.total_box
                      )}/bu)`}
                </div>
              </NavLink>
            </NavItem>
            <NavItem className={`w-50`}>
              <NavLink
                disabled={!!boxOnly}
                className={`text-nowrap text-ash-gray  ${
                  boxOnly
                    ? 'border-top-0 pl-1 text-gray-lighter cursor-auto '
                    : 'border-top underline  pl-2 text-black '
                } ${
                  isAList ? 'border-right' : 'border-right-0'
                }  border-radius-t-right  d-flex flex-grow-1  ${activeTab ===
                  '2' &&
                  `${
                    boxOnly ? ' font-weight-light ' : ' font-weight-bold'
                  } text-decoration-none active ibf-text-line-height-2
                              ${
                                activeTab === '2'
                                  ? 'border-left'
                                  : 'border-left-0'
                              }`} ${activeTab === '1' &&
                  'mt-2 border-bottom '}`}
                onClick={() => {
                  setActive(true);

                  toggle('2');
                }}>
                {boxOnly
                  ? '(Bunch not available)'
                  : `Bunch ($${roundUp(grower.price_own_box)}/bu)  `}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className={`mb-2 ${isProductDetail ? 'p-2' : ''}`}>
                {/* {growerLengths} */}
              </div>

              {contentTab1}
            </TabPane>
            <TabPane tabId="2">
              <div className={`mb-2 ${isProductDetail ? 'p-2' : ''}`}>
                {/* {growerLengths} */}
              </div>

              {contentTab2}
            </TabPane>
          </TabContent>
        </>
      }
    </div>
  );
}
