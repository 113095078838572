import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getManagerInfo } from '../../../../Services/UserService';
import { toast } from '../../../../Utils/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import {
  faCommentAltLines,
  faQuestionCircle
} from '@fortawesome/pro-light-svg-icons';
import ImageComponent from '../../ImageComponent/ImageComponent';
import Environment from '../../../../Environment';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { ACCOUNT_MANAGER_MODAL } from '../../../../Store/Modal/Types';
import { authReducer, modalReducer } from '../../../../Store/Selectors';

const AccountManagerComponent = () => {
  const dispatch = useDispatch();
  const toggle = () => {
    dispatch(actionsModal.setCloseModal({ modal: ACCOUNT_MANAGER_MODAL }));
  };

  const { accountManagerModal } = useSelector(modalReducer);
  const [managerData, setManagerData] = useState({
    name: '',
    email: '',
    email_alias: '',
    phone: '',
    Extension: ''
  });

  const [state, setState] = useState('IDLE');

  const {
    manager,
    user: {
      metadata: { account_manager }
    }
  } = useSelector(authReducer);

  useEffect(() => {
    setState('PENDING');
    const getManager = async () => {
      const res = await getManagerInfo(account_manager);

      if (res?.success) {
        let box = res?.box;
        setManagerData({
          name: box?.name,
          email: box?.email,
          email_alias: box?.email_alias,
          phone: box?.phone,
          Extension: box?.Extension
        });

        setState('RESOLVED');
      } else {
        toast(res?.error || 'An error ocurred', 'error');
        setState('REJECTED');
      }
    };
    getManager();
  }, [account_manager]);

  return (
    <Modal size={'lg'} centered isOpen={accountManagerModal.open}>
      <ModalBody className={state !== 'RESOLVED' ? 'text-center' : ''}>
        <ModalHeader toggle={toggle} className="pt-0">
          Contact your account manager
        </ModalHeader>

        {state !== 'RESOLVED' ? (
          <div className="spinner-border mt-5" style={{ color: '#e83293' }} />
        ) : (
          <div>
            <div className="media mt-2">
              <ImageComponent
                imgSize="90"
                imageUrl={`${Environment.publicPath}images/${manager &&
                  manager.email &&
                  manager.email.split('@')[0]}.png`}
                className="mr-2"
              />

              <div className="media-body align-self-center">
                <strong>{managerData.name}</strong>
                <span className="d-block">
                  <FontAwesomeIcon icon={faPhone} className="mr-2" />
                  <a
                    className="primary-text-color"
                    href={`tel:${managerData?.phone}p${managerData?.Extension}`}>
                    Call {`${managerData?.phone} ext ${managerData.Extension}`}
                  </a>
                </span>
                <span className="d-block">
                  <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                  <a
                    className="primary-text-color"
                    href={
                      'mailto:' + managerData.email_alias ?? managerData.email
                    }>
                    {managerData.email_alias ?? managerData.email}
                  </a>
                </span>
              </div>
            </div>

            <div className="manager-separator my-3">
              <span className="px-2">OR</span>
            </div>

            <div className="row">
              <div className="col-6">
                <span
                  className="bg-soft clickable manager-card d-flex justify-content-center align-items-center"
                  id="show-manager-itercom">
                  <div className="text-primary text-center">
                    <FontAwesomeIcon
                      icon={faCommentAltLines}
                      size="2x"
                      color={'#e83293'}
                      className="d-block mb-1"
                    />
                    <span className="pointer primary-text-color">Chat</span>
                  </div>
                </span>
              </div>
              <div className="col-6">
                <a
                  className="bg-soft manager-card d-flex justify-content-center align-items-center"
                  href="http://help.ibuyflowers.com/"
                  id="show-manager-help"
                  rel="noopener noreferrer"
                  target="_blank">
                  <div className="text-primary text-center">
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      color={'#e83293'}
                      size="2x"
                      className="d-block mb-1 mx-auto"
                    />
                    <span className="primary-text-color">Help center</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default AccountManagerComponent;
