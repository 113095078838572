import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../Models/State';
import MixpanelService from '../../../../Services/MixpanelService';
import Environment from './../../../../Environment';
import { appendTo, getBunchesAvailable, toast } from '../../../../Utils/Utils';
import AddToCartToast from './../../../Shared/AddToCartToast';
import { RootState } from '../../../../Store/Reducers';
import { actionsCustomBox } from '../../../../Store/CustomBox/Slice';
import { TrackEventMonitor } from '../../../../Classes/TrackEvent';

type Props = {
  buttonName: string;
  setListSize: any;
  product: any;
  listSize: any;
};

const BoxProductFormComponent: React.FC<Props> = ({
  setListSize,
  listSize,
  buttonName,
  product
}) => {
  const { state, isAList, currentPercentage, currentBox } = useSelector(
    (state: RootState) => state.CustomBoxReducer
  );
  const { user } = useSelector((state: RootState) => state.AuthReducer);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(State.IDLE);
  const getListValue = (e: any) => {
    setListSize(e.target.value);
  };

  useEffect(() => {
    state === State.RESOLVED && setIsLoading(State.RESOLVED);
  }, [state]);

  const addProductToBox = () => {
    MixpanelService.track('add_to_cart_filling_box', {
      name: product.name,
      stems: listSize * product.stems_per_bunch
    });
    const imageProduct = Environment.publicPath + product.images[0];
    setIsLoading(State.PENDING);
    appendTo(
      'body-modal',
      product.inventory,
      <AddToCartToast
        titleToast="Adding to your cart"
        show
        body={`${product.name} ${listSize * product.stems_per_bunch} stems`}
        flowerImg={imageProduct}
        productKey={product.inventory}
      />
    );

    toast(
      `Product with ${parseInt(listSize) *
        product.stems_per_bunch} stems is being added.`
    );
    dispatch(
      actionsCustomBox.addToBox({
        boxParams: {
          customer: user.metadata.customer,
          invKey: product.inventory,
          arrival_date: product.eta,
          charge_date: product.charge_date,
          packing_date: product.packing_date,
          quantity: parseInt(listSize)
        },
        percentage: currentPercentage + product.custom_percentage * listSize
      })
    );

    if (window.dataLayer) {
      window.dataLayer.push({ event: 'Add to cart', value: '' });
    }

    TrackEventMonitor.newEvent({
      props: {
        grower_key: product.grower,
        percentage: currentPercentage + product.custom_percentage * listSize,
        inventory_key: product.inventory,
        arrival_date: product.eta,
        charge_date: product.charge_date,
        packing_date: product.packing_date,
        quantity: parseInt(listSize),
        custom_box_modal: true,
        cart_box_key: currentBox?._KEY ?? ''
      },
      metadata: {
        name: 'ibf_add_to_box'
      }
    });
  };

  const bunchesAvailable = getBunchesAvailable(
    product.available,
    product.custom_percentage,
    currentPercentage
  );

  return (
    <>
      <div
        className={`d-flex flex-wrap align-items-center justify-content-center pt-2 ${
          isAList ? `` : 'pb-3 px-1'
        }`}>
        <select
          id="size-list"
          onChange={getListValue}
          className={`d-flex form-control form-control-sm mr-2`}
          style={{ maxWidth: '142px', paddingLeft: '0' }}>
          {Array(bunchesAvailable)
            .fill(1)
            .map((i, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1 + `${index + 1 > 1 ? ' Bunches' : ' Bunch'}`} (
                {(index + 1) * product.stems_per_bunch +
                  `${
                    (index + 1) * product.stems_per_bunch > 1
                      ? ' stems'
                      : ' stem'
                  }`}
                )
              </option>
            ))}
        </select>

        <button
          onClick={addProductToBox}
          disabled={state === State.PENDING}
          className={` 
            ${
              isAList
                ? `btn btn-primary btn-sm ibf-btn-form-list justify-content-center`
                : 'btn btn-primary btn-sm ibf-btn-form'
            }`}>
          {isLoading === State.PENDING ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true">
                {' '}
              </span>
              <span className="sr-only">Loading...</span>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faCartPlus} /> <span>{buttonName}</span>
            </>
          )}
        </button>
      </div>
    </>
  );
};

export default BoxProductFormComponent;
