import React from 'react';
import { Button } from 'antd';

type Props = {
  stemList: any;
};

const StemHelperPopover: React.FC<Props> = ({ stemList }) => {
  const stemsQuantity = [
    '10 stems',
    '70 stems',
    '15 stems',
    '80 stems',
    '20 stems',
    '90 stems',
    '25 stems',
    '100 stems',
    '30 stems',
    '200 stems',
    '40 stems',
    '500 stems',
    '50 stems',
    '750 stems',
    '60 stems',
    '1000 stems'
  ];

  return (
    <>
      <div className="d-flex flex-wrap" style={{ maxWidth: '12em' }}>
        {stemsQuantity.map((stems, index) => {
          return (
            <Button key={index + '-stem'} onMouseDown={() => stemList(stems)}>
              {' '}
              {stems}{' '}
            </Button>
          );
        })}
      </div>
    </>
  );
};

export default StemHelperPopover;
