import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  addObjectParamsToPath,
  convertDateToNumberDate,
  convertNumberDateToDate,
  hrefContainsProperty,
  toast
} from '../../../Utils/Utils';
import AddressListComponent from './AddressListComponent/AddressListComponent';
import InputDateComponent from './InputDateComponent/InputDateComponent';
import { Popover } from 'antd';
import SearchTipPopover from '../../Shared/PopOver/SearchTipPopover/SearchTipPopover';
import SuggestionListComponent from './SuggestionListComponent';
import CleanSearchButtonComponent from './CleanSearchButtonComponent';
import MixpanelService from '../../../Services/MixpanelService';
import { actionsFilter } from '../../../Store/Filter/Slice';

import {
  authReducer,
  displayReducer,
  filterReducer,
  productReducer
} from '../../../Store/Selectors';
import useDeviceDetect from '../../../Hooks/useDeviceDetect';
import ZipcodeService from '../../../Services/ZipcodeService';
import OverlayComponent from '../../Shared/OverlayComponent';

const SearchInput: React.FC = () => {
  const { date, numberWeek } = useSelector(productReducer);
  const { search, filters_conditions } = useSelector(filterReducer);
  const { secondTip } = useSelector(displayReducer);
  const { user } = useSelector(authReducer);
  const ref = useRef<HTMLInputElement>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isMobile } = useDeviceDetect();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const onSubmit = async ({
    e,
    s,
    queryParams = {}
  }: {
    e?: React.FormEvent | null;
    s?: string;
    queryParams?: { [key: string]: string | number };
  }) => {
    if (e) {
      e.preventDefault();
    }
    if (ref && ref.current) {
      ref.current.blur();
    }
    const searchVal = s || search;
    if (
      searchVal &&
      ZipcodeService._STATES_WITH_ZIPCODES.HI.codes.includes(
        user.metadata.customer_main_zipcode
      ) &&
      searchVal.toLowerCase().includes('eucalyptus')
    ) {
      toast('This product is not available in this moment.', 'error');
      return;
    }
    const date1 = new Date();
    const date2 = convertNumberDateToDate(date);

    const Difference_In_Time = date2.getTime() - date1.getTime();
    const daysAhead = Difference_In_Time / (1000 * 3600 * 24);
    const today = convertDateToNumberDate(new Date());

    MixpanelService.track('selected_date', { daysAhead, today, date });
    MixpanelService.track('search', { search });

    // TrackEventMonitor.newEvent({
    //   props: {
    //     search,
    //     date_selected: Number(date)
    //   },
    //   metadata: {
    //     name: 'ibf_product_search'
    //   }
    // });

    let params: { [key: string]: string | number } = {
      date,
      search_val: searchVal,
      search_type: e ? 'text' : 'suggest'
    };
    if (hrefContainsProperty('week') || filters_conditions.week) {
      params.week = numberWeek;
    }

    if (queryParams) {
      params = { ...params, ...queryParams };
    }

    if (
      (params.product_group || params.variety || params.category) &&
      params.search_val
    ) {
      delete params.search_val;
    }

    history.push(addObjectParamsToPath('/search', params));
  };

  return (
    <>
      {show2 && (
        <OverlayComponent
          height={'100%'}
          width={'100vw'}
          zIndex={2}
          backgroundColor={'rgb(0 0 0 / 25%)'}
        />
      )}
      <div>
        <div
          className="ibf-search-grid mb-3 mb-lg-0"
          style={isMobile ? { marginLeft: '1px', paddingRight: '1px' } : {}}>
          <div
            className={`input-group search-input ${
              isMobile ? 'border-bottom' : ''
            }`}>
            <div className="input-group-append">
              <button
                onClick={() => onSubmit({})}
                className={
                  isMobile ? 'btn bg-white' : 'btn bg-white ibf-rounded-left'
                }
                style={
                  isMobile ? {} : { border: '1px solid #E7E2DF', zIndex: 3 }
                }>
                <FontAwesomeIcon
                  color="#495057"
                  icon={faSearch}
                  style={{ zIndex: 3 }}
                />
              </button>
            </div>
            <Popover
              placement={isMobile ? 'bottom' : 'rightTop'}
              overlayClassName={
                isMobile
                  ? 'ibf-search-tip-popover-mobile'
                  : 'ibf-search-tip-popover'
              }
              content={<SearchTipPopover />}
              visible={
                secondTip &&
                !user.metadata.preferences['hideSearchTip'] &&
                location.pathname === '/home'
              }>
              <input
                data-testid="searchInput"
                type="text"
                value={search}
                ref={ref}
                onBlur={() => {
                  setShowSuggestions(false);
                  setShow2(false);
                }}
                onFocus={() => {
                  setShow2(true);
                  setShowSuggestions(true);
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    // toast('Please click an item list to search.', 'info');
                    onSubmit({ e });
                  }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch(actionsFilter.setSearch({ search: e.target.value }));
                }}
                className={`form-control ${
                  isMobile ? 'ibf-search-input pl-0 border-0' : ''
                }`}
                placeholder={`Search 8000+ flowers ${
                  isMobile ? '' : '(Press Enter to see all results)'
                }`}
                aria-label={`Search 8000+ flowers ${
                  isMobile ? '' : '(Press Enter to see all results)'
                }`}
                aria-describedby="basic-addon2"
                style={
                  isMobile ? {} : { borderRight: 0, borderLeft: 0, zIndex: 3 }
                }
              />
            </Popover>
            {showSuggestions && (
              <SuggestionListComponent
                submit={({ queryParams }) =>
                  onSubmit(queryParams ? { queryParams } : {})
                }
                width={
                  ref.current
                    ? isMobile
                      ? '100%'
                      : ref.current.offsetWidth
                    : 0
                }
              />
            )}

            <div className={isMobile ? '' : 'input-group-prepend'}>
              <CleanSearchButtonComponent />
            </div>
          </div>

          {/*<div className={`${isMobile ? " form-control w-100" : ""} ibf-search-second-row `}>*/}
          <div
            style={show ? { zIndex: 5 } : {}}
            className={`d-flex align-items-center search-address ${
              isMobile ? '' : 'form-control'
            }`}>
            <AddressListComponent setShow={setShow} show={show} />
          </div>
          {show && (
            <OverlayComponent
              height={'100%'}
              width={'100%'}
              zIndex={4}
              backgroundColor={'rgb(0 0 0 / 25%)'}
              onCLick={() => setShow(false)}
            />
          )}
          <InputDateComponent />

          {/*</div>*/}
        </div>
      </div>
    </>
  );
};

export default SearchInput;
