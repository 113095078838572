import React from 'react';
import GrowerOption from './GrowerOption';
import { useDispatch, useSelector } from 'react-redux';
import MixpanelService from './../../../../../Services/MixpanelService';
import FilterItem from './../../../../../Models/FilterItem';
import Filter from '../../../../../Models/Filter';
import {
  addObjectParamsToPath,
  convertDateToStantard,
  getFiltersKeys
} from '../../../../../Utils/Utils';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../../../Store/Reducers';
import { actionsFilter } from '../../../../../Store/Filter/Slice';

const GrowerOptionList = () => {
  const { date, filters: reduxFilters } = useSelector(
    (state: RootState) => state.ProductReducer
  );

  const { availableGrowers, search, filters_conditions } = useSelector(
    (state: RootState) => state.FilterReducer
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const setFilter = (itemId: string) => {
    const filters = reduxFilters.map((filter: Filter) => {
      if ('Growers' === filter.label) {
        filter.values.forEach((item: FilterItem) => {
          if (item.key === itemId) {
            MixpanelService.track(
              `filter_${filter.label.toLowerCase()}_${item.value}`
            );
            item.selected = !item.selected;
          } else {
            if (!itemId || (item.selected && item.key !== itemId)) {
              item.selected = false;
            }
          }
        });
      }
      return filter;
    });

    const filtersKeys = getFiltersKeys(filters);
    const params = {
      date,
      search_val: search,
      desc: filters_conditions.desc,
      ...filtersKeys
    };
    history.push(addObjectParamsToPath('/search', params));
  };

  const selectGrower = (grower: FilterItem) => {
    if (grower.key) {
      dispatch(
        actionsFilter.setFilters({ ...filters_conditions, grower: grower.key })
      );
    }
    dispatch(
      actionsFilter.setSelectedGrower({ name: grower.value, key: grower.key })
    );
    setFilter(grower.key);
    const filters = { grower: [grower.key] };
    const params = { date, search_val: search, ...filters };
    history.push(addObjectParamsToPath('/search', params));
  };

  return (
    <div className="d-flex flex-column mt-3">
      <span className="font-weight-bold">
        Or, shop for {convertDateToStantard(date.toString()).slice(0, 5)} from
        another grower:
      </span>
      <div className="d-flex flex-wrap mt-2">
        {availableGrowers.map((grower, index: number) => (
          <GrowerOption
            key={'grower_' + index}
            grower={grower}
            selectGrower={() => selectGrower(grower)}
          />
        ))}
      </div>
    </div>
  );
};

export default GrowerOptionList;
