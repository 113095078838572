import React, { useEffect } from 'react';
import FooterComponent from './FooterComponent/FooterComponent';
import StandingOrderComponent from './StandingOrderComponent/StandingOrderComponent';
import HomeInformationComponent from './HomeInformationComponent/HomeInformationComponent';
import ReferralMessageComponent from './ReferralMessageComponent/ReferralMessageComponent';
import UspMobileAndDesktopView from '../UpsComponent/UspMobileAndDesktopView';
import { authReducer } from '../../Store/Selectors';
import { useSelector } from 'react-redux';
import { State } from '../../Models/State';
import ColorRangeComponent from './ColorRangeComponent/ColorRangeComponent';
import OccasionComponent from './OcassionComponent/OccasionComponent';
import CategoriesWithProductGroups from './CategoriesWithProductGroups';

const HomeComponent = () => {
  const { user, state } = useSelector(authReducer);

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={`ibf-home-container 
                    ${
                      state === State.RESOLVED &&
                      (!user.metadata?.n_orders ||
                        user?.metadata?.n_orders <= 6) &&
                      user?.metadata?.guest &&
                      window?.innerWidth < 800
                        ? 'pt-3'
                        : ''
                    }`}>
      <HomeInformationComponent />
      {/*<UpdateAndFeedBackComponent />*/}
      {/*<div className="ibf-home-categories-post">*/}

      {/*  <CategoryComponent />*/}
      {/*</div>*/}

      <div className="mt-5">
        <OccasionComponent />
      </div>

      <div className="mx-auto my-5 px-3 px-lg-0" style={{ maxWidth: '1500px' }}>
        <ColorRangeComponent />
      </div>

      <div
        className="align-items-center d-flex justify-content-center mx-auto px-3 px-lg-0"
        style={{ gap: '15px', maxWidth: '1500px' }}>
        <ReferralMessageComponent />
        <StandingOrderComponent />
      </div>

      <hr className="w-100" />

      <div className="mx-auto my-3" style={{ maxWidth: '1500px' }}>
        <UspMobileAndDesktopView />
        {user.metadata.customer && <CategoriesWithProductGroups />}
      </div>

      <FooterComponent />
    </div>
  );
};

export default HomeComponent;
