import React, { useEffect, useRef, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  addObjectParamsToPath,
  convertDateToNumberDate,
  convertNumberDateMMDDYYYY,
  selectDate
} from '../../../../Utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import CalendarComponent from '../../../Shared/Calendar/CalendarComponent';
import CalendarMobileComponent from '../../../Shared/Calendar/CalendarMobileComponent';
import { useHistory, useLocation } from 'react-router-dom';
import { actionsProduct } from '../../../../Store/Product/Slice';
import { RootState } from '../../../../Store/Reducers';
import { actionsDisplay } from '../../../../Store/Display/Slice';
import { authReducer } from '../../../../Store/Selectors';
import useDeviceDetect from '../../../../Hooks/useDeviceDetect';
import OverlayComponent from '../../../Shared/OverlayComponent';
import { actionsCalendar } from '../../../../Store/Calendar/Slice';

const FORMAT = 'Select a date';

const InputDateComponent = () => {
  const [deliveryDate, setDeliveryDate] = useState('');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { config } = useSelector(authReducer);
  const { date } = useSelector((state: RootState) => state.ProductReducer);
  const { isMobile } = useDeviceDetect();
  const [show, setShow] = useState(false);
  const { search, filters_conditions } = useSelector(
    (state: RootState) => state.FilterReducer
  );
  const {
    modifiersStyles,
    disabledDays,
    modifiers,
    aheadMonths,
    showOverlay
  } = useSelector((state: RootState) => state.CalendarReducer);
  const handleDate = (handleDate: Date) => {
    let pathname = location.pathname;

    const params = selectDate(
      handleDate,
      search,
      filters_conditions,
      date,
      pathname
    );

    setDeliveryDate(convertNumberDateMMDDYYYY(handleDate));
    dispatch(actionsDisplay.setScrollPosition(0));
    dispatch(
      actionsProduct.setProductDate({
        date: convertDateToNumberDate(handleDate)
      })
    );
    setShow(false);
    history.push(addObjectParamsToPath(params.pathname, params.search));
    dispatch(actionsCalendar.setShowOverlay(false));
  };

  useEffect(() => {
    date && setDeliveryDate(convertNumberDateMMDDYYYY(date));
  }, [date]);

  //ref for input desktop and mobile
  const dayPickerInputDesktopRef = useRef<DayPickerInput>(null);
  const dayPickerInputMobileRef = useRef<DayPickerInput>(null);

  //close date picker input desktop and mobile
  const closeDayPickerDesktopInput = () => {
    if (dayPickerInputDesktopRef && dayPickerInputDesktopRef.current) {
      dayPickerInputDesktopRef.current.hideDayPicker();
      // dayPickerInputDesktopRef.current.showDayPicker();
      setShow(false);
      dispatch(actionsCalendar.setShowOverlay(false));
    }
  };

  const closeDayPickerMobileInput2 = () => {
    if (dayPickerInputMobileRef && dayPickerInputMobileRef.current) {
      dayPickerInputMobileRef.current.hideDayPicker();
      dispatch(actionsCalendar.setShowOverlay(false));
    }
  };

  const getLabelDate = (date: number) => {
    if (config.especial_days) {
      const specialDate = config.especial_days.find((especialDays) =>
        especialDays.dates.find((especialDate) => especialDate === date)
      );
      if (specialDate) {
        return specialDate.label;
      }
    }
    return '';
  };

  const renderDay = (day: Date) => {
    const date = convertNumberDateMMDDYYYY(convertDateToNumberDate(day));
    const dateNumber = day.getDate();

    const today = convertNumberDateMMDDYYYY(new Date());

    const label = getLabelDate(convertDateToNumberDate(day));
    return (
      <div
        style={
          deliveryDate === date
            ? { padding: '0.5em', width: '100%', border: '1px solid black' }
            : { padding: '0.2em', width: '35px' }
        }>
        {label && (
          <div
            className="text-break"
            style={{ fontSize: '0.6em', width: '100%', height: '100%' }}>
            {label}
          </div>
        )}
        <div
          className={`${today === date ? 'ml-1' : ''}`}
          style={{ width: '100%', height: '100%' }}>
          {dateNumber}
        </div>
        {today === date && (
          <div
            className="ml-1"
            style={{ fontSize: '0.5em', width: '100%', height: '100%' }}>
            TODAY
          </div>
        )}
        {deliveryDate === date && (
          <div style={{ fontSize: '0.5em' }}>SELECT</div>
        )}
      </div>
    );
  };

  useEffect(() => {
    dayPickerInputDesktopRef.current?.setState((res: any) => ({
      ...res,
      open: false
    }));
  }, []);

  useEffect(() => {
    if (showOverlay) {
      dayPickerInputDesktopRef.current?.showDayPicker();
    }

    if (isMobile && showOverlay) {
      dayPickerInputMobileRef.current?.showDayPicker();
    }
  }, [showOverlay, isMobile]);

  return (
    <>
      {/*desktop*/}

      {show && (
        <OverlayComponent
          height={'100%'}
          width={'100%'}
          zIndex={4}
          backgroundColor={'rgb(0 0 0 / 25%)'}
          onCLick={() => setShow(false)}
        />
      )}
      <div
        className="ibf-rounded-right ibf-calendar-desktop ibf-input-date-search input-group bg-white"
        style={show ? { zIndex: 4 } : {}}>
        <FontAwesomeIcon color="#495057" className="mx-2" icon={faCalendar} />
        <DayPickerInput
          ref={dayPickerInputDesktopRef}
          onDayChange={handleDate}
          placeholder={FORMAT}
          value={deliveryDate ? deliveryDate : FORMAT}
          dayPickerProps={{
            toMonth: aheadMonths,
            className: 'ibf-calendar-border',
            numberOfMonths: 2,
            disabledDays: disabledDays,
            selectedDays: new Date(deliveryDate),
            modifiers,
            modifiersStyles,
            // todayButton: "Close",
            // onTodayButtonClick(): void {
            //   closeDayPickerDesktopInput();
            // },
            renderDay: renderDay
          }}
          overlayComponent={(props: any) => (
            <CalendarComponent
              {...props}
              onClose={closeDayPickerDesktopInput}
              width={`${!isMobile ? '720px' : '400px'}`}
            />
          )}
          inputProps={{
            className: 'color-gray-text pointer ibf-font-15',
            readOnly: true,
            onClick: () => setShow(true),
            style: {
              backgroundColor: 'white',
              border: 'none',
              outline: 'none',
              width: '100%',
              height: '2.2em',
              padding: '0px 5px'
            }
          }}
        />
      </div>
      {/*mobile*/}
      <div className="search-date ibf-calendar-mobile border-left">
        <span className="mx-2">
          <FontAwesomeIcon color="#495057" icon={faCalendar} />
        </span>
        <DayPickerInput
          ref={dayPickerInputMobileRef}
          onDayChange={handleDate}
          placeholder={FORMAT}
          value={deliveryDate ? deliveryDate : FORMAT}
          dayPickerProps={{
            toMonth: aheadMonths,
            className: 'ibf-calendar-border',
            disabledDays: disabledDays,
            selectedDays: new Date(deliveryDate),
            modifiers,
            modifiersStyles,
            // todayButton: "Close",
            // onTodayButtonClick(): void {
            //   closeDayPickerMobileInput2();
            // },
            renderDay: renderDay
          }}
          overlayComponent={(props: any) => (
            <CalendarMobileComponent
              {...props}
              onClose={closeDayPickerMobileInput2}
            />
          )}
          inputProps={{
            className: 'color-gray-text ibf-font-15',
            readOnly: true,
            style: {
              zIndex: 4,
              border: 'none',
              outline: 'none',
              width: '100%',
              height: '2.2em',
              padding: '0px 5px'
            }
          }}
        />
      </div>
    </>
  );
};

export default InputDateComponent;
