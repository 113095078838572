import React from 'react';

type Props = {
  description: string;
  warning: string;
};

const ProductDescriptionComponent: React.FC<Props> = ({
  description,
  warning
}) => {
  return (
    <div className="d-flex flex-column ibf-product-description-wrapper p-2">
      <span className="ibf-font-14 mt-2">{description ?? ''}</span>
      <span className="color-red font-weight-bold">
        {warning ? 'Note: ' + warning : ''}
      </span>
    </div>
  );
};

export default ProductDescriptionComponent;
