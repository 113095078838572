import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { actionsAuth } from '../../../../../Store/Auth/Slice';
import { SignInGuestParams } from '../../../../../Models/Auth';
import { actionsModal } from '../../../../../Store/Modal/Slice';
import { SIGN_IN_FLOW } from '../../../../../Store/Modal/Types';
import { TrackEventMonitor } from '../../../../../Classes/TrackEvent';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';

type Props = {
  previous: () => void;
  objUser: SignInGuestParams;
};
const SignInFinalEmailStep = ({ previous, objUser }: Props) => {
  const dispatch = useDispatch();
  const [displayLinks, setDisplayLinks] = useState<boolean>(false);

  const goToStart = () => {
    TrackEventMonitor.newEvent({
      props: {
        step: 'send_email_step',
        value: 'back',
        email: objUser.email
      },
      metadata: { name: 'ibf_sign_in_step' }
    });
    dispatch(actionsModal.setCloseModal({ modal: SIGN_IN_FLOW }));
  };

  const resendEmail = () => {
    TrackEventMonitor.newEvent({
      props: { email: objUser.email },
      metadata: { name: 'ibf_re_send_email_confirmation' }
    });
    dispatch(
      actionsAuth.setVerifyEmailAccount({ userInfo: objUser, resend: true })
    );
  };

  return (
    <div className="d-flex flex-column bf-signin-modal pt-5 background-primary">
      <div className="text-center mb-2">
        <FontAwesomeIcon icon={faEnvelope} color={'#fff'} size={'8x'} />
      </div>
      <div className="d-flex flex-column align-items-center font-weight-light ">
        <h2 className="ml-3 ml-md-0 text-white mb-5 text-center">
          To start shopping, open your email <br />({objUser.email})<br />
          and click on the link in the email
        </h2>

        <div className="d-flex justify-content-center mb-3 ibf-font-14 py-5">
          <h4
            onClick={() => setDisplayLinks((prev) => !prev)}
            className="text-center text-white">
            <u>
              Help: I didn’t receive an email after 2 minutes at <br />{' '}
              <u className="text-warning">{objUser.email}</u>{' '}
            </u>
            <br />
            (it’s not in my spam folder either)
          </h4>
          <br />
        </div>
        {displayLinks && (
          <span className="font-weight-light ibf-font-14 ml-3 ml-md-0 pr-1 pr-md-0">
            Let us
            <span className="underline ml-1 mr-1 pointer" onClick={resendEmail}>
              send you a new email
            </span>
            <span className="mr-1">or</span>
            <span className="underline pointer" onClick={goToStart}>
              go back and use another email address
            </span>
          </span>
        )}
      </div>
    </div>
  );
};
export default SignInFinalEmailStep;
