import React from 'react';
import { DeepMap, FieldErrors, UseFormRegister } from 'react-hook-form';
import { FormValuesSignUp, validationInputMessage } from './GuestSignUpForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
type Props = {
  register: UseFormRegister<FormValuesSignUp>;
  errors: DeepMap<FormValuesSignUp, FieldErrors>;
  loading: boolean;
  onSubmit: () => void;
};
export default function GuestItemButton({
  register,
  errors,
  loading,
  onSubmit
}: Props) {
  return (
    <div>
      <div className="form-group  d-flex flex-column">
        <div className="form-check">
          <input
            type="checkbox"
            id="checked_email"
            className="form-check-input"
            {...register('checked_email', {
              required: true
            })}
          />
          <label className="form-check-label" htmlFor="checked_email">
            I double checked my email address, and agree to{' '}
            <a
              href={`https://help.ibuyflowers.com/knowledge/term-and-conditions`}
              rel="noopener noreferrer"
              className="text-black"
              target="_blank">
              {' '}
              <span className="underline"> all terms</span>
            </a>{' '}
            (incl. communication, claims, security, and delivery terms).*
          </label>
        </div>

        {errors.checked_email && (
          <div className="d-flex flex-column">{validationInputMessage}</div>
        )}
      </div>

      <div className={'d-flex justify-content-center mb-3'}>
        <button
          type={'button'}
          disabled={
            loading
            // !enabled ||
            // (Object.keys(errors).length > 0 &&
            //   errors.hasOwnProperty('email') &&
            //   errors?.email?.type === 'manual')
          }
          onClick={() => onSubmit()}
          className={` btn  background-primary text-white w-100 py-2
              }`}>
          {loading && (
            <FontAwesomeIcon icon={faCircleNotch} pulse className="mr-2" />
          )}
          Done. Verify my email address »
        </button>
      </div>
    </div>
  );
}
