import { runCS, sbxCoreService } from '../Network';
import Environment from '../Environment';
import ResponseCS from '../Models/ResponseCS/ResponseCS';
import AddProductCS from '../Models/ResponseCS/AddProductCS';
import { getTodayEST, toast } from '../Utils/Utils';

export function createShoppingList(customer: string, address: any): any {
  return runCS(Environment.cloudscripts.setShoppingList, {
    customer,
    address
  }).then((res: ResponseCS<AddProductCS>) => res);
}

export function getShoppingListsByConsecutive(keys: Array<any>) {
  return sbxCoreService
    .with('shopping_list')
    .whereWithKeys(keys)
    .find()
    .then((res) => res.results)
    .catch((error) => {
      console.error(error);
    });
}

export const getShoppingListsProcessed = (customer: string) => {
  return sbxCoreService
    .with('shopping_list')
    .andWhereIsEqualTo('customer', customer)
    .andWhereIsEqualTo('status', 'PROCESSED')
    .find()
    .then((res) => res)
    .catch((error) => toast(error, 'error'));
};

export function getOrderRequestsByCustomer(customer: string) {
  return runCS(Environment.cloudscripts.getOrderRequestByCustomer, { customer })
    .then((res) => res)
    .catch((error) => console.error(error));
}

export function setDeleteShoppingListItem(_KEY: string) {
  const todayNumber = getTodayEST();
  return sbxCoreService
    .update('shopping_list_item', { _KEY, deleted: todayNumber })
    .then((res: any) => res);
}

export function setFinishShoppingList(_KEY: string) {
  return sbxCoreService
    .update('shopping_list', { _KEY, status: 'FINISHED' })
    .then((res: any) => res);
}
