import React from 'react';
import Product from '../../../../../Models/Product';

type Props = {
  lengthSelect: JSX.Element;
  growerSelect: JSX.Element;
  validations: {
    isProductDetail?: boolean;
    stemValidation: boolean;
    growerSelected: boolean;
    boxOnly?: boolean;

    growers: boolean;
  };
  product: Product;

  /*  tabsProductPrice: JSX.Element;
  growerLengths: JSX.Element; */
};

const CardGrowerInputs: React.FC<Props> = ({
  validations,
  lengthSelect,
  growerSelect,
  product
  /* tabsProductPrice,
  growerLengths */
}) => {
  const { isProductDetail } = validations;
  //console.log('CardGrowerInputs_isProductDetail: ', isProductDetail);

  return (
    <div className="mx-1">
      <div className={`col-12  order-0 ${isProductDetail?'p-2':'p-0'}`}>
        {isProductDetail && <label>Grower:</label>}
        {growerSelect}
      </div>
      {/*  {tabsProductPrice}
      {growerLengths} */}
      <div className={`col-12 my-2  order-1 ${isProductDetail?'p-2':'p-0'} `}>
        {/* {isProductDetail && <label>Length:</label>} */}

        {/*   {product.growers?.length!>1&&   lengthSelect} */}
      </div>
    </div>
  );
};

export default CardGrowerInputs;
