import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterItem from './../../../../Models/FilterItem';
import { getFiltersKeys } from '../../../../Utils/Utils';
import { actionsCustomBox } from '../../../../Store/CustomBox/Slice';
import { RootState } from '../../../../Store/Reducers';
import FilterListItemComponent from '../../../ProductWrapperComponent/ProductFilterComponent/FilterListItemComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { loadingReducer } from '../../../../Store/Selectors';
type Props = {
  listItems: Array<FilterItem>;
  isMobile: boolean;
  filterLabel: string;
};

const BoxFilterListComponent: React.FC<Props> = ({
  listItems,
  isMobile,
  filterLabel
}) => {
  let { filters: reduxFilters, currentPercentage, search } = useSelector(
    (state: RootState) => state.CustomBoxReducer
  );
  const { CUSTOM_BOX_MODAL } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const { customer } = useSelector(
    (state: RootState) => state.AuthReducer.user.metadata
  );

  const { inventoryLoading } = useSelector(loadingReducer);

  const [filterLimit, setFilterLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Array<any>>([]);
  const dispatch = useDispatch();
  const handleFilterListItems = () => {
    filterLimit === 5 ? setFilterLimit(listItems.length) : setFilterLimit(5);
  };

  useEffect(() => {
    Object.keys(reduxFilters).length > 0 && setFilters(reduxFilters);
  }, [reduxFilters]);

  const getProductsFilter = (filterKeys: any) => {
    const filters = {
      ...filterKeys,
      grower: [CUSTOM_BOX_MODAL.data.grower.key]
    };
    dispatch(
      actionsCustomBox.searchInventory({
        params: {
          conditions: {
            search,
            custom: true,
            currentPercentage,
            filters: filters
          },
          customer: customer,
          eta: CUSTOM_BOX_MODAL.data.eta_date,
          page: 1,
          pageSize: 120
        }
      })
    );
    dispatch(actionsCustomBox.setBoxFilters({ conditions: filters, search }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const itemId = e.target.id;
    setFilter(itemId);
  };

  const setFilter = (itemId: string) => {
    const newFilters = Object.keys(filters).map((key: any) => {
      if (filterLabel === filters[key].label) {
        let label = '';
        if (filterLabel === 'Product varieties') {
          label = 'variety';
        } else if (filterLabel === 'Categories') {
          label = 'category';
        } else {
          label = filters[key].label;
        }

        return {
          label,
          values: filters[key].values.reduce((arr: any, item: FilterItem) => {
            if (item.key === itemId) {
              item = { ...item, selected: !item.selected };
            }
            arr.push(item);
            return arr;
          }, [])
        };
      }
      return filters[key];
    });

    const filtersKeys = getFiltersKeys(newFilters);
    getProductsFilter(filtersKeys);
  };

  return (
    <>
      {inventoryLoading && (
        <div className="d-flex justify-content-center my-4">
          <FontAwesomeIcon icon={faSpinner} pulse />
        </div>
      )}

      <ul
        className={`list-group list-group-flush py-2 ${
          listItems.length <= 5 ? 'h-auto' : ''
        }
      ${filterLimit === 5 ? 'filter-list-less' : 'filter-list-more'}`}>
        {listItems &&
          !inventoryLoading &&
          listItems.slice(0, filterLimit).map((item: FilterItem) => {
            return (
              item.value !== null && (
                <li
                  key={listItems.indexOf(item) + item.value}
                  className={`list-group-item p-0 ${
                    isMobile ? 'ibf-filter-list-items bg-white' : 'border-0'
                  }`}>
                  <FilterListItemComponent
                    checked={item.selected}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e)
                    }
                    itemKey={item.key}
                    checkboxName={item.value}
                  />
                </li>
              )
            );
          })}
      </ul>

      {listItems && listItems.length > 5 && !inventoryLoading && (
        <>
          <div className="px-2 mb-2">
            <span
              className="pointer color-gray"
              onClick={handleFilterListItems}>
              <u>
                {filterLimit === 5
                  ? `See ${listItems.length - 5} more`
                  : 'See less'}
              </u>
            </span>
          </div>
        </>
      )}
    </>
  );
};
export default BoxFilterListComponent;
