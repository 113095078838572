import { Type } from 'class-transformer';
import Config from '../../Models/Config/Config';
import User from '../../Models/User/User';
import Manager from '../../Models/Manager';
import { Holiday } from '../../Models/Holiday';
import { State } from '../../Models/State';

export const VALIDATE_TOKEN = 'auth/validateToken';
export const CHANGE_PASSWORD = 'auth/changePassword';
export const CHANGE_EMAIL = 'auth/changeEmail';
export const VERIFY_EMAIL_ACCOUNT = 'auth/setVerifyEmailAccount';

export default class AuthState {
  token!: string;
  @Type(() => Config)
  config!: Config;
  @Type(() => User)
  user!: User;
  offset!: string;
  isNewDay!: boolean;
  error!: string;
  manager!: Manager;
  holidays!: Array<Holiday>;
  state!: State;
  time!: number;
  verificationCode!: number;
  smsPreferencesKey!: string;
}
