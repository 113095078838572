import { State } from '../../Models/State';
import { Grower } from '../../Models/Grower';
export const ADD_CUSTOMER_ADDRESS = 'customer/addCustomerAddress';
export const GET_CURRENT_ADDRESS = 'customer/getCurrentAddress';
export const SET_USER_PREFERENCES = 'customer/setUserPreferences';

export const FETCH_ORDERS = 'order/fetchOrders';
export const FETCH_ORDERS_BY_PURCHASE_DATE = 'order/fetchOrdersByPurchaseDate';
export const FETCH_ORDER_DETAIL = 'order/fetchOrderDetail';
export const SAVE_LIST_ORDERS = 'order/saveListOrders';
export const SAVE_LIST_ORDERS_BY_PURCHASE_DATE =
  'order/saveListOrdersByPurchaseDate';
export const GO_TO_PAGE = 'order/goToPage';
export const SAVE_ORDER_DETAIL = 'order/saveOrderDetail';
export const CLEAN_ORDER_DETAIL = 'order/cleanOrderDetail';
export const NEW_CHECKOUT = 'order/newCheckout';

export interface orderState {
  orders: Order[] | OrderPurchase[];
  ordersKeys: TableKeys[];
  tableHeaders: Array<string>;
  ordersByArrivalLoaded: boolean;
  indexCurrentPage: number;
  growersList: string[];
  isNewCheckout: boolean;
  ordersByPurchaseLoaded: boolean;
  orderDetail: any;
  orderDetailLoaded: boolean;
  state: State;
  repurchase: boolean;
}

export interface TableKeys {
  date: string;
  key: string;
}

export interface OrderEntity {
  custom?: boolean;
  _KEY?: string | null;
  grower: string;
  grower_obj?: Grower | null;
  po: string | number;
  order: string | number;
  product_group_key: string;
  product_group: string;
  variety_key: string;
  box_only: boolean;
  variety_grade: string;
  length: string | number;
  stem_bunch: string | number;
  total_stems: number;
  total_bunches: string | number;
  price: number;
  extra_freight_cost?: number;
  uom: string;
  total_price: string | number;
  order_detail?: string | null;
  eta_date: string | number;
  customer: string;
  charge_date: number;
  event_name?: string | null;
  event_color?: string | null;
  event_key?: string | null;
  cart_box_item_key?: string | null;
  customizable?: boolean | null;
  masterlist_uom?: string | null;
  units_masterlist?: number | null;
  masterlist_key?: string;
  current_percentage: number;
  charge_code: number;
  img: Array<string>;
}
export type Order = OrderEntity[][];

export interface OrderPurchase {
  _KEY: string;
  po: number;
  date: string;
  user: string;
  boxes: number;
  status?: null;
  subtotal: number;
  total: number;
  order_detail: string;
}
