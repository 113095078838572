import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from '../../../Utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
// import {GoogleReCaptcha,GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faSpinner, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { actionsCard } from '../../../Store/Card/Slice';
import { actionsLoading } from '../../../Store/Loading/Slice';
import { actionsModal } from '../../../Store/Modal/Slice';
import { State } from '../../../Models/State';
import { RootState } from '../../../Store/Reducers';
import { PAYMENT_MODAL } from '../../../Store/Modal/Types';
// import Environment from '../../../Environment';

const PaymentModalComponent = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState<string>('');
  const [addingCard, setAddingCard] = useState<string>('IDLE');
  // const [captcha, setCaptcha] = useState('');
  const dispatch = useDispatch();
  const { paymentModal } = useSelector(
    (state: RootState) => state.ModalReducer
  );

  const { user } = useSelector((state: RootState) => state.AuthReducer);
  const { state } = useSelector((state: RootState) => state.CardReducer);

  const addCardFunction = async (token: any) => {
    // if (!captcha) {
    //   return toast("We couldn't validate the captcha", 'error');
    // } else {
    dispatch(actionsCard.setNewCard({ token, customer: customer.customer }));
    // }
  };

  useEffect(() => {
    if (state === State.RESOLVED) {
      setAddingCard(State.RESOLVED);
    }
  }, [state]);

  const customer = user.metadata;
  const from = paymentModal.data.from;
  const submit = async () => {
    setAddingCard('PENDING');
    dispatch(actionsCard.addCard(true));
    if (name) {
      const options = {
        name: name,
        address: {
          city: customer.customer_city,
          line1: customer.customer_street,
          state: customer.customer_state
        }
      };

      const cardOptions = {
        name: name,
        address_city: customer.customer_city,
        address_line1: customer.customer_street,
        address_state: customer.customer_state,
        address_zip:
          typeof customer.customer_zipcode === 'string'
            ? customer.customer_zipcode
            : (customer.customer_zipcode as Number).toString()
      };

      if (!stripe || !elements) {
        setAddingCard('REJECTED');
        return;
      }

      const cardElement = elements.getElement(CardElement);

      if (cardElement) {
        const { error } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: options
        });

        if (error) {
          toast(error.message || '', 'error');
          setAddingCard('REJECTED');
          dispatch(actionsCard.addCard(false));
          dispatch(actionsLoading.addingCard(false));
        } else {
          stripe.createToken(cardElement, cardOptions).then((res) => {
            res?.token && addCardFunction(res.token);
          });
        }
      }
    } else {
      toast('Please enter a name for the card', 'warning');
      setAddingCard('REJECTED');
      dispatch(actionsLoading.addingCard(false));
    }
  };

  const onChangeTextInput = (event: React.FormEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const onClose = () =>
    dispatch(actionsModal.setCloseModal({ modal: PAYMENT_MODAL }));
  return (
    // <GoogleReCaptchaProvider reCaptchaKey={Environment.captchaKey}>
    <Modal
      isOpen={paymentModal.open}
      toggle={onClose}
      centered
      onClosed={onClose}>
      <ModalHeader>Add Payment Option</ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column">
          <div className="form-inline">
            <label>
              Card Name
              <input
                className="StripeElement form-control"
                name="name"
                type="text"
                placeholder="Name Card"
                required
                onChange={onChangeTextInput}
              />
            </label>
          </div>

          <label htmlFor="">
            Card details
            <CardElement />
          </label>
        </div>
      </ModalBody>
      <ModalFooter
        style={from === 'paymentOptions' ? {} : { display: 'block' }}>
        {from === 'paymentOptions' ? (
          <>
            <button
              id="add-payment-save"
              className="btn btn-primary text-white btn-sm"
              disabled={addingCard === State.PENDING || state === State.PENDING}
              onClick={() => submit()}>
              {addingCard === State.PENDING || state === State.PENDING ? (
                <FontAwesomeIcon icon={faSpinner} pulse />
              ) : (
                <span>
                  <FontAwesomeIcon icon={faSave} className="mr-2" />
                  Save
                </span>
              )}
            </button>
            <button
              onClick={onClose}
              id=" add-payment-close"
              className=" btn btn-danger btn-sm">
              <span>
                <FontAwesomeIcon icon={faTimes} className="mr-2" />
                Close
              </span>
            </button>
          </>
        ) : (
          <>
            {/*<button id="add-payment-save" className="btn w-75 mb-2 btn-block btn-primary"*/}
            {/*        disabled={addingCard || shoppingCartTotalItems === 0}*/}
            {/*        onClick={() => submit(true)}>*/}
            {/*  <i className={addingCard && 'fa fa-spinner fa-pulse'}/> Place order now using this credit card*/}
            {/*</button>*/}
            {/*<div>*/}
            {/*  <u className={!addingCard && 'clickable'} onClick={() => addingCard ? null : submit()}>Only save my credit*/}
            {/*    card and go back to review my*/}
            {/*    summary</u>*/}
            {/*</div>*/}
          </>
        )}
      </ModalFooter>
    </Modal>
    //   <GoogleReCaptcha onVerify={(token) => setCaptcha(token)} />
    // </GoogleReCaptchaProvider>
  );
};

export default PaymentModalComponent;
