import React from 'react';
import { roundUp } from '../../Utils/Utils';

const CustomBoxDetailItemComponent = (props: any) => {
  const {
    common_name,
    price,
    variety_name,
    color_name,
    percentage,
    stems_per_bunch,
    quantity,
    picture,
    masterlist
  } = props.data;

  return (
    <li className="list-group-item">
      <div className="media">
        <div className="image-wrapper mr-3">
          <div className="product-image-wrapper">
            <img
              className="product-image hover cursor-pointer"
              src={picture}
              alt=""
            />
          </div>
        </div>
        <div className="media-body text-truncate">
          <div className="d-flex justify-content-between">
            <b className="text-truncate" title="undefined">
              {' '}
              {common_name} {variety_name} ({color_name}){' '}
            </b>
          </div>
          <div className="media">
            <div className="media-body">
              <ul className="list-unstyled">
                <li>
                  <b>${roundUp(price)}/bunch</b>
                </li>
                <li>
                  {' '}
                  {stems_per_bunch} stem - {masterlist.length}
                  <span> cm</span>
                </li>
                <li> {percentage}% of box volume</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex ibf-button-action align-items-center mb-1">
          <span className="sm">x{quantity}</span>
        </div>
      </div>
    </li>
  );
};

export default CustomBoxDetailItemComponent;
