import React from 'react';
import { useSelector } from 'react-redux';
import StoreType from '../../../Models/Redux/Store';
import Skeleton from 'react-loading-skeleton';
import { authReducer } from '../../../Store/Selectors';

const StandingOrderComponent = () => {
  const { authLoading } = useSelector(
    (store: StoreType) => store.LoadingReducer
  );

  const { manager } = useSelector(authReducer);

  return (
    <div className="d-flex flex-column card flex-grow-1 p-3">
      {authLoading ? (
        <Skeleton />
      ) : (
        <div>
          <h5 className="font-weight-bold">Standing order prices</h5>

          <span>
            Lower prices for (bi-)weekly Standing orders.{' '}
            {manager?.phone ? (
              <span>
                <a
                  href={`tel:+1${manager?.phone}`}
                  className="ibf-contact-options mb-2">
                  <span>
                    Call {manager?.name} ({manager && manager.phone}
                    {manager?.Extension && ' ext '}
                    {manager && manager.Extension})
                  </span>{' '}
                </a>
                today.
              </span>
            ) : (
              <Skeleton width={'200px'} />
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default StandingOrderComponent;
