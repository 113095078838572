import React, { useEffect, useState } from 'react';
import { Input, Modal } from 'reactstrap';
import { Button } from 'antd';
import { toast, validateEmail } from '../../../../../Utils/Utils';
import { forgotPassword } from '../../../../../Services/AuthService';
import { MESSAGE_MODAL } from '../../../../../Store/Modal/Types';
import { actionsModal } from '../../../../../Store/Modal/Slice';
import { useDispatch } from 'react-redux';

type Props = {
  email: string;
  close: () => void;
  setForgotPasswordMessage: (value: string) => void;
};
const ForgotPasswordModal: React.FC<Props> = ({
  email,
  close,
  setForgotPasswordMessage
}) => {
  const [emailAddress, setEmailAddress] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setEmailAddress(email);
  }, [email]);

  const onSubmitForgotPassword = async () => {
    setForgotPasswordMessage('close');
    if (validateEmail(emailAddress)) {
      const res = await forgotPassword(emailAddress);
      const data = {
        body: `A link to create your new password has been sent to: ${email}`,
        text: `It can take up to 1 minute to receive our email. Please check you spam/junk mailbox, in case you can't find our email.`,
        size: 'md',
        buttonEnabled: true,
        textButton: 'Ok, close',
        classNameText: 'font-weight-light d-block ibf-text-line-height-1 mb-2',
        bodyClassName: 'font-weight-bold text-break',
        classNameParagraph:
          'ibf-font-20 d-block font-weight-bold ibf-text-line-height-1 mb-4',
        buttonClassName:
          'mt-2 mb-3 w-100 btn bg-primary border-radius-22 text-white',
        onClick: () => setForgotPasswordMessage('open')
      };
      if (res && res.success) {
        toast('Email sent.');
        close();
        dispatch(
          actionsModal.setOpenModal({
            modal: MESSAGE_MODAL,
            data: data
          })
        );
      } else {
        toast(res.error, 'error');
      }
    } else {
      toast('Please use an email', 'error');
    }
  };

  return (
    <Modal isOpen className="ibf-login-modal-main">
      <div className="ibf-forgot-password-modal-home">
        <div>
          <div className="ibf-login-modal-home-title">
            <span>Create a new password</span>
          </div>
          <span className="d-flex px-3 px-md-5">
            Type your email address to receive an email with a link to create
            your new password:
          </span>

          <div className="ibf-login-modal-home-body ">
            <div className="py-3">
              <Input
                value={emailAddress}
                disabled={true}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setEmailAddress(e.currentTarget.value.replace(/\s/g, ''))
                }
              />
            </div>
            <div className="ibf-login-modal-continue-btn text-center">
              <Button className="mb-3" onClick={onSubmitForgotPassword}>
                Email me the link now
              </Button>
              <u onClick={close} className="pointer my-3">
                Cancel
              </u>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
