import React, { useEffect, useRef, useState } from 'react';
import ProductList from './ProductList';
// import ProductFilter from './ProductFilterComponent/ProductFilter';
import { useDispatch, useSelector } from 'react-redux';
import ResultsComponents from './ProductComponent/ResultsComponent';
import ContactComponent from '../Shared/ContactComponent/ContactComponent';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faHeart } from '@fortawesome/pro-solid-svg-icons';
import MixpanelService from '../../Services/MixpanelService';
import { hrefToObject } from '../../Utils/Utils';
import useIsMount from '../../Hooks/useIsMount';
import MoreOptionsComponent from './ProductComponent/MoreProductOptionsComponent/MoreOptionsComponent';
import { actionsProduct } from '../../Store/Product/Slice';
import { actionsCart } from '../../Store/Cart/Slice';
import { actionsModal } from '../../Store/Modal/Slice';
import { RootState } from '../../Store/Reducers';
import { CONFIRM_DELIVERY_DATE_MODAL } from '../../Store/Modal/Types';
import {
  filterReducer,
  loadingReducer,
  productReducer
} from '../../Store/Selectors';
import { ProductFilterComponentsDi } from './ProductFilterComponentD';

const ProductWrapperComponent = () => {
  const dispatch = useDispatch();
  const { date, favorites, filters: filterRedux, products } = useSelector(
    productReducer
  );

  const { availableDates, availableGrowers } = useSelector(filterReducer);
  const { initialLoading, filterLoading, isFromProductDetail } = useSelector(
    loadingReducer
  );
  const { scrollPosition } = useSelector(
    (state: RootState) => state.DisplayReducer
  );

  const [noFavorites, setNoFavorites] = useState(false);

  const location = useLocation();

  const {
    user: {
      metadata: { customer }
    }
  } = useSelector((state: RootState) => state.AuthReducer);

  const [filterMobileView, setFilterMobileView] = useState(false);
  const [filtersCount] = useState(0); //setFilterCount
  const [visibleButton, setVisibleButton] = useState(false);
  // const [isScrolling, setIsScrolling] = useState(false);
  const favoriteArr = useRef(favorites);
  const isMount = useIsMount();
  const filterRef = useRef(filterRedux);

  const toggleFilterMobileView = () => {
    if (filterMobileView) {
      MixpanelService.track('list_view');
    } else {
      MixpanelService.track('cart_view');
    }
    setFilterMobileView(!filterMobileView);
  };

  useEffect(() => {
    filterRef.current = filterRedux;

    return () => {
      filterRef.current = [];
    };
  }, [filterRedux]);

  useEffect(() => {
    if (favorites) {
      favoriteArr.current = favorites;
    }
    return () => {
      favoriteArr.current = [];
    };
  }, [favorites]);

  useEffect(() => {
    if (!window.location.href.includes('/#/')) {
      window.location.href =
        window.location.protocol +
        '//' +
        window.location.host +
        '/#' +
        location.pathname +
        (location.search ?? '');
    } else {
      const {
        date: d,
        search_val: search = '',
        ...filters
      }: { [key: string]: any } = hrefToObject();
      if (d) {
        const { orderBy, sortOrder, combo, substitute, search_type } = filters;
        delete filters.orderBy;
        delete filters.sortOrder;
        delete filters.combo;
        delete filters.substitute;
        delete filters.token;
        delete filters.user_key;
        const params = {
          eta: d,
          substitute,
          conditions: {
            search,
            filters,
            orderBy,
            sortOrder,
            combo,
            search_type
          },
          customer
        };
        if (Array.isArray(search)) {
          params.conditions.search = params.conditions.search.join(', ');
        }
        if (filters.favorites) {
          filters.variety = Object.keys(favoriteArr.current);
        }
        Object.keys(filters).forEach((key) => {
          if (!Array.isArray(filters[key]))
            filters[key] = !!filters[key] ? [filters[key]] : [];
        });
        if (
          (filters.variety && filters.variety.length > 0) ||
          !filters.favorites
        ) {
          if (!isFromProductDetail) {
            dispatch(actionsProduct.getProducts({ params, loading: true }));
          }
          if (noFavorites) {
            setNoFavorites(false);
          }
        } else {
          setNoFavorites(true);
          dispatch(
            actionsProduct.setProducts({
              results: {
                products: [],
                unavailableProducts: [],
                filters: [...filterRef.current],
                totalItems: 0,
                currentPage: 0,
                totalPages: 0
              }
            })
          );
        }
      }
    }
    return () => {};
  }, [dispatch, location, customer, noFavorites, isFromProductDetail]);

  useEffect(() => {
    if (isMount && customer) {
      actionsCart.listCart({ customer });
    }
    return () => {};
  }, [dispatch, customer, isMount]);

  useEffect(() => {
    if (
      !filterLoading &&
      window.scrollY < scrollPosition &&
      location.pathname === '/search'
    ) {
      setTimeout(() => {
        window.scroll({ top: scrollPosition, behavior: 'auto' });
        // window.scrollTo(0, scrollPosition || 0);
      }, 1000);

      // setTimeout(() => {
      //   if (window.scrollY !== scrollPosition) {
      //     setIsScrolling((last) => !last);
      //   }
      // }, 1000);
    }
  }, [scrollPosition, filterLoading, location]);

  useEffect(() => {
    const { date: d }: { [key: string]: any } = hrefToObject();

    if (!date && !d) {
      dispatch(
        actionsModal.setOpenModal({
          modal: CONFIRM_DELIVERY_DATE_MODAL,
          data: { isMultipleSelection: false }
        })
      );
    }
  }, [dispatch, date]);

  const scrollToTop = () => window.scroll({ top: 0, behavior: 'smooth' });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setVisibleButton(scrollTop > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="ibf-product-list-container bg-list" id="products-container">
      {initialLoading ? (
        <LoadingSpinner message={'LOADING'} height={'60vh'} />
      ) : (
        <>
          <div className="ibf-product-list-results">
            <ResultsComponents className="ibf-text-result ibf-text-result-small" />
            <ContactComponent />
          </div>

          <div className="d-flex px-3">
            {!!products?.length && (
              <div className="ibf-filter-list ibf-filter-pr4">
                {/* <ProductFilter
                  filtersCount={filtersCount}
                  setFilterCount={setFilterCount}
                  showMobileView={filterMobileView}
                  toggleMobileView={toggleFilterMobileView}
                /> */}
                <ProductFilterComponentsDi />
              </div>
            )}
            {noFavorites ? (
              <div className="d-flex flex-column mx-4">
                <span>You have no favorites yet.</span>
                <span>
                  You can simply make a favorite by clicking on the{' '}
                  <FontAwesomeIcon icon={faHeart} className="mr-1" />
                  heart symbol on any product
                </span>
              </div>
            ) : !filterLoading &&
              (availableDates.length > 0 || availableGrowers.length > 0) ? (
              <MoreOptionsComponent />
            ) : (
              <div className="w-100">
                <ProductList
                  filtersCount={filtersCount}
                  toggleMobileView={toggleFilterMobileView}
                />
              </div>
            )}
          </div>
          <div
            className={
              'back-to-top pointer ' +
              (visibleButton
                ? 'back-to-top d-flex align-items-center justify-content-center'
                : 'd-none')
            }
            onClick={scrollToTop}>
            <FontAwesomeIcon icon={faChevronUp} color="white" />
          </div>
        </>
      )}
    </div>
  );
};

export default ProductWrapperComponent;
