import Product from './Product';

export default class CartProduct extends Product {
  productKey!: string;
  length!: number;
  quantity!: number;
  delivery_date!: number;
  price_limit!: number;
  variety!: string;
  repurchase_cartbox_item!: string;
  _META: any;
}
