import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import ColorList from './ColorList';
import { RootState } from '../../../Store/Reducers';

type Props = {
  closeMenu: () => void;
  openMenu: () => void;
};
const ColorListComponent: React.FC<Props> = ({ closeMenu, openMenu }) => {
  const { navOptions } = useSelector(
    (state: RootState) => state.DisplayReducer
  );
  const toggleMenu = () => {
    if (navOptions && navOptions.colors) {
      closeMenu();
    } else {
      openMenu();
    }
  };
  return (
    <>
      <span className="ibf-text-navbar d-none d-sm-inline">
        <span onClick={toggleMenu} className="pointer">
          Colors
          <FontAwesomeIcon
            className="ml-1"
            color="gray"
            icon={faChevronDown}
          />{' '}
        </span>{' '}
      </span>
      {navOptions.colors && <ColorList toggleMenu={toggleMenu} />}
    </>
  );
};
export default ColorListComponent;
