import React, { CSSProperties } from 'react';
import ContentLoader from 'react-content-loader';

const Style: CSSProperties = {
  width: '100%'
};

const EventListLoading = () => {
  return (
    <ContentLoader
      style={Style}
      className="px-4"
      backgroundColor="#ababab"
      foregroundColor="#fafafa">
      <circle cx="10" cy="20" r="8" />
      <rect x="25" y="15" rx="5" ry="5" width="450" height="20" />
      <circle cx="10" cy="50" r="8" />
      <rect x="25" y="45" rx="5" ry="5" width="450" height="20" />
      <circle cx="10" cy="80" r="8" />
      <rect x="25" y="75" rx="5" ry="5" width="450" height="20" />
      <circle cx="10" cy="110" r="8" />
      <rect x="25" y="105" rx="5" ry="5" width="450" height="20" />
    </ContentLoader>
  );
};

export default EventListLoading;
