import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  VALIDATE_TOKEN,
  VERIFY_EMAIL_ACCOUNT
} from './Types';
import { actionsAuth } from './Slice';
import {
  changeEmailRequestService,
  logout,
  updatePasswordService,
  validateToken,
  verifyEmailAccountService
} from '../../Services/AuthService';
import { plainToClass } from 'class-transformer';
import AuthResponse from '../../Models/AuthResponse';
import { actionsModal } from '../Modal/Slice';
import { CHANGE_EMAIL_MODAL } from '../Modal/Types';
import { toast } from '../../Utils/Utils';
import { TrackEventMonitor } from '../../Classes/TrackEvent';
import { ResponseSbx } from '../../Models/ResponseSbx';

function* validateTokenSaga({
  payload
}: ReturnType<typeof actionsAuth.validateToken>) {
  try {
    const {
      data: { user, isNewDay, offset, manager, config }
    } = yield call(validateToken, payload.token, payload.user_key);
    const authData = plainToClass(AuthResponse, {
      token: payload.token,
      config,
      manager,
      user,
      isNewDay,
      offset
    });

    yield put(actionsAuth.setAuthData(authData));
  } catch (e) {
    console.error(e);
    yield put(actionsAuth.setRejected());
  }
}

function* changePasswordSaga({
  payload
}: ReturnType<typeof actionsAuth.changePassword>): Generator {
  try {
    const response: any = yield call(
      updatePasswordService,
      payload.current_password,
      payload.new_password
    );
    if (response?.success) {
      logout();
    } else {
      toast(response.error || 'An error ocurred.', 'error');
      yield put(actionsAuth.setRejected());
    }
  } catch (e) {
    console.error(e);
    yield put(actionsAuth.setRejected());
  }
}

function* changeEmailSaga({
  payload
}: ReturnType<typeof actionsAuth.changeEmail>) {
  try {
    const res: { success: boolean; error: any } = yield call(
      changeEmailRequestService,
      payload
    );
    if (res?.success) {
      yield put(actionsModal.setCloseModal({ modal: CHANGE_EMAIL_MODAL }));
      yield put(actionsAuth.setUserEmail(payload.email));
      toast('Email was updated.');

      yield put(
        actionsAuth.validateToken({
          token: localStorage.getItem('token') ?? ''
        })
      );

      // TrackEventMonitor.newEvent({
      //   props: {
      //     user_key: payload.user,
      //     new_email: payload.new_email,
      //     current_email: payload.current_email
      //   },
      //   metadata: {
      //     name: 'ibf_request_email_change'
      //   }
      // });
    } else {
      yield put(actionsModal.setCloseModal({ modal: CHANGE_EMAIL_MODAL }));
      toast(res.error || 'An error ocurred', 'error');
      yield put(actionsAuth.setRejected());
    }
  } catch (e) {
    console.error(e);
    yield put(actionsAuth.setRejected());
  }
}
function* verifyEmailAccountSaga({
  payload
}: ReturnType<typeof actionsAuth.setVerifyEmailAccount>): Generator {
  try {
    const res: ResponseSbx = (yield call(
      verifyEmailAccountService,
      payload.userInfo
    )) as ResponseSbx;
    // const res1: ResponseSbx = (yield call(
    //   insertAccountSent,
    //   payload.userInfo.email
    // )) as ResponseSbx;

    if (res.success) {
      if (!payload.resend) {
        TrackEventMonitor.newEvent({
          props: {
            email: payload.userInfo.email
          },
          metadata: { name: 'ibf_send_email_confirmation' }
        });
      }

      toast('Check your email', 'success');
      yield put(actionsAuth.setResolved());
    } else {
      toast('An error occurred while recording data', 'error');
      yield put(actionsAuth.setRejected());
    }
  } catch (e) {
    yield put(actionsAuth.setRejected());
  }
}

export default function* AuthSaga() {
  yield all([
    //Auth sagas
    takeEvery(VALIDATE_TOKEN, validateTokenSaga),
    takeEvery(CHANGE_PASSWORD, changePasswordSaga),
    takeEvery(CHANGE_EMAIL, changeEmailSaga),
    takeEvery(VERIFY_EMAIL_ACCOUNT, verifyEmailAccountSaga)
  ]);
}
