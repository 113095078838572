import React, { useEffect, useRef } from 'react';
import Product from '../../../../Models/Product';
import ImageComponent from '../../ImageComponent/ImageComponent';
import Environment from './../../../../Environment';

type Props = {
  product: Product;
};

const ReplacementProduct: React.FC<Props> = ({ product }) => {
  const productRef = useRef(product);
  const productPhotos = productRef?.current?.photos?.map(
    (i) => Environment.publicPath + i
  );
  useEffect(() => {
    productRef.current = product;

    return () => {
      productRef.current = new Product();
    };
  }, [product]);
  return (
    <div className="ibf-card-shadow h-100">
      <div className="h-80">
        <>
          <div className={'justify-content-center d-flex my-1 pointer'}>
            <div
              className="overflow-hidden d-flex justify-content-center align-items-center"
              style={{ width: '220px', height: '150px' }}>
              <ImageComponent
                imageUrl={productPhotos && productPhotos[0]}
                width={'235px'}
                onErrorUrl={''}
              />
            </div>
          </div>

          <div className="px-3 font-weight-bold">
            <span className=" p-sm-0 ibf-text-info">{product.name}</span>
          </div>
        </>
      </div>
    </div>
  );
};

export default React.memo(ReplacementProduct);
