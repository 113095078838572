import React from 'react';
import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import DeliveryDateModal from './../../../../Shared/Modal/DeliveryDateModal/DeliveryDateModal';
import { RootState } from '../../../../../Store/Reducers';
import AvailabilityAirlineCalendar from '../../../../AvailabilityAirlineCalendar/AvailabilityAirlineCalendar';
import { convertDateToStantard } from '../../../../../Utils/Utils';
interface Iprops {
  date?: string;
  availableDates?: boolean;
}
const DateOptionList = ({ date, availableDates }: Iprops) => {
  const { selectedGrower, filters_conditions } = useSelector(
    (state: RootState) => state.FilterReducer
  );
  const getMessage = useCallback(
    (date) => {
      if (selectedGrower.key) {
        return <p>Please select another date above. </p>;
      } else {
        return (
          <p>
            Sorry, there is no availability for {date}. Please select another
            date.
          </p>
        );
      }
    },
    [selectedGrower]
  );

  const [openDeliveryDateModal, setOpenDeliveryDateModal] = useState(false);
  const toggleDeliveryDateModal = useCallback(
    () => setOpenDeliveryDateModal(!openDeliveryDateModal),
    [openDeliveryDateModal]
  );
  return (
    <div className="d-flex flex-wrap mb-2">
      <AvailabilityAirlineCalendar enableDateGrower={availableDates} />
      <div className="d-inline w-100">
        {selectedGrower.key && !filters_conditions.favorites && (
          <b>{`Grower ${
            selectedGrower.name
          } has no availability for ${convertDateToStantard(date)} `}</b>
        )}
        <div className="font-weight-bold">{getMessage(date)}</div>
        <DeliveryDateModal
          openDeliveryDateModal={openDeliveryDateModal}
          toggleDeliveryDateModal={toggleDeliveryDateModal}
          className="ibf-delivery-date-modal"
          dateList
        />
      </div>
    </div>
  );
};

export default DateOptionList;
