import React, { useRef, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { actionsModal } from '../../../../Store/Modal/Slice';
import * as ModalTypes from '../../../../Store/Modal/Types';
import { toast } from '../../../../Utils/Utils';
import { eventActions } from '../../../../Store/Event/Slice';
import {
  authReducer,
  eventReducer,
  modalReducer
} from '../../../../Store/Selectors';
import ButtonPickerColor from '../../ButtonPickerColor/ButtonPickerColor';
import { State } from '../../../../Models/State';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { EVENT_NAME_MODAL } from '../../../../Store/Modal/Types';

export default function NewEventModal() {
  const eventNameRef = useRef<HTMLInputElement>(null);
  const { state } = useSelector(eventReducer);

  const {
    EVENT_NAME_MODAL: { open, data }
  } = useSelector(modalReducer);
  const {
    user: {
      metadata: { customer }
    }
  } = useSelector(authReducer);
  const [selectedColor, setSelectedColor] = useState(
    data?.event?.color ?? '#f99b9c'
  );

  const dispatch = useDispatch();

  const addEventName = () => {
    if (eventNameRef?.current?.value === '') {
      toast('Please type a name.', 'error');
      return;
    }

    if (eventNameRef?.current?.value) {
      if (data?.cartbox) {
        dispatch(
          eventActions.addCartboxEvent({
            params: {
              customer,
              tag_name: eventNameRef?.current?.value,
              color: selectedColor
            },
            cart_box: data.cartbox
          })
        );
      } else {
        if (data?.isUpdate) {
          dispatch(
            eventActions.updateEvent({
              events: [
                {
                  _KEY: data.event.event_key,
                  color: selectedColor,
                  tag_name: eventNameRef.current.value
                }
              ],
              helperAction: actionsModal.setCloseModal({
                modal: EVENT_NAME_MODAL
              })
            })
          );
        }
      }
    }
  };

  const closeModal = () =>
    dispatch(
      actionsModal.setCloseModal({ modal: ModalTypes.EVENT_NAME_MODAL })
    );

  return (
    <div>
      <Modal isOpen={open} size="lg" toggle={closeModal}>
        <ModalBody className="px-4">
          <div className="d-flex justify-content-between">
            <h4 className="title-modal-event font-weight-bold">
              Relate any event name to an item to organise your weddings and
              events:
            </h4>
            <FontAwesomeIcon
              icon={faTimes}
              className="size-icon"
              onClick={closeModal}
            />
          </div>

          <p className="mb-4">
            The event name will be visible in your Cart, Checkout, Order
            history, and on your receipt. Also, this helps you to checkout
            complete events, or to hold them separate, for later.
          </p>
          <p className="mb-2">
            Type your first event name (you can always change it later):
          </p>

          <div className="d-lg-flex flex-sm-column flex-lg-row justify-content-between align-items-center  mb-2">
            <input
              className="form-control flex-fill"
              defaultValue={data?.event?.tag_name ?? ''}
              disabled={state === State.PENDING}
              maxLength={40}
              ref={eventNameRef}
              placeholder="“Wedding John and Maria”"
            />
            <div className="d-flex align-items-center w-100 mt-3 mt-lg-0">
              <ButtonPickerColor
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                className="ml-2"
              />
              <button
                className="btn btn-primary ml-3 px-5"
                disabled={state === State.PENDING}
                onClick={addEventName}>
                {state === State.PENDING && (
                  <FontAwesomeIcon icon={faSpinner} pulse className="mr-2" />
                )}
                Continue
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
