import React from 'react';

type Props = {
  informationPercentage: number;
};

const PercentageComponent: React.FC<Props> = ({ informationPercentage }) => {
  const companyStyle = {
    width: informationPercentage + '%'
  };

  return (
    <div className="d-flex information-percentage-container">
      <div
        className="information-percentage-container-loader"
        style={companyStyle}></div>
      <span className="position-absolute">
        {'Now filled ' + informationPercentage + '%'}
      </span>
    </div>
  );
};

export default PercentageComponent;
