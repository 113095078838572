import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  CONFIRM_MODAL,
  PAYMENT_LIST_MODAL,
  PAYMENT_MODAL
} from '../../../../Store/Modal/Types';
import { Card } from '../../../../Models/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { toast } from '../../../../Utils/Utils';
import MixpanelService from '../../../../Services/MixpanelService';
import './paymentOptionModal.scss';
import { RootState } from '../../../../Store/Reducers';
import { actionsCard } from '../../../../Store/Card/Slice';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { State } from '../../../../Models/State';

type Props = {
  className?: string;
};

const PaymentOptionModal: React.FC<Props> = ({ className }) => {
  const { paymentListModal, confirmModal } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const { cards, state } = useSelector((state: RootState) => state.CardReducer);
  const { user } = useSelector((state: RootState) => state.AuthReducer);
  const [selectedCardKey, setSelectedCardKey] = useState<string>('');
  const [selectedCard, setSelectedCard] = useState<Card>();
  const [cardToDelete, setCardToDelete] = useState<Card>();
  const dispatch = useDispatch();
  const customer = user.metadata.customer;

  const trackMixPanel = useCallback(() => {
    if (cardToDelete) {
      MixpanelService.track('remove_card', {
        cardBrand: cardToDelete.card_brand,
        from: paymentListModal.data.from
      });
    }
  }, [cardToDelete, paymentListModal.data.from]);

  useEffect(() => {
    if (confirmModal?.data && confirmModal.data.delete && cardToDelete) {
      trackMixPanel();
      dispatch(
        actionsCard.deleteCard({
          customer,
          cardKey: cardToDelete._KEY,
          isDefault: cardToDelete.is_default
        })
      );
      dispatch(actionsModal.setCleanModal({ modal: CONFIRM_MODAL }));
    }
  }, [confirmModal, cardToDelete, dispatch, customer, trackMixPanel]);

  const toggle = () =>
    dispatch(actionsModal.setCloseModal({ modal: PAYMENT_LIST_MODAL }));

  const selectCard = (card: Card) => {
    if (selectedCardKey !== card._KEY) {
      setSelectedCard(card);
      setSelectedCardKey(card._KEY);
    }
  };
  const selectDefault = async () => {
    const card = selectedCard;
    if (card && !card.is_default) {
      dispatch(actionsCard.changeDefaultCard({ cards, card }));
    } else {
      toast('The payment method selected is already default.', 'info');
    }
  };

  const deleteCard = (card: Card) => {
    if (state !== State.PENDING) {
      if (cards.length === 1) {
        toast('You must have at least one payment option.', 'show');
      } else {
        setCardToDelete(card);
        const data = {
          body: `Are you sure to delete ${card.card_name}?`,
          delete: true,
          cancelText: 'No',
          okText: 'Yes',
          footerEnabled: true,
          result: false
        };
        dispatch(
          actionsModal.setOpenModal({
            modal: CONFIRM_MODAL,
            data: data
          })
        );
      }
    }
  };

  return (
    <div>
      <Modal
        size="lg"
        isOpen={paymentListModal.open}
        toggle={toggle}
        className={className}>
        <ModalHeader toggle={toggle}>Your current payment details</ModalHeader>

        <ModalBody>
          <ul className="list-group">
            {cards.map((item: Card) => (
              <li
                className={`list-group-item card-list-item selectable pointer ${
                  selectedCardKey === item._KEY ? 'selected' : ''
                }`}
                key={item._KEY}
                onClick={() => selectCard(item)}>
                <div className="row">
                  <div
                    className="card-payment-method col-9"
                    style={{ backgroundColor: 'inherit' }}>
                    <div className="media">
                      <span
                        className={`card-payment-method__icon ${item.icon}`}
                      />
                      <div className="media-body">
                        <div className="row">
                          <div className="text-ellipsis col-3">
                            {item.card_name}
                          </div>
                          <div className="col-3">
                            <span className="h6 mr-1">
                              &#8226;&#8226;&#8226;&#8226;
                            </span>{' '}
                            {item.card_last4}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-end p-2 col-3">
                    {item.is_default && (
                      <span
                        id="payment-default"
                        className={`btn py-0 pr-2 ${
                          item.is_default
                            ? 'btn-outline-success'
                            : 'btn-success'
                        }`}>
                        Default
                        <i
                          className={`fal fa-fw text-ibf-bold ${
                            item.is_default ? 'fa-check-circle' : 'fa-circle'
                          }`}
                        />
                      </span>
                    )}
                    <span
                      id="payment-remove"
                      className="text-danger ml-4"
                      onClick={() => deleteCard(item)}>
                      {state === State.PENDING ? (
                        <FontAwesomeIcon icon={faSpinner} pulse />
                      ) : (
                        <FontAwesomeIcon icon={faTrashAlt} color="red" />
                      )}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </ModalBody>

        <ModalFooter>
          <button
            id="payment-choose"
            className="btn btn-success"
            disabled={!selectedCardKey}
            onClick={selectDefault}>
            Set as default
          </button>
          <button
            id="payment-add"
            className="btn btn-primary"
            onClick={() =>
              dispatch(
                actionsModal.setOpenModal({
                  modal: PAYMENT_MODAL,
                  data: { from: 'paymentOptions' }
                })
              )
            }>
            Add card
          </button>
          <button className="btn btn-secondary" onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PaymentOptionModal;
