import React, { useCallback, useEffect, useState } from 'react';
import FlowerList from '../../AllFlowersListComponent/FlowerList';
import FlowerListOptionsComponent from '../../AllFlowersListComponent/FlowerListOptionsComponent';
import { useSelector } from 'react-redux';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardComponent from '../../../DashboardComponent/DashboardComponent';
import GrowerList from '../../GrowerListComponent/GrowerList';
import ColorList from '../../ColorListComponent/ColorList';
import MyAccountList from '../MyAccountList/MyAccountList';
import FavoriteList from '../../FavoriteListComponent/FavoriteList';
import { RootState } from '../../../../Store/Reducers';

type Props = {
  mainMenu: any;
  first: boolean;
  second: boolean;
  visible: any;
  setSecond: (second: boolean) => void;
  closeMenu: () => void;
  getToSecondPage: (secondPage: string) => void;
  secondPage: string;
  closeMenuDashboard: () => void;
  back: () => void;
};

const OptionListComponent: React.FC<Props> = ({
  visible,
  mainMenu,
  first,
  second,
  setSecond,
  closeMenu,
  getToSecondPage,
  secondPage,
  closeMenuDashboard,
  back
}) => {
  const { categoryList } = useSelector(
    (state: RootState) => state.ProductReducer
  );
  const [detailVariety, setDetailVarieties] = useState({
    show: false,
    category: { name: '', key: '' }
  });

  const backFlowerList = () => {
    setSecond(true);
    setDetailVarieties({
      show: false,
      category: { name: '', key: '' }
    });
  };

  useEffect(() => {
    if (detailVariety.show) {
      setSecond(false);
    }
  }, [setSecond, detailVariety]);

  const renderSecondPage = (list: string) => {
    switch (list) {
      case 'flowers':
        return (
          <FlowerList
            mobile
            closeMenuMobile={closeMenu}
            setVarietyDetail={setDetailVarieties}
          />
        );
      case 'growers':
        return <GrowerList closeMenuMobile={closeMenu} mobile />;
      case 'colors':
        return <ColorList closeMenuMobile={closeMenu} mobile />;
      case 'myAccount':
        return (
          <MyAccountList
            goToDashboard={() => getToSecondPage('dashboard')}
            closeMenu={closeMenu}
          />
        );
      case 'dashboard':
        return <DashboardComponent toggleDashboard={closeMenuDashboard} />;
      case 'favorites':
        return <FavoriteList mobile closeMenuMobile={closeMenu} />;
      default:
        return;
    }
  };

  const renderFirstPage = useCallback(() => {
    return (
      <div>
        <ul className="pt-2 pl-3 pr-5">
          {mainMenu.map((menu: any, index: number) => {
            return (
              <li
                key={`option-item-${index}`}
                onClick={menu.function}
                className={`d-flex ${
                  menu.icon && !menu.arrow ? '' : 'justify-content-between'
                } align-items-center my-3 pointer list-unstyled w-75`}>
                <div className="d-flex align-items-center">
                  {menu.icon && (
                    <FontAwesomeIcon icon={menu.icon} className="mr-2" />
                  )}
                  <span className="underline">{menu.label}</span>
                </div>
                {menu.arrow && (
                  <FontAwesomeIcon icon={faChevronRight} size="lg" />
                )}
                {menu.space && <div className="my-4" />}
              </li>
            );
          })}
        </ul>

        {/*<span
          onClick={() => redirect('FEEDBACK')}
          className="pointer border rounded border-dark px-4 position-absolute mx-4 mt-5">
          Upcoming website updates and giving your feedback »
        </span>*/}
      </div>
    );
  }, [mainMenu]);

  const renderThirdPage = () => {
    const productGroup = categoryList.find(
      (productGroup: any) =>
        productGroup.info.category === detailVariety.category.name
    );

    switch (true) {
      case detailVariety.show:
        return (
          <FlowerListOptionsComponent
            items={productGroup && productGroup.items}
            mobile
            closeMenuMobile={closeMenu}
          />
        );
      default:
        return;
    }
  };

  return (
    <>
      <div
        className={
          first && !second ? 'ibf-menu-show-window' : 'ibf-menu-hide-window'
        }>
        {renderFirstPage()}
      </div>

      <div
        className={
          !first && second && secondPage === 'dashboard'
            ? 'ibf-menu-show-window'
            : 'ibf-menu-hide-window'
        }>
        <div className="px-4 pt-3 pointer font-weight-bold">
          <u onClick={back}>{'< Back'}</u>
        </div>

        {renderSecondPage(secondPage)}
      </div>

      <div
        className={
          !first && second && secondPage !== 'dashboard'
            ? 'ibf-menu-show-window'
            : 'ibf-menu-hide-window'
        }>
        <div className="px-4 pt-3 pointer font-weight-bold">
          <u onClick={visible.previous}>{'< Back'}</u>
        </div>
        {renderSecondPage(secondPage)}
      </div>

      <div
        className={
          !first && !second && detailVariety.show
            ? 'ibf-menu-show-window'
            : 'ibf-menu-hide-window'
        }>
        <div className="px-4 pt-3 pointer">
          <u onClick={backFlowerList} className="font-weight-bold">
            {'< Back'}
          </u>
        </div>
        {renderThirdPage()}
      </div>
    </>
  );
};

export default OptionListComponent;
