import React, { useEffect, useRef, useState } from 'react';
import Environment from './../../../../Environment';
import { useSelector } from 'react-redux';
import InventoryProduct from './../../../../Models/InventoryProduct';
import BoxProductInfoComponent from './BoxProductInfoComponent';
import BoxProductFormComponent from './BoxProductFormComponent';
import { RootState } from '../../../../Store/Reducers';
import './boxProduct.scss';

type Props = {
  product: InventoryProduct;
  index?: number;
  openPopover: boolean;
  modalWidth: number | undefined;
  setOpenPopover: (open: boolean) => void;
};

const BoxProductComponent: React.FC<Props> = ({
  product,
  index,
  openPopover,
  setOpenPopover,
  modalWidth
}) => {
  const productRef = useRef(product);
  const productPhotos = productRef?.current?.images?.map(
    (i) => Environment.publicPath + i
  );
  const [listSize, setListSize] = useState('1');
  const { isAList } = useSelector((state: RootState) => state.CustomBoxReducer);
  useEffect(() => {
    productRef.current = product;

    return () => {
      productRef.current = new InventoryProduct();
    };
  }, [product]);
  return (
    <div
      className={
        isAList
          ? 'box-product-grid ibf-border mb-2 p-1 align-items-center'
          : 'ibf-card-shadow h-100'
      }>
      <div className={isAList ? 'box-product-info-grid' : 'h-80'}>
        <BoxProductInfoComponent
          product={productRef.current}
          productPhotos={productPhotos}
        />
      </div>

      <div className={isAList ? '' : 'h-20'}>
        <BoxProductFormComponent
          buttonName="Add"
          setListSize={setListSize}
          listSize={listSize}
          product={productRef.current}
        />
      </div>
    </div>
  );
};

export default React.memo(BoxProductComponent);
