import React from 'react';
import Environment from '../../Environment';
import { actionsModal } from '../../Store/Modal/Slice';
import { DELIVERY_SCHEDULE_MODAL } from '../../Store/Modal/Types';
import { useDispatch } from 'react-redux';

const redirectUrlIbuyflowers = (text: string) => {
  return text.substring(0, text.length - 3) + '#/search';
};

export const TextDelivered = () => {
  const dispatch = useDispatch();
  const openModal = () => {
    dispatch(actionsModal.setOpenModal({ modal: DELIVERY_SCHEDULE_MODAL }));
  };
  return (
    <div className="text-black">
      <p className="text-black">
        <b>Real fresh cut quality flowers</b> from Latin America, US and
        Holland, delivered <b>within 72 hours</b> to your doorstep{' '}
        <span className="underline text-black pointer" onClick={openModal}>
          (see the delivery schedule)
        </span>
      </p>
      <p className="text-black">
        <b>Need within 24h?</b> Then choose tomorrow’s date in the calendar.
        When you shop from US growers, those flowers have the quality you would
        expect from your local wholesaler.
      </p>
      <p className="text-black">
        Our business partners FedEx and DHL deliver your order to your shop or
        event. You receive track&trace information on the day flowers are
        packed.
      </p>

      <p className="text-black">
        <b>So, no more need to drive to your local wholesaler.</b>
      </p>
    </div>
  );
};

export const SecurePrices = () => {
  return (
    <div className="text-black">
      <p className="text-black">
        <b className="d-block">Easy to budget: </b>
        Simply select a date, add flowers to your cart and checkout. The price
        you see is what you get.
      </p>
      <p className="text-black">
        It’s safe, your credit card will only be charged a few days before
        packing. Cancel before packing is free.
      </p>

      <p className="text-black">
        Because growers offer their inventory up to months out, you can
        calculate your prices months in advance.
      </p>
    </div>
  );
};

export const ShopGrowers = () => {
  return (
    <div className="text-black">
      <p className="text-black">
        <b className="d-block">100+ growers:</b>
        Order directly from growers in Ecuador, Colombia, US and Holland.
      </p>

      <p className="text-black">
        <a
          className="underline text-black"
          href={`${redirectUrlIbuyflowers(Environment.baseUrl)}`}
          rel="noopener noreferrer">
          Start shopping all growers
        </a>
      </p>
    </div>
  );
};

export const QualityRating = () => {
  return (
    <div>
      <p className="text-black">
        <b className="d-block">Quality rating:</b>
        See what other florists and wedding & event planners say about the
        quality of the growers:
      </p>
      <div className="text-wrap">
        <a
          className="underline text-black"
          href="http://ibuyflowers.com/reviews"
          target="_blank"
          rel="noopener noreferrer">
          Reviews - iBuyFlowers
        </a>
      </div>
    </div>
  );
};

export const Website = () => {
  return (
    <div>
      <p className="text-black">
        <b className="d-block">Updates and your feedback:</b>
        iBuyFlowers is constantly making updates based on feedback.
      </p>

      <p className="text-black">
        <a
          href="https://www.ibuyflowers.com/feedback"
          className="text-black underline"
          target="_blank"
          rel="noopener noreferrer">
          Please share all your thoughts here »
        </a>
      </p>
    </div>
  );
};
