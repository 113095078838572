import React, { useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { parse } from 'date-fns';
import { DateUtils } from 'react-day-picker';
import CalendarInputComponent from './CalendarInputComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faUndo,
  faChevronDown
} from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router';
import { eventActions } from '../../Store/Event/Slice';
import { useDispatch, useSelector } from 'react-redux';
import { authReducer, eventReducer } from '../../Store/Selectors';
import { Dropdown } from 'antd';
import MobileFilteredMenu from './MobileFilteredMenu';
import { EventCartbox } from '../../Models/EventCartbox';
import { TypeTable } from '../OrdersByEtaComponent/OrdersByEtaComponent';
const FiltersTableComponent = (props: any) => {
  const history = useHistory();
  const [selectedDay, setSelectedDay] = useState<string | Date>('');
  const [poNumber, setPoNumber] = useState('');
  const [productName, setProductName] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [growerPicked, setGrowerPicked] = useState('Pick a grower');
  const {
    user: {
      metadata: { is_available_event_name_view }
    }
  } = useSelector(authReducer);
  const [showVisibleDropdown, setShowVisibleDropdown] = useState<boolean>(
    false
  );
  const events = useSelector(eventReducer);
  const dispatch = useDispatch();

  const handleOrderNumber = (event: any) => {
    setOrderNumber(event.target.value);
  };

  const highlighted = (calendarDay: any) => {
    let response = false;
    props.daysAvailable.forEach((dayAvailable: any) => {
      dayAvailable.setHours(0, 0, 0);
      calendarDay.setHours(0, 0, 0);

      if (dayAvailable.getTime() === calendarDay.getTime()) {
        response = true;
      }
    });
    return response;
  };

  const parseDate = (str: any, format: any) => {
    const parsed = parse(str, format);
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  };

  // when the user types something in this field let's start the search automatically
  const handleDayChange = (day: any) => {
    // check first if the returned value is a valid date
    if (day instanceof Date) {
      setSelectedDay(day);

      // only start the search when in PURCHASE_DATE
      if (props.type === TypeTable.PURCHASE_DATE) {
        props.searchHandler({
          poNumber,
          orderNumber,
          growerPicked,
          selectedDay: day
        });
      }
    }
  };

  // when the user types something in this field let's start the search automatically
  const handlePoNumber = (event: any) => {
    setPoNumber(event.target.value);

    // only start the search when in PURCHASE_DATE
    if (props.type === TypeTable.PURCHASE_DATE) {
      props.searchHandler({
        selectedDay,
        orderNumber,
        growerPicked,
        poNumber: event.target.value
      });
    }
  };

  // this fields are only used in ARRIVAL_DATE

  const handleGrowerPicker = (event: any) => {
    setGrowerPicked(event.target.value);
  };

  const handleProductName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductName(event.target.value);
  };

  const handleEventName = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let data = event.target.value;

    dispatch(eventActions.setEventName(data));
  };

  const clearInputs = () => {
    setSelectedDay('');
    setPoNumber('');
    setOrderNumber('');
    setProductName('');
    setGrowerPicked('Pick a grower');
    props.resetFilterHandler();
    dispatch(eventActions.setEventName(''));
    dispatch(eventActions.setFilteredSearchEvent('clearFilter'));
  };

  const handleSearch = () => {
    let searchParams: { [key: string]: string | Date };

    let eventName = events.eventName;

    // if the select dropdown is in the default state (pick a grower), then the user has not selected anything

    if (eventName !== '') {
      dispatch(eventActions.setFilteredSearchEvent('eventName'));
      searchParams = {
        eventName
      };
    } else {
      searchParams = {
        selectedDay,
        poNumber,
        orderNumber,
        growerPicked,
        eventName,
        productName
      };
    }

    if (growerPicked === 'Pick a grower') {
      searchParams = {
        selectedDay,
        poNumber,
        orderNumber,
        growerPicked: '',
        eventName,
        productName
      };
    }
    Object.keys(searchParams).some((key) => searchParams[key]) &&
      props.searchHandler(searchParams);
  };
  const handleDropdownVisibility = () => {
    setShowVisibleDropdown((prevState) => !prevState);
  };
  // conditional elements that are shown only in ARRIVAL_DATE, otherwise let's hide them
  return (
    <div className="d-flex flex-md-column justify-content-start mb-4 pt-4 flex-grow-1">
      <div className="d-flex flex-grow-1">
        <div
          className={`d-flex flex-column d-md-flex  ${
            props.type !== TypeTable.ARRIVAL_DATE
              ? 'flex-grow-0'
              : 'flex-grow-1'
          }`}>
          <div className="d-flex flex-md-column flex-md-grow-0 pr-lg-2 pr-xl-5">
            <label className="mr-2 mr-sm-4 mr-md-0"> Show by: </label>
            <div className="d-flex justify-content-sm-between flex-md-column  align-items-start mr-4 flex-grow-1 flex-sm-grow-0 flex-md-grow-1 ">
              <div className="form-check mr-2 ">
                <input
                  type="radio"
                  className="form-check-input"
                  id="arrival"
                  defaultChecked={
                    window.location.hash.indexOf('ordersbyarrival') !== -1
                  }
                  onChange={() => history.push('/ordersbyarrival')}
                />
                <label htmlFor="arrival">Arrival date</label>
              </div>
              <div className="form-check text-nowrap">
                <input
                  type="radio"
                  className="form-check-input"
                  id="purchase"
                  defaultChecked={
                    window.location.hash.indexOf('ordersbypurchase') !== -1
                  }
                  onChange={() => history.push('/ordersbypurchase')}
                />
                <label htmlFor="purchase">Purchase date</label>
              </div>
            </div>
          </div>

          <Dropdown
            visible={showVisibleDropdown}
            onVisibleChange={handleDropdownVisibility}
            trigger={['click']}
            placement="bottomCenter"
            className="d-md-none ant-scroll-number-custom-component"
            overlay={
              <MobileFilteredMenu
                selectedDay={selectedDay}
                handleDayChange={handleDayChange}
                parseDate={parseDate}
                highlighted={highlighted}
                handlePoNumber={handlePoNumber}
                poNumber={poNumber}
                handleOrderNumber={handleOrderNumber}
                orderNumber={orderNumber}
                handleGrowerPicker={handleGrowerPicker}
                growerPicked={growerPicked}
                growers={props.growers}
                handleEventName={handleEventName}
                events={events.eventName}
                eventList={props.eventList}
                handleSearch={handleSearch}
                clearInputs={clearInputs}
                onClick={handleDropdownVisibility}
                type={props.type}
              />
            }>
            <div className="align-items-center d-md-none position-relative ">
              <button className="btn btn-block btn-outline-dark">
                Search
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="position-absolute ml-1 mt-1"
                />
              </button>
            </div>
          </Dropdown>
        </div>

        <div
          className={`border d-none d-md-flex  rounded px-3 pb-2 flex-wrap flex-xl-nowrap flex-column ${
            props.type !== TypeTable.ARRIVAL_DATE ? 'flex-grow-1' : ''
          }`}>
          <div className="d-flex pt-2 flex-wrap flex-xl-nowrap">
            <div className="d-flex flex-column ibf-table-input-group">
              <label>Arrival date</label>
              <DayPickerInput
                value={selectedDay}
                onDayChange={handleDayChange}
                // formatDate={FiltersTableComponent.formatDate}
                parseDate={parseDate}
                format={'MM/DD/YYYY'}
                component={CalendarInputComponent}
                inputProps={{
                  ref: null
                }}
                dayPickerProps={{
                  className: 'calendar-arrival',
                  modifiers: { highlighted },
                  modifiersStyles: {
                    highlighted: {
                      backgroundColor: '#8cd198'
                    }
                  },
                  disabledDays: {
                    daysOfWeek: [0, 6]
                  }
                }}
              />
            </div>

            <div
              className={
                props.type === TypeTable.ARRIVAL_DATE
                  ? 'ibf-table-input-group mr-md-2 '
                  : 'mx-0 mx-md-2 mb-2 mb-md-0'
              }>
              <label className="ml-md-2 ml-lg-0">PO #</label>
              <input
                className="form-control mt-2 ml-sm-2 ml-lg-0 px-mb-0 px-lg-0"
                onChange={handlePoNumber}
                value={poNumber}
              />
            </div>
            {props.type !== TypeTable.ARRIVAL_DATE && (
              <div className="align-self-center mt-sm-3 mt-lg-0 mt-xl-3 ml-md-1 pt-sm-3 pt-xl-4">
                <button
                  className="btn btn-purple btn-font-sm "
                  onClick={clearInputs}>
                  <FontAwesomeIcon className="ml-2" icon={faUndo} /> Reset
                  Filters
                </button>
              </div>
            )}

            {props.type === TypeTable.ARRIVAL_DATE && (
              <>
                <div className="ibf-table-input-group ml-md-1 ml-lg-0">
                  <label>Order #</label>
                  <input
                    className="form-control mt-2 px-mb-0 px-lg-0"
                    onChange={handleOrderNumber}
                    value={orderNumber}
                  />
                </div>
                <div className="ibf-table-input-group ml-md-1 ml-lg-0">
                  <label> Product name</label>
                  <input
                    className="form-control mt-2 px-mb-0 px-lg-0"
                    onChange={handleProductName}
                    value={productName}
                  />
                </div>

                <div className="ibf-table-input-group">
                  <label className="ml-sm-5 ml-md-0">Grower</label>
                  <br />
                  <select
                    className="custom-select mt-2 ml-sm-5 ml-md-1 ml-lg-0"
                    onChange={handleGrowerPicker}
                    value={growerPicked}>
                    <option disabled>Pick a grower</option>
                    {props.growers.map((item: any) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {is_available_event_name_view && (
                  <div className="ibf-table-input-group ml-md-2 ml-lg-0">
                    <label>Event name</label>
                    <br />
                    <select
                      className="custom-select mt-2"
                      onChange={handleEventName}
                      value={events.eventName}>
                      <option value="" disabled>
                        Select event name
                      </option>
                      {props.eventList.map((item: EventCartbox) => {
                        return (
                          <option key={item._KEY} value={item._KEY}>
                            {item.tag_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {props.type === TypeTable.ARRIVAL_DATE && (
                  <div className="ibf-table-input-group mt-md-4 mt-lg-2 pt-md-3 mt-xl-4   pt-xl-3 ml-md-1 ml-lg-0">
                    <button
                      className="btn btn-purple mr-2 btn-font-sm"
                      onClick={handleSearch}>
                      <FontAwesomeIcon className="ml-2" icon={faSearch} />{' '}
                      Search
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="d-flex justify-content-xl-end mr-3 mt-1 pr-1">
            {props.type === TypeTable.ARRIVAL_DATE && (
              <button
                className="btn btn-purple btn-font-sm "
                onClick={clearInputs}>
                <FontAwesomeIcon className="ml-2" icon={faUndo} /> Reset Filters
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersTableComponent;
