import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Filter from '../../../Models/Filter';
import FilterItem from '../../../Models/FilterItem';
import { RootState } from '../../../Store/Reducers';
import { getCountryName } from '../../../Utils/Utils';
import Environment from '../../../Environment';
import { loadingReducer } from '../../../Store/Selectors';

type Props = {
  className?: string;
  styles?: object;
};

const ResultsComponents: React.FC<Props> = ({ className, styles }) => {
  const { totalItems, filters } = useSelector(
    (state: RootState) => state.ProductReducer
  );

  const {
    search,
    substitute,
    filters_conditions,
    selectedGrower
  } = useSelector((state: RootState) => state.FilterReducer);

  const { filterLoading } = useSelector(loadingReducer);

  const [filterList, setFilterList] = useState<Array<string>>([]);

  useEffect(() => {
    let filteredElements =
      filters &&
      filters.reduce((arr: Array<string>, item: Filter) => {
        item.values.forEach((filterItem: FilterItem) => {
          if (filterItem.selected) {
            if (filterItem.key === selectedGrower.key) {
              arr.push(selectedGrower.name);
            } else {
              arr.push(filterItem.value);
            }
          }
        });
        return arr;
      }, []);
    search && setFilterList(filteredElements);
    !!filters_conditions && setFilterList(filteredElements);
  }, [filters, search, filters_conditions, selectedGrower]);

  return (
    <>
      <span className={className} style={styles}>
        Home / All Flowers /{' '}
        {`${filterLoading ? 'Searching' : totalItems} ${
          substitute ? '' : 'results'
        } ${
          filters_conditions.favorites
            ? 'for Favorites'
            : filters_conditions.combo
            ? 'for Mixed Boxes'
            : ''
        }
        ${
          filters_conditions.favorites &&
          totalItems === 0 &&
          selectedGrower.name
            ? `, ${selectedGrower.name}`
            : ''
        }
        ${
          !filterLoading && filters_conditions.country
            ? filters_conditions.country?.length > 0
              ? 'for "' +
                filters_conditions.country
                  .map((country: string) =>
                    getCountryName(Environment.country_flag_map[country])
                  )
                  .join(', ') +
                '"'
              : ''
            : ''
        }
        
 
        ${
          !filterLoading && substitute
            ? `${
                totalItems === 0 ? 'results for' : 'available'
              } substitutes for "${substitute}"`
            : !search && filterList && filterList.length
            ? 'for "' + filterList.join(', ') + '"'
            : search && `for "${search}"`
        } 
         ${
           filterList && search && filterList.length
             ? '(' + filterList.join(', ') + ')'
             : ''
         }`}
      </span>
    </>
  );
};

export default ResultsComponents;
