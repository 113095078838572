import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Popover } from 'antd';
type Props = {
  text?: string;
  content?: JSX.Element;
  className?: string;
};
function UspItem({ text, content, className }: Props) {
  return (
    <Popover
      placement={'bottomRight'}
      trigger="click"
      content={content}
      overlayClassName={className}>
      <div className="d-flex align-items-center pointer">
        <FontAwesomeIcon icon={faCheck} className="color-green-light" />
        <span className="ml-2 border-bottom-dashed">{text}</span>
      </div>
    </Popover>
  );
}

export default UspItem;
