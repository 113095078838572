import React, { useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { DeepMap, FieldError, UseFormRegister } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { authReducer, modalReducer } from '../../../../Store/Selectors';
import { CustomerRegisterModel } from '../../../../Models/CustomerRegisterModel';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { CHANGE_PASSWORD_MODAL } from '../../../../Store/Modal/Types';
import { checkEmailRequest } from '../../../../Services/AuthService';
import { State } from '../../../../Models/State';
import { EmailRequest } from '../../../../Models/Auth';
import { convertDateToHourDate } from '../../../../Utils/Utils';
import * as ModalTypes from '../../../../Store/Modal/Types';

type Props = {
  register: UseFormRegister<CustomerRegisterModel>;

  errors: DeepMap<CustomerRegisterModel, FieldError>;
  setValue: any;
  allDisabled: boolean;
  setAllDisabled: () => void;
};

const validationInputMessage = (
  <span className="ibf-font-12 color-red">Please fill in this info.</span>
);
const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === ' ') {
    e.preventDefault();
  }
};
const ContactDetailsComponent = ({
  register,
  errors,
  setValue,
  allDisabled,
  setAllDisabled
}: Props) => {
  const {
    user: {
      email,
      first_name,
      last_name,
      key,
      metadata: {
        guest,
        customer_name,
        customer_resale_number,
        from_manager,
        main_user_first_name,
        main_user_last_name,
        main_user_email
      }
    }
  } = useSelector(authReducer);

  const [emailRequestInProcess, setEmailRequestInProcess] = useState({
    exist: false,
    new_email: '',
    date: '',
    loading: true
  });
  const dispatch = useDispatch();
  const { CHANGE_EMAIL_MODAL } = useSelector(modalReducer);

  React.useEffect(() => {
    const getEmailRequestStatus = async () => {
      const response = await checkEmailRequest(key);
      const process: EmailRequest =
        response?.length > 0 &&
        response.find(
          (request: { status: State; new_email: string; created_at: string }) =>
            request.status === State.PENDING
        );
      if (process) {
        setEmailRequestInProcess({
          exist: true,
          new_email: process.new_email,
          date: process.created_at,
          loading: false
        });
      } else {
        setEmailRequestInProcess((prevState) => ({
          ...prevState,
          loading: false
        }));
      }
    };
    getEmailRequestStatus();
  }, [key, CHANGE_EMAIL_MODAL.open]);

  return (
    <div>
      <b>Your login</b>
      <div className="input-register my-4">
        <Input
          type="text"
          id={'company_name'}
          placeholder=" "
          {...register('customer.company_name', {
            required: from_manager ? !from_manager : !allDisabled,
            pattern: !from_manager && /([^\s])/
          })}
          maxLength={35}
          defaultValue={customer_name || ''}
          disabled={allDisabled}
          onChange={(evt) =>
            setValue('customer.company_name', evt.target.value)
          }
        />
        {errors?.customer?.company_name && (
          <span className="ibf-font-12 color-red">
            Please write a company name
          </span>
        )}
        <label htmlFor="company_name" className="register-label">
          Company Name <span className="text-danger"> * </span>{' '}
        </label>
      </div>

      <FormGroup className="input-register mb-4">
        <Input
          type="text"
          id={'first_name'}
          {...register('user.first_name', {
            required: from_manager ? !from_manager : !allDisabled,
            pattern: !from_manager && /([^\s])/
          })}
          data-testid={`
           first_name'
    `}
          defaultValue={
            (from_manager ? main_user_first_name : first_name) || ''
          }
          placeholder=" "
          disabled={allDisabled}
          onChange={(evt) => setValue('user.first_name', evt.target.value)}
        />
        {errors?.user?.first_name && validationInputMessage}
        <label htmlFor="first_name" className="register-label">
          Your first name <span className="text-danger"> * </span>
        </label>
      </FormGroup>

      <FormGroup className="input-register mb-3">
        <Input
          type="text"
          id={'last_name'}
          data-testid={'last_name'}
          placeholder=" "
          disabled={allDisabled}
          {...register('user.last_name', {
            required: from_manager ? !from_manager : !allDisabled,
            pattern: !from_manager && /([^\s])/
          })}
          defaultValue={(from_manager ? main_user_last_name : last_name) || ''}
          onChange={(evt) => setValue('user.last_name', evt.target.value)}
        />
        {errors?.user?.last_name && validationInputMessage}
        <label htmlFor="last_name" className="register-label">
          Your last name <span className="text-danger"> * </span>
        </label>
      </FormGroup>

      <FormGroup className="input-register my-4">
        <Input
          type="email"
          autoComplete="off"
          placeholder=" "
          id={'user_email'}
          data-testid="user-email"
          onKeyDown={handleKeyDown}
          disabled
          value={(from_manager ? main_user_email : email) || ''}
        />

        {!emailRequestInProcess?.loading && emailRequestInProcess.exist ? (
          <span className="color-green my-2">
            Your request to change your email address to{' '}
            <b>{emailRequestInProcess.new_email}</b> is send on{' '}
            {convertDateToHourDate(emailRequestInProcess.date)}. It will be
            confirmed normally within 48 business hours to your current and new
            email address. Then you'll only be able to login with this new email
            address.
          </span>
        ) : (
          <u
            className="pointer"
            onClick={() =>
              dispatch(
                actionsModal.setOpenModal({
                  modal: ModalTypes.CHANGE_EMAIL_MODAL
                })
              )
            }>
            Change your email address
          </u>
        )}

        <label
          className={'register-label'}
          htmlFor="user_email"
          placeholder=" ">
          Email address <span className="text-danger"> * </span>{' '}
        </label>
      </FormGroup>

      <FormGroup className="input-register mb-4">
        <Input
          type={'password'}
          autoComplete="off"
          placeholder=" "
          id={'Password'}
          onKeyDown={handleKeyDown}
          disabled
          defaultValue={'*********'}
        />

        <label htmlFor="Password" className="register-label">
          Password <span className="text-danger"> * </span>
        </label>
        {!guest && (
          <u
            className="pointer"
            onClick={() =>
              dispatch(
                actionsModal.setOpenModal({ modal: CHANGE_PASSWORD_MODAL })
              )
            }>
            Change your password
          </u>
        )}
      </FormGroup>

      <div className="form-group input-register mb-lg-5">
        <Input
          type="text"
          id={'tax_id'}
          placeholder=" "
          autoComplete="off"
          disabled={allDisabled}
          {...register('customer.tax_id', {
            required: from_manager ? !from_manager : !allDisabled,
            pattern: !from_manager && /([^\s])/
          })}
          defaultValue={customer_resale_number || ''}
          onChange={(evt) => setValue('customer.tax_id', evt.target.value)}
        />
        {errors?.customer?.tax_id && validationInputMessage}
        <label htmlFor="tax_id" className="register-label">
          Resale number <span className="text-danger"> * </span>{' '}
        </label>
      </div>

      {!allDisabled && (
        <div className="d-none d-lg-flex justify-content-start">
          <span className="ibf-link" onClick={setAllDisabled}>
            Cancel
          </span>
        </div>
      )}
    </div>
  );
};

export default ContactDetailsComponent;
