import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faAngleDoubleRight, faHeart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Environment from '../../../Environment';
import FavoriteProduct from './FavoriteProduct';
import Product from '../../../Models/Product';
import MixpanelService from '../../../Services/MixpanelService';
import { RootState } from '../../../Store/Reducers';
import { actionsModal } from '../../../Store/Modal/Slice';
import { CONFIRM_DELIVERY_DATE_MODAL } from '../../../Store/Modal/Types';
import { addObjectParamsToPath } from '../../../Utils/Utils';
import { useHistory } from 'react-router-dom';

type Props = {
  toggleMenu?: () => void;
  mobile?: boolean;
  closeMenuMobile?: () => void;
};

const FavoriteList: React.FC<Props> = ({
  toggleMenu,
  mobile,
  closeMenuMobile
}) => {
  const { navHeight } = useSelector((state: RootState) => state.DisplayReducer);

  const { favorites, date } = useSelector(
    (state: RootState) => state.ProductReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const detail = (product: Product) => {
    dispatch(
      actionsModal.setOpenModal({
        modal: CONFIRM_DELIVERY_DATE_MODAL,
        data: { detail: true, productId: product.key }
      })
    );
    MixpanelService.track('favorite_specific_item', { name: product.name });
    const params: any = {
      id: product.key,
      date
    };
    MixpanelService.track('product_details_view', { ...params });
    history.push(addObjectParamsToPath('/product', params));
    closeMenuMobile && closeMenuMobile();
    toggleMenu && toggleMenu();
  };

  const showFavorites = () => {
    closeMenuMobile && closeMenuMobile();
    toggleMenu && toggleMenu();

    dispatch(
      actionsModal.setOpenModal({
        modal: CONFIRM_DELIVERY_DATE_MODAL,
        data: { favorites: true }
      })
    );
  };

  return (
    <div
      className={`d-flex flex-column ${
        mobile ? '' : 'dropdown-wrapper shadow bg-white px-5'
      }`}
      style={{ top: `${navHeight + 61}px` }}>
      <span className="selected-item d-none d-lg-block" />
      <div className="row justify-content-between w-100 ">
        <div className="d-flex flex-column ">
          <div
            className={`d-flex mt-3 ${
              mobile ? 'mx-4 flex-column' : 'align-items-center'
            }`}>
            <h3 className="mr-5">Favorites ({Object.keys(favorites).length})</h3>
            {Object.keys(favorites).length > 0 && (
              <button className="btn btn-primary" onClick={showFavorites}>
                {' '}
                Shop all available favorites with price info for a delivery date{' '}
                <FontAwesomeIcon className="ml-1" icon={faAngleDoubleRight} />
              </button>
            )}
          </div>
        </div>
        <div className="ibf-link d-none d-lg-block pt-2" onClick={toggleMenu}>
          Close
        </div>
      </div>

      {Object.keys(favorites).length === 0 && (
        <div className="d-flex flex-column mx-4 mx-lg-0">
          <span>You have no favorites yet.</span>
          <span>
            You can simply make a favorite by clicking on the{' '}
            <FontAwesomeIcon icon={faHeart} className="mr-1" />
            heart symbol on any product
          </span>
        </div>
      )}
      <div
        className={`d-flex dropdown-items-wrapper overflow-auto ${
          mobile ? 'justify-content-center' : ''
        }`}>
        <ul
          className={`list-group list-unstyled overflow-auto flex-row flex-wrap ${
            mobile ? 'justify-content-center' : ''
          }`}>
          {Object.values(favorites).map((favorite: Product, index: number) => {
            const productPhotos = favorite?.photos?.map(
              (i: string) => Environment.publicPath + i
            );
            return (
              <li className="mt-2 mr-lg-2" key={'favorite_' + favorite.key}>
                <FavoriteProduct
                  mobile={mobile}
                  productPhotos={productPhotos}
                  product={favorite}
                  detail={() => detail(favorite)}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default FavoriteList;
