import React, { useCallback } from 'react';
import {
  isLessThan15Days,
  convertNumberDateToDate,
  numberDateToDate
} from '../../../Utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import Product from './../../../Models/Product';
import { faAngleDoubleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StoreType from '../../../Models/Redux/Store';
import { actionsProduct } from '../../../Store/Product/Slice';

type Props = {
  product?: Product;
  form?: any;
  detail?: () => void;
};

const ProductStatusComponent: React.FC<Props> = ({ product, form, detail }) => {
  const { date, numberWeek } = useSelector(
    (store: StoreType) => store.ProductReducer
  );
  const dispatch = useDispatch();

  const getSubstitutes = useCallback(() => {
    if (product) {
      dispatch(actionsProduct.getProductReplacements(product));
    }
  }, [dispatch, product]);

  // const {isAList} = useSelector((store: StoreType) => store.LoadingReducer);
  const isLess15Days = isLessThan15Days(convertNumberDateToDate(date));

  const searchText = (
    <li
      key={1}
      className="underline pointer ibf-text-info color-green"
      onClick={getSubstitutes}>
      Search available substitutes
      <FontAwesomeIcon className="ml-1" icon={faAngleDoubleRight} />
    </li>
  );

  const less3weeks = (
    <div className="d-flex flex-column mx-2 color-orange">
      <span>
        Not available at growers right now:
        <ul className="list-unstyled">{searchText}</ul>
      </span>
    </div>
  );

  const more3weeks = (
    <div className="d-flex flex-column mx-2 color-green">
      <span>
        Usually available around {numberDateToDate(date)}. But grower(s) can't
        give price info for this item more then 2 weeks ahead.
        <ul className="list-unstyled">{searchText}</ul>
      </span>
    </div>
  );

  const noAvailable = (
    <div className="d-flex flex-column mx-2 color-red">
      <span>
        This item is not grown in this week:
        <ul className="list-unstyled">
          <li key={0} className="underline pointer">
            See calendar it's grown
          </li>
          {searchText}
        </ul>
      </span>
    </div>
  );

  switch (true) {
    case isLess15Days && !product?.pricing: // Removed !product?.isAvailable &&
      return less3weeks;
    case !isLess15Days && !product?.pricing: // Removed !product?.isAvailable &&
      return more3weeks;
    case !numberWeek: // Removed !product?.isAvailable &&
      return noAvailable;
    default:
      return form;
  }
  // switch (true) {
  //   case !!product.pricing:
  //     return form;
  //   case !product.pricing && product.isAvailable && !isLess15Days:
  //     return (
  //       <>
  //         <div
  //           className={`ibf-product-available-desktop ${
  //             isAList
  //               ? 'd-flex justify-content-between align-items-center'
  //               : 'px-3 mb-3 '
  //           } `}>
  //           <>
  //             <small className={isAList ? 'ibf-product-grown-text' : ''}>
  //               <b className="color-green">
  //                 Product is available for {convertNumberDateMMMDD(date)}, but
  //                 pricing and ordering only 2 weeks before.
  //               </b>
  //             </small>
  //           </>
  //         </div>
  //       </>
  //     );
  //   case product.isAvailable && !product.pricing && isLess15Days:
  //     return form;
  //   case !product.pricing && !product.isAvailable:
  //     return (
  //       <div
  //         className={`ibf-product-available-desktop ${
  //           isAList ? 'd-flex align-items-center' : 'px-3 mb-3 '
  //         } `}>
  //         <NotAvailableComponent isAList={isAList} detail={detail} />
  //       </div>
  //     );
  //   default:
  //     return form;
  // }
};

export default ProductStatusComponent;
