import React, { useState } from 'react';
import { Popover } from 'antd';
import useDeviceDetect from '../../../Hooks/useDeviceDetect';

const helpLinks = [
  {
    name: 'Online buying options',
    link: 'https://help.ibuyflowers.com/knowledge/online-buying-options'
  },
  {
    name: 'Order information',
    link: 'https://help.ibuyflowers.com/knowledge/order-information'
  },
  {
    name: 'Payment & CC information',
    link:
      'https://help.ibuyflowers.com/knowledge/payment-credit-card-information'
  },
  {
    name: 'Quality issues',
    link: 'https://help.ibuyflowers.com/knowledge/quality-issues'
  },
  {
    name: 'Standing Order',
    link: 'https://help.ibuyflowers.com/knowledge/standing-order'
  },
  {
    name: 'Technical related',
    link: 'https://help.ibuyflowers.com/knowledge/technical-related'
  }
];

const contentPopover = (
  <div className="d-flex flex-column color-gray">
    <div className="mb-3 d-flex flex-column">
      {helpLinks.map((option, index) => (
        <u
          className="pointer "
          key={index}
          onClick={() => window.open(option.link)}>
          {option.name}
        </u>
      ))}
    </div>

    <b>Email us: </b>
    <a
      className="pointer underline color-gray"
      href="mailto:customerservice@ibuyflowers.com">
      customerservice@ibuyflowers.com
    </a>
    <span>(we respond normally within 1 workday)</span>
  </div>
);

const TierContactComponent = () => {
  const [visiblePopover, setVisiblePopover] = useState(false);
  const { isMobile } = useDeviceDetect();

  return (
    <div className="">
      <Popover
        content={contentPopover}
        trigger="click"
        visible={visiblePopover}
        className="d-flex align-items-center justify-content-end"
        placement={'bottomRight'}
        onVisibleChange={() => setVisiblePopover((prevState) => !prevState)}>
        <u className="pointer mr-2 ibf-font-16 d-flex align-items-center">
          <span className="d-flex d-lg-none">Help</span>
          <span className="d-none d-lg-flex ">Need Help?</span>
        </u>

        <div
          style={
            isMobile
              ? { width: '25px', height: '25px' }
              : { width: '45px', height: '45px' }
          }
          className="background-primary border rounded-circle d-flex align-items-center justify-content-center pointer">
          <span
            className={`text-white ${
              isMobile ? 'ibf-font-14' : 'ibf-font-30'
            } `}>
            i
          </span>
        </div>
      </Popover>
    </div>
  );
};

export default TierContactComponent;
