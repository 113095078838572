import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../Store/Reducers';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { REPLACEMENTS_MODAL } from '../../../../Store/Modal/Types';
import BoxLoadingSpinner from '../../../CustomBoxModal/BoxProductsComponent/BoxLoadingSpinner';
import { State } from '../../../../Models/State';
import Modal from '../../Modal/ModalComponent';
import ReplacementList from './ReplacementList';
import { actionsProduct } from '../../../../Store/Product/Slice';
import { useHistory } from 'react-router-dom';
import { addObjectParamsToPath } from '../../../../Utils/Utils';

const ReplacementsModal: React.FC = () => {
  const { REPLACEMENTS_MODAL: replacementsModal } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const { productReplaces, state } = useSelector(
    (state: RootState) => state.ProductReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const toggle = () =>
    dispatch(actionsModal.setCloseModal({ modal: REPLACEMENTS_MODAL }));

  const searchReplacements = () => {
    const varietyList = productReplaces.map((item) => item.key);
    dispatch(
      actionsProduct.getProducts({
        loading: true,
        params: {
          page: 1,
          conditions: {
            search: '',
            filters: { variety: varietyList }
          }
        }
      })
    );
    dispatch(actionsProduct.clearUnavailableProducts());
    history.push(
      addObjectParamsToPath('/search', { search_val: '', variety: varietyList })
    );
    toggle();
  };

  return (
    <Modal
      size="lg"
      isOpen={!!replacementsModal.open}
      onClose={toggle}
      title={`Replacements for ${replacementsModal.data?.productName}`}
      okText={
        state !== State.PENDING && productReplaces.length
          ? 'Search replacements'
          : ''
      }
      okClick={searchReplacements}
      cancelText="Close">
      {state === State.PENDING ? (
        <BoxLoadingSpinner
          height="60vh"
          message="LOADING"
          className="d-flex justify-content-center align-items-center w-100"
        />
      ) : productReplaces.length > 0 ? (
        <ReplacementList products={productReplaces} />
      ) : (
        <h3>There are no replacements for this product</h3>
      )}
    </Modal>
  );
};

export default ReplacementsModal;
