import Product from '../Product';

export default class UserMetadata {
  customer: string = '';
  customer_admin!: 2158;
  guest!: boolean;
  customer_name!: string;
  customer_margin!: 27;
  account_manager!: string;
  customer_folder!: string;
  customer_logo!: string;
  customer_zipcode!: string;
  customer_state!: string;
  customer_state_key!: string;
  customer_street!: string;
  customer_city!: string;
  customer_main_zipcode!: string;
  customer_main_state!: string;
  customer_main_street!: string;
  customer_main_city!: string;
  customer_office_phone!: string;
  customer_business!: string;
  customer_events_per_year!: string;
  customer_stores_quantity!: string;
  customer_employees_quantity!: string;
  customer_hear_about_us!: string;
  customer_spend_per_week!: string;
  customer_country!: string;
  customer_store_type!: string;
  customer_mobile_phone!: string;
  customer_office_email!: string;
  customer_tier!: string;
  actual_address!: string;
  main_user_email!: string;
  main_user_id!: number;
  main_user_key!: string;
  main_user_phone!: string;
  customer_facebook_page!: string;
  customer_website!: string;
  customer_instagram_page!: string;
  customer_resale_number!: string;
  from_manager!: false;
  customer_street_label!: string;
  is_gift!: boolean;
  n_orders!: number;
  last_order_number!: number;
  customer_confirm_profile!: boolean;
  customer_signup_date!: string;
  is_available_event_name_view!: boolean;
  preferences: any = {};
  favorites!: Product[];
  isFirstRepurchase!: boolean;
  customer_statement_email_weekly!: boolean;
  main_user_first_name!: string;
  main_user_last_name!: string;
  customer_actual_address!: string;
  active_so!: string;
  customer_closed_days!: string;
}
