import React from 'react';
import { actionsProduct } from '../../Store/Product/Slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  convertDateToNumberDate,
  convertNumberDateToDate,
  addObjectParamsToPath,
  selectDate
} from '../../Utils/Utils';
import {
  filterReducer,
  loadingReducer,
  productReducer
} from '../../Store/Selectors';

import { useLocation, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

type Props = {
  //item: TotalProductByDate;
  item: {
    index: number;
    date: string;
    dateNumber: string | number;
  };
  totalItems: any;
  dateSelected: number | any;
  filterLoading?: boolean;
  enableDateGrower?: boolean;
};
const AvailabilityContent = ({
  enableDateGrower,
  item,
  totalItems,
  dateSelected,
  filterLoading
}: Props) => {
  const dispatch = useDispatch();
  const { date, totalItems: totalItemsToday } = useSelector(productReducer);
  const { filterLoading: loading } = useSelector(loadingReducer);
  const { search, filters_conditions } = useSelector(filterReducer);
  const location = useLocation();
  const history = useHistory();

  const handleOnClick = (handleDate: Date) => {
    let pathname = location.pathname;
    const params = selectDate(
      handleDate,
      search,
      filters_conditions,
      date,
      pathname
    );

    dispatch(
      actionsProduct.setProductDate({
        date: convertDateToNumberDate(handleDate)
      })
    );
    history.push(addObjectParamsToPath(params.pathname, params.search));
  };

  return (
    <>
      {(totalItems[item.dateNumber] !== 0 || enableDateGrower) && (
        <div
          className={`d-flex flex-column ml-1 content-available ${
            item.dateNumber === parseInt(dateSelected)
              ? 'custom-delivery text-primary'
              : ''
          }
        ${totalItems[item.dateNumber + ''] === 0 && 'h-100 mb-0'}
        `}>
          {item.dateNumber === parseInt(dateSelected!) ? (
            <div className="d-flex flex-column text-center font-weight-bold">
              <span className="text-primary d-block font-weight-bold text-nowrap">
                Delivery on
              </span>
              <span className="mt-2 mt-sm-0">{item.date}</span>
              <span className="font-weight-bold text-primary text-center pointer">
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} pulse />
                ) : (
                  `${totalItemsToday} results`
                )}
              </span>
            </div>
          ) : (
            <button
              className={`d-flex flex-column background-transparent border-0  align-items-center justify-content-center ${
                totalItems[item.dateNumber + ''] === 0
                  ? 'cursor-auto bg-ibf-light-gray h-100 text-center'
                  : 'pointer'
              }`}
              onClick={() =>
                handleOnClick(convertNumberDateToDate(item.dateNumber))
              }
              disabled={totalItems[item.dateNumber + ''] === 0}>
              <span className="text-center">{item.date}</span>
              <span className="font-weight-bold text-black text-center">
                {!filterLoading ? (
                  `${totalItems[item.dateNumber + '']} results`
                ) : (
                  <FontAwesomeIcon icon={faSpinner} pulse />
                )}
              </span>
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default AvailabilityContent;
