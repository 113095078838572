import React, { Fragment } from 'react';
import { roundUp, uuidV4 } from '../../Utils/Utils';
import { Popover } from 'antd';
import {
  // faAngleDoubleRight,
  faSpinner
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrderEntity } from '../../Store/Order/Types';

// import { STANDING_ORDER_CREATION_MODAL } from '../../Store/Modal/Types';
// import { actionsModal } from '../../Store/Modal/Slice';
// import { useDispatch, useSelector } from 'react-redux';
// import { standingOrderReducer } from '../../Store/Selectors';
// import Environment from '../../Environment';
/* import ButtonStadingOrder from './ButtonStadingOrder'; */

type Props = {
  soExist: any;
  box: OrderEntity;
  masterlist_prices?: any;
  deleteHandler: (
    cartbox: any,
    customer: any,
    price: any,
    requestedByCustomer: any,
    box: any
  ) => void;
  openCalendar: (itemOrder: any) => void;
  /*  openStandingOrder?: (masterlist_prices: any) => void */
  itemOrder: any;
  popoverContent: JSX.Element;
  isPopoverVisible: boolean;
  shouldDeleteCartbox: (box: OrderEntity) => boolean;
  buttonAction?: JSX.Element;
  isDeleting: boolean;
  onClick?: () => void;
  isCustomBox?: boolean;
};

const OrderBoxRow = ({
  masterlist_prices,
  shouldDeleteCartbox,
  buttonAction,
  //openStandingOrder,
  itemOrder,
  isPopoverVisible,
  popoverContent,
  openCalendar,
  deleteHandler,
  box,
  isDeleting,
  isCustomBox,
  onClick,
  soExist
}: /*  linkAction */
Props) => {
  // so.find((res: any) => res===box._KEY);
  // const dispatch = useDispatch();
  // const openStandingOrder = () => {
  //   dispatch(
  //     actionsModal.setOpenModal({
  //       modal: STANDING_ORDER_CREATION_MODAL,
  //       data: {
  //         prices: masterlist_prices[box._KEY!],
  //         box,
  //         custom: false
  //       }
  //     })
  //   );
  // };
  // const { resCreationStandingOrder } = useSelector(standingOrderReducer);
  return (
    <Fragment key={uuidV4()}>
      <tr className="overflow-hidden">
        <td colSpan={3} className=" text-primary underline cursor pointer">
          <span onClick={onClick} key={uuidV4()}>
            {box.order && box.po && (
              <>
                {' '}
                {box.po}-{box.order}
              </>
            )}
          </span>
        </td>
        <td className="">
          {box.product_group}-{box.variety_grade}
          <>
            {box.event_color && box.event_name && (
              <div className="d-flex align-items-center">
                <span
                  className="ibf-btn-circle d-block border border-dark"
                  style={{ background: box.event_color }}
                />
                <span className="ml-1"> {box.event_name}</span>
              </div>
            )}
          </>
        </td>
        {/*  <td className="p-2"> {box.variety_grade} </td> */}
        <td className=""> {box.length}cm</td>
        <td className="">
          {' '}
          {box.grower.toUpperCase()}{' '}
          {box.grower_obj?.importer ? `(Miami Inventory)` : null}
        </td>
        {/* <td className="p-2"> {box.total_stems} </td>
        <td className="p-2"> {box.total_bunches} </td> */}
        {/*  <td className="p-2"> ${roundUp(box.price)} </td> */}
        <td className="text-nowrap">
          {/*  {' '}
          {capitalizeFirstLtoetter(box?.masterlist_uom ?? box.uom)}{' '} */}
          {box.total_stems} stems
          <br />
          {/*{`(in ${box.stem_bunch} ${*/}
          {/*  box.stem_bunch > 1 ? 'bunches)' : 'bunch)'*/}
          {/*}`}*/}({box.stem_bunch} stems/bunch)
        </td>
        <td className="">
          ${roundUp(box.total_price)}
          {` ($${roundUp(box.price / (box.stem_bunch as number))}/st $${roundUp(
            box.price
          )}/bunch)
          `}
        </td>

        {!isCustomBox && (
          <td className="d-none d-lg-table-cell text-nowrap" colSpan={1}>
            <Popover
              trigger="click"
              placement={'top'}
              overlayClassName="custom-step-desktop-popover"
              content={popoverContent}
              visible={isPopoverVisible}>
              <button
                className="btn border bg-ibf-calculating text-primary"
                onClick={() => openCalendar(itemOrder)}>
                Re-order this box
                {/* (select new date) */}
              </button>
            </Popover>
          </td>
        )}
        {/*{soExist ||*/}
        {/*(resCreationStandingOrder?.created &&*/}
        {/*  resCreationStandingOrder._KEY === box._KEY) ? (*/}
        {/*  <td className=" d-lg-table-cell text-nowrap" colSpan={1}>*/}
        {/*    <i>*/}
        {/*      This order is part of a standing order.*/}
        {/*      <br />*/}
        {/*      <a*/}
        {/*        className="text-black underline"*/}
        {/*        href={`${redirectUrlIbuyflowers(*/}
        {/*          Environment.baseUrl,*/}
        {/*          '#/myStandingOrders'*/}
        {/*        )}`}*/}
        {/*        target="_blank"*/}
        {/*        rel="noopener noreferrer">*/}
        {/*        check your standing order{' '}*/}
        {/*        <FontAwesomeIcon icon={faAngleDoubleRight} />*/}
        {/*      </a>*/}
        {/*    </i>*/}
        {/*  </td>*/}
        {/*) : (*/}
        {/*  !isCustomBox && (*/}
        {/*    <td className="d-none d-lg-table-cell text-nowrap" colSpan={3}>*/}
        {/*      <Popover*/}
        {/*        trigger="click"*/}
        {/*        placement={'top'}*/}
        {/*        overlayClassName="custom-step-desktop-popover"*/}
        {/*        content={popoverContent}*/}
        {/*        visible={isPopoverVisible}>*/}
        {/*        {masterlist_prices[box._KEY!]?.weekly_price?.saving !==*/}
        {/*          null && (*/}
        {/*          <button*/}
        {/*            className="btn border bg-ibf-calculating"*/}
        {/*            style={{ color: 'green' }}*/}
        {/*            onClick={*/}
        {/*              masterlist_prices &&*/}
        {/*              !isNaN(*/}
        {/*                masterlist_prices[box._KEY!]?.weekly_price?.saving*/}
        {/*              ) &&*/}
        {/*              openStandingOrder*/}
        {/*            }>*/}
        {/*            Save*/}
        {/*            {masterlist_prices &&*/}
        {/*            !isNaN(*/}
        {/*              masterlist_prices[box._KEY!]?.weekly_price?.saving*/}
        {/*            ) ? (*/}
        {/*              <b>*/}
        {/*                {' '}*/}
        {/*                $*/}
        {/*                {masterlist_prices[*/}
        {/*                  box._KEY!*/}
        {/*                ]?.weekly_price?.saving?.toFixed(2)}*/}
        {/*              </b>*/}
        {/*            ) : (*/}
        {/*              <FontAwesomeIcon*/}
        {/*                icon={faSpinner}*/}
        {/*                spin={true}*/}
        {/*                className="mx-1"*/}
        {/*              />*/}
        {/*            )}{' '}*/}
        {/*            /year as Standing Order*/}
        {/*          </button>*/}
        {/*        )}*/}
        {/*      </Popover>*/}
        {/*    </td>*/}
        {/*  )*/}
        {/*)}*/}
      </tr>
      {!isCustomBox && (
        <tr className="d-table-row d-lg-none border-0">
          <td colSpan={4} className="pt-0 pr-2 border-0">
            <Popover
              trigger="click"
              placement={'top'}
              overlayClassName="custom-step-desktop-popover"
              content={popoverContent}
              visible={isPopoverVisible}>
              <button
                className="btn border bg-ibf-calculating  text-primary mt-1 mt-md-0 mr-md-1 mr-lg-0"
                onClick={() => openCalendar(itemOrder)}>
                Re-order this box
                {/* (select new date) */}
              </button>
            </Popover>

            {/*  <ButtonStadingOrder/> */}
          </td>
        </tr>
      )}
      {!!box.po && (
        <tr className="border-0">
          <td colSpan={3} className="pt-0 text-left border-0">
            {shouldDeleteCartbox(box) && (
              <div className="btn-group dropdown" key={uuidV4()}>
                <button
                  className="btn btn-sm btn-danger"
                  disabled={isDeleting}
                  onClick={() => {
                    deleteHandler(box._KEY, box.customer, box.price, true, box);
                  }}>
                  {isDeleting && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      pulse
                      className={'mr-2'}
                    />
                  )}{' '}
                  Delete this order
                </button>
              </div>
            )}
          </td>
          {/*  <td colSpan={10} className="pt-0 text-right pr-2">
            <Popover
              trigger="click"
              placement={'top'}
              overlayClassName="custom-step-desktop-popover"
              content={popoverContent}
              visible={isPopoverVisible}>
              <button
                className="btn ibf-btn-grey"
                onClick={() => openCalendar(itemOrder)}>
                Re-order this box (select new date)
              </button>
            </Popover>
            <Popover
              trigger="click"
              placement={'top'}
              overlayClassName="custom-step-desktop-popover"
              content={popoverContent}
              visible={isPopoverVisible}>
              <button
                className="btn ibf-btn-grey"
                onClick={() => openCalendar(itemOrder)}>
                Re-order this box (select new date)
              </button>
            </Popover>
          </td> */}
        </tr>
      )}
    </Fragment>
  );
};

export default OrderBoxRow;
