import React from 'react';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import { authReducer } from '../../../Store/Selectors';
import useDeviceDetect from '../../../Hooks/useDeviceDetect';

type Props = {
  onClose: () => void;
};

const CalendarHeaderComponent = ({ onClose }: Props) => {
  const { config } = useSelector(authReducer);
  const { isMobile } = useDeviceDetect();

  return (
    <div className="d-flex flex-column">
      <div className="py-2 px-4 ibf-calendar-border-top d-flex justify-content-between align-items-center">
        <h4 className="ibf-text-gray">
          <b>Select a delivery date:</b>
        </h4>
        <div className="ibf-link " onClick={onClose}>
          Close
        </div>
      </div>

      {/*<div className="px-4">*/}
      {/*  <MessageReminderShop classIcon="ibf-font-20 mr-1" textColor={'black'} />*/}
      {/*</div>*/}

      <Alert
        style={{ backgroundColor: '#ffffce', borderColor: 'black' }}
        isOpen={config?.calendarAlert?.visible}
        className="m-4 d-flex align-items-center">
        <FontAwesomeIcon
          icon={faBell}
          color={'black'}
          size={'2x'}
          className="mr-2"
        />
        <span
          className={`color-black ${
            isMobile ? 'ibf-font-14 text-center' : 'ibf-font-20'
          }`}>
          {config?.calendarAlert?.message}
          {isMobile ? <br /> : null}
          <b>{config?.calendarAlert?.boldText}</b>
        </span>
      </Alert>
    </div>
  );
};

export default CalendarHeaderComponent;
