import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OrdersTableComponent from '../OrdersTableComponent/OrdersTableComponent';
import FiltersTableComponent from '../FiltersTableComponent/FiltersTableComponent';
import {
  convertDateToNumberDate,
  convertNumberDateToDate,
  toast
} from '../../Utils/Utils';
import MixpanelService from '../../Services/MixpanelService';
import DisplayRouteComponent from '../Shared/DisplayRouteComponent';
import { deleteOrder } from '../../Services/OrderService';
import { RootState } from '../../Store/Reducers';
import { eventReducer, orderReducer } from '../../Store/Selectors';
import { actionsOrder } from '../../Store/Order/Slice';
import { useHistory } from 'react-router-dom';
import { actionsModal } from '../../Store/Modal/Slice';
import { CONFIRMATION_CHECKOUT_MODAL } from '../../Store/Modal/Types';
import { State } from '../../Models/State';
import { Order, OrderEntity, TableKeys } from '../../Store/Order/Types';
import { Pagination } from 'antd';
import { TrackEventMonitor } from '../../Classes/TrackEvent';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';

export type SearchParams = {
  eventName?: string;
  growerPicked?: string;
  orderNumber?: string;
  poNumber?: string;
  selectedDay?: string;
  productName?: string;
};

export enum TypeTable {
  ARRIVAL_DATE = 'ARRIVAL_DATE',
  PURCHASE_DATE = 'PURCHASE_DATE'
}

export const filterOrdersFunction = {
  orderNumber: (orderNumber: string | number, order: OrderEntity[]) =>
    order[0].order.toString() === orderNumber.toString(),
  growerPicked: (growerPicked: string, order: OrderEntity[]) =>
    growerPicked.toString() === order[0]?.grower_obj?.company_name,
  poNumber: (poNumber: string | number, order: OrderEntity[]) =>
    poNumber.toString() === order[0].po.toString(),
  eventName: (eventName: string, order: OrderEntity[]) =>
    eventName.toString() === order[0].event_key,
  selectedDay: (selectedDay: string, order: OrderEntity[]) =>
    convertDateToNumberDate(selectedDay) === order[0].eta_date,
  productName: (productName: string, order: OrderEntity[]) =>
    order[0].product_group.toLowerCase().includes(productName.toLowerCase()) ||
    order[0].variety_grade.toLowerCase().includes(productName.toLowerCase())
};

const OrdersByEtaComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filtersEnabled, setFiltersEnabled] = useState(false);
  const [filterParams, setFilterParams] = useState<SearchParams>({});
  const [dataSource, setDataSource] = useState<Order[]>([]);
  const [spinner, setSpinner] = useState<JSX.Element | null>(null);
  const [searching, setSearching] = useState(State.IDLE);
  const [filterPage, setFilterPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const orders = useSelector(orderReducer);
  const events = useSelector(eventReducer);

  const [boxDeleting, setBoxDeleting] = useState('');
  const { customer, from_manager } = useSelector(
    (state: RootState) => state.AuthReducer.user.metadata
  );

  const goToPage = useCallback(
    (pageIndex: number) => {
      dispatch(actionsOrder.goToPage(pageIndex - 1));
      setFilterPage(pageIndex - 1);
    },
    [dispatch]
  );

  React.useEffect(() => {
    setFilterPage(orders.indexCurrentPage);
  }, [orders.indexCurrentPage]);

  useEffect(() => {
    MixpanelService.track('orderHistory', {});
    dispatch(actionsOrder.fetchOrders(customer));
  }, [dispatch, customer]);

  const goToOrderDetail = useCallback(
    (order_key: string) => {
      history.push('/order_detail/' + order_key);
    },
    [history]
  );

  const searchHandler = (searchParams: SearchParams) => {
    setFiltersEnabled(true);
    setFilterPage(0);
    setFilterParams(searchParams);
  };

  const resetFiltersHandler = () => {
    setFiltersEnabled(false);
    dispatch(actionsOrder.goToPage(0));
    setFilterParams({});
  };

  const filterTable = useCallback(
    (filterParams: SearchParams) => {
      setSearching(State.PENDING);
      return (orders.orders as Order[]).reduce((arr: Order[], order) => {
        const newOrder = order.filter((boxOrder: OrderEntity[]) => {
          return Object.keys(filterParams).some((key) => {
            const filterKey = key as keyof SearchParams;
            if (filterParams[filterKey]) {
              return filterOrdersFunction[filterKey](
                filterParams[filterKey] || '',
                boxOrder
              );
            }

            return false;
          });
        });

        if (newOrder.length > 0) {
          arr.push(newOrder);
        }
        return arr;
      }, []);
    },
    [orders.orders]
  );

  const deleteHandler = useCallback(
    (
      cartbox: string,
      customer: string,
      price: any,
      requestedByCustomer: boolean,
      box: { order: number; po: number }
    ) => {
      setBoxDeleting(cartbox);
      const cancelAction = () => {
        setBoxDeleting('');
      };

      const confirmAction = () => {
        deleteOrder(cartbox, customer, requestedByCustomer)
          .then((res: any) => {
            // reload page
            if (res.success) {
              setBoxDeleting('');
              TrackEventMonitor.newEvent({
                props: {
                  customer,
                  requested_by_customer: !from_manager,
                  cart_box_key: cartbox,
                  po: box.po,
                  box_consecutive: box.order
                },
                metadata: {
                  name: 'ibf_delete_order'
                }
              });
              MixpanelService.track('deleteOrder', { quantity: 1, price });
              dispatch(actionsOrder.fetchOrders(customer));
              toast('The box has been removed successfully', 'success');
            } else {
              setBoxDeleting('');

              const message = res?.error?.includes('ibuyflowers')
                ? res.error
                : 'An error ocurred';
              toast(message, 'error');

              Sentry.withScope(function(scope) {
                scope.setLevel(Severity.Error);
                Sentry.setExtra(
                  'Props',
                  JSON.stringify({
                    customer,
                    requested_by_customer: !from_manager,
                    cart_box_key: cartbox,
                    po: box.po,
                    box_consecutive: box.order
                  })
                );
                Sentry.setExtra('Response', JSON.stringify(res));
                // The exception has the event level set by the scope (info).
                Sentry.captureException(
                  new Error('Delete order Error (Front)')
                );
              });
              dispatch(actionsOrder.setRejected());
            }
          })
          .catch((err) => {
            console.error(err);
            Sentry.withScope(function(scope) {
              scope.setLevel(Severity.Error);
              Sentry.setExtra(
                'Props',
                JSON.stringify({
                  customer,
                  requested_by_customer: !from_manager,
                  cart_box_key: cartbox,
                  po: box.po,
                  box_consecutive: box.order
                })
              );
              Sentry.setExtra('Error', JSON.stringify(err));
              // The exception has the event level set by the scope (info).
              Sentry.captureException(
                new Error('Delete order Error (Front) catch')
              );
            });
            setBoxDeleting('');
            dispatch(actionsOrder.setRejected());
          });
      };

      const modalBody = (
        <h3 className="font-weight-bold">
          Are you sure you want to delete this box? This can't be undone.
        </h3>
      );
      dispatch(
        actionsModal.setOpenModal({
          modal: CONFIRMATION_CHECKOUT_MODAL,
          data: {
            body: modalBody,
            confirmButtonText: 'Yes, delete',
            cancelButtonText: 'No, go back',
            confirmAction: confirmAction,
            showCancelButton: true,
            cancelAction
          }
        })
      );
    },
    [dispatch, from_manager]
  );

  const getDaysAvailables = (order_keys: TableKeys[]) => {
    if (!order_keys) {
      return [];
    }
    return order_keys.map((item) => {
      return convertNumberDateToDate(item.key);
    });
  };

  useEffect(() => {
    if (orders.ordersByArrivalLoaded) {
      if (filtersEnabled) {
        const result = filterTable(filterParams);
        setSearching(State.RESOLVED);
        setTotalItems(result.length);
        setDataSource([result[filterPage]]);
      } else {
        setDataSource([orders.orders[orders.indexCurrentPage] as Order]);
        setTotalItems(orders.orders.length);
      }
    } else {
      setSpinner(
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '150px'
          }}>
          <i className={'fa fa-spinner fa-spin fa-3x'}> </i>
        </div>
      );
    }
  }, [
    filterPage,
    filterParams,
    filterTable,
    filtersEnabled,
    orders.indexCurrentPage,
    orders.orders,
    orders.ordersByArrivalLoaded
  ]);

  return (
    <div key="eta" className="ibf-orders-container">
      <DisplayRouteComponent currentRouteName={'My orders'} />
      <FiltersTableComponent
        key="filters_etaa"
        type={TypeTable.ARRIVAL_DATE}
        searchHandler={searchHandler}
        resetFilterHandler={resetFiltersHandler}
        growers={orders.growersList || []}
        eventList={events.eventList || []}
        daysAvailable={getDaysAvailables(orders.ordersKeys)}
      />

      <OrdersTableComponent
        typeTable={TypeTable.ARRIVAL_DATE}
        data={dataSource}
        headers={orders.tableHeaders}
        goToOrderDetail={goToOrderDetail}
        boxDeleting={boxDeleting}
        isSearching={searching === State.PENDING}
        deleteHandler={deleteHandler}
      />

      {dataSource.length > 0 && (
        <Pagination
          pageSize={1}
          current={filterPage + 1}
          className="text-center"
          total={totalItems}
          onChange={goToPage}
        />
        // <PaginationComponent
        //   previousPage={previousPage}
        //   nextPage={nextPage}
        //   ordersKeys={orders.ordersKeys}
        //   goToPage={goToPage}
        //   indexCurrentPage={orders.indexCurrentPage}
        // />
      )}
      {spinner}
    </div>
  );
};

export default OrdersByEtaComponent;
