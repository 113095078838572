import Config from '../../Models/Config/Config';
import User from '../../Models/User/User';
import Manager from '../../Models/Manager';
import AuthState from './Types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../Models/State';
import { ChangeEmailParams, SignInGuestParams } from '../../Models/Auth';
const { IDLE, REJECTED, RESOLVED, PENDING } = State;

const initialState: AuthState = {
  token: '',
  config: new Config(),
  user: new User(),
  offset: '',
  isNewDay: false,
  error: '',
  holidays: [],
  manager: new Manager(),
  time: 0,
  state: IDLE,
  verificationCode: 0,
  smsPreferencesKey: ''
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    validateToken(
      state: AuthState,
      { payload }: PayloadAction<{ token: string; user_key?: string }>
    ) {
      state.state = PENDING;
    },
    setAuthData(state: AuthState, { payload }: PayloadAction<any>) {
      state.token = payload.token;
      state.config = payload.config;
      state.user = payload.user;
      state.offset = payload.offset;
      state.isNewDay = payload.isNewDay;
      state.state = RESOLVED;
    },
    setManagerInfo(state: AuthState, { payload }: PayloadAction<any>) {
      state.manager = payload;
      state.state = RESOLVED;
    },
    newVerificationCode(state: AuthState, { payload }: PayloadAction<any>) {
      state.state = RESOLVED;
      state.verificationCode = payload.code;
      state.smsPreferencesKey = payload.key;
    },
    setIsFirstRepurchase(
      state: AuthState,
      { payload }: PayloadAction<boolean>
    ) {
      state.state = RESOLVED;
      state.user.metadata.isFirstRepurchase = payload;
    },
    changePassword(
      state: AuthState,
      {
        payload
      }: PayloadAction<{ current_password: string; new_password: string }>
    ) {
      state.state = PENDING;
    },

    changeEmail(
      state: AuthState,
      { payload }: PayloadAction<ChangeEmailParams>
    ) {
      state.state = PENDING;
    },
    setUserEmail(state: AuthState, { payload }: PayloadAction<string>) {
      if (state.user.metadata.from_manager) {
        state.user.metadata.main_user_email = payload;
      } else {
        state.user.email = payload;
      }
    },
    setUserPreferences(
      state,
      { payload }: PayloadAction<{ preferences: { [key: string]: boolean } }>
    ) {
      state.user.metadata.preferences = payload.preferences;
    },
    setUserStatementEmailWeekly(
      state: AuthState,
      { payload }: PayloadAction<boolean>
    ) {
      state.user.metadata.customer_statement_email_weekly = payload;
      state.state = RESOLVED;
    },
    setVerifyEmailAccount(
      state: AuthState,
      {
        payload
      }: PayloadAction<{ userInfo: SignInGuestParams; resend?: boolean }>
    ) {
      state.state = PENDING;
    },

    setRejected(state) {
      state.state = REJECTED;
    },

    setResolved(state) {
      state.state = RESOLVED;
    }
  }
});

export const actionsAuth = slice.actions;
export default slice.reducer;
