import './polyfills';
import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import Store from './Store/Store';
import packageJson from '../package.json';

Sentry.init({
  dsn:
    'https://c6a5126afe21422596ec6d58fbc58cf0@o512690.ingest.sentry.io/5621337',
  integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: 'ibf-ui-shopping@' + packageJson.version,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'Unexpected token <',
    'Extension context invalidated',
    'Extension context invalidated.'
  ],
  denyUrls: ['http://localhost:8080', 'http://localhost:8081']
});

ReactDOM.render(
  <Provider store={Store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
