import React from 'react';
import { authReducer } from '../../Store/Selectors';
import { useSelector } from 'react-redux';
import UspCarousel from './UspCarousel';
import UspComponent from './UspComponent';
import { State } from '../../Models/State';

const UspMobileAndDesktopView = () => {
  const { user, state } = useSelector(authReducer);
  return (
    <>
      {state === State.RESOLVED && (
        // (!user.metadata?.n_orders || user?.metadata?.n_orders <= 6) && (
        <>
          <div
            className={`d-lg-none ${
              state === State.RESOLVED &&
              (!user.metadata?.n_orders || user?.metadata?.n_orders <= 6) &&
              user?.metadata?.guest &&
              window?.innerWidth < 800
                ? 'mb-4'
                : 'mb-2'
            }`}>
            <UspCarousel />
          </div>
          <div className="d-none d-lg-block">
            <UspComponent />
          </div>
        </>
      )
      // )
      }
    </>
  );
};

export default UspMobileAndDesktopView;
