import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useSelector } from 'react-redux';
import CalendarInputComponent from '../../../FiltersTableComponent/CalendarInputComponent';
import { calendarReducer } from '../../../../Store/Selectors';
import { DeepMap, FieldError, UseFormClearErrors } from 'react-hook-form';
import { CreateStandingOrder } from '../../../../Models/StandingOrder';

type Props = {
  chooseFrecuency: boolean;
  selectedDay: string | Date;
  setSelectedDay: (day: Date) => void;
  weeklySelected: string;
  setWeeklySelected: (event: string) => void;
  errors: DeepMap<CreateStandingOrder, FieldError>;
  clearErrors: UseFormClearErrors<CreateStandingOrder>;
};
///

const validationInputMessage = (
  <span className="ibf-font-12 color-red">Please fill in this info.</span>
);
export default function ChoosingDate({
  chooseFrecuency,
  selectedDay,
  setSelectedDay,
  weeklySelected,
  setWeeklySelected,
  errors,
  clearErrors
}: Props) {
  const { modifiersStyles, disabledDays } = useSelector(calendarReducer);
  const handleDayChange = (day: Date) => {
    if (day instanceof Date) {
      setSelectedDay(day);
    }
  };
  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setWeeklySelected(event.currentTarget.value);
  };
  return (
    <div className="mt-4 d-flex flex-column flex-md-row align-items-lg-center">
      <b>Choose starting date:</b>
      <div className="d-flex align-items-center ml-lg-2">
        <div className="d-flex flex-column">
          <div className={`${Object.keys(errors).length > 0 ? 'mt-3' : ''}`}>
            <DayPickerInput
              value={selectedDay}
              onDayChange={(e) => {
                clearErrors('start_date');
                handleDayChange(e);
              }}
              format={'MM/DD/YYYY'}
              component={CalendarInputComponent}
              inputProps={{
                ref: null,
                name: 'start_date'
              }}
              dayPickerProps={{
                className: 'calendar-arrival',
                /* modifiers: { highlighted }, */
                modifiersStyles: modifiersStyles,
                disabledDays: disabledDays
              }}
              ///
            />
          </div>

          {errors?.start_date?.type === 'required' && validationInputMessage}
        </div>
        <div className="ml-2 mt-2">
          <select
            className="form-control input-sm"
            onChange={handleOnChange}
            value={weeklySelected}>
            {chooseFrecuency && (
              <option value="for 6 months">for 6 months</option>
            )}
            <option value="for 12 months">for 12 months</option>
          </select>
        </div>
      </div>
    </div>
  );
}
