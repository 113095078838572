import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PercentageComponent from './PercentageComponent';
import ContactComponent from '../Shared/ContactComponent/ContactComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import BoxProductsComponent from './BoxProductsComponent/BoxProductsComponent';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../Models/State';
import { removeBox, searchInventory, toast } from '../../Utils/Utils';
import * as ModalTypes from '../../Store/Modal/Types';
import SearchInputBox from './SearchInputBox';
import MixpanelService from './../../Services/MixpanelService';
import { RootState } from '../../Store/Reducers';
import { actionsModal } from '../../Store/Modal/Slice';
import { actionsCustomBox } from '../../Store/CustomBox/Slice';
import { customBoxReducer, loadingReducer } from '../../Store/Selectors';
import { hlp } from '../../App';
import useDeviceDetect from '../../Hooks/useDeviceDetect';
import PopoverGrowerNotification from './PopoverGrowerNotification';

const CustomBoxModal = () => {
  const { CUSTOM_BOX_MODAL } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const {
    products,
    state,
    currentPercentage,
    productsStillFitInBox,
    filter_conditions,
    currentBox,
    search,
    inventorySold
  } = useSelector(customBoxReducer);

  const { inventoryLoading, moreProductsLoading } = useSelector(loadingReducer);
  const { isMobile } = useDeviceDetect();

  const { customer, n_orders } = useSelector(
    (state: RootState) => state.AuthReducer.user.metadata
  );
  const [visiblePopover, setVisiblePopover] = useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      currentPercentage < 100 &&
      productsStillFitInBox &&
      !inventoryLoading &&
      !moreProductsLoading &&
      n_orders === 0
    ) {
      hlp.startTour(isMobile ? 'cGWdlwIeFs' : 'LHnjS0FsRm');
    }
  }, [
    currentPercentage,
    productsStillFitInBox,
    inventoryLoading,
    moreProductsLoading,
    isMobile,
    n_orders
  ]);

  const closeProductBox = () => {
    if (state === State.PENDING) {
      toast('A product is being added, please wait.', 'info');
    } else {
      dispatch(
        actionsModal.setCloseModal({ modal: ModalTypes.CUSTOM_BOX_MODAL })
      );
      dispatch(actionsCustomBox.closeBox({ customer, fromBox: true }));
      MixpanelService.track('done_return_to_cart');
    }
  };

  const searchBoxInventory = () => {
    const filters = {
      grower: [CUSTOM_BOX_MODAL.data.grower.key]
    };

    searchInventory(
      dispatch,
      customer,
      '',
      currentPercentage,
      CUSTOM_BOX_MODAL.data.eta_date,
      filters
    );
  };

  const openCartModal = () => {
    dispatch(actionsModal.setOpenModal({ modal: ModalTypes.CART_MODAL }));
    searchBoxInventory();
  };

  const deleteBox = async () => {
    if (state === State.PENDING) {
      toast('A product is being added, please wait.', 'info');
    } else {
      removeBox(
        currentBox?.cartbox ? currentBox.cartbox : currentBox,
        dispatch,
        customer,
        true
      );

      dispatch(
        actionsModal.setCloseModal({ modal: ModalTypes.CUSTOM_BOX_MODAL })
      );
      dispatch(actionsCustomBox.closeBox({ customer, fromBox: true }));
      MixpanelService.track('done_return_to_cart');
    }
  };

  const getNotProductsMessage = () => {
    let message = '';
    if (search) {
      message = `There are no results for ${search} at this moment, click`;
    } else if (Object.keys(filter_conditions)) {
      message = `There are no results for the selected filters at this moment, click`;
    }

    if (message) {
      return (
        <h4>
          {message}{' '}
          <u
            className="pointer ibf-link"
            onClick={() => {
              searchBoxInventory();
              dispatch(actionsCustomBox.setSearch(''));
            }}>
            here
          </u>{' '}
          to remove the filters and continue.
        </h4>
      );
    }

    return <h4>No product found</h4>;
  };

  return (
    <>
      <Modal isOpen={CUSTOM_BOX_MODAL.open} className="" size="xl">
        <>
          <div className="d-flex align-items-start align-items-lg-center justify-content-between mb-4 mb-lg-0 px-3 pt-2">
            <div className="d-flex flex-column flex-lg-row flex-grow-1 align-items-lg-center justify-content-between mr-0 mr-lg-5">
              <div className="d-flex flex-column">
                <span className="ibf-shopping-modal-title mr-2 mb-3 mb-lg-0">
                  Continue filling your box at{' '}
                  {CUSTOM_BOX_MODAL.data.grower.name}
                </span>
                <span>
                  You get FREE shipping when a box is filled at least 80%
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <PercentageComponent
                  informationPercentage={currentPercentage}
                />
                <span className="ibf-link ml-3" onClick={openCartModal}>
                  Show/edit box details
                </span>
              </div>
            </div>
            <ContactComponent modal className="d-none d-lg-flex" />
          </div>
          <ModalBody id="body-modal">
            {currentPercentage < 100 && inventorySold && !search ? (
              <div>
                <p>
                  We are sorry… {CUSTOM_BOX_MODAL.data.grower.name}’s inventory
                  was sold out while you were working on this custom box.
                </p>
                <p className="mb-0">Please choose one of these options:</p>
                <ul>
                  <li>
                    place your selected items{' '}
                    <span
                      className="underline pointer text-success"
                      onClick={closeProductBox}>
                      as a custom box in your cart
                    </span>
                  </li>
                  <li>
                    or,{' '}
                    <span
                      className="underline pointer text-danger"
                      onClick={deleteBox}>
                      delete
                    </span>{' '}
                    this custom box (you might be able to find availability on
                    another date this week)
                  </li>
                </ul>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  {currentPercentage < 100 && productsStillFitInBox ? (
                    <SearchInputBox />
                  ) : (
                    <h3 style={{ color: '#399C39' }}>
                      <FontAwesomeIcon icon={faCheck} color="green" /> Your
                      custom box is full.
                    </h3>
                  )}
                  <div className="ibf-btn-done-wrapper">
                    <PopoverGrowerNotification
                      handleVisibleChange={() =>
                        setVisiblePopover((prev) => !prev)
                      }
                      visible={currentPercentage < 50 ? visiblePopover : false}
                      closeModal={closeProductBox}>
                      <button
                        onClick={
                          currentPercentage >= 50 ? closeProductBox : () => {}
                        }
                        className="d-flex align-items-center btn btn-outline-primary ibf-btn-done justify-content-center">
                        <span className="underline">Done? Close box</span>
                      </button>
                    </PopoverGrowerNotification>
                  </div>
                </div>
                {products.length === 0 &&
                state === State.RESOLVED &&
                productsStillFitInBox ? (
                  <div className="d-flex align-items-center justify-content-center mt-3">
                    {getNotProductsMessage()}
                  </div>
                ) : (
                  <>
                    {currentPercentage < 100 && productsStillFitInBox && (
                      <div className="mt-3">
                        <BoxProductsComponent />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </ModalBody>
        </>
      </Modal>
    </>
  );
};

export default CustomBoxModal;
