import React from 'react';
import { useDispatch } from 'react-redux';
import { MESSAGE_MODAL } from '../../../../Store/Modal/Types';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { useSelector } from 'react-redux';
import { authReducer } from '../../../../Store/Selectors';
type Props = {
  className?: string;
};

export default function MessageDisclaimer({ className }: Props) {
  const dispatch = useDispatch();
  const { config } = useSelector(authReducer);
  const data = {
    body: 'This item has a grower disclaimer on the color.',
    text: `${config.tintedAlert.message_after_click}.`,
    bodyClassName: 'text-break',
    classNameParagraph: 'align-self-start',
    iconCloseEnabled: true,
    size: 'md',
    buttonClose: true,
    textClose: 'Ok, close',
    classNameText: 'font-weight-light d-block ibf-text-line-height-1 mb-2',
    contentClassButton: 'd-flex justify-content-center mt-3',
    buttonClassClose: 'mt-2 mb-3  btn bg-primary text-white px-4',
    cancelAction: () => {}
  };
  const onClick = () => {
    dispatch(
      actionsModal.setOpenModal({
        modal: MESSAGE_MODAL,
        data: data
      })
    );
  };

  return (
    <div
      className={` bg-ibf-yellow pointer d-flex justify-content-center py-1 my-1 ${className ??
        ''}`}>
      <span
        className="border-bottom-dashed pointer ibf-font-11"
        onClick={onClick}>
        {config?.tintedAlert?.message}
      </span>
    </div>
  );
}
