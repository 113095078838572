import { createSlice } from '@reduxjs/toolkit';
import { State } from '../../Models/State';

const { IDLE, RESOLVED } = State;

const initialState = {
  navHeight: 0,
  headerHeight: 0,
  scrollPosition: 0,
  tipStep: 1,
  firstTip: true,
  secondTip: false,
  navOptions: {
    flowers: false,
    myAccount: false,
    growers: false,
    colors: false,
    favorites: false
  },
  state: IDLE
};

const slice = createSlice({
  name: 'display',
  initialState,
  reducers: {
    setNavHeight(state, { payload }) {
      state.state = RESOLVED;
      state.navHeight = payload - 60;
    },
    setTipStep(state, { payload }) {
      state.state = RESOLVED;
      state.tipStep = payload;
    },
    setScrollPosition(state, { payload }) {
      state.state = RESOLVED;
      state.scrollPosition = payload;
    },
    setNavHeaderHeight(state, { payload }) {
      state.state = RESOLVED;
      state.headerHeight = payload;
    },
    setOpenFirstTip(state) {
      state.state = RESOLVED;
      state.firstTip = !state.firstTip;
    },
    setOpenSecondTip(state, { payload }) {
      state.state = RESOLVED;
      state.secondTip = payload;
    },
    setOpenNavOptions(state, { payload }) {
      state.state = RESOLVED;
      state.navOptions = payload;
    }
  }
});

export const actionsDisplay = slice.actions;
export default slice.reducer;
