import React, { useState } from 'react';
import {
  hrefToObject,
  signInQuestions,
  toast,
  validateEmail
} from '../../../../Utils/Utils';
import Select, {
  GroupedOptionsType,
  OptionsType,
  OptionTypeBase
} from 'react-select';

import GuestQuestionsBussiness from './GuestQuestionsBussiness';
import GuestItemsAdvantages from './GuestItemsAdvantages';
import GuestItemButton from './GuestItemButton';
import ZipcodeService from '../../../../Services/ZipcodeService';
import {
  Controller,
  ControllerRenderProps,
  DefaultValues,
  SubmitHandler,
  useForm
} from 'react-hook-form';
import {
  checkEmailAvailable,
  getListEmailGuest,
  logout
} from '../../../../Services/AuthService';
import { EmailGuest } from '../LoginModal/LoginModal';
import { State } from '../../../../Models/State';
import { SignInGuestParams } from '../../../../Models/Auth';
import { TrackEventMonitor } from '../../../../Classes/TrackEvent';

export interface Option {
  label: string | number;
  value: string | number | boolean;
}

export type PropsFieldEventsPerYears = {
  field: ControllerRenderProps<FormValuesSignUp, 'events_per_year'>;
};
export type PropsFieldState = {
  field: ControllerRenderProps<FormValuesSignUp, 'state'>;
};

export type FormValuesSignUp = {
  zipcode: string;
  email: string;
  company_name: string;
  first_name: string;
  mobile_phone: string;
  business: string;
  events_per_year: Option;
  storefront_type: string;
  last_name: string;
  checked_email: boolean;
  state: Option;
  resale_number: number;
  others_business: Option;
};

const defaultValues: DefaultValues<FormValuesSignUp> = {
  checked_email: false
};

type Props = {
  signInGuest: (params: SignInGuestParams) => void;
  loading: boolean;
  setLoading: (state: State) => void;
  next: () => void;
};
export const validationInputMessage = (
  <span className="ibf-font-12 color-red">This field is required.</span>
);

export default function GuestSignUpForm({
  loading,
  next,
  setLoading,
  signInGuest
}: Props) {
  const {
    register,
    control,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
    watch,
    getValues
  } = useForm<FormValuesSignUp>({
    defaultValues
  });
  const { referral_code }: { [key: string]: any } = hrefToObject();

  const storedEmail = localStorage.getItem('ibf_lastEmailUsed');

  // const [isValid, setIsValid] = useState(false);

  const watchTypeBussiness = watch('business', '');

  const [states] = useState<{ [key: string]: string }>(
    ZipcodeService.getStateMap()
  );

  const onSubmit: SubmitHandler<FormValuesSignUp> = async (data) => {
    setLoading(State.PENDING);
    const isAvailable = await checkEmailAvailable(data.email);

    const isAvailableGuest = await getListEmailGuest();

    if (isAvailableGuest && isAvailableGuest.data.success) {
      const listEmailGuest = isAvailableGuest.data;
      const newGuest = listEmailGuest.item.find(
        (r: EmailGuest) => r.email === data.email
      );
      if (newGuest) {
        toast(
          'A guest account has already been requested with this email.',
          'error'
        );
        setLoading(State.REJECTED);
        return;
      }
    }
    if (!isAvailable) {
      setLoading(State.REJECTED);

      return;
    }

    if (!validateEmail(data.email)) {
      setError('email', {
        type: 'pattern'
      });
      setLoading(State.REJECTED);
      return;
    }
    let dataOtherBusiness = (data?.others_business as Option)?.value as string;

    const dataObjUser: SignInGuestParams = {
      zipcode: data?.zipcode,
      email: data.email,
      company_name: data.company_name,
      first_name: data.first_name,
      mobile_phone: data?.mobile_phone,
      business: data.business === 'Other' ? dataOtherBusiness : data.business,
      events_per_year: (data.events_per_year as Option).value as string,
      storefront_type: data.storefront_type as string
    };

    const dataForm = {
      ...dataObjUser,
      last_name: data.last_name,
      state: (data.state as Option).value as string,
      resale_number: data.resale_number
    };

    TrackEventMonitor.newEvent({
      props: {
        ...dataForm,
        checkbox_confirmation: data.checked_email
      },
      metadata: { name: 'ibf_sign_in' }
    });

    if (window.dataLayer) {
      window.dataLayer.push({ event: 'Conversion guest account', value: '' });
    }

    if (window && window.gtag) {
      const callback = function() {
        return;
      };
      window.gtag('event', 'conversion', {
        send_to: 'AW-869926913/W25sCN2PmMsBEIGQ6J4D',
        event_callback: callback
      });
    }
    (function() {
      const ub_script = document.createElement('script');
      ub_script.type = 'text/javascript';
      ub_script.src =
        ('https:' === document.location.protocol ? 'https://' : 'http://') +
        'd3pkntwtp2ukl5.cloudfront.net/uba.js';
      const s = document.getElementsByTagName('script')[0];
      if (s?.parentNode) {
        s.parentNode.insertBefore(ub_script, s);
      }
    })();
    localStorage.setItem('ibf_lastEmailUsed', data.email);

    clearErrors('email');
    signInGuest(dataObjUser);
    if (!referral_code) {
      next();
    }
  };
  const onKeyPressInputNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!e.key.match(/\d/g)) {
      e.preventDefault();
    }
  };
  const onBlurCheckEmail = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const email = event.target.value;

    const isAvailable = await checkEmailAvailable(email);

    if (!isAvailable && email !== '') {
      setError('email', {
        type: 'manual'
      });
    } else {
      clearErrors('email');
    }
  };
  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    setValue(
      'company_name',
      event.currentTarget.value.replace(/[^\w\s]/gi, '')
    );
  };
  // useEffect(() => {
  //   const subscription = watch((value) => {
  //     let eventPerYear = (value?.events_per_year as Option)?.value as string;
  //     let othersBusiness = (value?.others_business as Option)?.value as string;
  //     let business =
  //       value?.business === 'Other' ? othersBusiness : value?.business;
  //
  //     if (
  //       business &&
  //       value?.checked_email &&
  //       value?.company_name &&
  //       value?.first_name &&
  //       value?.last_name &&
  //       value?.mobile_phone &&
  //       value?.zipcode &&
  //       value?.resale_number &&
  //       value?.state &&
  //       eventPerYear &&
  //       value?.email &&
  //       value?.storefront_type
  //     ) {
  //       setIsValid(true);
  //     } else {
  //       setIsValid(false);
  //     }
  //   });
  //
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  const onError = (errors: any) => {
    if (Object.keys(errors).length > 0) {
      toast(
        'Please fill in all required fields marked with an asterisk (*) before submitting the form. Thank you!',
        'error'
      );

      const eventData = {
        email: getValues()?.email ?? '',
        missing_fields: Object.keys(errors).join(', ')
      };

      TrackEventMonitor.newEvent({
        props: {
          ...eventData
        },
        metadata: { name: 'ibf_sign_in_missing_data' }
      });
    }
  };

  return (
    <>
      <div className="d-flex flex-column flex-lg-row ">
        <div className="d-flex flex-column col-12 col-lg-6 ibf-guest-modal-home ">
          <div className="d-flex justify-content-center mt-5 mb-2">
            <span className="ibf-font-30 font-weight-bold text-center text-break">
              Sign up and shop directly <br />
              from 100+ farms
            </span>
          </div>

          <GuestItemsAdvantages />
        </div>
        <div className=" col-12 col-lg-6 mt-5 mb-2">
          <GuestQuestionsBussiness
            register={register}
            errors={errors}
            control={control}
            watchTypeBussiness={watchTypeBussiness}
          />

          <div className="d-flex  flex-column my-3">
            <div className="d-flex align-items-center">
              <Controller
                name="events_per_year"
                control={control}
                rules={{ required: true }}
                render={({ field }: PropsFieldEventsPerYears) => {
                  return (
                    <Select
                      {...field}
                      className="w-100"
                      placeholder={
                        '--Select the number of floral events a year'
                      }
                      options={
                        signInQuestions['events_per_year'].options as
                          | GroupedOptionsType<OptionTypeBase>
                          | OptionsType<OptionTypeBase>
                          | undefined
                      }
                    />
                  );
                }}
              />
            </div>

            {errors.events_per_year && (
              <div className="d-flex flex-column">{validationInputMessage}</div>
            )}
          </div>

          <div className="d-flex align-items-center my-3">
            <div className="form-group col-6 px-0">
              <label htmlFor="" className="">
                {' '}
                Resale number (business only)*
              </label>
              <input
                className="form-control"
                {...register('resale_number', {
                  required: true
                })}
              />
              {errors.resale_number && (
                <div className="d-flex flex-column">
                  {validationInputMessage}
                </div>
              )}
            </div>
            <div className="form-group col-6 pr-0 align-self-end align-self-md-center align-self-lg-end align-self-xl-center">
              <label htmlFor="">State*</label>

              <Controller
                name="state"
                control={control}
                rules={{ required: true }}
                render={({ field }: PropsFieldState) => {
                  return (
                    <Select
                      {...field}
                      options={
                        Object.keys(states).map((state) => ({
                          value: state,
                          label: states[state]
                        })) as
                          | GroupedOptionsType<OptionTypeBase>
                          | OptionsType<OptionTypeBase>
                          | undefined
                      }
                    />
                  );
                }}
              />

              {errors.state && (
                <div className="d-flex flex-column">
                  {validationInputMessage}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center my-3">
            <div className="form-group col-6 px-0">
              <label htmlFor="zipcode"> Zipcode (5 digits)*</label>
              <input
                id={'zipcode'}
                {...register('zipcode', {
                  required: true
                })}
                type="text"
                inputMode="numeric"
                maxLength={5}
                minLength={5}
                pattern="[0-9]{5}"
                className="form-control"
              />
              <div className="d-flex flex-column">
                {(errors.zipcode?.type === 'minLength' ||
                  errors.zipcode?.type === 'maxLength') && (
                  <span className="ibf-font-12 bg-ibf-yellow color-black d-block py-1 mt-1 pl-1">
                    Sorry, your zip code doesn't seem right
                  </span>
                )}
                {errors.zipcode && validationInputMessage}
              </div>
            </div>
            <div className="form-group col-6 pr-0">
              <label htmlFor="mobile" className="text-nowrap">
                Mobile phone number*
              </label>
              <input
                type="number"
                className="form-control"
                id="mobile"
                inputMode="numeric"
                {...register('mobile_phone', {
                  required: true,
                  minLength: 10
                })}
                onKeyPress={onKeyPressInputNumber}
              />

              {errors.mobile_phone?.type === 'minLength' ? (
                <span className="d-block mt-1 color-black ibf-font-12 py-1 bg-ibf-yellow pl-1">
                  Please fill in the 10 digits of your mobile phone number.
                </span>
              ) : (
                errors.mobile_phone && (
                  <div className="d-flex flex-column">
                    {validationInputMessage}
                  </div>
                )
              )}
            </div>
          </div>

          <div className="col-12 px-0">
            <div className="form-group ">
              <label htmlFor="email">
                {' '}
                Business email address (for login and communication)*
              </label>
              <input
                type="text"
                className="form-control"
                id="email"
                {...register('email', {
                  required: true
                })}
                onBlur={onBlurCheckEmail}
                defaultValue={storedEmail || ''}
              />
              {errors.email?.type === 'manual' && (
                <span className="font-weight-light color-black ibf-font-12 bg-ibf-yellow d-block mt-1 py-1 pl-1 ">
                  This email address is already related to an iBuyFlowers
                  account.
                  <span
                    className="underline ml-1 pointer"
                    onClick={() => logout()}>
                    Cancel this new sign up and login with this email address.
                  </span>
                </span>
              )}
              {errors.email?.type === 'required' && (
                <div className="d-flex flex-column">
                  {validationInputMessage}
                </div>
              )}
              {errors.email?.type === 'pattern' && (
                <span className="ibf-font-12 bg-ibf-yellow color-black d-block py-1 mt-1 pl-1">
                  Sorry, your email doesn't seem right.
                </span>
              )}
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="form-group ">
              <label htmlFor="company_name"> Company name*</label>
              <input
                type="text"
                className="form-control"
                id={'company_name'}
                {...register('company_name', {
                  required: true
                })}
                maxLength={35}
                onChange={handleInput}
              />
              {errors.company_name && (
                <div className="d-flex flex-column">
                  {validationInputMessage}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center my-3">
            <div className="form-group col-6 px-0">
              <label htmlFor="first_name"> Your first name*</label>
              <input
                className="form-control"
                maxLength={35}
                id="first_name"
                {...register('first_name', {
                  required: true,
                  maxLength: 35
                })}
              />
              {errors.first_name && (
                <div className="d-flex flex-column">
                  {validationInputMessage}
                </div>
              )}
            </div>
            <div className="form-group col-6 pr-0">
              <label htmlFor="last_name">Your last name*</label>
              <input
                className="form-control"
                id="last_name"
                maxLength={35}
                {...register('last_name', {
                  required: true,
                  maxLength: 35
                })}
              />
              {errors.last_name && (
                <div className="d-flex flex-column">
                  {validationInputMessage}
                </div>
              )}
            </div>
          </div>

          <GuestItemButton
            onSubmit={() => handleSubmit(onSubmit, onError)()}
            register={register}
            errors={errors}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}
