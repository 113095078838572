import React, { useState } from 'react';
import { signInQuestions } from '../../../../Utils/Utils';
import Select, {
  GroupedOptionsType,
  OptionTypeBase,
  OptionsType
} from 'react-select';
import {
  Control,
  Controller,
  DeepMap,
  FieldErrors,
  UseFormRegister
} from 'react-hook-form';
import { FormValuesSignUp, validationInputMessage } from './GuestSignUpForm';

type Props = {
  register: UseFormRegister<FormValuesSignUp>;
  errors: DeepMap<FormValuesSignUp, FieldErrors>;
  control: Control<FormValuesSignUp>;
  watchTypeBussiness: string;
};
export default function GuestQuestionsBussiness({
  register,
  errors,
  control,
  watchTypeBussiness
}: Props) {
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedRetailShop, setSelectedRetailShop] = useState('');

  const handleOnchange = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const value = event.currentTarget.value;
    setSelectedValue(value);
  };

  const handleOnchangeRetailShop = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const value = event.currentTarget.value;
    setSelectedRetailShop(value);
  };

  return (
    <>
      <div className="d-flex align-items-center flex-wrap my-2">
        <span className="mr-3">I am a:*</span>

        {signInQuestions['main_menu']?.options.map((option, index) => (
          <div className=" custom-control custom-radio mr-3" key={`${index}`}>
            <input
              {...register('business', {
                required: watchTypeBussiness !== 'Other'
              })}
              id={`${index}`}
              className="custom-control-input"
              type="radio"
              value={option.value}
              checked={selectedValue === option.value}
              onClick={(e) => {
                handleOnchange(e);
              }}
            />
            <label className="custom-control-label" htmlFor={`${index}`}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {errors.business && (
        <div className="d-flex flex-column">{validationInputMessage}</div>
      )}

      {selectedValue === 'Other' && (
        <div className="d-flex justify-content-end my-2 ">
          <Controller
            name="others_business"
            control={control}
            rules={{ required: watchTypeBussiness === 'Other' }}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  className="w-75 z-index"
                  placeholder={'--Select '}
                  options={
                    signInQuestions['other_business_type'].options as
                      | GroupedOptionsType<OptionTypeBase>
                      | OptionsType<OptionTypeBase>
                      | undefined
                  }
                />
              );
            }}
          />
        </div>
      )}
      {errors.others_business && (
        <div className="d-flex flex-column">{validationInputMessage}</div>
      )}

      <div className="d-flex align-items-center flex-wrap my-3">
        <span className="mr-3">With a:*</span>

        {signInQuestions['retail_shop_studio']?.options.map((option, index) => (
          <div
            className=" custom-control custom-radio mr-3"
            key={`retail_${index}`}>
            <input
              {...register('storefront_type', {
                required: true
              })}
              className="custom-control-input"
              type="radio"
              id={`retail_${index}`}
              value={option.value}
              checked={selectedRetailShop === option.value}
              onClick={(e) => {
                handleOnchangeRetailShop(e);
              }}
            />
            <label className="custom-control-label" htmlFor={`retail_${index}`}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {errors.storefront_type && (
        <div className="d-flex flex-column">{validationInputMessage}</div>
      )}
    </>
  );
}
