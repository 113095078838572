import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  ADD_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_REPLACEMENTS,
  UPDATE_PRODUCT_QUANTITY
} from './Types';
import { actionsProduct } from './Slice';
import {
  addProduct,
  getProductDetails,
  getProductGroupSuggestion,
  getSubstitutesInfo,
  getVarietySuggestion,
  searchInventory,
  updateQuantity
} from '../../Services/ProductService';
import Environment from '../../Environment';
import {
  addObjectParamsToPath,
  findDuplicateVarietyKeys,
  getErrorTypeSearchProduct,
  getStemLabel,
  hrefToObject,
  PAGE_SIZE_PRODUCT,
  removeDuplicateFromArrayObj,
  sendSbxEvent,
  toast
} from '../../Utils/Utils';
import { actionsLoading } from '../Loading/Slice';
import { actionsFilter } from '../Filter/Slice';
import { actionsCart } from '../Cart/Slice';
import { customerKey } from '../Selectors';
import { RootState } from '../Reducers';
import SearchInventoryParams from '../../Models/SearchInventory/SearchInventoryParams';
import Filter from '../../Models/Filter';
import { Masterlist } from '../../Models/Cartbox';
import ProductGroup from '../../Models/ProductGroup';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import { ReplaceVarietySuggestion } from '../../Models/Variety';
import Product from '../../Models/Product';

function* getSubstitutesInfoSaga(arrayVarietyKeys: string[]): any {
  const promise = yield call(getSubstitutesInfo, arrayVarietyKeys);
  return yield all(promise);
}

export function* getProductsSaga({
  payload
}: ReturnType<typeof actionsProduct.getProducts>): any {
  try {
    if (payload.loading) {
      yield put(actionsLoading.setFilterLoading(true));
    }
    if (payload.params.eta) {
      yield put(actionsProduct.setProductDate({ date: payload.params.eta }));
    } else {
      payload.params.eta = yield select(
        (state: RootState) => state.ProductReducer.date
      );
    }
    if (!payload.params.customer) {
      payload.params.customer = yield select(
        (state: RootState) => state.AuthReducer.user.metadata.customer
      );
    }
    const serviceParams: SearchInventoryParams = {
      ...payload.params,
      pageSize: PAGE_SIZE_PRODUCT
    };
    if (
      serviceParams.conditions.filters.favorites &&
      serviceParams.conditions.filters.variety?.length === 0
    ) {
      delete serviceParams.conditions.filters.favorites;
      delete serviceParams.conditions.filters.variety;
      delete payload.params.conditions.filters.favorites;
    }

    yield put(
      actionsFilter.setSearch({
        search: payload.params.conditions.search,
        substitute: payload.params.substitute
      })
    );

    let search_type = '';
    let search_name = '';

    // if (payload.params.conditions.search) {
    if (serviceParams.conditions.filters.search_type) {
      search_type = serviceParams.conditions.filters.search_type[0];
      delete serviceParams.conditions.filters.search_type;
    }

    if (serviceParams.conditions.filters.search_name) {
      search_name = serviceParams.conditions.filters.search_name[0];
      delete serviceParams.conditions.filters.search_name;
    }
    // }else{

    // }

    let res = yield call(searchInventory, serviceParams);

    if (res && res.success) {
      if (payload.pageProduct) {
        yield put(
          actionsProduct.setPageProduct({ products: res.response.products })
        );
      } else {
        if (search_name || payload.params.conditions?.search) {
          sendSbxEvent({
            name: 'ibf_product_search',
            props: {
              search: search_name ?? payload.params.conditions?.search ?? '',
              search_type,
              date_selected: serviceParams.eta,
              results: res.response.totalItems
            }
          });
        }

        yield put(actionsProduct.setProducts({ results: res.response }));
      }

      let { product_group } = hrefToObject();
      const { filters } = yield select(
        (state: RootState) => state.ProductReducer
      );

      if (res.response.products.length < 10) {
        if (
          serviceParams?.conditions?.search &&
          serviceParams?.conditions?.search.length > 0
        ) {
          const response = yield call(
            getProductGroupSuggestion,
            serviceParams.conditions.search
          );

          yield put(actionsProduct.setLoadingProductSuggestion(true));
          yield put(actionsProduct.setLoadingVarietySuggestionsBySearch(true));
          if (response?.data?.success) {
            response.data.items = response.data.items.map(
              (masterlist: Masterlist) => masterlist.product_group
            );

            response.data.items = removeDuplicateFromArrayObj(
              response.data.items,
              '_KEY'
            ).sort((a: Masterlist, b: Masterlist) =>
              (a.product_group as ProductGroup)?.common_name?.localeCompare(
                (b.product_group as ProductGroup)?.common_name ?? ''
              )
            );
            //Get product by product_group
            const date = yield select(
              (state: RootState) => state.ProductReducer.date
            );

            const customer = yield select(
              (state: RootState) => state.AuthReducer.user.metadata.customer
            );

            const productGroup = response.data.items.reduce(
              (acc: Array<{ _KEY: string }>, item: { _KEY: any }) => {
                if (item._KEY) {
                  acc.push(item._KEY);
                }
                return acc;
              },
              []
            );

            const searchParams: SearchInventoryParams = {
              customer,
              pageSize: 50,
              eta: date,
              conditions: { filters: { product_group: productGroup } }
            };

            const productGroupRes = yield call(searchInventory, searchParams);

            yield put(actionsProduct.setLoadingProductSuggestion(false));
            yield put(
              actionsProduct.setProductGroupSuggestionsBySearch({
                masterlist: response.data.items
              })
            );

            yield put(
              actionsProduct.setProductByProductGroup({
                product: productGroupRes.success
                  ? productGroupRes.response.products
                  : []
              })
            );
          }

          //b
          //yield put(actionsProduct.setLoadingVarietySuggestionsBySearch(true));
          let responseVariety = yield call(
            getVarietySuggestion,
            serviceParams.conditions.search!
          );

          if (responseVariety?.data?.items?.length) {
            const array = findDuplicateVarietyKeys(responseVariety.data.items);
            const arrayVarietyKeys = [...new Set(array.map((item) => item))];

            if (arrayVarietyKeys.length) {
              // console.log('arrayVarietyKeys', arrayVarietyKeys);
              const responseReplaceVariety: ReplaceVarietySuggestion[] = yield call(
                getSubstitutesInfoSaga,
                arrayVarietyKeys
              );

              if (Array.isArray(responseReplaceVariety)) {
                const arrayReplaceVariety = responseReplaceVariety
                  .flat()
                  .filter((item) => item);

                let resultResponseReplacement = arrayReplaceVariety.map(
                  (i: ReplaceVarietySuggestion) => {
                    return arrayVarietyKeys.includes(i.variety)
                      ? i.replace
                      : i.variety;
                  }
                );
                const arrayReplacevrietyKey = [
                  ...new Set(
                    resultResponseReplacement.map((item: string) => item)
                  )
                ];

                ///////call
                const dataParams: SearchInventoryParams = {
                  eta: serviceParams.eta,
                  pageSize: 50,

                  customer: serviceParams.customer,
                  conditions: {
                    filters: {
                      variety: arrayReplacevrietyKey
                    }
                  }
                };

                let resProductSuggestions = yield call(
                  searchInventory,
                  dataParams
                );

                if (resProductSuggestions?.success) {
                  yield put(
                    actionsProduct.setLoadingVarietySuggestionsBySearch(false)
                  );
                  let product = resProductSuggestions?.response?.products;
                  yield put(
                    actionsProduct.setVarietySuggestionsBySearch({
                      items: arrayReplacevrietyKey,
                      listProductSuggestions: product
                    })
                  );
                } else {
                  yield put(
                    actionsProduct.setVarietySuggestionsBySearch({
                      items: [],
                      listProductSuggestions: []
                    })
                  );
                  yield put(
                    actionsProduct.setLoadingVarietySuggestionsBySearch(false)
                  );
                }
              } else {
                yield put(
                  actionsProduct.setLoadingVarietySuggestionsBySearch(false)
                );
                yield put(
                  actionsProduct.setVarietySuggestionsBySearch({
                    items: [],
                    listProductSuggestions: []
                  })
                );
              }
            } else {
              yield put(
                actionsProduct.setLoadingVarietySuggestionsBySearch(false)
              );
            }
          }
        }

        if (product_group) {
          const filter: Filter = filters.find(
            (filter: Filter) => filter.label === 'Product groups'
          );
          if (filter) {
            product_group = Array.isArray(product_group)
              ? product_group[0]
              : product_group;
            const productGroupName = filter.values.find(
              (filterItem) => filterItem.key === product_group
            )?.value;

            if (productGroupName) {
              const response = yield call(
                getProductGroupSuggestion,
                productGroupName
              );

              if (response?.data?.success) {
                response.data.items = response.data.items.map(
                  (masterlist: Masterlist) => masterlist.product_group
                );
                response.data.items = removeDuplicateFromArrayObj(
                  response.data.items,
                  '_KEY'
                ).sort((a: Masterlist, b: Masterlist) =>
                  (a.product_group as ProductGroup)?.common_name?.localeCompare(
                    (b.product_group as ProductGroup)?.common_name ?? ''
                  )
                );

                yield put(
                  actionsProduct.setProductGroupSuggestions({
                    masterlist: response.data.items
                  })
                );
              }
            }
          }
        }
        //   const productGroup = filters.values.find((filter: FilterItem) => filter.key === filters_conditions.product_group[filters_conditions.product_group.length - 1])
        //   console.log(productGroup)
      } else {
        yield put(
          actionsProduct.setProductGroupSuggestions({ masterlist: [] })
        );
        yield put(
          actionsProduct.setVarietySuggestionsBySearch({
            items: [],
            listProductSuggestions: []
          })
        );
        yield put(actionsProduct.setLoadingProductSuggestion(false));
      }

      yield put(actionsFilter.setFilters(payload.params.conditions.filters));
    } else {
      toast('Data could not be loaded, for all product items', 'error');
      yield put(actionsLoading.setFilterLoading(false));
      yield put(actionsProduct.setRejected());

      res = { response: { products: [], totalItems: 0 } };
      const jsonStringifiedData = JSON.stringify(res).toLowerCase();
      if (payload.pageProduct) {
        yield put(
          actionsProduct.setPageProduct({ products: res.response.products })
        );
      } else {
        yield put(actionsProduct.setProducts({ results: res.response }));
      }
      if (jsonStringifiedData?.includes('error')) {
        Sentry.withScope(function(scope) {
          scope.setLevel(Severity.Error);
          Sentry.setExtra('Payload', JSON.stringify(payload));
          Sentry.setExtra('Response Stringify', JSON.stringify(res));
          Sentry.setExtra('Response', res);
          // The exception has the event level set by the scope (info).
          Sentry.captureException(new Error('Error on Search products'));
        });
      }
    }
  } catch (e) {
    const errorMessage = e?.toString();
    const errorType = getErrorTypeSearchProduct(errorMessage);
    if (!JSON.stringify(e).includes('Network Error')) {
      if (!errorType) {
        Sentry.withScope(function(scope) {
          scope.setLevel(Severity.Error);
          Sentry.setExtra('Payload', JSON.stringify(payload));
          Sentry.setExtra('Error', JSON.stringify(e));
          // The exception has the event level set by the scope (info).
          Sentry.captureException(new Error('Error on Search products'));
        });
        console.error('Error on get products saga');
      }
    }

    yield put(actionsProduct.setRejected());
  }
}

export function* getProductDetailSaga({
  payload: { variety, date, customer }
}: ReturnType<typeof actionsProduct.getProductDetail>): any {
  try {
    const res = yield call(getProductDetails, { variety, date, customer });

    if (res.response) {
      const p = res.response.product;
      const productPhotos =
        p && p.photos.map((i: string) => Environment.publicPath + i);
      yield put(
        actionsProduct.setProductDetail({
          productGroup: p && p.key,
          imageDetail: productPhotos && productPhotos[0],
          product: { ...p },
          productReplaces: res.response.replacements
        })
      );
    } else {
      toast(res.message, 'error');
      yield put(actionsProduct.setRejected());
    }
  } catch (e) {
    yield put(actionsProduct.setRejected());
    console.error('Error on get product detail saga');
  }
}

export function* getProductReplacementsSaga({
  payload
}: ReturnType<typeof actionsProduct.getProductReplacements>): any {
  try {
    const res = yield call(getSubstitutesInfo, [payload.key]);

    if (res.length) {
      const { substitute } = yield select(
        (state: RootState) => state.FilterReducer
      );
      const date = yield select(
        (state: RootState) => state.ProductReducer.date
      );
      const { filters_conditions } = yield select(
        (state: RootState) => state.ProductReducer
      );
      const customer = yield select(
        (state: RootState) => state.AuthReducer.user.metadata.customer
      );

      let filters = Object.assign(
        {},
        {
          ...filters_conditions,
          date,
          substitute: payload.name || substitute,
          variety: res.map((i: any) =>
            i.variety === payload.key ? i.replace : i.variety
          )
        }
      );
      const searchParams: SearchInventoryParams = {
        customer,
        pageSize: 1,
        eta: date,
        conditions: { filters: { variety: filters.variety } }
      };

      const searchRes = yield call(searchInventory, searchParams);
      if (searchRes.response.totalItems) {
        const newHref = addObjectParamsToPath('#/search', filters);
        if ('#' + window.location.href.split('#')[1] === newHref) {
          window.location.reload();
        } else {
          window.location.href = newHref;
        }
      } else {
        toast('No available substitutes found', 'error');
        yield put(actionsLoading.setFilterLoading(false));
      }
    } else {
      toast('No substitutes found', 'error');
      yield put(actionsLoading.setFilterLoading(false));
    }
  } catch (e) {
    yield put(actionsProduct.setRejected());
    console.error('Error on get product replacements saga');
  }
}

export function* updateProductQuantitySaga({
  payload
}: ReturnType<typeof actionsProduct.updateProductQuantity>): any {
  try {
    const res = yield call(
      updateQuantity,
      payload.productKey,
      payload.quantity
    );

    const boxOnly = payload.boxOnly ? payload.boxOnly : false;

    if (res.success) {
      const customer = yield select(customerKey);
      if (payload.prevQuantity) {
        toast(
          `Quantity ${getStemLabel(
            payload.prevQuantity,
            boxOnly
          )} was update to: ${getStemLabel(payload.quantity, boxOnly)}`
        );
      }

      let addCartboxBestDeal = !!payload.quantity;
      yield put(
        actionsCart.listCart({
          customer,
          addCartboxBestDeal: addCartboxBestDeal
        })
      );
      yield put(actionsProduct.setResolved());
    } else {
      console.error('Error on updating product quantity saga');
      yield put(actionsProduct.setRejected());
    }
  } catch (e) {
    yield put(actionsProduct.setRejected());
    console.error('Error on update product quantity saga');
  }
}

export function* addProductSaga({
  payload
}: ReturnType<typeof actionsProduct.addProduct>): any {
  try {
    const res = yield call(addProduct, payload.params, payload.customer);

    const params = payload.params as Product;

    if (res.success) {
      let addCartboxBestDeal = false;

      if (params && params.growers && Array.isArray(params.growers)) {
        addCartboxBestDeal = params.growers.length > 1;
      }
      yield put(
        actionsCart.listCart({
          customer: payload.customer,
          addCartboxBestDeal: addCartboxBestDeal
        })
      );
      yield put(actionsProduct.setResolved());
    } else {
      yield put(actionsProduct.setRejected());
    }
  } catch (e) {
    yield put(actionsProduct.setRejected());
    console.error('Error on update product quantity saga');
  }
}

export default function* AllSagas() {
  yield all([
    //Auth sagas
    takeEvery(GET_PRODUCT, getProductsSaga),
    takeEvery(GET_PRODUCT_DETAIL, getProductDetailSaga),
    takeEvery(GET_PRODUCT_REPLACEMENTS, getProductReplacementsSaga),
    takeEvery(UPDATE_PRODUCT_QUANTITY, updateProductQuantitySaga),
    takeEvery(ADD_PRODUCT, addProductSaga)
  ]);
}
