import * as React from 'react';
import { useRef, useState } from 'react';
import ModalComponent from '../ModalComponent';
import { useDispatch, useSelector } from 'react-redux';
import { authReducer } from '../../../../Store/Selectors';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { CONFIRM_ZIPCODE_MODAL } from '../../../../Store/Modal/Types';
import { State } from '../../../../Models/State';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { updateCustomerInfoService } from '../../../../Services/UserService';
import { toast } from '../../../../Utils/Utils';

const ConfirmZipcodeModal = () => {
  const { user } = useSelector(authReducer);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(State.IDLE);
  const onClose = () =>
    dispatch(actionsModal.setCloseModal({ modal: CONFIRM_ZIPCODE_MODAL }));
  const zipcodeRef = useRef<HTMLInputElement>(null);
  const [showError, setShowError] = useState(false);

  return (
    <ModalComponent
      // title={`Dear ${user?.metadata.from_manager ? user?.metadata.main_user_first_name : user?.first_name}`}
      headerEnabled={false}
      onClose={onClose}
      footerEnabled={false}
      size="md">
      <div className="d-flex flex-column">
        {
          <div className="d-flex justify-content-between">
            <h3>
              {`Dear ${
                user?.metadata.from_manager
                  ? user?.metadata.main_user_first_name
                  : user?.first_name
              }`}
              ,
            </h3>

            {user?.metadata?.from_manager && (
              <h3 onClick={onClose} className="pointer">
                X
              </h3>
            )}
          </div>
        }

        <p>
          Please confirm the zip code of your default address (
          {user?.metadata.customer_main_street},{' '}
          {user?.metadata.customer_main_city}), to continue:
        </p>

        <div className="d-flex flex-column align-items-start">
          <input
            type="text"
            className="form-control w-75"
            ref={zipcodeRef}
            defaultValue={
              user?.metadata.customer_main_zipcode ||
              user?.metadata.customer_zipcode ||
              ''
            }
          />

          {showError && (
            <span className="ibf-text-red">
              Please fill in the 5 number of your zipcode
            </span>
          )}
          <button
            className="btn btn-primary mt-3"
            disabled={loading === State.PENDING}
            onClick={async () => {
              if (zipcodeRef.current?.value!?.length < 5) {
                setShowError(true);
                return;
              }

              setShowError(false);

              const default_address = {
                state: { abbr: user.metadata.customer_main_state },
                address: user.metadata.customer_main_street,
                city: user.metadata.customer_main_city,
                zipcode: zipcodeRef.current?.value
              };

              setLoading(State.PENDING);

              const response = await updateCustomerInfoService({
                _KEY: user.metadata.customer,
                zipcode: zipcodeRef.current?.value,
                actual_address: JSON.stringify(default_address)
              });

              if (response?.success) {
                toast('Zipcode updated successfully', 'success');
                onClose();
              } else {
                toast('Error updating zipcode', 'error');
                setLoading(State.REJECTED);
              }
            }}>
            Save and close{' '}
            {loading === State.PENDING && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin={loading === State.PENDING}
              />
            )}
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default ConfirmZipcodeModal;
