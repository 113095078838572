import UserMetadata from './UserMetadata';

export default class User {
  user_id!: number;
  role!: string;
  name!: string;
  key!: string;
  metadata: UserMetadata = new UserMetadata();
  id!: number;
  email!: string;
  is_admin!: boolean;
  main_user_is_admin?: boolean;
  first_name!: string;
  last_name!: string;
}
