import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './dashboardComponent.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  DashboardList,
  DashboardListOptions
} from '../../Models/DashboardList';
import { logout } from '../../Services/AuthService';
import { goToSignup, toast } from '../../Utils/Utils';
import MixpanelService from './../../Services/MixpanelService';
import { actionsModal } from '../../Store/Modal/Slice';
import { authReducer, dashboardReducer } from '../../Store/Selectors';
import { useHistory } from 'react-router';
import { Popover } from 'antd';
import { guestSettings } from './DashboardComponent';
type Props = {
  list: DashboardList;
  className?: string;
  optionClassName?: string;
  toggleMenu?: () => void;
  visiblePopover?: boolean;
  setVisibleOnChange?: () => void;
};

const DashboardListComponent: React.FC<Props> = ({
  list,
  className,
  optionClassName,
  toggleMenu,
  setVisibleOnChange,
  visiblePopover
}) => {
  const dispatch = useDispatch();
  const { trackPackageInfo } = useSelector(dashboardReducer);
  const {
    user: {
      metadata: { guest }
    }
  } = useSelector(authReducer);
  const history = useHistory();
  const getOptionComponent = (component: any) => {
    if (component.name === 'button') {
      const onClickButton = () => handleClickOption(component);
      return (
        <div>
          <button
            className="btn btn-sm dashboard-button"
            onClick={() => onClickButton()}>
            {component.label}
          </button>
        </div>
      );
    }
    return;
  };

  const redirectTo = (url: string, name: string) => {
    if (trackPackageInfo && name === 'Track Package') {
      MixpanelService.track('track_and_trace');
      history.push(`/order_detail/${trackPackageInfo}`);
      toggleMenu && toggleMenu();
    } else {
      if (name === 'Track Package') {
        return toast('No traceable packages found.', 'error');
      }
      window.location.href = url;
    }
  };

  const dispatchModal = (modal: { name: string; data?: any }) => {
    dispatch(
      actionsModal.setOpenModal({ modal: modal.name, data: modal.data })
    );
  };

  const handleClickOption = (option: DashboardListOptions) => {
    if (option.closeMenu) {
      toggleMenu && toggleMenu();
    }

    if (option.name === 'Logout') {
      logout();
    }

    if (guest && !guestSettings.includes(option.name)) {
      goToSignup(dispatch);
    } else {
      if (option.url) {
        redirectTo(option.url, option.name);
      }
      if (option.modalData) {
        dispatchModal(option.modalData);
      }
      if (option.function) {
        option.function();
      }
    }
  };

  return (
    <div className={className}>
      <ul className="list-group list-group-flush">
        <li className="list-group-item dashboard-list-title px-0" key={0}>
          <FontAwesomeIcon className="mr-2" icon={list.icon} />
          {list.title}
        </li>
        {list.options.map((option, index: number) => (
          <li className="list-group-item px-0" key={index + 1}>
            <div className="d-flex justify-content-between">
              {option.name === 'Statements' ? (
                <Popover
                  trigger="click"
                  placement={'left'}
                  onVisibleChange={setVisibleOnChange}
                  visible={visiblePopover}
                  overlayClassName={`custom-step-desktop-popover position-fixed ${
                    window?.innerWidth > 800 ? 'w-25' : 'w-50'
                  } `}
                  overlayStyle={{ zIndex: 1500 }}
                  content={
                    <span className="text-black">
                      NEW: download all your purchases per week, month, year or
                      event name.
                    </span>
                  }>
                  {' '}
                  <span
                    className={`${optionClassName ?? ''} `}
                    onClick={() => handleClickOption(option)}>
                    {option.name}
                  </span>
                </Popover>
              ) : (
                <span
                  className={`${optionClassName ?? ''} `}
                  onClick={() => handleClickOption(option)}>
                  {option.name}
                </span>
              )}

              {option.component && getOptionComponent(option.component)}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DashboardListComponent;
