import React from 'react';
import { NavItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShippingFast } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import MixpanelService from './../../../Services/MixpanelService';
import { RootState } from '../../../Store/Reducers';
import { useHistory } from 'react-router';

const TraceComponent = () => {
  const { trackPackageInfo } = useSelector(
    (state: RootState) => state.DashboardReducer
  );
  const {
    user: { metadata }
  } = useSelector((state: RootState) => state.AuthReducer);
  const history = useHistory();

  const orderDetail = () => {
    if (trackPackageInfo) {
      MixpanelService.track('track_and_trace');
      history.push(`/order_detail/${trackPackageInfo}`);
    }
  };

  return (
    <>
      {!!trackPackageInfo && metadata && !metadata.guest && (
        <NavItem className="my-3 px-3">
          <div onClick={orderDetail} className="pointer">
            <FontAwesomeIcon color="gray" icon={faShippingFast} />{' '}
            <span className="ibf-text-navbar d-none d-sm-inline">
              Track & trace
            </span>
          </div>
        </NavItem>
      )}
    </>
  );
};

export default TraceComponent;
