import React from 'react';
import { useHistory } from 'react-router';
import ContactComponent from './ContactComponent/ContactComponent';

type Props = {
  currentRouteName: string;
};

const DisplayRouteComponent = ({ currentRouteName }: Props) => {
  const history = useHistory();

  const goToHome = () => {
    history.push('/home');
  };

  return (
    <div className="d-flex mt-3 mt-md-0 align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column flex-md-row">
          <span className="product-detail-title mr-1">
            {' '}
            <span className="clickable" onClick={goToHome}>
              Home
            </span>{' '}
            /{' '}
            <span className="clickable" onClick={goToHome}>
              My account
            </span>{' '}
            /
          </span>{' '}
          <b className="product-detail-name">{currentRouteName}</b>
        </div>
      </div>
      <ContactComponent className="ml-auto" />
    </div>
  );
};

export default DisplayRouteComponent;
