import React from 'react';
import Modal from '../../../Models/Modal';
import {
  Button,
  Modal as RSModal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';

const ModalComponent: React.FC<Modal> = ({
  title,
  body,
  onClose,
  okText,
  okClick,
  cancelText,
  className = '',
  size,
  largeOkButton = false,
  footerEnabled = true,
  customFooter,
  centerFooter = false,
  iconClassName = '',
  bodyClassName,
  headerEnabled = true,
  isOpen = true,
  disabledOverFlow,
  children
}) => {
  return (
    <div>
      <RSModal
        isOpen={isOpen}
        toggle={onClose}
        className={className}
        size={size}
        centered={true}>
        {headerEnabled && (
          <ModalHeader toggle={onClose}>
            <span className="text-primary">
              <i className={iconClassName} />
            </span>{' '}
            {title}
          </ModalHeader>
        )}
        <ModalBody
          className={bodyClassName}
          style={
            disabledOverFlow ? {} : { overflow: 'auto', maxHeight: '70vh' }
          }>
          {body ? body : children}
        </ModalBody>
        {footerEnabled && !customFooter && (
          <ModalFooter
            className={`${centerFooter ? 'justify-content-center' : ''}`}>
            <Button
              color="primary"
              onClick={okClick}
              style={largeOkButton ? { width: '12em' } : {}}>
              {okText || 'Continue'}
            </Button>
            {cancelText && (
              <Button color="secondary" onClick={onClose}>
                {cancelText}
              </Button>
            )}
          </ModalFooter>
        )}
        {!!customFooter && <ModalFooter>{customFooter}</ModalFooter>}
      </RSModal>
    </div>
  );
};

export default ModalComponent;
