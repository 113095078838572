import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { convertDateDWMMDD } from '../../../Utils/Utils';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { RootState } from '../../../Store/Reducers';
import { actionsCustomBox } from '../../../Store/CustomBox/Slice';

type Props = {
  toggleFilterMobileView: () => void;
};

const BoxDisplayFilterComponent: React.FC<Props> = ({
  toggleFilterMobileView
}) => {
  const { isAList } = useSelector((state: RootState) => state.CustomBoxReducer);
  const { products } = useSelector(
    (state: RootState) => state.CustomBoxReducer
  );
  const { CUSTOM_BOX_MODAL } = useSelector(
    (state: RootState) => state.ModalReducer
  );
  const onChange = (event: any) => {};

  const dispatch = useDispatch();

  return (
    <div className="d-flex flex-column flex-lg-row pb-2 pb-lg-0 align-items-center w-100">
      <span className="ibf-display-filter-text">{`${
        products.length
      } results for grower ${CUSTOM_BOX_MODAL.data.grower.name}
       (${CUSTOM_BOX_MODAL.data.grower.country}) for ${convertDateDWMMDD(
        CUSTOM_BOX_MODAL.data.eta_date
      )}:`}</span>
      <div className="d-flex align-items-center ibf-display-filter-options ml-lg-auto">
        <span
          className="d-flex align-items-center d-lg-none ibf-text-navbar-modal"
          onClick={toggleFilterMobileView}>
          Filter <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
        </span>
        <div className="d-flex align-items-center">
          <span
            className="d-flex ibf-text-navbar-modal pr-2"
            style={{ color: '#4A4A4A' }}>
            Show as: List{' '}
          </span>
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              onChange={onChange}
              checked={!isAList}
              onClick={() => dispatch(actionsCustomBox.setIsAList())}
              className="custom-control-input"
              id="customSwitch2"
            />{' '}
            <label
              style={{ cursor: 'pointer', color: '#4A4A4A' }}
              className="custom-control-label ibf-text-navbar-modal"
              htmlFor="customSwitch2">
              <span className="ibf-switch-text-mobile pr-3">Icon</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxDisplayFilterComponent;
