import React from 'react';
import { ProductFilterComponentDi } from './ProductFilterComponentDi';
import { ColorFilterComponentD } from './ColorFilterComponentD';
import { FarmFilterComponentD } from './FarmFilterComponentD';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
// import { ListItemD } from './ListItemD';

const arrayItems = [
  'Acacia',
  'Acuba',
  'Agapanthus',
  'Ageratum',
  'Agonis',
  'Agonis2',
  'Agonis3'
];
const arrayItems2 = [
  'Alstroemeria',
  'Asters',
  'Bouquets',
  'Carnations',
  'Chrysanthemums'
];

export const ProductFilterComponentsDi = () => {

  const closeFilter = () => {
    const divFilters = document.querySelector('.showFilter');
    
    
    if(divFilters) {
      const newClass = divFilters.className.replace('showFilter', '');
      divFilters.className = newClass;
    } 
  }

  return (
    <div className='contFiltersMainD'>

      <button className='btnCloseFilters' onClick={closeFilter}>
        <FontAwesomeIcon icon={faPlus} />
      </button>

      <div>
        <ProductFilterComponentDi
          arrayItems={arrayItems}
          input={'Product Groups'}
        />
        <ProductFilterComponentDi arrayItems={arrayItems2} input={'Category'} />
        <ColorFilterComponentD />
        <FarmFilterComponentD />
      </div>
    </div>
  );
};
