import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import MixpanelService from './../../../Services/MixpanelService';
import { actionsModal } from '../../../Store/Modal/Slice';
import { DELIVERY_SCHEDULE_MODAL } from '../../../Store/Modal/Types';
import CalendarFooter from './CalendarFooter';
import CalendarHeaderComponent from './CalendarHeaderComponent';

const CalendarMobileComponent = ({
  classNames,
  selectedDay,
  children,
  ...props
}: any) => {
  const dispatch = useDispatch();
  const openDeliverySchedule = () => {
    MixpanelService.track('delivery_scheme');
    dispatch(actionsModal.setOpenModal({ modal: DELIVERY_SCHEDULE_MODAL }));
  };

  return (
    <div
      className={classNames?.overlayWrapper}
      style={{ marginLeft: -98, zIndex: 4, width: '100vw' }}
      {...props}>
      <div
        className={classNames?.overlay}
        style={{ overflow: 'auto', maxHeight: '600px' }}>
        <CalendarHeaderComponent onClose={props.onClose} />

        <div className="text-center overflow-auto">
          {children}
          <div
            style={{ marginTop: '-8px' }}
            className="px-3 pb-3 text-left ibf-calendar-border">
            <div className="ibf-calendar-availability flex-wrap">
              <div className="order-0">
                <FontAwesomeIcon
                  color="#8cd198"
                  className="mr-2"
                  icon={faSquare}
                />
                <span>Full availability/shipping</span>
              </div>
              <div className="order-lg-1 order-3">
                <u onClick={openDeliverySchedule} className="pointer">
                  (see delivery scheme)
                </u>
              </div>
              <div className="order-2">
                <FontAwesomeIcon
                  color="#EECD81"
                  className="mr-2"
                  icon={faSquare}
                />
                <span>
                  {' '}
                  Limited availability due to 1 to 3 days shipping options
                </span>
              </div>
            </div>
          </div>
        </div>

        <CalendarFooter />
      </div>
    </div>
  );
};

export default CalendarMobileComponent;
