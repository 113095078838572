import React from 'react';
import { useDispatch } from 'react-redux';
import { MESSAGE_MODAL } from '../../../../Store/Modal/Types';
import { actionsModal } from '../../../../Store/Modal/Slice';

type Props = {
  className?: string;
  message?: string | JSX.Element;
};

export default function MessageProductKommet({ className, message }: Props) {
  const data = {
    body: (
      <span>
        This product is part of a live <b> 'open market' </b>inventory: checkout
        ASAP
      </span>
    ),
    text: (
      <ul>
        <li>
          We can only reserve ‘open market’ products for 30 minutes, after you
          place them in your cart.
        </li>

        <li>
          If you do not checkout within the time frame, your boxes will be
          deleted from your cart automatically by the open market inventory.
        </li>
      </ul>
    ),
    bodyClassName: 'text-break',
    classNameParagraph: 'align-self-start',
    iconCloseEnabled: true,
    size: 'md',
    buttonClose: true,
    textClose: 'Ok, close',
    classNameText: 'font-weight-light d-block ibf-text-line-height-1 mb-2',
    contentClassButton: 'd-flex justify-content-center mt-3',
    buttonClassClose: 'mt-2 mb-3  btn bg-primary text-white px-4',
    cancelAction: () => {}
  };

  const onClick = () => {
    dispatch(
      actionsModal.setOpenModal({
        modal: MESSAGE_MODAL,
        data: data
      })
    );
  };

  const dispatch = useDispatch();
  return (
    <div
      className={`bg-ibf-orange pointer font-weight-bold d-flex  ${className ??
        ''}`}>
      <span className="border-bottom-dashed ibf-font-11" onClick={onClick}>
        {message ?? 'Open market inventory: checkout ASAP'}
      </span>
    </div>
  );
}
