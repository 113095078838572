import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import PaymentOptionModal from './PaymentOptionModal/PaymentOptionModal';
import UsersModal from './UsersModal/UsersModal';
import EditUserModal from './UsersModal/EditUserModal';
import ConfirmModalComponent from './ConfirmModal/ConfirmModalComponent';
import PaymentModalComponent from '../PaymentModal/PaymentModalComponent';
import ManageGrowersModal from './ManageGrowersModal/ManageGrowersModal';
import DeliveryScheduleModal from './DeliveryScheduleModal/DeliveryScheduleModal';
import ShippingDetails from '../ShippingDetailsModal/ShippingDetails';
import AccountManagerComponent from './AccountManagerComponent/AccountManagerComponent';
import GrowerInfoComponent from './GrowerInfoModal/GrowerInfoComponent';
import EditCompanyInformationModal from './CompanyInformationModal/OldCompanyInformation/EditCompanyInformationModal';
import ReferralsModal from './ReferralsModal/ReferralsModal';
import { Elements } from '@stripe/react-stripe-js';
import StripeService from '../../../Services/StripeService';
import GreetingModal from './GreetingModal/GreetingModal';
import HowToUseModal from './HowToUseModal/HowToUseModal';
import ConfirmDeliveryDateModal from './DeliveryDateModal/ConfirmDeliveryDateModal';
import CustomBoxModal from '../../CustomBoxModal/CustomBoxModal';
import CartModal from '../CartModal/CartModal';
import CellphoneModal from './CellphoneModal/CellphoneModal';
import ReplacementsModal from './ReplacementsModal/ReplacementsModal';
//import SignInFlowContainerModal from './LoginModal/SigninFlow/SignInFlowContainerModal';
import SignupGuestModal from '../../SignupGuestModal/SignupGuestModal';
import ConfirmationCheckoutModal from './ConfirmationCheckoutModal/ConfirmationCheckoutModal';
import NewCompanyInformationModal from './CompanyInformationModal/NewCompanyInformationModal';
import { modalReducer } from '../../../Store/Selectors';
import ChangePasswordModal from './ChangePasswordModal/ChangePasswordModal';
import ChangeEmailModal from './ChangeEmailModal/ChangeEmailModal';
import AddAndEditEvent from './AddAndEditEvent/AddAndEditEvent';
import ModalDone from './ModalDone/ModalDone';
import NewEventModal from './NewEventModal/NewEventModal';
import ConfirmProfileModal from './ConfirmProfileModal/ConfirmProfileModal';
import MessageModal from './MessageModal/MessageModal';
import MessageCookiesModal from './MessageCookiesModal/MessageCookiesModal';
import AddressModal from './AddressModal/AddressModal';
import UserMainDataModal from './LoginModal/UserMainDataModal/UserMainDataModal';
import StandingOrderCreationModal from './StadingOrderCreationModal/StandingOrderCreationModal';
import StandingOrderDoneModal from './StadingOrderCreationModal/StandingOrderDoneModal/StandingOrderDoneModal';
import GuestSignUpFormContainerModal from './GuestSignUpForm/GuestSignUpFormContainerModal';
import ConfirmZipcodeModal from './ConfirmZipcodeModal/ConfirmZipcodeModal';

const ModalWrapperContainer = () => {
  const {
    usersListModal,
    addUserModal,
    paymentListModal,
    confirmModal,
    paymentModal,
    manageGrowersModal,
    deliveryScheduleModal,
    shippingDetails,
    accountManagerModal,
    growerInfoModal,
    editCompanyInformationModal,
    referralModal,
    greetingModal,
    howToUseModal,
    confirmDeliveryDateModal,
    CUSTOM_BOX_MODAL,
    CART_MODAL,
    CELLPHONE_MODAL,
    REPLACEMENTS_MODAL,
    SIGN_IN_FLOW,
    SIGN_UP_MODAL,
    COMPANY_INFORMATION_MODAL,
    CONFIRMATION_CHECKOUT_MODAL,
    CHANGE_EMAIL_MODAL,
    CHANGE_PASSWORD_MODAL,
    ADD_EDIT_EVENT_NAME,
    EVENT_MODAL_DONE,
    EVENT_NAME_MODAL,
    CONFIRM_PROFILE_MODAL,
    MESSAGE_MODAL,
    MESSAGE_COOKIE_MODAL,
    ADDRESSES_MODAL,
    GUEST_USER_NAME_CHANGE,
    STANDING_ORDER_CREATION_MODAL,
    STANDING_ORDER_DONE,
    CONFIRM_ZIPCODE_MODAL
  } = useSelector(modalReducer);

  return (
    <Fragment>
      {COMPANY_INFORMATION_MODAL.open && <NewCompanyInformationModal />}
      {usersListModal.open && <UsersModal />}
      {addUserModal.open && <EditUserModal />}
      {paymentListModal.open && <PaymentOptionModal />}
      {confirmModal.open && <ConfirmModalComponent {...confirmModal.data} />}
      <Elements stripe={StripeService}>
        {paymentModal.open && <PaymentModalComponent />}
      </Elements>
      {manageGrowersModal.open && <ManageGrowersModal />}
      {deliveryScheduleModal.open && <DeliveryScheduleModal />}
      {shippingDetails.open && <ShippingDetails />}
      {accountManagerModal.open && <AccountManagerComponent />}
      {growerInfoModal.open && <GrowerInfoComponent />}
      {editCompanyInformationModal.open && <EditCompanyInformationModal />}
      {referralModal.open && <ReferralsModal />}
      {greetingModal.open && <GreetingModal />}
      {howToUseModal.open && <HowToUseModal />}
      {confirmDeliveryDateModal.open && <ConfirmDeliveryDateModal />}
      {CUSTOM_BOX_MODAL.open && <CustomBoxModal />}
      {CART_MODAL.open && <CartModal />}
      {CELLPHONE_MODAL.open && <CellphoneModal />}
      {REPLACEMENTS_MODAL.open && <ReplacementsModal />}
      {SIGN_UP_MODAL.open && <SignupGuestModal />}
      {/* {SIGN_IN_FLOW.open && <SignInFlowContainerModal />} */}
      {SIGN_IN_FLOW.open && <GuestSignUpFormContainerModal />}
      {CONFIRMATION_CHECKOUT_MODAL.open && <ConfirmationCheckoutModal />}
      {CHANGE_EMAIL_MODAL.open && <ChangeEmailModal />}
      {CHANGE_PASSWORD_MODAL.open && <ChangePasswordModal />}
      {EVENT_NAME_MODAL.open && <NewEventModal />}
      {EVENT_MODAL_DONE.open && <ModalDone />}
      {ADD_EDIT_EVENT_NAME.open && <AddAndEditEvent />}
      {CONFIRM_PROFILE_MODAL.open && <ConfirmProfileModal />}
      {MESSAGE_MODAL.open && <MessageModal {...MESSAGE_MODAL.data} />}
      {MESSAGE_COOKIE_MODAL.open && <MessageCookiesModal />}
      {ADDRESSES_MODAL.open && <AddressModal />}
      {GUEST_USER_NAME_CHANGE.open && <UserMainDataModal />}
      {STANDING_ORDER_CREATION_MODAL.open && <StandingOrderCreationModal />}
      {STANDING_ORDER_DONE.open && <StandingOrderDoneModal />}
      {CONFIRM_ZIPCODE_MODAL.open && <ConfirmZipcodeModal />}
    </Fragment>
  );
};

export default ModalWrapperContainer;
