import React, { useState } from 'react';
import Select from 'react-select';
import { FormGroup, Input, Label } from 'reactstrap';
import { DeepMap, FieldError, UseFormRegister } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerRegisterModel } from '../../../../Models/CustomerRegisterModel';
import { CompanyInformationState } from './NewCompanyInformationModal';
import { authReducer } from '../../../../Store/Selectors';
import ZipcodeService from '../../../../Services/ZipcodeService';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { SIGN_UP_MODAL } from '../../../../Store/Modal/Types';
import { actionsCustomer } from '../../../../Store/Customer/Slice';
import {
  companyInformationOptions,
  changeCustomerActualAddress,
  replaceSpecialCharacters
} from '../../../../Utils/Utils';
// import ClosedDays from '../../ClosedDays';

type Props = {
  register: UseFormRegister<CustomerRegisterModel>;
  errors: DeepMap<CustomerRegisterModel, FieldError>;
  setValue: any;
  formState: CompanyInformationState;
  dispatchForm: (
    name: keyof CompanyInformationState,
    value: string | number
  ) => void;
  allDisabled: boolean;
  setAllDisabled: () => void;
  returnClosedDays: (data: Array<string>) => void;
};

const validationInputMessage = (
  <span className="ibf-font-12 color-red">Please fill in this info.</span>
);
const validationSelectMessage = (
  <span className="ibf-font-12 color-red">Please choose an option.</span>
);

const selectValidationStyle = () => {
  return {
    container: (base: any) => ({
      ...base,
      backgroundColor: 'red',
      padding: 1,
      borderRadius: '4px'
    })
  };
};

const CompanyInformationComponent = ({
  register,
  errors,
  setValue,
  formState,
  dispatchForm,
  allDisabled,
  setAllDisabled,
  returnClosedDays
}: Props) => {
  const {
    user: {
      metadata: {
        customer_business,
        customer_events_per_year,
        customer_mobile_phone,
        customer_store_type,
        customer_office_phone,
        customer_stores_quantity,
        customer_website,
        customer_facebook_page,
        from_manager,
        customer_main_street,
        customer_main_city,
        customer_main_state,
        customer_main_zipcode
      }
    }
  } = useSelector(authReducer);

  const [states] = useState<{ [key: string]: string }>(
    ZipcodeService.getStateMap()
  );
  const dispatch = useDispatch();
  const [city, setCity] = useState(customer_main_city || '');
  const [street, setStreet] = useState(customer_main_street || '');

  React.useEffect(() => {
    if (customer_main_street) {
      setStreet(customer_main_street);
    }
    if (customer_main_city) {
      setCity(customer_main_city);
    }
  }, [customer_main_street, customer_main_city]);

  const backToHome = () => {
    dispatch(actionsModal.setCloseModal({ modal: SIGN_UP_MODAL }));
  };

  const getSelectValue = (
    options: { label: string; value: string | number }[],
    value: string
  ) => {
    return options.find((option) => option.value === value);
  };

  return (
    <div>
      <b>Your business type and events a year:</b>

      <div
        className="d-flex flex-column flex-lg-row mt-4"
        style={{ columnGap: '5px' }}>
        <Select
          id="business"
          name="business"
          className="flex-grow-1"
          isDisabled={allDisabled}
          defaultValue={
            getSelectValue(
              companyInformationOptions.business,
              customer_business
            ) ?? {
              label: companyInformationOptions.business[0].label,
              value: companyInformationOptions.business[0].value
            }
          }
          onChange={(e) => dispatchForm('business', e?.value ?? '')}
          styles={
            !formState.business && errors.customer?.business
              ? selectValidationStyle()
              : {}
          }
          options={companyInformationOptions.business.map((business) => ({
            value: business.value,
            label: business.label
          }))}
        />

        {formState.business !== 'consumer' && (
          <div className="flex-grow-1">
            <Select
              id="events_per_year"
              name="events_per_year"
              className="flex-grow-1 mt-2 mt-lg-0"
              isDisabled={allDisabled}
              defaultValue={
                getSelectValue(
                  companyInformationOptions.events,
                  customer_events_per_year
                ) ?? null
              }
              onChange={(e) => dispatchForm('events_per_year', e?.value ?? '')}
              styles={
                !formState.events_per_year && errors.customer?.events_per_year
                  ? selectValidationStyle()
                  : {}
              }
              options={companyInformationOptions.events
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((event) => ({
                  value: event.value,
                  label: event.label
                }))}
            />
            {errors?.customer?.events_per_year && validationSelectMessage}
          </div>
        )}
      </div>

      {formState.business === 'consumer' ? (
        <div className={'d-flex flex-column'}>
          <span className="mb-3">
            We're so sorry. iBuyFlowers is a business to business wholesaler
            marketplace and can only sell to businesses with a resale number.
          </span>
          <span className="underline pointer" onClick={backToHome}>
            {'<'} Stop and go back to home
          </span>
        </div>
      ) : (
        <>
          <div className="d-flex flex-column mt-2">
            <Label>My business is a: </Label>

            <div className="d-flex flex-column flex-lg-row">
              <div className="flex-grow-1 mr-1">
                <Select
                  id="storefront_type"
                  name="storefront_type"
                  className="flex-grow-1 mt-2 mt-lg-0"
                  isDisabled={allDisabled}
                  defaultValue={
                    getSelectValue(
                      companyInformationOptions.storefront,
                      customer_store_type
                    ) ?? null
                  }
                  onChange={(e) =>
                    dispatchForm('storefront_type', e?.value ?? '')
                  }
                  styles={
                    !formState.storefront_type &&
                    errors.customer?.storefront_type
                      ? selectValidationStyle()
                      : {}
                  }
                  options={companyInformationOptions.storefront
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((store) => ({
                      value: store.value,
                      label: store.label
                    }))}
                />
                {errors?.customer?.storefront_type && validationSelectMessage}
              </div>

              {formState.storefront_type !== 'Home-based studio' && (
                <div className="flex-grow-1">
                  <Select
                    id="stores_quantity"
                    name="stores_quantity"
                    className="mt-2 mt-lg-0"
                    isDisabled={allDisabled}
                    placeholder={'-- Select the number of stores'}
                    defaultValue={
                      getSelectValue(
                        companyInformationOptions.stores_quantity,
                        customer_stores_quantity
                      ) ?? null
                    }
                    onChange={(e) =>
                      dispatchForm('stores_quantity', e?.value ?? '')
                    }
                    styles={
                      !formState.stores_quantity &&
                      errors.customer?.stores_quantity
                        ? selectValidationStyle()
                        : {}
                    }
                    options={companyInformationOptions.stores_quantity}
                  />
                  {errors?.customer?.stores_quantity && validationSelectMessage}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex flex-column mt-2">
            <span>
              How many people are working in your business (owners and employees
              in total)?*
            </span>
            <div className="d-flex flex-wrap">
              {companyInformationOptions.employees.map((option, index) => (
                <div className="form-check mr-3" key={index}>
                  <input
                    className="form-check-input"
                    type="radio"
                    disabled={allDisabled}
                    name={option.value.toString()}
                    id={option.value.toString()}
                    onChange={(event) =>
                      dispatchForm(
                        'employees_quantity',
                        parseInt(event.target.name)
                      )
                    }
                    checked={formState.employees_quantity === option.value}
                  />
                  <label
                    className="normal-register-label"
                    htmlFor={option.value.toString()}>
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
            {errors?.customer?.employees_quantity && validationSelectMessage}
          </div>

          <b>
            Your primary address (other addresses can be set while shopping)
          </b>

          <div className="d-flex flex-column mt-2">
            <div
              className="d-flex flex-column flex-lg-row "
              style={{ columnGap: '5px' }}>
              <FormGroup className="input-register flex-grow-1 mb-3">
                <Input
                  type="text"
                  id={'street'}
                  placeholder=" "
                  value={street}
                  disabled={allDisabled}
                  {...register('customer.street', {
                    required: from_manager ? !from_manager : !allDisabled
                    // pattern: !from_manager && /^[A-Za-z0-9]+$/
                  })}
                  onChange={(evt) => {
                    let value = replaceSpecialCharacters(evt.target.value);
                    setStreet(value);
                    if (evt.target.value.length > 3 && customer_main_street) {
                      const value = changeCustomerActualAddress(
                        evt.target.value,
                        customer_main_street,
                        'address'
                      );
                      dispatch(actionsCustomer.setShowMessage(value));
                    }
                    setStreet(evt.target.value.replace(/[^\w\s]/gi, ''));
                    setValue(
                      'customer.street',
                      evt.target.value.replace(/[^\w\s]/gi, '')
                    );
                  }}
                />
                {errors?.customer?.street && validationInputMessage}
                <label htmlFor="street" className="register-label">
                  Street <span className="text-danger"> * </span>{' '}
                </label>
              </FormGroup>
              <div className="form-group input-register flex-grow-1">
                <Input
                  type="text"
                  placeholder=" "
                  id={'city'}
                  value={city}
                  defaultValue={customer_main_city || ''}
                  disabled={allDisabled}
                  {...register('customer.city', {
                    required: from_manager ? !from_manager : !allDisabled
                    // pattern: !from_manager && /^[A-Za-z0-9]+$/
                  })}
                  onChange={(evt) => {
                    let value = replaceSpecialCharacters(evt.target.value);
                    setCity(value);
                    if (customer_main_city && evt.target.value.length > 3) {
                      const value = changeCustomerActualAddress(
                        evt.target.value,
                        customer_main_city,
                        'city'
                      );
                      dispatch(actionsCustomer.setShowMessage(value));
                    }
                    setCity(evt.target.value.replace(/[^\w\s]/gi, ''));
                    setValue(
                      'customer.city',
                      evt.target.value.replace(/[^\w\s]/gi, '')
                    );
                  }}
                />
                {errors?.customer?.city && validationInputMessage}
                <label htmlFor="city" className="register-label">
                  City <span className="text-danger"> * </span>{' '}
                </label>
              </div>
            </div>
            {/*<div>*/}
            {/*  <Label>*/}
            {/*    <b> For this address </b>*/}
            {/*  </Label>*/}
            {/*  <ClosedDays*/}
            {/*    isDisabled={allDisabled}*/}
            {/*    isUpdateProfileView*/}
            {/*    returnClosedDays={(data) => returnClosedDays(data)}*/}
            {/*  />*/}
            {/*</div>*/}
            <div className="state-row mb-2">
              <div className=" normal-register-label input-register input-states mt-0 flex-grow-1">
                <label htmlFor="state">
                  <b>State</b> <span className="text-danger"> * </span>{' '}
                </label>
                <Select
                  defaultValue={
                    getSelectValue(
                      Object.keys(states).map((state) => ({
                        value: state,
                        label: states[state]
                      })),
                      customer_main_state
                    ) ?? null
                  }
                  isDisabled={allDisabled}
                  styles={
                    !formState.state && errors.customer?.state
                      ? selectValidationStyle()
                      : {}
                  }
                  name="state"
                  onChange={(evt) => {
                    if (evt?.value && customer_main_state) {
                      let value = changeCustomerActualAddress(
                        evt?.value,
                        customer_main_state,
                        'state'
                      );
                      dispatch(actionsCustomer.setShowMessage(value));
                    }
                    dispatchForm('state', evt?.value ?? '');
                  }}
                  options={Object.keys(states).map((state) => ({
                    value: state,
                    label: states[state]
                  }))}
                />
                {errors?.customer?.state && validationSelectMessage}

                {!errors?.customer?.state && errors?.customer?.zipcode && (
                  <span className="ibf-font-12 text-white">
                    Please write a zipcode
                  </span>
                )}
              </div>
              <div className="input-register flex-grow-1">
                <Input
                  type="number"
                  placeholder=" "
                  disabled={allDisabled}
                  id={'zipcode'}
                  maxLength={5}
                  {...register('customer.zipcode', {
                    required: from_manager ? !from_manager : !allDisabled,
                    pattern: !from_manager && /([^\s])/,
                    maxLength: allDisabled ? undefined : 5,
                    minLength: allDisabled ? undefined : 5
                  })}
                  onChange={(evt) => {
                    if (customer_main_zipcode && evt.target.value.length > 3) {
                      let value = changeCustomerActualAddress(
                        evt.target.value,
                        customer_main_zipcode,
                        'zipcode'
                      );
                      dispatch(actionsCustomer.setShowMessage(value));
                    }

                    setValue('customer.zipcode', evt.target.value.toString());
                  }}
                  defaultValue={customer_main_zipcode || ''}
                />
                {errors.customer?.zipcode?.type === 'minLength' ||
                errors.customer?.zipcode?.type === 'maxLength' ? (
                  <span className="ibf-font-12 ibf-text-red">
                    Sorry, your zip code doesn't seem right
                  </span>
                ) : (
                  errors?.customer?.zipcode && validationInputMessage
                )}
                {errors?.customer?.state && !errors?.customer?.zipcode && (
                  <span className="ibf-font-12 text-white">
                    Please write a zipcode
                  </span>
                )}
                <label htmlFor="zipcode" className={'register-label'}>
                  Zipcode <span className="text-danger"> * </span>{' '}
                </label>
              </div>
            </div>

            <div
              className="d-flex flex-column flex-lg-row mb-2"
              style={{ columnGap: '5px' }}>
              <div className="input-register flex-grow-1">
                <Input
                  type="text"
                  placeholder=" "
                  disabled={allDisabled}
                  id={'mobile_phone'}
                  defaultValue={customer_mobile_phone || ''}
                  {...register('customer.mobile_phone', {
                    required: from_manager ? !from_manager : !allDisabled,
                    pattern: !from_manager && /([^\s])/,
                    minLength: 10
                  })}
                  onChange={(evt) =>
                    setValue('customer.mobile_phone', evt.target.value)
                  }
                />

                <div className="d-flex flex-column">
                  {errors.customer?.mobile_phone?.type === 'minLength' && (
                    <span className="ibf-font-12 color-red">
                      Please fill in the 10 digits of your mobile phone number.
                    </span>
                  )}

                  {errors.customer?.mobile_phone && validationInputMessage}
                </div>
                <label htmlFor="mobile_phone" className="register-label">
                  (Mobile) number when contact is needed{' '}
                  <span className="text-danger"> * </span>{' '}
                </label>
              </div>
              <div className="form-group input-register flex-grow-1">
                <Input
                  type="text"
                  autoComplete="none"
                  placeholder=" "
                  id={'office_phone'}
                  disabled={allDisabled}
                  defaultValue={customer_office_phone || ''}
                  {...register('customer.office_phone', {
                    required: from_manager ? !from_manager : !allDisabled,
                    pattern: !from_manager && /([^\s])/
                  })}
                  onChange={(evt) =>
                    setValue('customer.office_phone', evt.target.value)
                  }
                />
                {errors?.customer?.office_phone && validationInputMessage}
                <label htmlFor="office_phone" className="register-label">
                  Office Phone <span className="text-danger"> * </span>{' '}
                </label>
              </div>
            </div>

            <div
              className="d-flex flex-column flex-lg-row mb-3"
              style={{ columnGap: '5px' }}>
              <div className="form-group input-register flex-grow-1 mt-1">
                <Input
                  id={'website'}
                  type="text"
                  placeholder=" "
                  disabled={allDisabled}
                  {...register('customer.website')}
                  defaultValue={customer_website || ''}
                  onChange={(evt) =>
                    setValue('customer.website', evt.target.value)
                  }
                />
                <label htmlFor="website" className="register-label">
                  Your website address (optional)
                </label>
              </div>

              <div className="form-group input-register flex-grow-1 mt-1">
                <Input
                  type="text"
                  id={'facebook_page'}
                  disabled={allDisabled}
                  placeholder=" "
                  {...register('customer.facebook_page')}
                  defaultValue={customer_facebook_page || ''}
                  onChange={(evt) =>
                    setValue('customer.facebook_page', evt.target.value)
                  }
                />
                <label htmlFor="facebook_page" className="register-label">
                  Your facebook address (optional)
                </label>
              </div>
            </div>
            {!allDisabled && (
              <div className="d-flex d-lg-none justify-content-start">
                <span className="ibf-link" onClick={setAllDisabled}>
                  Cancel
                </span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyInformationComponent;
