import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DayPicker from 'react-day-picker';
import ImageComponent from '../../ImageComponent/ImageComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  addObjectParamsToPath,
  convertDateToNumberDate,
  hrefToObject
} from '../../../../Utils/Utils';
import { getISOWeek } from 'date-fns';
import Environment from '../../../../Environment';
import { useHistory, useLocation } from 'react-router-dom';
import { actionsFilter } from '../../../../Store/Filter/Slice';
import { actionsProduct } from '../../../../Store/Product/Slice';
import { RootState } from '../../../../Store/Reducers';

type Props = {
  className?: string;
  toggleDeliveryDateModal: () => void;
  openDeliveryDateModal: boolean;
  dateList?: boolean;
};

const DeliveryDateModal: React.FC<Props> = ({
  className,
  toggleDeliveryDateModal,
  openDeliveryDateModal,
  dateList
}) => {
  const dispatch = useDispatch();

  const { disabledDays, modifiers, modifiersStyles } = useSelector(
    (state: RootState) => state.CalendarReducer
  );
  const { filters_conditions, search } = useSelector(
    (state: RootState) => state.FilterReducer
  );
  const { manager } = useSelector((state: RootState) => state.AuthReducer);
  const history = useHistory();
  const location = useLocation();
  const handleDate = async (date: Date, modifiers: any) => {
    if (modifiers.disabled) {
      return;
    }
    const dateForSearch = convertDateToNumberDate(date);
    const numberWeek = getISOWeek(date);
    dispatch(
      actionsFilter.setFilters({ ...filters_conditions, week: numberWeek })
    );
    dispatch(actionsProduct.setProductDate({ date: dateForSearch }));

    toggleDeliveryDateModal();

    if (dateList) {
      const queryParams = {
        ...hrefToObject(),
        date: convertDateToNumberDate(date)
      };
      const params = {
        search_val: search,
        ...filters_conditions,
        ...queryParams
      };

      history.push(addObjectParamsToPath(location.pathname, params));
    }
  };
  return (
    <div>
      <Modal
        isOpen={openDeliveryDateModal}
        size="lg"
        toggle={toggleDeliveryDateModal}
        className={className}>
        <ModalHeader toggle={toggleDeliveryDateModal}>
          Select a delivery date:{' '}
        </ModalHeader>
        <ModalBody className="text-center">
          <DayPicker
            disabledDays={disabledDays}
            modifiers={modifiers}
            modifiersStyles={modifiersStyles}
            onDayClick={handleDate}
            numberOfMonths={2}
          />
        </ModalBody>
        <ModalFooter className="background-yellow">
          <div className="justify-content-center d-flex align-items-center w-100">
            <ImageComponent
              className="mr-3"
              imageUrl={`${Environment.publicPath}images/${
                manager?.email?.split('@')[0]
              }.png`}
              width="50px"
            />
            <span>
              Best Delivery Day for this Flower is 3-5 Days before your Event.
            </span>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeliveryDateModal;
