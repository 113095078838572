import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import MixpanelService from './../../../Services/MixpanelService';
import { actionsModal } from '../../../Store/Modal/Slice';
import { DELIVERY_SCHEDULE_MODAL } from '../../../Store/Modal/Types';
import CalendarFooter from './CalendarFooter';
import CalendarHeaderComponent from './CalendarHeaderComponent';
import OverlayComponent from '../OverlayComponent';

const CalendarComponent = ({
  classNames,
  selectedDay,
  width,
  children,
  ...props
}: any) => {
  const dispatch = useDispatch();
  const openDeliverySchedule = () => {
    MixpanelService.track('delivery_scheme');
    dispatch(actionsModal.setOpenModal({ modal: DELIVERY_SCHEDULE_MODAL }));
  };

  return (
    <>
      <OverlayComponent
        height={'100%'}
        width={'100%'}
        zIndex={3}
        backgroundColor={'rgb(0 0 0 / 25%)'}
      />
      <div
        className={classNames?.overlayWrapper}
        style={{
          marginLeft: -380,
          marginRight: -100,
          zIndex: 4,
          width: width ? width : '600px'
        }}
        {...props}>
        <div className={`${classNames?.overlay} ibf-calendar-wrapper`}>
          <CalendarHeaderComponent onClose={props.onClose} />

          {children}

          <div className="p-3 pt-0 text-left ibf-calendar-border">
            <div className="ibf-calendar-availability">
              <div>
                <FontAwesomeIcon
                  color="#8cd198"
                  className="mr-2"
                  icon={faSquare}
                />
                <span>Full availability/shipping</span>
                <br />
                <span
                  onClick={openDeliverySchedule}
                  className="pointer underline">
                  (see delivery schedule)
                </span>
              </div>

              <div>
                <FontAwesomeIcon
                  color="#EECD81"
                  className="mr-2"
                  icon={faSquare}
                />
                <span>
                  {' '}
                  Limited availability due to 1 to 3 days shipping options
                </span>
              </div>
            </div>
          </div>
          <CalendarFooter />
        </div>
      </div>
    </>
  );
};

export default CalendarComponent;
