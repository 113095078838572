import React, { useCallback, useEffect, useState } from 'react';
import FilterListComponent from './FilterListComponent';
import SearchFilter from './SearchFilter';
import Filter from '../../../Models/Filter';
import FilterItem from '../../../Models/FilterItem';
import { useDispatch, useSelector } from 'react-redux';
import BoxFilterListComponent from '../../CustomBoxModal/BoxProductsComponent/BoxProductFilter/BoxFilterListComponent';
import { actionsFilter } from '../../../Store/Filter/Slice';
import { RootState } from '../../../Store/Reducers';
import ZipcodeService from '../../../Services/ZipcodeService';
import { authReducer } from '../../../Store/Selectors';

type Props = {
  listItemsFilter: Filter;
  isMobile: boolean;
  isBox?: boolean;
};

const ProductFilterItem: React.FC<Props> = ({
  listItemsFilter,
  isMobile,
  isBox
}) => {
  const [filterItems, setFilterItems] = useState<Array<FilterItem>>([]);
  const [search, setSearch] = useState('');
  const { user } = useSelector(authReducer);

  const { resetFilter } = useSelector(
    (state: RootState) => state.FilterReducer
  );
  const dispatch = useDispatch();

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const handleClean = useCallback(() => {
    setSearch('');
    dispatch(actionsFilter.setResetFilters(false));
  }, [dispatch]);

  useEffect(() => {
    const results = listItemsFilter.values.filter(
      (item: FilterItem) =>
        item.value !== null &&
        item.value.toLowerCase().includes(search.toLowerCase())
    );
    let items: FilterItem[] = [];
    items = results.length > 0 ? results : listItemsFilter.values;

    if (
      ZipcodeService._STATES_WITH_ZIPCODES.HI.codes.includes(
        user.metadata.customer_main_zipcode
      )
    ) {
      items = items.filter(
        (item) => item.key !== '12932620-8484-4dba-bd71-205ed8e5da22'
      );
    }

    setFilterItems(
      [...items].sort((a: FilterItem, b: FilterItem) => {
        if (a.selected > b.selected) return -1;
        if (a.selected < b.selected) return 1;
        return 0;
      })
    );
    resetFilter && search.length && handleClean();
  }, [
    search,
    listItemsFilter,
    handleClean,
    resetFilter,
    user.metadata.customer_main_zipcode
  ]);

  return (
    <div className={`ibf-filter-bar ${isBox ? 'bg-white border' : ''}`}>
      <SearchFilter
        placeholderSearchName={listItemsFilter.label}
        search={search}
        isBox={isBox}
        handleChange={(event: React.FormEvent<HTMLInputElement>) =>
          handleChange(event)
        }
      />
      {isBox ? (
        <BoxFilterListComponent
          filterLabel={listItemsFilter.label}
          listItems={filterItems}
          isMobile={isMobile}
        />
      ) : (
        <FilterListComponent
          filterLabel={listItemsFilter.label}
          listItems={filterItems}
          isMobile={isMobile}
        />
      )}
    </div>
  );
};

export default ProductFilterItem;
