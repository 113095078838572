import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Popover } from 'antd';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';
import {
  TextDelivered,
  SecurePrices,
  ShopGrowers,
  QualityRating,
  Website
} from './UspContentPopover';
const items = [
  {
    id: 1,
    icon: <FontAwesomeIcon icon={faCheck} className="color-green-light" />,
    text: 'Delivered in 24h - 72h',
    component: <TextDelivered />,
    visiblePopover: false
  },
  {
    id: 2,
    icon: <FontAwesomeIcon icon={faCheck} className="color-green-light" />,
    text: 'Secure your prices months ahead',
    component: <SecurePrices />,
    visiblePopover: false
  },
  {
    id: 3,
    icon: <FontAwesomeIcon icon={faCheck} className="color-green-light" />,
    text: 'Shop directly from 100+ farms',
    component: <ShopGrowers />,
    visiblePopover: false
  },
  {
    id: 4,
    icon: <FontAwesomeIcon icon={faCheck} className="color-green-light" />,
    text: 'Quality rating 4,8 / 5',
    component: <QualityRating />,
    visiblePopover: false
  },
  {
    id: 5,
    icon: <FontAwesomeIcon icon={faCheck} className="color-green-light" />,
    text: 'Website updates based on feedback',
    component: <Website />,
    visiblePopover: false
  }
];

const UspCarousel = () => {
  const [animating, setAnimating] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [item, setItem] = useState<any>(null);
  const handleVisibleChange = (visible: boolean, id?: number) => {
    const itemSelected = items.find((item) => item.id === id);
    setItem(itemSelected ?? null);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = items.map((item) => {
    const text = (
      <Popover
        placement={'bottom'}
        trigger="click"
        visible={item.visiblePopover}
        content={item.component}
        onVisibleChange={(visible) => handleVisibleChange(visible, item.id)}
        overlayClassName="custom-step-desktop-popover w-75">
        <div className="d-flex align-items-center justify-content-center pointer">
          {item.icon}
          <span className="ml-2 border-bottom-dashed">{item.text}</span>
        </div>
      </Popover>
    );
    return (
      <CarouselItem
        /* className="custom-tag" */
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}>
        {text}
      </CarouselItem>
    );
  });

  return (
    <div>
      {!item ? (
        <Carousel
          activeIndex={activeIndex}
          next={next}
          interval={4000}
          previous={previous}
          ride={'carousel'}>
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
            className="text-primary"
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      ) : (
        <div
        /* className="custom-tag" */
        >
          <Popover
            placement={'bottom'}
            trigger="click"
            visible
            content={item.component}
            onVisibleChange={(visible) => handleVisibleChange(visible)}
            overlayClassName="custom-step-desktop-popover w-75">
            <div className="d-flex align-items-center justify-content-center pointer">
              {item.icon}
              <span className="ml-2 border-bottom-dashed">{item.text}</span>
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};
export default UspCarousel;
