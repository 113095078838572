import React, { useReducer, useRef, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ContactDetailsComponent from './ContactDetailsComponent';
import CompanyInformationComponent from './CompanyInformationComponent';
import './signupGuestModal.scss';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  toast,
  validationNumber,
  validationSpecialCharacters,
  validateNumberLetters,
  getNumbersWithoutSymbols
} from '../../Utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Store/Reducers';
import { actionsModal } from '../../Store/Modal/Slice';
import { SIGN_UP_MODAL } from '../../Store/Modal/Types';
import {
  checkEmailAvailable,
  guestSignUp,
  validateToken
} from '../../Services/AuthService';
import { plainToClass } from 'class-transformer';
import AuthResponse from '../../Models/AuthResponse';
import { actionsAuth } from '../../Store/Auth/Slice';
import MixpanelService from '../../Services/MixpanelService';
import { CustomerRegisterModel } from '../../Models/CustomerRegisterModel';
import { State } from '../../Models/State';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { authReducer } from '../../Store/Selectors';
import { TrackEventMonitor } from '../../Classes/TrackEvent';
import { getCheckAvailableUserPhone } from '../../Services/UserService';

export type SignUpState = {
  state: string;
  hear_about_us_first_time?: string;
  business: string;
  events_per_year: string;
  employees_quantity: number;
  storefront_type: string;
  stores_quantity: number;
};

const initialState: SignUpState = {
  state: '',
  hear_about_us_first_time: '',
  business: '',
  events_per_year: '< 10',
  employees_quantity: 0,
  storefront_type: '',
  stores_quantity: 0
};

enum Types {
  ADD_FORM
}

function reducer(
  state: SignUpState,
  { type, payload }: { type: Types; payload: any }
) {
  switch (type) {
    case Types.ADD_FORM:
      return {
        ...state,
        [payload.name]: payload.value
      };
    default:
      throw new Error();
  }
}

const SignupGuestModal = () => {
  const [isLoading, setIsLoading] = useState(State.IDLE);
  const [state, dispatchLocal] = useReducer(reducer, initialState);
  const {
    SIGN_UP_MODAL: { open }
  } = useSelector((state: RootState) => state.ModalReducer);
  const dispatch = useDispatch();
  const checkboxConfirm = useRef<HTMLInputElement>(null);

  const {
    token,
    config,
    manager,
    user: {
      id,
      key,
      email,
      metadata: {
        customer,
        main_user_id,
        customer_business,
        customer_events_per_year,
        main_user_email,
        customer_name,
        main_user_key,
        customer_employees_quantity,
        customer_store_type
      }
    }
  } = useSelector(authReducer);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    clearErrors
  } = useForm<CustomerRegisterModel>();

  React.useEffect(() => {
    dispatchLocal({
      type: Types.ADD_FORM,
      payload: { name: 'business', value: customer_business }
    });
    dispatchLocal({
      type: Types.ADD_FORM,
      payload: { name: 'events_per_year', value: customer_events_per_year }
    });

    dispatchLocal({
      type: Types.ADD_FORM,
      payload: {
        name: 'employees_quantity',
        value: customer_employees_quantity
      }
    });

    dispatchLocal({
      type: Types.ADD_FORM,
      payload: { name: 'storefront_type', value: customer_store_type }
    });
  }, [
    customer_business,
    customer_events_per_year,
    customer_store_type,
    customer_employees_quantity
  ]);

  React.useEffect(() => {
    setValue('customer.company_name', customer_name);
    setValue('user.user_email', main_user_email ?? email);
  }, [setValue, email, customer_name, main_user_email]);

  const signUp = async (params: CustomerRegisterModel) => {
    //state.userKey = key;
    //state.token = token;
    params.customer._KEY = customer;
    params.token = token;
    params.userKey = main_user_key ?? key;
    params.user.id = main_user_id ?? id;

    const res = await guestSignUp(params);
    if (res && res.success) {
      TrackEventMonitor.newEvent({
        props: {
          email: params.user.user_email,
          first_name: params.user.first_name,
          last_name: params.user.last_name,
          ...params.customer,
          signup_place: 'shopping'
        },
        metadata: { name: 'ibf_signup_submit' }
      });

      if (main_user_id) {
        window.location.reload();
      }
      const {
        data: { user, isNewDay, offset }
      }: any = await validateToken(token);

      const authData = plainToClass(AuthResponse, {
        token,
        config,
        manager,
        user,
        isNewDay,
        offset
      });
      dispatch(actionsAuth.setAuthData(authData));

      toast('Sign up successful');
      MixpanelService.track('signup_new', {
        email: params.user.user_email,
        first_name: params.user.first_name,
        last_name: params.user.last_name,
        company_name: params.customer.company_name,
        office_phone: params.customer.office_phone,
        signup_place: 'shopping'
      });
      toggle();
    } else {
      toast(res.error || 'An error ocurred', 'error');
      setIsLoading(State.REJECTED);
      MixpanelService.track('signup_error', {
        user: params.user,
        company_name: params.customer.company_name,
        office_phone: params.customer.office_phone
      });
    }
  };

  const onSubmit: SubmitHandler<CustomerRegisterModel> = async (data) => {
    switch (true) {
      case !state.employees_quantity:
        setError('customer.employees_quantity', {
          type: 'manual',
          message: 'Please select the employees_quantity'
        });
        break;
      case !state.state:
        setError('customer.state', {
          type: 'manual',
          message: 'Please select the customer state'
        });
        break;
      case !state.hear_about_us_first_time:
        setError('customer.hear_about_us_first_time', {
          type: 'manual',
          message: 'Please select how you hear about us for first time'
        });
        break;
      default:
        if (
          !state.stores_quantity &&
          state.storefront_type !== 'Home-based studio'
        ) {
          setError('customer.stores_quantity', {
            type: 'manual',
            message: 'Please select stores quantity'
          });
          return;
        }

        if (
          data.user?.password?.toLowerCase() !==
          data.user?.repeatPassword?.toLowerCase()
        ) {
          toast('Password does not match', 'error');
          return;
        }

        if (
          data.user?.user_email?.toLowerCase() !==
          data.user?.repeatEmail?.toLowerCase()
        ) {
          toast('Email does not match', 'error');
          return;
        }

        if (data.customer.mobile_phone) {
          const responsePhone = await getCheckAvailableUserPhone({
            phone: data.customer.mobile_phone,
            user_key: main_user_key ?? key
          });

          if (responsePhone?.success && responsePhone.row_count > 0) {
            setError('customer.mobile_phone', {
              type: 'manual',
              message: ''
            });

            return;
          }
        }
        if (!validationNumber(data?.customer?.zipcode!)) {
          setError('customer.zipcode', {
            type: 'pattern'
          });
          setIsLoading(State.REJECTED);
          return;
        } else {
          clearErrors('customer.zipcode');
        }
        if (!validationSpecialCharacters(data?.customer?.city!)) {
          setError('customer.city', {
            type: 'pattern',
            message: 'Only letters are allowed'
          });
          setIsLoading(State.REJECTED);
          return;
        } else {
          clearErrors('customer.city');
        }
        if (!validateNumberLetters(data?.customer?.street!)) {
          setError('customer.street', {
            type: 'pattern',
            message: 'Only letters and numbers are allowed'
          });
          setIsLoading(State.REJECTED);
          return;
        } else {
          clearErrors('customer.street');
        }
        if (!checkboxConfirm?.current?.checked) {
          setError('customer.checkboxConfirm', {
            type: 'manual',
            message: `Please select the checkbox`
          });
          setIsLoading(State.REJECTED);
          return;
        } else {
          clearErrors('customer.checkboxConfirm');
        }

        if (data.customer.mobile_phone) {
          const responsePhone = await getCheckAvailableUserPhone({
            phone: data.customer.mobile_phone,
            user_key: main_user_key ?? key
          });

          if (responsePhone?.success && responsePhone.row_count > 0) {
            setError('customer.mobile_phone', {
              type: 'manual',
              message: ''
            });

            return;
          }
        }

        setIsLoading(State.PENDING);

        const isAvailable = await checkEmailAvailable(
          data.user.user_email,
          true
        );
        if (!isAvailable) {
          setError('user.user_email', {
            type: 'manual',
            message: ''
          });
          setIsLoading(State.REJECTED);
          return;
        }
        delete data.customer?.checkboxConfirm;
        delete data.user?.repeatEmail;
        delete data.user?.repeatPassword;
        data.customer.mobile_phone = getNumbersWithoutSymbols(
          data?.customer?.mobile_phone!
        );
        data.customer.office_phone = getNumbersWithoutSymbols(
          data?.customer.office_phone!
        );

        const params = {
          user: { ...data.user, cellphone: data.customer.mobile_phone },
          customer: { ...data.customer, ...state },
          token: '',
          userKey: '',
          is_new: false,
          role: 'FLORIST',
          referral_code: ''
        };

        signUp(params);
        break;
    }
  };

  const dispatchForm = (name: keyof SignUpState, value: string | number) => {
    if (state.storefront_type === 'Home-based studio') {
      clearErrors('customer.stores_quantity' as keyof CustomerRegisterModel);
    }
    clearErrors(`customer.${name}` as keyof CustomerRegisterModel);
    dispatchLocal({ type: Types.ADD_FORM, payload: { name, value } });
  };

  const toggle = () => {
    dispatch(actionsModal.setCloseModal({ modal: SIGN_UP_MODAL }));
  };

  return (
    <Modal isOpen={open} size={'xl'}>
      <div className="d-flex align-items-center justify-content-between p-3">
        <h2>
          <b>Sign up to continue</b>{' '}
          <small>(it's free and this page only)</small>
        </h2>
        <FontAwesomeIcon
          className={'pointer'}
          onClick={toggle}
          icon={faTimes}
          size={'2x'}
        />
      </div>

      <ModalBody className="signup-grid hidden-hs-form">
        <ContactDetailsComponent
          register={register}
          errors={errors}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
        />
        <CompanyInformationComponent
          register={register}
          dispatchForm={dispatchForm}
          checkboxConfirm={checkboxConfirm}
          setError={setError}
          clearErrors={clearErrors}
          setValue={setValue}
          formState={state}
          errors={errors}
          onSubmit={() => handleSubmit(onSubmit)()}
          loading={State.PENDING === isLoading}
        />
      </ModalBody>
    </Modal>
  );
};

export default SignupGuestModal;
