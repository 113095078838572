import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { dashboardReducer } from '../../Store/Selectors';
import {
  convertMinutesToHour,
  getEstNowTimeMinutes,
  getGrowerTime,
  USA
} from '../../Utils/Utils';
import { Grower } from '../../Models/Grower';

type Props = {
  className?: string;
  classIcon?: string;
  classNameSpan?: string;
  textColor?: 'black' | 'white';
  setHideMessage?: (hide: boolean) => void;
};

const getDiffHours = (hour: number, minute: number) => {
  const tomorrow = moment(new Date())
    .tz('America/New_York')
    .add(1, 'days')
    .set({ hour: hour, minute: minute, second: 0 });
  const today = moment(new Date()).tz('America/New_York');
  const diff = moment.duration(tomorrow.diff(today));

  return `${diff.hours()}h${diff.minutes()}m`;
};

export default function MessageReminderShop({
  classIcon,
  classNameSpan,
  className,
  textColor,
  setHideMessage
}: Props) {
  const { growerList } = useSelector(dashboardReducer);

  const [closeTime, setCloseTime] = useState('');

  React.useEffect(() => {
    if (Object.keys(growerList).length > 0) {
      let growerTimes: number[] = [];
      Object.values(growerList)
        .flat()
        .forEach((grower) => {
          if (
            (grower as Grower).country === USA &&
            (grower as Grower).closing_time
          ) {
            if (
              getGrowerTime((grower as Grower).closing_time as string) >
              getEstNowTimeMinutes()
            ) {
              growerTimes.push(
                getGrowerTime((grower as Grower).closing_time as string)
              );
            }
          }
        });

      if (growerTimes.length > 0) {
        // console.log(growerTimes)
        // console.log(convertMinutesToHour(Math.min(...growerTimes)))
        setCloseTime(convertMinutesToHour(Math.min(...growerTimes)));
      } else {
        if (setHideMessage) {
          setHideMessage(true);
        }
      }
    } else {
      setCloseTime('');
      if (setHideMessage) {
        setHideMessage(true);
      }
    }
  }, [growerList, setHideMessage]);

  return (
    <div
      style={{ color: textColor ?? 'black' }}
      className={`${
        !closeTime ? 'd-none' : 'd-flex'
      } flex-grow-1 justify-content-center  align-items-start align-items-lg-center ${className ??
        ''}`}>
      <FontAwesomeIcon
        icon={faClock}
        color={'black'}
        size={'1x'}
        className={classIcon}
      />

      <div className={``}>
        You have max{' '}
        <b>
          {getDiffHours(
            parseInt(closeTime.split(':')[0]),
            parseInt(closeTime.split(':')[1])
          )}
        </b>{' '}
        left to checkout for delivery tomorrow (US growers only). Some growers
        might close sooner. Your Credit Card will be charged immediately at
        checkout.
      </div>
    </div>
  );
}
