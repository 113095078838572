import { Popover } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionsModal } from '../../Store/Modal/Slice';
import { CONFIRM_DELIVERY_DATE_MODAL } from '../../Store/Modal/Types';
import { actionsOrder } from '../../Store/Order/Slice';
import { RootState } from '../../Store/Reducers';
import BoxOrderDetailComponent from './BoxOrderDetailComponent';
import SideInfoOrderDetailComponent from './SideInfoOrderDetailComponent';
import { actionsCustomer } from '../../Store/Customer/Slice';
import backButton from '../../Assets/icons/backButton.png';
import { useHistory } from 'react-router';
import { State } from '../../Models/State';
import { eventReducer } from '../../Store/Selectors';
import { eventActions } from '../../Store/Event/Slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { convertNumberDateToFullDate2 } from '../../Utils/Utils';
import { Cartbox } from '../../Models/Cartbox';
import { Grower } from '../../Models/Grower';
import { Variety } from '../../Models/Variety';
import ProductGroup from '../../Models/ProductGroup';
import dhlLogo from '../../Assets/Images/dhl_logo_mini.png';

type Detail = {
  _KEY: string;
} & Cartbox &
  Grower &
  Variety &
  ProductGroup;

type Group = {
  [key: string]: Array<Detail>;
};

type GroupedDetail = {
  date: string;
  detail: Array<Detail>;
};

const OrderDetailComponent = (props: any) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.AuthReducer);
  const history = useHistory();
  const { orderDetail, orderDetailLoaded, state } = useSelector(
    (state: RootState) => state.OrderReducer
  );
  const [groupedDetail, setGroupedDetail] = useState<Array<GroupedDetail>>([]);

  const [popoverVisible, setPopoverVisible] = useState(false);
  const events = useSelector(eventReducer);

  useEffect(() => {
    const { order_key } = props.match.params;
    if (state !== State.PENDING && Object.keys(orderDetail).length === 0) {
      dispatch(actionsOrder.fetchOrderDetail({ key: order_key }));
    }
  }, [dispatch, props.match.params, state, orderDetail]);

  const {
    user: {
      metadata: { isFirstRepurchase }
    }
  } = useSelector((state: RootState) => state.AuthReducer);

  React.useEffect(() => {
    setPopoverVisible(
      !user.metadata?.preferences?.hideRepurchasePopin && isFirstRepurchase
    );
  }, [isFirstRepurchase, user.metadata]);

  const closeMessage = () => {
    const preferences = {
      ...user.metadata.preferences,
      hideRepurchasePopin: true
    };

    dispatch(
      actionsCustomer.updateUserPreferences({
        customer: user.metadata.customer,
        preferences
      })
    );
    setPopoverVisible(false);
  };

  useEffect(() => {
    if (!!orderDetailLoaded) {
      const groups = orderDetail?.cart_box?.reduce(
        (groups: Group, detail: Detail) => {
          const date = detail.eta_date;
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(detail);
          return groups;
        },
        {}
      );
      const groupArrays = Object.keys(groups).map((date: string) => {
        return {
          date,
          detail: groups[date]
        };
      });

      setGroupedDetail(groupArrays);
    }
  }, [orderDetailLoaded, orderDetail]);

  const openCalendar = (boxes: any) => {
    let boxItems: Array<any> = [];
    boxes.forEach((box: any) => {
      boxItems = [
        ...boxItems,
        ...box.items.map((item: any) => {
          return {
            variety_key: item.variety,
            product_group_key: item.product_group,
            grower: box.grower,
            grower_obj: item.grower_obj,
            length: item.masterlist.length,
            units_masterlist: item?.units_masterlist ?? 0,
            total_stems: item.quantity * item.stems_per_bunch,
            stem_bunch: item.stems_per_bunch,
            total_bunches: item.quantity,
            customizable: item.masterlist.customizable,
            cart_box_item_key: item.cart_box_item_key,
            product_name: `${item.common_name} ${item.variety_name} ${item.masterlist.length}cm`
          };
        })
      ];
    });
    dispatch(
      actionsModal.setOpenModal({
        modal: CONFIRM_DELIVERY_DATE_MODAL,
        data: {
          isMultipleSelection: true,
          boxes: boxItems
        }
      })
    );
  };

  return (
    <Fragment key={props.match.params.order_key ?? new Date().getTime()}>
      {!!orderDetailLoaded && (
        <div className="ibf-orders-container ">
          <div className="row py-2" style={{ rowGap: '10px' }}>
            <div className="col-2 col-sm-1">
              <img
                src={backButton}
                className="img-fluid pointer product-detail-back-button"
                onClick={() => {
                  history.push('/ordersbyarrival');
                  dispatch(eventActions.setFilteredSearchEvent('clearFilter'));
                  dispatch(eventActions.setEventName(''));
                }}
                alt="backButton"
              />
            </div>
            <div className="col-10 col-sm-3">
              <div className="ibf-text-purple ibf-order-detail-title">
                <b>
                  {' '}
                  Purchase order #{orderDetail?.purchase_info?.consecutive}{' '}
                </b>
                ({orderDetail?.purchase_info?.boxes} boxes)
              </div>

              {orderDetail !== undefined &&
                orderDetail?.cart_box !== undefined && (
                  <div className="d-flex align-items-center">
                    <span
                      className={`ibf-btn-circle 
                               ${orderDetail.cart_box[0]?.event &&
                                 'border-dark border '}`}
                      style={{
                        background: events.eventList.find(
                          (item) => item._KEY === orderDetail.cart_box[0]?.event
                        )?.color
                      }}
                    />
                    <span className="text-capitalize ml-1 font-weight-bold">
                      {
                        events.eventList.find(
                          (item) => item._KEY === orderDetail.cart_box[0]?.event
                        )?.tag_name
                      }
                    </span>
                  </div>
                )}
            </div>
            <div className="col-lg-4">
              <Popover
                trigger="click"
                placement={'bottom'}
                overlayClassName="custom-step-desktop-popover"
                content={
                  <div className="d-flex flex-column align-items-center">
                    <div className="font-weight-bold">Re-order a box</div>
                    <div style={{ width: '250px', textAlign: 'center' }}>
                      Use this feature for the first time and get a $10 off
                      coupon code! (Valid for 1 month)
                    </div>
                    <div
                      className="clickable underline font-weight-bold"
                      onClick={closeMessage}>
                      Close
                    </div>
                  </div>
                }
                visible={popoverVisible}>
                <button
                  className="btn ibf-btn-grey"
                  onClick={() => {
                    openCalendar(orderDetail.cart_box);
                  }}>
                  Re-order this box (select new date)
                </button>
              </Popover>
            </div>
            {/*// <div className="col-lg-2 text-right border">*/}
            {/*  <div className="d-inline">*/}
            {/*    <span className="align-content-center">*/}
            {/*      <FontAwesomeIcon icon={faPrint} className="text-dark" size={'lg'}/>*/}
            {/*      <u> Print </u>*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="col-lg-4  ">
              <div className="d-inline-block d-flex justify-content-lg-end ">
                <button
                  className=" mt-1 mx-3 btn btn-link text-dark"
                  onClick={() => {
                    window.print();
                  }}>
                  <FontAwesomeIcon
                    icon={faPrint}
                    className="text-dark"
                    size={'lg'}
                  />
                  <u> Print </u>
                </button>
                <div>
                  {groupedDetail.some((group) =>
                    group.detail.some((item) => item.tracking_code)
                  ) && (
                    <span className="fedex-badge">
                      <div className="d-flex align-items-center">
                        <span className="mr-2">
                          <b>Free </b>
                          shipping by{' '}
                        </span>

                        <div className="d-flex flex-column">
                          {groupedDetail.some((group) =>
                            group.detail.some((item) => item.tracking_code)
                          ) && (
                            <img
                              className="fedex-logo mb-1"
                              src="https://test.ibuyflowers.com/assets/images/fedex.svg"
                              alt=""
                            />
                          )}

                          {groupedDetail.some((group) =>
                            group.detail.some(
                              (item) =>
                                item.tracking_code &&
                                (item.tracking_code as string).includes('dhl')
                            )
                          ) && (
                            <img className="fedex-logo" src={dhlLogo} alt="" />
                          )}
                        </div>
                      </div>
                    </span>
                  )}
                </div>
              </div>
            </div>
            {/*<div className="col-lg-2 text-lg-right">*/}
            {/*  <div className="d-inline-block">*/}
            {/*    <span className="fedex-badge">*/}
            {/*      <b>Free </b>*/}
            {/*      shipping by{' '}*/}
            {/*      <img*/}
            {/*        className="fedex-logo"*/}
            {/*        src="https://test.ibuyflowers.com/assets/images/fedex.svg"*/}
            {/*        alt=""*/}
            {/*      />*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          <div className="d-flex flex-column flex-lg-row flex-fill bg-ibf-soft">
            <div className="w-100 flex-fill">
              {groupedDetail.map((group: GroupedDetail) => (
                <Fragment key={group.date}>
                  <span className="ibf-font-20 font-weight-bold ml-2">
                    {convertNumberDateToFullDate2(group.date)} delivery (
                    {group.detail.length} box
                    {group.detail.length > 1 && 'es'})
                  </span>
                  {group.detail.map((item: Detail) => (
                    <Fragment key={item._KEY}>
                      <BoxOrderDetailComponent
                        data={item}
                        key={item._KEY}
                        // purchaseInfo={orderDetail?.purchase_info}
                      />
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </div>

            <SideInfoOrderDetailComponent
              data={orderDetail?.purchase_info}
              cart_boxes={orderDetail?.cart_box}
            />
          </div>
        </div>
      )}

      {!orderDetailLoaded && orderDetail?.hasOwnProperty('purchase_info') && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '150px'
          }}>
          LoadingSpinner
        </div>
      )}
    </Fragment>
  );
};

export default OrderDetailComponent;
