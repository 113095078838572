import { cardState } from './Types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../Models/State';
import { Card } from '../../Models/Card';

const { IDLE, REJECTED, RESOLVED, PENDING } = State;

const initialState: cardState = {
  cardDefault: {},
  selectedCard: {},
  cards: [],
  loadingCards: true,
  cardAddedSuccessfully: true,
  state: IDLE
};

const slice = createSlice({
  name: 'card',
  initialState,
  reducers: {
    getCards(state, { payload }: PayloadAction<{ creditCards: Card[] }>) {
      state.state = PENDING;
      if (state.cards.length === 0) {
        state.loadingCards = false;
      }
    },
    listCards(
      state,
      { payload }: PayloadAction<{ cards: Array<Card>; cardDefault: Card }>
    ) {
      state.state = RESOLVED;
      state.cardDefault = payload.cardDefault;
      state.cards = payload.cards;
      state.selectedCard = payload.cardDefault;
      state.loadingCards = false;
    },
    setSelectedCard(state, { payload }: PayloadAction<string>) {
      state.state = RESOLVED;
      state.selectedCard = payload;
    },
    changeDefaultCard(
      state,
      { payload }: PayloadAction<{ cards: Card[]; card: Card }>
    ) {
      state.state = PENDING;
    },
    setDefaultCard(
      state,
      { payload }: PayloadAction<{ cards: Array<Card>; cardDefault: Card }>
    ) {
      state.state = RESOLVED;
      state.cards = [...payload.cards];
      state.cardDefault = payload.cardDefault;
    },
    changeSelectedCard(state, { payload }: PayloadAction<Card>) {
      state.state = RESOLVED;
      state.selectedCard = payload;
    },
    setNewCard(
      state,
      { payload }: PayloadAction<{ customer: string; token: any }>
    ) {
      state.state = PENDING;
    },
    addCard(state, { payload }: PayloadAction<boolean>) {
      state.state = RESOLVED;
      state.cardAddedSuccessfully = payload;
    },
    deleteCard(
      state,
      {
        payload
      }: PayloadAction<{
        customer: string;
        cardKey: string;
        isDefault: boolean;
      }>
    ) {
      state.state = PENDING;
    },
    setCards(state, { payload }: PayloadAction<Array<Card>>) {
      state.state = RESOLVED;
      state.cards = [...payload];
    },
    setResolved(state) {
      state.state = RESOLVED;
    },
    setRejected(state) {
      state.state = REJECTED;
    }
  }
});

export const actionsCard = slice.actions;
export default slice.reducer;
