import { all, call, put, select, takeEvery } from 'typed-redux-saga';
import {
  ADD_CARTBOX_EVENT,
  DELETE_EVENT,
  GET_EVENT_LIST,
  UPDATE_CARTBOX_EVENT,
  UPDATE_EVENT
} from './Types';
import { eventActions } from './Slice';
import { actionsModal } from '../Modal/Slice';
import { EVENT_NAME_MODAL } from '../Modal/Types';
import { toast, updateProductsByName } from '../../Utils/Utils';
import { authReducer, checkoutReducer } from '../Selectors';
import { EventCartbox } from '../../Models/EventCartbox';
import { Cartbox } from '../../Models/Cartbox';
import {
  addNewEventService,
  deleteEventService,
  getEventListService,
  updateCartboxEventService,
  updateEventService
} from '../../Services/EventService';
import { ProductsByEventName } from '../Checkout/Types';
import { ResponseSbx } from '../../Models/ResponseSbx';
import { actionsCheckout } from '../Checkout/Slice';

function* addCartboxEventSaga({
  payload
}: ReturnType<typeof eventActions.addCartboxEvent>) {
  try {
    const response: ResponseSbx = yield call(
      addNewEventService,
      payload.params
    );
    if (response?.success && response.keys) {
      yield put(actionsModal.setCloseModal({ modal: EVENT_NAME_MODAL }));

      let params: {
        customer_key: string;
        cart_box?: Cartbox;
        event_key?: string;
      } = { customer_key: payload.params.customer };
      if (payload.cart_box) {
        const res: ResponseSbx = yield call(
          updateCartboxEventService,
          payload.cart_box._KEY,
          response.keys[0]
        );
        if (res.success) {
          params = {
            ...params,
            cart_box: payload.cart_box,
            event_key: response.keys[0]
          };
        }
      }
      yield put(eventActions.getEventList(params));
    } else {
      toast('An error ocurred to add the event.');
      yield put(eventActions.setRejected());
    }
  } catch (e) {
    console.error(e);
    yield put(eventActions.setRejected());
  }
}

function* getEventListSaga({
  payload
}: ReturnType<typeof eventActions.getEventList>) {
  try {
    const response: ResponseSbx<EventCartbox> = yield call(
      getEventListService,
      payload.customer_key
    );

    if (response.success) {
      const { productsByEventName, products } = yield select(checkoutReducer);
      yield put(eventActions.setEventList({ eventList: response.results }));
      if (payload.cart_box) {
        const event = response.results.find(
          (event: EventCartbox) => event._KEY === payload.event_key
        );
        let newProducts: ProductsByEventName = { ...productsByEventName };

        const newEventProducts = updateProductsByName({
          products: newProducts,
          cart_box: payload.cart_box,
          event: event || null
        });
        yield put(
          actionsCheckout.setProductsByEventName({
            productsByEventName: newEventProducts
          })
        );
      }

      let checkoutProducts: Cartbox[] = [...products];
      checkoutProducts = checkoutProducts.map((product) => {
        if (
          product.event &&
          payload?.event_key_list?.includes(product.event?._KEY)
        ) {
          const event = response.results.find(
            (event: EventCartbox) => event._KEY === product.event?._KEY
          );
          product = { ...product, event: event ?? null };
        }
        return product;
      });

      yield put(
        actionsCheckout.setProducts({
          products: checkoutProducts
        })
      );
    } else {
      toast('An error ocurred to get the event list.', 'error');
      yield put(eventActions.setRejected());
    }
  } catch (e) {
    console.error(e);
    yield put(eventActions.setRejected());
  }
}

function* updateCartboxEventSaga({
  payload
}: ReturnType<typeof eventActions.updateCartboxEvent>) {
  try {
    const response: ResponseSbx = yield call(
      updateCartboxEventService,
      payload.cart_box._KEY,
      payload.event?._KEY ?? null
    );
    if (response.success) {
      const { productsByEventName } = yield select(checkoutReducer);
      let newProducts: ProductsByEventName = { ...productsByEventName };

      const newEventProducts = updateProductsByName({
        products: newProducts,
        cart_box: payload.cart_box,
        event: payload?.event || null
      });

      yield put(
        actionsCheckout.setProductsByEventName({
          productsByEventName: newEventProducts
        })
      );
      yield put(eventActions.setResolved());
    } else {
      toast('An error ocurred to update the event in the cartbox', 'error');
      yield put(eventActions.setRejected());
    }
  } catch (e) {
    console.error(e);
    yield put(eventActions.setRejected());
  }
}

function* updateEventSaga({
  payload
}: ReturnType<typeof eventActions.updateEvent>) {
  try {
    const response: ResponseSbx = yield call(
      updateEventService,
      payload.events
    );
    if (response.success) {
      const {
        user: {
          metadata: { customer }
        }
      } = yield select(authReducer);
      toast('Event was updated.');
      yield getEventListSaga({
        type: GET_EVENT_LIST,
        payload: {
          customer_key: customer,
          event_key_list: payload.events.map((event) => event._KEY)
        }
      });

      if (payload.helperAction) {
        yield put(payload.helperAction);
      }

      yield put(eventActions.setResolved());
    } else {
      toast('An error ocurred', 'error');
      yield put(eventActions.setRejected());
    }
  } catch (e) {
    console.error(e);
    yield put(eventActions.setRejected());
  }
}

function* deleteEventSaga({
  payload
}: ReturnType<typeof eventActions.deleteEvent>) {
  try {
    const response: ResponseSbx = yield call(
      deleteEventService,
      payload.event_key
    );
    if (response.success) {
      toast('Event have been deleted.');
      const {
        user: {
          metadata: { customer }
        }
      } = yield select(authReducer);
      yield getEventListSaga({
        type: GET_EVENT_LIST,
        payload: { customer_key: customer }
      });
      yield put(eventActions.setResolved());
    } else {
      toast('An error ocurred', 'error');
      yield put(eventActions.setRejected());
    }
  } catch (e) {
    console.error(e);
    yield put(eventActions.setRejected());
  }
}

export default function* EventSagas() {
  yield all([
    takeEvery(ADD_CARTBOX_EVENT, addCartboxEventSaga),
    takeEvery(GET_EVENT_LIST, getEventListSaga),
    takeEvery(UPDATE_CARTBOX_EVENT, updateCartboxEventSaga),
    takeEvery(UPDATE_EVENT, updateEventSaga),
    takeEvery(DELETE_EVENT, deleteEventSaga)
  ]);
}
