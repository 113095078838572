import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import './modalDone.scss';
import { actionsModal } from '../../../../Store/Modal/Slice';
import { authReducer, modalReducer } from '../../../../Store/Selectors';
import { eventActions } from '../../../../Store/Event/Slice';
import { EVENT_MODAL_DONE } from '../../../../Store/Modal/Types';

export default function ModalDone() {
  const {
    EVENT_MODAL_DONE: { open, data }
  } = useSelector(modalReducer);
  const dispatch = useDispatch();
  const { user } = useSelector(authReducer);

  const closeModal = () => {
    if (!user.metadata.preferences.donePopover) {
      dispatch(
        eventActions.setPopoverStatus({ popover: 'donePopover', open: true })
      );
    }
    dispatch(actionsModal.setCloseModal({ modal: EVENT_MODAL_DONE }));
  };
  return (
    <div>
      <Modal isOpen={open} toggle={closeModal} size="md">
        <ModalBody className="px-5">
          <h3 className="mb-3">Done.</h3>
          <p className="text-done">
            Your event <b>'{data?.event_name}'</b> is saved
            {data?.isCartbox ? ' and related to your item' : ''}.
          </p>
          <div className="pb-3">
            <button
              className="btn btn-primary ibf-payment-button"
              onClick={closeModal}>
              Show me
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
