import { OrderItem } from './../../Models/Order';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { statementState } from './Types';
import { State } from '../../Models/State';
const { IDLE, RESOLVED, PENDING, REJECTED } = State;

const initialState: statementState = {
  statements: [],
  state: IDLE,
  selectedValue: 'week',
  statementLoaded: false,
  statementsSince: new Date()
};

const slice = createSlice({
  name: 'statements',
  initialState,
  reducers: {
    getStatements: (
      state: statementState,
      { payload }: PayloadAction<string>
    ) => {
      state.state = PENDING;
      state.statementLoaded = true;
    },
    setStatements: (
      state: statementState,
      { payload }: PayloadAction<OrderItem[]>
    ) => {
      state.state = RESOLVED;
      state.statements = payload;
      state.statementLoaded = false;
    },
    setRejected(state) {
      state.state = REJECTED;
      state.statementLoaded = false;
    },
    setSelectedValue(state, { payload }: PayloadAction<string>) {
      state.selectedValue = payload;
    },
    showStatementsSince(state, { payload }: PayloadAction<any>) {
      state.statementsSince = payload;
    }
  }
});

export const actionsStatements = slice.actions;
export default slice.reducer;
