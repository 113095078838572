import { State } from '../../Models/State';
import { StandingOrder } from '../../Models/StandingOrder';
export const GET_STANDING_ORDER = 'standingOrder/getStandingOrder';
export const CREATE_STANDING_ORDER = 'standingOrder/createStandingOrder';
export const SEND_EMAIL_STANDING_ORDER = 'standingOrder/sendEmailStandingOrder';
export interface standingOrderState {
  standingOrder: Array<StandingOrder>;
  state: State;
  indexCurrentPage: number;
  resCreationStandingOrder: { created: boolean; _KEY: string };
}
