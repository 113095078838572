import { State } from '../../Models/State';

export const OPEN_BOX = 'customBox/openBox';
export const ADD_TO_BOX = 'customBox/addToBox';
export const ADD_TO_CART = 'customBox/addToCart';
export const CLOSE_BOX = 'customBox/closeBox';
export const SEARCH_INVENTORY = 'customBox/searchInventory';

export interface CustomBoxState {
  totalItems: number;
  search: string;
  isEmpty: boolean;
  isAList: boolean;
  productsStillFitInBox: boolean;
  totalPages: number;
  currentPercentage: number;
  filters: any[];
  state: State;
  currentPage: number;
  filter_conditions: any;
  singleFilters: any;
  currentBox: any;
  products: any[];
  fromEdit: boolean;
  inventorySold: boolean;
}
