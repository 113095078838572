import React from 'react';

export default function HeaderProductKommetComponent() {
  return (
    <div className={`background-orange`}>
      <div
        className={`ibf-header-text d-flex align-items-center px-3 py-2 justify-content-sm-center w-100 ibf-header-arrow-orange border-top  `}>
        <span className={`d-block`}>
          {' '}
          Checkout your Open inventory boxes asap we can not reserve
          availability
        </span>
      </div>
    </div>
  );
}
