import React from 'react';
import BoxProductFilterList from './BoxProductFilterList';

type Props = {
  showMobileView: boolean;
  toggleMobileView: () => void;
};

const BoxProductFilter: React.FC<Props> = ({
  showMobileView,
  toggleMobileView
}) => {
  return (
    <BoxProductFilterList
      toggle={toggleMobileView}
      showMobile={showMobileView}
    />
  );
};

export default BoxProductFilter;
