import Product from '../Product';
import Filter from '../Filter';
// import { Type } from "class-transformer";
import FilterItem from './../FilterItem';

export default class SearchCS {
  // @Type(() => Product)
  products!: Array<Product>;
  // @Type(() => Filter)
  filters!: Array<Filter>;
  availableGrowers?: FilterItem[] | any[];
  availableDates?: number[] | any[];
  currentPage!: number;
  totalItems!: number;
  totalPages!: number;
}

export enum ErrorTypeRequest {
  AuthenticationRequired = 'AuthenticationRequired',
  NetworkError = 'NetworkError',
  RequestCancelled = 'RequestCancelled'
}
