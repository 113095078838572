import React from 'react';
import { useSelector } from 'react-redux';
import BoxProductComponent from './BoxProductComponent/BoxProductComponent';
import BoxDisplayFilterComponent from './BoxDisplayFilterComponent';
import { customBoxReducer, loadingReducer } from '../../../Store/Selectors';

type Props = {
  toggleMobileView: () => void;
  openPopover: boolean;
  modalWidth: number | undefined;
  setOpenPopover: (open: boolean) => void;
};
const BoxProductList: React.FC<Props> = ({
  toggleMobileView,
  setOpenPopover,
  openPopover,
  modalWidth
}) => {
  const { moreProductsLoading } = useSelector(loadingReducer);

  const { products, isAList } = useSelector(customBoxReducer);

  return (
    <div className="w-100">
      <div className="d-flex mb-2">
        <BoxDisplayFilterComponent toggleFilterMobileView={toggleMobileView} />
      </div>

      <div className={`mr-2 ${isAList ? '' : 'ibf-product-list-grid'}`}>
        {products.map((product, index: number) => {
          return (
            <React.Fragment key={product.key + ' ' + index}>
              <BoxProductComponent
                index={index}
                modalWidth={modalWidth}
                openPopover={openPopover}
                setOpenPopover={setOpenPopover}
                product={product}
              />
            </React.Fragment>
          );
        })}
        {moreProductsLoading && (
          <div className="ibf-product-list-grid-item">
            <div
              className="border loader d-flex justify-content-center loader w-100 bg-ibf-yellow my-2 py-2"
              key={0}>
              Loading ...
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default BoxProductList;
