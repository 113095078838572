import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/Reducers';
import { cartReducer, checkoutReducer } from '../../Store/Selectors';
import CartMenuComponent from './CartMenuComponent';
import MixpanelService from '../../Services/MixpanelService';

const buttonStyle = {
  borderRadius: '20px',
  height: '2.2rem',
  border: '1px solid  #E7E2DF'
};

type Props = {
  className?: string;
};

const CartComponent = ({ className }: Props) => {
  const { itemsInCart } = useSelector(checkoutReducer);

  const { cartLoading } = useSelector(
    (state: RootState) => state.LoadingReducer
  );

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [count, setCount] = useState(0);

  const { cartProducts } = useSelector(cartReducer);

  const toggle = () => {
    if (drawerVisible) {
      MixpanelService.track('cart');
    } else {
      MixpanelService.track('cart_close');
    }
    setDrawerVisible((prev) => !prev);
  };

  useEffect(() => {
    let productCount = 0;
    Object.keys(cartProducts).forEach((key: string) => {
      productCount += cartProducts[key].length;
    });
    setCount(productCount);
  }, [cartProducts]);

  return (
    <div className={`${className ?? ''}`}>
      <button
        className={'ibf-no-outline ibf-btn-cart'}
        style={buttonStyle}
        onClick={toggle}>
        <span className={'ibf-notification-cart'}>
          {' '}
          {cartLoading ? (
            <FontAwesomeIcon icon={faSpinner} pulse />
          ) : cartProducts ? (
            count + itemsInCart > 99 ? (
              '99+'
            ) : (
              count + itemsInCart
            )
          ) : (
            0
          )}{' '}
        </span>
        <>
          <FontAwesomeIcon color={'#4A4A4A'} icon={faShoppingCart} />{' '}
          <span
            style={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '17px',
              lineHeight: '24px',
              color: 'black'
            }}>
            Cart
          </span>
        </>
      </button>
      <CartMenuComponent drawerVisible={drawerVisible} toggle={toggle} />
    </div>
  );
};

export default CartComponent;
