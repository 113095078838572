import React from 'react';

type Props = {
  index: string;
  isSelected: boolean;
  bg: string;
};
const WeekItem: React.FC<Props> = ({ index, bg, isSelected }) => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{
        fontSize: '12px',
        background: bg,
        ...(isSelected ? { padding: '0.2em', border: '2px solid black' } : {})
      }}
      dangerouslySetInnerHTML={{ __html: index }}
    />
  );
};

export default WeekItem;
